import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { SwUpdate } from '@angular/service-worker';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  currentDate = (new Date()).getFullYear();
  moduleActive = '';
  pageActive = '';
  subPageActive = '';
  currentModule = '';
  buildVersion: string = '-';

  constructor(
    private router: Router,
    updates: SwUpdate,
  ) {
    this.router.events.subscribe((val) => {
      if (val instanceof NavigationEnd) {
        this.initRoute();
      }
    });
    if (environment.production) {
      updates.activated.subscribe(event => {
        console.log('[footer] new version is', event.current);
        this.buildVersion = event.current.hash;
      });
    }
  }

  initRoute() {
    const arr_url = this.router.url.split('/');
    if (arr_url[1] === 'main') {
      this.moduleActive = arr_url[2];
      this.pageActive = arr_url[3];
      this.subPageActive = arr_url[4];
    }
  }

  ngOnInit() {
  }

}
