import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-footer5g',
  templateUrl: './footer5g.component.html',
  styleUrls: ['./footer5g.component.scss']
})
export class Footer5gComponent implements OnInit {

  currentDate = (new Date()).getFullYear();

  constructor() { }

  ngOnInit() {
  }

}
