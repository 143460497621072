import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { GlobalService } from './global.service';
import { Observable, throwError, Subject, BehaviorSubject } from 'rxjs';
import { ResponseApiModel } from '../_models/response-api.model';
import { map, retry, catchError } from 'rxjs/operators';
import { CookieService } from 'ngx-cookie-service';

const API_GET_DATA_PERSONAL = '/employeeProfile/profile/get-data-personal';
const API_GET_DATA_ASSIGNMENT = '/employeeProfile/profile/get-data-assignment';
const API_GET_DATA_PAYSLIP = '/employeeProfile/profile/get-data-payslip';
const API_GET_DATA_IDENTITY = '/employeeProfile/profile/get-data-identity';
const API_GET_DATA_RECRUITMENT = '/employeeProfile/profile/get-data-recruitment';
const API_GET_DATA_SYSTEM_INFO = '/employeeProfile/profile/get-data-system-info';
const API_GET_DATA_FAMILY_INFO = '/employeeProfile/profile/get-data-family-info';
const API_GET_DATA_EMERGENCY_CONTACT = '/employeeProfile/profile/get-data-emergency-contact';
const API_GET_DATA_ASSET = '/employeeProfile/profile/get-data-asset';
const API_GET_DATA_PROJECT_CODE = '/employeeProfile/profile/get-data-project-code';
const API_GET_DATA_SKILLS = '/employeeProfile/profile/get-data-skills';
const API_GET_DATA_TRAINING = '/employeeProfile/profile/get-data-training';
const API_GET_DATA_EXAMS = '/employeeProfile/profile/get-data-exams';
const API_GET_DATA_CERTIFICATE = '/employeeProfile/profile/get-data-certificate';
const API_SAVE_ATTACHMENT =  '/employeeProfile/profile/save-attachment';
const API_SAVE_KERABAT =  '/employeeProfile/profile/add-sibling';
const API_DELETE_SIBLING = '/employeeProfile/profile/delete-sibling';

@Injectable({
  providedIn: 'root'
})
export class ProfileService {

  public mobileToggleEmit: Subject<boolean> = new Subject<boolean>();
  public mobileToggleEmitObs = this.mobileToggleEmit.asObservable();

  constructor(
    private http: HttpClient,
    private globalService: GlobalService,
    private cookieService: CookieService
  ) {
    this.mobileToggleEmit.next(false);
  }

  public header() {
    return {
      headers: new HttpHeaders()
        .set('token', this.cookieService.get('_q'))
        .set('module', localStorage.getItem('employeeProfile'))
    };
  }

  getDataPersonal(): Observable<ResponseApiModel> {
    return this.http.post<ResponseApiModel>(
      this.globalService.apiVersionHost + API_GET_DATA_PERSONAL, null, this.header()
    ).pipe(
      map(response => response)
    );
  }

  getDataFamilyInfo(param?: { filter: any }): Observable<ResponseApiModel> {
    return this.http.post<ResponseApiModel>(
      this.globalService.apiVersionHost + API_GET_DATA_FAMILY_INFO, param, this.header()
    ).pipe(
      map(response => response)
    );
  }

  getDataEmergencyContact(param?: { filter: any }): Observable<ResponseApiModel> {
    return this.http.post<ResponseApiModel>(
      this.globalService.apiVersionHost + API_GET_DATA_EMERGENCY_CONTACT, param, this.header()
    ).pipe(
      map(response => response)
    );
  }

  getDataAsset(param?: {filter: any}): Observable<ResponseApiModel> {
    return this.http.post<ResponseApiModel>(
      this.globalService.apiVersionHost + API_GET_DATA_ASSET, param, this.header()
    ).pipe(
      map(response => response)
    );
  }

  getDataProjectCode(param?: { filter: any }): Observable<ResponseApiModel> {
    return this.http.post<ResponseApiModel>(
      this.globalService.apiVersionHost + API_GET_DATA_PROJECT_CODE, param, this.header()
    ).pipe(
      map(response => response)
    );
  }

  getDataSkills(param?: { filter: any }): Observable<ResponseApiModel> {
    return this.http.post<ResponseApiModel>(
      this.globalService.apiVersionHost + API_GET_DATA_SKILLS, param, this.header()
    ).pipe(
      map(response => response)
    );
  }

  getDataTraining(param?: { filter: any }): Observable<ResponseApiModel> {
    return this.http.post<ResponseApiModel>(
      this.globalService.apiVersionHost + API_GET_DATA_TRAINING, param, this.header()
    ).pipe(
      map(response => response)
    );
  }

  getDataExams(param?: { filter: any }): Observable<ResponseApiModel> {
    return this.http.post<ResponseApiModel>(
      this.globalService.apiVersionHost + API_GET_DATA_EXAMS, param, this.header()
    ).pipe(
      map(response => response)
    );
  }

  getDataCertificate(param?: { filter: any }): Observable<ResponseApiModel> {
    return this.http.post<ResponseApiModel>(
      this.globalService.apiVersionHost + API_GET_DATA_CERTIFICATE, param, this.header()
    ).pipe(
      map(response => response)
    );
  }

  public saveAttachment(param): Observable<ResponseApiModel> {
    return this.http.post<ResponseApiModel>(
      this.globalService.apiVersionHost + API_GET_DATA_CERTIFICATE, param, this.header()
    ).pipe(
      map(response => response)
    );
  }

  public saveKerabat(param): Observable<ResponseApiModel> {
    return this.http.post<ResponseApiModel>(
      this.globalService.apiVersionHost + API_SAVE_KERABAT, param, this.header()
    ).pipe(
      map(response => response),
      catchError(err => {
        return throwError(err);
      })
    );
  }

  public deleteApplication(param): Observable<ResponseApiModel> {
    return this.http.post<ResponseApiModel>(
      this.globalService.apiVersionHost + API_DELETE_SIBLING, param, this.header()
    ).pipe(
      map(response => response),
      catchError(err => {
        return throwError(err);
      })
    );
  }

}
