import { Component, OnInit, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { TableAdvanceModel } from './table-advance.config';
import { SelectedCheckbox } from './selected-checkbox.model';
import { CheckboxEmit } from './checkbox-emit.model';
import Swal from 'sweetalert2';
import { NgbCalendar, NgbDate, NgbInputDatepicker } from '@ng-bootstrap/ng-bootstrap';
import { DataDateRangeModel } from '../table-common/data-daterange.models';

@Component({
  selector: 'app-table-advance',
  templateUrl: './table-advance.component.html',
  styleUrls: ['./table-advance.component.scss']
})
export class TableAdvanceComponent implements OnInit {

  @Input() tableAdvanceConfig: TableAdvanceModel;

  @Output() InsertEvent = new EventEmitter<boolean>();
  @Output() pageSizeEvent = new EventEmitter<number>();
  @Output() pageEvent = new EventEmitter<number>();
  @Output() orderEvent = new EventEmitter<string>();
  @Output() filterInput = new EventEmitter<any>();
  @Output() checkboxHandling = new EventEmitter<CheckboxEmit>();

  selectAll = false;
  selectedCb: SelectedCheckbox[] = [];
  checkboxModel: any = {};
  hasSelectedValue: boolean;

  toDay: NgbDate;
  dateRange: any = {};

  @ViewChild('datePicker', { static: false }) public datePicker: NgbInputDatepicker;

  constructor(
    public calendar: NgbCalendar,
  ) { }

  ngOnInit() {
    this.toDay = this.calendar.getToday();
    for (let colOpt of this.tableAdvanceConfig.columnOption) {
      if (colOpt['setPipe'] && colOpt['setPipe'] == 'date') {
        let k = colOpt['name'];
        if (this.tableAdvanceConfig.filter[k] && this.tableAdvanceConfig.filter[k]['from'] && this.tableAdvanceConfig.filter[k]['to']) {
          const toDay = new Date(this.tableAdvanceConfig.filter[k]['to'] + ' 00:00:00');
          const from = new Date(this.tableAdvanceConfig.filter[k]['from'] + ' 00:00:00');
          const to = new Date(this.tableAdvanceConfig.filter[k]['to'] + ' 00:00:00');
          const diffTimeFrom = Math.abs(toDay.getTime() - from.getTime());
          const diffDaysFrom = Math.ceil(diffTimeFrom / (1000 * 60 * 60 * 24));
          const diffTimeTo = Math.abs(toDay.getTime() - to.getTime());
          const diffDaysTo = Math.ceil(diffTimeTo / (1000 * 60 * 60 * 24));
          let d: DataDateRangeModel = {
            hoveredDate: null,
            fromDate: ((diffDaysFrom != 0) ? this.calendar.getNext(this.calendar.getToday(), 'd', -1 * diffDaysFrom) : this.calendar.getToday()),
            strFromDate: this.tableAdvanceConfig.filter[k]['from'],
            toDate: ((diffDaysTo != 0) ? this.calendar.getNext(this.calendar.getToday(), 'd', -1 * diffDaysTo) : this.calendar.getToday()),
            strToDate: this.tableAdvanceConfig.filter[k]['to']
          }
          this.dateRange[k] = d;
        }
        else {
          let d: DataDateRangeModel = {
            hoveredDate: null,
            fromDate: null,
            strFromDate: '',
            toDate: null,
            strToDate: ''
          }
          this.dateRange[k] = d;
          this.tableAdvanceConfig.filter[k] = {};
        }
      }
    }
  }

  generateCheckbox() {
    this.checkboxModel = {};
    this.tableAdvanceConfig.dataSource.forEach(element => {
      const filterSelectedCb = this.selectedCb.filter(fil => fil.primaryKey === element[this.tableAdvanceConfig.primaryKey])[0];
      this.checkboxModel[element[this.tableAdvanceConfig.primaryKey]] = (filterSelectedCb && filterSelectedCb.selected) ? true : false;
    });
  }
  generateOrderingColumn() {
    return this.tableAdvanceConfig.columnOption.filter(fil => fil.ordering);
  }
  generateIcon(icon) {
    const cssClasses = {};
    if (icon) { cssClasses['fa-' + icon] = true; }
    return cssClasses;
  }

  orderData(column: string) {
    this.orderEvent.emit(column);
  }
  changePageSize($event) {
    this.pageSizeEvent.emit($event);
    this.selectAll = false;
  }
  changePage(page: number) {
    this.pageEvent.emit(page);
    this.selectAll = false;
  }
  submitFilter() {
    this.pageEvent.emit(1);
    this.filterInput.emit(this.tableAdvanceConfig.filter);
  }

  actionSelectAll() {
    this.tableAdvanceConfig.dataSource.forEach(element => {
      this.checkboxModel[element[this.tableAdvanceConfig.primaryKey]] = this.selectAll;
      this.actionSelectCb(element[this.tableAdvanceConfig.primaryKey]);
    });
    this.checkUpSelectedCb();
  }
  actionSelectCb(key) {
    const indexOfCb = this.selectedCb.findIndex(i => i.primaryKey === key);
    if (indexOfCb !== -1) {
      this.selectedCb[indexOfCb].selected = this.checkboxModel[key];
    } else {
      this.selectedCb.push({primaryKey: key, selected: this.checkboxModel[key]});
    }
    this.checkUpSelectedCb();
  }
  checkUpSelectedCb() {
    let count = 0;
    this.selectedCb.forEach(element => {
      if (element.selected) { count += 1; }
    });
    this.hasSelectedValue = (count) ? true : false;
  }

  warning() {
    Swal.fire({
      title: 'Are you sure ?',
      text: 'You won\'t be able to revert this!',
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.value) {
        this.changeStatus('remove');
      }
    });
  }

  changeStatus(type: string) {
    const selected = [];
    this.selectedCb.forEach(element => {
      if (element.selected) { selected.push(element.primaryKey); }
    });
    this.checkboxHandling.emit({
      action: type,
      keys: selected
    });
  }

  parseColumnOption(obj, find, val, get) {
    const temp = obj.filter(el => el[find] === val);
    return temp[0][get];
  }

  isHovered(date: NgbDate, col) {
    return this.dateRange[col].fromDate && !this.dateRange[col].toDate && this.dateRange[col].hoveredDate && date.after(this.dateRange[col].fromDate) && date.before(this.dateRange[col].hoveredDate);
  }

  isInside(date: NgbDate, col) {
    return date.after(this.dateRange[col].fromDate) && date.before(this.dateRange[col].toDate);
  }

  isRange(date: NgbDate, col) {
    return date.equals(this.dateRange[col].fromDate) || date.equals(this.dateRange[col].toDate) || this.isInside(date, col) || this.isHovered(date, col);
  }

  dateParse(date) {
    const _date = new Date(date);
    return { year: _date.getFullYear(), month: _date.getMonth() + 1, day: _date.getDate() };
  }

  formatDate({ year, month, day }) {
    let _year, _month, _day;
    _year = year;
    _month = (month < 10) ? '0' + month : month;
    _day = (day < 10) ? '0' + day : day;

    return [_year, _month, _day].join('-');
  }

  onDateSelection(date: NgbDate, col) {
    if (!this.dateRange[col].fromDate && !this.dateRange[col].toDate) {
      this.dateRange[col].fromDate = date;
      this.dateRange[col].strFromDate = this.formatDate(this.dateRange[col].fromDate);
      this.tableAdvanceConfig.filter[col]['from'] = this.dateRange[col].strFromDate;
    } else if (this.dateRange[col].fromDate && !this.dateRange[col].toDate && date.after(this.dateRange[col].fromDate)) {
      this.dateRange[col].toDate = date;
      this.dateRange[col].strToDate = this.formatDate(this.dateRange[col].toDate);
      this.tableAdvanceConfig.filter[col]['to'] = this.dateRange[col].strToDate;
    } else {
      this.dateRange[col].toDate = null;
      this.dateRange[col].strToDate = '';
      this.dateRange[col].fromDate = date;
      this.dateRange[col].strFromDate = this.formatDate(this.dateRange[col].fromDate);
      this.tableAdvanceConfig.filter[col]['from'] = this.dateRange[col].strFromDate;
    }

    if (this.dateRange[col].fromDate && this.dateRange[col].toDate) {
      this.datePicker.close();
      this.submitFilter();
    }
  }

}
