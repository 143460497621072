<div class="ndstable">
  <table class="table">
    <thead class="thead-light">
      <tr>
        <th width="20">No</th>
        <th width="120" class="text-center">...</th>
        <th>Vendor</th>
        <th>Skill</th>
        <th>System</th>
        <th>Level</th>
        <th>Year</th>
      </tr>
    </thead>
    <tbody>
      <ng-container *ngFor="let row of skillFormConfig.dataSource; let ind = index;">
        <ng-container [ngTemplateOutlet]="ind !== row_id ? dataSkill : formSkill" [ngTemplateOutletContext]="{row:row, ind:ind}"></ng-container>
      </ng-container>
    </tbody>
  </table>
</div>

<!-- Data -->
<ng-template #dataSkill let-row='row' let-ind='ind'>
  <tr>
    <th scope="row" class="ndstable-none">
      {{ind + 1}}
    </th>
    <td class="ndstable-inline-block text-center ndstable-floatright">
      <button class="btn btn-success btn-sm btn-icon" type="button" (click)="isEdit(ind, row);">
        <i class="fa fa-edit fa-sm"></i>
      </button>
    </td>
    <td class="ndstable-inline-block">
      {{row.vendor}}
    </td>
    <td class="ndstable-inline-block">
      {{row.skill}}
    </td>
    <td class="ndstable-inline-block">
      {{row.system}}
    </td>
    <td class="ndstable-clear"></td>
    <td class="ndstable-50w ndstable-floatleft" id="level_0_1">
      {{row.level}}
    </td>
    <td class="ndstable-50w ndstable-floatleft" id="year_0_1">
      {{row.experience_in_year}}
    </td>
    <td class="ndstable-clear"></td>
  </tr>
</ng-template>

<!-- Form -->
<ng-template #formSkill let-row='row' let-ind='ind'>
  <tr>
    <th scope="row" class="ndstable-none">
      {{ind + 1}}
    </th>
    <td class="ndstable-inline-block text-center ndstable-floatright">
      <div class="btn-group" role="group">
        <button class="btn btn-primary btn-sm btn-icon" type="button" (click)="isSave(row);">
          <i class="fa fa-save fa-sm"></i>
        </button>
        <button class="btn btn-warning btn-sm btn-icon" type="button" (click)="isCancel();">
          <i class="fa fa-times fa-sm"></i>
        </button>
      </div>
    </td>
    <td class="ndstable-inline-block">
      <input class="form-control form-control-sm" type="text" [(ngModel)]= "edit_model['vendor']" disabled>
    </td>
    <td class="ndstable-inline-block">
      <input class="form-control form-control-sm" type="text" [(ngModel)]= "edit_model['skill']" disabled>
    </td>
    <td class="ndstable-inline-block">
      <input class="form-control form-control-sm" type="text" [(ngModel)]= "edit_model['system']" disabled>
    </td>
    <td class="ndstable-clear"></td>
    <td class="ndstable-50w ndstable-floatleft" id="level_0_1">
      <select class="form-control form-control-sm" [(ngModel)]="edit_model['level']">
        <option disabled selected>Level</option>
        <option [ngValue]="'0.0'">-</option>
        <option [ngValue]="'1.0'">Poor</option>
        <option [ngValue]="'2.0'">Below Average</option>
        <option [ngValue]="'3.0'">Average</option>
        <option [ngValue]="'4.0'">Good</option>
        <option [ngValue]="'5.0'">Excelent</option>
      </select>
    </td>
    <td class="ndstable-50w ndstable-floatleft" id="year_0_1">
      <select class="form-control form-control-sm" [(ngModel)]="edit_model['experience_in_year']">
        <option disabled selected>Year</option>
        <option [ngValue]="'0.0'">-</option>
        <option [ngValue]="'0.5'">&lt; 1 year</option>
        <option [ngValue]="'1.0'">1 Year</option>
        <option [ngValue]="'2.0'">2 Year</option>
        <option [ngValue]="'3.0'">3 Year</option>
        <option [ngValue]="'4.0'">4 Year</option>
        <option [ngValue]="'5.0'">5 Year</option>
        <option [ngValue]="'6.0'">6 Year</option>
        <option [ngValue]="'7.0'">7 Year</option>
        <option [ngValue]="'8.0'">8 Year</option>
        <option [ngValue]="'9.0'">&gt; 8 Year</option>
      </select>
    </td>
    <td class="ndstable-clear"></td>
  </tr>
</ng-template>