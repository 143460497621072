import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpRequest, HttpEventType, HttpEvent } from '@angular/common/http';
import { CookieService } from 'ngx-cookie-service';
import { GlobalService } from './global.service';
import { Observable, Subject, forkJoin, throwError, from } from 'rxjs';
import { map, last, catchError } from 'rxjs/operators';
import { ResponseApiModel } from '../_models/response-api.model';

const API_GET_PRESIGN_URL = '/upload/get-presigned-url';
const API_GET_PRESIGN_URL_GCS = '/upload/get-presigned-url-gcs';
const API_COMPLETE_UPLOAD = '/upload/complate-upload';
const API_COMPLETE_UPLOAD_OBJ_GCS = '/upload/complate-upload-obj-gcs';
const API_CREATE_MULTIPART_UPLOAD = '/upload/create-multipart-upload';
const API_WEBCAM_UPLOAD = '/upload/webcam-upload';
const API_COMPLETE_MULTIPART_UPLOAD = '/upload/complate-multipart-upload';
const API_GET_WASABI_SUMMARY = '/upload/wasabi-summary';
const API_DOWNLOAD_URL_OBJ_GCS = '/upload/download-url-obj-gcs';
const API_GET_PRESIGN_UPLOAD_URL_GCS_FROM_FB = '/presignUrlUplaodGcs';
const API_GET_PRESIGN_DOWNLOAD_URL_GCS_FROM_FB = '/presignUrlDownloadGcs';
const API_SIGNED_WASABI_URL = '/upload/signed-wasabi-url';

@Injectable({
  providedIn: 'root'
})
export class UploadService {

  private progressEmit: Subject<any> = new Subject<any>();
  public progressEmitObs = this.progressEmit.asObservable();

  constructor(
    private http: HttpClient,
    private cookieService: CookieService,
    private globalService: GlobalService
  ) { }

  public header() {
    return {
      headers: new HttpHeaders()
        .set('token', this.cookieService.get('_q'))
    };
  }

  public headerForFb() {
    return {
      headers: new HttpHeaders()
    };
  }

  // ---single upload---- //
  public getPresignedUrl(params: { prefix: string, filename: string }): Observable<ResponseApiModel> {
    return this.http.post<ResponseApiModel>(
      this.globalService.apiVersionHost + API_GET_PRESIGN_URL, params, this.header()
    ).pipe(
      map(response => response)
    );
  }

  public getPresignedUrlGcs(params: {
    prefix: string,
    filename: string,
    mime: string,
    size: number,
    module: string,
    tableTarget: string,
    columnTarget: string,
    isPublicFile: boolean
  }): Observable<ResponseApiModel> {
    return this.http.post<ResponseApiModel>(
      this.globalService.apiVersionHost + API_GET_PRESIGN_URL_GCS, params, this.header()
    ).pipe(
      map(response => response)
    );
  }

  public getSignedUploadUrlGcsFromFb(params: {
    prefix: string,
    filename: string
  }): Observable<any> {
    return this.http.post<any>(
      this.globalService.firebaseHost + API_GET_PRESIGN_UPLOAD_URL_GCS_FROM_FB, params, this.headerForFb()
    ).pipe(
      map(response => response)
    );
  }

  public getSignedDownloadUrlGcsFromFb(params: {
    prefix: string,
    filename: string
  }): Observable<any> {
    return this.http.post<any>(
      this.globalService.firebaseHost + API_GET_PRESIGN_DOWNLOAD_URL_GCS_FROM_FB, params, this.headerForFb()
    ).pipe(
      map(response => response)
    );
  }

  // Upload Using Presigned Url
  public putFile(url: string, file: File) {
    return this.http.put(url, file).pipe(
      map(response => response)
    );
  }

  public putFileGcs(url: string, file: File) {
    const req = new HttpRequest('PUT', url, file, {
      reportProgress: true,
      headers: new HttpHeaders()
        .set('ngsw-bypass', 'true')
        .set('Content-Type', 'application/octet-stream')
    });
    this.progressEmit.next(0);
    return this.http.request(req).pipe(
      map(event => this.getEventMessageGCSUpload(event)),
      last()
    );
  }

  public putFileGcsFb(url: string, file: File) {
    const req = new HttpRequest('PUT', url, file, {
      reportProgress: true,
      headers: new HttpHeaders()
        .set('Content-Type', 'application/octet-stream')
    });
    this.progressEmit.next(0);
    return this.http.request(req).pipe(
      map(event => this.getEventMessageGCSUpload(event)),
      last()
    );
  }

  public getFileGcs(url: string): any {
    const req = new HttpRequest('GET', url, {
      responseType: 'blob',
      reportProgress: true,
      headers: new HttpHeaders()
        .set('Content-Type', 'application/json')
    });
    this.progressEmit.next(0);
    return this.http.request(req).pipe(
      map(event => this.getEventMessageGCSDownload(event)),
      last()
    );
  }

  public downloadUrlObjGcs(params: any): Observable<ResponseApiModel> {
    return this.http.post<ResponseApiModel>(
      this.globalService.apiVersionHost + API_DOWNLOAD_URL_OBJ_GCS, params, this.header()
    ).pipe(
      map(response => response)
    );
  }

  public complateUpload(params: { prefix: string, filename: string, mime: string, size: number }): Observable<ResponseApiModel> {
    return this.http.post<ResponseApiModel>(
      this.globalService.apiVersionHost + API_COMPLETE_UPLOAD, params, this.header()
    ).pipe(
      map(response => response)
    );
  }

  public complateUploadObjGcs(params: { prefix: string, filename: string }): Observable<ResponseApiModel> {
    return this.http.post<ResponseApiModel>(
      this.globalService.apiVersionHost + API_COMPLETE_UPLOAD_OBJ_GCS, params, this.header()
    ).pipe(
      map(response => response)
    );
  }
  // end

  // ---multipart upload---- //
  // tslint:disable-next-line: max-line-length
  public createMultipartUpload(params: { prefix: string, filename: string, mime: string, size: number, totalPart: number, tableTarget: string, columnTarget: string, isPublicFile: boolean }): Observable<ResponseApiModel> {
    return this.http.post<ResponseApiModel>(
      this.globalService.apiVersionHost + API_CREATE_MULTIPART_UPLOAD, params, this.header()
    ).pipe(
      map(response => response)
    );
  }

  // upload from webcam
  public webcamImageUpload(params: {prefix: string, filename: string, file: string}) {
    return this.http.post<ResponseApiModel>(
      this.globalService.apiVersionHost + API_WEBCAM_UPLOAD, params, this.header()
    ).pipe(
      map(response => response)
    );
  }

  public putFilePart(url: string, file: File) {
    const req = new HttpRequest('PUT', url, file, {
      reportProgress: true,
      headers: new HttpHeaders().set('ngsw-bypass', 'true')
    });

    return this.http.request(req).pipe(
      map(event => this.getEventMessage(event, file)),
      last()
    );
  }

  private getEventMessage(event: HttpEvent<any>, file: File) {
    switch (event.type) {
      case HttpEventType.UploadProgress:
        const percentDone = Math.round(100 * event.loaded / event.total);
        this.progressEmit.next(percentDone);
        return {
          status: 'progress',
          val: percentDone
        };

      case HttpEventType.Response:
        return {
          status: 'done',
          val: event.headers.get('etag')
        };

      default:
        return {
          status: 'unknow',
          val: null
        };
    }
  }

  private getEventMessageGCSUpload(event: HttpEvent<any>) {
    switch (event.type) {
      case HttpEventType.UploadProgress:
        const percentDone = Math.round(100 * event.loaded / event.total);
        this.progressEmit.next(percentDone);
        return {
          status: 'progress',
          val: percentDone
        };

      case HttpEventType.Response:
        return {
          status: 'done',
          val: event
        };

      default:
        return {
          status: 'unknow',
          val: null
        };
    }
  }

  private getEventMessageGCSDownload(event: HttpEvent<any>) {
    switch (event.type) {
      case HttpEventType.DownloadProgress:
        const percentDone = Math.round(100 * event.loaded / event.total);
        this.progressEmit.next(percentDone);
        return {
          status: 'progress',
          val: percentDone
        };

      case HttpEventType.Response:
        return {
          status: 'done',
          val: event
        };

      default:
        return {
          status: 'unknow',
          val: null
        };
    }
  }

  public complateMultipartUpload(params: { prefix: string, filename: string, parts: any, uploadId: any }): Observable<ResponseApiModel> {
    return this.http.post<ResponseApiModel>(
      this.globalService.apiVersionHost + API_COMPLETE_MULTIPART_UPLOAD, params, this.header()
    ).pipe(
      map(response => response)
    );
  }

  public getSignedWasabiUrl(params: { pathFile?: string, idCloudStorage?: number}) {
    return this.http.post<ResponseApiModel>(
      this.globalService.apiVersionHost + API_SIGNED_WASABI_URL, params, this.header()
    ).pipe(
      map(response => response)
    );
  }

  public downloadFile(path: string) {
    const req = new HttpRequest('GET', path, {
      reportProgress: true,
      responseType: 'blob'
    });

    return this.http.request(req).pipe(
      map(res => res),
      catchError(err => {
        return throwError(err);
      })
    );
  }

  public getWasabiSummary(): Observable<ResponseApiModel> {
    return this.http.post<ResponseApiModel>(
      this.globalService.apiVersionHost + API_GET_WASABI_SUMMARY, null, this.header()
    ).pipe(
      map(response => response)
    );
  }

  
  public getListCloud(params: {
    pageSize: number;
    page: number;
    filter: any;
  }): Observable<ResponseApiModel> {
    return this.http
      .post<ResponseApiModel>(
        this.globalService.apiVersionHost + '/upload/list-cloud-storage',
        params,
        this.header()
      )
      .pipe(map(response => response));
  }

}
