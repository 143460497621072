<div class="row" style="padding: 20px">
  <div class="col">
    <div class="alert alert-light alert-elevate fade show" role="alert">
      <div class="alert-icon"><i class="flaticon2-list-3 kt-font-brand"></i></div>
      <div class="alert-text">
          Silakan bubuhkan tanda tangan anda di sini, kemudian klik "Simpan" untuk menyimpannya.
      </div>
    </div>

    <div style="border:solid 2px #333;margin:0 auto ;width: 450px">
      <signature-pad [options]="signaturePadOptions" #datasignature (onEndEvent)="drawComplete($event, datasignature)">
      </signature-pad>    
    </div><br>
    <div style="margin:0 auto ;width: 450px">
      <button type="button" class="btn btn-warning" (click)="clear(datasignature)">Ulangi</button>
    &nbsp;<button type="button" class="btn btn-primary" (click)="submit(datasignature)">Simpan</button>
    </div>

  </div>
</div>

