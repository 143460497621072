/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./module.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/router";
import * as i3 from "@angular/common";
import * as i4 from "../../shared/_pipes/safe-html";
import * as i5 from "@angular/platform-browser";
import * as i6 from "../../../../../node_modules/ngx-ui-loader/ngx-ui-loader.ngfactory";
import * as i7 from "ngx-ui-loader";
import * as i8 from "./module.component";
import * as i9 from "../../../_services/user.service";
import * as i10 from "../../../_services/module-menu.service";
import * as i11 from "@angular/service-worker";
var styles_ModuleComponent = [i0.styles];
var RenderType_ModuleComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ModuleComponent, data: {} });
export { RenderType_ModuleComponent as RenderType_ModuleComponent };
function View_ModuleComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "a", [["class", "icon-item rounded rounded-circle mx-auto d-block bg-white shadow mb-3"]], [[8, "href", 4]], null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "div", [["class", "search-icons"]], [[8, "innerHTML", 1]], null, null, null, null)), i1.ɵppd(3, 1), (_l()(), i1.ɵeld(4, 0, null, null, 1, "a", [["class", " text-white"]], [[8, "href", 4]], null, null, null, null)), (_l()(), i1.ɵted(5, null, [" ", " "]))], null, function (_ck, _v) { var currVal_0 = i1.ɵinlineInterpolate(1, "", _v.parent.parent.context.$implicit.url, ""); _ck(_v, 1, 0, currVal_0); var currVal_1 = i1.ɵunv(_v, 2, 0, _ck(_v, 3, 0, i1.ɵnov(_v.parent.parent.parent.parent, 0), _v.parent.parent.context.$implicit.icon_svg)); _ck(_v, 2, 0, currVal_1); var currVal_2 = i1.ɵinlineInterpolate(1, "", _v.parent.parent.context.$implicit.url, ""); _ck(_v, 4, 0, currVal_2); var currVal_3 = _v.parent.parent.context.$implicit.menu_label; _ck(_v, 5, 0, currVal_3); }); }
function View_ModuleComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "a", [["class", "icon-item rounded rounded-circle mx-auto d-block bg-white shadow mb-3"]], [[8, "href", 4]], null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "div", [["class", "search-icons"]], [[8, "innerHTML", 1]], null, null, null, null)), i1.ɵppd(3, 1), (_l()(), i1.ɵeld(4, 0, null, null, 1, "a", [["class", " text-white"]], [[8, "href", 4]], null, null, null, null)), (_l()(), i1.ɵted(5, null, [" ", " "]))], null, function (_ck, _v) { var currVal_0 = i1.ɵinlineInterpolate(1, "", _v.parent.parent.parent.context.$implicit.url, ""); _ck(_v, 1, 0, currVal_0); var currVal_1 = i1.ɵunv(_v, 2, 0, _ck(_v, 3, 0, i1.ɵnov(_v.parent.parent.parent.parent.parent, 0), _v.parent.parent.parent.context.$implicit.icon_svg)); _ck(_v, 2, 0, currVal_1); var currVal_2 = i1.ɵinlineInterpolate(1, "", _v.parent.parent.parent.context.$implicit.url, ""); _ck(_v, 4, 0, currVal_2); var currVal_3 = _v.parent.parent.parent.context.$implicit.menu_label; _ck(_v, 5, 0, currVal_3); }); }
function View_ModuleComponent_9(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 4, "a", [["class", "icon-item rounded rounded-circle mx-auto d-block bg-white shadow mb-3"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 2).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(2, 671744, null, 0, i2.RouterLinkWithHref, [i2.Router, i2.ActivatedRoute, i3.LocationStrategy], { routerLink: [0, "routerLink"] }, null), i1.ɵpad(3, 1), (_l()(), i1.ɵeld(4, 0, null, null, 1, "div", [["class", "search-icons"]], [[8, "innerHTML", 1]], null, null, null, null)), i1.ɵppd(5, 1), (_l()(), i1.ɵeld(6, 0, null, null, 3, "a", [["class", " text-white"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 7).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(7, 671744, null, 0, i2.RouterLinkWithHref, [i2.Router, i2.ActivatedRoute, i3.LocationStrategy], { routerLink: [0, "routerLink"] }, null), i1.ɵpad(8, 1), (_l()(), i1.ɵted(9, null, [" ", " "]))], function (_ck, _v) { var currVal_2 = _ck(_v, 3, 0, _v.parent.parent.parent.parent.parent.context.$implicit.url); _ck(_v, 2, 0, currVal_2); var currVal_6 = _ck(_v, 8, 0, _v.parent.parent.parent.parent.parent.context.$implicit.url); _ck(_v, 7, 0, currVal_6); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 2).target; var currVal_1 = i1.ɵnov(_v, 2).href; _ck(_v, 1, 0, currVal_0, currVal_1); var currVal_3 = i1.ɵunv(_v, 4, 0, _ck(_v, 5, 0, i1.ɵnov(_v.parent.parent.parent.parent.parent.parent.parent, 0), _v.parent.parent.parent.parent.parent.context.$implicit.icon_svg)); _ck(_v, 4, 0, currVal_3); var currVal_4 = i1.ɵnov(_v, 7).target; var currVal_5 = i1.ɵnov(_v, 7).href; _ck(_v, 6, 0, currVal_4, currVal_5); var currVal_7 = _v.parent.parent.parent.parent.parent.context.$implicit.menu_label; _ck(_v, 9, 0, currVal_7); }); }
function View_ModuleComponent_10(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "a", [["class", "icon-item rounded rounded-circle mx-auto d-block bg-white shadow mb-3"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 671744, null, 0, i2.RouterLinkWithHref, [i2.Router, i2.ActivatedRoute, i3.LocationStrategy], { routerLink: [0, "routerLink"] }, null), i1.ɵpad(2, 1), (_l()(), i1.ɵeld(3, 0, null, null, 1, "div", [["class", "search-icons"]], [[8, "innerHTML", 1]], null, null, null, null)), i1.ɵppd(4, 1), (_l()(), i1.ɵeld(5, 0, null, null, 3, "a", [["class", " text-white"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 6).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(6, 671744, null, 0, i2.RouterLinkWithHref, [i2.Router, i2.ActivatedRoute, i3.LocationStrategy], { routerLink: [0, "routerLink"] }, null), i1.ɵpad(7, 1), (_l()(), i1.ɵted(8, null, [" ", " "]))], function (_ck, _v) { var currVal_2 = _ck(_v, 2, 0, "/main/pr/my-request/index"); _ck(_v, 1, 0, currVal_2); var currVal_6 = _ck(_v, 7, 0, "/main/pr/my-request/index"); _ck(_v, 6, 0, currVal_6); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).target; var currVal_1 = i1.ɵnov(_v, 1).href; _ck(_v, 0, 0, currVal_0, currVal_1); var currVal_3 = i1.ɵunv(_v, 3, 0, _ck(_v, 4, 0, i1.ɵnov(_v.parent.parent.parent.parent.parent.parent.parent, 0), _v.parent.parent.parent.parent.parent.context.$implicit.icon_svg)); _ck(_v, 3, 0, currVal_3); var currVal_4 = i1.ɵnov(_v, 6).target; var currVal_5 = i1.ɵnov(_v, 6).href; _ck(_v, 5, 0, currVal_4, currVal_5); var currVal_7 = _v.parent.parent.parent.parent.parent.context.$implicit.menu_label; _ck(_v, 8, 0, currVal_7); }); }
function View_ModuleComponent_8(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, null, null, null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ModuleComponent_9)), i1.ɵdid(2, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), (_l()(), i1.ɵand(0, [["myRequestRoute", 2]], null, 0, null, View_ModuleComponent_10))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.userService.isInGroup("Project Support Subcon Huawei"); var currVal_1 = i1.ɵnov(_v, 3); _ck(_v, 2, 0, currVal_0, currVal_1); }, null); }
function View_ModuleComponent_12(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 4, "a", [["class", "icon-item rounded rounded-circle mx-auto d-block bg-white shadow mb-3"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 2).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(2, 671744, null, 0, i2.RouterLinkWithHref, [i2.Router, i2.ActivatedRoute, i3.LocationStrategy], { routerLink: [0, "routerLink"] }, null), i1.ɵpad(3, 1), (_l()(), i1.ɵeld(4, 0, null, null, 1, "div", [["class", "search-icons"]], [[8, "innerHTML", 1]], null, null, null, null)), i1.ɵppd(5, 1), (_l()(), i1.ɵeld(6, 0, null, null, 3, "a", [["class", " text-white"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 7).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(7, 671744, null, 0, i2.RouterLinkWithHref, [i2.Router, i2.ActivatedRoute, i3.LocationStrategy], { routerLink: [0, "routerLink"] }, null), i1.ɵpad(8, 1), (_l()(), i1.ɵted(9, null, [" ", " "]))], function (_ck, _v) { var currVal_2 = _ck(_v, 3, 0, _v.parent.parent.parent.parent.parent.context.$implicit.url); _ck(_v, 2, 0, currVal_2); var currVal_6 = _ck(_v, 8, 0, _v.parent.parent.parent.parent.parent.context.$implicit.url); _ck(_v, 7, 0, currVal_6); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 2).target; var currVal_1 = i1.ɵnov(_v, 2).href; _ck(_v, 1, 0, currVal_0, currVal_1); var currVal_3 = i1.ɵunv(_v, 4, 0, _ck(_v, 5, 0, i1.ɵnov(_v.parent.parent.parent.parent.parent.parent.parent, 0), _v.parent.parent.parent.parent.parent.context.$implicit.icon_svg)); _ck(_v, 4, 0, currVal_3); var currVal_4 = i1.ɵnov(_v, 7).target; var currVal_5 = i1.ɵnov(_v, 7).href; _ck(_v, 6, 0, currVal_4, currVal_5); var currVal_7 = _v.parent.parent.parent.parent.parent.context.$implicit.menu_label; _ck(_v, 9, 0, currVal_7); }); }
function View_ModuleComponent_13(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "a", [["class", "icon-item rounded rounded-circle mx-auto d-block bg-white shadow mb-3"]], [[8, "href", 4]], null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "div", [["class", "search-icons"]], [[8, "innerHTML", 1]], null, null, null, null)), i1.ɵppd(2, 1), (_l()(), i1.ɵeld(3, 0, null, null, 1, "a", [["class", " text-white"]], [[8, "href", 4]], null, null, null, null)), (_l()(), i1.ɵted(4, null, [" ", " "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.infoDomain + _v.parent.parent.parent.parent.parent.context.$implicit.url); _ck(_v, 0, 0, currVal_0); var currVal_1 = i1.ɵunv(_v, 1, 0, _ck(_v, 2, 0, i1.ɵnov(_v.parent.parent.parent.parent.parent.parent.parent, 0), _v.parent.parent.parent.parent.parent.context.$implicit.icon_svg)); _ck(_v, 1, 0, currVal_1); var currVal_2 = (_co.infoDomain + _v.parent.parent.parent.parent.parent.context.$implicit.url); _ck(_v, 3, 0, currVal_2); var currVal_3 = _v.parent.parent.parent.parent.parent.context.$implicit.menu_label; _ck(_v, 4, 0, currVal_3); }); }
function View_ModuleComponent_11(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_ModuleComponent_12)), i1.ɵdid(1, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), (_l()(), i1.ɵand(0, [["info", 2]], null, 0, null, View_ModuleComponent_13))], function (_ck, _v) { var currVal_0 = (((_v.parent.parent.parent.parent.context.$implicit.menu_label !== "Blog Management") && (_v.parent.parent.parent.parent.context.$implicit.menu_label !== "FAQ")) && (_v.parent.parent.parent.parent.context.$implicit.menu_label !== "Documentation")); var currVal_1 = i1.ɵnov(_v, 2); _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
function View_ModuleComponent_7(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_ModuleComponent_8)), i1.ɵdid(1, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), (_l()(), i1.ɵand(0, [["other", 2]], null, 0, null, View_ModuleComponent_11))], function (_ck, _v) { var currVal_0 = (_v.parent.parent.parent.context.$implicit.menu_label == "PO Request Management"); var currVal_1 = i1.ɵnov(_v, 2); _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
function View_ModuleComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, null, null, null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ModuleComponent_6)), i1.ɵdid(2, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), (_l()(), i1.ɵand(0, [["bukanSip", 2]], null, 0, null, View_ModuleComponent_7))], function (_ck, _v) { var currVal_0 = (_v.parent.parent.context.$implicit.menu_label == "SIP"); var currVal_1 = i1.ɵnov(_v, 3); _ck(_v, 2, 0, currVal_0, currVal_1); }, null); }
function View_ModuleComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 4, "div", [["class", "col-4 col-md-2 text-center mb-5 pb-4"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ModuleComponent_4)), i1.ɵdid(3, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ModuleComponent_5)), i1.ɵdid(5, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.cekLink(_v.parent.context.$implicit.module_key); _ck(_v, 3, 0, currVal_0); var currVal_1 = !_co.cekLink(_v.parent.context.$implicit.module_key); _ck(_v, 5, 0, currVal_1); }, null); }
function View_ModuleComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ModuleComponent_3)), i1.ɵdid(2, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var currVal_0 = (_v.context.$implicit.user_ptnw_status == "Internal"); _ck(_v, 2, 0, currVal_0); }, null); }
function View_ModuleComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "div", [["class", "col-lg-11"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 2, "div", [["class", "row justify-content-center search-icons"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ModuleComponent_2)), i1.ɵdid(4, 278528, null, 0, i3.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.menuList; _ck(_v, 4, 0, currVal_0); }, null); }
function View_ModuleComponent_17(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "a", [["class", "icon-item rounded rounded-circle mx-auto d-block bg-white shadow mb-3"]], [[8, "href", 4]], null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "div", [["class", "search-icons"]], [[8, "innerHTML", 1]], null, null, null, null)), i1.ɵppd(3, 1), (_l()(), i1.ɵeld(4, 0, null, null, 1, "a", [["class", " text-white"]], [[8, "href", 4]], null, null, null, null)), (_l()(), i1.ɵted(5, null, [" ", " "]))], null, function (_ck, _v) { var currVal_0 = i1.ɵinlineInterpolate(1, "", _v.parent.parent.context.$implicit.url, ""); _ck(_v, 1, 0, currVal_0); var currVal_1 = i1.ɵunv(_v, 2, 0, _ck(_v, 3, 0, i1.ɵnov(_v.parent.parent.parent.parent, 0), _v.parent.parent.context.$implicit.icon_svg)); _ck(_v, 2, 0, currVal_1); var currVal_2 = i1.ɵinlineInterpolate(1, "", _v.parent.parent.context.$implicit.url, ""); _ck(_v, 4, 0, currVal_2); var currVal_3 = _v.parent.parent.context.$implicit.menu_label; _ck(_v, 5, 0, currVal_3); }); }
function View_ModuleComponent_19(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "a", [["class", "icon-item rounded rounded-circle mx-auto d-block bg-white shadow mb-3"]], [[8, "href", 4]], null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "div", [["class", "search-icons"]], [[8, "innerHTML", 1]], null, null, null, null)), i1.ɵppd(3, 1), (_l()(), i1.ɵeld(4, 0, null, null, 1, "a", [["class", " text-white"]], [[8, "href", 4]], null, null, null, null)), (_l()(), i1.ɵted(5, null, [" ", " "]))], null, function (_ck, _v) { var currVal_0 = i1.ɵinlineInterpolate(1, "", _v.parent.parent.parent.context.$implicit.url, ""); _ck(_v, 1, 0, currVal_0); var currVal_1 = i1.ɵunv(_v, 2, 0, _ck(_v, 3, 0, i1.ɵnov(_v.parent.parent.parent.parent.parent, 0), _v.parent.parent.parent.context.$implicit.icon_svg)); _ck(_v, 2, 0, currVal_1); var currVal_2 = i1.ɵinlineInterpolate(1, "", _v.parent.parent.parent.context.$implicit.url, ""); _ck(_v, 4, 0, currVal_2); var currVal_3 = _v.parent.parent.parent.context.$implicit.menu_label; _ck(_v, 5, 0, currVal_3); }); }
function View_ModuleComponent_20(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "a", [["class", "icon-item rounded rounded-circle mx-auto d-block bg-white shadow mb-3"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 671744, null, 0, i2.RouterLinkWithHref, [i2.Router, i2.ActivatedRoute, i3.LocationStrategy], { routerLink: [0, "routerLink"] }, null), i1.ɵpad(2, 1), (_l()(), i1.ɵeld(3, 0, null, null, 1, "div", [["class", "search-icons"]], [[8, "innerHTML", 1]], null, null, null, null)), i1.ɵppd(4, 1), (_l()(), i1.ɵeld(5, 0, null, null, 3, "a", [["class", " text-white"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 6).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(6, 671744, null, 0, i2.RouterLinkWithHref, [i2.Router, i2.ActivatedRoute, i3.LocationStrategy], { routerLink: [0, "routerLink"] }, null), i1.ɵpad(7, 1), (_l()(), i1.ɵted(8, null, [" ", " "]))], function (_ck, _v) { var currVal_2 = _ck(_v, 2, 0, _v.parent.parent.parent.context.$implicit.url); _ck(_v, 1, 0, currVal_2); var currVal_6 = _ck(_v, 7, 0, _v.parent.parent.parent.context.$implicit.url); _ck(_v, 6, 0, currVal_6); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).target; var currVal_1 = i1.ɵnov(_v, 1).href; _ck(_v, 0, 0, currVal_0, currVal_1); var currVal_3 = i1.ɵunv(_v, 3, 0, _ck(_v, 4, 0, i1.ɵnov(_v.parent.parent.parent.parent.parent, 0), _v.parent.parent.parent.context.$implicit.icon_svg)); _ck(_v, 3, 0, currVal_3); var currVal_4 = i1.ɵnov(_v, 6).target; var currVal_5 = i1.ɵnov(_v, 6).href; _ck(_v, 5, 0, currVal_4, currVal_5); var currVal_7 = _v.parent.parent.parent.context.$implicit.menu_label; _ck(_v, 8, 0, currVal_7); }); }
function View_ModuleComponent_18(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, null, null, null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ModuleComponent_19)), i1.ɵdid(2, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), (_l()(), i1.ɵand(0, [["bukanSip", 2]], null, 0, null, View_ModuleComponent_20))], function (_ck, _v) { var currVal_0 = (_v.parent.parent.context.$implicit.menu_label == "SIP"); var currVal_1 = i1.ɵnov(_v, 3); _ck(_v, 2, 0, currVal_0, currVal_1); }, null); }
function View_ModuleComponent_16(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 4, "div", [["class", "col-4 col-md-2 text-center mb-5 pb-4"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ModuleComponent_17)), i1.ɵdid(3, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ModuleComponent_18)), i1.ɵdid(5, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.cekLink(_v.parent.context.$implicit.module_key); _ck(_v, 3, 0, currVal_0); var currVal_1 = !_co.cekLink(_v.parent.context.$implicit.module_key); _ck(_v, 5, 0, currVal_1); }, null); }
function View_ModuleComponent_15(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ModuleComponent_16)), i1.ɵdid(2, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var currVal_0 = (_v.context.$implicit.user_ptnw_status == "External"); _ck(_v, 2, 0, currVal_0); }, null); }
function View_ModuleComponent_14(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "div", [["class", "col-lg-11"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 2, "div", [["class", "row justify-content-center search-icons"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ModuleComponent_15)), i1.ɵdid(4, 278528, null, 0, i3.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.menuList; _ck(_v, 4, 0, currVal_0); }, null); }
export function View_ModuleComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i4.SafeHtml, [i5.DomSanitizer]), (_l()(), i1.ɵeld(1, 0, null, null, 10, "div", [["class", "kt-content kt-grid__item kt-grid__item--fluid"], ["id", "kt_content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 2, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "div", [["class", "col-12 mb-5"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\u00A0"])), (_l()(), i1.ɵeld(5, 0, null, null, 6, "div", [["class", "row justify-content-center pt-5 pb-2 "]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 1, "ngx-ui-loader", [], null, null, null, i6.View_ɵb_0, i6.RenderType_ɵb)), i1.ɵdid(7, 770048, null, 0, i7.ɵb, [i5.DomSanitizer, i1.ChangeDetectorRef, i7.NgxUiLoaderService], { fgsType: [0, "fgsType"], loaderId: [1, "loaderId"], hasProgressBar: [2, "hasProgressBar"], text: [3, "text"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ModuleComponent_1)), i1.ɵdid(9, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ModuleComponent_14)), i1.ɵdid(11, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "three-bounce"; var currVal_1 = "menu-loader"; var currVal_2 = false; var currVal_3 = "Please Wait..."; _ck(_v, 7, 0, currVal_0, currVal_1, currVal_2, currVal_3); var currVal_4 = (_co.currentUser.user_ptnw_status == "Internal"); _ck(_v, 9, 0, currVal_4); var currVal_5 = (_co.currentUser.user_ptnw_status == "External"); _ck(_v, 11, 0, currVal_5); }, null); }
export function View_ModuleComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-module", [], null, null, null, View_ModuleComponent_0, RenderType_ModuleComponent)), i1.ɵdid(1, 114688, null, 0, i8.ModuleComponent, [i9.UserService, i10.ModuleMenuService, i11.SwUpdate, i7.NgxUiLoaderService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ModuleComponentNgFactory = i1.ɵccf("app-module", i8.ModuleComponent, View_ModuleComponent_Host_0, {}, {}, []);
export { ModuleComponentNgFactory as ModuleComponentNgFactory };
