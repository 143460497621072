<p *ngIf="remark"><span class="kt-link kt-link--state kt-link--primary">{{remark}}</span></p>
<ng-container *ngIf="theme == 'default'">
  <div class="form-group mb-10">
    <div class="custom-file">
      <input #inputFile *ngIf="acceptFile" type="file" class="custom-file-input"
        (change)="cropper ? fileChangeEvent($event) : selectFile($event)"
        [disabled]="selectedFiles && !isFinish" [attr.accept]="acceptFile">
      <input #inputFile *ngIf="!acceptFile" type="file" class="custom-file-input"
        (change)="cropper ? fileChangeEvent($event) : selectFile($event)"
        [disabled]="selectedFiles && !isFinish">
      <label *ngIf="labelInput != ''" class="custom-file-label text-ellipsis" for="customFile">{{labelInput}}</label>
    </div>
  </div>
  <p *ngIf="errorMime"><span class="kt-link kt-link--state kt-link--danger">invalid file type!</span></p>
  <p *ngIf="uploadSuccess"><span class="kt-link kt-link--state kt-link--primary">file uploaded successfully.</span></p>
  <ng-container *ngIf="selectedFiles && !isFinish">
    <ngb-progressbar type="primary" [value]="progress" [striped]="true" [animated]="true"><i>{{(isFinish)? 'Upload
        finish!':progress+'%' }}</i></ngb-progressbar>
  </ng-container>
</ng-container>
<ng-container *ngIf="theme == 'small'">
  <ul class="list-group">
    <li class="list-group-item list-group-item-nopadding d-flex justify-content-between align-items-center">
      <a *ngIf="errorMime" class="text-ellipsis kt-link kt-link--state kt-link--danger" href="javascript:;">invalid file type!</a>
      <a *ngIf="uploadSuccess" class="text-ellipsis kt-link kt-link--state kt-link--primary" href="javascript:;">upload successful.</a>
      <ng-container *ngIf="selectedFiles && !isFinish">
        <div style="width: 100%; height: 19.5px;">
          <ngb-progressbar type="primary" [value]="progress" [striped]="true" [animated]="true"><i>{{(isFinish)? 'Upload
            finish!':progress+'%' }}</i></ngb-progressbar>
        </div>
      </ng-container>
      <ng-container *ngIf="!errorMime && !uploadSuccess && !(selectedFiles && !isFinish)">
        <span>&nbsp;</span>
      </ng-container>
      <span (click)="inputFileSmall.click()" class="fa fa-paperclip kt-font-primary cursor-pointer"></span>
    </li>
  </ul>
  <input #inputFileSmall type="file" class="custom-file-input" style="display: none;"
    (change)="cropper ? fileChangeEvent($event) : selectFile($event)"
    [disabled]="selectedFiles && !isFinish" [attr.accept]="acceptFile">
</ng-container>

<ng-template #content let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Profile update</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <image-cropper [imageChangedEvent]="imageChangedEvent" [maintainAspectRatio]="true" [aspectRatio]="1/1"
      [resizeToWidth]="640" format="jpg" (imageCropped)="imageCropped($event)" (imageLoaded)="imageLoaded()"
      (cropperReady)="cropperReady()" (loadImageFailed)="loadImageFailed()"></image-cropper>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-dark" (click)="modal.close('crop')"> Crop</button>
  </div>
</ng-template>
