import { Component, OnInit } from '@angular/core';
import { LandingpageService } from 'src/app/_services/landingpage.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-kebijakan-mutu',
  templateUrl: './kebijakan-mutu.component.html',
  styleUrls: ['./kebijakan-mutu.component.scss']
})
export class KebijakanMutuComponent implements OnInit {
  public webContent: any = {};
  
  constructor(
    private landingService: LandingpageService,
  ) { }

  ngOnInit() {
    this.getContent();
  }

  getContent() {
    this.landingService.getBodyContent().subscribe(
      response => {
        if (response.status) {
          this.webContent = response.result;
        }
      },
      error => {
        Swal.fire(error.error.message);
      }
    );
  }
}
