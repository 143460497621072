import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {

  @Input() categories: any;
  @Input() populars: any;

  @Output() reloadCategory = new EventEmitter<string>();
  @Output() reloadPost = new EventEmitter<string>();

  constructor() { }

  ngOnInit() {
  }

}
