<div id="content" class="bg-white">
  <div class="row align-items-center no-gutters justify-content-end">
    <div class="col-lg-5 col-xl-5">
      <div class="pb-5 mb-5 pt-5 px-4 px-md-5 ml-0 ml-xl-5">
        <h1 class="display-3 text-primary mb-0 pb-0">Gabung Bersama Kami</h1>
        <div class="text-dark lead pt-3 pt-md-4 pb-4 pb-md-5 line-height-180">Don't Pick a Job With Great Vacation Time.
          Pick One That Doesn't Need Escaping From.
        </div>
      </div>
    </div>
    <div class="col-lg-7 col-xl-7">
      <div class="pl-0 pl-lg-5">
        <img src="./assets/media/5g/career.jpg" class="w-100" alt="" />
      </div>
    </div>
  </div>
  <div class="bg-white pb-1">
    <div class="container-fluid">
      <div class="p-4 bg-primary shadow rounded mx-auto" style="width: 90%;">
        <form #prForm="ngForm" (ngSubmit)="reloadData()" class="ng-pristine ng-valid ng-touched p-md-2">
          <div class="input-group">
            <input name="filter.title" [(ngModel)]="filter.title" type="text" class="form-control" placeholder="Posisi apa yang anda cari?"
              aria-label="Posisi apa yang anda cari?" aria-describedby="basic-addon2">
            <div class="input-group-append">
              <button class="btn btn-dark" type="submit"><span class="fa fa-search"></span> Cari</button>
            </div>
          </div>
        </form>
      </div>
    </div>
    <div class="py-md-5 py-4  px-0 px-lg-4">
      <ng-container *ngIf="!isLoading else Loading">
        <ng-container *ngFor="let row of dataSource">
          <div class="container-fluid mb-5 pb-5 border-bottom">
            <div class="row justify-content-center align-items-top pt-4 pb-3">
              <div class="col-md-3">
                <div class="px-2 pb-3">
                  <img [src]="row['gambar']" onerror="src='https\:\/\/cloudscene.global.ssl.fastly.net/CompanyLogo/200_4161.png';" alt="" class="border rounded w-100">
                </div>
              </div>
              <div class="col-md-9">
                <div class="pl-3">
                  <h2 class="stack_font2 h4 text-uppercase font-weight-bold">
                    {{row['title']}}
                  </h2>
                  <div class="stack_font1 pt-2">
                    <div class="row">
                      <div class="col-md-6 mb-3">
                        <span class="far fa-handshake"></span>
                        &nbsp;Project : {{row['project_name']}}
                      </div>
                      <div class="col-md-6 mb-3">
                        <span class="far fa-building"></span>
                        &nbsp; Operator : {{row['operator']}}
                      </div>
                      <div class="col-md-6 mb-3">
                        <span class="far fa-calendar-alt"></span>
                        &nbsp; Project Start : {{row['start_project']}}
                      </div>
                      <!-- <div class="col-md-6 mb-3">
                        <span class="fas fa-calendar-alt"></span>
                        &nbsp; Project End : 28 September 2019
                      </div> -->
                    </div>
                  </div>
                </div>
                <div class="row no-gutters bg-dark text-white">
                  <div class="col-12 px-3 py-2">
                    Posisi Pekerjaan yang Tersedia
                  </div>
                </div>
                <ng-container *ngIf="row.detail else NotFound">
                  <ng-container *ngFor='let detail of row.detail; let ind = index'>
                    <div class="row no-gutters border-bottom pt-3 pb-1 px-3" [ngClass]="(ind % 2 === 0) ? '' : 'bg-light'">
                      <div class="col-md-4 font-weight-bold pb-2">
                        {{detail.position}}
                      </div>
                      <div class="col-md-3 pb-2">
                        <span class="fas fa-briefcase"></span>
                        &nbsp; Vendor : {{detail.vendor}}
                      </div>
                      <div class="col-md-3 pb-2">
                        <span class="fas fa-sitemap"></span>
                        &nbsp; Level : {{detail.level}}
                      </div>
                      <div class="col-md-2 pb-2 text-left text-md-center">
                        <button class="btn btn-success btn-sm" (click)="open(detail.id, loginModal, detail)">Apply</button>
                      </div>
                    </div>
                  </ng-container>
                </ng-container>                
              </div>
            </div>
            <div class="modal fade" tabindex="-1" role="dialog">
              <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
                <div class="modal-content">
                  <div class="modal-body">
                    <img [src]="row['gambar']" onerror="src='https\:\/\/cloudscene.global.ssl.fastly.net/CompanyLogo/200_4161.png';" alt="" class="rounded w-100">
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ng-container>
      </ng-container>      
    </div>
    <div class="py-md-5 py-4  px-0 px-lg-4">
      <nav aria-label="Page navigation" class="pb-5 bg-white">
        <app-pagination-common [paginateOption]="paging" [pageSizeOption]="pageSizeOption"
          (currentPageSizeEvent)="getCurrentPageSize($event)" (currentPageEvent)="getCurrentPage($event)">
        </app-pagination-common>
      </nav>
    </div>    
  </div>
</div>

<!-- Loading Template -->
<ng-template #Loading>
  <div class="text-center">
    <img src="/assets/media/loader/timeline.svg">
  </div>
</ng-template>

<!-- Not Found Template -->
<ng-template #NotFound>
  <div class="row no-gutters border-bottom pt-3 pb-1 px-3">
    <div class="col-md-12 font-weight-bold pb-2">
      Belum Ada Posisi yang Tersedia
    </div>
  </div>
</ng-template>

<!-- Login Template -->
<ng-template #loginModal let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Login</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <form id="loginForm" #loginForm="ngForm" (ngSubmit)="submit(loginForm, modal)">

      <div class="row">
        <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4 text-center">
          <div class="form-group">
            <label for="username"><strong>Position</strong></label>
            <div class="text-center">{{selectedVacancy?.position}}</div>
          </div>
        </div>
        <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4 text-center">
          <div class="form-group">
            <label for="username"><strong>Vendor</strong></label>
            <div class="text-center">{{selectedVacancy?.vendor}}</div>
          </div>
        </div>
        <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4 text-center">
          <div class="form-group">
            <label for="username"><strong>Level</strong></label>
            <div class="text-center">{{selectedVacancy?.level}}</div>
          </div>
        </div>
      </div>

      <hr>

      <div class="form-group">
        <label for="username">Username</label>
        <div class="input-group">
          <input type="text" id="username" class="form-control" name="username" [(ngModel)]="login_form.username">
        </div>
      </div>
      <div class="form-group">
        <label for="password">Password</label>
        <div class="input-group">
          <input type="password" id="password" class="form-control" name="password" [(ngModel)]="login_form.password">
        </div>
      </div>
      <div *ngIf="isSubmited" class="text-center">
        <img src="/assets/media/loader/timeline.svg" [style.height.px]="37">
      </div>

      <div *ngIf="showError" id="loginError" class="alert alert-danger">{{loginError}}</div>

    </form>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-primary" (click)="submit(loginForm, modal)"> Login</button>
    <button type="button" class="btn btn-light" (click)="modal.dismiss('cancel')"> Cancel</button>
  </div>
</ng-template>