import { Component, OnInit, Renderer2 } from '@angular/core';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { NgxPermissionsService } from 'ngx-permissions';
import { AuthenticationService } from 'src/app/auth/authentication.service';
import { UserService } from 'src/app/_services/user.service';
import { LayoutService } from 'src/app/_services/layout.service';
import { CookieService } from 'ngx-cookie-service';
import { MessagingService } from 'src/app/_services/messaging.service';
import { AttendanceService } from 'src/app/_services/attendance.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss']
})
export class MainComponent implements OnInit {
  customModuleName: string = '';
  currentUser: any = {};
  onModulePage: boolean;
  onModuleApprovalPage: boolean = false;
  subheaderShown: boolean = true;
  needHideSubheader: string[] = [
    'site_survey',
    'fo_productivity',
    'ion-project',
    'hse',
    'carrepair',
    'project-request'
  ];
  needShowSubheader: string[] = [
    'pmc'
  ];
  currentModule: string;
  currentPage: string;
  partialRender: boolean = false;
  getModuleFullScreenObs = false;
  
  constructor(
    private router: Router,
    private permissionsService: NgxPermissionsService,
    private authenticationService: AuthenticationService,
    private userService: UserService,
    private layoutService: LayoutService,
    private messagingService: MessagingService,
    private renderer: Renderer2,
    private attendanceService: AttendanceService,
    private cookieService: CookieService
  ) {
    this.userService.getAccessToken().subscribe(token => {
      if (token) {
        const expiry = (JSON.parse(atob(token.split('.')[1]))).exp;
        if ((Math.floor((new Date).getTime() / 1000)) >= expiry) {
          this.authenticationService.logout();
        }
      }
    });

    let roles2 = this.authenticationService.getUserRoles('employeeProfile');
    if (roles2) {
      this.authenticationService.loadPermission(roles2);
    }
    else {
      this.authenticationService.getRolesToken({ module: 'employeeProfile' }).subscribe(
        response => {
          if (response.status) {
            let roles2 = this.authenticationService.getUserRoles('employeeProfile');
            if (roles2) {
              this.authenticationService.loadPermission(roles2);
            }
          }
        }
      );
    }


    let roles3 = this.authenticationService.getUserRoles('attendance');
    if (roles3) {
      this.authenticationService.loadPermission(roles3);
    }
    else {
      this.authenticationService.getRolesToken({ module: 'attendance' }).subscribe(
        response => {
          if (response.status) {
            let roles3 = this.authenticationService.getUserRoles('attendance');
            if (roles3) {
              this.authenticationService.loadPermission(roles3);
            }
          }
        }
      );
    }

    let group: any[];
    if (this.userService.getUserGroup()) {
      group = this.userService.getUserGroup();
    } else {
      group = [];
    }
    group.push('USER');
    this.permissionsService.addPermission(group);

    this.layoutService.menuModuleEmitObs.subscribe(
      res => {
        this.onModulePage = res;
      }
    );

    this.layoutService.setModuleFullScreenObs.subscribe(
      res => {
        this.partialRender = res;
      }
    );

    this.router.events.subscribe((val) => {
      if (val instanceof NavigationEnd) {
        const arr_url = this.router.url.split('/');
        this.currentModule = arr_url[2];
        this.currentPage = arr_url[3] ? arr_url[3] : '';
        if (arr_url[2] === 'modules') {
          this.onModulePage = true;
          this.layoutService.menuModuleEmit.next(this.onModulePage);

          this.onModuleApprovalPage = false;
        } else if (arr_url[2] === 'modules-approval') {
          this.onModuleApprovalPage = true;

          this.onModulePage = false;
          this.layoutService.menuModuleEmit.next(this.onModulePage);
        } else {
          this.onModulePage = false;
          this.layoutService.menuModuleEmit.next(this.onModulePage);

          this.onModuleApprovalPage = false;

          if (this.needShowSubheader.indexOf(arr_url[2]) > -1) {
            this.subheaderShown = true;
            if (!document.body.classList.contains('kt-subheader--fixed')) {
              this.renderer.addClass(document.body, 'kt-subheader--fixed');
            }
            if (!document.body.classList.contains('kt-subheader--enabled')) {
              this.renderer.addClass(document.body, 'kt-subheader--enabled');
            }
            if (!document.body.classList.contains('kt-subheader--solid')) {
              this.renderer.addClass(document.body, 'kt-subheader--solid');
            }
          }
          else {
            this.subheaderShown = false;
            if (document.body.classList.contains('kt-subheader--fixed')) {
              this.renderer.removeClass(document.body, 'kt-subheader--fixed');
            }
            if (document.body.classList.contains('kt-subheader--enabled')) {
              this.renderer.removeClass(document.body, 'kt-subheader--enabled');
            }
            if (document.body.classList.contains('kt-subheader--solid')) {
              this.renderer.removeClass(document.body, 'kt-subheader--solid');
            }
          }
        }

        if (this.currentModule === 'tsel-online' || this.currentModule === 'huaweiresource' || this.currentPage.includes('print-qr-web')
            || (this.currentModule === 'costallocation' && (this.currentPage === 'all-list' || this.currentPage === 'list' || this.currentPage === 'confirmation' || this.currentPage === 'resolve' ||  this.currentPage === 'projectmanagement') )
           ) 
        {
          this.partialRender = true;
        } else {
          this.partialRender = false;
        }
      }
    });

  }

  ngOnInit() {
    let payload = this.userService.payloadToken();
    this.currentUser = payload.data;
    this.authenticationService.getPermission('employee');
    this.authenticationService.getPermission('documentation');
    this.authenticationService.getPermission('whistleBlowing');
    this.getMessage();


    // logout jika token expired;
    if (payload.exp < Date.now() / 1000) {
      this.authenticationService.logout();
    }

    const attendanceCheck = this.cookieService.get('attendanceCheck');

    if (attendanceCheck != this.dateYMD() && this.currentUser.user_ptnw_status == 'Internal') {
      this.attendanceService.checkAttendance().subscribe(response => {

        if (response.status) {
          if (!response.result && this.currentUser.user_ptnw_status == 'Internal') {
            this.cookieService.set('attendanceCheck', this.dateYMD());
            Swal.fire('Anda belum melakukan presensi pada hari ini');
          }
        }
      }, error => {
        if (error.error.message == 'Session expired') {
          this.authenticationService.logout();
        }
      }
      );
    }

    this.authenticationService.checkPasswordExp().subscribe(
      response => {
        if (response.status) {
          if (response.result === 1) {
            this.router.navigateByUrl('/change-password');
          }
        }
      }
    );

    if (this.currentUser.userid = 2087) {

      let roles4 = this.authenticationService.getUserRoles('employeeAdmin');
      if (roles4) {
        this.authenticationService.loadPermission(roles4);
      }
      else {
        this.authenticationService.getRolesToken({ module: 'employeeAdmin' }).subscribe(
          response => {
            if (response.status) {
              let roles4 = this.authenticationService.getUserRoles('employeeAdmin');
              if (roles4) {
                this.authenticationService.loadPermission(roles4);
              }
            }
          }
        );
      }
    }

  }

  getMessage() {
    const userId = this.userService.getUsername();
    this.messagingService.requestPermission(userId);
    this.messagingService.receiveMessage();
  }

  dateYMD() {
    let d = new Date();
    let year, month, day;
    year = String(d.getFullYear());
    month = String(d.getMonth() + 1);
    if (month.length == 1) {
      month = "0" + month;
    }
    day = String(d.getDate());
    if (day.length == 1) {
      day = "0" + day;
    }
    return year + "-" + month + "-" + day;
  }
}
