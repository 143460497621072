import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { GlobalService } from 'src/app/_services/global.service';

@Component({
  selector: 'app-alert-modal',
  templateUrl: './alert-modal.component.html',
  styleUrls: ['./alert-modal.component.scss']
})
export class AlertModalComponent {

  @Input() kategori: 'alert' | 'confirm' | 'history' | 'confirm_prepaid' | 'confirm_blog';

  // LIST Komponen Instance
  // --> ALERT => error
  // --> CONFIRM => title, data_category, data_name
  // --> HISTORY => title, data_history[], setPipe

  constructor(
    public modal: NgbActiveModal,
    public globalHost: GlobalService,
  ) {}

}

@Component({
  selector: 'app-alert-modal',
  templateUrl: './alert-modal.component.html',
  styleUrls: ['./alert-modal.component.scss']
})
export class ConfirmModalComponent implements OnInit { // Masih dipakai Don't Delete

  kategori = 'confirm';

  constructor(public modal: NgbActiveModal) {}

  ngOnInit() {
  }

}
