import { Component, Input, OnInit, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { Ng2ImgMaxService } from 'ng2-img-max';
import { UploadService } from 'src/app/_services/upload.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-gcs-upload',
  templateUrl: './gcs-upload.component.html',
  styleUrls: ['./gcs-upload.component.scss']
})
export class GcsUploadComponent implements OnInit {
  @Input() fileType: string;
  @Input() prefix: string;
  @Input() acceptFile?: string[]; // contoh ['image/jpg','image/jpeg','image/png']
  @Input() remark?: string;
  @Input() cropper: boolean = false;
  @Input() module: string = null;
  @Input() tableTarget: string = null;
  @Input() columnTarget: string = null;
  @Input() maxLengthFilename: number = 200;
  @Input() filesizeMaximum?: number; //dalam byte
  @Input() filepixelMaximum?: number; //dalam pixel
  @Input() labelInput: string = 'Choose file';
  @Input() autoReset: boolean = false;
  @Input() isPublicFile: boolean = false;
  @Input() theme: 'default' | 'small' = 'default';

  selectedFiles: FileList;
  progress: number;
  isFinish: boolean;
  errorMime: boolean;
  uploadSuccess: boolean;
  compressedFile: any;

  uncommonFiletype: any = {
    jpeg: 'image/jpeg',
    jpg: 'image/jpeg',
    png: 'image/png',
    pdf: 'application/pdf',
    doc: 'application/msword',
    docx: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    ppt: 'application/vnd.ms-powerpoint',
    pptx: 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
    xls: 'application/vnd.ms-excel',
    xlsx: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    txt: 'text/plain',
    zip: 'application/x-zip-compressed',
    csv: 'text/plain',
    mp4: 'video/mp4',
  };

  @Output() success = new EventEmitter<any>();
  @Output() afterCrop = new EventEmitter<any>();
  @Output() change = new EventEmitter<FileList>();

  @ViewChild('inputFile', { static: false })
  inputFileVariable: ElementRef;

  @ViewChild('content', { static: false }) content: any;

  imageChangedEvent: any = '';
  croppedImage: any = '';

  constructor(
    private uploadService: UploadService,
    private maxPixelService: Ng2ImgMaxService,
  ) {
    this.uploadService.progressEmitObs.subscribe(
      res => {
        this.progress = res;
      }
    );
  }

  ngOnInit() {
  }

  uniqueTime(): string {
    var d = new Date(),
      newMonth = '' + (d.getMonth()),
      newDay = '' + d.getDate(),
      newYear = '' + d.getFullYear(),
      newHours = '' + d.getHours(),
      newMinutes = '' + d.getMinutes(),
      newSeconds = '' + d.getSeconds(),
      newMiliSeconds = '' + d.getMilliseconds();

    if (newMonth.length < 2) newMonth = '0' + newMonth;
    if (newDay.length < 2) newDay = '0' + newDay;

    return '' + newYear + newMonth + newDay + newHours + newMinutes + newSeconds + newMiliSeconds;
  }

  checkLengthFilename(event) {
    this.selectedFiles = event.target.files;
    const file = this.selectedFiles.item(0);
    if (file.name.length > this.maxLengthFilename) {
      return false;
    }
    else {
      return true;
    }
  }

  changePixel(file: any) {

    let maxWidth: number;
    let maxHeight: number;
    console.log('size File sebelum compress: ' + file[0].size);
    let fr = new FileReader();
    fr.onload = () => { // when file has loaded
      let img = new Image();
      img.onload = () => {
        let pixelImg = img.width * img.height;
        if (this.filepixelMaximum < pixelImg) {
          maxWidth = (img.width * this.filepixelMaximum) / pixelImg;
          maxHeight = (img.height * this.filepixelMaximum) / pixelImg;
        }
      };
    };
    let image = file[0];
    this.maxPixelService.resizeImage(image, maxWidth, maxHeight).subscribe(
      result => {
        this.compressedFile = result;
        console.log('size File setelah compress: ' + this.compressedFile.size);
      },
      error => {
        console.log('😢 Oh no!', error);
      }
    );

    this.compressedFile;
  }

  mimeChecker(file: File) {
    let parseFilename = file.name.split('.');
    let extention = parseFilename[parseFilename.length - 1];
    let tempType = file.type.split('/')[1];
    let result = false;

    if (!this.acceptFile) {
      this.fileType = extention;
      return true;
    }

    for (let i = 0; i < this.acceptFile.length; i++) {
      // let accExt: string = this.acceptFile[i].trim(); //accExt.split('.')[1];
      this.fileType = this.acceptFile[i].trim();
      if (this.fileType == file.type || this.fileType === tempType) {
        result = true;
        break;
      }
      else if (extention == this.fileType && this.uncommonFiletype[this.fileType] == file.type) {
        result = true;
        break;
      }
      else if (this.fileType.indexOf(extention) != -1) {
        result = true;
        break;
      }
    }

    return result;
  }

  uploadFile(param: any, file: File) {
    this.uploadService.putFileGcs(param.signedUploadURL, file).subscribe(
      event => {
        if (event.status == 'done') {
          this.uploadService.complateUploadObjGcs({ prefix: this.prefix, filename: this.labelInput}).subscribe(
            response => {
              if (response.status){
                this.isFinish = true;
                this.uploadSuccess = true;
                this.success.emit(response.result);
                if (this.autoReset) {
                  this.reset();
                }
              }
            }
          );
        }
      }
    );
  }

  bytesToSize(bytes: number) {
    var sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
    if (bytes == 0) {
      return '0 Byte';
    }
    else {
      var i = Math.floor(Math.log(bytes) / Math.log(1024));
      return Math.round(bytes / Math.pow(1024, i)) + ' ' + sizes[i];
    }
  }

  selectFile(event) {
    this.change.emit(event.target.files);

    let objFile: any;

    let filesize = event.target.files[0].size;
    if ((this.filesizeMaximum && filesize <= this.filesizeMaximum) || (!this.filesizeMaximum)) {
      if (this.checkLengthFilename(event)) {
        this.progress = 0;
        this.isFinish = false;
        this.errorMime = false;
        this.uploadSuccess = false;
        if (this.filepixelMaximum) {
          objFile = this.changePixel(event.target.files);
        } else {
          objFile = event.target.files[0];
        }

        const file = objFile;
        if (file) {
          if (this.mimeChecker(file)) {
            let _filename = file.name;
            let filename = _filename.split('.').slice(0, -1).join('_');
            let ext = _filename.split('.').pop();
            let temp_filename = filename.replace(/[\W\s\n]/gm, "");
            this.labelInput = this.uniqueTime() + '_' + temp_filename + '.' + ext;
            const fileSize = file.size;

            this.uploadService.getPresignedUrlGcs({
              prefix: this.prefix,
              filename: this.labelInput,
              mime: this.fileType,
              size: fileSize,
              module: this.module,
              tableTarget: this.tableTarget,
              columnTarget: this.columnTarget,
              isPublicFile: this.isPublicFile
            }).subscribe(
              arg => {
                if (arg.status) {
                  this.uploadFile(arg.result, file);
                }
              }
            );
          } else {
            this.isFinish = undefined;
            this.progress = 0;
            this.selectedFiles = undefined;
            this.inputFileVariable.nativeElement.value = '';
            this.errorMime = true;
          }
        }
      }
      else {
        Swal.fire('Max length filename is ' + this.maxLengthFilename);
        this.progress = 0;
        this.isFinish = false;
        this.errorMime = false;
        this.uploadSuccess = false;
        this.selectedFiles = undefined;
      }
    }
    else {
      Swal.fire({
        title: 'File Input',
        type: 'info',
        text: 'Ukuruan file tidak boleh lebih dari : ' + this.bytesToSize(this.filesizeMaximum)
      });
    }
  }

  reset() {
    this.inputFileVariable.nativeElement.value = "";
    this.uploadSuccess = false;
    this.labelInput = 'Choose file';
  }

}
