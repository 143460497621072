export class MenuConfig {
  public config: any = {};

  constructor() {
    this.config = {
      header: [
      ],
      aside: [
        {
          module: 'home',
          items: []
        },
        {
          module: 'search',
          items: []
        },
        {
          module: 'modules',
          items: []
        },
        {
          module: 'modules-approval',
          items: []
        },
        {
          module: 'authorization',
          items: []
        },
        {
          module: 'summary',
          items: []
        },
        {
          module: 'employee',
          items: [
            {
              title: 'Add Employee',
              permissions: ['employee/hrd/add-employee'],
              keyRoute: 'add',
              route: ['/main/employee/add'],
              icon: 'fa fa-user-plus'
            },
            {
              title: 'List Employee',
              permissions: ['employee/hrd/get-users'],
              keyRoute: 'all',
              route: ['/main/employee/all'],
              icon: 'fa fa-grip-vertical'
            },
            {
              title: 'List All Employee',
              permissions: ['employeeAdmin/dev/list-all'],
              keyRoute: 'list-all',
              route: ['/main/employee/list-all'],
              icon: 'fa fa-list-alt'
            },
            {
              title: 'MT / DW',
              permissions: ['employee/hrd/list-mtdw'],
              keyRoute: 'mt-dw',
              route: ['/main/employee/mt-dw/index'],
              icon: 'fa fa-user-secret'
            },
            {
              title: 'Mitra Kerja',
              permissions: ['employee/hrd/list-mtdw'],
              keyRoute: 'mitra-kerja',
              route: ['/main/employee/mitra-kerja/index'],
              icon: 'fa fa-user-secret'
            },
            {
              title: 'Employee Notice Period',
              permissions: ['employee/hrd/get-notice-period-list'],
              keyRoute: 'notice-period',
              route: ['/main/employee/notice-period'],
              icon: 'fa fa-business-time'
            },
            {
              title: 'Employee Resign',
              permissions: ['employee/hrd/resign'],
              keyRoute: 'resign-list',
              route: ['/main/employee/resign-list'],
              icon: 'fa fa-list'
            },
            {
              title: 'Employee History',
              permissions: ['employee/hrd/list-history'],
              keyRoute: 'history',
              route: ['/main/employee/history'],
              icon: 'fa fa-list'
            },
            {
              title: 'Blacklist Employee',
              permissions: ['employee/blacklist/get_blacklist'],
              keyRoute: 'blacklist',
              route: ['/main/employee/blacklist'],
              icon: 'fa fa-user-slash'
            },
            {
              title: 'Employee Profile',
              permissions: ['USER'],
              keyRoute: 'employee-profile',
              route: ['/main/employee/employee-profile'],
              icon: 'fa fa-user'
            },
            {
              title: 'Employee Receiveble',
              permissions: ['employee/piutang/list-receiveble'],
              keyRoute: 'employee-receiveble',
              route: ['/main/employee/receiveble'],
              icon: 'fa fa-user'
            },
            {
              title: 'PM Update',
              permissions: ['employee/hrd/submit-pm-update'],
              keyRoute: 'pm-update',
              route: ['/main/employee/pm-update'],
              icon: 'fa fa-male'
            },
            {
              title: 'TL Update',
              permissions: ['employee/hrd/submit-tl-update'],
              keyRoute: 'tl-update',
              route: ['/main/employee/tl-update'],
              icon: 'fa fa-chalkboard-teacher'
            },
            // {
            //   title: 'PM Movement',
            //   permissions: ['employee/hrd/submit-pm-movement'],
            //   keyRoute: 'pm-movement',
            //   route: ['/main/employee/pm-movement'],
            //   icon: 'fa fa-male'
            // },
            // {
            //   title: 'TL Movement',
            //   permissions: ['employee/hrd/submit-tl-movement'],
            //   keyRoute: 'tl-movement',
            //   route: ['/main/employee/tl-movement'],
            //   icon: 'fa fa-chalkboard-teacher'
            // },
            // {
            //   title: 'Movement Tracker',
            //   permissions: ['USER'],
            //   keyRoute: 'employee-movement',
            //   route: ['/main/employee/employee-movement'],
            //   icon: 'fa fa-book'
            // },
            // {
            //   title: 'Finished Movement Tracker',
            //   permissions: ['USER'],
            //   keyRoute: 'employee-movement-finish',
            //   route: ['/main/employee/employee-movement-finish'],
            //   icon: 'fa fa-book'
            // },
            {
              title: 'Export Movement',
              permissions: ['employee/hrd/list-data-movement'],
              keyRoute: 'exportmovement',
              route: ['/main/employee/exportmovement'],
              icon: 'fa fa-calendar-alt'
            },
            {
              title: 'Tracker Movement',
              permissions: ['USER'],
              keyRoute: 'tracker-movement',
              route: ['/main/employee/tracker-movement'],
              icon: 'fa fa-clipboard-list'
            },
            {
              title: 'Tracker Movement Finished',
              permissions: ['USER'],
              keyRoute: 'tracker-movement-finish',
              route: ['/main/employee/tracker-movement-finish'],
              icon: 'fa fa-clipboard-check'
            },
            {
              title: 'My Approval Movement',
              permissions: ['USER'],
              keyRoute: 'tracker-approval-movement',
              route: ['/main/employee/tracker-approval-movement'],
              icon: 'fa fa-thumbs-up'
            },
            {
              title: 'Export Resign',
              permissions: ['employee/hrd/list-export-resign'],
              keyRoute: 'exportresign',
              route: ['/main/employee/exportresign'],
              icon: 'fa fa-calendar-alt'
            },
            {
              title: 'Export Vaccine',
              permissions: ['employee/hrd/list-export-vaccine'],
              keyRoute: 'exportvaccine',
              route: ['/main/employee/exportvaccine'],
              icon: 'fa fa-calendar-alt'
            },
            {
              title: 'Reminder Contract End',
              permissions: ['employee/users/get-reminder'],
              keyRoute: 'reminder',
              route: ['/main/employee/reminder'],
              icon: 'fa fa-bell'
            },
            {
              title: 'Approval Employee',
              permissions: ['employee/users/list-approval-employee'],
              keyRoute: 'approval-employee',
              route: ['/main/employee/approval-employee'],
              icon: 'fa fa-file-signature'
            },
            {
              title: 'CV Download',
              permissions: ['employeeAdmin/dev/list-cvby-projectcode'],
              keyRoute: 'download-cv',
              route: ['/main/employee/download-cv'],
              icon: 'fa fa-file-pdf'
            },
            {
              title: 'Bank Account',
              permissions: ['payslip/bank-account/get-data'],
              keyRoute: 'bank-account',
              route: ['/main/employee/bank-account'],
              icon: 'fa fa-dollar-sign'
            },
          {
              title: 'Find Employee',
              permissions: ['employee/data/find'],
              keyRoute: 'find',
              route: ['/main/employee/find'],
              icon: 'fa fa-users'
            },
            {
              title: 'Nw Assignment & Position',
              permissions: ['employeeAdmin/dev/list-nw-assignment', 'employeeAdmin/dev/list-position'],
              keyRoute: 'index',
              route: ['/main/employee/nw-assignment-and-position/index'],
              icon: 'fa fa-user-tie'
            },
            {
              title: 'Organization Chart',
              permissions: ['employee/org-chart/get-tree'],
              keyRoute: 'org-chart',
              route: ['/main/employee/org-chart'],
              icon: 'fa fa-users'
            },
            {
              title: 'Organization Chart Official',
              permissions: ['employee/org-chart/get-tree-official'],
              keyRoute: 'org-chart-official',
              route: ['/main/employee/org-chart-official'],
              icon: 'fa fa-users'
            },
            {
              title: 'Field Ops Productivity',
              permissions: ['productivity/productivity/get-data'],
              keyRoute: 'field-ops-productivity',
              route: ['/main/employee/field-ops-productivity'],
              icon: 'fa fa-users'
            },
            {
              title: 'Submit Resign RFS',
              permissions: ['employee/hrd/submit-resign'],
              keyRoute: 'submit-resign',
              route: ['/main/employee/submit-resign'],
              icon: 'fa fa-users'
            },
            {
              title: 'Hr Achievement',
              permissions: ['employee/hrd/hr-achievement'],
              keyRoute: 'hr-achievement',
              route: ['/main/employee/hr-achievement'],
              icon: 'fa fa-chart-bar'
            },
            // {
            //   title: 'Vaccine Data',
            //   permissions: ['employee/hrd/vaccine-data'],
            //   keyRoute: 'vaccine',
            //   route: ['/main/employee/vaccine'],
            //   icon: 'fa fa-syringe'
            // }
          ]
        },
        {
          module: 'pr',
          items: [
            {
              title: 'Dashboard',
              permissions: ['USER'],
              keyRoute: 'dashboard',
              route: ['/main/pr'],
              icon: 'fa fa-chart-bar'
            },
            {
              title: 'Dashboard TI',
              permissions: ['USER'],
              keyRoute: 'dashboard-ti',
              route: ['/main/pr/dashboard-ti'],
              icon: 'fa fa-chart-bar'
            },
            {
              title: 'Dashboard End To End',
              permissions: ['USER'],
              keyRoute: 'dashboard-end-to-end',
              route: ['/main/pr/dashboard-end-to-end'],
              icon: 'fa fa-chart-bar'
            },
            {
              title: 'All PO Request',
              permissions: ['pr/default/get-pr'],
              keyRoute: 'tracker',
              route: ['/main/pr/tracker/index'],
              icon: 'fa fa-send'
            },
            {
              title: 'My Approval',
              permissions: [
                'pr/default/list-my-approval',
                'pr/default/process-approval'
              ],
              keyRoute: 'my-approval',
              route: ['/main/pr/my-approval'],
              icon: 'fa fa-thumbs-up'
            },
            {
              title: 'My Request',
              permissions: ['pr/default/list-my-request'],
              keyRoute: 'my-request',
              route: ['/main/pr/my-request/index'],
              icon: 'fa fa-question'
            },
            {
              title: 'Quotation',
              // permissions: ['pr/quotation/quotation-list'],
              permissions: ['USER'],
              keyRoute: 'quotation-list',
              route: ['/main/pr/quotation'],
              icon: 'fa fa-cogs'
            },
            {
              title: 'PR Assignment',
              permissions: ['pr/manage/region-request'],
              keyRoute: 'region-request',
              route: ['/main/pr/region-request'],
              icon: 'fa fa-question'
            },
            {
              title: 'Finished',
              permissions: ['pr/default/list-finished'],
              keyRoute: 'finished',
              route: ['/main/pr/finished/index'],
              icon: 'fa fa-check-square'
            },
            {
              title: 'Vendor',
              permissions: ['pr/default/list-vendor-pr'],
              keyRoute: 'vendor',
              route: ['/main/pr/vendor/index'],
              icon: 'fa fa-university'
            },
            {
              title: 'PR Step',
              permissions: ['pr/manage/list-step'],
              keyRoute: 'step-pr',
              route: ['/main/pr/step-pr/index'],
              icon: 'fa fa-sort-numeric-asc'
            },
            {
              title: 'PR Summary',
              permissions: ['USER'],
              keyRoute: 'pr-summary',
              route: ['/main/pr/pr-summary/index'],
              icon: 'fa fa-list'
            },
            {
              title: 'PR Reminder',
              permissions: [
                'pr/dev/get-outstanding-pic'
              ],
              keyRoute: 'outstanding-pic',
              route: ['/main/pr/outstanding-pic'],
              icon: 'fa fa-bullhorn'
            },
            {
              title: 'Approval Report',
              permissions: ['USER'],
              keyRoute: 'approval-report',
              route: ['/main/pr/approval-report'],
              icon: 'fa fa-calendar-alt'
            },
            {
              title: 'Outstanding Step',
              permissions: ['pr/default/get-pr-outstanding'],
              keyRoute: 'outstanding-step',
              route: ['/main/pr/outstanding-step'],
              icon: 'fa fa-calendar-alt'
            },
            {
              title: 'BC Assignment',
              permissions: ['pr/default/bc-assignment'],
              keyRoute: 'bc-assignment',
              route: ['/main/pr/tracker/bc-assignment'],
              icon: 'fa fa-send'
            },
          ]
        },
        {
          module: 'material-request',
          items: [
            {
              title: 'Dashboard',
              permissions: ['USER'],
              keyRoute: 'dashboard',
              route: ['/main/material-request'],
              icon: 'fa fa-chart-bar'
            },
            {
              title: 'Request',
              permissions: [
                'materialrequest/default/add-request'
              ],
              keyRoute: 'request',
              route: ['/main/material-request/request/index'],
              icon: 'fa fa-envelope'
            },
            {
              title: 'Approval',
              permissions: ['USER'],
              keyRoute: 'my-approval',
              route: ['/main/material-request/my-approval'],
              icon: 'fa fa-history'
            },
            {
              title: 'Summary',
              permissions: ['USER'],
              keyRoute: 'all-tracker',
              route: ['/main/material-request/all-tracker'],
              icon: 'fa fa-envelope'
            },
          ]
        },
        {
          module: 'material-request-v2',
          items: [
            {
              title: 'Dashboard',
              permissions: ['USER'],
              keyRoute: 'dashboard',
              route: ['/main/material-request-v2'],
              icon: 'fa fa-chart-bar'
            },
            {
              title: 'Material Stock',
              keyRoute: 'material-stock',
              permissions: ['USER'],
              route: ['/main/material-request-v2/material-stock'],
              icon: 'fa fa-list'
            },
            {
              title: 'Material Tracker',
              keyRoute: 'material-tracker',
              permissions: ['USER'],
              route: ['/main/material-request-v2/material-tracker'],
              icon: 'fa fa-rocket'
            },
            {
              title: 'Warehouse List',
              keyRoute: 'warehouse-tracker',
              permissions: ['Developer','PA FAT'],
              route: ['/main/material-request-v2/warehouse'],
              icon: 'fa fa-home'
            },
            {
              title: 'Request',
              // permissions: [
              //   'materialrequest/default/add-request'
              // ],
              keyRoute: 'request',
              route: ['/main/material-request-v2/request/index'],
              icon: 'fa fa-envelope'
            },
            {
              title: 'Approval',
              permissions: ['USER'],
              keyRoute: 'my-approval',
              route: ['/main/material-request-v2/my-approval'],
              icon: 'fa fa-history'
            },
            {
              title: 'Summary',
              permissions: ['USER'],
              keyRoute: 'all-tracker',
              route: ['/main/material-request-v2/all-tracker'],
              icon: 'fa fa-envelope'
            },
          
          ]
        },
        {
          module: 'arsip',
          items: [
            {
              title: 'Dashboard',
              permissions: ['USER'],
              keyRoute: 'dashboard',
              route: ['/main/arsip/dashboard'],
              icon: 'fa fa-chart-bar'
            },
            {
              title: 'All Tracker',
              permissions: ['USER'],
              keyRoute: 'all-tracker',
              route: ['/main/arsip/all-tracker'],
              icon: 'fa fa-envelope'
            },
            {
              title: 'My Approval',
              permissions: ['USER'],
              keyRoute: 'my-approval',
              route: ['/main/arsip/my-approval'],
              icon: 'fa fa-history'
            },
            {
              title: 'Konfigurasi',
              permissions: ['USER'],
              keyRoute: 'config',
              route: ['/main/arsip/config/index'],
              icon: 'fa fa-envelope'
            },
          ]
        },
        {
          module: 'activity',
          items: [
            {
              title: 'Dashboard',
              permissions: ['USER'],
              keyRoute: 'dashboard',
              route: ['/main/activity/dashboard'],
              icon: 'fa fa-chart-bar'
            },
            {
              title: 'All Tracker',
              permissions: ['USER'],
              keyRoute: 'all-tracker',
              route: ['/main/activity/all-tracker'],
              icon: 'fa fa-th-list'
            },
          ]
        },
        {
          module: 'simcard',
          items: [
            {
              title: 'Request Top Up Pulsa',
              permissions: ['simcard/default/get-all-prepaid'],
              keyRoute: 'index',
              route: ['/main/simcard/prepaid/index'],
              icon: 'fa fa-send'
            },
            {
              title: 'Reminder Top Up Pulsa',
              permissions: ['simcard/dev/topup-reminder'],
              keyRoute: 'topup-reminder',
              route: ['/main/simcard/prepaid/topup-reminder'],
              icon: 'fa fa-bullhorn'
            },
            {
              title: 'My Approval',
              permissions: ['simcard/default/get-all-prepaid'],
              keyRoute: 'my-approval',
              route: ['/main/simcard/prepaid/my-approval'],
              icon: 'fa fa-rocket'
            },
            {
              title: 'View History',
              permissions: ['simcard/default/finish'],
              keyRoute: 'view-history',
              route: ['/main/simcard/prepaid/view-history'],
              icon: 'fa fa-history'
            },
            {
              title: 'PIC Topup',
              permissions: ['simcard/pic/get-all-pic'],
              keyRoute: 'pic',
              route: ['/main/simcard/prepaid/pic'],
              icon: 'fa fa-user'
            },
            {
              title: 'User Phone Number',
              permissions: ['simcard/phonenumber/get-list-phone'],
              keyRoute: 'list',
              route: ['/main/simcard/phone-number/list'],
              icon: 'fa fa-address-book'
            },
            {
              title: 'Phone Number Approval',
              permissions: ['simcard/phonenumber/get-phone-approval'],
              keyRoute: 'phone-approval',
              route: ['/main/simcard/phone-number/phone-approval'],
              icon: 'fa fa-th-list'
            },
            // {
            //   title: 'Top Up Pulsa Summary',
            //   permissions: ['USER'],
            //   keyRoute: 'summary',
            //   route: ['/main/simcard/prepaid/summary'],
            //   icon: 'fa fa-th-list'
            // },
            // {
            //   title: 'Top Up Report / Rekap Data',
            //   permissions: ['USER'],
            //   keyRoute: 'report',
            //   route: ['/main/simcard/prepaid/report'],
            //   icon: 'fa fa-book'
            // }
            {
              title: 'Request by Admin',
              permissions: ['simcard/prepaid-admin/add-request'],
              keyRoute: 'admin-request',
              route: ['/main/simcard/prepaid/admin-request'],
              icon: 'fa fa-send'
            },
            {
              title: 'My Number on Request',
              permissions: ['simcard/default/my-number-request'],
              keyRoute: 'my-number-on-request',
              route: ['/main/simcard/prepaid/my-number-on-request'],
              icon: 'fa fa-book'
            },
            {
              title: 'View Project Code',
              permissions: ['USER'],
              keyRoute: 'view-project-code',
              route: ['/main/simcard/prepaid/view-project-code'],
              icon: 'fa fa-cogs'
            },
            {
              title: 'Finished Request Pulsa',
              permissions: ['USER'],
              keyRoute: 'finished-request',
              route: ['/main/simcard/prepaid/finished-request'],
              icon: 'fa fa-file-alt'
            },
            {
              title: 'All Request Pulsa',
              permissions: ['USER'],
              keyRoute: 'all-request',
              route: ['/main/simcard/prepaid/all-request'],
              icon: 'fa fa-clipboard-list'
            },
            {
              title: 'Pulsa Payment Tracker',
              permissions: ['USER'],
              keyRoute: 'tracker',
              route: ['/main/simcard/pulsa-payment/tracker'],
              icon: 'fa fa-file-invoice'
            },
            {
              title: 'Pulsa Payment Approval',
              permissions: ['USER'],
              keyRoute: 'tracker-approval',
              route: ['/main/simcard/pulsa-payment/tracker-approval'],
              icon: 'fa fa-file-invoice'
            },
            {
              title: 'Postpaid Payment Tracker',
              permissions: ['USER'],
              keyRoute: 'postpaid',
              route: ['/main/simcard/postpaid/index'],
              icon: 'fa fa-list'
            },
            {
              title: 'My Approval Postpaid Payment Tracker',
              permissions: ['USER'],
              keyRoute: 'my-approval',
              route: ['/main/simcard/postpaid/my-approval'],
              icon: 'fa fa-thumbs-up'
            },
          ]
        },
        {
          module: 'asset',
          items: [
            {
              title: 'Dashboard',
              keyRoute: 'dashboard',
              permissions: ['USER'],
              route: ['/main/asset/dashboard'],
              icon: 'fa fa-chart-bar'
            },
            {
              title: 'All Asset',
              keyRoute: 'index',
              permissions: ['asset/default/index-asset'],
              route: ['/main/asset/index'],
              icon: 'fa fa-book'
            },
            {
              title: 'Fixed Asset',
              keyRoute: 'fixed-asset',
              permissions: ['asset/default/fixed-asset'],
              route: ['/main/asset/fixed-asset'],
              icon: 'fa fa-laptop'
            },
            {
              title: 'CSM Tools',
              keyRoute: 'csmtools',
              permissions: ['asset/default/csmtool'],
              route: ['/main/asset/csmtools'],
              icon: 'fa fa-wrench'
            },
            {
              title: 'CSM CP Tools (New)',
              keyRoute: 'csmcptools',
              permissions: ['asset/default/csmcptool'],
              route: ['/main/asset/csm-cp'],
              icon: 'fa fa-vote-yea'
            },
            {
              title: 'My Team Assets',
              keyRoute: 'my-team-asset',
              // permissions: ['asset/default/csmtool'],
              route: ['/main/asset/my-team-asset'],
              icon: 'fa fa-list'
            },
            {
              title: 'Stock Opname On Employee',
              keyRoute: 'employee-asset',
              permissions: [
                // 'asset/pa/employee-asset'
                'USER'
              ],
              route: ['/main/asset/employee-asset'],
              icon: 'fa fa-user-cog'
            },
            {
              title: 'Verify Opname Summary',
              keyRoute: 'opname-verify',
              permissions: [
                'USER'
              ],
              route: ['/main/asset/opname-verify'],
              icon: 'fa fa-user-cog'
            },
            {
              title: 'Stock Opname On Warehouse',
              keyRoute: 'stock-on-warehouse',
              permissions: [
                // 'asset/pa/employee-asset'
                'USER'
              ],
              route: ['/main/asset/stock-on-warehouse'],
              icon: 'fa fa-user-cog'
            },
            {
              title: 'Stock Opname On Team',
              keyRoute: 'stock-opname-on-team',
              permissions: [
                'asset/dev/stock-opname-on-team'
              ],
              route: ['/main/asset/stock-opname-on-team'],
              icon: 'fa fa-user-cog'
            },
            {
              title: 'Summary',
              keyRoute: 'summary',
              permissions: ['asset/pa/get-summary'],
              route: ['/main/asset/summary'],
              icon: 'fa fa-chalkboard-teacher'
            },
            {
              title: 'Resigned with Asset',
              keyRoute: 'resign-summary',
              permissions: ['asset/pa/get-summary'],
              route: ['/main/asset/resign-summary'],
              icon: 'fa fa-exclamation'
            },
            {
              title: 'Asset Disposal',
              keyRoute: 'disposal',
              permissions: ['asset/dev/disposal'],
              route: ['/main/asset/disposal'],
              icon: 'fa fa-trash-alt'
            },
            {
              title: 'Sending Reminder',
              keyRoute: 'sendingreminder',
              permissions: ['asset/dev/sendingreminder'],
              route: ['/main/asset/sendingreminder'],
              icon: 'fa fa-envelope-open-text'
            },
            {
              title: 'Report Sending',
              keyRoute: 'report-sending',
              permissions: ['asset/default/get-report-sending'],
              route: ['/main/asset/report-sending'],
              icon: 'fa fa-envelope-open-text'
            },
            {
              title: 'Asset Stock Opname',
              keyRoute: 'stock-opname',
              permissions: ['USER'],
              route: ['/main/asset/stock-opname'],
              icon: 'fa fa-cubes'
            },
            {
              title: 'Asset Logbook',
              keyRoute: 'indexlog',
              permissions: ['asset/default/indexlog'],
              route: ['/main/asset/indexlog'],
              icon: 'fa fa-book-open'
            },
            {
              title: 'Asset Type',
              keyRoute: 'asset-type',
              permissions: ['asset/assettype/getassettype'],
              route: ['/main/asset/asset-type'],
              icon: 'fa fa-vote-yea'
            },
            {
              title: 'Asset Disposal Management',
              keyRoute: 'adf/tracker',
              permissions: ['adf/default/get-disposal-request'],
              route: ['/main/asset/adf/tracker/page/1'],
              icon: 'fa fa-trash'
            },
            {
              title: 'Asset Disposal Need Approval',
              keyRoute: 'adf/my-approval',
              permissions: ['adf/default/my-approval'],
              route: ['/main/asset/adf/my-approval/page/1'],
              icon: 'fa fa-trash'
            },
            {
              title: 'Asset Disposal Approval Report',
              keyRoute: 'adf/approval-report',
              permissions: ['adf/default/get-approval-report-disposal'],
              route: ['/main/asset/adf/approval-report'],
              icon: 'fa fa-file'
            },
            {
              title: 'Notice Period Reminder',
              keyRoute: 'notice-period-reminder',
              permissions: ['asset/dev/get-data-notice-period'],
              route: ['/main/asset/notice-period-reminder'],
              icon: 'fa fa-exclamation'
            },
            {
              title: 'Asset Receive Reminder',
              keyRoute: 'assigningasset',
              permissions: ['asset/pa/assigning-asset'],
              route: ['/main/asset/assigningasset'],
              icon: 'fa fa-send'
            },
            {
              title: 'Print QR',
              keyRoute: 'index',
              permissions: ['asset/default/index-asset'],
              route: ['/main/asset/print-qr'],
              icon: 'fa fa-print'
            },
            {
              title: 'Asset Justification Request',
              keyRoute: 'justification-asset-request',
              permissions: ['USER'],
              route: ['/main/asset/justification-asset-request'],
              icon: 'fa fa-calendar-day'
            },
            {
              title: 'Asset Justification Approval',
              keyRoute: 'justification-asset-approval',
              permissions: ['USER'],
              route: ['/main/asset/justification-asset-approval'],
              icon: 'fa fa-calendar-check'
            },
            {
              title: 'All Request Asset',
              keyRoute: 'request-asset-by-employee',
              permissions: ['requestAsset/default/index-request-asset'],
              route: ['/main/asset/request-asset-by-employee'],
              icon: 'fa fa-th'
            },
            {
              title: 'My Approval Request Asset',
              keyRoute: 'request-asset-by-employee-my-approval',
              permissions: ['requestAsset/default/my-approval-request-asset'],
              route: ['/main/asset/request-asset-by-employee-my-approval'],
              icon: 'fa fa-check'
            },
            {
              title: 'Report Stock Opname',
              keyRoute: 'report-stock-opname',
              permissions: ['USER'],
              route: ['/main/asset/report-stock-opname'],
              icon: 'fa fa-list'
            },
            {
              title: 'Asset DO',
              keyRoute: 'do',
              permissions: ['USER'],
              route: ['/main/asset/do'],
              icon: 'fa fa-list'
            },
            // {
            //   title: 'CSM CP Tracker',
            //   keyRoute: 'csm-cp-tracker',
            //   permissions: ['USER'],
            //   route: ['/main/asset/csm-cp-tracker'],
            //   icon: 'fa fa-list'
            // },
            // {
            //   title: 'CSM CP Stock',
            //   keyRoute: 'csm-cp-stock',
            //   permissions: ['USER'],
            //   route: ['/main/asset/csm-cp-stock'],
            //   icon: 'fa fa-list'
            // },
            // {
            //   title: 'CSM CP Disposal',
            //   keyRoute: 'csm-cp-adf',
            //   permissions: ['USER'],
            //   route: ['/main/asset/csm-cp-adf'],
            //   icon: 'fa fa-trash'
            // },
            // {
            //   title: 'CSM CP Log',
            //   keyRoute: 'csm-cp-log',
            //   permissions: ['USER'],
            //   route: ['/main/asset/csm-cp-log'],
            //   icon: 'fa fa-list'
            // },
            {
              title: 'Warehouse List',
              keyRoute: 'warehouse-tracker',
              permissions: ['Developer','PA FAT'],
              route: ['/main/material-request-v2/warehouse'],
              icon: 'fa fa-home'
            },
          ]
        },
        {
          module: 'advanceclearance',
          items: [
            {
              title: 'Dashboard',
              permissions: ['USER'],
              keyRoute: 'dashboard',
              route: ['/main/advanceclearance/dashboard'],
              icon: 'fa fa-chart-bar'
            },
            // {
            //   title: 'Add',
            //   permissions: ['advanceclearance/advanceclearance/createnew'],
            //   keyRoute: 'create',
            //   route: ['/main/advanceclearance/create-v2'],
            //   icon: 'fa fa-cart-plus'
            // },
            {
              title: 'Tracker',
              permissions: ['advanceclearance/advanceclearance/get-list-advance'],
              keyRoute: 'index',
              route: ['/main/advanceclearance/index'],
              icon: 'fa fa-book'
            },
            {
              title: 'My Approval',
              permissions: ['USER'],
              keyRoute: 'my-approval',
              route: ['/main/advanceclearance/my-approval'],
              icon: 'fa fa-thumbs-up'
            },
            // {
            //   title: 'History',
            //   permissions: ['USER'],
            //   keyRoute: 'history',
            //   route: ['/main/advanceclearance/history'],
            //   icon: 'fa fa-clock'
            // }
          ]
        },
        {
          module: 'advancesip',
          items: [
            {
              title: 'Dashboard',
              permissions: ['USER'],
              keyRoute: 'dashboard',
              route: ['/main/advancesip/dashboard'],
              icon: 'fa fa-chart-bar'
            },
            {
              title: 'Tracker Fund Request',
              permissions: ['advancesip/default-advance/get-requests'],
              keyRoute: 'tracker-req-fund',
              route: ['/main/advancesip/tracker-req-fund'],
              icon: 'fa fa-book'
            },
            {
              title: 'My Approval Fund Request',
              permissions: ['USER'],
              keyRoute: 'my-approval-req-fund',
              route: ['/main/advancesip/my-approval-req-fund'],
              icon: 'fa fa-thumbs-up'
            },
            {
              title: 'Tracker NY Clearance',
              permissions: ['advancesip/default-advance/get-requests-clearance-yet'],
              keyRoute: 'tracker-req-ny-clearance',
              route: ['/main/advancesip/tracker-req-ny-clearance'],
              icon: 'fa fa-book'
            },
            {
              title: 'Tracker Clearance',
              permissions: ['advancesip/default-clearance/get-requests'],
              keyRoute: 'tracker-clearance',
              route: ['/main/advancesip/tracker-clearance'],
              icon: 'fa fa-book'
            },
            {
              title: 'Dana Kasuistik',
              permissions: ['USER'],
              keyRoute: 'dana-kasuistik',
              route: ['/main/advancesip/dana-kasuistik'],
              icon: 'fa fa-book'
            },
            {
              title: 'My Approval Clearance',
              permissions: ['USER'],
              keyRoute: 'my-approval-clearance',
              route: ['/main/advancesip/my-approval-clearance'],
              icon: 'fa fa-thumbs-up'
            },
            {
              title: 'Tracker Advance Floating',
              permissions: ['advancesip/default/get-requests'],
              keyRoute: 'tracker-advance-floating',
              route: ['/main/advancesip/tracker-advance-floating'],
              icon: 'fa fa-book'
            },
            {
              title: 'My Approval Advance Floating',
              permissions: ['USER'],
              keyRoute: 'my-approval-advance-floating',
              route: ['/main/advancesip/my-approval-advance-floating'],
              icon: 'fa fa-thumbs-up'
            },
            {
              title: 'Tracker SIP Budget',
              permissions: ['advancesip/default-sip-budget/get-requests'],
              keyRoute: 'tracker-sip',
              route: ['/main/advancesip/tracker-sip'],
              icon: 'fa fa-book'
            },
            {
              title: 'My Approval SIP Budget',
              permissions: ['USER'],
              keyRoute: 'my-approval-sip',
              route: ['/main/advancesip/my-approval-sip'],
              icon: 'fa fa-thumbs-up'
            },
            {
              title: 'Report SIP',
              permissions: ['USER'],
              keyRoute: 'report-sip',
              route: ['/main/advancesip/report-sip'],
              icon: 'fa fa-file-contract'
            },
            {
              title: 'Summary SIP',
              permissions: ['USER'],
              keyRoute: 'summary-sip',
              route: ['/main/advancesip/summary-sip'],
              icon: 'fa fa-file-contract'
            },
            {
              title: 'Bank Account',
              permissions: ['Bank SIP Management', 'Finance View SIP'],
              keyRoute: 'bank-account',
              route: ['/main/advancesip/bank-account'],
              icon: 'fa fa-dollar-sign'
            },
            {
              title: 'Import Bank Statement',
              permissions: ['Bank SIP Management', 'Finance View SIP'],
              keyRoute: 'import-bank-statement',
              route: ['/main/advancesip/import-bank-statement'],
              icon: 'fa fa-comments-dollar'
            },
            {
              title: 'Tracker Bank Statement',
              permissions: ['Bank SIP Management', 'Finance View SIP'],
              keyRoute: 'tracker-bank-statement',
              route: ['/main/advancesip/tracker-bank-statement'],
              icon: 'fa fa-comments-dollar'
            },
            {
              title: 'Tracker Allowance',
              permissions: ['Bank SIP Management', 'Finance View SIP'],
              keyRoute: 'tracker-allowance',
              route: ['/main/advancesip/tracker-allowance'],
              icon: 'fa fa-dollar-sign'
            }
          ]
        },
        {
          module: 'exit_clearance',
          items: [
            {
              title: 'Dashboard',
              permissions: ['USER'],
              keyRoute: 'dashboard',
              route: ['/main/exit_clearance/dashboard'],
              icon: 'fa fa-chart-bar'
            },
            {
              title: 'Interview Exit',
              permissions: ['exitclearance/index/all-data'],
              keyRoute: 'interview-exit',
              route: ['/main/exit_clearance/interview-exit/index'],
              icon: 'fa fa-file'
            },
            {
              title: 'Recent Interview Exit',
              permissions: ['exitclearance/index/recent-exit'],
              keyRoute: 'recent-exit',
              route: ['/main/exit_clearance/recent-exit'],
              icon: 'fa fa-history'
            },
            {
              title: 'Not Yet Submited',
              permissions: ['exitclearance/view/not-yet-submited'],
              keyRoute: 'not-submited',
              route: ['/main/exit_clearance/not-submited'],
              icon: 'fa fa-clock'
            },
            {
              title: 'Reminder Exit',
              permissions: ['exitclearance/exceed-last-date/remind'],
              keyRoute: 'reminder-exit',
              route: ['/main/exit_clearance/reminder-exit'],
              icon: 'fa fa-bullhorn'
            },
            {
              title: 'My Approval',
              permissions: ['exitclearance/my-approval/my-approval'],
              keyRoute: 'my-approval',
              route: ['/main/exit_clearance/my-approval'],
              icon: 'fa fa-thumbs-up'
            },
            {
              title: 'Exceed Last Date',
              permissions: ['exitclearance/exceed-last-date/list'],
              keyRoute: 'exceed-last-date',
              route: ['/main/exit_clearance/exceed-last-date'],
              icon: 'fa fa-calendar-times'
            },
            {
              title: 'Finished',
              permissions: ['exitclearance/view/finish'],
              keyRoute: 'finished',
              route: ['/main/exit_clearance/finished'],
              icon: 'fa fa-check'
            },
            {
              title: 'Export Exit Clearance',
              permissions: ['exitclearance/index/all-data'],
              keyRoute: 'export-ec',
              route: ['/main/exit_clearance/export-ec'],
              icon: 'fa fa-calendar-alt'
            },
            {
              title: 'Export Data Create Exit Clearance',
              permissions: ['exitclearance/index/all-data'],
              keyRoute: 'export-data-create-ec',
              route: ['/main/exit_clearance/export-data-create-ec'],
              icon: 'fa fa-file'
            },
          ]
        },
        {
          module: 'reimbursement',
          items: [
            {
              title: 'All Requests',
              permissions: ['Reimbursement Management', 'Finance Department'],
              keyRoute: 'tracker',
              route: ['/main/reimbursement/tracker/index'],
              icon: 'fa fa-send'
            },
            {
              title: 'My Approval',
              permission: ['USER'],
              keyRoute: 'my-approval',
              route: ['/main/reimbursement/my-approval/index'],
              icon: 'fa fa-thumbs-up'
            },
            {
              title: 'My Request',
              permission: ['USER'],
              keyRoute: 'my-request',
              route: ['/main/reimbursement/my-request/index'],
              icon: 'fa fa-question'
            },
            {
              title: 'Finished',
              permission: ['Reimbursement Management'],
              keyRoute: 'reimbursement-finished',
              route: ['/main/reimbursement/tracker/finished'],
              icon: 'fa fa-check-square'
            }
          ]
        },
        {
          module: 'recruitment',
          items: [
            {
              title: 'Applicant List',
              permission: ['USER'],
              keyRoute: 'recruitment',
              route: ['/main/recruitment/applicant-list'],
              icon: 'fa fa-question'
            },

          ]
        },
        {
          module: 'application',
          items: [
            {
              title: 'Personal',
              permissions: ['vacancy/employeeapplication/get-application'],
              keyRoute: 'personal',
              route: ['/main/application/personal'],
              icon: 'fa fa-user'
            },
            {
              title: 'Keluarga',
              permissions: ['vacancy/employeeapplication/get-application'],
              keyRoute: 'family',
              route: ['/main/application/family'],
              icon: 'fa fa-heart'
            },
            {
              title: 'Kerabat',
              permissions: ['vacancy/employeeapplication/get-application'],
              keyRoute: 'sibling',
              route: ['/main/application/sibling'],
              icon: 'fa fa-hand-peace-o'
            },
            {
              title: 'Pendidikan',
              permissions: ['vacancy/employeeapplication/get-application'],
              keyRoute: 'education',
              route: ['/main/application/education'],
              icon: 'fa fa-graduation-cap'
            },
            {
              title: 'Bahasa',
              permissions: ['vacancy/employeeapplication/get-application'],
              keyRoute: 'language',
              route: ['/main/application/language'],
              icon: 'fa fa-language'
            },
            {
              title: 'Penghargaan',
              permissions: ['vacancy/employeeapplication/get-application'],
              keyRoute: 'activity',
              route: ['/main/application/activity'],
              icon: 'fa fa-trophy'
            },
            {
              title: 'Pelatihan',
              permissions: ['vacancy/employeeapplication/get-application'],
              keyRoute: 'training',
              route: ['/main/application/training'],
              icon: 'fa fa-chalkboard-teacher'
            },
            {
              title: 'Pengalaman',
              permissions: ['vacancy/employeeapplication/get-application'],
              keyRoute: 'experience',
              route: ['/main/application/experience'],
              icon: 'fa fa-briefcase'
            },
            {
              title: 'Riwayat Tunjangan',
              permissions: ['vacancy/employeeapplication/get-application'],
              keyRoute: 'allowance',
              route: ['/main/application/allowance'],
              icon: 'fa fa-money-bill-wave'
            },
            {
              title: 'Tambahan',
              permissions: ['vacancy/employeeapplication/get-application'],
              keyRoute: 'additional',
              route: ['/main/application/additional'],
              icon: 'fa fa-question'
            },
            {
              title: 'Relasi',
              permissions: ['vacancy/employeeapplication/get-application'],
              keyRoute: 'reference',
              route: ['/main/application/reference'],
              icon: 'fa fa-handshake-o'
            },
            {
              title: 'Lain-Lain',
              permissions: ['vacancy/employeeapplication/get-application'],
              keyRoute: 'other',
              route: ['/main/application/other'],
              icon: 'fa fa-quote-left'
            }
          ]
        },
        {
          module: 'mrps',
          items: [
            {
              title: 'MR PS List',
              permissions: ['mrps/mrps/get-all-mrps'],
              keyRoute: 'list',
              route: ['/main/mrps/list'],
              icon: 'fa fa-send'
            },
            {
              title: 'Site Database MR PS',
              permissions: ['mrps/mrps/get-site'],
              keyRoute: 'site',
              route: ['/main/mrps/site'],
              icon: 'fa fa-thumbs-up'
            }
          ]
        },
        {
          module: 'blog',
          items: [
            {
              title: 'Blog Post',
              permissions: ['blog/post/get-all-blogpost'],
              keyRoute: 'post',
              route: ['/main/blog/post/index'],
              icon: 'fa fa-send'
            },
            {
              title: 'Category Blog',
              permissions: ['blog/blog/get-all-category'],
              keyRoute: 'post-category',
              route: ['/main/blog/post-category/index'],
              icon: 'fa fa-list-alt'
            },
            {
              title: 'View Blog',
              permissions: ['blog/default/all-blog'],
              keyRoute: 'index',
              route: ['/main/blog/index'],
              icon: 'fa fa-file-image'
            },
            {
              title: 'View Gallery Blog',
              permissions: ['blog/gallery/get-view-gallery'],
              keyRoute: 'view',
              route: ['/main/blog/gallery/view'],
              icon: 'fa fa-file-image'
            }
          ]
        },
        {
          module: 'elearning',
          items: [
            {
              title: 'Bank Soal',
              permissions: ['eLearning/banksoal/get-list-banksoal'],
              keyRoute: 'banksoal',
              route: ['/main/elearning/banksoal'],
              icon: 'fa fa-question-circle'
            },
            {
              title: 'Exam',
              permissions: ['eLearning/exam/get-list-exam'],
              keyRoute: 'exam',
              route: ['/main/elearning/exam/index'],
              icon: 'fa fa-pencil-square-o'
            },
            // {
            //   title: 'Start Exam',
            //   permissions: ['eLearning/exam/cek-voucher-exam'],
            //   keyRoute: 'start',
            //   route: ['/main/elearning/exam/start'],
            //   icon: 'fa fa-hourglass-start'
            // },
            {
              title: 'Exam Result',
              permissions: ['eLearning/exam/exam-result'],
              keyRoute: 'result',
              route: ['/main/elearning/exam/result'],
              icon: 'fa fa-trophy'
            },
            {
              title: 'Survey Result',
              permissions: ['eLearning/exam/survey'],
              keyRoute: 'exam/survey',
              route: ['/main/elearning/exam/survey'],
              icon: 'fa fa-line-chart'
            },
            {
              title: 'Disc Result',
              permissions: ['eLearning/exam/disc'],
              keyRoute: 'exam/disc',
              route: ['/main/elearning/exam/disc'],
              icon: 'fa fa-bar-chart'
            },
            {
              title: 'Video Exam',
              //permissions: ['eLearning/video-exam'],
              keyRoute: 'index',
              route: ['/main/elearning/video-exam/index'],
              icon: 'fas fa-video'
            },
            // {
            //   title: 'Summary Video Exam',
            //   permission: ['USER'],
            //   keyRoute: 'list-summary-video-exam',
            //   route: ['/main/elearning/list-summary-video-exam'],
            //   icon: 'fas fa-video'
            // },
          ]
        },
        {
          module: 'certificate',
          items: [
            {
              title: 'Certificate',
              permissions: ['certificate/certificate/get-certificate'],
              keyRoute: 'certificate',
              route: ['/main/certificate/index'],
              icon: 'fa fa-file-alt'
            },
            {
              title: 'Certificate WAH',
              permissions: ['certificate/certificate/get-certificate-wah'],
              keyRoute: 'certificate-wah',
              route: ['/main/certificate/certificate-wah/index'],
              icon: 'fa fa-file-pdf'
            }
          ]
        },
        {
          module: 'research',
          items: []
        },
        {
          module: 'crm',
          items: [
            {
              title: 'Grid View',
              permissions: ['USER'],
              keyRoute: 'index',
              route: ['/main/crm/index'],
              icon: 'fa fa-list-ul'
            },
            {
              title: 'Kanban View',
              permissions: ['USER'],
              keyRoute: 'kanban',
              route: ['/main/crm/kanban'],
              icon: 'fa fa-th'
            },
            {
              title: 'Create CRM',
              permissions: ['USER'],
              keyRoute: 'create',
              route: ['/main/crm/create'],
              icon: 'fa fa-user-cog'
            }
          ]
        },
        {
          module: 'comcase',
          items: [
            {
              title: 'Tracker',
              permissions: ['USER'],
              keyRoute: 'list',
              route: ['/main/comcase/list'],
              icon: 'fa fa-list-ul'
            },

          ]
        },
        {
          module: 'entertainment',
          items: [
            {
              title: 'Entertainment',
              permissions: ['USER'],
              keyRoute: 'list',
              route: ['/main/entertainment/list'],
              icon: 'fa fa-list-ul'
            },

          ]
        },
        {
          module: 'car_monitoring',
          items: [
            {
              title: 'Car List',
              permissions: [
                'Car Coordinator',
                'carMonitoring/index/listallcar'
              ],
              keyRoute: 'car-list',
              route: ['/main/car_monitoring/car-list/index'],
              icon: 'fas fa-car-side'
            },
            {
              title: 'Driver List',
              permissions: [
                'Car Coordinator',
                'carMonitoring/index/listdriver'
              ],
              keyRoute: 'driver-list',
              route: ['/main/car_monitoring/driver-list/index'],
              icon: 'fas fa-user-circle'
            },
            {
              title: 'Trip Plans',
              permissions: ['carMonitoring/plan/add-plan'],
              keyRoute: 'trip-request',
              route: ['/main/car_monitoring/trip-request/index'],
              icon: 'fas fa-route'
            },
            {
              title: 'My Approval',
              permissions: [
                'Car Coordinator',
                'carMonitoring/indextopup/get-list-approval'
              ],
              keyRoute: 'my-approval',
              route: ['/main/car_monitoring/my-approval/index'],
              icon: 'fas fa-tasks'
            },
            {
              title: 'Car Request',
              permissions: ['Driver', 'carMonitoring/carrequest/get-approval'],
              keyRoute: 'car-request',
              route: ['/main/car_monitoring/car-request/index'],
              icon: 'fas fa-car'
            },
            {
              title: 'Fuel Request',
              permissions: ['Driver', 'carMonitoring/fuel/get-topup-param'],
              keyRoute: 'fuel-request',
              route: ['/main/car_monitoring/fuel-request/index'],
              icon: 'fas fa-gas-pump'
            },
            {
              title: 'SIM',
              permissions: ['Car Coordinator', 'carMonitoring/sim/get-sims'],
              keyRoute: 'sim',
              route: ['/main/car_monitoring/sim'],
              icon: 'fas fa-gas-pump'
            }
          ]
        },
        {
          module: 'carrepair',
          items: [
            {
              title: 'All Tracker',
              permissions: ['USER'],
              keyRoute: 'all-tracker',
              route: ['/main/carrepair/all-tracker'],
              icon: 'fas fa-car-side'
            },
            {
              title: 'My Approval',
              permissions: ['USER'],
              keyRoute: 'my-approval',
              route: ['/main/carrepair/my-approval'],
              icon: 'fa fa-tasks'
            },
            {
              title: 'Report Tracker',
              permissions: ['USER'],
              keyRoute: 'report-tracker',
              route: ['/main/carrepair/report-tracker'],
              icon: 'fa fa-book'
            }
          ]
        },
        {
          module: 'grab',
          items: [
            {
              title: 'List Grab',
              permissions: ['grab/index/list-all-data'],
              keyRoute: 'index',
              route: ['/main/grab/index'],
              icon: 'fa fa-taxi'
            },
            {
              title: 'My Approval',
              permissions: ['grab/approval/my-approval'],
              keyRoute: 'my-approval',
              route: ['/main/grab/my-approval'],
              icon: 'fa fa-thumbs-up'
            },
            {
              title: 'Import Report',
              permissions: ['Developer', 'Admin Grab'],
              keyRoute: 'import-report',
              route: ['/main/grab/import-report'],
              icon: 'flaticon flaticon-upload'
            }
          ]
        },
        {
          module: 'pm',
          items: [
            {
              title: 'Productivity',
              permissions: ['pm/leader-productivity'],
              keyRoute: 'leader-productivity',
              route: ['/main/pm/leader-productivity'],
              icon: 'fa fa-boxes'
            },
           
          ]
        },
        {
          module: 'reload-authorization',
          items: []
        },
        {
          module: 'attendance',
          items: [
            {
              title: 'Justification from HRD',
              permissions: ['attendance/dev/justification'],
              keyRoute: 'justification',
              route: ['/main/attendance/justification'],
              icon: 'fa fa-check-double'
            },
            {
              title: 'Justification Request',
              permissions: ['USER'],
              keyRoute: 'justification-request',
              route: ['/main/attendance/justification-request'],
              icon: 'fa fa-calendar-day'
            },
            {
              title: 'Justification Approval',
              permissions: ['USER'],
              keyRoute: 'justification-approval',
              route: ['/main/attendance/justification-approval'],
              icon: 'fa fa-calendar-check'
            },
            {
              title: 'My Team Today',
              permissions: ['USER'],
              keyRoute: 'my-team-today',
              route: ['/main/attendance/my-team-today'],
              icon: 'fa fa-chart-bar'
            },
            {
              title: 'Attendance Dashboard',
              permissions: ['USER'],
              keyRoute: 'dashboard-attendance',
              route: ['/main/attendance/dashboard-attendance'],
              icon: 'fa fa-chart-bar'
            },
            {
              title: 'Attendance Summary',
              permissions: ['USER'],
              keyRoute: 'summary',
              route: ['/main/attendance/summary'],
              icon: 'fa fa-user-clock'
            },
            {
              title: 'Permission Summary',
              permissions: ['USER'],
              keyRoute: 'permission',
              route: ['/main/attendance/summary-permission'],
              icon: 'fa fa-person-booth'
            },
            {
              title: 'Attendance Point Request',
              permissions: ['USER'],
              keyRoute: 'attendance-point',
              route: ['/main/attendance/attendance-point'],
              icon: 'fa fa-hotel'
            }, 
            {
              title: 'WFO WFH Summary',
              permissions: ['USER'],
              keyRoute: 'summary',
              route: ['/main/attendance/wfo-wfh'],
              icon: 'fa fa-user-clock'
            },
            {
              title: 'Performance Employee',
              permissions: ['USER'],
              keyRoute: 'low-performance',
              route: ['/main/attendance/performance'],
              icon: 'fa fa-user-minus'

            },
            {
              title: 'My Permission Request',
              permissions: ['USER'],
              keyRoute: 'my-permission-request',
              route: ['/main/attendance/my-permission-request'],
              icon: 'fa fa-thumbs-up'
            },
            {
              title: 'My Permission Approvals',
              permissions: ['USER'],
              keyRoute: 'my-permission-approval',
              route: ['/main/attendance/my-permission-approval'],
              icon: 'fa fa-book'
            },
            {
              title: 'Employees who do not record attendance',
              permissions: ['USER'],
              keyRoute: 'not-record',
              route: ['/main/attendance/not-record'],
              icon: 'fa fa-user-slash'
            },
            {
              title: 'My Team Attendance Summary',
              permissions: ['attendance/default/get-my-team-attendance-summary'],
              keyRoute: 'my-team-attendance-summary',
              route: ['/main/attendance/my-team-attendance-summary'],
              icon: 'la la-line-chart'
            },
            {
              title: 'My Team Attendance',
              permissions: ['attendance/default/get-my-team-attendance'],
              keyRoute: 'my-team-attendance',
              route: ['/main/attendance/my-team-attendance'],
              icon: 'fa fa-users'
            },
            {
              title: 'My Team Attendance Preview',
              permissions: ['attendance/default/get-my-team-attendance'],
              keyRoute: 'my-team-preview',
              route: ['/main/attendance/my-team-preview'],
              icon: 'fa fa-address-card'
            },
            {
              title: 'Export Attendance',
              permissions: ['attendance/default/get-my-team-attendance'],
              keyRoute: 'export-attendance',
              route: ['/main/attendance/export-attendance'],
              icon: 'fa fa-list-alt'
            },
            {
              title: 'Daily Attendance Summary',
              permissions: ['attendance/default/get-daily-attendance-summary'],
              keyRoute: 'daily-attendance-summary',
              route: ['/main/attendance/daily-attendance-summary'],
              icon: 'fa fa-calendar-alt'
            },
            {
              title: 'List Permission',
              permissions: ['attendance/approval/get-list-approval'],
              keyRoute: 'list-permission',
              route: ['/main/attendance/list-permission'],
              icon: 'fa fa-list-alt'
            },
            {
              title: 'No Clock Out',
              permissions: ['attendance/dev/no-clock-out'],
              keyRoute: 'no-clock-out',
              route: ['/main/attendance/no-clock-out'],
              icon: 'fa fa-times-circle'
            },
            {
              title: 'Home Location',
              permissions: ['USER'],
              keyRoute: 'home-location',
              route: ['/main/home-location'],
              icon: 'fa fa-home'
            },
            {
              title: 'Unpaid Leave',
              permissions: ['USER'],
              keyRoute: 'unpaid-leave',
              route: ['/main/attendance/unpaid-leave'],
              icon: 'fa fa-user-minus'

            },
            // {
            //   title: 'Import Office List',
            //   permissions: ['USER'],
            //   keyRoute: 'import-officelist',
            //   route: ['/main/attendance/import-officelist'],
            //   icon: 'fa fa-building'
            // }
          ]
        },
        {
          module: 'mom',
          items: [
            {
              title: 'Dashboard',
              permissions: ['USER'],
              keyRoute: 'dashboard',
              route: ['/main/mom/dashboard'],
              icon: 'fa fa-user-friends'
            },
            {
              title: 'My Meeting',
              permissions: ['USER'],
              keyRoute: 'my-meeting',
              route: ['/main/mom/my-meeting'],
              icon: 'fa fa-user-friends'
            },
          ]
        },
        {
          module: 'projectrequest',
          items: [
            {
              title: 'Dashboard',
              permissions: ['USER'],
              keyRoute: 'dashboard',
              route: ['/main/projectrequest/dashboard'],
              icon: 'fas fa-chart-line'
            },
            {
              title: 'List Tracker',
              permissions: ['USER'],
              keyRoute: 'index',
              route: ['/main/projectrequest/index'],
              icon: 'fa fa-user-friends'
            },
          ]
        },
        {
          module: 'site_survey',
          items: [
            {
              title: 'Dashboard',
              permissions: ['USER'],
              keyRoute: 'dashboard',
              route: ['/main/site_survey/dashboard'],
              icon: 'fa fa-chart-bar'
            },
            {
              title: 'Task Survey',
              permissions: ['USER'],
              keyRoute: 'plan',
              route: ['/main/site_survey/plan'],
              icon: 'fa fa-puzzle-piece'
            },
            {
              title: 'Task Assignment Field Ops',
              permissions: ['USER'],
              keyRoute: 'index-ops',
              route: ['/main/site_survey/index-ops'],
              icon: 'fa fa-receipt'
            },
            {
              title: 'MK Productivity',
              permissions: ['USER'],
              keyRoute: 'mk-productivity',
              route: ['/main/site_survey/mk-productivity'],
              icon: 'fa fa-receipt'
            },
            {
              title: 'Productivity Tracker',
              permissions: ['USER'],
              keyRoute: 'productivity',
              route: ['/main/site_survey/productivity'],
              icon: 'fa fa-check-double'
            },
            {
              title: 'Management Team Field Ops',
              permissions: ['USER'],
              keyRoute: 'manajemen-tim',
              route: ['/main/site_survey/manajemen-tim'],
              icon: 'fa fa-users'
            },
            {
              title: 'Hierarchy Team',
              permissions: ['USER'],
              keyRoute: 'team-chart',
              route: ['/main/site_survey/team-chart'],
              icon: 'fa fa-users'
            },
            {
              title: 'Schedule Team',
              permissions: ['USER'],
              keyRoute: 'schedule-teamm',
              route: ['/main/site_survey/schedule-team'],
              icon: 'fa fa-calendar-alt'
            },
          ]
        },
        {
          module: 'fo_productivity',
          items: [
            {
              title: 'Dashboard',
              permissions: ['USER'],
              keyRoute: 'dashboard',
              route: ['/main/fo_productivity/dashboard'],
              icon: 'fa fa-chart-bar'
            },
            {
              title: 'Task Assignment Field Ops',
              permissions: ['USER'],
              keyRoute: 'index',
              route: ['/main/fo_productivity/index'],
              icon: 'fa fa-receipt'
            },
            {
              title: 'Field Operations Productivity',
              permissions: ['USER'],
              keyRoute: 'productivity',
              route: ['/main/fo_productivity/productivity-fo'],
              icon: 'fa fa-check-double'
            },
            {
              title: 'Manajemen Tim FO',
              permissions: ['USER'],
              keyRoute: 'manajemen-tim',
              route: ['/main/fo_productivity/manajemen-tim-fo'],
              icon: 'fa fa-users'
            },
          ]
        },
        {
          module: 'hse',
          items: [
            {
              title: 'Dashboard',
              permissions: ['USER'],
              keyRoute: 'dashboard',
              route: ['/main/hse/dashboard'],
              icon: 'fa fa-chart-bar'
            },
            {
              title: 'HSE Tracker',
              permissions: ['USER'],
              keyRoute: 'hse-tracker',
              route: ['/main/hse/hse-tracker'],
              icon: 'fa fa-puzzle-piece'
            },
            {
              title: 'Site Inspection Tracker',
              permissions: ['USER'],
              keyRoute: 'tracker-si',
              route: ['/main/hse/tracker-si'],
              icon: 'fa fa-puzzle-piece'
            },
            // {
            //   title: 'SIKA',
            //   permissions: ['USER'],
            //   keyRoute: 'tracker-sika',
            //   route: ['/main/hse/tracker-sika'],
            //   icon: 'fa fa-clipboard-list'
            // },
            // {
            //   title: 'SWMS',
            //   permissions: ['USER'],
            //   keyRoute: 'tracker-swms',
            //   route: ['/main/hse/tracker-swms'],
            //   icon: 'fa fa-list-alt'
            // },
          ]
        },
        {
          module: 'geotagging_photo',
          items: [
            {
              title: 'List Geotagging Photo',
              permissions: ['geotaggingphoto/default/get-geotagging-photo'],
              keyRoute: 'list',
              route: ['/main/geotagging_photo/list'],
              icon: 'fa fa-location-arrow'
            },
          ]
        },
        {
          module: 'cloud-storage',
          items: []
        },
        {
          module: 'group',
          items: [
            {
              title: 'Index',
              permissions: ['group/default/index'],
              keyRoute: 'index',
              route: ['/main/group/index'],
              icon: 'fa fa-send'
            }
          ]
        },
        {
          module: 'region_location',
          items: [
            {
              title: 'List',
              permissions: ['USER'],
              keyRoute: 'index',
              route: ['/main/region_location/index'],
              icon: 'fa fa-send'
            },
            {
              title: 'Org Chart',
              permissions: ['USER'],
              keyRoute: 'index',
              route: ['/main/region_location/org-chart'],
              icon: 'fa fa-sitemap'
            }
          ]
        },
        {
          module: 'timesheet',
          items: []
        },
        {
          module: 'sop',
          items: []
        },
        {
          module: 'request-pulsa-by-api',
          items: []
        },
        {
          module: 'projectresources',
          items: []
        },
        {
          module: 'ion-project',
          items: [
            {
              title: 'Dashboard',
              permissions: ['USER'],
              keyRoute: 'dashboard',
              route: ['/main/ion-project/dashboard'],
              icon: 'fa fa-chart-bar'
            },
            {
              title: 'MASTER TRACKER (MT)',
              permissions: [
                'ION PM',
                // 'ION IP ENG',
                // 'ION Admin MOP',
                // 'ION Project Coordinator',
                // 'ION Nokia Management',
              ],
              keyRoute: 'list',
              route: ['/main/ion-project/list'],
              icon: 'fa fa-puzzle-piece'
            },
            // {
            //   title: 'PVI',
            //   permissions: [
            //     'ION PM',
            //     'ION IP ENG',
            //     'ION Admin MOP',
            //     'ION Project Coordinator',
            //     'ION Nokia Management',
            //   ],
            //   keyRoute: 'pvi',
            //   route: ['/main/ion-project/pvi'],
            //   icon: 'fa fa-calendar-alt'
            // },
            // {
            //   title: 'ATP',
            //   permissions: [
            //     'ION PM',
            //     'ION IP ENG',
            //     'ION Admin MOP',
            //     'ION Project Coordinator',
            //     'ION Nokia Management',
            //   ],
            //   keyRoute: 'atp',
            //   route: ['/main/ion-project/atp'],
            //   icon: 'fa fa-clipboard-list'
            // },
            // {
            //   title: 'History Import MT',
            //   permissions: [
            //     'ION PM',
            //     'ION IP ENG',
            //     'ION Admin MOP',
            //     'ION Project Coordinator',

            //   ],
            //   keyRoute: 'history-import',
            //   route: ['/main/ion-project/history-import'],
            //   icon: 'flaticon flaticon-upload'
            // },
            // {
            //   title: 'History Import PVI',
            //   permissions: [
            //     'ION PM',
            //     'ION IP ENG',
            //     'ION Admin MOP',
            //     'ION Project Coordinator',

            //   ],
            //   keyRoute: 'history-import-pvi',
            //   route: ['/main/ion-project/history-import-pvi'],
            //   icon: 'flaticon flaticon-upload'
            // },
            // {
            //   title: 'History Import ATP',
            //   permissions: [
            //     'ION PM',
            //     'ION IP ENG',
            //     'ION Admin MOP',
            //     'ION Project Coordinator',

            //   ],
            //   keyRoute: 'history-import-atp',
            //   route: ['/main/ion-project/history-import-atp'],
            //   icon: 'flaticon flaticon-upload'
            // },
            {
              title: 'Droplist',
              permissions: [
                'ION PM',
                'ION IP ENG',
                'ION Admin MOP',
                'ION Project Coordinator',

              ],
              keyRoute: 'droplist',
              route: ['/main/ion-project/droplist'],
              icon: 'fa fa-receipt'
            },
            // {
            //   title: 'Import Data ION',
            //   permissions: [
            //     'ION PM',
            //     'ION IP ENG',
            //     'ION Admin MOP',
            //     'ION Project Coordinator',

            //   ],
            //   keyRoute: 'import-ion-project',
            //   route: ['/main/ion-project/import-ion-project'],
            //   icon: 'flaticon flaticon-upload'
            // },
            {
              title: 'FAT Report',
              permissions: [
                'USER',
              ],
              keyRoute: 'fat',
              route: ['/main/ion-project/fat'],
              icon: 'fa fa-clipboard-check'
            },
            // old component ion project
            // {
            //   title: 'List ION Project OLD',
            //   permissions: ['USER'],
            //   keyRoute: 'list',
            //   route: ['/main/ion-project/list'],
            //   icon: 'fa fa-puzzle-piece'
            // },
            // {
            //   title: 'ION Project Import OLD',
            //   permissions: ['USER'],
            //   keyRoute: 'ion-import',
            //   route: ['/main/ion-project/ion-import'],
            //   icon: 'flaticon flaticon-upload'
            // },

          ]
        },
        {
          module: 'sip',
          items: [
            {
              title: 'Dashboard',
              permissions: ['USER'],
              keyRoute: 'dashboard',
              route: ['/main/sip/dashboard'],
              icon: 'fa fa-chart-bar'
            },
            {
              title: 'TRACKER',
              permissions: ['USER'],
              keyRoute: 'list-master',
              route: ['/main/sip/list-master'],
              icon: 'fa fa-puzzle-piece'
            },
            {
              title: 'My Approval',
              permissions: ['USER'],
              keyRoute: 'my-approval',
              route: ['/main/sip/my-approval'],
              icon: 'fa fa-thumbs-up'
            },
          ]
        },

        {
          module: 'okr',
          items: [
            {
              title: 'Dashboard',
              permissions: ['USER'],
              keyRoute: 'dashboard',
              route: ['/main/okr/dashboard'],
              icon: 'fa fa-chart-bar'
            },
            {
              title: 'PA Scoring Dashboard',
              permissions: ['USER'],
              keyRoute: 'pa-scoring',
              route: ['/main/okr/pa-scoring'],
              icon: 'fa fa-chart-bar'
            },
            {
              title: 'PA OKR Productivity Dashboard',
              permissions: ['USER'],
              keyRoute: 'pa-productivity',
              route: ['/main/okr/pa-productivity'],
              icon: 'fa fa-chart-bar'
            },

            {
              title: 'List OKR',
              permissions: ['USER'],
              keyRoute: 'okr',
              route: ['/main/okr/list-okr'],
              icon: 'fa fa-clipboard-list'
            },
          ]
        },

        {
          module: 'faq',
          items: [
            {
              title: 'Dashboard',
              permissions: ['USER'],
              keyRoute: 'dashboard',
              route: ['/main/faq/dashboard'],
              icon: 'fa fa-chart-bar'
            },
            {
              title: 'FAQ Post',
              permissions: ['USER'],
              keyRoute: 'list-master',
              route: ['/main/faq/list-master'],
              icon: 'fa fa-send'
            },
            {
              title: 'FAQ Category',
              permissions: ['USER'],
              keyRoute: 'faq-category',
              route: ['/main/faq/faq-category'],
              icon: 'fa flaticon-cogwheel-2'
            },
            {
              title: 'FAQ Subcategory',
              permissions: ['USER'],
              keyRoute: 'faq-subcategory',
              route: ['/main/faq/faq-subcategory'],
              icon: 'fa fa-cogs'
            },
          ]
        },
        {
          module: 'retopup-pulsa',
          items: []
        },
        {
          module: 'failed-topup',
          items: []
        },
        {
          module: 'tsel-online',
          items: [
            {
              title: 'Dashboard',
              permissions: ['USER'],
              keyRoute: 'dashboard',
              route: ['/main/tsel-online/dashboard'],
              icon: 'fa fa-puzzle-piece'
            },
            {
              title: 'Tsel Online Tracker',
              permissions: ['USER'],
              keyRoute: 'dashboard',
              route: ['/main/tsel-online/index'],
              icon: 'fa fa-chart-bar'
            },
          ]
        },
        {
          module: 'authorization',
          items: []
        },
        {
          module: 'documentation',
          items: []
        },
        {
          module: 'whatsapp',
          items: []
        },
        {
          module: 'account-management',
          items: []
        },
        {
          module: 'huaweiresource',
          items: []
        },
        {
          module: 'logbook',
          items: [
            {
              title: 'Dashboard',
              permissions: ['USER'],
              keyRoute: 'dashboard',
              route: ['/main/logbook/dashboard'],
              icon: 'fa fa-puzzle-piece'
            },
            {
              title: 'Account Usage',
              permissions: ['USER'],
              keyRoute: 'account-usage',
              route: ['/main/logbook/account-usage'],
              icon: 'fa fa-user'
            },
            {
              title: 'User Agreement',
              permissions: ['USER'],
              keyRoute: 'user-agreement',
              route: ['/main/logbook/user-agreement'],
              icon: 'fa fa-check'
            },
            {
              title: 'User Software',
              permissions: ['USER'],
              keyRoute: 'index',
              route: ['/main/logbook/user-software'],
              icon: 'fa fa-save'
            },
            {
              title: 'Keystroke',
              permissions: ['USER'],
              keyRoute: 'keystroke',
              route: ['/main/logbook/keystroke'],
              icon: 'fa fa-chart-bar'
            },
          ]
        },
        {
          module: 'users-home',
          items: []
        },
        {
          module: 'whistle-blowing',
          items: []
        },
        {
          module: 'e-patrol',
          items: []
        },
        {
          module: 'emergency-report',
          items: [
            {
              title: 'List Emergency Report',
              permissions: ['USER'],
              keyRoute: 'index',
              route: ['/main/emergency-report/index'],
              icon: 'fa fa-exclamation-circle'
            },
          ]
        },
        {
          module: 'e-patrol',
          items: [
            {
              title: 'Visits',
              permissions: ['USER'],
              keyRoute: 'index',
              route: ['/main/e-patrol/index'],
              icon: 'fa fa-exclamation-circle'
            },
            {
              title: 'Map',
              permissions: ['USER'],
              keyRoute: 'map',
              route: ['/main/e-patrol/map'],
              icon: 'fa fa-map'
            },
            {
              title: 'Post',
              permissions: ['USER'],
              keyRoute: 'post',
              route: ['/main/e-patrol/post'],
              icon: 'fa fa-users'
            },
            {
              title: 'Group',
              permissions: ['USER'],
              keyRoute: 'group',
              route: ['/main/e-patrol/group'],
              icon: 'fa fa-users'
            },
          ]
        },
        {
          module: 'office-list',
          items: []
        },
        {
          module: 'contract-ext',
          items: [
            {
              title: 'Dashboard',
              permissions: ['USER'],
              keyRoute: 'dashboard',
              route: ['/main/contract-ext/dashboard'],
              icon: 'fa fa-chart-bar'
            },
            {
              title: 'Tracker',
              permissions: ['USER'],
              keyRoute: 'tracker',
              route: ['/main/contract-ext/tracker/page/1'],
              icon: 'fa fa-clipboard-list'
            },
            {
              title: 'Notice Period',
              permissions: ['HR Contract Extension'],
              keyRoute: 'notice-period',
              route: ['/main/contract-ext/notice-period/page/1'],
              icon: 'fa fa-clipboard-list'
            },
            {
              title: 'Not Extended',
              permissions: ['HR Contract Extension'],
              keyRoute: 'not-extended',
              route: ['/main/contract-ext/not-extended/page/1'],
              icon: 'fa fa-user-times'
            },
            {
              title: 'My Approval',
              permissions: ['USER'],
              keyRoute: 'my-approval',
              route: ['/main/contract-ext/my-approval/page/1'],
              icon: 'fa fa-clipboard-check'
            },
            {
              title: 'Approved Tracker',
              permissions: ['USER'],
              keyRoute: 'Approved',
              route: ['/main/contract-ext/approved/page/1'],
              icon: 'fa fa-clipboard-list'
            },
            {
              title: 'History Tracker',
              permissions: ['USER'],
              keyRoute: 'finished',
              route: ['/main/contract-ext/finished/page/1'],
              icon: 'fa fa-clipboard-list'
            },
          ]
        },
        {
          module: 'administration',
          items: [
            {
              title: 'Nomor Surat',
              permissions: ['administration/default/list-nomor-surat'],
              keyRoute: 'index',
              route: ['/main/administration/index'],
              icon: 'fa fa-file'
            },

            {
              title: 'Paklaring',
              permissions: ['administration/paklaring/get-list'],
              keyRoute: 'paklaring',
              route: ['/main/paklaring/index'],
              icon: 'fa fa-users'
            },

          ]
        },
        {
          module: 'resign',
          items: [
            {
              title: 'Tracker',
              permissions: ['USER'],
              keyRoute: 'index',
              route: ['/main/resign/tracker/index'],
              icon: 'fa fa-file'
            },
            {
              title: 'My Request',
              permissions: ['USER'],
              keyRoute: 'my-request',
              route: ['/main/resign/my-request/index'],
              icon: 'fa fa-file'
            },
            {
              title: 'My Approval',
              permissions: ['USER'],
              keyRoute: 'my-approval',
              route: ['/main/resign/my-approval/index'],
              icon: 'fa fa-file'
            },
          ]
        },
        {
          module: 'logistic',
          items: [
            {
              title: 'Request',
              permissions: ['USER'],
              keyRoute: 'rerquest',
              route: ['/main/logistic/request/index'],
              icon: 'fa fa-file'
            },
            {
              title: 'Item',
              permissions: ['USER'],
              keyRoute: 'item',
              route: ['/main/logistic/item/index'],
              icon: 'fa fa-file'
            },
            {
              title: 'Payment Tracker',
              permissions: ['USER'],
              keyRoute: 'shipping-payment-tracker',
              route: ['/main/logistic/shipping-payment-tracker'],
              icon: 'fa fa-file'
            },
            {
              title: 'Payment Aproval',
              permissions: ['USER'],
              keyRoute: 'shipping-payment-approval',
              route: ['/main/logistic/shipping-payment-approval'],
              icon: 'fa fa-file'
            },
          ]
        },
        {
          module: 'users',
          items: [
            {
              title: 'All Users',
              permissions: ['users/default/get-users'],
              keyRoute: 'all-users',
              route: ['/main/users/all-users'],
              icon: 'fa fa-users'
            },
            {
              title: 'Employee Document',
              permissions: ['users/default/get-document'],
              keyRoute: 'document',
              route: ['/main/users/document'],
              icon: 'fa fa-book'
            },
            // {
            //   title: 'Employee File',
            //   permissions: ['users/default/get-file'],
            //   keyRoute: 'file',
            //   route: ['/main/users/file'],
            //   icon: 'fa fa-book'
            // },
            {
              title: 'List Drop CV',
              permission: ['users/default/list-drop-cv'],
              keyRoute: 'cv',
              route: ['/main/users/cv'],
              icon: 'fa fa-question'
            },
            {
              title: 'Attachment Upload',
              keyRoute: 'attachment',
              route: ['/main/users/doc-confirm'],
              icon: 'fa fa-file-text'
            }
          ]
        },
        {
          module: 'product-management',
          items: [
            {
              title: 'Dashboard',
              permissions: ['USER'],
              keyRoute: 'dashboard',
              route: ['/main/product-management/dashboard'],
              icon: 'fa fa-chart-bar'
            },
            {
              title: 'Stock Tracker',
              permissions: ['USER'],
              keyRoute: 'stock-tracker',
              route: ['/main/product-management/stock-tracker'],
              icon: 'fa fa-code-branch'
            },
            {
              title: 'Product',
              permissions: ['USER'],
              keyRoute: 'product-tracker',
              route: ['/main/product-management/product-tracker'],
              icon: 'fa fa-cubes'
            },
            {
              title: 'Product Category',
              permissions: ['USER'],
              keyRoute: 'product-category-tracker',
              route: ['/main/product-management/product-category-tracker'],
              icon: 'fa fa-cubes'
            },
            {
              title: 'Stock Location',
              permissions: ['USER'],
              keyRoute: 'stock-location',
              route: ['/main/product-management/stock-location'],
              icon: 'fa fa-warehouse'
            },
            {
              title: 'Pricelist',
              permissions: ['USER'],
              keyRoute: 'pricelist-tracker',
              route: ['/main/product-management/pricelist-tracker'],
              icon: 'fa fa-money-bill'
            },
          ]
        },
        {
          module: 'costallocation',
          items: [
            {
              title: 'Dashboard',
              permissions: ['USER'],
              keyRoute: 'dashboard',
              route: ['/main/costallocation/dashboard'],
              icon: 'fa fa-chart-bar'
            },
            {
              title: 'All  List',
              permissions: ['USER'],
              keyRoute: 'all-list',
              route: ['/main/costallocation/all-list'],
              icon: 'fa fa-chart-bar'
            }, 
            {
              title: 'List',
              permissions: ['USER'],
              keyRoute: 'list',
              route: ['/main/costallocation/list'],
              icon: 'fa fa-chart-bar'
            }, 
            {
              title: 'Confirmation',
              permissions: ['USER'],
              keyRoute: 'confirmation',
              route: ['/main/costallocation/confirmation'],
              icon: 'fa fa-chart-bar'
            }, 
            {
              title: 'Resolve',
              permissions: ['USER'],
              keyRoute: 'resolve',
              route: ['/main/costallocation/resolve'],
              icon: 'fa fa-chart-bar'
            },
            {
              title: 'Project Management',
              permissions: ['USER'],
              keyRoute: 'projectmanagement',
              route: ['/main/costallocation/projectmanagement'],
              icon: 'fa fa-chart-bar'
            },
            {
              title: 'Resign Verification',
              permissions: ['USER'],
              keyRoute: 'resign-verification',
              route: ['/main/costallocation/resign-verification'],
              icon: 'fa fa-sign-out-alt'
            }
          ]
        },
        {
          module: 'petty-cash',
          items: [
            {
              title: 'Dashboard',
              permissions: ['USER'],
              keyRoute: 'dashboard',
              route: ['/main/petty-cash/dashboard'],
              icon: 'fa fa-chart-bar'
            },
            {
              title: 'Floating Tracker',
              permissions: ['USER'],
              keyRoute: 'floating-tracker',
              route: ['/main/petty-cash/floating-tracker'],
              icon: 'fa fa-book'
            },
            {
              title: 'Floating Approval',
              permissions: ['USER'],
              keyRoute: 'floating-approval',
              route: ['/main/petty-cash/floating-approval'],
              icon: 'fa fa-thumbs-up'
            }
          ]
        }
      ],
      menuSection: {
        administration: 'Administration',
        asset: 'Asset Management',
        employee: 'Employee',
        pr: 'PO Request',
        material_request: 'Material Request',
        simcard: 'Simcard',
        recruitment: 'Recruitment',
        application: 'Application Form',
        reimbursement: 'Reimbursement',
        mrps: 'Material Request PS',
        advanceclearance: 'Cash Advance',
        advancesip: 'Advance SIP',
        blog: 'Blog',
        elearning: 'E-Learning',
        pmc: 'PMC',
        hse: 'HSE',
        certificate: 'Certificate',
        exit_clearance: 'Exit Clearance',
        crm: 'CRM',
        grab: 'Grab',
        car_monitoring: 'Car Monitoring',
        pm: 'Project Management',
        vendor_care: 'Vendor Care',
        attendance: 'Attendance',
        mom: 'MOM',
        site_survey: 'Site Survey',
        geotagging_photo: 'Geotagging Photo',
        group: 'group',
        region_location: 'Region Location',
        projectresources: 'Project Resources',
        'contract-ext': 'Contract Extension',
        resign: 'Resign',
        'projectrequest': 'Employee Requisition',
        users: 'Users',
        activity: 'Activity',
        'petty-cash': 'Petty Cash',
        logistic: 'Logistic'
      }
    };
  }
}
