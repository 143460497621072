import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Component, OnInit, Input, Output, EventEmitter, ElementRef, ViewChild } from '@angular/core';
import { UploadService } from 'src/app/_services/upload.service';
import { ImageCroppedEvent } from 'ngx-image-cropper';
import { HttpEventType, HttpResponse } from '@angular/common/http';
import Swal from 'sweetalert2';
import { Ng2ImgMaxService } from 'ng2-img-max';

@Component({
  selector: 'app-direct-upload',
  templateUrl: './direct-upload.component.html',
  styleUrls: ['./direct-upload.component.scss']
})
export class DirectUploadComponent implements OnInit {

  @Input() id = 'formGroup';
  @Input() singleUpload = false;
  @Input() prefix: string;
  @Input() acceptFile?: string;
  @Input() remark?: string;
  @Input() fileChunkSize = 50000000;
  @Input() cropper = false;
  @Input() fileType: string;
  @Input() module: string = null;
  @Input() tableTarget: string;
  @Input() columnTarget: string;
  @Input() boxUpload: boolean;
  @Input() maxLengthFilename: number = 200;
  @Input() filesizeMaximum?: number; //dalam byte
  @Input() filepixelMaximum?: number; //dalam pixel
  @Input() autoReset: boolean = false;
  @Input() isPublicFile?: boolean = true;

  labelInput = 'Choose file';
  selectedFiles: FileList;
  presignUrl: string;
  uploadId: string;
  listPresignUrlPart: any;
  uploadPartsArray: any[];
  numChunks: number;
  currentPart: number;
  progress: number;
  isFinish: boolean;
  errorMime: boolean;
  uploadSuccess: boolean;
  tempEvent: any = undefined;
  compressedFile: any;

  uncommonFiletype: any = {
    jpeg: 'image/jpeg',
    jpg: 'image/jpeg',
    png: 'image/png',
    pdf: 'application/pdf',
    doc: 'application/msword',
    docx: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    ppt: 'application/vnd.ms-powerpoint',
    pptx: 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
    xls: 'application/vnd.ms-excel',
    xlsx: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    xlsm: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    xlsb: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    txt: 'text/plain',
    zip: 'application/x-zip-compressed',
    csv: 'text/plain',
  };

  @Output() success = new EventEmitter<any>();
  @Output() afterCrop = new EventEmitter<any>();
  @Output() change = new EventEmitter<FileList>();

  @ViewChild('inputFile', { static: false })
  inputFileVariable: ElementRef;

  @ViewChild('content', { static: false }) content: any;

  imageChangedEvent: any = '';
  croppedImage: any = '';
  closeResult: string;
  fileExtension = '';

  constructor(
    private uploadService: UploadService,
    private modal: NgbModal,
    private maxPixelService: Ng2ImgMaxService,
  ) {
    this.fileChunkSize = (this.fileChunkSize < 5242880) ? 5242880 : this.fileChunkSize;
    this.uploadService.progressEmitObs.subscribe(
      res => {
        if (this.singleUpload) {
          this.progress = res;
        } else {
          this.progress = Math.floor((100 * this.currentPart - 100 + res) / this.numChunks);
        }
      }
    );
  }

  ngOnInit() {

  }

  openWindows() {
    document.getElementById('formGroup').click();
  }

  uniqueTime(): string {
    var d = new Date(),
      newMonth = '' + (d.getMonth()),
      newDay = '' + d.getDate(),
      newYear = '' + d.getFullYear(),
      newHours = '' + d.getHours(),
      newMinutes = '' + d.getMinutes(),
      newSeconds = '' + d.getSeconds(),
      newMiliSeconds = '' + d.getMilliseconds();

    if (newMonth.length < 2) newMonth = '0' + newMonth;
    if (newDay.length < 2) newDay = '0' + newDay;

    return '' + newYear + newMonth + newDay + newHours + newMinutes + newSeconds + newMiliSeconds;
  }

  checkLengthFilename(event) {
    this.selectedFiles = event.target.files;
    const file = this.selectedFiles.item(0);
    if (file.name.length > this.maxLengthFilename) {
      return false;
    }
    else {
      return true;
    }
  }

  selectSingleFilePresignUrl(event) {
    this.change.emit(event.target.files);

    let objFile: any;

    let filesize = event.target.files[0].size;
    if ((this.filesizeMaximum && filesize <= this.filesizeMaximum) || (!this.filesizeMaximum)) {
      if (this.checkLengthFilename(event)) {
        this.progress = 0;
        this.uploadPartsArray = [];
        this.isFinish = false;
        this.errorMime = false;
        this.uploadSuccess = false;
        if (this.filepixelMaximum) {
          objFile = this.changePixel(event.target.files);
        } else {
          objFile = event.target.files[0];
        }

        const file = objFile;
        if (file) {
          if (this.mimeChecker(file)) {
            let _filename = file.name;
            let filename = _filename.split('.').slice(0, -1).join('_');
            let ext = _filename.split('.').pop();
            let temp_filename = filename.replace(/[\W\s\n]/gm, "");
            this.labelInput = this.uniqueTime() + '_' + temp_filename + '.' + ext;
            const fileSize = file.size;
            this.numChunks = Math.floor(fileSize / this.fileChunkSize) + 1;

            this.uploadService.getPresignedUrlGcs({
              prefix: this.prefix,
              filename: this.labelInput,
              mime: this.fileType,
              size: fileSize,
              module: this.module,
              tableTarget: this.tableTarget,
              columnTarget: this.columnTarget,
              isPublicFile: false
             }).subscribe(
              arg => {
                if (arg.status) {
                  this.uploadFile(arg.result, file);
                }
              }, (error) => {

              }
            );
          } else {
            this.isFinish = undefined;
            this.progress = 0;
            this.selectedFiles = undefined;
            this.inputFileVariable.nativeElement.value = '';
            this.errorMime = true;
          }
        }
      }
      else {
        Swal.fire('Max length filename is ' + this.maxLengthFilename);
        this.progress = 0;
        this.uploadPartsArray = [];
        this.isFinish = false;
        this.errorMime = false;
        this.uploadSuccess = false;
        this.selectedFiles = undefined;
      }
    }
    else {
      Swal.fire({
        title: 'File Input',
        type: 'info',
        text: 'Ukuruan file tidak boleh lebih dari : ' + this.bytesToSize(this.filesizeMaximum)
      });
    }
  }

  selectFilePresignUrlPart(event) {
    this.change.emit(event.target.files);
    let objFile: any;

    let filesize = event.target.files[0].size;
    if ((this.filesizeMaximum && filesize <= this.filesizeMaximum) || (!this.filesizeMaximum)) {
      if (this.checkLengthFilename(event)) {
        this.progress = 0;
        this.uploadPartsArray = [];
        this.isFinish = false;
        this.errorMime = false;
        this.uploadSuccess = false;
        if (this.filepixelMaximum) {
          objFile = this.changePixel(event.target.files);
        } else {
          objFile = event.target.files[0];
        }

        const file = objFile;
        if (file) {
          if (this.mimeChecker(file)) {
            let _filename = file.name;
            let filename = _filename.split('.').slice(0, -1).join('_');
            let ext = _filename.split('.').pop();
            let temp_filename = filename.replace(/[\W\s\n]/gm, "");
            this.labelInput = this.uniqueTime() + '_' + temp_filename + '.' + ext;

            const fileSize = file.size;
            this.numChunks = Math.floor(fileSize / this.fileChunkSize) + 1;

            this.uploadService.createMultipartUpload({ prefix: this.prefix, filename: this.labelInput, mime: file.type, size: fileSize, totalPart: this.numChunks, tableTarget: this.tableTarget, columnTarget: this.columnTarget, isPublicFile: this.isPublicFile }).subscribe(
              response => {
                if (response.status) {
                  this.uploadId = response.result.uploadId;
                  this.listPresignUrlPart = response.result.listPresignedUrl;
                  this.queueUpload(1);
                }
              }
            );
          }
          else {
            this.isFinish = undefined;
            this.progress = 0;
            this.selectedFiles = undefined;
            this.inputFileVariable.nativeElement.value = "";
            this.errorMime = true;
          }
        }
      }
      else {
        Swal.fire('Max length filename is ' + this.maxLengthFilename);
        this.progress = 0;
        this.uploadPartsArray = [];
        this.isFinish = false;
        this.errorMime = false;
        this.uploadSuccess = false;
        this.selectedFiles = undefined;
      }
    }
    else {
      Swal.fire({
        title: 'File Input',
        type: 'info',
        text: 'Ukuruan file tidak boleh lebih dari : ' + this.bytesToSize(this.filesizeMaximum)
      });
    }
  }

  uploadFile(param: any, file: File) {
    this.uploadService.putFileGcs(param.signedUploadURL, file).subscribe(
      event => {
        if (event.status == 'done') {
          this.isFinish = true;
          this.uploadSuccess = true;
          this.success.emit(param);
          if (this.autoReset) {
            this.reset();
          }
        }
      }
    );
  }

  queueUpload(index) {
    this.currentPart = index;
    let file = this.selectedFiles.item(0);
    if (index <= this.numChunks) {
      let start, end, blob;

      start = (index - 1) * this.fileChunkSize;
      end = (index) * this.fileChunkSize;
      blob = (index < this.numChunks) ? file.slice(start, end) : file.slice(start);

      this.uploadService.putFilePart(this.listPresignUrlPart[index], blob).subscribe(
        event => {
          if (event.status == 'done') {
            let etag: any = event.val;
            etag = etag.replace(/^"(.*)"$/, '$1');
            let param = {
              ETag: etag,
              PartNumber: index
            }
            this.uploadPartsArray.push(param);
            this.queueUpload(index + 1);
          }
        }
      );
    }
    else {
      this.uploadService.complateMultipartUpload({ prefix: this.prefix, filename: this.labelInput, parts: this.uploadPartsArray, uploadId: this.uploadId }).subscribe(
        response => {
          if (response.status) {
            this.isFinish = true;
            this.success.emit(response.result);
            this.progress = 0;
            this.selectedFiles = undefined;
            this.inputFileVariable.nativeElement.value = "";
            this.uploadSuccess = true;
            if (this.autoReset) {
              this.reset();
            }
          }
        }
      );
    }
  }

  mimeChecker(file: File) {
    if (!this.acceptFile) {
      return true;
    }
    let result = false;
    let listAcceptFile = this.acceptFile.split(',');

    for (let i = 0; i < listAcceptFile.length; i++) {
      let filetype = listAcceptFile[i].trim();
      let parseFilename = file.name.split('.');
      let extention = parseFilename[parseFilename.length - 1];

      let temp = file.name.split('.');
      let tempType = '.' + file.type.split('/')[1];

      if (filetype == file.type || filetype === tempType) {
        result = true;
        break;
      }
      else if (extention == filetype.replace('.', '') && this.uncommonFiletype[filetype.replace('.', '')] == file.type) {
        result = true;
        break;
      }
      else if (file.type.indexOf(extention) != -1) {
        result = true;
        break;
      }
      else if(extention.toLowerCase === filetype.replace('.', '').toLowerCase){
        result = true;
      }
    }

    return result;
  }

  dataURLtoFile(dataurl, filename) {

    var arr = dataurl.split(','),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, { type: mime });
  }

  fileChangeEvent(event: any): void {

    this.imageChangedEvent = event;
    this.change.emit(event.target.files);

    this.modal.open(this.content).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
      this.selectFilePresignUrlPart(this.imageChangedEvent);
    }, (reason) => {
    });
  }

  imageCropped(event: ImageCroppedEvent) {
    this.afterCrop.emit(event.base64);
  }

  imageLoaded() {
    // show cropper
  }

  cropperReady() {
    // cropper ready
  }

  loadImageFailed() {
    // show message
  }

  bytesToSize(bytes: number) {
    var sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
    if (bytes == 0) {
      return '0 Byte';
    }
    else {
      var i = Math.floor(Math.log(bytes) / Math.log(1024));
      return Math.round(bytes / Math.pow(1024, i)) + ' ' + sizes[i];
    }
  }

  changePixel(file: any) {

    let fileCompress: any;
    let maxWidth: number;
    let maxHeight: number;
    console.log('size File sebelum compress: ' + file[0].size);
    let fr = new FileReader();
    fr.onload = () => { // when file has loaded
      let img = new Image();
      img.onload = () => {
        let pixelImg = img.width * img.height;
        if (this.filepixelMaximum < pixelImg) {
          maxWidth = (img.width * this.filepixelMaximum) / pixelImg;
          maxHeight = (img.height * this.filepixelMaximum) / pixelImg;
        }
      };
    };
    let image = file[0];
    this.maxPixelService.resizeImage(image, maxWidth, maxHeight).subscribe(
      result => {
        this.compressedFile = result;
        console.log('size File setelah compress: ' + this.compressedFile.size);
      },
      error => {
        console.log('😢 Oh no!', error);
      }
    );

    this.compressedFile;
  }

  reset() {
    this.inputFileVariable.nativeElement.value = "";
    this.uploadSuccess = false;
    this.labelInput = 'Choose file';
  }


}
