<div class="row align-items-center bg-white justify-content-end">
  <div class="col-lg-7 col-xl-6">
    <div class="pb-5 mb-5 pt-5 px-4 px-md-5 ml-0 ml-xl-5">
      <h1 class="display-3 text-primary mb-0 pb-0">Drop CV</h1>
      <div class="text-dark lead pt-3 pt-md-4 pb-4 pb-md-5 line-height-180">Find the job you need. Easily apply for the
        jobs you want.</div>
      <form>
        <div class="row align-items-center justify-content-center pb-5">
          <div class="col-12">
            <div class="border border-white shadow rounded p-4 p-md-5 p-lg-4 p-xl-5">
              <div class="row">
                <div class="col-lg-6 col-md-12 text-left">
                  <div class="form-group">
                    <label for="name" class="col-12 col-form-label text-left control-label">Name*</label>
                    <div class="col-12">
                      <input #name="ngModel" (blur)="validasi('nama')" class="form-control" type="text" id="name"
                        name="name" [(ngModel)]="data.nama" required [class.is-invalid]="invalid.nama">
                      <span class="help-block" [class.d-none]="!invalid.nama">
                        <div class="red-text">*Name tidak boleh kosong.</div>
                      </span>
                    </div>
                  </div>
                </div>
                <div class="col-lg-6 col-md-12 text-left">
                  <div class="form-group">
                    <label for="hp" class="col-12 col-form-label text-left control-label">No. Handphone</label>
                    <div class="col-12">
                      <input #hp="ngModel" (blur)="clearHp()" class="form-control" type="text" id="hp" name="hp" [(ngModel)]="data.hp">
                    </div>
                  </div>
                </div>
                <div class="col-lg-12 col-md-12 text-left">
                  <div class="form-group ">
                    <label for="email" class="col-12 col-form-label text-left">Posisi</label>
                    <div class="col-12">
                      <select2 [data]="posisiKerja" 
                      (update)="updateSelectePosisi($event)" [minCharForSearch]="1"
                      [customSearchEnabled]="true"
                      (search)="searchSelectePosisi($event)">
                  </select2>
                      <!-- <select #posisi="ngModel" id="posisi" name="posisi"
                                class="form-control" [(ngModel)]="data.posisi" 
                                >
                                <option *ngFor="let rel of posisiKerja" [ngValue]="rel">
                                  {{rel}}
                                </option>
                              </select> -->
                    </div>
                  </div>
                </div>
                <div class="col-lg-12 col-md-12 text-left">
                  <div class="form-group ">
                    <label for="email" class="col-12 col-form-label text-left">Email Address</label>
                    <div class="col-12">
                      <input #email="ngModel" type="text" class="form-control" id="email" name="email"
                        placeholder="contoh : email@contoh.com" [(ngModel)]="data.email"
                        [class.is-invalid]="invalid.email" (blur)="validasi('email')"
                        pattern="^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$">
                      <span class="help-block" [class.d-none]="!invalid.email">
                        <div class="red-text">*Email tidak valid.</div>
                      </span>
                    </div>
                  </div>
                </div>
                <div class="col-lg-12 col-md-12 text-left">
                  <div class="form-group ">
                    <label class="col-12 col-form-label">
                      File CV (format .pdf)*
                    </label>
                    <div class="col-12">
                      <!-- <app-gcs-upload [acceptFile]="['application/pdf']" [remark]="''" [isPublicFile]="true"
                        [prefix]="'uploads/cv/'" [module]="'users'" [tableTarget]="'drop_cv'" [columnTarget]="'file'"
                        (success)="onSuccess($event, 'file')">
                      </app-gcs-upload> -->
                      <app-direct-upload [tableTarget]="'drop_cv'" [columnTarget]="'file'" [prefix]="'uploads/cv/'"
                      [acceptFile]="'.pdf'" [filesizeMaximum]="10000000" (success)="onUploaded($event, 'file')">
                    </app-direct-upload>
                    </div>
                  </div>
                </div>
                <div class="col-md-12">
                  <button type="reset" (click)="saveCV()" class="btn btn-primary mr-2">Submit</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>

  </div>
  <div class="col-lg-5 col-xl-6">
    <div class="pl-0 pl-xl-5 d-none d-lg-block">
      <img src="./assets/media/5g/dropcv.jpg" class="w-100" alt="" />
    </div>
  </div>
</div>