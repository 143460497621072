import { OnInit, EventEmitter } from '@angular/core';
import Swal from 'sweetalert2';
import { NgbCalendar, NgbInputDatepicker } from '@ng-bootstrap/ng-bootstrap';
var TableAdvanceComponent = /** @class */ (function () {
    function TableAdvanceComponent(calendar) {
        this.calendar = calendar;
        this.InsertEvent = new EventEmitter();
        this.pageSizeEvent = new EventEmitter();
        this.pageEvent = new EventEmitter();
        this.orderEvent = new EventEmitter();
        this.filterInput = new EventEmitter();
        this.checkboxHandling = new EventEmitter();
        this.selectAll = false;
        this.selectedCb = [];
        this.checkboxModel = {};
        this.dateRange = {};
    }
    TableAdvanceComponent.prototype.ngOnInit = function () {
        this.toDay = this.calendar.getToday();
        for (var _i = 0, _a = this.tableAdvanceConfig.columnOption; _i < _a.length; _i++) {
            var colOpt = _a[_i];
            if (colOpt['setPipe'] && colOpt['setPipe'] == 'date') {
                var k = colOpt['name'];
                if (this.tableAdvanceConfig.filter[k] && this.tableAdvanceConfig.filter[k]['from'] && this.tableAdvanceConfig.filter[k]['to']) {
                    var toDay = new Date(this.tableAdvanceConfig.filter[k]['to'] + ' 00:00:00');
                    var from = new Date(this.tableAdvanceConfig.filter[k]['from'] + ' 00:00:00');
                    var to = new Date(this.tableAdvanceConfig.filter[k]['to'] + ' 00:00:00');
                    var diffTimeFrom = Math.abs(toDay.getTime() - from.getTime());
                    var diffDaysFrom = Math.ceil(diffTimeFrom / (1000 * 60 * 60 * 24));
                    var diffTimeTo = Math.abs(toDay.getTime() - to.getTime());
                    var diffDaysTo = Math.ceil(diffTimeTo / (1000 * 60 * 60 * 24));
                    var d = {
                        hoveredDate: null,
                        fromDate: ((diffDaysFrom != 0) ? this.calendar.getNext(this.calendar.getToday(), 'd', -1 * diffDaysFrom) : this.calendar.getToday()),
                        strFromDate: this.tableAdvanceConfig.filter[k]['from'],
                        toDate: ((diffDaysTo != 0) ? this.calendar.getNext(this.calendar.getToday(), 'd', -1 * diffDaysTo) : this.calendar.getToday()),
                        strToDate: this.tableAdvanceConfig.filter[k]['to']
                    };
                    this.dateRange[k] = d;
                }
                else {
                    var d = {
                        hoveredDate: null,
                        fromDate: null,
                        strFromDate: '',
                        toDate: null,
                        strToDate: ''
                    };
                    this.dateRange[k] = d;
                    this.tableAdvanceConfig.filter[k] = {};
                }
            }
        }
    };
    TableAdvanceComponent.prototype.generateCheckbox = function () {
        var _this = this;
        this.checkboxModel = {};
        this.tableAdvanceConfig.dataSource.forEach(function (element) {
            var filterSelectedCb = _this.selectedCb.filter(function (fil) { return fil.primaryKey === element[_this.tableAdvanceConfig.primaryKey]; })[0];
            _this.checkboxModel[element[_this.tableAdvanceConfig.primaryKey]] = (filterSelectedCb && filterSelectedCb.selected) ? true : false;
        });
    };
    TableAdvanceComponent.prototype.generateOrderingColumn = function () {
        return this.tableAdvanceConfig.columnOption.filter(function (fil) { return fil.ordering; });
    };
    TableAdvanceComponent.prototype.generateIcon = function (icon) {
        var cssClasses = {};
        if (icon) {
            cssClasses['fa-' + icon] = true;
        }
        return cssClasses;
    };
    TableAdvanceComponent.prototype.orderData = function (column) {
        this.orderEvent.emit(column);
    };
    TableAdvanceComponent.prototype.changePageSize = function ($event) {
        this.pageSizeEvent.emit($event);
        this.selectAll = false;
    };
    TableAdvanceComponent.prototype.changePage = function (page) {
        this.pageEvent.emit(page);
        this.selectAll = false;
    };
    TableAdvanceComponent.prototype.submitFilter = function () {
        this.pageEvent.emit(1);
        this.filterInput.emit(this.tableAdvanceConfig.filter);
    };
    TableAdvanceComponent.prototype.actionSelectAll = function () {
        var _this = this;
        this.tableAdvanceConfig.dataSource.forEach(function (element) {
            _this.checkboxModel[element[_this.tableAdvanceConfig.primaryKey]] = _this.selectAll;
            _this.actionSelectCb(element[_this.tableAdvanceConfig.primaryKey]);
        });
        this.checkUpSelectedCb();
    };
    TableAdvanceComponent.prototype.actionSelectCb = function (key) {
        var indexOfCb = this.selectedCb.findIndex(function (i) { return i.primaryKey === key; });
        if (indexOfCb !== -1) {
            this.selectedCb[indexOfCb].selected = this.checkboxModel[key];
        }
        else {
            this.selectedCb.push({ primaryKey: key, selected: this.checkboxModel[key] });
        }
        this.checkUpSelectedCb();
    };
    TableAdvanceComponent.prototype.checkUpSelectedCb = function () {
        var count = 0;
        this.selectedCb.forEach(function (element) {
            if (element.selected) {
                count += 1;
            }
        });
        this.hasSelectedValue = (count) ? true : false;
    };
    TableAdvanceComponent.prototype.warning = function () {
        var _this = this;
        Swal.fire({
            title: 'Are you sure ?',
            text: 'You won\'t be able to revert this!',
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then(function (result) {
            if (result.value) {
                _this.changeStatus('remove');
            }
        });
    };
    TableAdvanceComponent.prototype.changeStatus = function (type) {
        var selected = [];
        this.selectedCb.forEach(function (element) {
            if (element.selected) {
                selected.push(element.primaryKey);
            }
        });
        this.checkboxHandling.emit({
            action: type,
            keys: selected
        });
    };
    TableAdvanceComponent.prototype.parseColumnOption = function (obj, find, val, get) {
        var temp = obj.filter(function (el) { return el[find] === val; });
        return temp[0][get];
    };
    TableAdvanceComponent.prototype.isHovered = function (date, col) {
        return this.dateRange[col].fromDate && !this.dateRange[col].toDate && this.dateRange[col].hoveredDate && date.after(this.dateRange[col].fromDate) && date.before(this.dateRange[col].hoveredDate);
    };
    TableAdvanceComponent.prototype.isInside = function (date, col) {
        return date.after(this.dateRange[col].fromDate) && date.before(this.dateRange[col].toDate);
    };
    TableAdvanceComponent.prototype.isRange = function (date, col) {
        return date.equals(this.dateRange[col].fromDate) || date.equals(this.dateRange[col].toDate) || this.isInside(date, col) || this.isHovered(date, col);
    };
    TableAdvanceComponent.prototype.dateParse = function (date) {
        var _date = new Date(date);
        return { year: _date.getFullYear(), month: _date.getMonth() + 1, day: _date.getDate() };
    };
    TableAdvanceComponent.prototype.formatDate = function (_a) {
        var year = _a.year, month = _a.month, day = _a.day;
        var _year, _month, _day;
        _year = year;
        _month = (month < 10) ? '0' + month : month;
        _day = (day < 10) ? '0' + day : day;
        return [_year, _month, _day].join('-');
    };
    TableAdvanceComponent.prototype.onDateSelection = function (date, col) {
        if (!this.dateRange[col].fromDate && !this.dateRange[col].toDate) {
            this.dateRange[col].fromDate = date;
            this.dateRange[col].strFromDate = this.formatDate(this.dateRange[col].fromDate);
            this.tableAdvanceConfig.filter[col]['from'] = this.dateRange[col].strFromDate;
        }
        else if (this.dateRange[col].fromDate && !this.dateRange[col].toDate && date.after(this.dateRange[col].fromDate)) {
            this.dateRange[col].toDate = date;
            this.dateRange[col].strToDate = this.formatDate(this.dateRange[col].toDate);
            this.tableAdvanceConfig.filter[col]['to'] = this.dateRange[col].strToDate;
        }
        else {
            this.dateRange[col].toDate = null;
            this.dateRange[col].strToDate = '';
            this.dateRange[col].fromDate = date;
            this.dateRange[col].strFromDate = this.formatDate(this.dateRange[col].fromDate);
            this.tableAdvanceConfig.filter[col]['from'] = this.dateRange[col].strFromDate;
        }
        if (this.dateRange[col].fromDate && this.dateRange[col].toDate) {
            this.datePicker.close();
            this.submitFilter();
        }
    };
    return TableAdvanceComponent;
}());
export { TableAdvanceComponent };
