import { OnInit, EventEmitter } from '@angular/core';
import { NgbModal, NgbDate, NgbInputDatepicker, NgbCalendar } from '@ng-bootstrap/ng-bootstrap';
import Swal from 'sweetalert2';
import { GlobalService } from 'src/app/_services/global.service';
import { DropzoneComponent } from 'ngx-dropzone-wrapper';
var TableCommonComponent = /** @class */ (function () {
    function TableCommonComponent(modalService, globalService, calendar) {
        this.modalService = modalService;
        this.globalService = globalService;
        this.calendar = calendar;
        this.currentRow = 0;
        this.currentEdit = {};
        this.currentDeleted = {};
        this.expandedElement = false;
        this.onEdit = false;
        this.checkboxAll = false;
        this.dateRange = {};
        this.currentPageSizeEvent = new EventEmitter();
        this.currentPageEvent = new EventEmitter();
        this.filterInput = new EventEmitter();
        this.sortEvent = new EventEmitter();
        this.checkboxAction = new EventEmitter();
        this.rowEdited = new EventEmitter();
        this.rowDeleted = new EventEmitter();
        this.rowCancelEdit = new EventEmitter();
        this.expandRow = new EventEmitter();
        this.rowAction = new EventEmitter();
    }
    TableCommonComponent.prototype.ngOnInit = function () {
        this.api_url = this.globalService.apiHost;
        this.api_version_url = this.globalService.apiVersionHost;
        this.currentPageSize = this.tableCommonConfig.paginateOption.pageSize;
        this.toDay = this.calendar.getToday();
        for (var _i = 0, _a = this.tableCommonConfig.columnOption; _i < _a.length; _i++) {
            var colOpt = _a[_i];
            if (colOpt['inputType'] && colOpt['inputType'] == 'date') {
                var k = colOpt['column'];
                if (this.tableCommonConfig.filter[k] && this.tableCommonConfig.filter[k]['from'] && this.tableCommonConfig.filter[k]['to']) {
                    var toDay = new Date(this.tableCommonConfig.filter[k]['to'] + ' 00:00:00');
                    var from = new Date(this.tableCommonConfig.filter[k]['from'] + ' 00:00:00');
                    var to = new Date(this.tableCommonConfig.filter[k]['to'] + ' 00:00:00');
                    var diffTimeFrom = Math.abs(toDay.getTime() - from.getTime());
                    var diffDaysFrom = Math.ceil(diffTimeFrom / (1000 * 60 * 60 * 24));
                    var diffTimeTo = Math.abs(toDay.getTime() - to.getTime());
                    var diffDaysTo = Math.ceil(diffTimeTo / (1000 * 60 * 60 * 24));
                    var d = {
                        hoveredDate: null,
                        fromDate: ((diffDaysFrom != 0) ? this.calendar.getNext(this.calendar.getToday(), 'd', -1 * diffDaysFrom) : this.calendar.getToday()),
                        strFromDate: this.tableCommonConfig.filter[k]['from'],
                        toDate: ((diffDaysTo != 0) ? this.calendar.getNext(this.calendar.getToday(), 'd', -1 * diffDaysTo) : this.calendar.getToday()),
                        strToDate: this.tableCommonConfig.filter[k]['to']
                    };
                    this.dateRange[k] = d;
                }
                else {
                    var d = {
                        hoveredDate: null,
                        fromDate: null,
                        strFromDate: '',
                        toDate: null,
                        strToDate: ''
                    };
                    this.dateRange[k] = d;
                    this.tableCommonConfig.filter[k] = {};
                }
            }
        }
    };
    TableCommonComponent.prototype.resetCheckbox = function () {
        this.checkboxAll = false;
        if (this.tableCommonConfig.checkboxOption) {
            for (var _i = 0, _a = this.tableCommonConfig.checkboxOption.itemCheckbox; _i < _a.length; _i++) {
                var item = _a[_i];
                item.isSelected = false;
            }
        }
    };
    TableCommonComponent.prototype.checkboxAllTriggered = function () {
        for (var _i = 0, _a = this.tableCommonConfig.checkboxOption.itemCheckbox; _i < _a.length; _i++) {
            var item = _a[_i];
            item.isSelected = this.checkboxAll;
        }
    };
    TableCommonComponent.prototype.changePageSize = function () {
        // this.currentPageEvent.emit(1);
        this.currentPageSizeEvent.emit(this.currentPageSize);
        this.resetCheckbox();
    };
    TableCommonComponent.prototype.changePage = function (page, allow) {
        if (!allow) {
            this.currentPageEvent.emit(page);
            this.resetCheckbox();
        }
    };
    TableCommonComponent.prototype.submitFilter = function () {
        this.currentPageEvent.emit(1);
        this.filterInput.emit(this.tableCommonConfig.filter);
    };
    TableCommonComponent.prototype.resetFilter = function () {
        this.tableCommonConfig.filter = {};
        for (var i in this.dateRange) {
            this.dateRange[i] = {
                hoveredDate: null,
                fromDate: null,
                strFromDate: '',
                toDate: null,
                strToDate: ''
            };
            this.tableCommonConfig.filter[i] = {};
        }
        this.currentPageEvent.emit(1);
        this.filterInput.emit(this.tableCommonConfig.filter);
    };
    TableCommonComponent.prototype.expandToogle = function (id) {
        this.expandedElement = (this.currentRow === id) ? !this.expandedElement : true;
        this.currentRow = id;
        this.expandRow.emit({ expand: this.expandedElement, id: this.currentRow });
    };
    TableCommonComponent.prototype.buildRoute = function (row, route, param) {
        var newRoute = [];
        newRoute.push(route);
        // tslint:disable-next-line: forin
        for (var key in param) {
            var temp = row[param[key]];
            if (!temp) {
                temp = param[key];
            }
            newRoute.push(temp);
        }
        return newRoute;
    };
    TableCommonComponent.prototype.extractParam = function (row, obj) {
        var param = {};
        // tslint:disable-next-line: forin
        for (var key in obj) {
            param[key] = row[obj[key]];
        }
        return param;
    };
    TableCommonComponent.prototype.openModal = function (param, componentModal, modalOption) {
        if (modalOption === void 0) { modalOption = { size: 'lg' }; }
        var modalRef = this.modalService.open(componentModal, modalOption);
        // tslint:disable-next-line: forin
        for (var key in param) {
            modalRef.componentInstance[key] = param[key];
        }
    };
    TableCommonComponent.prototype.sendParam = function (param, action) {
        var _this = this;
        var data = { param: param, action: action };
        if (action === 'view' || action === 'edit' || action === 'download' || action === 'other') {
            this.rowAction.emit(data);
        }
        if (action === 'delete') {
            Swal.fire({
                title: 'Are you sure ?',
                text: 'You won\'t be able to revert this!',
                type: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, delete it!'
            }).then(function (result) {
                if (result.value) {
                    _this.rowAction.emit(data);
                }
            });
        }
    };
    TableCommonComponent.prototype.parseColumnOption = function (obj, find, val, get) {
        var temp = obj.filter(function (el) { return el[find] === val; });
        return temp[0][get];
    };
    TableCommonComponent.prototype.checkboxActionTrigger = function (event) {
        this.checkboxAction.emit({ message: event, data: this.tableCommonConfig.checkboxOption.itemCheckbox });
    };
    TableCommonComponent.prototype.edit = function (row) {
        var id = row[this.tableCommonConfig.primaryKey];
        this.onEdit = (this.currentRow === id) ? !this.onEdit : true;
        this.currentRow = id;
        this.currentEdit = row;
        var inputDate = this.tableCommonConfig.columnOption.filter(function (el) { return el.inputType === 'date'; });
        for (var _i = 0, inputDate_1 = inputDate; _i < inputDate_1.length; _i++) {
            var input = inputDate_1[_i];
            var tempDate = new Date();
            var tempObjDate = { year: tempDate.getFullYear(), month: tempDate.getMonth() + 1, day: tempDate.getDate() };
            this.currentEdit[input.column] = (this.currentEdit[input.column]) ? this.dateParse(this.currentEdit[input.column]) : tempObjDate;
        }
    };
    TableCommonComponent.prototype.save = function () {
        var inputDate = this.tableCommonConfig.columnOption.filter(function (el) { return el.inputType === 'date'; });
        for (var _i = 0, inputDate_2 = inputDate; _i < inputDate_2.length; _i++) {
            var input = inputDate_2[_i];
            this.currentEdit[input.column] = this.formatDate(this.currentEdit[input.column]);
        }
        this.rowEdited.emit(this.currentEdit);
        this.onEdit = false;
    };
    TableCommonComponent.prototype.cancelEdit = function () {
        this.onEdit = false;
        this.rowCancelEdit.emit();
    };
    TableCommonComponent.prototype.delete = function (row) {
        var _this = this;
        var id = row[this.tableCommonConfig.primaryKey];
        this.currentRow = id;
        this.currentDeleted = row;
        Swal.fire({
            title: 'Are you sure ?',
            text: 'You won\'t be able to revert this!',
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then(function (result) {
            if (result.value) {
                _this.rowDeleted.emit(_this.currentDeleted);
            }
        });
    };
    TableCommonComponent.prototype.getSelectedTypehead = function ($event, col) {
        this.currentEdit[col] = $event.key;
    };
    TableCommonComponent.prototype.getSelectedSelect = function (val, col) {
        this.currentEdit[col] = val;
    };
    TableCommonComponent.prototype.isHovered = function (date, col) {
        return this.dateRange[col].fromDate && !this.dateRange[col].toDate && this.dateRange[col].hoveredDate && date.after(this.dateRange[col].fromDate) && date.before(this.dateRange[col].hoveredDate);
    };
    TableCommonComponent.prototype.isInside = function (date, col) {
        return date.after(this.dateRange[col].fromDate) && date.before(this.dateRange[col].toDate);
    };
    TableCommonComponent.prototype.isRange = function (date, col) {
        return date.equals(this.dateRange[col].fromDate) || date.equals(this.dateRange[col].toDate) || this.isInside(date, col) || this.isHovered(date, col);
    };
    TableCommonComponent.prototype.dateParse = function (date) {
        var _date = new Date(date);
        return { year: _date.getFullYear(), month: _date.getMonth() + 1, day: _date.getDate() };
    };
    TableCommonComponent.prototype.formatDate = function (_a) {
        var year = _a.year, month = _a.month, day = _a.day;
        var _year, _month, _day;
        _year = year;
        _month = (month < 10) ? '0' + month : month;
        _day = (day < 10) ? '0' + day : day;
        return [_year, _month, _day].join('-');
    };
    TableCommonComponent.prototype.onDateSelection = function (date, col) {
        if (!this.dateRange[col].fromDate && !this.dateRange[col].toDate) {
            this.dateRange[col].fromDate = date;
            this.dateRange[col].strFromDate = this.formatDate(this.dateRange[col].fromDate);
            this.tableCommonConfig.filter[col]['from'] = this.dateRange[col].strFromDate;
        }
        else if (this.dateRange[col].fromDate && !this.dateRange[col].toDate && date.after(this.dateRange[col].fromDate)) {
            this.dateRange[col].toDate = date;
            this.dateRange[col].strToDate = this.formatDate(this.dateRange[col].toDate);
            this.tableCommonConfig.filter[col]['to'] = this.dateRange[col].strToDate;
        }
        else {
            this.dateRange[col].toDate = null;
            this.dateRange[col].strToDate = '';
            this.dateRange[col].fromDate = date;
            this.dateRange[col].strFromDate = this.formatDate(this.dateRange[col].fromDate);
            this.tableCommonConfig.filter[col]['from'] = this.dateRange[col].strFromDate;
        }
        if (this.dateRange[col].fromDate && this.dateRange[col].toDate) {
            this.datePicker.close();
            this.submitFilter();
        }
    };
    TableCommonComponent.prototype.onSuccess = function ($event, col) {
        this.currentEdit[col] = $event.id.toString();
    };
    TableCommonComponent.prototype.sortColumn = function (col) {
        if (this.tableCommonConfig.sortBy[col] == '-') {
            this.tableCommonConfig.sortBy[col] = 'asc';
        }
        else if (this.tableCommonConfig.sortBy[col] == 'asc') {
            this.tableCommonConfig.sortBy[col] = 'desc';
        }
        else if (this.tableCommonConfig.sortBy[col] == 'desc') {
            this.tableCommonConfig.sortBy[col] = '-';
        }
        else {
            this.tableCommonConfig.sortBy[col] = '-';
        }
        this.sortEvent.emit(this.tableCommonConfig.sortBy);
    };
    TableCommonComponent.prototype.stepDate = function (pass, i, approval_date) {
        var x = '';
        if (approval_date !== null) {
            var appdate = approval_date.split('.', 2);
            appdate = appdate[0];
            // let x: any = '';
            if (pass == 1) {
                if (i == 0) {
                    x = appdate;
                }
                else {
                    x = this.getinterval(this.lastApproval, appdate);
                    x = appdate + ' (Approval time: ' + x + ')';
                }
            }
            else {
                x = ' (Waiting : ' + this.getinterval(this.lastApproval, 'x') + ')';
            }
            this.lastApproval = appdate;
        }
        return x;
    };
    TableCommonComponent.prototype.getinterval = function (from, to) {
        var now = new Date();
        var F;
        if (to == 'x') {
            F = now;
        }
        else {
            F = new Date(to);
        }
        var N = new Date(from);
        var dateFuture = F.getTime();
        var dateNow = N.getTime();
        var seconds = Math.floor((dateFuture - (dateNow)) / 1000);
        var minutes = Math.floor(seconds / 60);
        var hours = Math.floor(minutes / 60);
        var days = Math.floor(hours / 24);
        hours = hours - (days * 24);
        minutes = minutes - (days * 24 * 60) - (hours * 60);
        seconds = seconds - (days * 24 * 60 * 60) - (hours * 60 * 60) - (minutes * 60);
        var x = '';
        if (days > 0) {
            x = days + (days > 1 ? ' days ' : ' day ');
        }
        return x + hours + ':' + minutes + ':' + seconds;
    };
    TableCommonComponent.prototype.arrexplode = function (string) {
        return string.split('|');
    };
    TableCommonComponent.prototype.complateDuration = function (pass, i, approval_date, data, type) {
        var labelAging = '';
        if (approval_date !== null) {
            var appdate = approval_date.split('.', 2);
            appdate = appdate[0];
            if (pass == 1) {
                if (i == 0) {
                    labelAging = appdate;
                }
                else {
                    var aging = (data < 0) ? 0 : data;
                    var seconds = 0;
                    if (type == 'hour') {
                        seconds = aging * 3600;
                    }
                    else if (type == 'minute') {
                        seconds = aging * 60;
                    }
                    else {
                        seconds = aging * 3600;
                    }
                    var minutes = Math.floor(seconds / 60);
                    var hours = Math.floor(minutes / 60);
                    var days = Math.floor(hours / 24);
                    hours = hours - (days * 24);
                    minutes = minutes - (days * 24 * 60) - (hours * 60);
                    seconds = seconds - (days * 24 * 60 * 60) - (hours * 60 * 60) - (minutes * 60);
                    if (days > 0) {
                        labelAging = days + (days > 1 ? ' days ' : ' day ');
                    }
                    labelAging = labelAging + hours + ':' + minutes + ':' + seconds;
                    labelAging = appdate + ' (Approval time: ' + labelAging + ')';
                }
            }
            else {
                labelAging = ' (Waiting : ' + this.getinterval(appdate, 'x') + ')';
            }
        }
        return labelAging;
    };
    return TableCommonComponent;
}());
export { TableCommonComponent };
