<!-- begin:: Header -->
<div #kt_header id="kt_header" class="kt-header kt-grid__item kt-header--fixed" (window:resize)="onResize($event)">
  <!-- begin:: Header Menu -->
  <button (click)="toggleMenuHeaderMobile()" class="kt-header-menu-wrapper-close" id="kt_header_menu_mobile_close_btn"><i
      class="la la-close"></i></button>
  <div class="kt-header-menu-wrapper" [ngClass]="{'kt-header-menu-wrapper--on': headerMenu}" id="kt_header_menu_wrapper">
    <div id="kt_header_menu" class="kt-header-menu kt-header-menu-mobile kt-header-menu--layout-default ">
      <ul class="kt-menu__nav ">
        <ng-container *ngFor="let menu of menuHeader; let i = index;">
          <li [ngClass]="{'kt-menu__item--open kt-menu__item--active kt-menu__item--open-dropdown kt-menu__item--hover': menu.toggle}"
            class="kt-menu__item  kt-menu__item--submenu kt-menu__item--rel" data-ktmenu-submenu-toggle="click"
            aria-haspopup="true">
            <ng-container *ngIf="menu.typeMenu == 'dropDown'">
              <a (click)="toggleMenuHeader(i)" href="javascript:;" class="kt-menu__link kt-menu__toggle">
                <span class="kt-menu__link-text">{{menu.title}}</span>
                <i class="kt-menu__ver-arrow la la-angle-right"></i>
              </a>
              <div class="kt-menu__submenu kt-menu__submenu--classic kt-menu__submenu--left">
                <ul class="kt-menu__subnav">
                  <ng-container *ngFor="let submenu of menu.items">
                    <li class="kt-menu__item " aria-haspopup="true">
                      <ng-container [ngSwitch]="true">
                        <ng-container *ngSwitchCase="moduleActive == 'pmc' && submenu.title != 'My Task' && submenu.title != 'My Approval'">
                          <a [routerLink]="(hash)?submenu.route.concat(hash):submenu.route" class="kt-menu__link ">
                            <span class="kt-menu__link-icon"><i class="{{submenu.icon}}" [style.font-size.px]="24"></i></span>
                            <span class="kt-menu__link-text">{{submenu.title}}</span>
                          </a>
                        </ng-container>
                        <ng-container *ngSwitchDefault>
                          <a [routerLink]="submenu.route" class="kt-menu__link ">
                            <span class="kt-menu__link-icon"><i class="{{submenu.icon}}" [style.font-size.px]="24"></i></span>
                            <span class="kt-menu__link-text">{{submenu.title}}</span>
                          </a>
                        </ng-container>
                      </ng-container>
                    </li>
                  </ng-container>
                </ul>
              </div>
            </ng-container>
            <ng-container *ngIf="menu.typeMenu == 'route'">
              <ng-container [ngSwitch]="true">
                <ng-container *ngSwitchCase="moduleActive == 'pmc'">
                  <a [routerLink]="(hash)?menu.route.concat(hash):menu.route" class="kt-menu__link">
                    <span class="kt-menu__link-text">{{menu.title}}</span>
                  </a>
                </ng-container>
                <ng-container *ngSwitchDefault>
                  <a [routerLink]="menu.route" class="kt-menu__link">
                    <span class="kt-menu__link-text">{{menu.title}}</span>
                  </a>
                </ng-container>
              </ng-container>
            </ng-container>
          </li>
        </ng-container>
      </ul>
    </div>
  </div>
  <ng-container *ngIf="headerMenu">
    <div class="kt-header-menu-wrapper-overlay" (click)="toggleMenuHeaderMobile()"></div>
  </ng-container>
  <!-- end:: Header Menu -->
  <!-- begin:: Header Topbar -->
  <div class="kt-header__topbar">
    <ng-container *ngIf="allowNotification else EnableNotification">
    <!--begin: Notifications -->
    <div class="kt-header__topbar-item kt-header__topbar-item--notifications">
      <div (click)="toggleMenuHeader(null,false,!notifDropDownShowed)" class="kt-header__topbar-wrapper" data-toggle="dropdown"
        data-offset="10px,0px" aria-expanded="true">
        <span class="kt-header__topbar-icon kt-label-font-color-4"><i class="flaticon2-bell-alarm-symbol "></i></span>
        <ng-container *ngIf="total_notif>0 || total_notif=='99+'">
          <span class="kt-badge kt-badge--danger kt-badge--sm notification-number">{{total_notif}}</span>
        </ng-container>
      </div>
      <div [ngClass]="{'show': notifDropDownShowed}" class="dropdown-menu dropdown-menu-fit dropdown-menu-right dropdown-menu-anim dropdown-menu-top-unround dropdown-menu-xl"
        id="nds_header_notification" x-placement="top-end" [ngStyle]="{'position': 'absolute', 'will-change': 'transform', 'top.px': 0, 'left.px': 0, 'transform': 'translate3d('+userDropdownOffset+'px, 64px, 0px)'}">
        <div (click)="toggleMenuHeader(null,false,!notifDropDownShowed)" class="kt-notification">
          <ng-container *ngIf="notifications">
            <ng-container *ngFor="let notif of notifications">
              <a (click)="clickNotif(notif)" class="kt-notification__item">
                <div class="kt-notification__item-details">
                  <div class="kt-notification__item-title kt-font-bold">
                    {{notif['description']}}
                  </div>
                </div>
              </a>
            </ng-container>
            <div class="kt-notification__custom kt-space-between">
              <a [routerLink]="['/main/notification/index']" class="btn btn-label btn-label-brand btn-sm btn-bold">See
                all notifications</a>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
    <!--end: Notifications -->
    </ng-container>
    <!--begin: User Bar -->
    <div class="kt-header__topbar-item kt-header__topbar-item--user">
      <div (click)="toggleMenuHeader(null,!userDropDownShowed,false)" class="kt-header__topbar-wrapper" data-toggle="dropdown"
        data-offset="0px,0px">
        <div class="kt-header__topbar-user">
          <span class="kt-header__topbar-welcome kt-hidden-mobile">Hi,</span>
          <span class="kt-header__topbar-username kt-hidden-mobile">{{firstName}}</span>
          <img *ngIf="user.profile" alt="Pic" [src]="[apiHost+'/site/download-profile/'+user.profile]" />

          <!--use below badge element instead the user avatar to display username's first letter(remove kt-hidden class to display it) -->
          <span *ngIf="!user.profile" class="kt-badge kt-badge--username kt-badge--unified-success kt-badge--lg kt-badge--rounded kt-badge--bold">{{user.nama.substring(0,
            1)}}</span>
        </div>
      </div>
      <div [ngClass]="{'show': userDropDownShowed}" class="dropdown-menu dropdown-menu-fit dropdown-menu-right dropdown-menu-anim dropdown-menu-top-unround dropdown-menu-xl"
        x-placement="top-end" [ngStyle]="{'position': 'absolute', 'will-change': 'transform', 'top.px': 0, 'left.px': 0, 'transform': 'translate3d('+userDropdownOffset+'px, 64px, 0px)'}">
        <!--begin: Head -->
        <div class="kt-user-card kt-user-card--skin-dark kt-notification-item-padding-x" style="background-image: url(assets/media/misc/bg-1.jpg)">
          <div class="kt-user-card__avatar">
            <img *ngIf="user.profile" alt="Pic" [src]="[apiHost+'/site/download-profile/'+user.profile]" />

            <!--use below badge element instead the user avatar to display username's first letter(remove kt-hidden class to display it) -->
            <span *ngIf="!user.profile" class="kt-badge kt-badge--lg kt-badge--rounded kt-badge--bold kt-font-success">{{user.nama.substring(0,
              1)}}</span>
          </div>
          <div class="kt-user-card__name">
            {{user.nama}}
          </div>
          <!-- <div class="kt-user-card__badge">
            <span class="btn btn-success btn-sm btn-bold btn-font-md">23 messages</span>
          </div> -->
        </div>

        <!--end: Head -->

        <!--begin: Navigation -->
        <div (click)="toggleMenuHeader(null,!userDropDownShowed,false)" class="kt-notification">
          <a [routerLink]="['/main/employee/profile']" class="kt-notification__item">
            <div class="kt-notification__item-icon">
              <i class="flaticon2-calendar-3 kt-font-success"></i>
            </div>
            <div class="kt-notification__item-details">
              <div class="kt-notification__item-title kt-font-bold">
                My Profile
              </div>
              <!-- <div class="kt-notification__item-time">
                Account settings and more
              </div> -->
            </div>
          </a>
          <a [routerLink]="['/main/attendance/my-attendance']" class="kt-notification__item">
            <div class="kt-notification__item-icon">
              <i class="flaticon-calendar-with-a-clock-time-tools kt-font-warning"></i>
            </div>
            <div class="kt-notification__item-details">
              <div class="kt-notification__item-title kt-font-bold">
                My Attendance
              </div>
              <!-- <div class="kt-notification__item-time">
                Attendance Summary
              </div> -->
            </div>
          </a>
          <a (click)="reloadAuth()" class="kt-notification__item">
            <div class="kt-notification__item-icon">
              <i class="flaticon2-lock kt-font-warning"></i>
            </div>
            <div class="kt-notification__item-details">
              <div class="kt-notification__item-title kt-font-bold">
                Reload Authorization
              </div>
            </div>
          </a>
          <a [routerLink]="['/main/employee/change-password']" class="kt-notification__item">
            <div class="kt-notification__item-icon">
              <i class="flaticon2-user-1 kt-font-primary"></i>
            </div>
            <div class="kt-notification__item-details">
              <div class="kt-notification__item-title kt-font-bold">
                Change Password
              </div>
            </div>
          </a>
          <div class="kt-notification__custom kt-space-between">
            <button (click)="logout()" class="btn btn-label btn-label-brand btn-sm btn-bold">Sign Out</button>
          </div>
        </div>

        <!--end: Navigation -->
      </div>
    </div>
    <!--end: User Bar -->
  </div>

</div>
<!-- end:: Header -->

<!-- Pop when notification was block -->
<ng-template #EnableNotification>
  <div class="kt-header__topbar-item mx-2 my-2 my-sm-2 mt-md-3">
    <span class="my-2 mr-1 pt-1">Your notification was blocked!</span>
    <a href="javascript:;" class="btn btn-label-warning btn-bold" style="height: 40px;" (click)="open(notificationGuide)">
      Enable
    </a>
  </div>
</ng-template>

<!-- Allow Notification Guide Modal -->
<ng-template #notificationGuide let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Allow Notification</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <img src="./assets/media/misc/allow_notif.png" alt="image" class="w-100"/>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-dark" (click)="modal.close()">Close</button>
  </div>
</ng-template>
