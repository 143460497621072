<ng-container *ngIf="errors">
  <div class="card mb-5">
    <ngb-alert *ngFor="let err of errors" type="danger" [dismissible]="false">
      <strong>{{err}}</strong>
    </ngb-alert>
  </div>
</ng-container>

<ng-container *ngIf="error">
  <ngb-alert *ngFor="let err of error" type="danger" [dismissible]="false">
    <strong>{{err}}</strong>
  </ngb-alert>
</ng-container>
<ng-container *ngIf="success">
  <ngb-alert *ngFor="let succ of success" type="success" [dismissible]="false">
    <strong>{{succ}}</strong>
  </ngb-alert>
</ng-container>
<ng-container *ngIf="warning">
  <ngb-alert *ngFor="let warn of warning" type="warning" [dismissible]="true" (close)="warning = []">
    <strong>Perhatian!</strong>&nbsp;{{warn}}
  </ngb-alert>
</ng-container>