<ng-container *ngIf="moduleActive !== 'hse' && moduleActive !== 'carrepair'">
  <div class="kt-footer kt-grid__item kt-grid kt-grid--desktop kt-grid--ver-desktop" style="z-index: 1;" id="kt_footer">
    <div id="nds_footer" style="display:inherit;">
      <div class="kt-footer__copyright">
        {{currentDate}}&nbsp;&copy;&nbsp;
        <a href="javascript:;" target="_blank" class="kt-link">
          NDS
        </a>
      </div>
      <div class="kt-footer__menu">
        <a href="javascript:;" target="_blank" class="kt-footer__menu-link kt-link">
          About
        </a>
        <a href="javascript:;" target="_blank" class="kt-footer__menu-link kt-link">
          Team
        </a>
        <a href="javascript:;" target="_blank" class="kt-footer__menu-link kt-link">
          Contact
        </a>
        <a href="javascript:;" target="_blank" class="text-right kt-footer__menu-link kt-link">
          build version : {{buildVersion}}
        </a>
      </div>
    </div>
  </div>
</ng-container>
