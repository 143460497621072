<div class="row mb-10" style="margin-bottom: 10px" *ngIf="tableCommonConfig.checkboxOption">
  <div class="col-md-12">
    <div class="btn-group" role="group" aria-label="First group">
      <ng-container *ngFor="let btn of tableCommonConfig.checkboxOption.buttonOption">
        <button type="button" class="btn" [ngClass]="btn.btnClass" (click)="checkboxActionTrigger(btn.dataEmit)"><i
            [ngClass]="btn.icon"></i> {{btn.label}}</button>
      </ng-container>
    </div>
  </div>
</div>

<div [ngClass]="{'card': tableCommonConfig.advanceOption.showPagination}">
  <div [ngClass]="{'scrollable' : tableCommonConfig.advanceOption.showHorizontalScroll}" class="kt-portlet m-0">
    <div class="kt-portlet__body p-0">
      <table id="table-common" class="table table-bordered" *ngIf="(!loading || tableCommonConfig.dataSource) else Loading">
        <thead>
          <tr>
            <td class="align-middle" [attr.rowspan]="((tableCommonConfig.advanceOption.showExpandable && tableCommonConfig.advanceOption.showFilterColumn) || tableCommonConfig.advanceOption.showFilterColumn)?2:1">
              No.
            </td>
            <ng-container *ngIf="tableCommonConfig.checkboxOption">
              <td class="align-middle" [attr.rowspan]="((tableCommonConfig.advanceOption.showExpandable && tableCommonConfig.advanceOption.showFilterColumn) || tableCommonConfig.advanceOption.showFilterColumn)?2:1">
                <label class="kt-checkbox kt-checkbox--bold kt-checkbox--brand">
                  <input type="checkbox" [(ngModel)]="checkboxAll" name="checkboxall" (change)="checkboxAllTriggered()">
                  <span></span>
                </label>
              </td>
            </ng-container>
            <td *ngIf="tableCommonConfig.advanceOption.showExpandable">&nbsp;</td>
            <td *ngIf="tableCommonConfig.advanceOption.showActionColumn" class="text-center"><i class="flaticon-more-1"></i>
            </td>
            <td *ngFor="let col of tableCommonConfig.displayedColumns" [style.white-space]="'nowrap'"
              [style.min-width.px]="100">
              <ng-container *ngIf="tableCommonConfig.sortBy && tableCommonConfig.sortBy[col] else defaultColumn">
                <a href="javascript:;" (click)="sortColumn(col)" class="dis-flex">
                  {{parseColumnOption(tableCommonConfig.columnOption,'column',col,'label')}}
                  <i *ngIf="tableCommonConfig.sortBy[col] == '-'" class="flex-pull-right fa fa-sort"></i>
                  <i *ngIf="tableCommonConfig.sortBy[col] == 'asc'" class="flex-pull-right fa fa-sort-up"></i>
                  <i *ngIf="tableCommonConfig.sortBy[col] == 'desc'" class="flex-pull-right fa fa-sort-down"></i>
                </a>
              </ng-container>
              <ng-template #defaultColumn>
                {{parseColumnOption(tableCommonConfig.columnOption,'column',col,'label')}}
              </ng-template>
            </td>
          </tr>
          <tr *ngIf="tableCommonConfig.advanceOption.showExpandable || tableCommonConfig.advanceOption.showFilterColumn">
            <td *ngIf="tableCommonConfig.advanceOption.showExpandable && tableCommonConfig.advanceOption.showFilterColumn">&nbsp;</td>
            <td *ngIf="tableCommonConfig.advanceOption.showActionColumn && tableCommonConfig.advanceOption.showFilterColumn">
              <div class="btn-group" role="group" aria-label="First group">
                <button (click)="submitFilter()" type="button" class="btn btn-outline-primary btn-sm btn-icon"><i class="fa fa-filter fa-sm"></i></button>
                <button (click)="resetFilter()" type="button" class="btn btn-outline-warning btn-sm btn-icon"><i class="fa fa-sync fa-sm"></i></button>
              </div>
            </td>
            <ng-container *ngIf="tableCommonConfig.advanceOption.showFilterColumn">
              <td *ngFor="let col of tableCommonConfig.displayedColumns">
                <ng-container *ngIf="parseColumnOption(tableCommonConfig.columnOption,'column',col,'showFilter')">
                  <ng-container [ngSwitch]="parseColumnOption(tableCommonConfig.columnOption,'column',col,'inputType')">
                    <ng-container *ngSwitchCase="'date'">
                      <div style="width: 250px;" class='input-group pull-right' [id]="'daterangepicker_'+col">
                        <input class="form-control form-control-sm" placeholder="Select date range" ngbDatepicker
                          #datePicker="ngbDatepicker" [autoClose]="'outside'" (dateSelect)="onDateSelection($event, col)"
                          [displayMonths]="2" [dayTemplate]="customPicker" [navigation]="'select'" [outsideDays]="'visible'"
                          [showWeekNumbers]="false" [startDate]="dateRange[col].fromDate" name="rangeDate"
                          [value]="(dateRange[col].strFromDate | date : 'd MMM y') +' s/d '+ (dateRange[col].strToDate | date : 'd MMM y')"
                          readonly>
                        <div class="input-group-append">
                          <span class="input-group-text" (click)="datePicker.toggle()">
                            <i class="la la-calendar-check-o"></i>
                          </span>
                        </div>
                      </div>
                      <ng-template #customPicker let-date let-focused="focused">
                        <span class="custom-day" [class.focused]="focused" [class.range]="isRange(date, col)"
                          [class.faded]="isHovered(date, col) || isInside(date, col)" (mouseenter)="dateRange[col].hoveredDate = date"
                          (mouseleave)="dateRange[col].hoveredDate = null">
                          {{ date.day }}
                        </span>
                      </ng-template>
                    </ng-container>
                    <ng-container *ngSwitchCase="'text'">
                      <input type="text" [(ngModel)]="tableCommonConfig.filter[col]" class="form-control form-control-sm" (keyup.enter)="submitFilter()"
                        placeholder="filter {{parseColumnOption(tableCommonConfig.columnOption,'column',col,'label')}}">
                    </ng-container>
                    <ng-container *ngSwitchCase="'select'">
                      <select [(ngModel)]="tableCommonConfig.filter[col]" class="form-control form-control-sm" (change)="submitFilter()">
                        <option [value]="''"></option>
                        <ng-container *ngFor="let item of parseColumnOption(tableCommonConfig.columnOption,'column',col,'listOption')">
                          <option [value]="item.key">{{item.val}}</option>
                        </ng-container>
                      </select>
                    </ng-container>
                    <ng-container *ngSwitchCase="'number'">
                      <input type="number" [(ngModel)]="tableCommonConfig.filter[col]" class="form-control form-control-sm"
                        (keyup.enter)="submitFilter()" placeholder="filter {{parseColumnOption(tableCommonConfig.columnOption,'column',col,'label')}}">
                    </ng-container>
                    <ng-container *ngSwitchCase="'period'">
                      <input type="month" [(ngModel)]="tableCommonConfig.filter[col]" class="form-control form-control-sm"
                        (keyup.enter)="submitFilter()" (change)="submitFilter()" placeholder="filter {{parseColumnOption(tableCommonConfig.columnOption,'column',col,'label')}}">
                    </ng-container>
                    <ng-container *ngSwitchDefault>
                      <input type="text" [(ngModel)]="tableCommonConfig.filter[col]" class="form-control form-control-sm" (keyup.enter)="submitFilter()"
                        placeholder="filter {{parseColumnOption(tableCommonConfig.columnOption,'column',col,'label')}}">
                    </ng-container>
                  </ng-container>
                </ng-container>
              </td>
            </ng-container>
          </tr>
        </thead>
        <tbody>
          <ng-container *ngFor="let row of tableCommonConfig.dataSource; let i = index;">
            <tr [ngClass]="{
                'alert-success': tableCommonConfig.rowColorOption?.colorState == 'alert-success' && tableCommonConfig.rowColorOption?.colorActived(row) ,  
                'alert-primary': tableCommonConfig.rowColorOption?.colorState == 'alert-primary' && tableCommonConfig.rowColorOption?.colorActived(row) ,  
                'alert-info': tableCommonConfig.rowColorOption?.colorState == 'alert-info' && tableCommonConfig.rowColorOption?.colorActived(row) ,  
                'alert-warning': tableCommonConfig.rowColorOption?.colorState == 'alert-warning' && tableCommonConfig.rowColorOption?.colorActived(row) ,  
                'alert-danger': tableCommonConfig.rowColorOption?.colorState == 'alert-danger' && tableCommonConfig.rowColorOption?.colorActived(row) ,  
                'alert-dark': tableCommonConfig.rowColorOption?.colorState == 'alert-dark' && tableCommonConfig.rowColorOption?.colorActived(row)}">
              <td>{{(tableCommonConfig.paginateOption.pageNumber-1)*tableCommonConfig.paginateOption.pageSize+(i+1)}}</td>
              <ng-container *ngIf="tableCommonConfig.checkboxOption">
                <td>
                  <label class="kt-checkbox kt-checkbox--bold kt-checkbox--brand">
                    <input type="checkbox" [(ngModel)]="tableCommonConfig.checkboxOption.itemCheckbox[i]['isSelected']"
                      name="item_checkbox">
                    <span></span>
                  </label>
                </td>
              </ng-container>
              <td *ngIf="tableCommonConfig.advanceOption.showExpandable" class="align-middle">
                <button (click)="expandToogle(row[tableCommonConfig.primaryKey])" type="button" class="btn btn-outline-dark btn-icon btn-sm">
                  <i [ngClass]="{'flaticon2-next': (!expandedElement || row[tableCommonConfig.primaryKey] != currentRow), 'flaticon2-down': (expandedElement && row[tableCommonConfig.primaryKey] == currentRow)}"></i></button>
              </td>
              <td *ngIf="tableCommonConfig.advanceOption.showActionColumn">
                <ng-container *ngIf="!tableCommonConfig.advanceOption.isEditableTable || tableCommonConfig.advanceOption.showDropzone === true">
                  <ng-container *ngIf="tableCommonConfig.buttonOption.length > 1">
                    <div ngbDropdown placement="right-bottom" container="body" class="d-inline-block mr-3">
                      <button class="btn btn-sm btn-outline-primary btn-icon" id="dropdownBasic1" ngbDropdownToggle>
                        <i class="flaticon-more-1"></i>
                      </button>
                      <div ngbDropdownMenu aria-labelledby="dropdownBasic1" class="dropdown-menu-right">
                        <ng-container *ngFor="let btn of tableCommonConfig.buttonOption" [ngSwitch]="btn.type">


                          <ng-container *ngIf="btn.visible == undefined || (btn.visible && btn.visible(row)) ">
                            <ng-container *ngSwitchCase="'route'">
                              <ng-template [ngxPermissionsOnly]="((btn.permissions)?btn.permissions:['USER'])">
                                <a ngbDropdownItem [routerLink]="buildRoute(row, btn.route, btn.routeParam)"
                                  [queryParams]="extractParam(row, (btn.queryParam)?btn.queryParam:{})" [hidden]="btn.hideButton">
                                  <span class="mr-3"><i class="{{btn.icon}}"></i></span>
                                  <span>{{btn.label}}</span>
                                </a>
                              </ng-template>
                            </ng-container>
                            <ng-container *ngSwitchCase="'modal'">
                              <ng-template [ngxPermissionsOnly]="((btn.permissions)?btn.permissions:['USER'])">
                                <button ngbDropdownItem (click)="openModal(extractParam(row, btn.param),btn.componentModal)"
                                  [hidden]="btn.hideButton">
                                  <span class="mr-3"><i class="{{btn.icon}}"></i></span>
                                  <span>{{btn.label}}</span>
                                </button>
                              </ng-template>
                            </ng-container>
                            <ng-container *ngSwitchCase="'self'">
                              <ng-template [ngxPermissionsOnly]="((btn.permissions)?btn.permissions:['USER'])">
                                <button ngbDropdownItem (click)="sendParam(extractParam(row, btn.param), btn.action)"
                                  [hidden]="btn.hideButton">
                                  <span class="mr-3"><i class="{{btn.icon}}"></i></span>
                                  <span>{{btn.label}}</span>
                                </button>
                              </ng-template>
                            </ng-container>
                            <ng-container *ngSwitchCase="'callback'">
                              <ng-template [ngxPermissionsOnly]="((btn.permissions)?btn.permissions:['USER'])">
                                <button ngbDropdownItem (click)="btn.callback(row)" [hidden]="btn.hideButton">
                                  <span class="mr-3"><i class="{{btn.icon}}"></i></span>
                                  <span>{{btn.label}}</span>
                                </button>
                              </ng-template>
                            </ng-container>
                            <ng-container *ngSwitchCase="'separator'">
                              <hr />
                            </ng-container>
                            <button *ngSwitchDefault ngbDropdownItem [routerLink]="buildRoute(row, btn.route, btn.routeParam)"
                              [queryParams]="extractParam(row, (btn.queryParam)?btn.queryParam:{})"><i class="{{btn.icon}}"></i>
                              {{btn.label}}</button>

                          </ng-container>

                        </ng-container>
                      </div>
                    </div>
                  </ng-container>
                  <ng-container *ngIf="tableCommonConfig.buttonOption.length == 1">
                    <ng-container *ngFor="let btn of tableCommonConfig.buttonOption" [ngSwitch]="btn.type">
                      <ng-container *ngIf="btn.visible == undefined || (btn.visible && btn.visible(row)) ">
                        <ng-container *ngSwitchCase="'route'">
                          <ng-template [ngxPermissionsOnly]="((btn.permissions)?btn.permissions:['USER'])">
                            <a ngbTooltip="{{btn.label}}" class="btn btn-icon btn-sm btn-primary" [routerLink]="buildRoute(row, btn.route, btn.routeParam)"
                              [queryParams]="extractParam(row, (btn.queryParam)?btn.queryParam:{})" [hidden]="btn.hideButton">
                              <i class="{{btn.icon}}"></i>
                            </a>
                          </ng-template>
                        </ng-container>
                        <ng-container *ngSwitchCase="'modal'">
                          <ng-template [ngxPermissionsOnly]="((btn.permissions)?btn.permissions:['USER'])">
                            <button ngbTooltip="{{btn.label}}" class="btn btn-icon btn-sm btn-primary" (click)="openModal(extractParam(row, btn.param),btn.componentModal)"
                              [hidden]="btn.hideButton">
                              <i class="{{btn.icon}}"></i>
                            </button>
                          </ng-template>
                        </ng-container>
                        <ng-container *ngSwitchCase="'self'">
                          <ng-template [ngxPermissionsOnly]="((btn.permissions)?btn.permissions:['USER'])">
                            <button ngbTooltip="{{btn.label}}" class="btn btn-icon btn-sm btn-primary" (click)="sendParam(extractParam(row, btn.param), btn.action)"
                              [hidden]="btn.hideButton">
                              <i class="{{btn.icon}}"></i>
                            </button>
                          </ng-template>
                        </ng-container>
                        <ng-container *ngSwitchCase="'callback'">
                          <ng-template [ngxPermissionsOnly]="((btn.permissions)?btn.permissions:['USER'])">
                            <button ngbTooltip="{{btn.label}}" class="btn btn-icon btn-sm btn-primary" (click)="btn.callback(row)"
                              [hidden]="btn.hideButton">
                              <i class="{{btn.icon}}"></i>
                            </button>
                          </ng-template>
                        </ng-container>
                        <button ngbTooltip="{{btn.label}}" class="btn btn-icon btn-sm btn-primary" *ngSwitchDefault
                          [routerLink]="buildRoute(row, btn.route, btn.routeParam)" [queryParams]="extractParam(row, (btn.queryParam)?btn.queryParam:{})"><i
                            class="{{btn.icon}}"></i></button>
                      </ng-container>
                    </ng-container>
                  </ng-container>
                </ng-container>
                <ng-container *ngIf="tableCommonConfig.advanceOption.isEditableTable">
                  <div class="btn-group" role="group" aria-label="First group">
                    <ng-container *ngIf="tableCommonConfig.advanceOption.showDeleteButton !== false">
                      <button (click)="delete(row)" type="button" class="btn btn-danger btn-sm btn-icon">
                        <i class="fa fa-trash fa-sm"></i>
                      </button>
                    </ng-container>
                    <ng-container *ngIf="tableCommonConfig.advanceOption.showEditButton !== false">
                      <button (click)="save(row)" type="button" class="btn btn-primary btn-sm btn-icon" *ngIf="onEdit && row[tableCommonConfig.primaryKey] == currentRow">
                        <i class="fa fa-save fa-sm"></i>
                      </button>
                      <!-- button edit kalo ada parameter showEditButton -->
                      <ng-container *ngIf="tableCommonConfig.showEditButton else editBiasa">
                        <button (click)="edit(row)" type="button" class="btn btn-success btn-sm btn-icon" *ngIf="(!onEdit || row[tableCommonConfig.primaryKey] != currentRow)"
                          [hidden]="!tableCommonConfig.showEditButton[i]">
                          <i class="fa fa-edit fa-sm"></i>
                        </button>
                      </ng-container>
                      <!-- button edit kalo tidak ada parameter showEditButton -->
                      <ng-template #editBiasa>
                        <button (click)="edit(row)" type="button" class="btn btn-success btn-sm btn-icon" *ngIf="(!onEdit || row[tableCommonConfig.primaryKey] != currentRow)">
                          <i class="fa fa-edit fa-sm"></i>
                        </button>
                      </ng-template>
                      <button (click)="cancelEdit()" type="button" class="btn btn-warning btn-sm btn-icon" *ngIf="onEdit && row[tableCommonConfig.primaryKey] == currentRow">
                        <i class="fa fa-close fa-sm"></i>
                      </button>
                    </ng-container>
                  </div>
                </ng-container>
              </td>
              <td *ngFor="let col of tableCommonConfig.displayedColumns" [style.white-space]="'nowrap'"
                [style.text-align]="parseColumnOption(tableCommonConfig.columnOption,'column',col,'align') || 'left'" [ngClass]="{'text-ellipsis' : parseColumnOption(tableCommonConfig.columnOption,'column',col,'setEllipsis')}">
                <ng-container [ngSwitch]="true">
                  <ng-container *ngSwitchCase="parseColumnOption(tableCommonConfig.columnOption,'column',col,'contentType') && parseColumnOption(tableCommonConfig.columnOption,'column',col,'contentType') == 'profile'">
                    <span style="width: 200px;">
                      <div class="kt-user-card-v2">
                        <div class="kt-user-card-v2__pic">
                          <img *ngIf="row['profile'].includes('s3.ptnw.net/uploads') else imgDefaultProfile;" [src]="row['profile']"
                            alt="image" class="rounded rounded-circle">
                          <ng-template #imgDefaultProfile>
                            <img src="/assets/media/users/default.jpg" alt="image" class="rounded rounded-circle">
                          </ng-template>
                        </div>
                        <div *ngIf="row['nama'] && row['username']" class="kt-user-card-v2__details">
                          <span class="kt-user-card-v2__name">{{row['nama']}}</span>
                          <span class="kt-user-card-v2__desc">{{row['username']}}</span>
                        </div>
                      </div>
                    </span>
                  </ng-container>
                  <ng-container *ngSwitchCase="parseColumnOption(tableCommonConfig.columnOption,'column',col,'contentType') && parseColumnOption(tableCommonConfig.columnOption,'column',col,'contentType') == 'imgPath'">
                    <a href="{{row[col]}}" target="_blank">
                      <img [src]="row[col]" alt="image" style="width: 50px;" onerror="src='/assets/media/users/default.jpg'">
                    </a>
                  </ng-container>
                  <ng-container *ngSwitchCase="parseColumnOption(tableCommonConfig.columnOption,'column',col,'contentType') && parseColumnOption(tableCommonConfig.columnOption,'column',col,'contentType') == 'perline'">
                    <p *ngFor="let x of arrexplode(row[col])">{{x}}</p>
                  </ng-container>
                  <ng-container *ngSwitchDefault>
                    <ng-container *ngIf="(!onEdit || row[tableCommonConfig.primaryKey] != currentRow)">
                      <ng-container *ngIf="!parseColumnOption(tableCommonConfig.columnOption,'column',col,'setDownloadButton')">
                        <ng-container *ngIf="parseColumnOption(tableCommonConfig.columnOption,'column',col,'labelValue');let labelValue;else defaultValue">
                          {{labelValue[row[col]]}}
                        </ng-container>
                        <ng-template #defaultValue>
                          <ng-container *ngIf="!parseColumnOption(tableCommonConfig.columnOption,'column',col,'setEllipsis')">
                            <span [style.float]="(parseColumnOption(tableCommonConfig.columnOption,'column',col,'setPipe') == 'currency' || parseColumnOption(tableCommonConfig.columnOption,'column',col,'setPipe') == 'number')? 'right' : 'left'" [ngClass]="{'font-roboto-mono': (parseColumnOption(tableCommonConfig.columnOption,'column',col,'setPipe') == 'currency' || parseColumnOption(tableCommonConfig.columnOption,'column',col,'setPipe') == 'number')}">
                              {{row[col] === null ? 'not set' : row[col] | formatValue:
                              parseColumnOption(tableCommonConfig.columnOption,'column',col,'setPipe')}}
                            </span>
                          </ng-container>
                          <ng-container *ngIf="parseColumnOption(tableCommonConfig.columnOption,'column',col,'setEllipsis')">
                            {{row[col]}}
                          </ng-container>
                        </ng-template>
                      </ng-container>
                      <ng-container *ngIf="parseColumnOption(tableCommonConfig.columnOption,'column',col,'setDownloadButton')">
                        <ng-container *ngIf="row[col] != null">
                          <ng-container *ngIf="parseColumnOption(tableCommonConfig.columnOption,'column',col,'setDownloadButton') === 'direct'">
                            <a class="btn btn-sm btn-primary mr-2" href="{{api_version_url+'/'+row[col]}}" download><i
                                class="fa fa-download"></i>VIEW
                              /
                              DOWNLOAD</a>
                          </ng-container>
                          <ng-container *ngIf="parseColumnOption(tableCommonConfig.columnOption,'column',col,'setDownloadButton') === 'tableBackblaze'">
                            <a class="btn btn-sm btn-primary mr-2" href="{{api_url + '/site/download/'+row[col]}}"
                              [target]="'_blank'"><i class="fa fa-download"></i>VIEW / DOWNLOAD</a>
                          </ng-container>
                          <ng-container *ngIf="parseColumnOption(tableCommonConfig.columnOption,'column',col,'setDownloadButton') === 'tableWasabi'">
                            <a class="btn btn-sm btn-primary mr-2" href="{{api_url + '/site/download-from-wasabi/'+row[col]}}"
                              [target]="'_blank'"><i class="fa fa-download"></i>VIEW / DOWNLOAD</a>
                          </ng-container>
                          <ng-container *ngIf="parseColumnOption(tableCommonConfig.columnOption,'column',col,'setDownloadButton') === 'forceUrl'">
                            <a class="btn btn-sm btn-primary mr-2" href="{{row[col]}}" [target]="'_blank'"><i class="fa fa-download"></i>VIEW
                              /
                              DOWNLOAD</a>
                          </ng-container>
                          <ng-container *ngIf="parseColumnOption(tableCommonConfig.columnOption,'column',col,'setDownloadButton') === 'cloudStorage'">
                            <app-gcs-download
                              [buttonClass]="'btn btn-primary'"
                              [path]="row['gcs_path']"
                              [buttonLable]="'VIEW / DOWNLOAD'"
                              >
                            </app-gcs-download>
                          </ng-container>
                        </ng-container>
                        <ng-container *ngIf="row[col] == null">
                          -
                        </ng-container>
                      </ng-container>
                    </ng-container>
                    <ng-container *ngIf="(onEdit && row[tableCommonConfig.primaryKey] == currentRow)">
                      <ng-container [ngSwitch]="parseColumnOption(tableCommonConfig.columnOption,'column',col,'inputType')">
                        <ng-container *ngSwitchCase="'date'">
                          <input class="form-control" [placeholder]="'yyyy-mm-dd'" name="col" [(ngModel)]="currentEdit[col]"
                            ngbDatepicker #d="ngbDatepicker" required readOnly (click)="d.toggle()">
                        </ng-container>
                        <ng-container *ngSwitchCase="'text'">
                          <input type="text" [name]="col" [(ngModel)]="currentEdit[col]" class="form-control"
                            [disabled]="!parseColumnOption(tableCommonConfig.columnOption,'column',col,'editable')">
                        </ng-container>
                        <ng-container *ngSwitchCase="'typehead'">
                          <app-dynamic-select [id]="'dynamicSelect'+col" [dataOptions]="parseColumnOption(tableCommonConfig.columnOption,'column',col,'listOption')"
                            [dataOptions]="parseColumnOption(tableCommonConfig.columnOption,'column',col,'listOption')"
                            (selected)="getSelectedTypehead($event, col)">
                          </app-dynamic-select>
                        </ng-container>
                        <ng-container *ngSwitchCase="'select'">
                          <select [name]="col" [(ngModel)]="currentEdit[col]" class="form-control" (change)="getSelectedSelect($event.target.value, col)">
                            <ng-container *ngFor="let item of parseColumnOption(tableCommonConfig.columnOption,'column',col,'listOption')">
                              <option [value]="item.key">{{item.val}}</option>
                            </ng-container>
                          </select>
                        </ng-container>
                        <ng-container *ngSwitchCase="'number'">
                          <input type="number" [name]="col" [(ngModel)]="currentEdit[col]" class="form-control"
                            [disabled]="!parseColumnOption(tableCommonConfig.columnOption,'column',col,'editable')">
                        </ng-container>
                        <ng-container *ngSwitchCase="'file'">
                          <app-gcs-upload [tableTarget]="parseColumnOption(tableCommonConfig.columnOption,'column',col,'tableTarget')?parseColumnOption(tableCommonConfig.columnOption,'column',col,'tableTarget'):'tableCommon'" [columnTarget]="col" [module]="'site_survey'" [prefix]="parseColumnOption(tableCommonConfig.columnOption,'column',col,'prefixFileInput')" [acceptFile]="['image/jpg','image/jpeg','image/png','application/pdf']" (success)="onSuccess($event,col)"></app-gcs-upload>
                        </ng-container>
                        <ng-container *ngSwitchCase="'datetime'">
                          <input type="datetime-local" [name]="col" [(ngModel)]="currentEdit[col]" class="form-control"
                            [disabled]="!parseColumnOption(tableCommonConfig.columnOption,'column',col,'editable')">
                        </ng-container>
                        <ng-container *ngSwitchDefault>
                          <input type="text" [name]="col" [(ngModel)]="currentEdit[col]" class="form-control"
                            [disabled]="!parseColumnOption(tableCommonConfig.columnOption,'column',col,'editable')">
                        </ng-container>
                      </ng-container>
                    </ng-container>
                  </ng-container>
                </ng-container>
              </td>
            </tr>
            <tr *ngIf="expandedElement && row[tableCommonConfig.primaryKey] == currentRow">
              <td [attr.colSpan]="tableCommonConfig.displayedColumns.length+3">
                <div *ngIf="tableCommonConfig.expandRowConfig.dataApproval && tableCommonConfig.expandRowConfig.typeContent == 'custom'"
                  class="row">
                  <div class="col-lg-12">
                    <div class="card">
                      <div class="kt-portlet">
                        <div class="kt-portlet__head">
                          <div class="kt-portlet__head-label">
                            <h3 class="kt-portlet__head-title">
                              {{ tableCommonConfig.expandRowConfig.labelContent }}
                            </h3>

                          </div>
                        </div>
                        <div class="kt-portlet__body">
                          <div class="kt-notes">
                            <div class="kt-notes__items">
                              <ng-container *ngIf="tableCommonConfig.expandRowConfig.dataApproval != null && tableCommonConfig.expandRowConfig.dataApproval.length > 0 && tableCommonConfig.expandRowConfig.dataApproval != [null]">
                                <ng-container *ngFor="let step of tableCommonConfig.expandRowConfig.dataApproval; let i=index;">
                                  <div  class="kt-notes__item">
                                    <div class="kt-notes__media">
                                      <span class="kt-notes__icon">
                                        <i [ngClass]="step.reject_reason ? 'flaticon-exclamation-1 kt-font-warning' : 'flaticon2-check-mark kt-font-brand'"></i>
                                      </span>
                                    </div>
                                    <div class="kt-notes__content">
                                      <div class="kt-notes__section">
                                        <div class="kt-notes__info">
                                          <a href="javascript:;" class="kt-notes__title">
                                            {{step.step_description}}
                                          </a>
                                          <span class="kt-notes__desc">
                                            at {{step.approval_date}}
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </ng-container>
                              </ng-container>                              
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div *ngIf="tableCommonConfig.expandRowConfig.dataApproval && tableCommonConfig.expandRowConfig.typeContent == 'approval'"
                  class="row">
                  <div class="col-lg-12">
                    <div class="card">
                      <div class="kt-portlet">
                        <div class="kt-portlet__head">
                          <div class="kt-portlet__head-label">
                            <h3 class="kt-portlet__head-title">
                              Step Approval
                            </h3>
                          </div>
                        </div>
                        <div class="kt-portlet__body">
                          <div class="kt-notes">
                            <div class="kt-notes__items">
                              <ng-container *ngIf="tableCommonConfig.expandRowConfig.dataApproval != null && tableCommonConfig.expandRowConfig.dataApproval.length > 0 && tableCommonConfig.expandRowConfig.dataApproval[0] != null">
                                <ng-container *ngFor="let step of tableCommonConfig.expandRowConfig.dataApproval; let i=index;">
                                  <div *ngIf="step.pass == 1" class="kt-notes__item">
                                    <div class="kt-notes__media">
                                      <span class="kt-notes__icon">
                                        <i [ngClass]="step.reject_reason ? 'flaticon-exclamation-1 kt-font-warning' : 'flaticon2-check-mark kt-font-brand'"></i>
                                      </span>
                                    </div>
                                    <div class="kt-notes__content">
                                      <div class="kt-notes__section">
                                        <div class="kt-notes__info">
                                          <a href="javascript:;" class="kt-notes__title">
                                            {{step.step_description}}
                                          </a>
                                          <span class="kt-notes__desc">
                                            <ng-container *ngIf="step.complete_duration && step.complete_duration.duration_type else defaultAging">
                                              {{complateDuration(step.pass, i, step.approval_date, step.complete_duration, step.complete_duration.duration_type)}}
                                            </ng-container>
                                            <ng-template #defaultAging>
                                              {{stepDate(step.pass, i,step.approval_date)}}
                                            </ng-template>
                                          </span>
                                        </div>
                                      </div>
                                      <span class="kt-notes__body">
                                        <span class="kt-badge kt-badge--inline kt-badge--success">
                                          by {{step.approval_pic}}
                                        </span><br>
                                        <ng-container *ngIf="step.reject_reason">
                                          Reject Reason : <br> {{step.reject_reason}}
                                        </ng-container>
                                      </span>
                                    </div>
                                  </div>
                                  <div *ngIf="step.pass == 0 || step.pass == null" class="kt-notes__item">
                                    <div class="kt-notes__media">
                                      <span class="kt-notes__icon">
                                        <i *ngIf="step.step_description === 'Reject'" class="flaticon2-cross kt-font-danger"></i>
                                        <i *ngIf="step.step_description === 'Finish'" class="flaticon2-check-mark kt-font-brand"></i>
                                        <i *ngIf="step.step_description !== 'Reject' && step.step_description !== 'Finish'"
                                          class="flaticon2-hourglass-1 kt-font-danger"></i>
                                      </span>
                                    </div>
                                    <div *ngIf="step.step_description !== 'Reject' && step.step_description !== 'Finish'"
                                      class="kt-notes__content">
                                      <div class="kt-notes__section">
                                        <div class="kt-notes__info">
                                          <a href="javascript:;" class="kt-notes__title">
                                            {{step.step_description}}
                                          </a>
                                          <span class="kt-notes__desc">
                                            not yet&nbsp;{{stepDate(step.pass, i,step.approval_date)}}
                                          </span>
                                        </div>
                                      </div>
                                      <span class="kt-notes__body">
                                        <span class="kt-badge kt-badge--danger kt-badge--inline">by
                                          {{step.approval_pic}}
                                        </span><br>
                                        <ng-container *ngIf="step.reject_reason">
                                          Reject Reason : <br> {{step.reject_reason}}
                                        </ng-container>
                                      </span>
                                    </div>
                                    <div *ngIf="step.step_description === 'Finish'" class="kt-notes__content">
                                      <div class="kt-notes__section">
                                        <div class="kt-notes__info">
                                          <a href="javascript:;" class="kt-notes__title">
                                            Finished
                                          </a>
                                        </div>
                                      </div>
                                    </div>
                                    <div *ngIf="step.step_description === 'Reject'" class="kt-notes__content">
                                      <div class="kt-notes__section">
                                        <div class="kt-notes__info">
                                          <a href="javascript:;" class="kt-notes__title">
                                            Rejected
                                          </a>
                                        </div>
                                      </div>
                                      <span class="kt-notes__body">
                                        <ng-container *ngIf="step.reject_reason">
                                          Reject Reason : <br> {{step.reject_reason}}
                                        </ng-container>
                                      </span>
                                    </div>
                                  </div>
                                </ng-container>
                              </ng-container>                              
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div *ngIf="tableCommonConfig.expandRowConfig.dataTable && tableCommonConfig.expandRowConfig.typeContent == 'table'"
                  class="row">
                  <div class="col-lg-12">
                    <table class="table">
                      <thead class="thead-light">
                        <tr>
                          <th>#</th>
                          <ng-container *ngFor="let column of tableCommonConfig.expandRowConfig.dataTable.column;">
                            <th>{{tableCommonConfig.expandRowConfig.dataTable.label[column]}}</th>
                          </ng-container>
                        </tr>
                      </thead>
                      <tbody>
                        <ng-container *ngFor="let row of tableCommonConfig.expandRowConfig.dataTable.row; let i = index;">
                          <tr>
                            <th scope="row">{{i+1}}</th>
                            <ng-container *ngFor="let column of tableCommonConfig.expandRowConfig.dataTable.column;">
                              <td>{{row[column]}}</td>
                            </ng-container>
                          </tr>
                        </ng-container>
                      </tbody>
                    </table>
                  </div>
                </div>
              </td>
            </tr>
          </ng-container>
          <ng-container *ngIf="(tableCommonConfig.dataSource.length === 0) && !loading">
            <tr>
              <td [attr.colSpan]="tableCommonConfig.displayedColumns.length + 1 + (tableCommonConfig.advanceOption.showExpandable?1:0) + (tableCommonConfig.advanceOption.showActionColumn?1:0) + (tableCommonConfig.checkboxOption?1:0)">
                <div style="min-height: 300px;">No data record</div>
              </td>
            </tr>
          </ng-container>
        </tbody>
      </table>
    </div>
    <!--begin: Pagination-->
    <div class="kt-portlet__foot" *ngIf="tableCommonConfig.advanceOption.showPagination && tableCommonConfig.dataSource.length > 0">
      <div class="kt-pagination kt-pagination--brand">
        <ul class="kt-pagination__links">
          <ngb-pagination [collectionSize]="tableCommonConfig.paginateOption.total" [(page)]="tableCommonConfig.paginateOption.pageNumber"
            [pageSize]="tableCommonConfig.paginateOption.pageSize" [maxSize]="5" [rotate]="true" [ellipses]="false"
            [boundaryLinks]="true" (pageChange)="changePage($event)"></ngb-pagination>
          <span class="pagination__desc">
            Page {{tableCommonConfig.paginateOption.pageNumber}} of {{tableCommonConfig.paginateOption.getTotalPage()}}
          </span>
        </ul>
        <div class="kt-pagination__toolbar">
          <select [(ngModel)]="currentPageSize" (change)="changePageSize()" class="form-control kt-font-brand" style="width: 60px">
            <option *ngFor="let number of tableCommonConfig.pageSizeOption" [value]="number">{{number}}</option>
          </select>
          <span class="pagination__desc">
            Displaying
            {{(tableCommonConfig.dataSource.length < tableCommonConfig.paginateOption.pageSize) ?
              tableCommonConfig.dataSource.length : tableCommonConfig.paginateOption.pageSize}} of
              {{tableCommonConfig.paginateOption.total}} records </span> </div> </div> </div> <!--end: Pagination-->
        </div>
        <!-- <div class="kt-portlet" *ngIf="(tableCommonConfig.dataSource.length === 0) && !loading">
    <div class="kt-portlet__body">
      <p>No data record</p>
    </div>
  </div> -->
      </div>

      <ng-template #Loading>
        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 text-center">
          <img src="/assets/media/loader/timeline.svg">
        </div>
      </ng-template>
