<div id="content" class="bg-white">
  <div class="pb-5 pt-5 px-4 px-md-5 ml-0 ml-xl-5">
    <h1 class="display-3 text-primary mb-0 pb-0">Gallery</h1>
  </div>
</div>
<div class="bg-white pt-5 pb-5 px-0 px-lg-5">
  <div class="container-fluid">
    <ng-container *ngIf="dataGallery else gallerySkeleton">
      <div class="row">
        <ng-container *ngFor="let gallery of dataGallery">
          <div class="col-lg-4 col-xl-3">
            <div class="kt-portlet kt-portlet--height-fluid kt-widget19">
              <div class="kt-portlet__body kt-portlet__body--fit kt-portlet__body--unfill">
                <a href="javascript:;" class="kt-widget19__pic kt-portlet-fit--top kt-portlet-fit--sides">
                  <img [src]="gallery.picture_url" alt="{{gallery.title}}" style="height: 250px;">
                </a>
              </div>
              <div class="kt-portlet__body">
                <h4>
                  <a href="#" class="kt-font-dark">
                    {{gallery.title}} </a>
                </h4>
                <span class="kt-widget19__time pb-0 mb-0">
                  {{!gallery.date ? 'not set' : gallery.date | date : "d MMM y"}}
                  {{!gallery.date ? '' : gallery.date | date : "HH"}}
                  {{!gallery.date ? '' : ':'}}
                  {{!gallery.date ? '' : gallery.date | date : "mm"}} </span>
              </div>
            </div>
          </div>
        </ng-container>
      </div>
      <div class="row">
        <div class="col-6">
          <div style="text-align: left; margin: 0 0 20px 0">
            <a class="btn btn-light" href="javascript:" (click)="previousPage()" [class.d-none]="page == 1">
              &larr; Halaman Sebelumnya
            </a>
          </div>
        </div>
        <div class="col-6 text right">
          <div style="text-align: right; margin: 0 0 20px 0">
            <a class="btn btn-light" href="javascript:" (click)="nextPage()" [class.d-none]="(page + 1) * pageSize > total">
              Halaman Selanjutnya &rarr;
            </a>
          </div>
        </div>
      </div>
    </ng-container>
  </div>
</div>
<ng-template #gallerySkeleton>
  <div class="row">
    <div class="col-lg-3 col-xl-3">
      <div class="kt-portlet kt-portlet--height-fluid kt-widget19">
        <div class="kt-portlet__body kt-portlet__body--fit kt-portlet__body--unfill">
          <a href="#" class="kt-widget19__pic kt-portlet-fit--top kt-portlet-fit--sides skeleton-loader" style="min-height: 250px;"></a>
        </div>
        <div class="kt-portlet__body">
          <h4 class="skeleton-loader" style="height: 26px;">
            &nbsp;
          </h4>
          <span class="kt-widget19__time pb-0 mb-0 skeleton-loader" style="height: 20px;">
            &nbsp; </span>
        </div>
      </div>
    </div>
    <div class="col-lg-3 col-xl-3">
      <div class="kt-portlet kt-portlet--height-fluid kt-widget19">
        <div class="kt-portlet__body kt-portlet__body--fit kt-portlet__body--unfill">
          <a href="#" class="kt-widget19__pic kt-portlet-fit--top kt-portlet-fit--sides skeleton-loader" style="min-height: 250px;"></a>
        </div>
        <div class="kt-portlet__body">
          <h4 class="skeleton-loader" style="height: 26px;">
            &nbsp;
          </h4>
          <span class="kt-widget19__time pb-0 mb-0 skeleton-loader" style="height: 20px;">
            &nbsp; </span>
        </div>
      </div>
    </div>
    <div class="col-lg-3 col-xl-3">
      <div class="kt-portlet kt-portlet--height-fluid kt-widget19">
        <div class="kt-portlet__body kt-portlet__body--fit kt-portlet__body--unfill">
          <a href="#" class="kt-widget19__pic kt-portlet-fit--top kt-portlet-fit--sides skeleton-loader" style="min-height: 250px;"></a>
        </div>
        <div class="kt-portlet__body">
          <h4 class="skeleton-loader" style="height: 26px;">
            &nbsp;
          </h4>
          <span class="kt-widget19__time pb-0 mb-0 skeleton-loader" style="height: 20px;">
            &nbsp; </span>
        </div>
      </div>
    </div>
    <div class="col-lg-3 col-xl-3">
      <div class="kt-portlet kt-portlet--height-fluid kt-widget19">
        <div class="kt-portlet__body kt-portlet__body--fit kt-portlet__body--unfill">
          <a href="#" class="kt-widget19__pic kt-portlet-fit--top kt-portlet-fit--sides skeleton-loader" style="min-height: 250px;"></a>
        </div>
        <div class="kt-portlet__body">
          <h4 class="skeleton-loader" style="height: 26px;">
            &nbsp;
          </h4>
          <span class="kt-widget19__time pb-0 mb-0 skeleton-loader" style="height: 20px;">
            &nbsp; </span>
        </div>
      </div>
    </div>
  </div>
</ng-template>
