<!-- MODAL ALERT -->
<div *ngIf="kategori === 'alert'">
  <div class="modal-header">
    <h4 class="modal-title text-brand">Sorry!</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.close()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <span>{{error}}</span>
  </div>
  <div class="modal-footer">
    <a href="#">Contact Customer Care?</a>
    <button type="button" class="btn btn-outline-dark" (click)="modal.close()">Close</button>
  </div>
</div>

<!-- MODAL KONFIRMASI -->
<div *ngIf="kategori === 'confirm'">
  <div class="modal-header">
    <h4 class="modal-title" id="modal-title">Confirmation Delete Data {{title}}</h4>
    <button type="button" class="close" aria-label="Close button" aria-describedby="modal-title" (click)="modal.close(false)">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <p><strong>Are you sure to delete {{data_category}} data <span class="text-primary">"{{data_name}}"</span>?</strong></p>
    <p>Information {{data_category}} from {{data_name}} will be delete.<br>
    <span class="text-danger">This action can't be undone.</span>
    </p>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-secondary" (click)="modal.close(false)">Cancel</button>
    <button type="button" ngbAutofocus class="btn btn-danger" (click)="modal.close(true)">Ok</button>
  </div>
</div>

<!-- MODAL KONFIRMASI TEMPLATE PREPAID -->
<div *ngIf="kategori === 'confirm_prepaid'">
  <div class="modal-header">
    <h4 class="modal-title" id="modal-title">Template Approval</h4>
  </div>
  <div class="modal-body">
    <p><strong>Choose <span class="text-primary">"Template Approval"</span>!</strong></p>
    <p><span class="text-success"><b>SKIL TL</b></span> make status approval <span class="text-success">TL Approved</span></p>
    <p><span class="text-danger"><b>NO SKIP TL</b></span> make status approval <span class="text-danger">TL Not Approved</span></p>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-danger" (click)="modal.close(false)">NO SKIP TL</button>
    <button type="button" class="btn btn-outline-success" (click)="modal.close(true)">SKIP TL</button>
  </div>
</div>

<!-- MODAL KONFIRMASI TEMPLATE BLOG -->
<div *ngIf="kategori === 'confirm_blog'">
  <div class="modal-header">
    <h4 class="modal-title" id="modal-title">Template Blog</h4>
  </div>
  <div class="modal-body">
    <p><strong>Choose <span class="text-danger">"Template Blog"</span>!</strong></p>
    <p><span class="text-success"><strong>BLOG POST</strong></span> : Feature maked <span class="text-success">Content for Text dan Pictures</span></p>
    <p><span class="text-primary"><strong>BLOG GALERY</strong></span> : Feature maked <span class="text-primary">Content only for Gambar</span></p>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-primary" (click)="modal.close(false)">BLOG GALERY</button>
    <button type="button" class="btn btn-outline-success" (click)="modal.close(true)">BLOG POST</button>
  </div>
</div>

<!-- MODAL HISTORY -->
<div *ngIf="kategori === 'history'">
  <div class="modal-header">
    <h4 class="modal-title text-dark">History Cell - {{title}}</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.close()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <ng-container *ngIf="data_history">
      <table class="table border-bottom bg-white">
        <thead class="thead-light">
          <tr>
            <th>Modifier</th>
            <th>Old Value</th>
            <th>New Value</th>
            <th>Date Modified</th>
          </tr>
        </thead>
        <tbody>
          <ng-container *ngIf="data_history.length > 0">
            <ng-container *ngFor="let source of data_history">
              <tr>
                <td>
                  <img [src]="source.avatar_location_status === 'b2' ? globalHost.apiHost + '/' + source.avatar_name : 
                              source.avatar_location_status === 'wasabi' ? source.avatar_name : 
                              globalHost.apiHost + '/' + source.avatar_server_url"
                    onerror="src='./assets/media/users/default.jpg'" alt="image" width="30" class="rounded rounded-circle mr-2">
                    {{source.nama}}
                </td>
                <td>
                    {{source.old_value | formatValue: setPipe}}
                </td>
                <td>
                    {{source.new_value | formatValue: setPipe}}
                </td>
                <td>
                    {{source.modified_at | formatValue: 'datetime'}}
                </td>
              </tr>
            </ng-container>
          </ng-container>
          <ng-container *ngIf="data_history.length === 0">
            <tr>
              <td colspan="4" class="text-center text-dark">No History</td>
            </tr>
          </ng-container>
        </tbody>
      </table>
    </ng-container>
    <ng-container *ngIf="!data_history">
      <span class="text-primary">History not Found</span>
    </ng-container>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-dark" (click)="modal.close()">Close</button>
  </div>
</div>