import { HttpClient, HttpHeaders } from '@angular/common/http';
import { CookieService } from 'ngx-cookie-service';
import { map } from 'rxjs/operators';
import { GlobalService } from './global.service';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "./global.service";
import * as i3 from "ngx-cookie-service";
var UsersManagementService = /** @class */ (function () {
    function UsersManagementService(http, globalService, cookieService) {
        this.http = http;
        this.globalService = globalService;
        this.cookieService = cookieService;
    }
    UsersManagementService.prototype.header = function () {
        return {
            headers: new HttpHeaders()
                .set('token', this.cookieService.get('_q'))
                .set('module', localStorage.getItem('users'))
                .set('group', this.cookieService.get('_g'))
        };
    };
    UsersManagementService.prototype.getUsers = function (params) {
        return this.http.post(this.globalService.apiVersionHost + '/users/default/get-users', params, this.header()).pipe(map(function (response) { return response; }));
    };
    UsersManagementService.prototype.getDocument = function (param) {
        return this.http.post(this.globalService.apiVersionHost + '/users/default/get-document', param, this.header()).pipe(map(function (response) { return response; }));
    };
    UsersManagementService.prototype.getFile = function (param) {
        return this.http.post(this.globalService.apiVersionHost + '/users/default/get-file', param, this.header()).pipe(map(function (response) { return response; }));
    };
    UsersManagementService.prototype.saveVerification = function (param) {
        return this.http.post(this.globalService.apiVersionHost + '/users/default/save-verification', param, this.header()).pipe(map(function (response) { return response; }));
    };
    UsersManagementService.prototype.listDropCV = function (params) {
        return this.http.post(this.globalService.apiVersionHost + '/users/default/list-drop-cv', params, this.header()).pipe(map(function (response) { return response; }));
    };
    UsersManagementService.prototype.getProject = function (params) {
        return this.http.post(this.globalService.apiVersionHost + '/users/default/get-project', params, this.header()).pipe(map(function (response) { return response; }));
    };
    UsersManagementService.prototype.getApplyVacancy = function (params) {
        return this.http.post(this.globalService.apiVersionHost + '/users/default/get-apply-vacancy', params, this.header()).pipe(map(function (response) { return response; }));
    };
    UsersManagementService.prototype.getStepRecruitment = function (params) {
        return this.http.post(this.globalService.apiVersionHost + '/users/default/get-step-recruitment', params, this.header()).pipe(map(function (response) { return response; }));
    };
    UsersManagementService.prototype.getExitClearance = function (params) {
        return this.http.post(this.globalService.apiVersionHost + '/users/default/get-exit-clearance', params, this.header()).pipe(map(function (response) { return response; }));
    };
    UsersManagementService.prototype.getAssetUser = function (param) {
        return this.http.post(this.globalService.apiVersionHost + '/users/default/asset-user', param, this.header()).pipe(map(function (response) { return response; }));
    };
    UsersManagementService.prototype.getDataSkill = function (param) {
        return this.http.post(this.globalService.apiVersionHost + '/users/default/get-data-skill', param, this.header()).pipe(map(function (response) { return response; }));
    };
    UsersManagementService.prototype.updateDetailSkill = function (param) {
        return this.http.post(this.globalService.apiVersionHost + '/users/default/update-detail-skill', param, this.header()).pipe(map(function (response) { return response; }));
    };
    UsersManagementService.prototype.updateDataSkill = function (param) {
        return this.http.post(this.globalService.apiVersionHost + '/users/default/update-data-skill', param, this.header()).pipe(map(function (response) { return response; }));
    };
    UsersManagementService.prototype.prepareSkillForm = function () {
        return this.http.post(this.globalService.apiVersionHost + '/users/default/prepare-skill-form', null, this.header()).pipe(map(function (response) { return response; }));
    };
    UsersManagementService.prototype.saveDraftUsers = function (param) {
        return this.http.post(this.globalService.apiVersionHost + '/users/default/save-draft-users', param, this.header()).pipe(map(function (response) { return response; }));
    };
    UsersManagementService.prototype.saveSignatureUsers = function (param) {
        return this.http.post(this.globalService.apiVersionHost + '/users/default/save-signature-users', param).pipe(map(function (response) { return response; }));
    };
    UsersManagementService.prototype.getDraftUsers = function (param) {
        return this.http.post(this.globalService.apiVersionHost + '/users/default/get-draft-users', param, this.header()).pipe(map(function (response) { return response; }));
    };
    UsersManagementService.prototype.exportSkill = function (param) {
        return this.http.post(this.globalService.apiVersionHost + '/users/default/export-skill', param, this.header()).pipe(map(function (response) { return response; }));
    };
    UsersManagementService.prototype.confirmDraftUsers = function (param) {
        return this.http.post(this.globalService.apiVersionHost + '/users/default/confirm-draft-users', param, this.header()).pipe(map(function (response) { return response; }));
    };
    UsersManagementService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function UsersManagementService_Factory() { return new UsersManagementService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.GlobalService), i0.ɵɵinject(i3.CookieService)); }, token: UsersManagementService, providedIn: "root" });
    return UsersManagementService;
}());
export { UsersManagementService };
