import { SharedModule } from './../shared/shared.module';
import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { MainRoutingModule } from "./main-routing.module";
import { MainComponent } from "./main.component";
import { HeaderComponent } from "./header/header.component";
import { SidebarComponent } from "./sidebar/sidebar.component";
import { FooterComponent } from "./footer/footer.component";
import { HeaderMobileComponent } from "./header-mobile/header-mobile.component";
import { SubheaderComponent } from "./subheader/subheader.component";
import { NavbarComponent } from "./navbar/navbar.component";
import { AuthGuard } from "src/app/auth/auth-guard.service";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { NgxPermissionsModule } from "ngx-permissions";
import { ModuleComponent } from "./module/module.component";
import { ReloadAuthorizationComponent } from './pages/reload-authorization/reload-authorization.component';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFireMessagingModule } from '@angular/fire/messaging';
import { AngularFireModule } from '@angular/fire';
import { environment } from 'src/environments/environment';
import { ModuleApprovalComponent } from './module-approval/module-approval.component';
import { FormsModule } from '@angular/forms';
import { MonacoEditorModule } from 'ngx-monaco-editor';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AuthorizationModule } from './pages/authorization/authorization.module';

@NgModule({
  declarations: [
    MainComponent,
    HeaderComponent,
    SidebarComponent,
    FooterComponent,
    HeaderMobileComponent,
    SubheaderComponent,
    NavbarComponent,
    ModuleComponent,
    ReloadAuthorizationComponent,
    ModuleApprovalComponent,
  ],
  exports: [MainComponent, HeaderComponent, SidebarComponent, FooterComponent],
  imports: [
    FormsModule,
    CommonModule,
    MainRoutingModule,
    NgbModule,
    AuthorizationModule,
    SharedModule,
    NgxPermissionsModule.forChild(),
    AngularFireModule.initializeApp(environment.firebase),
    // AngularFireDatabaseModule,
    AngularFireAuthModule,
    AngularFireMessagingModule,
    AngularFirestoreModule,
    MonacoEditorModule.forRoot(),
  ],
  providers: [AuthGuard]
})
export class MainModule { }
