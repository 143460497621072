import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';

import { CookieService } from 'ngx-cookie-service';
import { GlobalService } from './_services/global.service';
import { RouterService } from './_services/router.service';

import { AppRoutingModule } from './app-routing.module';
import { AuthenticationModule } from './auth/authentication.module';
import { HttpClientModule } from '@angular/common/http';
import { NgxPermissionsModule } from 'ngx-permissions';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { MainModule } from './layout/main/main.module';

import { AppComponent } from './app.component';
import { LoginComponent } from './layout/login/login.component';
import { FormsModule } from '@angular/forms';
import { NgxUiLoaderModule } from 'ngx-ui-loader';
import { registerLocaleData } from '@angular/common';
import localId from '@angular/common/locales/id';
import localeIdExtra from '@angular/common/locales/extra/id';
import { DexieModule, DexieConfig } from 'ngx-dexie';
import { LogoutComponent } from './layout/logout/logout.component';
import { MenuConfig } from './config/menu';
import { NotFoundComponent } from './layout/not-found/not-found.component';
import { ForbiddenComponent } from './layout/forbidden/forbidden.component';
import { LandingModule } from './layout/landing/landing.module';
import { DataOperator } from './config/operator';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { SsoComponent } from './layout/sso/sso.component';
import { ChangePasswordComponent } from './layout/change-password/change-password.component';
import { ClipboardModule } from 'ngx-clipboard';
import { AngularFirestore } from '@angular/fire/firestore';

registerLocaleData(localId, 'id-ID', localeIdExtra);

const config: DexieConfig = {
  databaseName: 'DatabaseNds', // your database name here
  schema: {
    // tslint:disable-next-line: max-line-length
    applicant: '++id, username, eaf_submited, can_cancel, last_step, UserID, nama, HP, EmailAddress, hometown, is_blacklist, profile, gender, region, location, profile_foto, profile_server_url, profile_location_status, ava, backblazeBucketAlias',
    grab_cart: '++id, pick_up, drop_off, date, reason'
  } // any schema of your choice
};

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    LogoutComponent,
    NotFoundComponent,
    ForbiddenComponent,
    SsoComponent,
    ChangePasswordComponent,
  ],
  imports: [
    BrowserAnimationsModule,
    AppRoutingModule,
    NgbModule,
    FormsModule,
    MainModule,
    LandingModule,
    NgxPermissionsModule.forRoot(),
    AuthenticationModule,
    HttpClientModule,
    NgxUiLoaderModule,
    DexieModule.forRoot(config),
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
    ClipboardModule
  ],
  providers: [
    CookieService,
    GlobalService,
    RouterService,
    MenuConfig,
    DataOperator,
    AngularFirestore
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
