<nav class="navbar navbar-expand-lg navbar-light bg-white">
    <div class="container-fluid">
        <a class="navbar-brand order-lg-last" [routerLink]="['/']"><img src="./assets/media/nds/logo.png" class="mr-2"
                alt="" height="48" /></a>
        <button (click)="menuToggle()" [ngClass]="{'collapsed': expandMenu}" [attr.aria-expanded]="(expandMenu)?'true':'false'" class="navbar-toggler py-2" type="button" data-toggle="collapse"
            data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
            <i class="fas fa-bars"></i>
        </button>
        <div class="collapse navbar-collapse" id="navbarNav"
            [ngClass]="{'show': expandMenu, 'collapse': !collapsing, 'collapsing h-265': collapsing}">
            <ul class="navbar-nav">
                <li class="nav-item" (click)="menuToggle()">
                    <a class="nav-link" [routerLink]="['/home']" [routerLinkActive]="'active'">Home</a>
                </li>
                <li class="nav-item" (click)="menuToggle()">
                    <a class="nav-link" [routerLink]="['/about']" [routerLinkActive]="'active'">About</a>
                </li>
                <li class="nav-item" (click)="menuToggle()">
                    <a class="nav-link" [routerLink]="['/quality-policy']" [routerLinkActive]="'active'">Quality Policy</a>
                </li>
                <li class="nav-item" (click)="menuToggle()">
                    <a class="nav-link" [routerLink]="['/whistle-blowing']" [routerLinkActive]="'active'">Lapor</a>
                </li>
                <li class="nav-item" (click)="menuToggle()">
                    <a class="nav-link" [routerLink]="['/gallery']" [routerLinkActive]="'active'">Gallery</a>
                </li>
                <li class="nav-item" (click)="menuToggle()">
                    <a class="nav-link" [routerLink]="['/client']" [routerLinkActive]="'active'">Our Client</a>
                </li>
                <li class="nav-item" (click)="menuToggle()">
                    <a class="nav-link" [routerLink]="['/blog']" [routerLinkActive]="'active'">Blog</a>
                </li>
                <li class="nav-item" (click)="menuToggle()">
                    <a class="nav-link" [routerLink]="['/karir']" [routerLinkActive]="'active'">Career</a>
                </li>
                <li class="nav-item" (click)="menuToggle()">
                    <a class="nav-link" [routerLink]="['/cv']" [routerLinkActive]="'active'">Drop CV</a>
                </li>
                <li class="nav-item" (click)="menuToggle()">
                    <a [routerLink]="['/login']" class="nav-link">
                        <i class="fa fa-lock fa-fw"></i> Login
                    </a>
                </li>
            </ul>
        </div>
    </div>
</nav>