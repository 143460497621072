import { AdvancedSearchAttributes } from './../../../shared/advanced-search/advanced-search-attributes';
import { AuthenticationService } from './../../../../auth/authentication.service';
import { Component, OnInit } from '@angular/core';
import { TableCommonModel } from 'src/app/layout/shared/table-common/table-common.config';
import { PaginateModel } from 'src/app/_models/paginate.model';
import { AuthorizationService } from 'src/app/_services/authorization.service';
import Swal from 'sweetalert2';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { AdvancedSearchConfig } from 'src/app/layout/shared/advanced-search/advanced-search-config';
import { TableAdvanceModel } from 'src/app/layout/shared/table-advance/table-advance.config';
import { ModuleService } from 'src/app/_services/module.service';

@Component({
  selector: 'app-authorization',
  templateUrl: './authorization.component.html',
  styleUrls: ['./authorization.component.scss']
})
export class AuthorizationComponent implements OnInit {

  authorizationData: any = {};
  symbols: any[] = ['=','<>'];

  tableConfig: TableAdvanceModel = {
    primaryKey: 'id_authorization',
    dataSource: [],
    // displayedColumns: ['column_name','column_operator','column_value','module','controller','action','priority'],
    columnOption: [
      {
        name: 'column_name',
        label: 'Column Name',
        showFilter: true,
      },
      {
        name: 'column_operator',
        label: 'Column Operator',
        showFilter: true,
      },
      {
        name: 'column_value',
        label: 'Column Value',
        showFilter: true,
      },
      {
        name: 'module',
        label: 'Module',
        showFilter: true,
      },
      {
        name: 'controller',
        label: 'Controller',
        showFilter: true,
      },
      {
        name: 'action',
        label: 'Action',
        showFilter: true,
      },
      {
        name: 'priority',
        label: 'Priority',
        showFilter: true,
      },
      {
        name: 'last_update',
        label: 'Last Update',
        showFilter: true,
        setPipe: 'date'
      }
    ],
    pageSizeOption: [20, 30, 50, 100],
    paginateOption: new PaginateModel({ pageSize: 20 }),
    filter: {},
    buttonOption: []
    // advanceOption: {
    //   showActionColumn: true,
    //   showPagination: true,
    //   isEditableTable: true,
    //   showFilterColumn: true,
    //   showDeleteButton: false,
    // }
  }

  permissionValid: boolean = false;

  constructor(
    private authorizationService: AuthorizationService,
    private ngxLoader: NgxUiLoaderService,
    private authenticationService: AuthenticationService,
    private moduleService: ModuleService
  ) {
    let roles = this.authenticationService.getUserRoles('authorization');
    if (roles) {
      this.authenticationService.loadPermission(roles);
      this.permissionValid = true;
      this.loadAuthorization();
    }
    else {
      this.authenticationService.getRolesToken({ module: 'authorization' }).subscribe(
        response => {
          if (response.status) {
            let roles = this.authenticationService.getUserRoles('authorization');
            if (roles) {
              this.authenticationService.loadPermission(roles);
              this.permissionValid = true;
              this.loadAuthorization();
            }
          }
        }
      );
    }
   }

  ngOnInit() {

  }

  ngAfterContentInit() {
    setTimeout(() => {
      this.moduleService.syncModuleToken('authorization');
    }, 5000);
  }

  loadAuthorization(){
    this.tableConfig.loading = true;
    let param = { pageSize: this.tableConfig.paginateOption.pageSize, page: this.tableConfig.paginateOption.pageNumber, filter: this.tableConfig.filter }
    this.authorizationService.getAuthorizations(param).subscribe(
      response => {
        if(response.status){
          this.tableConfig.dataSource = response.result.row;
          this.tableConfig.paginateOption.total = response.result.total;
        }
        else{
          // Swal.fire(response.message);
          Swal.fire({
            title: 'Sorry!',
            type: 'info',
            html: response.message
          });
        }
        this.tableConfig.loading = false;
      }
    );
  }

  getCurrentPageSize($event) {
    this.tableConfig.paginateOption.pageNumber = 1;
    this.tableConfig.paginateOption.pageSize = $event;
    this.loadAuthorization();
  }

  getCurrentPage($event) {
    this.tableConfig.paginateOption.pageNumber = Math.ceil($event);
    this.loadAuthorization();
  }

  getFilterInput($event) {
    this.tableConfig.filter = ($event) ? $event : {};
  }

  validation(){

  }

  add(){
    this.authorizationService.addAuthorization(this.authorizationData).subscribe(
      response => {
        if(response.status && response.result){
          this.loadAuthorization();
          Swal.fire('Behasil menyimpan.');
        }
        else{
          Swal.fire(response.message);
        }
      }
    );
  }

  getRowEdited($event){
    this.authorizationService.editAuthorization($event).subscribe(
      response => {
        if (response.status && response.result) {
          this.loadAuthorization();
          Swal.fire('Behasil menyimpan.');
        }
        else {
          Swal.fire(response.message);
        }
      }
    );
  }

  getRowCancelEdit($event) {
    this.loadAuthorization();
  }


}
