import { OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { LandingpageService } from 'src/app/_services/landingpage.service';
import Swal from 'sweetalert2';
var DiscComponent = /** @class */ (function () {
    function DiscComponent(actRoute, ngxLoader, landingService, router) {
        this.actRoute = actRoute;
        this.ngxLoader = ngxLoader;
        this.landingService = landingService;
        this.router = router;
        this.id_voucher = this.actRoute.snapshot.params['voucher'];
        this.isStart = false;
        this.arrAnswer = [];
        this.answerMapping = { A: 0, B: 1, C: 2, D: 3, E: 4, F: 5, G: 6, H: 7, I: 8, J: 9 };
        this.examsSectionActive = {};
        this.oneExamsSectionActive = {};
        this.apiMapping = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J'];
        this.listAnswer = [];
    }
    DiscComponent.prototype.ngOnInit = function () {
        this.beforeExam();
    };
    DiscComponent.prototype.beforeExam = function () {
        var _this = this;
        this.ngxLoader.start();
        this.landingService.beforeExam(this.id_voucher).subscribe(function (response) {
            (response.status) ? (_this.dataBefore = response.result) : _this.showSwal('Info!', response.message);
            _this.ngxLoader.stop();
        }, function (error) {
            _this.ngxLoader.stop();
            _this.showSwal('Perhatian..', error.error.message);
        });
    };
    DiscComponent.prototype.reloadPage = function () {
        this.dataBefore = undefined;
        this.beforeExam();
    };
    DiscComponent.prototype.doingExam = function () {
        var _this = this;
        this.ngxLoader.start();
        this.landingService.doingExam(this.id_voucher).subscribe(function (response) {
            if (response.status) {
                _this.exams = response.result.row.model;
                _this.answers = (response.result.row.jawaban) ? response.result.row.jawaban : {};
                _this.total_section = response.result.row.total_section;
                _this.isStart = true;
                _this.formatAnswer();
                _this.activeIndeks = 1;
                _this.examsSectionActive = _this.exams.filter(function (item) { return item.section == _this.activeIndeks; });
                _this.oneExamsSectionActive = _this.examsSectionActive[0];
                _this.arrAnswer[_this.activeIndeks] = null;
            }
            else {
                _this.showSwal('Info!', response.message);
            }
            _this.ngxLoader.stop();
        }, function (error) {
            _this.ngxLoader.stop();
            _this.showSwal('Perhatian..', error.error.message);
        });
    };
    DiscComponent.prototype.formatAnswer = function () {
        for (var i in this.answers) {
            if (Array.isArray(this.answers[i])) {
                var temp = ['', '', '', '', '', '', '', '', '', ''];
                for (var j = 0; j < this.answers[i].length; j++) {
                    temp.splice(this.answerMapping[this.answers[i][j]], 1, this.answers[i][j]);
                }
                this.answers[i] = temp;
            }
        }
    };
    DiscComponent.prototype.formatList = function (row) {
        this.listAnswer = [];
        for (var i = 0; i < 10; i++) {
            var getJawabanRow = row[this.apiMapping[i]];
            if (getJawabanRow !== null && getJawabanRow !== '') {
                this.listAnswer.push({ key: getJawabanRow, val: getJawabanRow });
            }
        }
        return this.listAnswer;
    };
    DiscComponent.prototype.formatQuestion = function (row) {
        return row.soal.split('] ')[1];
    };
    DiscComponent.prototype.formatTipe = function (row) {
        return (row.soal.substring(row.soal.lastIndexOf('[') + 1, row.soal.lastIndexOf(']'))).toLowerCase();
    };
    DiscComponent.prototype.checkLink = function (idDetailUjian, answerklik, answermap) {
        if ((idDetailUjian == this.examsSectionActive[1].id_detail_ujian) && (this.arrAnswer[this.activeIndeks] == answermap)) {
            return "btn-dark linkDisabled strike";
        }
        else {
            if (answerklik === answermap) {
                return 'btn-primary';
            }
            else {
                return 'btn-secondary';
            }
        }
    };
    DiscComponent.prototype.onAnswering = function (idDetailUjian, answer) {
        if (this.examsSectionActive[0].id_detail_ujian == idDetailUjian) {
            this.arrAnswer[this.activeIndeks] = answer;
            this.answers[this.examsSectionActive[1].id_detail_ujian] = null;
        }
        this.answers[idDetailUjian] = answer;
    };
    DiscComponent.prototype.checkingAnswer = function (answers, answer) {
        return (answers) ? answers.includes(answer) : false;
    };
    DiscComponent.prototype.navigation = function (next) {
        var _this = this;
        if (this.answers[this.examsSectionActive[0].id_detail_ujian] != undefined && this.answers[this.examsSectionActive[0].id_detail_ujian] != null && this.answers[this.examsSectionActive[1].id_detail_ujian] != undefined && this.answers[this.examsSectionActive[1].id_detail_ujian] != null) {
            this.activeIndeks = (next) ? (this.activeIndeks + 1) : (this.activeIndeks - 1);
            this.examsSectionActive = this.exams.filter(function (item) { return item.section == _this.activeIndeks; });
            this.oneExamsSectionActive = this.examsSectionActive[0];
        }
        else {
            this.showSwal('Warning!', 'Mohon untuk mengisi semua soal terlebih dahulu.');
        }
        console.log("soal 1 :", this.answers[this.examsSectionActive[0].id_detail_ujian]);
        console.log("soal 2 :", this.answers[this.examsSectionActive[1].id_detail_ujian]);
    };
    DiscComponent.prototype.submitExam = function () {
        var _this = this;
        if (this.answers[this.examsSectionActive[0].id_detail_ujian] != undefined && this.answers[this.examsSectionActive[0].id_detail_ujian] != null && this.answers[this.examsSectionActive[1].id_detail_ujian] != undefined && this.answers[this.examsSectionActive[1].id_detail_ujian] != null) {
            Swal.fire({
                title: 'Apakah anda yakin?',
                text: 'Ujian anda akan disimpan ke dalam sistem!',
                type: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Ya, saya yakin!'
            }).then(function (result) {
                if (result.dismiss) {
                    console.log("dismissed");
                }
                else {
                    _this.ngxLoader.start();
                    if (result.value) {
                        var param = {
                            idujian: _this.dataBefore.obj_exam.id_ujian,
                            voucher: _this.id_voucher,
                            jawaban: _this.answers
                        };
                        var tempJawaban = [];
                        var tempExam = _this.exams;
                        var _loop_1 = function (key) {
                            if (param.jawaban[key] !== null) {
                                var findSoal = tempExam.find(function (item) { return item.id_detail_ujian == key; });
                                if (findSoal) {
                                    var index1 = tempExam.indexOf(findSoal);
                                    if (index1 !== -1) {
                                        tempJawaban.push({ soal: tempExam[index1]['soal'], jawaban_user: param.jawaban[key], id_detail_ujian: key });
                                    }
                                }
                            }
                        };
                        for (var _i = 0, _a = Object.keys(param.jawaban); _i < _a.length; _i++) {
                            var key = _a[_i];
                            _loop_1(key);
                        }
                        param.jawaban = tempJawaban;
                        _this.landingService.saveAnswer(param).subscribe(function (response) {
                            _this.ngxLoader.stop();
                            if (response.status) {
                                if (response.result.applicant === 0) {
                                    _this.showSwal('Info!', 'Terima kasih. Jawaban Berhasil disimpan.');
                                }
                                else {
                                    _this.showSwal('Info!', 'Terima kasih. Jawaban DISC TEST Berhasil disimpan. Selanjutnya silakan menunggu tim rekrutmen memproses menjadikan internal employee.');
                                }
                                _this.router.navigate(['/home']);
                            }
                            else {
                                _this.showSwal('Info!', response.message);
                            }
                        }, function (error) {
                            _this.showSwal('Perhatian..', error.error.message);
                            _this.ngxLoader.stop();
                        });
                    }
                }
            });
        }
        else {
            this.showSwal('Warning!', 'Mohon untuk mengisi semua soal terlebih dahulu.');
        }
    };
    DiscComponent.prototype.showSwal = function (title, text) {
        Swal.fire({
            title: title,
            text: text,
            type: 'warning',
            showCancelButton: false,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'OK',
            allowOutsideClick: false
        }).then(function (result) {
            if (result.value) {
                return false;
            }
        });
    };
    return DiscComponent;
}());
export { DiscComponent };
