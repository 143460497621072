import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ResponseApiModel } from '../_models/response-api.model';
import { GlobalService } from './global.service';

const API_CREATE = '/whistleBlowing/default/create-whistle-blowing';
const API_GET_ALL = '/whistleBlowing/manage/get-whistle-blowings';
const API_GET_ATTACH = '/whistleBlowing/manage/get-whistle-blowing';

@Injectable({
  providedIn: 'root'
})
export class WhistleBlowingService {

  constructor(
    private http: HttpClient,
    private globalService: GlobalService,
    private cookieService: CookieService
  ) { }

  public header() {
    return {
      headers: new HttpHeaders()
        .set('token', this.cookieService.get('_q'))
        .set('module', localStorage.getItem('whistleBlowing'))
    };
  }

  public create(params: { master: any, detail: any[] }): Observable<ResponseApiModel> {
    return this.http.post<ResponseApiModel>(
      this.globalService.apiVersionHost + API_CREATE, params
    ).pipe(
      map(response => response)
    );
  }

  public getAll(params: { pageSize: number, page: number, filter: any }): Observable<ResponseApiModel> {
    return this.http.post<ResponseApiModel>(
      this.globalService.apiVersionHost + API_GET_ALL, params, this.header()
    ).pipe(
      map(response => response)
    );
  }

  public getAttach(params: { id: number }): Observable<ResponseApiModel> {
    return this.http.post<ResponseApiModel>(
      this.globalService.apiVersionHost + API_GET_ATTACH, params, this.header()
    ).pipe(
      map(response => response)
    );
  }
}
