import { map } from "rxjs/operators";
import { Observable, Subject } from "rxjs";
import { CookieService } from "ngx-cookie-service";
import { GlobalService } from "src/app/_services/global.service";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ResponseApiModel } from "../_models/response-api.model";

const API_PMC_DASHBOARD = "/pmc/dashboard/get-dashboard";
const API_PMC_GET_PROJECT_ITEM = "/pmc/dashboard/get-project-item";
const API_PMC_GET_FEEDS = "/pmc/feed/get-feeds";
const API_PMC_PREPARE_CREATE = "/pmc/item/prepare-create";
const API_PMC_SAVE_ITEM = "/pmc/item/save-item";
const API_PMC_SAVE_SPRINT = "/pmc/item/save-sprint";
const API_PMC_GET_MY_TASKS = "/pmc/my-task/get-my-tasks";
const API_PMC_GET_TASKS_FRONT = "/pmc/my-task/get-tasks-front";
const API_PMC_GET_TASKS_FRONT_NEW = "/pmc/my-task/get-tasks-front-new";
const API_PMC_GET_MY_APPROVALS = "/pmc/my-approval/get-my-approvals";
const API_PMC_GET_CLASSIC = "/pmc/classic/prepare";
const API_PMC_CHANGE_STATUS = "/pmc/classic/change-status";
const API_PMC_GET_TREE = "/pmc/tree-view/get-project-items";
const API_PMC_GET_DOCUMENTS = "/pmc/documents/get-documents";
const API_PMC_GET_ISSUES = "/pmc/issue/get-issues";
const API_PMC_GET_KANBAN = "/pmc/kanban/get-kanban";
const API_PMC_GET_TABULAR = "/pmc/tabular/get-tabular";
const API_PMC_SAVE_FOLDER = "/pmc/documents/save-folder";
const API_PMC_ATTENDANCE_VS_TASK = "/pmc/report/attendance-vs-task";
const API_PMC_GANTT_CHART = "/pmc/report/gantt-chart";
const API_PMC_GANTT = "/pmc/gantt/gantt-data";
const API_PMC_GET_CUSTOM_FIELDS = "/pmc/item/get-custom-fields";
const API_PMC_GET_ROOT = "/pmc/navigation/get-root";
const API_PMC_GET_CHILD = "/pmc/navigation/get-child";
const API_PMC_GET_PATH = "/pmc/navigation/get-path";
const API_PMC_LOAD_CHILDS = "/pmc/classic/load-childs";
const API_PMC_LOAD_MORE_CHILDS = "/pmc/classic/load-more-childs";
const API_PMC_LOAD_ISSUES = "/pmc/classic/load-issues";
const API_PMC_LOAD_MORE_ISSUES = "/pmc/classic/load-more-issues";
const API_PMC_CUSTOM_REPORT = "/pmc/report/custom-report";
const API_PMC_GET_USER_PROJECT = "/pmc/classic/get-user-project";
const API_PMC_GET_DASHBOARD_PROJECT = "/pmc/dashboard-project/get-dashboard";
const API_PMC_UPDATE_PIC_MEMBER = "/pmc/classic/update-pic-member";
const API_PMC_GET_HIRARKI = "/pmc/classic/get-hirarki";
const API_PMC_ADD_DOCUMENT = "/pmc/documents/add-document";
const API_PMC_DELETE_DOCUMENT = "/pmc/documents/delete-document";

@Injectable({
  providedIn: 'root'
})
export class PmcService {

  public reloadDataEmit: Subject<boolean> = new Subject<boolean>();
  public reloadDataEmitObs = this.reloadDataEmit.asObservable();

  constructor(
    private http: HttpClient,
    private globalService: GlobalService,
    private cookieService: CookieService
  ) {}

  public header() {
    return {
      headers: new HttpHeaders()
        .set('token', this.cookieService.get('_q'))
        .set('module', localStorage.getItem('pmc'))
    };
  }

  public getDashboard(): Observable<ResponseApiModel> {
    return this.http
      .post<ResponseApiModel>(
        this.globalService.apiVersionHost + API_PMC_DASHBOARD,
        null,
        this.header()
      )
      .pipe(map(response => response));
  }

  public getProjectItem(id: number): Observable<ResponseApiModel> {
    return this.http
      .post<ResponseApiModel>(
        this.globalService.apiVersionHost + API_PMC_GET_PROJECT_ITEM,
        { id: id },
        this.header()
      )
      .pipe(map(response => response));
  }

  public getFeeds(param: any): Observable<ResponseApiModel> {
    return this.http
      .post<ResponseApiModel>(
        this.globalService.apiVersionHost + API_PMC_GET_FEEDS,
        param,
        this.header()
      )
      .pipe(map(response => response));
  }

  public getMyTasks(param: {
    pageSize: number;
    page: number;
    filter: any;
  }): Observable<ResponseApiModel> {
    return this.http
      .post<ResponseApiModel>(
        this.globalService.apiVersionHost + API_PMC_GET_MY_TASKS,
        param,
        this.header()
      )
      .pipe(map(response => response));
  }

  public getTasksFront(param: {
    pageSize: number;
    filter: any;
  }): Observable<ResponseApiModel> {
    return this.http
      .post<ResponseApiModel>(
        this.globalService.apiVersionHost + API_PMC_GET_TASKS_FRONT,
        param,
        this.header()
      )
      .pipe(map(response => response));
  }

  public getTasksFrontNew(param: {
    pageSize: number;
    filter: any;
  }): Observable<ResponseApiModel> {
    return this.http
      .post<ResponseApiModel>(
        this.globalService.apiVersionHost + API_PMC_GET_TASKS_FRONT_NEW,
        param,
        this.header()
      )
      .pipe(map(response => response));
  }

  public getMyApprovals(param: {
    pageSize: number;
    page: number;
    filter: any;
  }): Observable<ResponseApiModel> {
    return this.http
      .post<ResponseApiModel>(
        this.globalService.apiVersionHost + API_PMC_GET_MY_APPROVALS,
        param,
        this.header()
      )
      .pipe(map(response => response));
  }

  public getClassic(param: { hash: string }) {
    return this.http
      .post<ResponseApiModel>(
        this.globalService.apiVersionHost + API_PMC_GET_CLASSIC,
        param,
        this.header()
      )
      .pipe(map(response => response));
  }

  public prepareCreate(param: {
    hashParent: string;
  }): Observable<ResponseApiModel> {
    return this.http
      .post<ResponseApiModel>(
        this.globalService.apiVersionHost + API_PMC_PREPARE_CREATE,
        param,
        this.header()
      )
      .pipe(map(response => response));
  }

  public saveItem(param: any): Observable<ResponseApiModel> {
    return this.http
      .post<ResponseApiModel>(
        this.globalService.apiVersionHost + API_PMC_SAVE_ITEM,
        param,
        this.header()
      )
      .pipe(map(response => response));
  }

  public saveSprint(param: any): Observable<ResponseApiModel> {
    return this.http
      .post<ResponseApiModel>(
        this.globalService.apiVersionHost + API_PMC_SAVE_SPRINT,
        param,
        this.header()
      )
      .pipe(map(response => response));
  }

  public changeStatus(param: {
    hash: string;
    status: string;
  }): Observable<ResponseApiModel> {
    return this.http
      .post<ResponseApiModel>(
        this.globalService.apiVersionHost + API_PMC_CHANGE_STATUS,
        param,
        this.header()
      )
      .pipe(map(response => response));
  }

  public getTree(param: { hash: string }): Observable<ResponseApiModel> {
    return this.http
      .post<ResponseApiModel>(
        this.globalService.apiVersionHost + API_PMC_GET_TREE,
        param,
        this.header()
      )
      .pipe(map(response => response));
  }

  public getDocuments(param): Observable<ResponseApiModel> {
    return this.http
      .post<ResponseApiModel>(
        this.globalService.apiVersionHost + API_PMC_GET_DOCUMENTS,
        param,
        this.header()
      )
      .pipe(map(response => response));
  }

  public getIssues(param: {
    pageSize: number;
    page: number;
    filter: any;
  }): Observable<ResponseApiModel> {
    return this.http
      .post<ResponseApiModel>(
        this.globalService.apiVersionHost + API_PMC_GET_ISSUES,
        param,
        this.header()
      )
      .pipe(map(response => response));
  }

  public getFileInfo(url) {
    return this.http
      .get(url, { responseType: 'arraybuffer' })
      .pipe(map(response => response));
  }

  public getKanban(param: any): Observable<ResponseApiModel> {
    return this.http
      .post<ResponseApiModel>(
        this.globalService.apiVersionHost + API_PMC_GET_KANBAN,
        param,
        this.header()
      )
      .pipe(map(response => response));
  }

  public getTabular(param: {
    pageSize: number;
    page: number;
    filter: any;
  }): Observable<ResponseApiModel> {
    return this.http
      .post<ResponseApiModel>(
        this.globalService.apiVersionHost + API_PMC_GET_TABULAR,
        param,
        this.header()
      )
      .pipe(map(response => response));
  }

  public saveFolder(param: {
    nama_item: string;
    hash_parent: string;
  }): Observable<ResponseApiModel> {
    return this.http
      .post<ResponseApiModel>(
        this.globalService.apiVersionHost + API_PMC_SAVE_FOLDER,
        param,
        this.header()
      )
      .pipe(map(response => response));
  }

  public ganttChart(param: { hash: string }): Observable<ResponseApiModel> {
    return this.http
      .post<ResponseApiModel>(
        this.globalService.apiVersionHost + API_PMC_GANTT_CHART,
        param,
        this.header()
      )
      .pipe(map(response => response));
  }
  public getGantt(param: { hash: string }): Observable<ResponseApiModel> {
    return this.http
      .post<ResponseApiModel>(
        this.globalService.apiVersionHost + API_PMC_GANTT,
        param,
        this.header()
      )
      .pipe(map(response => response));
  }

  public getAttendanceVsTask(param: any): Observable<ResponseApiModel> {
    return this.http
      .post<ResponseApiModel>(
        this.globalService.apiVersionHost + API_PMC_ATTENDANCE_VS_TASK,
        param,
        this.header()
      )
      .pipe(map(response => response));
  }

  public getCustomFields(param: any): Observable<ResponseApiModel> {
    return this.http
      .post<ResponseApiModel>(
        this.globalService.apiVersionHost + API_PMC_GET_CUSTOM_FIELDS,
        param,
        this.header()
      )
      .pipe(map(response => response));
  }

  public getRootItem(): Observable<ResponseApiModel> {
    return this.http
      .post<ResponseApiModel>(
        this.globalService.apiVersionHost + API_PMC_GET_ROOT,
        null,
        this.header()
      )
      .pipe(map(response => response));
  }

  public getChildItem(param: {
    hashParent: string;
  }): Observable<ResponseApiModel> {
    return this.http
      .post<ResponseApiModel>(
        this.globalService.apiVersionHost + API_PMC_GET_CHILD,
        param,
        this.header()
      )
      .pipe(map(response => response));
  }

  public getPathItem(param: { hash: string }): Observable<ResponseApiModel> {
    return this.http
      .post<ResponseApiModel>(
        this.globalService.apiVersionHost + API_PMC_GET_PATH,
        param,
        this.header()
      )
      .pipe(map(response => response));
  }

  public loadChilds(param: {
    hash: string;
    filter: any;
  }): Observable<ResponseApiModel> {
    return this.http
      .post<ResponseApiModel>(
        this.globalService.apiVersionHost + API_PMC_LOAD_CHILDS,
        param,
        this.header()
      )
      .pipe(map(response => response));
  }
  public loadMoreChilds(param: {
    hash: string;
    count: number;
    filter: any;
  }): Observable<ResponseApiModel> {
    return this.http
      .post<ResponseApiModel>(
        this.globalService.apiVersionHost + API_PMC_LOAD_MORE_CHILDS,
        param,
        this.header()
      )
      .pipe(map(response => response));
  }

  public loadIssues(param: {
    hash: string;
    filter: any;
  }): Observable<ResponseApiModel> {
    return this.http
      .post<ResponseApiModel>(
        this.globalService.apiVersionHost + API_PMC_LOAD_ISSUES,
        param,
        this.header()
      )
      .pipe(map(response => response));
  }
  public loadMoreIssues(param: {
    hash: string;
    count: number;
    filter: any;
  }): Observable<ResponseApiModel> {
    return this.http
      .post<ResponseApiModel>(
        this.globalService.apiVersionHost + API_PMC_LOAD_MORE_ISSUES,
        param,
        this.header()
      )
      .pipe(map(response => response));
  }

  public getCustomReport(param: {
    hash: string;
  }): Observable<ResponseApiModel> {
    return this.http
      .post<ResponseApiModel>(
        this.globalService.apiVersionHost + API_PMC_CUSTOM_REPORT,
        param,
        this.header()
      )
      .pipe(map(response => response));
  }

  public getUserProject(param: { hash: string }): Observable<ResponseApiModel> {
    return this.http
      .post<ResponseApiModel>(
        this.globalService.apiVersionHost + API_PMC_GET_USER_PROJECT,
        param,
        this.header()
      )
      .pipe(map(response => response));
  }

  public getDashboardProject(param: {
    hash: string;
  }): Observable<ResponseApiModel> {
    return this.http
      .post<ResponseApiModel>(
        this.globalService.apiVersionHost + API_PMC_GET_DASHBOARD_PROJECT,
        param,
        this.header()
      )
      .pipe(map(response => response));
  }

  public updatePicMember(param: {
    hash: string;
    dataPIC?: any;
    dataMember?: any;
  }): Observable<ResponseApiModel> {
    return this.http
      .post<ResponseApiModel>(
        this.globalService.apiVersionHost + API_PMC_UPDATE_PIC_MEMBER,
        param,
        this.header()
      )
      .pipe(map(response => response));
  }

  public getHirarki(param: { hash: string }): Observable<ResponseApiModel> {
    return this.http
      .post<ResponseApiModel>(
        this.globalService.apiVersionHost + API_PMC_GET_HIRARKI,
        param,
        this.header()
      )
      .pipe(map(response => response));
  }
  
  public addDocument(param: { hash: string, idWasabi: number }): Observable<ResponseApiModel> {
    return this.http
      .post<ResponseApiModel>(
        this.globalService.apiVersionHost + API_PMC_ADD_DOCUMENT,
        param,
        this.header()
      )
      .pipe(map(response => response));
  }

  public deleteDocument(param: { id: number }): Observable<ResponseApiModel> {
    return this.http
      .post<ResponseApiModel>(
        this.globalService.apiVersionHost + API_PMC_DELETE_DOCUMENT,
        param,
        this.header()
      )
      .pipe(map(response => response));
  }
}
