<div id="nds_contents" style="margin-top: 70px">

  <div style="width: 100px">
    <!-- <app-image-uploader></app-image-uploader> -->

    <app-image-uploader [isPublicFile]="true" [prefix]="'uploads/foto/'" [acceptFile]="'.jpg,.jpeg,.png'" [tableTarget]="'users'"
      [columnTarget]="'foto'" (success)="onSuccess($event,'foto')" [cropper]="true">
    </app-image-uploader>
  </div>

</div>
