import { NgModule } from '@angular/core';
import { Routes, RouterModule, ExtraOptions } from '@angular/router';
import { NotFoundComponent } from './layout/not-found/not-found.component';
import { ForbiddenComponent } from './layout/forbidden/forbidden.component';
import { SsoComponent } from './layout/sso/sso.component';
import { ChangePasswordComponent } from './layout/change-password/change-password.component';
import { NgxPermissionsGuard } from 'ngx-permissions';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./layout/landing/landing.module').then(m => m.LandingModule),
  },
  {
    path: 'main',
    loadChildren: () => import('./layout/main/main.module').then(m => m.MainModule),
  },
  {
    path: 'customer',
    loadChildren: () => import('./layout/customer-management/customer-management.module').then(m => m.CustomerManagementModule),
  },
  {
    path: 'sso',
    component: SsoComponent,
  },
  {
    path: 'change-password',
    component: ChangePasswordComponent,
    canActivate: [NgxPermissionsGuard],
    data: {
      permissions: {
        only: ['USER'],
        redirectTo: '/'
      }
    }
  },
  {
    path: '404', component: NotFoundComponent
  },
  {
    path: '403', component: ForbiddenComponent
  },
  {
    path: '**',
    redirectTo: '/404',
    pathMatch: 'full'
  },
];

const routerOptions: ExtraOptions = {
  useHash: false,
  anchorScrolling: 'enabled',
};

@NgModule({
  imports: [RouterModule.forRoot(routes, routerOptions)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
