import { Component, OnInit  } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { LandingpageService } from 'src/app/_services/landingpage.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-faqpost  ',
  templateUrl: './faqpost.component.html',
  styleUrls: ['./faqpost.component.scss']
})
export class FaqpostComponent implements OnInit {
  isLoading = false;
  hash = this.actRoute.snapshot.params['hash'];
  dataSource: any;
  dataTerkait: any[] = [];
  host: any;
  private sub: any;

  constructor(
    public router: Router,
    public actRoute: ActivatedRoute,
    private landingService: LandingpageService,
  ) { }

  ngOnInit() {
    this.reloadData(this.hash);
  }

  reloadData(hash) {
    this.isLoading = true;
    const param = { hash: hash };
    this.sub =  this.landingService.getPostFaqLanding(param).subscribe(response => {
      this.dataSource = response.result.row;
      this.dataTerkait = response.result.row_faq_terkait;
      this.host = response.result.row.host;
      this.isLoading = false;
    }, error => {
      this.isLoading = false;
      Swal.fire(error.error.message);
    });
  }

  changeFaq(hash){
    this.router.navigate(['/faqpost/',hash]);
    this.hash = hash;
    this.reloadData(hash);
  }
}
