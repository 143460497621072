import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { PaginateModel } from 'src/app/_models/paginate.model';
import { GlobalService } from 'src/app/_services/global.service';
import { LandingpageService } from 'src/app/_services/landingpage.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-video-exam',
  templateUrl: './video-exam.component.html',
  styleUrls: ['./video-exam.component.scss']
})
export class VideoExamComponent implements OnInit {
  id_voucher = this.actRoute.snapshot.params['voucher'];
  dataBefore: any;
  
  exams: any;
  // answers: any;
  arrAnswer: any = [];
  activeIndeks: number;
  examsSectionActive: any = {};
  oneExamsSectionActive: any = {};
  // total_section: any;
  listAnswer: any = [];

  apiHost = '';
  id: number = null;
  video = null;
  exam = null;
  step = null;
  videoUrl = null;
  token = '';
  
  questions = [];
  pretest = [];
  posttest = [];
  answers = [];

  total_section = 0;
  total_pretest_section = 0;
  total_posttest_section = 0;

  activeIndex = 1;
  activeSection = null;
  activeQuestion = null;

  activePretestSection = null;
  activePretest = null;
  activePosttestSection = null;
  activePosttest = null;

  startPosttest: boolean = false;
  isStart: boolean = false;

  apiMapping = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J'];
  answerMapping = { A: 0, B: 1, C: 2, D: 3, E: 4, F: 5, G: 6, H: 7, I: 8, J: 9 };

  pageSizeOption: number[] = [10, 20, 30, 40, 50];
  paging = new PaginateModel({ pageSize: 10 });
  filter: any = {};
  detail: any = {};

  constructor(
    private actRoute: ActivatedRoute,
    private ngxLoader: NgxUiLoaderService,
    private landingService: LandingpageService,
    private router: Router,
    private globalService: GlobalService
  ) { }

  ngOnInit() {
    this.apiHost = this.globalService.apiHost;

    this.beforeExam();
  }

  beforeExam() {
    this.ngxLoader.start();
    this.landingService.beforeExam(this.id_voucher).subscribe(response => {
      if (response.status) {
        this.dataBefore = response.result;
        this.id = response.result.id;
        console.log(this.id);
      } else {
        this.showSwal('Info!', response.message);
      }

      // (response.status) ? (
      //   this.dataBefore = response.result
      // ) : this.showSwal('Info!', response.message);

      this.ngxLoader.stop();
    }, error => {
      this.ngxLoader.stop();
      this.showSwal('Perhatian..', error.error.message);
    });
  }

  showSwal(title: string, text: string) {
    Swal.fire({
      title: title,
      text: text,
      type: 'warning',
      showCancelButton: false,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'OK',
      allowOutsideClick: false
    }).then((result) => {
      if (result.value) {
        return false;
      }
    });
  }

  reloadPage() {
    this.dataBefore = undefined;
    this.beforeExam();
  }

  doingExam() {
    console.log(this.id);
    this.landingService.viewVideoExam({id:this.id,voucher:this.id_voucher}).subscribe(response => {
      // console.log(response);
if(response.status){
      this.video = response.result.video;
      //this.token = response.result.stream.token;
      this.exam = response.result.exam;
      this.step = response.result.step;

      if(this.step=='finish')
      {
        this.showSwal('Info!', 'Terima kasih. Anda telah menyelesaikan video exam ini.');
        // this.router.navigate(['/main/elearning/video-exam/summary/', this.id]);
        this.router.navigate(['/exam']);
        // this.router.navigate(['/home']);
        // this.reloadData();
      }

      this.questions = response.result.questions;
      this.pretest = response.result.pretest;
      this.posttest = response.result.posttest;

      if(this.pretest.length == 0)
      {
        this.step='posttest';
      }

      this.total_section = response.result.total_section;
      this.activeIndex = 1;

      this.activePretestSection = this.pretest.filter(item => item.section == this.activeIndex);
      this.activePretest = this.activePretestSection[0];
      this.total_pretest_section = response.result.total_pretest_section;

      this.activeSection = this.questions.filter(item => item.section == this.activeIndex);
      this.activeQuestion = this.activeSection[0];

      this.activePosttestSection = this.posttest.filter(item => item.section == this.activeIndex);
      this.activePosttest = this.activePosttestSection[0];
      this.total_posttest_section = response.result.total_posttest_section;

      this.videoUrl = 'https://iframe.videodelivery.net/' + this.video.video_id;
      this.isStart = true;

      console.log(this.activeSection);
    }else{
      this.showSwal('Sorry',response.message);
    }
    }, error => {
      console.log(error);
      this.showSwal('Perhatian..', error.message);
    });
  }

  formatAnswer() {
    for (const i in this.answers) {
      if (Array.isArray(this.answers[i])) {
        const temp = ['', '', '', '', '', '', '', '', '', ''];
        for (let j = 0; j < this.answers[i].length; j++) {
          temp.splice(this.answerMapping[this.answers[i][j]], 1, this.answers[i][j]);
        }
        this.answers[i] = temp;
      }
    }
  }

  formatList(row) {
    this.answers = [];
    for (let i = 0; i < 10; i++) {
      let getJawabanRow = row[this.apiMapping[i]];
      if (getJawabanRow !== null && getJawabanRow !== '') {
        this.answers.push({ key: getJawabanRow, val: getJawabanRow });
      }
    }
    return this.answers;
  }

  formatQuestion(row) {
    return row.soal.split('] ')[1];
  }

  formatTipe(row) {
    let result = (row.soal.substring(
      row.soal.lastIndexOf('[') + 1, row.soal.lastIndexOf(']')
    )).toLowerCase();

    return result;
  }

  onAnswering(idDetailUjian: number, answer: string) {
    this.answers[idDetailUjian] = answer;
    console.log("answer=",answer);
    console.log("this.answers=",this.answers);
  }

  onAnsweringMultiple(idDetailUjian: number, answer: string) {
    console.log("onAnsweringMultiple=",answer);
    if (!this.answers[idDetailUjian]) {
      this.answers[idDetailUjian] = ['', '', '', '', '', '', '', '', '', ''];
    }
    (this.answers[idDetailUjian].includes(answer)) ? (
      this.answers[idDetailUjian].splice(this.answerMapping[answer], 1, '')
    ) : this.answers[idDetailUjian].splice(this.answerMapping[answer], 1, answer);
  }

  onAnsweringUpload($event, idDetailUjian: number, answer: string) {
    console.log($event);
    const urlWasaby = this.apiHost + '/site/download-profile/' + $event.id;//$event.id.toString();
    const fileName = $event.filename.toString();
    const id_attachment = $event.id.toString();

    console.log(answer);
    console.log(id_attachment);

    this.answers[idDetailUjian] = id_attachment;
  }

  checkingAnswer(answers: any, answer: string) {
    console.log("checkingAnswer=",answer);
    return (answers) ? answers.includes(answer) : false;
  }

  navigationPretest(next: boolean) {
    this.activeIndex = (next) ? (this.activeIndex + 1) : (this.activeIndex - 1);
    this.activePretestSection = this.pretest.filter(item => item.section == this.activeIndex);
    this.activePretest = this.activePretestSection[0];

  }

  navigationPosttest(next: boolean) {
    this.activeIndex = (next) ? (this.activeIndex + 1) : (this.activeIndex - 1);
    this.activePosttestSection = this.posttest.filter(item => item.section == this.activeIndex);
    this.activePosttest = this.activePosttestSection[0];

  }

  navigation(next: boolean) {
    this.activeIndex = (next) ? (this.activeIndex + 1) : (this.activeIndex - 1);
    this.activeSection = this.questions.filter(item => item.section == this.activeIndex);
    this.activeQuestion = this.activeSection[0];
  }

  submitPrePost(type) {
    console.log(this.answers);
    let questions = this.pretest;
    if(type=="posttest")
    {
      questions = this.posttest;
    }

    let judul = 'Apakah anda yakin submit data Pre-Test?';
    if(type=='posttest')
    {
      judul = 'Apakah anda yakin submit data Post-Test?';
    }
    //type
    Swal.fire({
      title: judul,
      text: 'Ujian anda akan disimpan ke dalam sistem!',
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Ya, saya yakin!'
    }).then((result) => {
      console.log("submitted");
      if (result.value) {
        const param = {
          idujian: this.exam.id_ujian,
          voucher: this.id_voucher,
          jawaban: this.answers,
          type: type
        };

        const tempAnswers = [];
        const tempQuestions = questions;
        param.jawaban.forEach(function (value, key) {

          if (value !== null) {

            let findSoal = tempQuestions.find(item => item.id_detail_ujian == key);

            if (findSoal) {
              let index1 = tempQuestions.indexOf(findSoal);
              if (index1 !== -1) {
                tempAnswers.push({ soal: tempQuestions[index1]['soal'], jawaban_user: value, id_detail_ujian: key });
              }
            }
          }
        });

        param.jawaban = tempAnswers;
        param.type = type;
        console.log(param);

        this.landingService.savePrePostAnswerLanding(param).subscribe(response => {
          if (response.status) {
            this.showSwal('Info!', 'Terima kasih. Jawaban Berhasil disimpan.');
            if(type=='pretest')
            {
              this.step='posttest';
              // this.reloadCurrentRoute();
            }
            else
            {
              // this.router.navigate(['/main/elearning/video-exam/summary/', this.id]);
              this.router.navigate(['/exam']);
              // this.step='finish';
              // this.reloadData();
            }
          } else {
            this.showSwal('Info!', response.message);
          }
        }, error => {
          this.showSwal('Perhatian..', error.error.message);
        });
      }
    });
  }

  startPostTest()
  {
    console.log("startPosttest");
    this.startPosttest = true;
  }

  submitExam() {

    console.log(this.questions);
    console.log(this.answers);

    // if (this.questions.length != this.answers.length) {
    //   this.showSwal('Warning', 'Please answer all of the questions');
    //   return;
    // }

    Swal.fire({
      title: 'Apakah anda yakin?',
      text: 'Ujian anda akan disimpan ke dalam sistem!',
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Ya, saya yakin!'
    }).then((result) => {
      if (result.value) {
        const param = {
          idujian: this.exam.id_ujian,
          voucher: this.id_voucher,
          jawaban: this.answers
        };

        const tempAnswers = [];
        const tempQuestions = this.questions;
        param.jawaban.forEach(function (value, key) {

          if (value !== null) {

            let findSoal = tempQuestions.find(item => item.id_detail_ujian == key);

            if (findSoal) {
              let index1 = tempQuestions.indexOf(findSoal);
              if (index1 !== -1) {
                tempAnswers.push({ soal: tempQuestions[index1]['soal'], jawaban_user: value, id_detail_ujian: key });
              }
            }
          }
        });

        param.jawaban = tempAnswers;

        console.log(param);

        this.landingService.saveVideoAnswerLanding(param).subscribe(response => {
          if (response.status) {
            this.showSwal('Info!', 'Terima kasih. Jawaban Berhasil disimpan.');
            // this.router.navigate(['/home']);
          } else {
            this.showSwal('Info!', response.message);
          }
        }, error => {
          this.showSwal('Perhatian..', error.error.message);
        });
      }
    });
  }
}
