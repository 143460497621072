
// Browser tags
export const CHROME = 'Chrome';
export const CRIOS = 'CriOS';
export const FIREFOX = 'Firefox';
export const FX_IOS = 'FxiOS';
export const SAFARI = 'Safari';
export const EDGE = 'Edg';
export const EDGE_IOS = 'EdgiOS';
export const OPR = 'Opr';
export const MSIE_60 = 'MSIE 6.0';
export const MSIE_70 = 'MSIE 7.0';
export const MSIE_80 = 'MSIE 8.0';
export const MSIE_90 = 'MSIE 9.0';
export const MSIE_100 = 'MSIE 10.0';
export const TRIDENT = 'Trident';
export const OTHER = 'Other';

// Browser Labels
export const GOOGLE_CHROME = 'Google Chrome';
export const MOZILLA_FIREFOX = 'Mozilla Firefox';
export const MICROSOFT_EDGE = 'Microsoft Edge';
export const OPERA = 'Opera';
export const INTERNET_EXPLORER = 'Internet Explorer';
export const OTHER_CHROMIUM = 'Chromium';


// Operating Systems
export const WIN64 = 'Win64';
export const WINDOWS_60 = 'Windows NT 6.0';
export const WINDOWS_61 = 'Windows NT 6.1';
export const WINDOWS_62 = 'Windows NT 6.2';
export const WINDOWS_10 = 'Windows NT 10.0';
export const WINDOWS = 'Windows';
export const MACOS = 'Mac OS';
export const LINUX = 'Linux';
export const IOS = 'iOS';
export const CROS = 'CrOS';

export const ANDROID = 'Android';

// Hardware
export const IPHONE = 'iPhone';
export const MACINTOSH = 'Macintosh';
export const CHROME_BOOK = 'Chromebook';


