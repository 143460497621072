<!-- begin:: Content Head -->
<div class="kt-subheader kt-grid__item" id="kt_subheader">
  <div class="kt-subheader__main">
    <!-- <button (click)="toggleProfileAside()" class="kt-subheader__mobile-toggle kt-subheader__mobile-toggle--left" id="kt_subheader_mobile_toggle">
        <span></span>
      </button> -->
    <ng-container *ngIf="breadcrumbs.length !== 0">
      <h3 class="kt-subheader__title text-capitalize">{{breadcrumbs[0].label}}</h3>
    </ng-container>
    <ng-container *ngIf="breadcrumbs.length === 0">
      <h3 class="kt-subheader__title text-capitalize">Dashboard</h3>
    </ng-container>
    <span class="kt-subheader__separator kt-subheader__separator--v"></span>

    <div class="kt-subheader__breadcrumbs">
      <ng-container *ngIf="breadcrumbs.length > 0">
        <ng-container *ngIf="moduleActive != 'pmc'">
          <a href="" class="kt-subheader__breadcrumbs-link" [routerLink]="['/main/pm']">
            <i class="flaticon2-menu-2"></i>
          </a>
          <ng-container *ngFor="let bread of breadcrumbs[0].breadcrumb">
            <span class="kt-subheader__breadcrumbs-separator"></span>
            <a href="" class="kt-subheader__breadcrumbs-link text-capitalize" [routerLink]="bread.path">
              {{bread.step}}
            </a>
          </ng-container>
        </ng-container>
        <ng-container *ngIf="moduleActive == 'pmc'">
          <a href="javascript:;" href="https://pmc.ptnw.net/main/pmc/dashboard" class="kt-subheader__breadcrumbs-home">
            <i class="flaticon2-shelter"></i>
          </a>
          <span class="kt-subheader__breadcrumbs-separator"></span>
          <ng-container *ngFor="let bread of breadcrumbs[0].breadcrumb; last as isLast">
            <a href="javascript:;" class="kt-subheader__breadcrumbs-link"  href="https://pmc.ptnw.net/main/pmc/classic/{{bread.path}}" >
              {{bread.step}}
            </a>
            <span *ngIf="!isLast" class="kt-subheader__breadcrumbs-separator"></span>
          </ng-container>
        </ng-container>
      </ng-container>
    </div>
    <div class="kt-input-icon kt-input-icon--right kt-subheader__search kt-hidden">
      <input type="text" class="form-control" placeholder="Search order..." id="generalSearch">
      <span class="kt-input-icon__icon kt-input-icon__icon--right">
        <span><i class="flaticon2-search-1"></i></span>
      </span>
    </div>
  </div>
  <div class="kt-subheader__toolbar">
    <div class="kt-subheader__wrapper">
      <!-- <a href="javascript:;" (click)="test()" class="btn kt-subheader__btn-secondary">Test</a> -->

      <ng-container *ngIf="moduleActive === 'pmc'">
        <div ngbDropdown class="d-inline-block" *ngIf="hash">
          <button class="btn btn-outline-success" ngbDropdownToggle>Task View</button>
          <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
            <a href="https://pmc.ptnw.net/main/pmc/classic/{{hash}}"  ngbDropdownItem>
              <i class="la la-th-list"></i>
              Classic View</a>
            <a href="https://pmc.ptnw.net/main/pmc/tree/{{hash}}"  ngbDropdownItem>
              <i class="la la-tree"></i>
              Tree View</a>
            <a href="https://pmc.ptnw.net/main/pmc/tabular/{{hash}}"  ngbDropdownItem>
              <i class="la la-table"></i>
              Tabular View</a>
            <a href="https://pmc.ptnw.net/main/pmc/kanban/{{hash}}" ngbDropdownItem>
              <i class="la la-clone"></i>
              Kanban View</a>
          </div>
        </div>
        <div ngbDropdown class="d-inline-block">
          <button class="btn btn-outline-primary" ngbDropdownToggle>Actions</button>
          <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
            <a *ngIf="!hash" href="https://pmc.ptnw.net/main/pmc/create" ngbDropdownItem><i class="fa fa-plus-circle font-green-jungle"></i>
              Create Task</a>
            <a *ngIf="hash" href="https://pmc.ptnw.net/main/pmc/create/{{hash}}" ngbDropdownItem><i class="fa fa-plus-circle font-green-jungle"></i>
              Create Task</a>
            <a *ngIf="hash" href="javascript:" (click)="createSimple(simpleTask, hash)" ngbDropdownItem><i class="fa fa-pen-square font-green-jungle"></i>
              Create Simple Task</a>
            <button ngbDropdownItem><i class="fa fa-warning font-red-thunderbird"></i> Create Issue</button>
            <a *ngIf="hash" href="javascript:" (click)="createSprint(sprintModal, hash)" ngbDropdownItem><i class="fa fa-pen-square font-green-jungle"></i>
              Create Sprint</a>
          </div>
        </div>
      </ng-container>

      <!-- Tombol di Subheader Selain PMC -->
      <ng-container *ngIf="breadcrumbs.length !== 0">

        <!-- Home -->
        <ng-container *ngIf="(currentModule === 'home') && isInternal">
          <ng-template [ngxPermissionsOnly]="['USER']">
            <a class="btn btn-brand btn-bold" [routerLink]="['/main/attendance/list-attendance']">
              <i class="fa fa-calendar-day mr-1"></i>Attendance
            </a>
          </ng-template>
        </ng-container>

        <!-- Simcard -->
        <ng-container *ngIf="(currentModule === 'simcard') && pageActive === 'prepaid' && subPageActive === 'summary-approve'">
          <ng-template [ngxPermissionsOnly]="['simcard/prepaid/approval-detail']">
            <button type="button" class="btn btn-md btn-success mr-2" (click)="setApproval('accept')"><i class="fa fa-thumbs-up"></i>APPROVE</button>
            <button type="button" class="btn btn-md btn-danger" (click)="setApproval('reject')"><i class="fa fa-thumbs-down"></i>REJECT</button>
          </ng-template>
        </ng-container>

        <ng-container *ngIf="(currentModule === 'simcard') && pageActive === 'prepaid' && subPageActive === 'summary-approve-all'">
          <ng-template [ngxPermissionsOnly]="['USER']">
            <button type="button" class="btn btn-md btn-success mr-2" (click)="approveAll('approveAll')"><i class="fa fa-thumbs-up"></i>APPROVE
              All</button>
          </ng-template>
        </ng-container>

        <!-- Vacancy -->
        <ng-container *ngIf="currentModule === 'vacancy' && breadcrumbs[0].label === 'Detail Vacancy'">
          <ng-template [ngxPermissionsOnly]="['vacancy/dev/add-detail-vacancy']">
            <a class="btn btn-label-brand btn-bold" [routerLink]="['/main/vacancy/detail/create-detail', breadcrumbs[0].params.id]">
              Create Detail
            </a>
          </ng-template>
        </ng-container>
        <ng-container *ngIf="currentModule === 'vacancy' && breadcrumbs[0].label === 'Applicant List'">
          <ng-template *ngIf="allowNotification else EnableNotification" [ngxPermissionsOnly]="['vacancy/recruitment/get-applicant-list']">
            <label style="color: #3598DC;" class="kt-checkbox kt-checkbox--solid kt-checkbox--brand">
              <input type="checkbox" [checked]="enableIndexedDB" (change)="enableIDBToggle()"> Enable IndexedDB
              <span style="background: #3598DC;"></span>
            </label>
            &nbsp;
            <a *ngIf="enableIndexedDB" href="javascript:;" class="btn btn-label-brand btn-bold" (click)="notifyMe()">
              Reload Data
            </a>
          </ng-template>
        </ng-container>

        <!-- Site SUrvey -->
        <ng-container *ngIf="currentModule === 'site_survey' && breadcrumbs[0].label === 'Detail Template'">
          <ng-template [ngxPermissionsOnly]="['USER']">
            <a href="" class="btn btn-label-brand btn-bold" [routerLink]="['/main/site_survey/template/template-detail/create-detail', breadcrumbs[0].params.id]">
              Create Detail
            </a>
          </ng-template>
        </ng-container>

        <!-- Employee -->
        <ng-container *ngIf="currentModule === 'employee' && breadcrumbs[0].label === 'Blacklist Employees'">
          <ng-template [ngxPermissionsOnly]="['employee/blacklist/createblacklist']">
            <a href="" class="btn btn-label-brand btn-bold" [routerLink]="['/main/employee/blacklist/create-blacklist']">
              Create
            </a>
          </ng-template>
        </ng-container>
        <ng-container *ngIf="currentModule === 'employee' && breadcrumbs[0].label === 'Employee Detail'">
          <ng-template [ngxPermissionsOnly]="['employee/hrd/detail']">
            <div class="d-inline d-md-none">
              <button class="btn btn-icon btn-secondary" (click)="enabledSidebar()">
                <i class="fa fa-bars"></i>
              </button>
            </div>
          </ng-template>
        </ng-container>
        <!-- <ng-container *ngIf="currentModule === 'employee' && breadcrumbs[0].label === 'Employee Profile'">
            <ng-template [ngxPermissionsOnly]="['USER']">
              <div class="d-inline d-md-none">
                <button class="btn btn-icon btn-secondary" (click)="enabledSidebar()">
                  <i class="fa fa-bars"></i>
                </button>
              </div>
            </ng-template>
          </ng-container> -->

        <!-- Timesheet -->
        <!-- <ng-container *ngIf="currentModule === 'timesheet' && breadcrumbs[0].label === 'My Timesheet'">
            <ng-template [ngxPermissionsOnly]="['USER']">
              <button class="btn btn-info" (click)="newTimesheet()">
                <span class="fa fa-plus mr-2"></span>New Timesheet
              </button>
            </ng-template>
          </ng-container> -->

      </ng-container>
     
    </div>
  </div>
</div>
<!-- end:: Content Head -->

<!-- Pop when notification was block -->
<ng-template #EnableNotification>
  Your notification was blocked!
  <a href="javascript:;" class="btn btn-label-warning btn-bold" (click)="open(notificationGuide)">
    Enable
  </a>
</ng-template>

<!-- Allow Notification Guide Modal -->
<ng-template #notificationGuide let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Allow Notification</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <img src="./assets/media/misc/allow_notif.png" alt="image" class="w-100" />
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-dark" (click)="modal.close()">Close</button>
  </div>
</ng-template>

<!-- Create Simple Task -->
<!-- Allow Notification Guide Modal -->
<ng-template #simpleTask let-modal>
  <!-- <div class="modal modal-backdrop"> -->
  <div class="modal-header">
    <h5 class="modal-title" id="exampleModalLabel">
      Add Simple Task
    </h5>
    <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="modal.dismiss()"></button>
  </div>
  <div class="modal-body">
    <div class="form-group row">
      <label class="col-lg-2 col-form-label">
        * Title
      </label>
      <div class="col-lg-10">
        <input type="text" #nama_item="ngModel" id="nama_item" class="form-control" name="nama_item" [(ngModel)]="task.nama_item"
          required [class.is-invalid]="nama_item.invalid && nama_item.touched">
      </div>
    </div>
    <div class="form-group row">
      <label class="col-lg-2 col-form-label">
        * PIC
      </label>
      <div class="col-lg-10">
        <tag-input name="pic" [(ngModel)]="task.pic" theme='light' [placeholder]="'PIC'" [secondaryPlaceholder]="'PIC'">
          <!-- (change)="checkingData()"> -->
          <tag-input-dropdown [autocompleteItems]="listEmployee2"></tag-input-dropdown>
        </tag-input>
      </div>
    </div>
    <div class="form-group row">
      <label class="col-lg-2 col-form-label">
        Description
      </label>
      <div class="col-lg-10">
        <textarea class="form-control" #desc_item="ngModel" id="desc_item" rows="3" name="desc_item" [(ngModel)]="task.desc_item"
          [class.is-invalid]="desc_item.invalid && desc_item.touched"></textarea>
      </div>
    </div>
    <div class="form-group row">
      <label class="col-lg-2 col-form-label">
        Tag
      </label>
      <div class="col-lg-10">
        <tag-input name="category" [(ngModel)]="task.category" theme='light' [placeholder]="'Tag'"
          [secondaryPlaceholder]="'Tag'"></tag-input>
      </div>
    </div>
  </div>
  <div class="modal-footer justify-content-between">
    <button type="button" class="btn btn-primary">
      More Detail
    </button>
    <button type="button" class="btn btn-success" (click)="saveTask(task)">
      Save
    </button>
  </div>
  <!-- </div> -->
</ng-template>

<ng-template #sprintModal let-modal>
  <!-- <div class="modal modal-backdrop"> -->
  <div class="modal-header">
    <h5 class="modal-title" id="exampleModalLabel">
      Create Sprint
    </h5>
    <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="modal.dismiss()"></button>
  </div>
  <div class="modal-body">
    <div class="form-group row">
      <label class="col-lg-2 col-form-label">
        * Title
      </label>
      <div class="col-lg-10">
        <input type="text" #sprintName="ngModel" id="sprintName" class="form-control" name="sprintName" [(ngModel)]="sprint.nama_item"
          required [class.is-invalid]="sprintName.invalid && sprintName.touched">
        <span class="text-danger" [class.d-none]="sprintName.valid || sprintName.untouched">Title is required</span>
      </div>
    </div>
    <div class="form-group row">
      <label class="col-lg-2 col-form-label">
        * Duration
      </label>
      <div class="col-lg-3">
        <div class="input-group">
          <input type="number" class="form-control" #duration="ngModel" id="duration" name="duration" [(ngModel)]="sprint.duration"
            required [class.is-invalid]="duration.invalid && duration.touched" min="1" max="8">
          <div class="input-group-append"><span class="input-group-text" id="basic-addon2">hour(s)</span></div>
        </div>
        <span class="text-danger" [class.d-none]="duration.valid || duration.untouched">Duration is required</span>
      </div>
    </div>
    <div class="form-group row">
      <label class="col-lg-2 col-form-label">
        * Category
      </label>
      <div class="col-lg-3">
        <select class="form-control" #category="ngModel" name="sprintCategory" [(ngModel)]="sprint.category" [value]="'Development'">
          <option value="Development">Development</option>
          <option value="Bug">Bug</option>
        </select>
        <span class="text-danger" [class.d-none]="category.valid || category.untouched">Category is required</span>
      </div>
    </div>
  </div>
  <div class="modal-footer justify-content-between">
    <!-- <button type="button" class="btn btn-primary">
        More Detail
      </button> -->
    <button type="button" class="btn btn-success" (click)="saveSprint(sprint)">
      Save
    </button>
  </div>
  <!-- </div> -->
</ng-template>
