import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';

@Component({
  selector: 'app-landing',
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.scss']
})
export class LandingComponent implements OnInit {
  
  partialPage: boolean = false;

  constructor(
    private router: Router,
  ) {
    this.router.events.subscribe((val) => {
      if (val instanceof NavigationEnd) {
        const arr_url = this.router.url.split('/');
        if (typeof arr_url[1] !== 'undefined') {
          if (arr_url[1] == 'login' || arr_url[1] == 'forgot-password') {
            this.partialPage = true;
          } else {
            this.partialPage = false;
          }
        } else {
          this.partialPage = false;
        }
      }
    });
  }

  ngOnInit() {}

}
