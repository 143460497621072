import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

import { CookieService } from 'ngx-cookie-service';

import { ResponseApiModel } from '../_models/response-api.model';
import { Prepaid } from '../_models/prepaid.model';
import { PrepaidDetail } from '../_models/prepaid_detail.model';

import { GlobalService } from './global.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AlertModalComponent } from '../layout/shared/alert-modal/alert-modal.component';
import Swal from 'sweetalert2';

const API_APPROVAL_DETAIL = '/simcard/prepaid/approval-detail';
const API_CHECK_ALLOWED = '/simcard/default/cek-user-approval';
const API_APPROVAL = '/simcard/prepaid/approval';
const API_APPROVE = '/simcard/prepaid/approve';
const API_EXPORT = '/simcard/prepaid/export';
const API_EXPORT_SUMMARY = '/simcard/prepaid/export-summary';
const API_RESEND_EMAIL = '/simcard/default/resend-email';
const API_SUCCESS = '/simcard/default/success';
const API_CREATE_MASTER = '/simcard/default/add-prepaid';
const API_CEK_PREPAID = '/simcard/default/cek-add-prepaid';
const API_PREPAID = '/simcard/default/get-all-prepaid';
const API_PREPAID_PIC = '/simcard/default/is-pic';
const API_GET_PIC_LIST = '/simcard/pic/get-all-pic';
const API_GET_PIC = '/simcard/pic/get-pic';
const API_GET_PIC_PCODE = '/simcard/pic/get-pic-by-project';
const API_DELETE_PIC = '/simcard/pic/delete-pic';
const API_ADD_PIC = '/simcard/pic/add-pic';
const API_IMPORT_PIC = '/simcard/pic/import-pic';
const API_ADD_PIC_DATA = '/simcard/pic/getcreatedata';
const API_PREPAID_DETAIL = '/simcard/default/prepaid-detail';
const API_USER_PHONE_NUMBER = '/simcard/default/get-users-phone';
const API_GET_MY_APPROVAL = '/simcard/default/get-my-approval';
const API_TYPEHEAD = '/simcard/default/get-typehead-data';
const API_TYPEHEAD_ENGINEER = '/simcard/default/get-typehead-data-engineer';
const API_START_APPROVAL = '/simcard/default/start-approval';
const API_CREATE_DETAIL = '/simcard/default/add-detail';
const API_UPDATE_MASTER = '/simcard/prepaid/update-master';
const API_UPDATE_DETAIL = '/simcard/default/update-detail';
const API_DELETE_DETAIL = '/simcard/default/delete-detail';
const API_USER = '/simcard/default/get-user';
const API_USER_BY_ADMIN = '/simcard/default/get-user-by-admin';
const API_CODE = '/simcard/default/get-code';
const API_CODE_BY_ADMIN = '/simcard/default/get-code-by-admin';
const API_IMPORT_DETAIL = '/simcard/default/import-detail';
const API_DELETE = '/simcard/prepaid/delete';
const API_CREATE_PARAMS = '/simcard/phonenumber/create-params';
const API_ADD_PHONE_NUMBER = '/simcard/phonenumber/add-phone-number';
const API_LIST_PHONE_NUMBER = '/simcard/phonenumber/get-list-phone';
const API_EDIT_PHONE_NUMBER = '/simcard/phonenumber/edit-phone-number';
const API_DELETE_PHONE_NUMBER = '/simcard/phonenumber/delete-phone-number';
const API_ADD_PHONE_CONFIG = '/simcard/maxphonenumber/add-max-phone';
const API_LIST_PHONE_CONFIG = '/simcard/maxphonenumber/get-max-phone';
const API_EDIT_PHONE_CONFIG = '/simcard/maxphonenumber/edit-max-phone';
const API_DELETE_PHONE_CONFIG = '/simcard/maxphonenumber/delete-max-phone';
const API_GET_PHONE_APPROVAL = '/simcard/phonenumber/get-phone-approval';
const API_LIST_APPROVAL_DETAIL = '/simcard/phonenumber/approval-detail';
const API_CONFIRM_APPROVAL_DETAIL = '/simcard/phonenumber/confirm-checkbox';
const API_GET_DATA_SUMMARY = '/simcard/prepaid/data-summary';
const API_APPROVAL_ALL = '/simcard/prepaid/approval-all';
const API_GET_OPERATOR = '/simcard/default/get-operator';
const API_GET_AREA = '/simcard/default/get-area';
const API_MY_NUMBER_REQUEST = '/simcard/default/my-number-request';
const API_LIST_PROJECT_CODE = '/simcard/default/list-project-code';
const API_RESTRICT_REQUEST = '/simcard/prepaid-admin/check-restrict';
const API_PREPARE_REQUEST = '/simcard/prepaid-admin/prepare-request';
const API_ADD_REQUEST = '/simcard/prepaid-admin/add-request';
const API_GET_REQUEST_DETAIL = '/simcard/prepaid-admin/get-request-detail';
const API_PREPARE_REQUEST_DETAIL = '/simcard/prepaid-admin/prepare-request-detail';
const API_ADD_REQUEST_DETAIL = '/simcard/prepaid-admin/add-request-detail';
const API_DELETE_REQUEST_DETAIL = '/simcard/prepaid-admin/delete-request-detail';
const API_START_APPROVAL_ADMIN = '/simcard/prepaid-admin/start-approval';
const API_FINISHED_REQUEST = '/simcard/prepaid/finished-request';
const API_EXPORT_EXCEL_FINISHED_REQUEST = '/simcard/prepaid/export-excel-finished-request';
const API_EXPORT_PDF_FINISHED_REQUEST = '/simcard/prepaid/export-pdf-finished-request';
const API_GET_ACTIVE_USERS = '/simcard/default/get-active-users';
const API_REMINDER_PULSA = '/simcard/dev/topup-reminder';
const API_PULSA_REMINDER_ONE = '/simcard/dev/send-reminder';
const API_PULSA_REMINDER_ALL = '/simcard/dev/send-reminder-all';
const API_RETOPUP = '/simcard/prepaid-admin/re-topup';
const API_RETOPUP_RESEND_EMAIL = '/simcard/prepaid-admin/resend-email';
const API_ALL_REQUEST = '/simcard/prepaid/all-request';
const API_PULSA_PAYMENT_TRACKER = '/simcard/pulsa-payment/tracker';
const API_PULSA_PAYMENT_TRACKER_DETAIL = '/simcard/pulsa-payment/tracker-detail';
const API_PULSA_PAYMENT_TRACKER_PROGRESS = '/simcard/pulsa-payment/tracker-progress';
const API_PULSA_PAYMENT_SUBMIT_RECEIPT = '/simcard/pulsa-payment/submit-receipt';
const API_PULSA_PAYMENT_SUBMIT_TRACKER = '/simcard/pulsa-payment/submit-tracker';
const API_PULSA_PAYMENT_TRACKER_APPROVAL = '/simcard/pulsa-payment/tracker-approval';
const API_PULSA_PAYMENT_SUBMIT_TRACKER_APPROVAL = '/simcard/pulsa-payment/submit-tracker-approval';
const API_PULSA_PAYMENT_EXPORT_PDF = '/simcard/pulsa-payment/export-pdf';
const API_PULSA_PAYMENT_GENERATE_PULSA_REPORT = '/simcard/pulsa-payment/generate-pulsa-report';
const API_PROCESS_UPLOAD = '/simcard/pulsa-payment/upload-attachment';
const API_GET_ALL_POSTPAID = '/simcard/postpaid/get-all-postpaid';
const API_GET_ALL_POSTPAID_MY_APPROVAL = '/simcard/postpaid/my-approval';
const API_IMPORT_POSTPAID = '/simcard/postpaid/import-postpaid';
const API_GET_POSTPAID_PROGRESS = '/simcard/postpaid/progress';
const API_GET_OPERATOR_LIST= '/simcard/postpaid/get-operator';
const API_GET_POSTPAID_DETAIL = '/simcard/postpaid/detail';
const API_SUBMIT_POSTPAID_APPROVAL = '/simcard/postpaid/submit-postpaid-approval';
const API_GENERATE_POSTPAID_REPORT = '/simcard/postpaid/generate-postpaid-report';
const API_POSTPAID_PAYMENT_EXPORT_PDF = '/simcard/postpaid/export-pdf';
const API_POSTPAID_PAYMENT_EXPORT_EXCEL = '/simcard/postpaid/generate-postpaid-report';
const API_GET_CURRENT_REQUEST = '/simcard/postpaid/get-current-request';
const API_EDIT_REQUEST = '/simcard/postpaid/edit-postpaid';

@Injectable({
  providedIn: 'root'
})
export class SimcardService {

  constructor(
    private http: HttpClient,
    private cookieService: CookieService,
    private globalService: GlobalService,
    private modalService: NgbModal,
  ) { }

  alertModal(err: string, code?: number) {
    if (code === 403) {
      return Swal.fire(err);
    }
    const modalRef = this.modalService.open(AlertModalComponent);
    modalRef.componentInstance.kategori = 'alert';
    modalRef.componentInstance.error = (err) ? err : 'Request Timeout.';
  }

  public header() {
    return {
      headers: new HttpHeaders()
        .set('token', this.cookieService.get('_q'))
        .set('module', localStorage.getItem('simcard'))
        .set('group', this.cookieService.get('_g'))
    };
  }

  // START OF APPROVAL
  public getApprovalDetail(hash: string): Observable<ResponseApiModel> {
    return this.http.post<ResponseApiModel>(
      this.globalService.apiVersionHost + API_APPROVAL_DETAIL, { hash }, this.header()
    ).pipe(
      map(response => response),
      catchError(err => {
        return throwError(err);
      })
    );
  }

  public phoneApprovalDetail(userid): Observable<ResponseApiModel> {
    return this.http.post<ResponseApiModel>(
      this.globalService.apiVersionHost + API_LIST_APPROVAL_DETAIL, { userid }, this.header()
    ).pipe(
      map(response => response),
      catchError(err => {
        return throwError(err);
      })
    );
  }

  public confirmCheckbox(param: { action: string, listPhone: any, userid: any }): Observable<ResponseApiModel> {
    return this.http.post<ResponseApiModel>(
      this.globalService.apiVersionHost + API_CONFIRM_APPROVAL_DETAIL, param, this.header()
    ).pipe(
      map(response => response)
    );
  }

  public actionCheckAllowed(hash: string): Observable<ResponseApiModel> {
    return this.http.post<ResponseApiModel>(
      this.globalService.apiVersionHost + API_CHECK_ALLOWED, { hash }, this.header()
    ).pipe(
      map(response => response),
      catchError(err => {
        return throwError(err);
      })
    );
  }
  public actionApproval(param: { hash: string, action: string }): Observable<ResponseApiModel> {
    return this.http.post<ResponseApiModel>(
      this.globalService.apiVersionHost + API_APPROVAL, param, this.header()
    ).pipe(
      map(response => response),
      catchError(err => {
        return throwError(err);
      })
    );
  }
  public actionApprove(params): Observable<ResponseApiModel> {
    return this.http.post<ResponseApiModel>(
      this.globalService.apiVersionHost + API_APPROVE, params, this.header()
    ).pipe(
      map(response => response),
      catchError(err => {
        return throwError(err);
      })
    );
  }
  public exporTopup(param: { from: string, to: string }): Observable<ResponseApiModel> {
    return this.http.post<ResponseApiModel>(
      this.globalService.apiVersionHost + API_EXPORT, param, this.header()
    ).pipe(
      map(response => response),
      catchError(err => {
        return throwError(err);
      })
    );
  }
  public exporTopupSummary(param: { from: string, to: string }): Observable<ResponseApiModel> {
    return this.http.post<ResponseApiModel>(
      this.globalService.apiVersionHost + API_EXPORT_SUMMARY, param, this.header()
    ).pipe(
      map(response => response),
      catchError(err => {
        return throwError(err);
      })
    );
  }
  public actionSendEmail(hash: string): Observable<ResponseApiModel> {
    return this.http.post<ResponseApiModel>(
      this.globalService.apiVersionHost + API_RESEND_EMAIL, { hash }, this.header()
    ).pipe(
      map(response => response),
      catchError(err => {
        return throwError(err);
      })
    );
  }

  public actionSuccess(param: { id: any, id_master: number }): Observable<ResponseApiModel> {
    return this.http.post<ResponseApiModel>(
      this.globalService.apiVersionHost + API_SUCCESS, param, this.header()
    ).pipe(
      map(response => response),
      catchError(err => {
        return throwError(err);
      })
    );
  }
  // END OF APPROVAL

  public addPrepaid(param: any): Observable<ResponseApiModel> {
    return this.http.post<ResponseApiModel>(
      this.globalService.apiVersionHost + API_CREATE_MASTER, param, this.header()
    ).pipe(
      map(response => response),
      catchError(err => {
        return throwError(err);
      })
    );
  }

  public cekAddPrepaid(): Observable<ResponseApiModel> {
    return this.http.get<ResponseApiModel>(
      this.globalService.apiVersionHost + API_CEK_PREPAID, this.header()
    ).pipe(
      map(response => response),
      catchError(err => {
        return throwError(err);
      })
    );
  }

  public getPrepaid(param: { pageSize: number, page: number, filter: any }): Observable<ResponseApiModel> {
    return this.http.post<ResponseApiModel>(
      this.globalService.apiVersionHost + API_PREPAID, param, this.header()
    ).pipe(
      map(response => response),
      catchError(err => {
        return throwError(err);
      })
    );
  }

  public getPostpaid(param: { pageSize: number, page: number, filter: any }): Observable<ResponseApiModel> {
    return this.http.post<ResponseApiModel>(
      this.globalService.apiVersionHost + API_GET_ALL_POSTPAID, param, this.header()
    ).pipe(
      map(response => response),
      catchError(err => {
        return throwError(err);
      })
    );
  }

  public getPostpaidMyApproval(param: { pageSize: number, page: number, filter: any }): Observable<ResponseApiModel> {
    return this.http.post<ResponseApiModel>(
      this.globalService.apiVersionHost + API_GET_ALL_POSTPAID_MY_APPROVAL, param, this.header()
    ).pipe(
      map(response => response),
      catchError(err => {
        return throwError(err);
      })
    );
  }

  public isPic(): Observable<ResponseApiModel> {
    return this.http.get<ResponseApiModel>(
      this.globalService.apiVersionHost + API_PREPAID_PIC, this.header()
    ).pipe(
      map(response => response),
      catchError(err => {
        return throwError(err);
      })
    );
  }

  public getAllPic(param: { pageSize: number, page: number, filter: any }): Observable<ResponseApiModel> {
    return this.http.post<ResponseApiModel>(
      this.globalService.apiVersionHost + API_GET_PIC_LIST, param, this.header()
    ).pipe(
      map(response => response),
      catchError(err => {
        return throwError(err);
      })
    );
  }

  public getPic(param: { id: number }): Observable<ResponseApiModel> {
    return this.http.post<ResponseApiModel>(
      this.globalService.apiVersionHost + API_GET_PIC, param, this.header()
    ).pipe(
      map(response => response),
      catchError(err => {
        return throwError(err);
      })
    );
  }

  public getPicByProject(param: { pCode: string }): Observable<ResponseApiModel> {
    return this.http.post<ResponseApiModel>(
      this.globalService.apiVersionHost + API_GET_PIC_PCODE, param, this.header()
    ).pipe(
      map(response => response),
      catchError(err => {
        return throwError(err);
      })
    );
  }

  public getDeletePic(param: { id: number }): Observable<ResponseApiModel> {
    return this.http.post<ResponseApiModel>(
      this.globalService.apiVersionHost + API_DELETE_PIC, param, this.header()
    ).pipe(
      map(response => response),
      catchError(err => {
        return throwError(err);
      })
    );
  }

  public importPic(param: { data: any }): Observable<ResponseApiModel> {
    return this.http.post<ResponseApiModel>(
      this.globalService.apiVersionHost + API_IMPORT_PIC, param, this.header()
    ).pipe(
      map(response => response),
      catchError(err => {
        return throwError(err);
      })
    );
  }

  public addPic(param: { project_code: string, userid: number }): Observable<ResponseApiModel> {
    return this.http.post<ResponseApiModel>(
      this.globalService.apiVersionHost + API_ADD_PIC, param, this.header()
    ).pipe(
      map(response => response),
      catchError(err => {
        return throwError(err);
      })
    );
  }

  public getAddPicData(): Observable<ResponseApiModel> {
    return this.http.get<ResponseApiModel>(
      this.globalService.apiVersionHost + API_ADD_PIC_DATA, this.header()
    ).pipe(
      map(response => response),
      catchError(err => {
        return throwError(err);
      })
    );
  }

  public getMyapproval(param: { pageSize: number, page: number, filter: any }): Observable<ResponseApiModel> {
    return this.http.post<ResponseApiModel>(
      this.globalService.apiVersionHost + API_GET_MY_APPROVAL, param, this.header()
    ).pipe(
      map(response => response),
      catchError(err => {
        return throwError(err);
      })
    );
  }

  public getPhoneapproval(param: { pageSize: number, page: number, filter: any }): Observable<ResponseApiModel> {
    return this.http.post<ResponseApiModel>(
      this.globalService.apiVersionHost + API_GET_PHONE_APPROVAL, param, this.header()
    ).pipe(
      map(response => response),
      catchError(err => {
        return throwError(err);
      })
    );
  }

  public getPrepaidDetail(id): Observable<ResponseApiModel> {
    return this.http.post<ResponseApiModel>(
      this.globalService.apiVersionHost + API_PREPAID_DETAIL, { id }, this.header()
    ).pipe(
      map(response => response),
      catchError(err => {
        return throwError(err);
      })
    );
  }

  public getUsersPhoneNumber(params): Observable<ResponseApiModel> {
    return this.http.post<ResponseApiModel>(
      this.globalService.apiVersionHost + API_USER_PHONE_NUMBER, params, this.header()
    ).pipe(
      map(response => response),
      catchError(err => {
        return throwError(err);
      })
    );
  }

  public getTypeheadDataEngineer(): Observable<ResponseApiModel> {
    return this.http.get<ResponseApiModel>(
      this.globalService.apiVersionHost + API_TYPEHEAD_ENGINEER, this.header()
    ).pipe(
      map(response => response)
    );
  }

  public getTypeheadData(): Observable<ResponseApiModel> {
    return this.http.get<ResponseApiModel>(
      this.globalService.apiVersionHost + API_TYPEHEAD, this.header()
    ).pipe(
      map(response => response)
    );
  }

  // ------ service for PIC Pulsa-------------------------------------------------------------

  public checkRestrict(): Observable<ResponseApiModel> {
    return this.http.get<ResponseApiModel>(
      this.globalService.apiVersionHost + API_RESTRICT_REQUEST, this.header()
    ).pipe(
      map(response => response),
      catchError(err => {
        return throwError(err);
      })
    );
  }

  public prepareRequest(): Observable<ResponseApiModel> {
    return this.http.get<ResponseApiModel>(
      this.globalService.apiVersionHost + API_PREPARE_REQUEST, this.header()
    ).pipe(
      map(response => response),
      catchError(err => {
        return throwError(err);
      })
    );
  }

  public addRequest(param: any): Observable<ResponseApiModel> {
    return this.http.post<ResponseApiModel>(
      this.globalService.apiVersionHost + API_ADD_REQUEST, param, this.header()
    ).pipe(
      map(response => response),
      catchError(err => {
        return throwError(err);
      })
    );
  }

  public getRequestDetail(param: { hash: string }): Observable<ResponseApiModel> {
    return this.http.post<ResponseApiModel>(
      this.globalService.apiVersionHost + API_GET_REQUEST_DETAIL, param, this.header()
    ).pipe(
      map(response => response),
      catchError(err => {
        return throwError(err);
      })
    );
  }

  public prepareRequestDetail(param: { hash: string }): Observable<ResponseApiModel> {
    return this.http.post<ResponseApiModel>(
      this.globalService.apiVersionHost + API_PREPARE_REQUEST_DETAIL, param, this.header()
    ).pipe(
      map(response => response),
      catchError(err => {
        return throwError(err);
      })
    );
  }

  public addRequestDetail(param: any): Observable<ResponseApiModel> {
    return this.http.post<ResponseApiModel>(
      this.globalService.apiVersionHost + API_ADD_REQUEST_DETAIL, param, this.header()
    ).pipe(
      map(response => response),
      catchError(err => {
        return throwError(err);
      })
    );
  }

  public deteleRequestDetail(param: any): Observable<ResponseApiModel> {
    return this.http.post<ResponseApiModel>(
      this.globalService.apiVersionHost + API_DELETE_REQUEST_DETAIL, param, this.header()
    ).pipe(
      map(response => response),
      catchError(err => {
        return throwError(err);
      })
    );
  }

  public startApprovalAdmin(param: { hash: string }): Observable<ResponseApiModel> {
    return this.http.post<ResponseApiModel>(
      this.globalService.apiVersionHost + API_START_APPROVAL_ADMIN, param, this.header()
    ).pipe(
      map(response => response),
      catchError(err => {
        return throwError(err);
      })
    );
  }

  // -----------------------------------------------------------------------------------

  public getCreateDetail(hash): Observable<ResponseApiModel> {
    return this.http.get<ResponseApiModel>(
      this.globalService.apiVersionHost + '/simcard/default/create-detail?hash=' + hash, this.header()
    );
  }

  public startApproval(hash: string): Observable<ResponseApiModel> {
    return this.http.post<ResponseApiModel>(
      this.globalService.apiVersionHost + API_START_APPROVAL, { hash }, this.header()
    ).pipe(
      map(response => response)
    );
  }

  public addDetail(param: { detail: PrepaidDetail, hash_master: string, is_admin?: boolean }): Observable<ResponseApiModel> {
    return this.http.post<ResponseApiModel>(
      this.globalService.apiVersionHost + API_CREATE_DETAIL, param, this.header()
    ).pipe(
      map(response => response),
      catchError(err => {
        return throwError(err);
      })
    );
  }

  public updateMaster(param: { prepaid: Prepaid, hash: string }): Observable<ResponseApiModel> {
    return this.http.post<ResponseApiModel>(
      this.globalService.apiVersionHost + API_UPDATE_MASTER, param, this.header()
    ).pipe(
      map(response => response),
      catchError(err => {
        return throwError(err);
      })
    );
  }

  public updateDetail(param: { detail: PrepaidDetail, hash_master: string, is_admin?: boolean }): Observable<ResponseApiModel> {
    return this.http.post<ResponseApiModel>(
      this.globalService.apiVersionHost + API_UPDATE_DETAIL, param, this.header()
    ).pipe(
      map(response => response),
      catchError(err => {
        return throwError(err);
      })
    );
  }

  public deleteDetail(id): Observable<ResponseApiModel> {
    return this.http.post<ResponseApiModel>(
      this.globalService.apiVersionHost + API_DELETE_DETAIL, id, this.header()
    ).pipe(
      map(response => response)
    );
  }

  public getUser(id?: string): Observable<ResponseApiModel> {
    return this.http.get<ResponseApiModel>(
      this.globalService.apiVersionHost + API_USER + '?id=' + id, this.header()
    ).pipe(
      map(response => response)
    );
  }

  public getUserByAdmin(param: { hash: string }): Observable<ResponseApiModel> {
    return this.http.post<ResponseApiModel>(
      this.globalService.apiVersionHost + API_USER_BY_ADMIN, param, this.header()
    ).pipe(
      map(response => response)
    );
  }

  public getCode(params): Observable<ResponseApiModel> {
    return this.http.post<ResponseApiModel>(
      this.globalService.apiVersionHost + API_CODE, params, this.header()
    ).pipe(
      map(response => response)
    );
  }

  public getCodeByAdmin(params): Observable<ResponseApiModel> {
    return this.http.post<ResponseApiModel>(
      this.globalService.apiVersionHost + API_CODE_BY_ADMIN, params, this.header()
    ).pipe(
      map(response => response)
    );
  }

  public getOperator(params): Observable<ResponseApiModel> {
    return this.http.post<ResponseApiModel>(
      this.globalService.apiVersionHost + API_GET_OPERATOR, params, this.header()
    ).pipe(
      map(response => response)
    );
  }

  public getArea(params): Observable<ResponseApiModel> {
    return this.http.post<ResponseApiModel>(
      this.globalService.apiVersionHost + API_GET_AREA, params, this.header()
    ).pipe(
      map(response => response)
    );
  }

  public getFinish(param: { pageSize: number, page: number, filter: any }): Observable<ResponseApiModel> {
    return this.http.post<ResponseApiModel>(
      this.globalService.apiVersionHost + '/simcard/default/finish', param, this.header()
    ).pipe(
      map(response => response),
      catchError(err => {
        return throwError(err);
      })
    );
  }

  public importDetail(params): Observable<ResponseApiModel> {
    return this.http
      .post<ResponseApiModel>(
        this.globalService.apiVersionHost + API_IMPORT_DETAIL,
        params,
        this.header()
      )
      .pipe(map(response => response));
  }

  public delete(param: any): Observable<ResponseApiModel> {
    return this.http.post<ResponseApiModel>(
      this.globalService.apiVersionHost + API_DELETE, param, this.header()
    ).pipe(
      map(response => response)
    );
  }

  // Begin Phone Number
  public createParams(): Observable<ResponseApiModel> {
    return this.http.get<ResponseApiModel>(
      this.globalService.apiVersionHost + API_CREATE_PARAMS, this.header()
    ).pipe(
      map(response => response)
    );
  }
  public addPhoneNumber(param): Observable<ResponseApiModel> {
    return this.http.post<ResponseApiModel>(
      this.globalService.apiVersionHost + API_ADD_PHONE_NUMBER, param, this.header()
    ).pipe(
      map(response => response)
    );
  }
  public listPhoneNumber(param): Observable<ResponseApiModel> {
    return this.http.post<ResponseApiModel>(
      this.globalService.apiVersionHost + API_LIST_PHONE_NUMBER, param, this.header()
    ).pipe(
      map(response => response)
    );
  }
  public editPhoneNumber(param): Observable<ResponseApiModel> {
    return this.http.post<ResponseApiModel>(
      this.globalService.apiVersionHost + API_EDIT_PHONE_NUMBER, param, this.header()
    ).pipe(
      map(response => response)
    );
  }
  public deletePhoneNumber(param): Observable<ResponseApiModel> {
    return this.http.post<ResponseApiModel>(
      this.globalService.apiVersionHost + API_DELETE_PHONE_NUMBER, param, this.header()
    ).pipe(
      map(response => response)
    );
  }
  public addPhoneConfig(param): Observable<ResponseApiModel> {
    return this.http.post<ResponseApiModel>(
      this.globalService.apiVersionHost + API_ADD_PHONE_CONFIG, param, this.header()
    ).pipe(
      map(response => response)
    );
  }
  public listPhoneConfig(param): Observable<ResponseApiModel> {
    return this.http.post<ResponseApiModel>(
      this.globalService.apiVersionHost + API_LIST_PHONE_CONFIG, param, this.header()
    ).pipe(
      map(response => response)
    );
  }
  public editPhoneConfig(param): Observable<ResponseApiModel> {
    return this.http.post<ResponseApiModel>(
      this.globalService.apiVersionHost + API_EDIT_PHONE_CONFIG, param, this.header()
    ).pipe(
      map(response => response)
    );
  }
  public deletePhoneConfig(param): Observable<ResponseApiModel> {
    return this.http.post<ResponseApiModel>(
      this.globalService.apiVersionHost + API_DELETE_PHONE_CONFIG, param, this.header()
    ).pipe(
      map(response => response)
    );
  }

  public approveAll(param: { action: string, listIdMaster: any }): Observable<ResponseApiModel> {
    return this.http.post<ResponseApiModel>(
      this.globalService.apiVersionHost + API_APPROVAL_ALL, param, this.header()
    ).pipe(
      map(response => response)
    );
  }

  public getDataSummary(param: { listIdMaster: any }): Observable<ResponseApiModel> {
    return this.http.post<ResponseApiModel>(
      this.globalService.apiVersionHost + API_GET_DATA_SUMMARY, param, this.header()
    ).pipe(
      map(response => response)
    );
  }

  public myNumberRequest(param: { pageSize: number, page: number, filter: any }): Observable<ResponseApiModel> {
    return this.http.post<ResponseApiModel>(
      this.globalService.apiVersionHost + API_MY_NUMBER_REQUEST, param, this.header()
    ).pipe(
      map(response => response),
      catchError(err => {
        return throwError(err);
      })
    );
  }

  public listProjectCode(param: { pageSize: number, page: number, filter: any }): Observable<ResponseApiModel> {
    return this.http.post<ResponseApiModel>(
      this.globalService.apiVersionHost + API_LIST_PROJECT_CODE, param, this.header()
    ).pipe(
      map(response => response),
      catchError(err => {
        return throwError(err);
      })
    );
  }

  public getFinishedRequest(param: { pageSize: number, page: number, filter: any }): Observable<ResponseApiModel> {
    return this.http.post<ResponseApiModel>(
      this.globalService.apiVersionHost + API_FINISHED_REQUEST, param, this.header()
    ).pipe(
      map(response => response)
    );
  }

  public exportExcelFinishedRequest(param: { filter: any, page: number }): Observable<ResponseApiModel> {
    return this.http.post<ResponseApiModel>(
      this.globalService.apiVersionHost + API_EXPORT_EXCEL_FINISHED_REQUEST, param, this.header()
    ).pipe(
      map(response => response)
    );
  }

  public exportPdfFinishedRequest(param: { filter: any, trxPerPage: number }): Observable<ResponseApiModel> {
    return this.http.post<ResponseApiModel>(
      this.globalService.apiVersionHost + API_EXPORT_PDF_FINISHED_REQUEST, param, this.header()
    ).pipe(
      map(response => response)
    );
  }

  // BEGIN TOPUP REMINDER
  public getActiveUsers(): Observable<ResponseApiModel> {
    return this.http.post<ResponseApiModel>(
      this.globalService.apiVersionHost + API_GET_ACTIVE_USERS, {}, this.header()
    ).pipe(
      map(response => response)
    );
  }
  public getReminderTopup(param: { pageSize: number, page: number, filter: any }): Observable<ResponseApiModel> {
    return this.http.post<ResponseApiModel>(
      this.globalService.apiVersionHost + API_REMINDER_PULSA, param, this.header()
    ).pipe(
      map(response => response)
    );
  }
  public remindById(params): Observable<ResponseApiModel> {
    return this.http.post<ResponseApiModel>(
      this.globalService.apiVersionHost + API_PULSA_REMINDER_ONE, params, this.header()
    ).pipe(
      map(response => response)
    );
  }
  public remindAll(params): Observable<ResponseApiModel> {
    return this.http.post<ResponseApiModel>(
      this.globalService.apiVersionHost + API_PULSA_REMINDER_ALL, params, this.header()
    ).pipe(
      map(response => response)
    );
  }
  // END TOPUP REMINDER

  public retopup(params: { idDetail: number, passcode?: string, attachmentReTopup?: number }): Observable<ResponseApiModel> {
    return this.http.post<ResponseApiModel>(
      this.globalService.apiVersionHost + API_RETOPUP, params, this.header()
    ).pipe(
      map(response => response)
    );
  }

  public retopupResendEmail(params: { idMaster: number }): Observable<ResponseApiModel> {
    return this.http.post<ResponseApiModel>(
      this.globalService.apiVersionHost + API_RETOPUP_RESEND_EMAIL, params, this.header()
    ).pipe(
      map(response => response)
    );
  }

  public getAllRequest(param: { pageSize: number, page: number, filter: any }): Observable<ResponseApiModel> {
    return this.http.post<ResponseApiModel>(
      this.globalService.apiVersionHost + API_ALL_REQUEST, param, this.header()
    ).pipe(
      map(response => response)
    );
  }

  public getPulsaPaymentTracker(param: { pageSize: number, page: number, filter: any }): Observable<ResponseApiModel> {
    return this.http.post<ResponseApiModel>(
      this.globalService.apiVersionHost + API_PULSA_PAYMENT_TRACKER, param, this.header()
    ).pipe(
      map(response => response)
    );
  }

  public getPulsaPaymentTrackerDetail(param: { id: number }): Observable<ResponseApiModel> {
    return this.http.post<ResponseApiModel>(
      this.globalService.apiVersionHost + API_PULSA_PAYMENT_TRACKER_DETAIL, param, this.header()
    ).pipe(
      map(response => response)
    );
  }

  public getPulsaPaymentTrackerProgress(param: { id: number }): Observable<ResponseApiModel> {
    return this.http.post<ResponseApiModel>(
      this.globalService.apiVersionHost + API_PULSA_PAYMENT_TRACKER_PROGRESS, param, this.header()
    ).pipe(
      map(response => response)
    );
  }

  public pulsaPaymentSubmitReceipt(param: any): Observable<ResponseApiModel> {
    return this.http.post<ResponseApiModel>(
      this.globalService.apiVersionHost + API_PULSA_PAYMENT_SUBMIT_RECEIPT, param, this.header()
    ).pipe(
      map(response => response)
    );
  }

  public pulsaPaymentSubmitTracker(param: any): Observable<ResponseApiModel> {
    return this.http.post<ResponseApiModel>(
      this.globalService.apiVersionHost + API_PULSA_PAYMENT_SUBMIT_TRACKER, param, this.header()
    ).pipe(
      map(response => response)
    );
  }

  public getPulsaPaymentTrackerApproval(param: { pageSize: number, page: number, filter: any }): Observable<ResponseApiModel> {
    return this.http.post<ResponseApiModel>(
      this.globalService.apiVersionHost + API_PULSA_PAYMENT_TRACKER_APPROVAL, param, this.header()
    ).pipe(
      map(response => response)
    );
  }

  public pulsaPaymentSubmitApproval(param: any): Observable<ResponseApiModel> {
    return this.http.post<ResponseApiModel>(
      this.globalService.apiVersionHost + API_PULSA_PAYMENT_SUBMIT_TRACKER_APPROVAL, param, this.header()
    ).pipe(
      map(response => response)
    );
  }

  public pulsaPaymentExportPdf(param: { id: number }): Observable<ResponseApiModel> {
    return this.http.post<ResponseApiModel>(
      this.globalService.apiVersionHost + API_PULSA_PAYMENT_EXPORT_PDF, param, this.header()
    ).pipe(
      map(response => response)
    );
  }

  public postpaidPaymentExportPdf(param: { id: number }): Observable<ResponseApiModel> {
    return this.http.post<ResponseApiModel>(
      this.globalService.apiVersionHost + API_POSTPAID_PAYMENT_EXPORT_PDF, param, this.header()
    ).pipe(
      map(response => response)
    );
  }

  public postpaidPaymentExportExcel(param: { id: number }): Observable<ResponseApiModel> {
    return this.http.post<ResponseApiModel>(
      this.globalService.apiVersionHost + API_POSTPAID_PAYMENT_EXPORT_EXCEL, param, this.header()
    ).pipe(
      map(response => response)
    );
  }

  public pulsaPaymentGeneratePulsaReport(param: { id: number }): Observable<ResponseApiModel> {
    return this.http.post<ResponseApiModel>(
      this.globalService.apiVersionHost + API_PULSA_PAYMENT_GENERATE_PULSA_REPORT, param, this.header()
    ).pipe(
      map(response => response)
    );
  }

  uploadFile(param:{id_attachment:number,id:number,column:string}): Observable<ResponseApiModel> {
    return this.http.post<ResponseApiModel>(
      this.globalService.apiVersionHost + API_PROCESS_UPLOAD, param, this.header()
    ).pipe(
      map(response => response)
    );
  }

  public importPostpaid(params: any): Observable<ResponseApiModel> {
    return this.http.post<ResponseApiModel>(
      this.globalService.apiVersionHost + API_IMPORT_POSTPAID, params, this.header()
    ).pipe(
      map(response => response)
    );
  }

  public getPostpaidProgress(param: { id: number }): Observable<ResponseApiModel> {
    return this.http.post<ResponseApiModel>(
      this.globalService.apiVersionHost + API_GET_POSTPAID_PROGRESS, param, this.header()
    ).pipe(
      map(response => response)
    );
  }
  public getOperatorList(param: any=null): Observable<ResponseApiModel> {
    return this.http.post<ResponseApiModel>(
      this.globalService.apiVersionHost + API_GET_OPERATOR_LIST, param, this.header()
    ).pipe(
      map(response => response)
    );
  }

  public getPostpaidDetail(param: any): Observable<ResponseApiModel> {
    return this.http.post<ResponseApiModel>(
      this.globalService.apiVersionHost + API_GET_POSTPAID_DETAIL, param, this.header()
    ).pipe(
      map(response => response)
    );
  }

  public submitPostpaidApproval(param: any): Observable<ResponseApiModel> {
    return this.http.post<ResponseApiModel>(
      this.globalService.apiVersionHost + API_SUBMIT_POSTPAID_APPROVAL, param, this.header()
    ).pipe(
      map(response => response)
    );
  }

  public generatePostpaidReport(param: any): Observable<ResponseApiModel> {
    return this.http.post<ResponseApiModel>(
      this.globalService.apiVersionHost + API_GENERATE_POSTPAID_REPORT, param, this.header()
    ).pipe(
      map(response => response)
    );
  }

  public getCurrentRequest(param): Observable<ResponseApiModel> {
    return this.http.post<ResponseApiModel>(
      this.globalService.apiVersionHost + API_GET_CURRENT_REQUEST, param, this.header()
    ).pipe(
      map(response => response)
    );
  }

  public edit(param): Observable<ResponseApiModel> {
    return this.http.post<ResponseApiModel>(
      this.globalService.apiVersionHost + API_EDIT_REQUEST, param, this.header()
    ).pipe(
      map(response => response)
    );
  }
}
