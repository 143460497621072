<ng-template #rt let-r="result" let-t="term">
  <ngb-highlight [result]="r.val" [term]="t"></ngb-highlight>
</ng-template>

<input
  id="{{id}}"
  type="text" 
  class="{{classes}}"
  [placeholder]="(placeholder !== undefined && placeholder !== null) ? placeholder : ''"
  [(ngModel)]="selectedItem" 
  [ngbTypeahead]="search"
  [resultTemplate]="rt" 
  [inputFormatter]="formatter" 
  (focus)="focus$.next($event.target.value)"
  (click)="click$.next($event.target.value)" 
  (dblclick)="dblclick$.next($event.target.value)" 
  #instance="ngbTypeahead"
  (selectItem)="getSelected($event)"
  (change)="getIputChange($event.target.value)"
  (keyup)="getValueChange($event.target.value)"
  (keyup.enter)="getEnterChange($event.target.value)"
  (keydown.esc)="resetChange()"
  (focus)="onFocus()"
  (blur)="onBlur()"
  [disabled]="isDisabled"
  [readonly]="isReadonly"
  [ngStyle]="{'width': widthInput? widthInput: '100%'}"
  />