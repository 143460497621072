<div id="content" class="bg-white">
  <div class="bg-white pb-5 px-0 px-lg-4">
    <div class="container-fluid">
      <ng-container *ngIf="populars else skeletonHeader">
        <div class="row headlinewrap no-gutters">
          <div class="col-lg-8 col-md-12">
            <div class="headline">
              <a class="headline-img" [routerLink]="['/blog-post', populars[0].slug]">
                <img [src]="populars[0].picture" onerror="src='/assets/media/error/noimage.png'" alt="" class="img-fluid">
              </a>
              <div class="headline-body">
                <div class="mb-2">
                  <a class="text-color4 text-primary-hover btn btn-sm btn-outline-light" [routerLink]="['/blog-category', populars[0].slug_category]">
                    {{populars[0].category}} 
                  </a>
                </div>
                <h3 class="mb-1">
                  <a [routerLink]="['/blog-post', populars[0].slug]" class="text-white stack-font2">
                    {{populars[0].title}} 
                  </a>
                </h3>
                <div class="headline-meta text-white small">
                  <a href="./blog.html" class="text-white">
                    {{populars[0].nama}} 
                  </a>
                  &nbsp; 
                  {{!populars[0].date ? 'not set' : populars[0].date | date : "d MMM y"}}
                  {{!populars[0].date ? '' : populars[0].date | date : "HH"}}
                  {{!populars[0].date ? '' : ':'}}
                  {{!populars[0].date ? '' : populars[0].date | date : "mm"}}
                </div>
              </div>
            </div>
          </div>
          <div class="headline-sidebar col-lg-4 col-md-12">
            <div class="headline">
              <a class="headline-img" [routerLink]="['/blog-post', populars[1].slug]">
                <img [src]="populars[1].picture" onerror="src='/assets/media/error/noimage.png'" alt="" class="img-fluid">
              </a>
              <div class="headline-body">
                <div class="mb-2">
                  <a [routerLink]="['/blog-category', populars[1].slug_category]" class="text-color4 text-primary-hover btn btn-sm btn-outline-light">
                    {{populars[1].category}} 
                  </a>
                </div>
                <h3 class="mb-1">
                  <a [routerLink]="['/blog-post', populars[1].slug]" class="text-white stack-font2">
                    {{populars[1].title}} 
                  </a>
                </h3>
                <div class="headline-meta text-white small">
                  <a href="./blog.html" class="text-white">
                    {{populars[1].nama}} 
                  </a>
                  &nbsp; 
                  {{!populars[1].date ? 'not set' : populars[1].date | date : "d MMM y"}}
                  {{!populars[1].date ? '' : populars[1].date | date : "HH"}}
                  {{!populars[1].date ? '' : ':'}}
                  {{!populars[1].date ? '' : populars[1].date | date : "mm"}}
                </div>
              </div>
            </div>
            <div class="headline">
              <a class="headline-img" [routerLink]="['/blog-post', populars[3].slug]">
                <img [src]="populars[3].picture" onerror="src='/assets/media/error/noimage.png'" alt="" class="img-fluid">
              </a>
              <div class="headline-body">
                <div class="mb-2">
                  <a [routerLink]="['/blog-category', populars[3].slug_category]" class="text-color4 text-primary-hover btn btn-sm btn-outline-light">
                    {{populars[3].category}} 
                  </a>
                </div>
                <h3 class="mb-1">
                  <a [routerLink]="['/blog-post', populars[3].slug]" class="text-white stack-font2">
                    {{populars[3].title}} 
                  </a>
                </h3>
                <div class="headline-meta text-white small">
                  <a href="./blog.html" class="text-white">
                    {{populars[3].nama}} 
                  </a>
                  &nbsp; 
                  {{!populars[3].date ? 'not set' : populars[3].date | date : "d MMM y"}}
                  {{!populars[3].date ? '' : populars[3].date | date : "HH"}}
                  {{!populars[3].date ? '' : ':'}}
                  {{!populars[3].date ? '' : populars[3].date | date : "mm"}}
                </div>
              </div>
            </div>
          </div>
        </div>
      </ng-container>
      <div class="w-75 float-left">
        <div class="template-page-main">
          <div class=" pb-5 ">
            <ng-container *ngIf="blogs else skeleton">
              <div class="row text-left">
                <ng-container *ngFor="let blog of blogs; index as ind">
                  <div class="media col-12 mb-4 ">
                    <a [routerLink]="['/blog-post', blog.slug]">
                      <img [src]="blog.picture" onerror="src='/assets/media/error/noimage.png'" [alt]="blog.title"
                        class="mr-3 mr-md-4 mw-100 mb-3 " style="width:170px; height:113px;">
                    </a>
                    <div class="media-body">
                      <h4 class="stack_font2 ">
                        <a [routerLink]="['/blog-post', blog.slug]" class="text-primary">
                          {{blog.title}}
                        </a>
                      </h4>
                      <div class="description stack_font1 ">
                        <p class="text-dark m-0 pt-0 pb-2">
                          <i class="fa fa-calendar-alt"></i>
                          &nbsp; {{!blog.date ? 'not set' : blog.date | date : "d MMM y"}}
                              {{!blog.date ? '' : blog.date | date : "HH"}}
                              {{!blog.date ? '' : ':'}}
                              {{!blog.date ? '' : blog.date | date : "mm"}} &nbsp; - &nbsp; <i class="fa fa-user-tie"></i> &nbsp; {{blog.nama}}
                        </p>
                        <p class="">
                          {{blog.content}}
                        </p>
                      </div>
                    </div>
                  </div>
                </ng-container>
              </div>
              <div class="row">
                <div class="col-12" [class.d-none]="hideLoader">
                  <div style="text-align: center; margin: 0 0 20px 0">
                    <div class="text-center">
                      <img src="/assets/media/loader/timeline.svg">
                    </div>
                  </div>
                </div>
                <div class="col-6">
                  <div style="text-align: left; margin: 0 0 20px 0">
                    <a class="btn btn-light" href="javascript:" (click)="previousPage()" [class.d-none]="!hideLoader || page === 1">
                      &larr; Halaman Sebelumnya
                    </a>
                  </div>
                </div>
                <div class="col-6 text right">
                  <div style="text-align: right; margin: 0 0 20px 0">
                    <a class="btn btn-light" href="javascript:" (click)="nextPage()" [class.d-none]="!hideLoader || lastData">
                      Halaman Selanjutnya &rarr;
                    </a>
                  </div>
                </div>
              </div>
            </ng-container>
          </div>
        </div>
      </div>
      <div class="w-25 float-right">
        <div class="template-page-sidebar row">
          <div class="mb-2 p-3 col-12">
            <div class="category2">
              <h3 class="text-center pb-3 h3 stack_font2 m-0">
                Kategori Blog
              </h3>
              <ng-container *ngIf="categories else skeletonSide">
                <ul style="list-style-type:none; margin:0; padding:0;">
                  <ng-container *ngFor="let category of categories">
                    <li>
                      <a class="d-block text-dark border-top p-3 font-weight-bold" [routerLink]="['/blog-category', category.slug]">
                        {{category.category}}
                      </a>
                    </li>
                  </ng-container>                  
                </ul>
              </ng-container>              
            </div>
          </div>
          <div class="mb-4 px-3 py-3 col-12 bg-light" id="blogpopular">
            <h3 class="text-center pb-3 h3 stack_font2 m-0 text-dark rounded-top">
              Popular Post
            </h3>
            <ng-container *ngIf="populars else skeletonSide">
              <ng-container *ngFor="let popular of populars">
                <div class="media py-3 border-top">
                  <a [routerLink]="['/blog-post', popular.slug]">
                    <img [src]="popular.picture" onerror="src='/assets/media/error/noimage.png'" class="mr-3" alt="" style="width:60px; height:40px;">
                  </a>
                  <div class="media-body">
                    <p class="mt-0 mb-1">
                      <a [routerLink]="['/blog-post', popular.slug]"
                        class="text-color4 text-white-hover text-capitalize font-weight-bold">
                        {{popular.title}}
                      </a>
                    </p>
                    <div class="small">
                      {{!popular.date ? 'not set' : popular.date | date : "d MMM y"}}
                      {{!popular.date ? '' : popular.date | date : "HH"}}
                      {{!popular.date ? '' : ':'}}
                      {{!popular.date ? '' : popular.date | date : "mm"}}
                    </div>
                  </div>
                </div>
              </ng-container>
            </ng-container>            
            <div class="clear"></div>
          </div>
        </div>
      </div>
      <div class="clear"></div>
    </div>
  </div>
</div>

<ng-template #skeleton>

  <div class="row">
    <div class="col-12">
      <div class="skeleton-loader"></div>
    </div>
  </div>

  <div class="row">
    <div class="col-12">
      <div class="skeleton-loader"></div>
    </div>
  </div>

  <div class="row">
    <div class="col-12">
      <div class="skeleton-loader"></div>
    </div>
  </div>

  <div class="row">
    <div class="col-12">
      <div class="skeleton-loader"></div>
    </div>
  </div>

</ng-template>

<ng-template #skeletonHeader>

  <div class="row">
    <div class="col-12">
      <div class="skeleton-loader fake-header"></div>
    </div>
  </div>

</ng-template>

<ng-template #skeletonSide>

  <div class="row">
    <div class="col-12">
      <div class="skeleton-loader"></div>
    </div>
  </div>

  <div class="row">
    <div class="col-12">
      <div class="skeleton-loader"></div>
    </div>
  </div>

  <div class="row">
    <div class="col-12">
      <div class="skeleton-loader"></div>
    </div>
  </div>

  <div class="row">
    <div class="col-12">
      <div class="skeleton-loader"></div>
    </div>
  </div>

</ng-template>