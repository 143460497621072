import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFireMessaging } from '@angular/fire/messaging';
import { IndexedDbService } from 'src/app/_services/indexed-db.service';
import { take } from 'rxjs/operators';
import { Observable, Subject } from 'rxjs';
import Swal from 'sweetalert2';
import { GlobalService } from './global.service';
import { CookieService } from 'ngx-cookie-service';
import { map } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BrowserService } from './browser.service';
import * as i0 from "@angular/core";
import * as i1 from "@angular/fire/auth";
import * as i2 from "@angular/fire/messaging";
import * as i3 from "./indexed-db.service";
import * as i4 from "@angular/common/http";
import * as i5 from "./global.service";
import * as i6 from "ngx-cookie-service";
import * as i7 from "./browser.service";
var API_SAVE_TOKEN = '/auth/save-token';
var MessagingService = /** @class */ (function () {
    function MessagingService(angularFireAuth, angularFireMessaging, idbService, http, globalService, cookieService, browserService) {
        this.angularFireAuth = angularFireAuth;
        this.angularFireMessaging = angularFireMessaging;
        this.idbService = idbService;
        this.http = http;
        this.globalService = globalService;
        this.cookieService = cookieService;
        this.browserService = browserService;
        this.loadNotificationEmit = new Subject();
        this.loadNotificationEmitObs = this.loadNotificationEmit.asObservable();
        this.angularFireMessaging.messaging.subscribe(function (_messaging) {
            _messaging.onMessage = _messaging.onMessage.bind(_messaging);
            _messaging.onTokenRefresh = _messaging.onTokenRefresh.bind(_messaging);
        });
    }
    /**
     * update token in firebase database
     *
     * @param userId userId as a key
     * @param token token as a value
     */
    MessagingService.prototype.updateToken = function (userId, token) {
        // we can change this function to request our backend service
        this.angularFireAuth.authState.pipe(take(1)).subscribe(function () {
            var data = {};
            data[userId] = token;
        });
    };
    /**
     * request permission for notification from firebase cloud messaging
     *
     * @param userId userId
     */
    MessagingService.prototype.requestPermission = function (userId, isReload) {
        var _this = this;
        this.angularFireMessaging.requestToken.subscribe(function (token) {
            _this.currentToken = token;
            _this.updateToken(userId, token);
            _this.saveToken(token).subscribe(function (response) {
                if (!response.status) {
                    console.log(response);
                }
            }, function (error) {
                console.log(error);
            });
        }, function (err) {
            if (isReload) {
                Swal.fire('Please reload browser (Ctrl + R)\nThank you');
            }
            console.log('Unable to get permission to notify.', err);
        });
    };
    /**
       * Get access token
       */
    MessagingService.prototype.getFcmToken = function () {
        return this.currentToken;
    };
    /**
     * hook method when new notification received in foreground
     */
    MessagingService.prototype.receiveMessage = function () {
        var _this = this;
        this.angularFireMessaging.messages.subscribe(function (payload) {
            // console.log('new message received. ', (payload.data.globalNotif) ? true : false);
            // syncronization here
            (payload.data.globalNotif === 'true') ? _this.cookNotif(payload.data) : _this.synchronization(payload.data);
        });
    };
    MessagingService.prototype.cookNotif = function (data) {
        var tempNotif = JSON.parse(data.notification);
        Swal.fire(tempNotif.title + '\nPlease check your notification bar.');
        this.loadNotificationEmit.next(true);
    };
    MessagingService.prototype.synchronization = function (param) {
        var _this = this;
        // Jika payload null proses stop
        if (param.data === 'false') {
            return console.log('Empty Data Payload on ' + param.objectStore + '.\nSyncronization aborted.');
        }
        // Syncronization Code
        var objectStore = param.objectStore;
        var tempData = JSON.parse(param.data);
        // Idb Check In
        this.idbService.count(objectStore).then(function (arg) {
            (arg !== 0) ? _this.idbService.toArray(objectStore).then(function (objAll) {
                var targetData = objAll.filter(function (obj) { return obj.EmailAddress === tempData['EmailAddress']; })[0];
                // Checking tempData if is_deleted = 1 brati request tambah data
                if (tempData['is_deleted'] === 1) {
                    _this.idbService.deleteOne(objectStore, targetData.id).then(function () {
                        console.log('Success - data has been removed!');
                        return;
                        Swal.fire('Data on going!\nYour data has been syncronize.');
                    }, function (error) {
                        _this.alertModal(error);
                    });
                    return;
                }
                // Checking targetData if undefined brati request tambah data
                if (!targetData) {
                    _this.idbService.addOne(objectStore, tempData).then(function () {
                        console.log('Success - data has been added!');
                        return;
                        Swal.fire('Data in coming!\nYour data has been syncronize.');
                    }, function (error) {
                        _this.alertModal(error);
                    });
                    return;
                }
                _this.idbService.update(objectStore, targetData.id, tempData).then(function () {
                    console.log('Success - your data is updated!');
                    return;
                    Swal.fire('Data in coming!\nYour data has been syncronize.');
                }, function (error) {
                    _this.alertModal(error);
                });
            }) : _this.idbService.addOne(objectStore, tempData).then(function () {
                console.log('Success - data has been added!');
                return;
                Swal.fire('Data in coming!\nYour data has been syncronize.');
            }, function (error) {
                _this.alertModal(error);
            });
        }, function (error) {
            return console.log(error);
            _this.alertModal(error);
        });
    };
    MessagingService.prototype.alertModal = function (error) {
        Swal.fire({
            type: 'error',
            title: 'Terjadi Error.',
            text: 'Error : ' + error,
            footer: '<a href>Hubungi Customer Care?</a>'
        });
    };
    MessagingService.prototype.header = function () {
        return {
            headers: new HttpHeaders()
                .set('token', this.cookieService.get('_q'))
        };
    };
    MessagingService.prototype.saveToken = function (token) {
        var browserName = this.browserService.getBrowserName();
        var browserVersion = this.browserService.getBrowserVersion();
        var browserOs = this.browserService.getBrowserOperatingSystem();
        return this.http.post(this.globalService.apiVersionHost + API_SAVE_TOKEN, {
            token: token,
            brand: browserName,
            version: browserVersion,
            os: browserOs
        }, this.header()).pipe(map(function (response) { return response; }));
    };
    MessagingService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function MessagingService_Factory() { return new MessagingService(i0.ɵɵinject(i1.AngularFireAuth), i0.ɵɵinject(i2.AngularFireMessaging), i0.ɵɵinject(i3.IndexedDbService), i0.ɵɵinject(i4.HttpClient), i0.ɵɵinject(i5.GlobalService), i0.ɵɵinject(i6.CookieService), i0.ɵɵinject(i7.BrowserService)); }, token: MessagingService, providedIn: "root" });
    return MessagingService;
}());
export { MessagingService };
