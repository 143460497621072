/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./header-mobile.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/router";
import * as i3 from "@angular/common";
import * as i4 from "./header-mobile.component";
import * as i5 from "../../../_services/layout.service";
var styles_HeaderMobileComponent = [i0.styles];
var RenderType_HeaderMobileComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_HeaderMobileComponent, data: {} });
export { RenderType_HeaderMobileComponent as RenderType_HeaderMobileComponent };
export function View_HeaderMobileComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 13, "div", [["class", "kt-header-mobile kt-header-mobile--fixed "], ["id", "kt_header_mobile"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 7, "div", [["class", "kt-header-mobile__logo"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 6, "a", [["routerLinkActive", "router-link-active"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 3).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(3, 671744, [[2, 4]], 0, i2.RouterLinkWithHref, [i2.Router, i2.ActivatedRoute, i3.LocationStrategy], { routerLink: [0, "routerLink"] }, null), i1.ɵpad(4, 1), i1.ɵdid(5, 1720320, null, 2, i2.RouterLinkActive, [i2.Router, i1.ElementRef, i1.Renderer2, [2, i2.RouterLink], [2, i2.RouterLinkWithHref]], { routerLinkActive: [0, "routerLinkActive"] }, null), i1.ɵqud(603979776, 1, { links: 1 }), i1.ɵqud(603979776, 2, { linksWithHrefs: 1 }), (_l()(), i1.ɵeld(8, 0, null, null, 0, "img", [["alt", "LogoNDS"], ["src", "./assets/media/logos/logonds3.png"], ["style", "height: 25px"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 4, "div", [["class", "kt-header-mobile__toolbar"]], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 1, "button", [["class", "kt-header-mobile__toggler kt-header-mobile__toggler--left"], ["id", "kt_aside_mobile_toggler"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.toggleSideLeft() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(11, 0, null, null, 0, "span", [], null, null, null, null, null)), (_l()(), i1.ɵeld(12, 0, null, null, 1, "button", [["class", "kt-header-mobile__topbar-toggler"], ["id", "kt_header_mobile_topbar_toggler"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.toggleTopbar() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(13, 0, null, null, 0, "i", [["class", "flaticon-more"]], null, null, null, null, null))], function (_ck, _v) { var currVal_2 = _ck(_v, 4, 0, "/main/modules"); _ck(_v, 3, 0, currVal_2); var currVal_3 = "router-link-active"; _ck(_v, 5, 0, currVal_3); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 3).target; var currVal_1 = i1.ɵnov(_v, 3).href; _ck(_v, 2, 0, currVal_0, currVal_1); }); }
export function View_HeaderMobileComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-header-mobile", [], null, null, null, View_HeaderMobileComponent_0, RenderType_HeaderMobileComponent)), i1.ɵdid(1, 114688, null, 0, i4.HeaderMobileComponent, [i5.LayoutService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var HeaderMobileComponentNgFactory = i1.ɵccf("app-header-mobile", i4.HeaderMobileComponent, View_HeaderMobileComponent_Host_0, {}, {}, []);
export { HeaderMobileComponentNgFactory as HeaderMobileComponentNgFactory };
