<div id="nds_contents">
  <div class="bg-white navbar-spacer"></div>
  <div class="pagecomponentwrap text-center text-white " id="pageheader">
    <div class="container pb-5 pt-75">
      <div class="no-gutters row justify-content-center align-items-center" data-aos="">
        <div class="col-md-10" data-aos="flip-up">
          <h2 class="stack_font2 h1 pb-2">
            Pelaporan Kecurangan
          </h2>
          <p class="stack_font3 lead ">
            Halaman ini hanya digunakan untuk melaporkan pelanggaran atau kecurangan di lingkungan PT Nexwave Indonesia.
          </p>
          <p class="stack_font3 lead ">
            Diluar hal tersebut tidak akan ditanggapi.
          </p>
          <p class="stack_font3 lead ">
            Sistem tidak menyimpan data identitas pelapor, dimohon untuk tidak menuliskan identitas pada inputan laporan ini.
          </p>
          <p class="stack_font3 lead ">
            Terimakasih.
          </p>
        </div>
      </div>
    </div>
  </div>

  <div class="pagecomponentwrap">
    <div class="container pb-5 pt-5 px-4">
      <div class="kt-grid">
        <div class="kt-grid__item kt-grid__item--fluid kt-wizard-v4__wrapper">
          <div class="kt-wizard-v4__content text-center">
            <div class="kt-section kt-section--first">
              <div class="kt-wizard-v4__form">
                <form class="kt-form" #registration="ngForm">
                  <div class="row justify-content-center text-left">
                    <div class="col-lg-10">
                      <div class="kt-section__body">
                        <div class="form-group row">
                          <label class="col-xl-3 col-lg-3 col-form-label">
                            Subjek
                          </label>
                          <div class="col-lg-9 col-xl-9">
                            <div class="input-group">
                              <input [(ngModel)]="master.title" name="master_title" type="text" class="form-control">
                            </div>
                          </div>
                        </div>
                        <div class="form-group row">
                          <label class="col-xl-3 col-lg-3 col-form-label">
                            Keterangan
                          </label>
                          <div class="col-lg-9 col-xl-9">
                            <div class="input-group">
                              <textarea [(ngModel)]="master.description" name="master_description" class="form-control" rows="10"></textarea>
                            </div>
                          </div>
                        </div>
                        <div class="form-group row">
                          <label class="col-xl-3 col-lg-3 col-form-label">
                            Lampiran
                          </label>
                          <div class="col-lg-9 col-xl-9">
                            <!-- <app-gcs-upload [rese] [acceptFile]="['image/jpg','image/jpeg','image/png','application/vnd.rar','application/zip','application/vnd.ms-excel','application/vnd.openxmlformats-officedocument.spreadsheetml.sheet','application/msword','application/vnd.openxmlformats-officedocument.wordprocessingml.document']" [remark]="''"
                            [prefix]="'uploads/whistle-blowing/'" [module]="'site_survey'" [tableTarget]="'whistle_blowing_detail'" [filesizeMaximum]="52428800" [columnTarget]="'id_file'" (success)="onUploaded($event)">
                          </app-gcs-upload> -->
                          <app-direct-upload [filesizeMaximum]="52428800"  [tableTarget]="'whistle_blowing_detail'" [columnTarget]="'id_file'" [prefix]="'uploads/whistle-blowing/'"
                          [acceptFile]="'.jpg,.jpeg,.png,.pdf,.xls,.xlsx,.doc,.docx,.zip,.rar'" (success)="onSuccessWasabi($event)">
                        </app-direct-upload>
                            <br>
                            <div class="row">
                              <ng-container *ngFor="let attach of listAttach">
                                <ng-container *ngIf="attach.ext == 'jpg' || attach.ext == 'jpeg' || attach.ext == 'png'">
                                  <div class="col-xl-3 col-lg-4 col-md-4 col-sm-6 col-xs-6">
                                    <div class="attach-image" [style.background-image]="'url(' + attach.url + ')'">
                                      <i class="fa fa-trash fa-lg btn-delete" (click)="deleteAttach(attach.id_file)"></i>
                                    </div>
                                  </div>
                                </ng-container>
                                <ng-container *ngIf="attach.ext == 'zip' || attach.ext == 'rar'">
                                  <div class="col-xl-3 col-lg-4 col-md-4 col-sm-6 col-xs-6">
                                    <div class="attach-image" [style.background-image]="'url(./assets/media/icons/svg/Design/Layers.svg)'" [style.background-size]="'100px 100px'">
                                      <i class="fa fa-trash fa-lg btn-delete" (click)="deleteAttach(attach.id_file)"></i>
                                    </div>
                                  </div>
                                </ng-container>
                                <ng-container *ngIf="attach.ext == 'pdf' || attach.ext == 'xls' || attach.ext == 'xlsx' || attach.ext == 'doc' || attach.ext == 'docx'">
                                  <div class="col-xl-3 col-lg-4 col-md-4 col-sm-6 col-xs-6">
                                    <div class="attach-image" [style.background-image]="'url(./assets/media/icons/svg/Files/File.svg)'" [style.background-size]="'100px 100px'">
                                      <i class="fa fa-trash fa-lg btn-delete" (click)="deleteAttach(attach.id_file)"></i>
                                    </div>
                                  </div>
                                </ng-container>
                              </ng-container>
                            </div>
                          </div>
                        </div>
                        <div class="form-group row bg-light px-3 py-3 border rounded">
                          <div class="col-6 text-left"></div>
                          <div class="col-6 text-right">
                            <button (click)="submitWb()" class="btn btn-brand btn-md btn-tall btn-wide kt-font-bold kt-font-transform-u">
                              Submit
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
