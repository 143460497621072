<div class="login-form text-center text-dark"
  style="background: url(./assets/media/5g/login.jpg) no-repeat; background-size: 100%">
  <div class="container">
    <div class="row justify-content-center pt-0 pt-lg-5">
      <div class="col-xl-8 col-lg-9 col-md-6 col-11 pt-0 pt-lg-5">
        <div class="shadow login-form-wrap rounded">

          <ngx-ui-loader [fgsType]="'three-bounce'" [hasProgressBar]="false" [loaderId]="'login-loader'">
          </ngx-ui-loader>

          <div class="row align-items-center no-gutters">
            <div class="col-lg-6 login-form-left rounded text-center ">
              <div class="pt-5 pb-lg-5 pb-0 px-3">
                <a [routerLink]="['/']">
                  <img src="./assets/media/nds/logo.png" alt="" class="login-logo" />
                </a>
              </div>
            </div>
            <div class="col-lg-6 login-form-right">
              <div class="py-5 px-4 px-md-5">
                <h3 class="pb-4 font-weight-bold d-none d-lg-block">
                  User Login
                </h3>
                <form #loginForm="ngForm" (ngSubmit)="submit(loginForm)">
                  <div class="form-group">
                    <input type="text" name="username" [(ngModel)]="login_form.username" class="form-control"
                      placeholder="Username / Email" value="" [disabled]="isSubmited" [class.disabled]="isSubmited" />
                  </div>
                  <div class="form-group">
                    <input type="password" name="password" [(ngModel)]="login_form.password" class="form-control"
                      placeholder="Password" value="" [disabled]="isSubmited" [class.disabled]="isSubmited" />
                  </div>
                  <div class="form-group text-left pt-3">
                    <input type="submit" class="btn btn-dark mr-3" value="Login" [disabled]="isSubmited"
                      [class.disabled]="isSubmited" />
                    <a [routerLink]="['/']" class="btn btn-outline-dark mr-3">Back</a>
                    <a [routerLink]="['/forgot-password']" class="text-dark font-weight-bold">
                      Lost Password?
                    </a>
                  </div>

                  <angular-telegram-login-widget (load)="onLoad()" (loadError)="onLoadError()" (login)="onLogin($event)"
                    [botName]="botName"></angular-telegram-login-widget>

                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="clear"></div>

<ng-template #telegramModal let-modal>

  <ngx-ui-loader [fgsType]="'three-bounce'" [hasProgressBar]="false" [loaderId]="'telegram-loader'"></ngx-ui-loader>

  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Link Telegram Account</h4>
    <button class="btn-close" style="border: solid 1px #ccc; border-radius: 7px" aria-label="Close"
      (click)="modal.dismiss('Cross click')">&times;</button>
  </div>
  <div class="modal-body">

    <div class="row mb-3">
      <div class="col-4">
        Telegram Account
      </div>
      <div class="col-8">
        <input type="text" class="form-control" disabled [(ngModel)]="telegramAccount">
      </div>
    </div>
    <div class="row mb-3">
      <div class="col-4">
        Username
      </div>
      <div class="col-8">
        <input type="text" class="form-control" [(ngModel)]="telegramPtnwUsername" (keyup.enter)="linkTelegram()">
      </div>
    </div>
    <div class="row mb-3">
      <div class="col-4">
        Password
      </div>
      <div class="col-8">
        <input type="password" class="form-control" [(ngModel)]="telegramPtnwPassword" (keyup.enter)="linkTelegram()">
      </div>
    </div>

    <div class="text-danger text-center" id="error-message">&nbsp;</div>

  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-primary" (click)="linkTelegram()">LOGIN</button>
  </div>
</ng-template>

<button class="btn btn-lg btn-outline-primary d-none" id="tg-btn" (click)="open(telegramModal)"></button>