import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { AuthServiceConfig, GoogleLoginProvider, FacebookLoginProvider, LinkedinLoginProvider, } from 'angular-6-social-login';
// Configs
export function getAuthServiceConfigs() {
    var config = new AuthServiceConfig([
        {
            id: FacebookLoginProvider.PROVIDER_ID,
            provider: new FacebookLoginProvider("660136134567466")
        },
        {
            id: GoogleLoginProvider.PROVIDER_ID,
            provider: new GoogleLoginProvider("667983529003-33efj498j57kjgajocm68dclp5udcc0d.apps.googleusercontent.com")
        },
        {
            id: LinkedinLoginProvider.PROVIDER_ID,
            provider: new LinkedinLoginProvider("86lqdt3190nyp1")
        }
    ]);
    return config;
}
var DEFAULT_PERFECT_SCROLLBAR_CONFIG = {
    suppressScrollX: true
};
var ɵ0 = DEFAULT_PERFECT_SCROLLBAR_CONFIG;
var SharedModule = /** @class */ (function () {
    function SharedModule() {
    }
    return SharedModule;
}());
export { SharedModule };
export { ɵ0 };
