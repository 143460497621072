import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import Swal from 'sweetalert2';
import { AuthenticationService } from '../auth/authentication.service';
import { ResponseApiModel } from '../_models/response-api.model';
import { GlobalService } from './global.service';
import { UserService } from './user.service';

const API_GET_LIST = '/modules-menu/get-list';

@Injectable({
  providedIn: 'root'
})
export class ModuleMenuService {

  constructor(
    private http: HttpClient,
    private globalService: GlobalService,
    private cookieService: CookieService,
    private authenticationService: AuthenticationService,
    private userService: UserService
  ) { }

  private checkJWT() {
    this.userService.getAccessToken().subscribe(token => {
      if (token) {
        const expiry = (JSON.parse(atob(token.split('.')[1]))).exp;
        if ((Math.floor((new Date).getTime() / 1000)) >= expiry) {
          Swal.fire('Sistem PTNW.NET', 'Sesi login Anda telah habis. Silakan melakukan login ulang. Terima Kasih.', 'warning');
          this.authenticationService.logout();
        }
      }
    });
  }

  public header() {
    return {
      headers: new HttpHeaders()
        .set('token', this.cookieService.get('_q'))
    };
  }

  public getList(): Observable<ResponseApiModel> {
    this.checkJWT();
    return this.http.post<ResponseApiModel>(
      this.globalService.apiVersionHost + API_GET_LIST, null, this.header()
    ).pipe(
      map(response => response)
    );
  }
}
