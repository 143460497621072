import { DexieService } from 'ngx-dexie';
import * as i0 from "@angular/core";
import * as i1 from "ngx-dexie/dexie.service";
var IndexedDbService = /** @class */ (function () {
    function IndexedDbService(dexieService) {
        this.dexieService = dexieService;
    }
    IndexedDbService.prototype.addOne = function (table, object) {
        return this.dexieService.addOne(table, object);
    };
    IndexedDbService.prototype.addMultiple = function (table, objects) {
        return this.dexieService.addMultiple(table, objects);
    };
    IndexedDbService.prototype.count = function (table) {
        return this.dexieService.count(table);
    };
    IndexedDbService.prototype.addOrUpdateOne = function (table, object, key) {
        if (key) {
            return this.dexieService.addOrUpdateOne(table, object, key);
        }
        else {
            return this.dexieService.addOrUpdateOne(table, object);
        }
    };
    IndexedDbService.prototype.addOrUpdateMultiple = function (table, objects, keys) {
        if (keys) {
            return this.dexieService.addOrUpdateMultiple(table, objects, keys);
        }
        else {
            return this.dexieService.addOrUpdateMultiple(table, objects);
        }
    };
    IndexedDbService.prototype.deleteOne = function (table, primaryKey) {
        return this.dexieService.deleteOne(table, primaryKey);
    };
    IndexedDbService.prototype.deleteMultiple = function (table, primaryKeys) {
        return this.dexieService.deleteMultiple(table, primaryKeys);
    };
    IndexedDbService.prototype.clearAll = function (table) {
        return this.dexieService.clearAll(table);
    };
    IndexedDbService.prototype.operateOnEach = function (table, callback) {
        return this.dexieService.operateOnEach(table, callback);
    };
    IndexedDbService.prototype.filter = function (table, filterFunction) {
        return this.dexieService.filter(table, filterFunction);
    };
    IndexedDbService.prototype.getByPrimaryKey = function (table, primaryKey, callback) {
        if (callback) {
            return this.dexieService.getByPrimaryKey(table, primaryKey, callback);
        }
        else {
            return this.dexieService.getByPrimaryKey(table, primaryKey);
        }
    };
    IndexedDbService.prototype.getByKeyToValueMap = function (table, keyValueMap, callback) {
        if (callback) {
            return this.dexieService.getByKeyToValueMap(table, keyValueMap, callback);
        }
        else {
            return this.dexieService.getByKeyToValueMap(table, keyValueMap);
        }
    };
    IndexedDbService.prototype.limit = function (table, num) {
        return this.dexieService.getFirstNItemsOfTable(table, num);
    };
    IndexedDbService.prototype.orderBy = function (table, index) {
        return this.dexieService.orderBy(table, index);
    };
    IndexedDbService.prototype.offset = function (table, ignoreUpto) {
        return this.dexieService.offset(table, ignoreUpto);
    };
    IndexedDbService.prototype.reverse = function (table) {
        return this.dexieService.reverse(table);
    };
    IndexedDbService.prototype.toArray = function (table, callback) {
        if (callback) {
            return this.dexieService.toArray(table, callback);
        }
        else {
            return this.dexieService.toArray(table);
        }
    };
    IndexedDbService.prototype.toCollection = function (table) {
        return this.dexieService.toCollection(table);
    };
    IndexedDbService.prototype.update = function (table, key, changes) {
        return this.dexieService.update(table, key, changes);
    };
    IndexedDbService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function IndexedDbService_Factory() { return new IndexedDbService(i0.ɵɵinject(i1.DexieService)); }, token: IndexedDbService, providedIn: "root" });
    return IndexedDbService;
}());
export { IndexedDbService };
