import { HttpClient, HttpHeaders } from '@angular/common/http';
import { CookieService } from 'ngx-cookie-service';
import { GlobalService } from './global.service';
import { Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "ngx-cookie-service";
import * as i3 from "./global.service";
var API_GET_MY_TIMESHEETS = '/timesheet/get-my-timesheets';
var API_SET_AS_FINISH = '/timesheet/set-as-finish';
var API_GET_MORE_TIMESHEETS = '/timesheet/get-more-timesheets';
var API_SAVE_TIMESHEET = '/timesheet/save';
var TimesheetService = /** @class */ (function () {
    function TimesheetService(http, cookieService, globalService) {
        this.http = http;
        this.cookieService = cookieService;
        this.globalService = globalService;
        this.newTimesheetEmit = new Subject();
        this.loadNewTimesheetEmitObs = this.newTimesheetEmit.asObservable();
    }
    TimesheetService.prototype.header = function () {
        return {
            headers: new HttpHeaders()
                .set('token', this.cookieService.get('_q'))
        };
    };
    TimesheetService.prototype.getMyTimesheets = function (param) {
        return this.http.post(this.globalService.apiVersionHost + API_GET_MY_TIMESHEETS, param, this.header()).pipe(map(function (response) { return response; }));
    };
    TimesheetService.prototype.getMoreTimesheets = function (param) {
        return this.http.post(this.globalService.apiVersionHost + API_GET_MORE_TIMESHEETS, param, this.header()).pipe(map(function (response) { return response; }));
    };
    TimesheetService.prototype.setAsFinish = function (param) {
        return this.http.post(this.globalService.apiVersionHost + API_SET_AS_FINISH, param, this.header()).pipe(map(function (response) { return response; }));
    };
    TimesheetService.prototype.saveTimesheet = function (param) {
        return this.http.post(this.globalService.apiVersionHost + API_SAVE_TIMESHEET, param, this.header()).pipe(map(function (response) { return response; }));
    };
    TimesheetService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function TimesheetService_Factory() { return new TimesheetService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.CookieService), i0.ɵɵinject(i3.GlobalService)); }, token: TimesheetService, providedIn: "root" });
    return TimesheetService;
}());
export { TimesheetService };
