import { OnInit } from '@angular/core';
import { Router, ActivatedRoute, PRIMARY_OUTLET, NavigationEnd } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { deleteDB } from 'idb';
import { AuthenticationService } from 'src/app/auth/authentication.service';
import { UserService } from 'src/app/_services/user.service';
import { MessagingService } from 'src/app/_services/messaging.service';
import { FormService } from 'src/app/_services/form.service';
import { ProfileService } from 'src/app/_services/profile.service';
import { RouterService } from 'src/app/_services/router.service';
import { PmcService } from 'src/app/_services/pmc.service';
import { isNullOrUndefined } from 'util';
import { PrecalcService } from 'src/app/_services/precalc.service';
import { VacancyService } from 'src/app/_services/vacancy.service';
import { EmployeeService } from 'src/app/_services/employee.service';
import { TimesheetService } from 'src/app/_services/timesheet.service';
import Swal from 'sweetalert2';
import { SimcardService } from 'src/app/_services/simcard.service';
var SubheaderComponent = /** @class */ (function () {
    function SubheaderComponent(activatedRoute, router, routerService, authService, userService, messagingService, formService, profileService, pmcService, vacancyService, employeeService, timesheetService, simcardService, modalService, precalcService, ngxLoader) {
        var _this = this;
        this.activatedRoute = activatedRoute;
        this.router = router;
        this.routerService = routerService;
        this.authService = authService;
        this.userService = userService;
        this.messagingService = messagingService;
        this.formService = formService;
        this.profileService = profileService;
        this.pmcService = pmcService;
        this.vacancyService = vacancyService;
        this.employeeService = employeeService;
        this.timesheetService = timesheetService;
        this.simcardService = simcardService;
        this.modalService = modalService;
        this.precalcService = precalcService;
        this.ngxLoader = ngxLoader;
        this.moduleActive = '';
        this.pageActive = '';
        this.subPageActive = '';
        this.breadcrumb = '';
        this.currentModule = '';
        this.profileSide = false;
        this.enableIndexedDB = false;
        this.enableSidebar = false;
        this.task = {
            nama_item: '',
            pic: [],
            desc_item: '',
            category: []
        };
        this.sprintModal = null;
        this.sprint = {
            nama_item: '',
            duration: 1,
            category: 'Development'
        };
        this.breadcrumbs = [];
        this.profileService.mobileToggleEmitObs.subscribe(function (res) {
            _this.profileSide = res;
        });
        this.router.events.subscribe(function (val) {
            if (val instanceof NavigationEnd) {
                _this.initRoute();
            }
        });
        this.vacancyService.enableidbEmitObs.subscribe(function (res) {
            _this.enableIndexedDB = res;
        });
    }
    SubheaderComponent.prototype.ngOnInit = function () {
        // check notification permission
        this.allowNotification = (Notification.permission !== 'denied' && Notification.permission !== 'default') ? true : false;
        this.isInternal = this.userService.getUserPtnwStatus();
    };
    // ngAfterViewInit() {
    //   this.initRoute();
    // }
    SubheaderComponent.prototype.initRoute = function () {
        // console.log('initRoute');
        var arr_url = this.router.url.split('/');
        if (arr_url[1] === 'main') {
            this.moduleActive = arr_url[2];
            this.pageActive = arr_url[3];
            if (this.moduleActive === 'pmc') {
                this.hash = arr_url[4];
            }
            else {
                this.subPageActive = arr_url[4];
            }
        }
        this.moduleChecker(arr_url);
    };
    SubheaderComponent.prototype.moduleChecker = function (arr_url) {
        if (this.moduleActive === 'pmc' && this.hash) {
            this.getBreadcrumsPMC();
        }
        else {
            var root = this.activatedRoute.root;
            this.breadcrumbs = this.getBreadcrumbs(this.router.url, root);
        }
        if (this.moduleActive === 'pm' && this.pageActive === 'precalc' && this.subPageActive === 'view') {
            this.hash = arr_url[5];
        }
        if (this.moduleActive === 'simcard' && this.pageActive === 'prepaid' && this.subPageActive === 'summary-approve') {
            this.hash = arr_url[5];
        }
        if (this.moduleActive === 'simcard' && this.pageActive === 'prepaid' && this.subPageActive === 'summary-approve-all') {
            this.listItemChecker = arr_url[5];
        }
    };
    SubheaderComponent.prototype.formatStep = function (temp, tempPath) {
        var tempListBreadcrum = { step: '', path: '' };
        if (temp.search(/-/gi) !== -1) {
            tempListBreadcrum = { step: temp.replace(/-/g, ' '), path: tempPath };
        }
        else if (temp.search(/_/gi) !== -1) {
            tempListBreadcrum = { step: temp.replace(/_/g, ' '), path: tempPath };
        }
        else {
            tempListBreadcrum = { step: temp, path: tempPath };
        }
        return tempListBreadcrum;
    };
    SubheaderComponent.prototype.getBreadcrumbs = function (pathUrl, route, url, breadcrumbs) {
        if (url === void 0) { url = ''; }
        if (breadcrumbs === void 0) { breadcrumbs = []; }
        var ROUTE_DATA_BREADCRUMB = 'breadcrumb';
        var ROUTE_DATA_HAVE_SUBMODULE = 'haveSubModule';
        var ROUTE_DATA_FORCEREDIRECT = 'forceRedirect';
        // get the child routes
        var children = route.children;
        // return if there are no more children
        if (children.length === 0) {
            return breadcrumbs;
        }
        // iterate over each children
        for (var _i = 0, children_1 = children; _i < children_1.length; _i++) {
            var child = children_1[_i];
            // verify primary route
            if (child.outlet !== PRIMARY_OUTLET) {
                continue;
            }
            // verify the custom data property 'breadcrumb' is specified on the route
            if (!child.snapshot.data.hasOwnProperty(ROUTE_DATA_BREADCRUMB)) {
                var temp = pathUrl.split('/');
                if (temp[1] === 'main') {
                    this.currentModule = temp[2];
                }
                return this.getBreadcrumbs(pathUrl, child, url, breadcrumbs);
            }
            // get the route's URL segment
            var routeURL = child.snapshot.url.map(function (segment) { return segment.path; }).join('/');
            // append route URL to URL
            url += "/" + routeURL;
            // set breadcrumb param
            var subModule = child.snapshot.data[ROUTE_DATA_HAVE_SUBMODULE];
            var forceRedirect = child.snapshot.data[ROUTE_DATA_FORCEREDIRECT];
            // set breadcrumb
            var bredtemp = this.setBreadcrum(pathUrl, url, subModule, forceRedirect);
            // add breadcrumb
            var breadcrumb = {
                label: child.snapshot.data[ROUTE_DATA_BREADCRUMB],
                params: child.snapshot.params,
                url: url,
                breadcrumb: bredtemp
            };
            breadcrumbs.push(breadcrumb);
            // recursive
            return this.getBreadcrumbs(pathUrl, child, url, breadcrumbs);
        }
    };
    SubheaderComponent.prototype.setBreadcrum = function (pathUrl, currentUrl, subModule, forceRedirect) {
        var _this = this;
        var listBreadcrum = [];
        var temp = pathUrl.split('/');
        if (temp[1] === 'main') {
            this.currentModule = temp[2];
            var tempPath_1 = '/' + temp[1] + '/' + temp[2];
            var _loop_1 = function (i) {
                if (currentUrl === '/' + temp[i] || currentUrl === '/' + temp[i] + '/' + temp[i + 1]) {
                    return { value: listBreadcrum };
                }
                if (temp[i] === temp[2]) {
                    if (subModule) {
                        // ROUTES FOR MODULE ITSELF
                        var tempPathModule = tempPath_1 + '/';
                        listBreadcrum.push(this_1.formatStep(temp[i], tempPathModule));
                        // ROUTES FOR SUBMODULE
                        if (temp[i + 2] === 'index') {
                            return "continue";
                        }
                        if (!isNullOrUndefined(forceRedirect)) {
                            tempPath_1 += '/' + forceRedirect;
                            listBreadcrum.push(this_1.formatStep(forceRedirect, tempPath_1));
                        }
                        else {
                            tempPath_1 += '/' + temp[i + 1] + '/index';
                            listBreadcrum.push(this_1.formatStep(temp[i + 1], tempPath_1));
                        }
                    }
                    else {
                        if (temp[i + 1] === 'index') {
                            return "continue";
                        }
                        // ROUTES FOR MODULE ITSELF
                        tempPath_1 += '/index';
                        listBreadcrum.push(this_1.formatStep(temp[i], tempPath_1));
                    }
                }
                else {
                    if (subModule && temp[i] === temp[3]) {
                        return "continue";
                    }
                    var lastTemp_1 = tempPath_1.split('/');
                    this_1.routerService.getId().subscribe(function (arg) {
                        var newPath;
                        if (arg === '') {
                            newPath = temp[i];
                        }
                        else {
                            newPath = temp[i] + '/' + arg;
                        }
                        tempPath_1 = tempPath_1.replace(lastTemp_1[tempPath_1.split('/').length - 1], newPath);
                        listBreadcrum.push(_this.formatStep(temp[i], tempPath_1));
                    });
                }
            };
            var this_1 = this;
            for (var i = 2; i < pathUrl.split('/').length; i++) {
                var state_1 = _loop_1(i);
                if (typeof state_1 === "object")
                    return state_1.value;
            }
        }
    };
    SubheaderComponent.prototype.toggleProfileAside = function () {
        this.profileSide = !this.profileSide;
        this.profileService.mobileToggleEmit.next(this.profileSide);
    };
    SubheaderComponent.prototype.getBreadcrumsPMC = function () {
        var _this = this;
        this.pmcService.getHirarki({ hash: this.hash }).subscribe(function (response) {
            if (response.status) {
                var temp = {
                    label: 'PMC',
                    url: 'https://pmc.ptnw.net/main/pmc',
                    breadcrumb: []
                };
                temp.breadcrumb = response.result.map(function (x) {
                    return { step: x.nama_item, path: x.hash };
                });
                _this.breadcrumbs = [temp];
            }
        });
    };
    SubheaderComponent.prototype.open = function (content) {
        this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title', size: 'lg' });
    };
    SubheaderComponent.prototype.createSimple = function (content, hash) {
        if (hash === void 0) { hash = ''; }
        this.hash = (hash === '') ? null : hash;
        this.prepareCreate(content);
    };
    SubheaderComponent.prototype.prepareCreate = function (content) {
        var _this = this;
        this.pmcService.prepareCreate({ hashParent: this.hash }).subscribe(function (response) {
            if (response.status) {
                _this.listEmployee = _this.listEmployee2 = [];
                _this.task = { nama_item: '', pic: [], desc_item: '', category: [] };
                _this.listEmployee = response.result.row.list_user;
                _this.listEmployee2 = response.result.row.list_user2;
                response.result.row.parent_pic.forEach(function (element) {
                    var tempFilter = _this.listEmployee2.filter(function (ter) { return ter.value === element.userid; })[0];
                    if (tempFilter) {
                        _this.task.pic.push(tempFilter);
                    }
                });
                _this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title', size: 'lg' });
            }
        });
    };
    SubheaderComponent.prototype.checkingData = function () {
        var lastPic = this.task.pic[this.task.pic.length - 1];
        var duplicate = this.task.pic.filter(function (dt) { return dt.key === lastPic.key; });
        if (duplicate.length > 1) {
            this.task.pic.splice(this.task.pic.length - 1, 1);
        }
    };
    SubheaderComponent.prototype.saveTask = function (simpleTask) {
        var _this = this;
        this.ngxLoader.start();
        if (this.validate(simpleTask)) {
            var tempPic_1 = [];
            this.task.pic.forEach(function (element) {
                var tempFilter = _this.listEmployee.filter(function (ter) { return ter.userid === element.value; })[0];
                if (tempFilter) {
                    tempPic_1.push(tempFilter);
                }
            });
            var tempDate = [new Date(), new Date(new Date().getTime() + (28 * 24 * 60 * 60 * 1000))];
            var date = [];
            for (var index = 0; index < tempDate.length; index++) {
                var element = tempDate[index];
                var dd = element.getDate();
                var mm = element.getMonth() + 1;
                var yyyy = element.getFullYear();
                date[index] = yyyy + '-' + mm + '-' + dd;
            }
            var param = {
                hashParent: this.hash,
                nama_item: this.task.nama_item,
                pic: tempPic_1,
                desc_item: this.task.desc_item,
                category: this.task.category,
                plan_start: date[0],
                plan_end: date[1],
                tipe: 'General',
                occurance: 'Once',
                priority: '3',
                weight: 1,
                access: 'Private'
            };
            this.pmcService.saveItem(param).subscribe(function (response) {
                _this.ngxLoader.stop();
                if (response.status && response.result) {
                    Swal.fire('Task has been created.');
                    _this.pmcService.reloadDataEmit.next(true);
                    return;
                }
                Swal.fire(response.message);
            }, function () {
                _this.ngxLoader.stop();
                Swal.fire('Not Authorized');
            });
        }
    };
    SubheaderComponent.prototype.createSprint = function (modal) {
        this.sprintModal = this.modalService.open(modal, { ariaLabelledBy: 'modal-basic-title', size: 'lg' });
        //set autofocus
        console.log("sprintModal", this.sprintModal);
        // this.SprintTitleInput.nativeElement.focus();
    };
    SubheaderComponent.prototype.validateSprint = function (form_item) {
        var value = form_item;
        if (value.nama_item === '') {
            this.formService.errorValidation('Error', 'Title cannot be blank');
            return false;
        }
        var duration = parseInt(this.sprint.duration);
        if (duration <= 0 || duration > 8) {
            this.formService.errorValidation('Error', 'Duration can only between 1 and 8');
            return false;
        }
        return true;
    };
    SubheaderComponent.prototype.addHourse = function (date, hours) {
        date.setTime(date.getTime() + (hours * 60 * 60 * 1000));
        return date;
    };
    SubheaderComponent.prototype.saveSprint = function (sprint) {
        var _this = this;
        if (this.validateSprint(sprint)) {
            this.ngxLoader.start();
            var tempPic = [{
                    "key": this.userService.getUserID(),
                    "val": this.userService.getUsername() + "-" + this.userService.getName(),
                    "userid": this.userService.getUserID(),
                    "username": this.userService.getUsername()
                }];
            // this.task.pic.forEach(element => {
            //   const tempFilter = this.listEmployee.filter(ter => ter.userid === element.value)[0];
            //   if (tempFilter) {
            //     tempPic.push(tempFilter);
            //   }
            // });
            var tempDate = [new Date(), new Date(new Date().getTime() + (28 * 24 * 60 * 60 * 1000))];
            var date = [];
            for (var index = 0; index < tempDate.length; index++) {
                var element = tempDate[index];
                var dd = element.getDate();
                var mm = element.getMonth() + 1;
                var yyyy = element.getFullYear();
                date[index] = yyyy + '-' + mm + '-' + dd;
            }
            var param = {
                hashParent: this.hash,
                nama_item: this.sprint.nama_item,
                pic: tempPic,
                desc_item: "",
                category: [{
                        value: this.sprint.category
                    }],
                tipe: 'General',
                occurance: 'Once',
                priority: '3',
                weight: 1,
                access: 'Private',
                duration: this.sprint.duration
            };
            this.pmcService.saveSprint(param).subscribe(function (response) {
                _this.ngxLoader.stop();
                if (response.status) {
                    _this.sprintModal.close();
                    _this.pmcService.reloadDataEmit.next(true);
                    return;
                }
                else {
                    //this.sprintModal.close();
                    Swal.fire(response.message);
                }
            }, function () {
                _this.ngxLoader.stop();
                Swal.fire('Not Authorized');
            });
            this.ngxLoader.stop();
        }
    };
    SubheaderComponent.prototype.validate = function (form_item) {
        var value = form_item;
        if (value.nama_item === '' || !(value.pic.length > 0)) {
            this.ngxLoader.stop();
            this.formService.errorValidation('Gagal Menyimpan.', 'Lengkapi semua isian yang bertanda *.');
            return false;
        }
        return true;
    };
    SubheaderComponent.prototype.notifyMe = function () {
        // Let's check if the browser supports notifications
        if (!('Notification' in window)) {
            alert('This browser does not support desktop notification\nPlease use other browser\nThank you');
            // Let's check whether notification permissions have already been granted
        }
        else if (Notification.permission === 'granted') {
            // If it's okay let's create a notification
            this.reloadIndexedDb();
            // Otherwise, we need to ask the user for permission
        }
        else if (Notification.permission !== 'denied') {
            Notification.requestPermission().then(function (permission) {
                // If the user accepts, let's create a notification
                if (permission === 'granted') {
                    this.reloadIndexedDb();
                }
            });
        }
        // At last, if the user has denied notifications,
        // we can do nothing.
    };
    SubheaderComponent.prototype.reloadIndexedDb = function () {
        var _this = this;
        var userId = this.userService.getUsername();
        this.messagingService.requestPermission(userId, true);
        setTimeout(function () {
            // console.log('hi', this.messagingService.currentToken);
            _this.authService.reloadIdb({ username: userId, fcm_token: _this.messagingService.currentToken, old_token: null }).subscribe(function (arg) {
                arg.status ? _this.deleteDB() : console.log(arg.message);
            }, function (error) {
                console.log(error);
                // Swal.fire(error.error.message);
            });
        }, 10000);
    };
    SubheaderComponent.prototype.deleteDB = function () {
        console.log('Database has been Reloaded');
        deleteDB('DatabaseNds'); // Clear IndexedDB
        location.reload();
    };
    SubheaderComponent.prototype.enableIDBToggle = function () {
        this.enableIndexedDB = !this.enableIndexedDB;
        this.vacancyService.enableidbEmit.next(this.enableIndexedDB);
    };
    SubheaderComponent.prototype.enabledSidebar = function () {
        this.employeeService.mobileToggleEmit.next(!this.enableIndexedDB);
    };
    SubheaderComponent.prototype.newTimesheet = function () {
        this.timesheetService.newTimesheetEmit.next(true);
    };
    SubheaderComponent.prototype.setApproval = function (aksi) {
        var _this = this;
        this.ngxLoader.start();
        var param = { hash: this.hash, action: aksi };
        this.simcardService.actionApproval(param).subscribe(function (response) {
            if (response.status) {
                Swal.fire(response.message);
                _this.ngxLoader.stop();
                _this.router.navigate(['/main/simcard/prepaid/my-approval']);
            }
        }, function (error) {
            _this.ngxLoader.stop();
            Swal.fire(error.message);
        });
    };
    SubheaderComponent.prototype.approveAll = function (aksi) {
        var _this = this;
        this.ngxLoader.start();
        this.simcardService.approveAll({ action: aksi, listIdMaster: this.listItemChecker }).subscribe(function (response) {
            if (response.result) {
                _this.ngxLoader.stop();
                _this.router.navigate(['/main/simcard/prepaid/my-approval']);
            }
            else {
                _this.ngxLoader.stop();
                Swal.fire(response.message);
            }
        }, function (error) {
            _this.ngxLoader.stop();
            Swal.fire(error.message);
        });
    };
    return SubheaderComponent;
}());
export { SubheaderComponent };
