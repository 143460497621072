import { HttpClient, HttpHeaders } from '@angular/common/http';
import { GlobalService } from './global.service';
import { throwError, Subject, forkJoin } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { CookieService } from 'ngx-cookie-service';
import Swal from 'sweetalert2';
import { AlertModalComponent } from '../layout/shared/alert-modal/alert-modal.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "./global.service";
import * as i3 from "ngx-cookie-service";
import * as i4 from "@ng-bootstrap/ng-bootstrap";
var API_EMPLOYEES = '/employee/hrd/get-users';
var API_EDIT_EMPLOYEE = '/employee/hrd/edit-user';
var API_GET_REMINDER = '/employee/users/get-reminder';
var API_GET_APPROVAL_EMPLOYEE = '/employee/users/list-approval-employee';
var API_GET_DETAIL_APPROVAL = '/employee/users/detail-approval';
var API_APPROVE_UPDATE_DATA = '/employee/hrd/approve-update-data';
var API_ALL_LOCATION = '/employee/users/get-all-location';
var API_LIST_PCODE = '/employeeAdmin/dev/get-list-projectcode';
var API_LIST_PCODE_SELECT2 = '/employeeAdmin/dev/get-list-projectcode-select2';
var API_LIST_CV = '/employeeAdmin/dev/list-cvby-projectcode';
var API_CHANGE_PASSWORD = '/employeeProfile/profile/change-password';
var API_ALL_EMP_EXPORT_EXCEL = '/employeeAdmin/dev/all-emp-export-excel';
var API_ALL_OFFICE = '/employeeAdmin/dev/get-all-office';
var API_GET_OFFICE = '/employeeAdmin/dev/get-office';
var API_UPDATE_OFFICE = '/employeeAdmin/dev/update-office';
var API_ACTIVE_OFFICE = '/employeeAdmin/dev/active-office';
var API_NEW_OFFICE = '/employeeAdmin/dev/create-office';
var API_CANCEL_JOIN = '/employee/hrd/cancel-join';
var API_LIST_HISTORY = '/employee/hrd/list-history';
var API_FIND_EMPLOYEE = '/employee/data/find';
var API_PREPARE_EMAIL_FORM = '/email/prepare-form';
var API_HR_ACHIEVEMENT = '/employee/hrd/hr-achievement';
var API_LIST_HR_ACHIEVEMENT = '/employee/hrd/list-hr-achievement';
var API_GET_DOCUMENT = '/employee/data/get-document';
// new employee movement api
var API_GET_EMPLOYEE_MOV = '/employee/default/get-employee-mov';
var API_SUBMIT = '/employee/default/submit';
var API_RELEASE_TICKET = '/employee/default/release-ticket';
//field ops productivity
var API_GET_DATA_FIELD_OPS_PROD = '/productivity/productivity/get-data';
var API_EXPORT_DATA_FIELD_OPS_PROD = '/productivity/productivity/export-data';
var API_GET_DATA_VACCINE = '/employee/hrd/vaccine-data';
var API_EXPORT_DATA_VACCINE = '/employee/hrd/export-vaccine-data';
var API_GET_EMPLOYEE_PROFILE = '/employee/hrd/get-employee-profile';
var API_GET_ACTIVE_USERS = "/employee/hrd/get-active-users";
var API_GET_RESIGN_REASON = "/employee/hrd/get-resign-reason";
// NEW API MOVEMENT
var API_GET_MOVEMENT_DATA = '/movement/movement/get-movement-data';
var API_GET_PIC_MOVEMENT = '/movement/movement/get-pic-movement';
var API_SUBMIT_MOVEMENT = '/movement/movement/submit-movement';
var API_GET_MOVEMENT_TRACKER = '/movement/movement/get-movement-tracker';
var API_GET_MY_APPROVAL_MOVEMENT = '/movement/movement/get-my-approval-movement';
var API_GET_STEP_MOVEMENT = '/movement/movement/get-step-movement';
var API_CANCEL_MOVEMENT = '/movement/movement/cancel-movement';
var API_GET_DETAIL_MOVEMENT = '/movement/movement/get-detail-movement';
var API_APPROVE_MOVEMENT = '/movement/movement/approve-movement';
var API_RELEASE_TICKET_MOVEMENT = '/movement/movement/release-ticket-movement';
var EmployeeService = /** @class */ (function () {
    function EmployeeService(http, globalService, cookieService, modalService) {
        this.http = http;
        this.globalService = globalService;
        this.cookieService = cookieService;
        this.modalService = modalService;
        this.mobileToggleEmit = new Subject();
        this.mobileToggleEmitObs = this.mobileToggleEmit.asObservable();
        this.loadBankAccountEmit = new Subject();
        this.loadBankAccountEmitObs = this.loadBankAccountEmit.asObservable();
        this.mobileToggleEmit.next(false);
        this.loadBankAccountEmit.next(false);
    }
    EmployeeService.prototype.header = function (module) {
        if (module === void 0) { module = 'employee'; }
        return {
            headers: new HttpHeaders()
                .set('token', this.cookieService.get('_q'))
                .set('module', localStorage.getItem(module))
                .set('group', this.cookieService.get('_g'))
        };
    };
    EmployeeService.prototype.alertModal = function (err, code) {
        if (code === 403) {
            return Swal.fire(err);
        }
        var modalRef = this.modalService.open(AlertModalComponent);
        modalRef.componentInstance.kategori = 'alert';
        modalRef.componentInstance.error = (err) ? err : 'Request Timeout.';
    };
    EmployeeService.prototype.getActiveUsers = function () {
        return this.http.post(this.globalService.apiVersionHost + API_GET_ACTIVE_USERS, {}, this.header()).pipe(map(function (response) { return response; }));
    };
    EmployeeService.prototype.getResignReason = function (params) {
        return this.http.post(this.globalService.apiVersionHost + API_GET_RESIGN_REASON, params, this.header()).pipe(map(function (response) { return response; }));
    };
    EmployeeService.prototype.submitResignEmployee = function (dataForm) {
        return this.http.post(this.globalService.apiVersionHost + '/employee/hrd/submit-resign', dataForm, this.header());
    };
    EmployeeService.prototype.getHrAchievement = function (params) {
        return this.http.post(this.globalService.apiVersionHost + API_HR_ACHIEVEMENT, params, this.header()).pipe(map(function (response) { return response; }));
    };
    EmployeeService.prototype.getListHrAchievement = function (params) {
        return this.http.post(this.globalService.apiVersionHost + API_LIST_HR_ACHIEVEMENT, params, this.header()).pipe(map(function (response) { return response; }));
    };
    EmployeeService.prototype.getAllOffice = function (param) {
        return this.http.post(this.globalService.apiVersionHost + API_ALL_OFFICE, param, this.header('employeeAdmin'))
            .pipe(map(function (response) { return response; }));
    };
    EmployeeService.prototype.listHistory = function (param) {
        return this.http
            .post(this.globalService.apiVersionHost + API_LIST_HISTORY, param, this.header())
            .pipe(map(function (response) { return response; }));
    };
    EmployeeService.prototype.getOffice = function (param) {
        return this.http
            .post(this.globalService.apiVersionHost + API_GET_OFFICE, param, this.header('employeeAdmin'))
            .pipe(map(function (response) { return response; }));
    };
    EmployeeService.prototype.newOffice = function (param) {
        return this.http
            .post(this.globalService.apiVersionHost + API_NEW_OFFICE, param, this.header('employeeAdmin'))
            .pipe(map(function (response) { return response; }));
    };
    EmployeeService.prototype.setActiveOffice = function (param) {
        return this.http
            .post(this.globalService.apiVersionHost + API_ACTIVE_OFFICE, param, this.header('employeeAdmin'))
            .pipe(map(function (response) { return response; }));
    };
    EmployeeService.prototype.updateOffice = function (param) {
        return this.http
            .post(this.globalService.apiVersionHost + API_UPDATE_OFFICE, param, this.header('employeeAdmin'))
            .pipe(map(function (response) { return response; }));
    };
    EmployeeService.prototype.getEmployees = function (param) {
        return this.http
            .post(this.globalService.apiVersionHost + API_EMPLOYEES, param, this.header())
            .pipe(map(function (response) { return response; }));
    };
    EmployeeService.prototype.listAll = function (param) {
        return this.http
            .post(this.globalService.apiVersionHost + '/employeeAdmin/dev/list-all', param, this.header('employeeAdmin'))
            .pipe(map(function (response) { return response; }));
    };
    EmployeeService.prototype.historyListAll = function (param) {
        return this.http
            .post(this.globalService.apiVersionHost + '/employee/hrd/history-list-all', param, this.header())
            .pipe(map(function (response) { return response; }));
    };
    EmployeeService.prototype.getEmployeeById = function (params) {
        return this.http.post(this.globalService.apiVersionHost + '/employee/users/get-user-by-id', params, this.header());
    };
    // public getResignReason(params: {
    //   is_blacklisted: boolean;
    // }): Observable<ResponseApiModel> {
    //   return this.http.post<ResponseApiModel>(
    //     this.globalService.apiVersionHost + '/employee/users/get-resign-reason',
    //     params,
    //     this.header()
    //   );
    // }
    EmployeeService.prototype.discResult = function (params) {
        return this.http.post(this.globalService.apiVersionHost + '/employee/hrd/disc-result', params, this.header());
    };
    EmployeeService.prototype.generateDiscResult = function (params) {
        return this.http.post(this.globalService.apiVersionHost + '/employee/hrd/generate-disc', params, this.header());
    };
    EmployeeService.prototype.generateCorruptionStatement = function (params) {
        return this.http.post(this.globalService.apiVersionHost + '/employee/hrd/generate-cs', params, this.header());
    };
    EmployeeService.prototype.generateEhsStatement = function (params) {
        return this.http.post(this.globalService.apiVersionHost + '/employee/hrd/generate-ehss', params, this.header());
    };
    EmployeeService.prototype.generateCyberSecurityStatement = function (params) {
        return this.http.post(this.globalService.apiVersionHost + '/employee/hrd/generate-css', params, this.header());
    };
    EmployeeService.prototype.EmployeeReq = function (params) {
        return this.http.post(this.globalService.apiVersionHost + '/employee/hrd/employee-request', params, this.header());
    };
    EmployeeService.prototype.EmployeeReqUserList = function () {
        return this.http.get(this.globalService.apiVersionHost + '/employee/hrd/employee-request-user-list', this.header());
    };
    EmployeeService.prototype.EmployeeReqUserListSelect2 = function (param) {
        return this.http.post(this.globalService.apiVersionHost + '/employee/hrd/employee-request-user-list-select2', param, this.header());
    };
    EmployeeService.prototype.resignEmployee = function (params) {
        return this.http.post(this.globalService.apiVersionHost + '/employee/hrd/resign', params, this.header());
    };
    EmployeeService.prototype.employeeVsTaskRemark = function (params) {
        return this.http.post(this.globalService.apiVersionHost + '/employee/users/employeevsassetremark', params, this.header());
    };
    EmployeeService.prototype.inputResult = function (params) {
        return this.http.post(this.globalService.apiVersionHost + '/employee/hrd/inputresult', params, this.header());
    };
    EmployeeService.prototype.getDetail = function (params) {
        return this.http.post(this.globalService.apiVersionHost + '/employee/hrd/detail', params, this.header());
        // .pipe(
        //   retry(1),
        //   catchError(this.handleError)
        // )
    };
    EmployeeService.prototype.getDataVacancies = function (params) {
        return this.http.post(this.globalService.apiVersionHost + '/employee/hrd/get-data-vacancies', params, this.header());
        // .pipe(
        //   retry(1),
        //   catchError(this.handleError)
        // )
    };
    EmployeeService.prototype.getPositionVacancy = function (params) {
        return this.http.post(this.globalService.apiVersionHost + '/employee/hrd/get-position-by-vacancy', params, this.header());
        // .pipe(
        //   retry(1),
        //   catchError(this.handleError)
        // )
    };
    EmployeeService.prototype.getGetDataApply = function (params) {
        return this.http.post(this.globalService.apiVersionHost + '/employee/hrd/get-data-apply', params, this.header());
    };
    EmployeeService.prototype.addToApply = function (params) {
        return this.http.post(this.globalService.apiVersionHost + '/employee/hrd/add-to-vacancy', params, this.header());
    };
    EmployeeService.prototype.deleteApply = function (params) {
        return this.http.post(this.globalService.apiVersionHost + '/employee/hrd/delete-apply', params, this.header());
    };
    EmployeeService.prototype.addProjectCodeTemp = function (param) {
        return this.http
            .post(this.globalService.apiVersionHost + '/employee/hrd/add-project-temp', param, this.header())
            .pipe(map(function (response) { return response; }), catchError(function (err) {
            return throwError(err);
        }));
    };
    EmployeeService.prototype.deleteProjectCodeTemp = function (param) {
        return this.http
            .post(this.globalService.apiVersionHost +
            '/employee/hrd/delete-project-temp', param, this.header())
            .pipe(map(function (response) { return response; }), catchError(function (err) {
            return throwError(err);
        }));
    };
    EmployeeService.prototype.getListEmployeeByRegion = function () {
        return this.http
            .get(this.globalService.apiVersionHost +
            '/employee/users/getlistemployeebyregion', this.header())
            .pipe(map(function (response) { return response; }), catchError(function (err) {
            return throwError(err);
        }));
    };
    EmployeeService.prototype.getViewfull = function (id) {
        return this.http.get(this.globalService.apiVersionHost + '/employeeAdmin/dev/viewfull?id=' + id, this.header('employeeAdmin'));
        // .pipe(
        //   retry(1),
        //   catchError(this.handleError)
        // )
    };
    EmployeeService.prototype.getEditfull = function (id) {
        return this.http.get(this.globalService.apiVersionHost + '/employeeAdmin/dev/editfull?id=' + id, this.header('employeeAdmin'));
        // .pipe(
        //   retry(1),
        //   catchError(this.handleError)
        // )
    };
    EmployeeService.prototype.prepareEditFull = function (id) {
        return this.http.post(this.globalService.apiVersionHost + '/employeeAdmin/dev/prepare-edit-full?id=' + id, null, this.header('employeeAdmin'));
    };
    // editFullSubmit
    EmployeeService.prototype.editFullSubmit = function (id, param) {
        console.log('param', param);
        return this.http
            .post(this.globalService.apiVersionHost + '/employeeAdmin/dev/editfull?id=' + id, param, this.header('employeeAdmin'))
            .pipe(map(function (response) { return response; }), catchError(function (err) {
            return throwError(err);
        }));
    };
    EmployeeService.prototype.movementSubmit = function (params) {
        return this.http
            .post(this.globalService.apiVersionHost + '/employee/hrd/movement', params, this.header())
            .pipe(map(function (response) { return response; }), catchError(function (err) {
            return throwError(err);
        }));
    };
    EmployeeService.prototype.getListProjectCode = function () {
        return this.http.get(this.globalService.apiVersionHost +
            '/employee/users/get-list-projectcode', this.header());
        // .pipe(
        //   retry(1),
        //   catchError(this.handleError)
        // )
    };
    EmployeeService.prototype.getListProjectCodeSelect2 = function () {
        return this.http.get(this.globalService.apiVersionHost +
            '/employee/users/get-list-projectcode-select2', this.header());
        // .pipe(
        //   retry(1),
        //   catchError(this.handleError)
        // )
    };
    EmployeeService.prototype.listSelectAddEmployee = function () {
        return this.http.get(this.globalService.apiVersionHost +
            '/employee/hrd/list-select-add-employee', this.header());
        // .pipe(
        //   retry(1),
        //   catchError(this.handleError)
        // )
    };
    EmployeeService.prototype.getUserProjectCodeTemp = function (id) {
        return this.http.get(this.globalService.apiVersionHost +
            '/employee/users/getusersprojectcodetemp?id=' +
            id, this.header());
        // .pipe(
        //   retry(1),
        //   catchError(this.handleError)
        // )
    };
    EmployeeService.prototype.getUsersProjectCodeTempWithoutDelete = function (id) {
        if (id === void 0) { id = null; }
        return this.http.get(this.globalService.apiVersionHost +
            '/employee/users/getusersprojectcodetempwithoutdelete?id=' +
            id, this.header());
        // .pipe(
        //   retry(1),
        //   catchError(this.handleError)
        // )
    };
    EmployeeService.prototype.getResignList = function (param) {
        return this.http
            .post(this.globalService.apiVersionHost + '/employee/hrd/get-resign-list', param, this.header())
            .pipe(map(function (response) { return response; }), catchError(function (err) {
            return throwError(err);
        }));
    };
    EmployeeService.prototype.getNoticePeriodList = function (param) {
        return this.http.post(this.globalService.apiVersionHost + '/employee/hrd/get-notice-period-list', param, this.header()).pipe(map(function (response) { return response; }), catchError(function (err) {
            return throwError(err);
        }));
    };
    EmployeeService.prototype.getMyNoticePeriod = function (param) {
        return this.http.post(this.globalService.apiVersionHost + '/employee/default/get-my-notice-period', param, this.header()).pipe(map(function (response) { return response; }), catchError(function (err) {
            return throwError(err);
        }));
    };
    EmployeeService.prototype.getCancelResignList = function (param) {
        return this.http
            .post(this.globalService.apiVersionHost +
            '/employee/hrd/cancel-resign-list', param, this.header())
            .pipe(map(function (response) { return response; }), catchError(function (err) {
            return throwError(err);
        }));
    };
    EmployeeService.prototype.getHistoryMovement = function (param) {
        return this.http
            .post(this.globalService.apiVersionHost +
            '/employee/hrd/get-history-movement', param, this.header())
            .pipe(map(function (response) { return response; }), catchError(function (err) {
            return throwError(err);
        }));
    };
    EmployeeService.prototype.getUsersHistory = function (param) {
        return this.http
            .post(this.globalService.apiVersionHost + '/employee/hrd/get-users-history', param, this.header())
            .pipe(map(function (response) { return response; }), catchError(function (err) {
            return throwError(err);
        }));
    };
    EmployeeService.prototype.CancelResign = function (param) {
        return this.http
            .post(this.globalService.apiVersionHost + '/employeeAdmin/dev/cancel-resign', param, this.header('employeeAdmin'))
            .pipe(map(function (response) { return response; }), catchError(function (err) {
            return throwError(err);
        }));
    };
    EmployeeService.prototype.CancelResignFromNotice = function (param) {
        return this.http.post(this.globalService.apiVersionHost + '/employee/hrd/cancel-resign-from-notice', param, this.header()).pipe(map(function (response) { return response; }), catchError(function (err) {
            return throwError(err);
        }));
    };
    EmployeeService.prototype.getListProject = function () {
        return this.http.get(this.globalService.apiVersionHost + '/employee/users/getlistproject', this.header());
    };
    EmployeeService.prototype.getListNWAssignment = function () {
        return this.http.get(this.globalService.apiVersionHost + '/employee/users/getlistnwassignment', this.header());
    };
    //
    EmployeeService.prototype.getListPosition = function () {
        return this.http.get(this.globalService.apiVersionHost + '/employee/users/getlistposition', this.header());
    };
    EmployeeService.prototype.getListPm = function () {
        return this.http.get(this.globalService.apiVersionHost + '/employee/users/getlistpm', this.header());
    };
    EmployeeService.prototype.getListAtasan = function () {
        return this.http.get(this.globalService.apiVersionHost + '/employee/default/list-atasan', this.header()).pipe(map(function (response) { return response; }), catchError(function (err) {
            return throwError(err);
        }));
    };
    EmployeeService.prototype.getListAtasanSelect2 = function () {
        return this.http.get(this.globalService.apiVersionHost + '/employee/default/list-atasan-select2', this.header()).pipe(map(function (response) { return response; }), catchError(function (err) {
            return throwError(err);
        }));
    };
    EmployeeService.prototype.getDataMovement = function (params) {
        return this.http.post(this.globalService.apiVersionHost + '/employee/hrd/get-movement-data', params, this.header());
    };
    EmployeeService.prototype.getEmpMovement = function (params) {
        return this.http.post(this.globalService.apiVersionHost + '/employee/hrd/get-employee-movement', params, this.header());
    };
    EmployeeService.prototype.rejectMovement = function (params) {
        return this.http.post(this.globalService.apiVersionHost + '/employee/hrd/reject-movement', params, this.header()).pipe(map(function (response) { return response; }));
    };
    EmployeeService.prototype.getListNamaPm = function () {
        return this.http.post(this.globalService.apiVersionHost + '/employee/users/get-list-nama-pm', null, this.header());
    };
    EmployeeService.prototype.getUserById = function (param) {
        return this.http
            .post(this.globalService.apiVersionHost + '/employee/users/get-user-by-id', param, this.header())
            .pipe(map(function (response) { return response; }));
    };
    EmployeeService.prototype.getListNamaTl = function () {
        return this.http.post(this.globalService.apiVersionHost + '/employee/users/get-list-nama-tl', null, this.header());
    };
    EmployeeService.prototype.prepareEmailForm = function () {
        return this.http.post(this.globalService.apiVersionHost + API_PREPARE_EMAIL_FORM, null, this.header()).pipe(map(function (response) { return response; }));
    };
    EmployeeService.prototype.getListNamaEmployee = function () {
        return this.http.post(this.globalService.apiVersionHost + '/employee/users/get-list-nama-employee', null, this.header()).pipe(map(function (response) { return response; }));
    };
    EmployeeService.prototype.getListNamaEmployeeSelect2 = function () {
        return this.http.post(this.globalService.apiVersionHost + '/employee/users/get-list-nama-employee-select2', null, this.header()).pipe(map(function (response) { return response; }));
    };
    EmployeeService.prototype.getNamaEmployeeV2 = function (action) {
        return this.http.post(this.globalService.apiVersionHost + '/employee/users/get-list-nama-employee', { action: action }, this.header());
    };
    EmployeeService.prototype.getListRegion = function () {
        return this.http.get(this.globalService.apiVersionHost + '/employee/users/getlistregion', this.header());
    };
    EmployeeService.prototype.collectedResponse = function () {
        var request1 = this.getNwAssignmentMitraKerja();
        var request2 = this.getPositionMitraKerja();
        var request3 = this.getListAtasanSelect2();
        var request4 = this.getListRegion();
        return forkJoin([request1, request2, request3, request4]);
    };
    EmployeeService.prototype.getAllRegion = function () {
        return this.http.get(this.globalService.apiVersionHost + '/employee/users/get-all-region', this.header());
    };
    EmployeeService.prototype.getListTl = function (pm) {
        return this.http.get(this.globalService.apiVersionHost + '/employee/users/getlisttl?pm=' + pm, this.header());
    };
    EmployeeService.prototype.getListTlMov = function () {
        return this.http.get(this.globalService.apiVersionHost + '/employee/users/getlisttl-mov', this.header());
    };
    EmployeeService.prototype.getListLocation = function (location) {
        return this.http.get(this.globalService.apiVersionHost +
            '/employee/users/getlistlocation?location=' +
            location, this.header());
    };
    EmployeeService.prototype.getListLocationMov = function () {
        return this.http.get(this.globalService.apiVersionHost +
            '/employee/users/getlistlocation-mov', this.header());
    };
    EmployeeService.prototype.updateEmployee = function (param) {
        return this.http
            .post(this.globalService.apiVersionHost + API_EDIT_EMPLOYEE, param, this.header())
            .pipe(map(function (response) { return response; }), catchError(this.handleError));
    };
    EmployeeService.prototype.updateProfile = function (params) {
        return this.http
            .post(this.globalService.apiVersionHost + '/employee/users/update-profile', params, this.header())
            .pipe(map(function (response) { return response; }), catchError(this.handleError));
    };
    EmployeeService.prototype.updateVaccine = function (params) {
        return this.http
            .post(this.globalService.apiVersionHost + '/employeeProfile/profile/save-vaccine', params, this.header('employeeProfile'))
            .pipe(map(function (response) { return response; }), catchError(this.handleError));
    };
    EmployeeService.prototype.saveProject = function (params) {
        return this.http
            .post(this.globalService.apiVersionHost + '/employee/hrd/save-project', params, this.header())
            .pipe(map(function (response) { return response; }), catchError(this.handleError));
    };
    EmployeeService.prototype.importUserProject = function (params) {
        return this.http
            .post(this.globalService.apiVersionHost + '/employee/hrd/import-user-project', params, this.header())
            .pipe(map(function (response) { return response; }), catchError(this.handleError));
    };
    EmployeeService.prototype.cekUsername = function (params) {
        return this.http
            .post(this.globalService.apiVersionHost + '/employee/users/cek-username', params, this.header())
            .pipe(map(function (response) { return response; }), catchError(this.handleError));
    };
    EmployeeService.prototype.cekEmailAddress = function (params) {
        return this.http
            .post(this.globalService.apiVersionHost + '/employee/users/cek-email', params, this.header())
            .pipe(map(function (response) { return response; }), catchError(this.handleError));
    };
    EmployeeService.prototype.getTlByPm = function (params) {
        return this.http
            .post(this.globalService.apiVersionHost + '/employee/users/show-tl-by-pm', params, this.header())
            .pipe(map(function (response) { return response; }), catchError(this.handleError));
    };
    EmployeeService.prototype.getLocationByRegion = function (params) {
        return this.http
            .post(this.globalService.apiVersionHost +
            '/employee/users/show-location-by-region', params, this.header())
            .pipe(map(function (response) { return response; }), catchError(this.handleError));
    };
    EmployeeService.prototype.getLocationPic = function (params) {
        return this.http
            .post(this.globalService.apiVersionHost +
            '/employee/users/show-location-pic', params, this.header())
            .pipe(map(function (response) { return response; }), catchError(this.handleError));
    };
    EmployeeService.prototype.addEmployee = function (params) {
        return this.http
            .post(this.globalService.apiVersionHost + '/employee/hrd/add-employee', params, this.header())
            .pipe(map(function (response) { return response; }), catchError(this.handleError));
    };
    EmployeeService.prototype.submitPmUpdate = function (params) {
        return this.http
            .post(this.globalService.apiVersionHost + '/employee/hrd/submit-pm-update', params, this.header())
            .pipe(map(function (response) { return response; }), catchError(this.handleError));
    };
    EmployeeService.prototype.submitPmMovement = function (params) {
        return this.http
            .post(this.globalService.apiVersionHost +
            '/employee/hrd/submit-pm-movement', params, this.header())
            .pipe(map(function (response) { return response; }), catchError(this.handleError));
    };
    EmployeeService.prototype.submitTlUpdate = function (params) {
        return this.http
            .post(this.globalService.apiVersionHost + '/employee/hrd/submit-tl-update', params, this.header())
            .pipe(map(function (response) { return response; }), catchError(this.handleError));
    };
    EmployeeService.prototype.submitTlMovement = function (params) {
        return this.http
            .post(this.globalService.apiVersionHost +
            '/employee/hrd/submit-tl-movement', params, this.header())
            .pipe(map(function (response) { return response; }), catchError(this.handleError));
    };
    EmployeeService.prototype.uploadFile = function (params) {
        return this.http
            .post(this.globalService.apiVersionHost + '/employee/users/upload-file', params, this.header())
            .pipe(map(function (response) { return response; }), catchError(this.handleError));
    };
    EmployeeService.prototype.deleteProjectTemp = function (param) {
        return this.http
            .post(this.globalService.apiVersionHost +
            '/employee/hrd/delete-project-temp', param, this.header())
            .pipe(map(function (response) { return response; }), catchError(this.handleError));
    };
    EmployeeService.prototype.getProjectcode = function (id) {
        return null;
    };
    EmployeeService.prototype.handleError = function (error) {
        var errorMessage = '';
        if (error.error instanceof ErrorEvent) {
            // Get client-side error
            errorMessage = error.error.message;
        }
        else {
            // Get server-side error
            errorMessage = "Error Code: " + error.status + "\nMessage: " + error.message;
        }
        window.alert(errorMessage);
        return throwError(errorMessage);
    };
    EmployeeService.prototype.getListBankAccount = function (param) {
        return this.http
            .post(this.globalService.apiVersionHost +
            '/payslip/bank-account/get-data', param, this.header('payslip'))
            .pipe(map(function (response) { return response; }), catchError(function (err) {
            return throwError(err);
        }));
    };
    EmployeeService.prototype.viewBank = function (param) {
        return this.http
            .post(this.globalService.apiVersionHost + '/payslip/bank-account/view-data', param, this.header('payslip'))
            .pipe(map(function (response) { return response; }), catchError(function (err) {
            return throwError(err);
        }));
    };
    EmployeeService.prototype.editBank = function (param) {
        return this.http
            .post(this.globalService.apiVersionHost + '/payslip/bank-account/edit-data', param, this.header('payslip'))
            .pipe(map(function (response) { return response; }), catchError(function (err) {
            return throwError(err);
        }));
    };
    EmployeeService.prototype.getListMtdw = function (param) {
        return this.http
            .post(this.globalService.apiVersionHost + '/employee/hrd/list-mtdw', param, this.header())
            .pipe(map(function (response) { return response; }), catchError(function (err) {
            return throwError(err);
        }));
    };
    EmployeeService.prototype.getListMk = function (param) {
        return this.http
            .post(this.globalService.apiVersionHost + '/employee/hrd/list-mk', param, this.header())
            .pipe(map(function (response) { return response; }), catchError(function (err) {
            return throwError(err);
        }));
    };
    EmployeeService.prototype.getNwAssignmentResign = function (param) {
        return this.http
            .post(this.globalService.apiVersionHost +
            '/employee/hrd/nw-assignment-from-resign', param, this.header())
            .pipe(map(function (response) { return response; }), catchError(function (err) {
            return throwError(err);
        }));
    };
    EmployeeService.prototype.getNwAssignmentSelect2 = function (param) {
        return this.http
            .post(this.globalService.apiVersionHost +
            '/employee/hrd/get-nw-assignment-select2', param, this.header())
            .pipe(map(function (response) { return response; }), catchError(function (err) {
            return throwError(err);
        }));
    };
    EmployeeService.prototype.getNwAssignmentApplicant = function () {
        return this.http
            .get(this.globalService.apiVersionHost +
            '/employee/hrd/nw-assignment-from-applicant', this.header())
            .pipe(map(function (response) { return response; }), catchError(function (err) {
            return throwError(err);
        }));
    };
    EmployeeService.prototype.getNwAssignmentMtdw = function () {
        return this.http
            .get(this.globalService.apiVersionHost +
            '/employee/users/nw-assignment-mtdw', this.header())
            .pipe(map(function (response) { return response; }), catchError(function (err) {
            return throwError(err);
        }));
    };
    EmployeeService.prototype.getNwAssignmentMitraKerja = function () {
        return this.http
            .get(this.globalService.apiVersionHost +
            '/employee/users/nw-assignment-mitra-kerja', this.header())
            .pipe(map(function (response) { return response; }), catchError(function (err) {
            return throwError(err);
        }));
    };
    EmployeeService.prototype.getPositionMtdw = function () {
        return this.http
            .get(this.globalService.apiVersionHost + '/employee/users/position-mtdw', this.header())
            .pipe(map(function (response) { return response; }), catchError(function (err) {
            return throwError(err);
        }));
    };
    EmployeeService.prototype.getPositionMitraKerja = function () {
        return this.http
            .get(this.globalService.apiVersionHost + '/employee/users/position-mitra-kerja', this.header())
            .pipe(map(function (response) { return response; }), catchError(function (err) {
            return throwError(err);
        }));
    };
    EmployeeService.prototype.getPositionResign = function (param) {
        return this.http
            .post(this.globalService.apiVersionHost + '/employee/hrd/position-from-resign', param, this.header())
            .pipe(map(function (response) { return response; }), catchError(function (err) {
            return throwError(err);
        }));
    };
    EmployeeService.prototype.getPositionApplicant = function () {
        return this.http
            .get(this.globalService.apiVersionHost + '/employee/hrd/position-from-applicant', this.header())
            .pipe(map(function (response) { return response; }), catchError(function (err) {
            return throwError(err);
        }));
    };
    EmployeeService.prototype.getPositionSelect2 = function (param) {
        return this.http
            .post(this.globalService.apiVersionHost + '/employee/hrd/get-position-select2', param, this.header())
            .pipe(map(function (response) { return response; }), catchError(function (err) {
            return throwError(err);
        }));
    };
    EmployeeService.prototype.saveMtdw = function (param) {
        return this.http
            .post(this.globalService.apiVersionHost + '/employee/hrd/save-mtdw', param, this.header())
            .pipe(map(function (response) { return response; }), catchError(function (err) {
            return throwError(err);
        }));
    };
    EmployeeService.prototype.saveMk = function (param) {
        return this.http
            .post(this.globalService.apiVersionHost + '/employee/hrd/save-mk', param, this.header())
            .pipe(map(function (response) { return response; }), catchError(function (err) {
            return throwError(err);
        }));
    };
    EmployeeService.prototype.getUpdateMtdw = function (id) {
        return this.http
            .post(this.globalService.apiVersionHost + '/employee/hrd/update-mtdw', { id: id }, this.header())
            .pipe(map(function (response) { return response; }), catchError(function (err) {
            return throwError(err);
        }));
    };
    EmployeeService.prototype.saveUpdateMtdw = function (param) {
        return this.http
            .post(this.globalService.apiVersionHost + '/employee/hrd/save-update-mtdw', param, this.header())
            .pipe(map(function (response) { return response; }), catchError(function (err) {
            return throwError(err);
        }));
    };
    EmployeeService.prototype.saveUpdateLastDay = function (param) {
        return this.http
            .post(this.globalService.apiVersionHost + '/employee/hrd/save-update-last-day', param, this.header())
            .pipe(map(function (response) { return response; }), catchError(function (err) {
            return throwError(err);
        }));
    };
    EmployeeService.prototype.convertToMtdw = function (param) {
        return this.http
            .post(this.globalService.apiVersionHost + '/employee/hrd/convert-to-mtdw', param, this.header())
            .pipe(map(function (response) { return response; }), catchError(function (err) {
            return throwError(err);
        }));
    };
    EmployeeService.prototype.convertToMk = function (param) {
        return this.http
            .post(this.globalService.apiVersionHost + '/employee/hrd/convert-to-mk', param, this.header())
            .pipe(map(function (response) { return response; }), catchError(function (err) {
            return throwError(err);
        }));
    };
    EmployeeService.prototype.toInternal = function (param) {
        return this.http
            .post(this.globalService.apiVersionHost + '/employee/hrd/to-internal', param, this.header())
            .pipe(map(function (response) { return response; }), catchError(function (err) {
            return throwError(err);
        }));
    };
    EmployeeService.prototype.getTree = function (param) {
        return this.http
            .post(this.globalService.apiVersionHost + '/employee/org-chart/get-tree', param, this.header())
            .pipe(map(function (response) { return response; }), catchError(function (err) {
            return throwError(err);
        }));
    };
    EmployeeService.prototype.getTreeOfficial = function (param) {
        return this.http
            .post(this.globalService.apiVersionHost + '/employee/org-chart/get-tree-official', param, this.header())
            .pipe(map(function (response) { return response; }), catchError(function (err) {
            return throwError(err);
        }));
    };
    EmployeeService.prototype.getReminder = function (param) {
        return this.http
            .post(this.globalService.apiVersionHost + API_GET_REMINDER, param, this.header())
            .pipe(map(function (response) { return response; }), catchError(function (err) {
            return throwError(err);
        }));
    };
    EmployeeService.prototype.getApprovalEmployee = function (param) {
        return this.http.post(this.globalService.apiVersionHost + API_GET_APPROVAL_EMPLOYEE, param, this.header()).pipe(map(function (response) { return response; }));
    };
    EmployeeService.prototype.getDetailApproval = function (param) {
        return this.http.post(this.globalService.apiVersionHost + API_GET_DETAIL_APPROVAL, param, this.header()).pipe(map(function (response) { return response; }));
    };
    EmployeeService.prototype.approveUpdateData = function (param) {
        return this.http.post(this.globalService.apiVersionHost + API_APPROVE_UPDATE_DATA, param, this.header()).pipe(map(function (response) { return response; }));
    };
    EmployeeService.prototype.getAllLocation = function () {
        return this.http.get(this.globalService.apiVersionHost + API_ALL_LOCATION, this.header());
    };
    EmployeeService.prototype.getListPcode = function () {
        return this.http.post(this.globalService.apiVersionHost + API_LIST_PCODE, null, this.header('employeeAdmin'));
    };
    EmployeeService.prototype.getListPcodeSelect2 = function () {
        return this.http.post(this.globalService.apiVersionHost + API_LIST_PCODE_SELECT2, null, this.header('employeeAdmin'));
    };
    EmployeeService.prototype.getListCv = function (param) {
        return this.http.post(this.globalService.apiVersionHost + API_LIST_CV, param, this.header('employeeAdmin'));
    };
    EmployeeService.prototype.changePassword = function (param) {
        return this.http.post(this.globalService.apiVersionHost + API_CHANGE_PASSWORD, param, this.header('employeeProfile'));
    };
    EmployeeService.prototype.getListDataMovement = function (param) {
        return this.http
            .post(this.globalService.apiVersionHost + '/employee/hrd/list-data-movement', param, this.header())
            .pipe(map(function (response) { return response; }));
    };
    EmployeeService.prototype.getMovementTracking = function (param) {
        return this.http
            .post(this.globalService.apiVersionHost + '/employee/hrd/get-movement-track', param, this.header())
            .pipe(map(function (response) { return response; }));
    };
    EmployeeService.prototype.getMovementStep = function (param) {
        return this.http
            .post(this.globalService.apiVersionHost + '/employee/hrd/get-movement-step', param, this.header())
            .pipe(map(function (response) { return response; }));
    };
    EmployeeService.prototype.exportExcel = function (param) {
        return this.http.post(this.globalService.apiVersionHost + '/employee/hrd/export-excel', param, this.header()).pipe(map(function (response) { return response; }));
    };
    EmployeeService.prototype.getListExportResign = function (param) {
        return this.http
            .post(this.globalService.apiVersionHost + '/employee/hrd/list-export-resign', param, this.header())
            .pipe(map(function (response) { return response; }));
    };
    EmployeeService.prototype.getListExportVaccine = function (param) {
        return this.http
            .post(this.globalService.apiVersionHost + '/employee/hrd/list-export-vaccine', param, this.header())
            .pipe(map(function (response) { return response; }));
    };
    EmployeeService.prototype.resignExportExcel = function (param) {
        return this.http.post(this.globalService.apiVersionHost + '/employee/hrd/resign-export-excel', param, this.header()).pipe(map(function (response) { return response; }));
    };
    EmployeeService.prototype.vaccineExportExcel = function (param) {
        return this.http.post(this.globalService.apiVersionHost + '/employee/hrd/vaccine-export-excel', param, this.header()).pipe(map(function (response) { return response; }));
    };
    EmployeeService.prototype.generateEAF = function (param) {
        return this.http.post(this.globalService.apiVersionHost + '/employee/hrd/generate-eaf', param, this.header()).pipe(map(function (response) { return response; }));
    };
    EmployeeService.prototype.generateEAFpdf = function (param) {
        return this.http.post(this.globalService.apiVersionHost + '/employee/hrd/generate-eaf-pdf', param, this.header()).pipe(map(function (response) { return response; }));
    };
    EmployeeService.prototype.allEmpExportExcel = function () {
        return this.http.post(this.globalService.apiVersionHost + API_ALL_EMP_EXPORT_EXCEL, this.header('employeeAdmin')).pipe(map(function (response) { return response; }));
    };
    EmployeeService.prototype.cancelJoin = function (param) {
        return this.http.post(this.globalService.apiVersionHost + API_CANCEL_JOIN, param, this.header()).pipe(map(function (response) { return response; }));
    };
    EmployeeService.prototype.findEmployee = function (param) {
        return this.http.post(this.globalService.apiVersionHost + API_FIND_EMPLOYEE, param, this.header()).pipe(map(function (response) { return response; }));
    };
    EmployeeService.prototype.listPosition = function (param) {
        return this.http.post(this.globalService.apiVersionHost + '/employeeAdmin/dev/list-position', param, this.header('employeeAdmin'));
    };
    EmployeeService.prototype.addPosition = function (param) {
        return this.http.post(this.globalService.apiVersionHost + '/employeeAdmin/dev/create-position', param, this.header('employeeAdmin'));
    };
    EmployeeService.prototype.editPosition = function (param) {
        return this.http.post(this.globalService.apiVersionHost + '/employeeAdmin/dev/edit-position', param, this.header('employeeAdmin'));
    };
    EmployeeService.prototype.listNWAssignment = function (param) {
        return this.http.post(this.globalService.apiVersionHost + '/employeeAdmin/dev/list-nw-assignment', param, this.header('employeeAdmin'));
    };
    EmployeeService.prototype.addNWAssignment = function (param) {
        return this.http.post(this.globalService.apiVersionHost + '/employeeAdmin/dev/create-nw-assignment', param, this.header('employeeAdmin'));
    };
    EmployeeService.prototype.editNWAssignment = function (param) {
        return this.http.post(this.globalService.apiVersionHost + '/employeeAdmin/dev/edit-nw-assignment', param, this.header('employeeAdmin'));
    };
    EmployeeService.prototype.listMappingPaPcode = function (param) {
        return this.http.post(this.globalService.apiVersionHost + '/employeeAdmin/dev/mapping-pa-pcode', param, this.header('employeeAdmin'));
    };
    EmployeeService.prototype.addMappingPaPcode = function (param) {
        return this.http.post(this.globalService.apiVersionHost + '/employeeAdmin/dev/create-mapping-pa-pcode', param, this.header('employeeAdmin'));
    };
    EmployeeService.prototype.deleteMappingPaPcode = function (id) {
        return this.http.post(this.globalService.apiVersionHost + '/employeeAdmin/dev/delete-mapping-pa-pcode', { id: id }, this.header('employeeAdmin'));
    };
    EmployeeService.prototype.getEmployeeMov = function (param) {
        return this.http.post(this.globalService.apiVersionHost + API_GET_EMPLOYEE_MOV, param, this.header());
    };
    EmployeeService.prototype.submit = function (param) {
        return this.http.post(this.globalService.apiVersionHost + API_SUBMIT, param, this.header());
    };
    EmployeeService.prototype.releaseTicket = function (param) {
        return this.http.post(this.globalService.apiVersionHost + API_RELEASE_TICKET, param, this.header());
    };
    EmployeeService.prototype.getDataFieldOpsProd = function (param) {
        return this.http.post(this.globalService.apiVersionHost + API_GET_DATA_FIELD_OPS_PROD, param, this.header('productivity'));
    };
    EmployeeService.prototype.exportDataFieldOpsProd = function (param) {
        return this.http.post(this.globalService.apiVersionHost + API_EXPORT_DATA_FIELD_OPS_PROD, param, this.header('productivity'));
    };
    EmployeeService.prototype.getDataVaccine = function (param) {
        return this.http.post(this.globalService.apiVersionHost + API_GET_DATA_VACCINE, param, this.header('employee'));
    };
    EmployeeService.prototype.exportDataVaccine = function (param) {
        return this.http.post(this.globalService.apiVersionHost + API_EXPORT_DATA_VACCINE, param, this.header('employee'));
    };
    EmployeeService.prototype.getEmployeeProfile = function (param) {
        return this.http.post(this.globalService.apiVersionHost + API_GET_EMPLOYEE_PROFILE, param, this.header('employee'));
    };
    EmployeeService.prototype.getListEmployeePiutang = function () {
        return this.http.post(this.globalService.apiVersionHost + "/employee/piutang/list-employee-piutang", null, this.header('employee'));
    };
    EmployeeService.prototype.getEmployeeReceiveble = function (param) {
        return this.http.post(this.globalService.apiVersionHost + "/employee/piutang/list-receiveble", param, this.header('employee'));
    };
    EmployeeService.prototype.submitBayarReceiveble = function (param) {
        return this.http.post(this.globalService.apiVersionHost + "/employee/piutang/submit-bayar", param, this.header('employee'));
    };
    EmployeeService.prototype.importBayar = function (param) {
        return this.http.post(this.globalService.apiVersionHost + "/employee/hrd/import-bayar", param, this.header('employee'));
    };
    EmployeeService.prototype.submitHutangReceiveble = function (param) {
        return this.http.post(this.globalService.apiVersionHost + "/employee/piutang/submit-hutang", param, this.header('employee'));
    };
    EmployeeService.prototype.getDashboardSummary = function (params) {
        return this.http.post(this.globalService.apiVersionHost + '/employee/default/get-dashboard-summary', params, this.header()).pipe(map(function (response) { return response; }));
    };
    EmployeeService.prototype.checkAgreement = function (params) {
        return this.http.post(this.globalService.apiVersionHost + '/employee/default/check-agreement', params, this.header()).pipe(map(function (response) { return response; }));
    };
    EmployeeService.prototype.submitAgreement = function (params) {
        return this.http.post(this.globalService.apiVersionHost + '/employee/default/submit-agreement', params, this.header()).pipe(map(function (response) { return response; }));
    };
    EmployeeService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function EmployeeService_Factory() { return new EmployeeService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.GlobalService), i0.ɵɵinject(i3.CookieService), i0.ɵɵinject(i4.NgbModal)); }, token: EmployeeService, providedIn: "root" });
    return EmployeeService;
}());
export { EmployeeService };
