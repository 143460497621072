import { UploadService } from 'src/app/_services/upload.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { NgForm } from '@angular/forms';
import { LandingpageService } from 'src/app/_services/landingpage.service';
import Swal from 'sweetalert2';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { DropzoneConfigInterface } from 'ngx-dropzone-wrapper';
import { ImageCroppedEvent } from 'ngx-image-cropper';

@Component({
  selector: 'app-registration',
  templateUrl: './registration.component.html',
  styleUrls: ['./registration.component.scss']
})
export class RegistrationComponent implements OnInit {

  public users: any = {};
  errors: any = [];
  id_detail_vacancy = this.actRoute.snapshot.params['id'];

  imageChangedEvent: any = '';
  croppedFoto: any = '';
  croppedKtp: any = '';

  jeniskelamin: JenisKelamin[] = [
    { value: 'Male', viewValue: 'Laki-Laki' },
    { value: 'Female', viewValue: 'Perempuan' },
  ];

  pendidikan: Pendidikan[] = [
    { value: '1', viewValue: 'SMP' },
    { value: '2', viewValue: 'SMA/SMK' },
    { value: '3', viewValue: 'Diploma 1' },
    { value: '4', viewValue: 'Diploma 3' },
    { value: '5', viewValue: 'Sarjana' },
    { value: '6', viewValue: 'Master' },
    { value: '7', viewValue: 'Doktor' },
  ];

  ketersediaan: Ketersediaan[] = [
    { value: 'Idle', viewValue: 'Idle' },
    { value: 'On Job', viewValue: 'On Job' }
  ];

  public config: DropzoneConfigInterface = {
    clickable: true,
    maxFiles: 1,
    autoReset: null,
    errorReset: null,
    cancelReset: null
  };

  certificates: any[] = [
    {
      no: null,
      name: null,
      publisher: null,
      publish_date: null,
      expired_date: null,
      id_attachment: null,
      training_place: null,
      training_period_start: null,
      training_period_end: null,
      no_licence: null,
      id_attachment_licence: null,
      no_suket: null,
      id_attachment_suket: null
    }
  ];

  constructor(
    public landingpageService: LandingpageService,
    public router: Router,
    public actRoute: ActivatedRoute,
    private ngxLoader: NgxUiLoaderService
  ) { }

  ngOnInit() {
    window.scroll(0, 0);
  }

  getUploaded($event, row: string, nama: string) {
    this.users[row] = $event.value;
    this.users[nama] = $event.name;
  }

  save(registration) {
    this.ngxLoader.stop();
    if (this.validate(registration)) {
      Swal.fire({
        title: 'Data yang saya input dan upload adalah data yang benar sesuai dengan identitas diri saya, apabila ada kesalahan dalam penginputan dan upload dokumen diri saya, maka saya bersedia bertanggung jawab dan menerima segala konsekuensinya.',
        type: 'warning',
        showCancelButton: false,
        confirmButtonColor: '#3085d6',
        confirmButtonText: 'SETUJU',
        cancelButtonColor: '#d33',
        allowOutsideClick: true,
      }).then((result) => {
        if (result.value) {
          console.log(this.certificates);
          const param = {
            regis: this.users,
            id: this.id_detail_vacancy,
            certificates: this.certificates
          };

          this.landingpageService.saveRegistration(param).subscribe((data: any) => {
            this.ngxLoader.stop();
            if (data.status) {
              const userid = data.result;
              this.router.navigate(['/skill/', userid]);
              return;
            }
            if (data.result === null) {
              this.showSwalInfo('Perhatian !', data.message);
            } else {
              this.showSwalInfo(
                'Gagal Menyimpan. Email Yang Digunakan Sudah Terdaftar.',
                'Silakan menggunakan email lain yang masih aktif dan belum terdaftar di website ptnw.net.'
              );
            }
          });
        }
      });
    }
  }

  validate(form_item: NgForm) {
    this.errors = [];
    const value: any = form_item.form.value;
    if (value.email === '' || value.email === undefined || value.password === '' || value.password === undefined || value.repassword === '' || value.repassword === undefined) {
      this.ngxLoader.stop();
      this.showSwal('Gagal Menyimpan. Silakan lengkapi isian pada bagian Personal Account.', 'Lengkapi semua isian yang bertanda *.');
      return false;
    }
    else if (value.password !== value.repassword) {
      this.ngxLoader.stop();
      this.showSwal('Gagal Menyimpan. Password dan Retype Password tidak sesuai.', 'Isian Password dan Retype Password harus sama.');
      return false;
    }
    else if (value.nama === '' || value.nama === undefined || value.ktp === '' || value.ktp === undefined || value.tgl_lahir === '' || value.tgl_lahir === undefined || value.kota_asal === '' || value.kota_asal === undefined || value.hp === '' || value.hp === undefined || value.alamat === '' || value.alamat === undefined || value.join_date === '' || value.join_date === undefined || value.salary === '' || value.salary === undefined || value.jenis_kelamin === '' || value.jenis_kelamin === undefined || value.education === '' || value.education === undefined || value.availability === '' || value.availability === undefined) {
      this.ngxLoader.stop();
      this.showSwal('Gagal Menyimpan. Silakan lengkapi isian pada bagian Personal Information.', 'Lengkapi semua isian yang bertanda *.');
      return false;
    }
    else if (this.users.foto === '' || this.users.foto === undefined || this.users.ktp_attachment === '' || this.users.ktp_attachment === undefined || this.users.kk_attachment === '' || this.users.kk_attachment === undefined || this.users.cv_attachment === '' || this.users.cv_attachment === undefined) {
      this.ngxLoader.stop();
      this.showSwal('Gagal Menyimpan. Silakan lengkapi dokumen pada bagian File Attachment.', 'Lengkapi semua isian yang bertanda *.');
      return false;
    }
    else if (!this.users.vaccine_1_date || !Number.isInteger(this.users.vaccine_1_attach) || !this.users.vaccine_2_date || !Number.isInteger(this.users.vaccine_2_attach) || !this.users.vaccine_3_date || !Number.isInteger(this.users.vaccine_3_attach)) {
      this.ngxLoader.stop();
      this.showSwal('Gagal Menyimpan.', 'Lengkapi data vaksin.');
      return false;
    }
    else if (form_item.form.status === 'VALID') { 
      this.ngxLoader.stop();
      this.errors = [];
      return true;
    }
  }

  showSwal(title: string, text: string) {
    Swal.fire({
      title: title,
      text: text,
      type: 'warning',
      showCancelButton: false,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'OK',
      allowOutsideClick: false
    }).then((result) => {
      if (result.value) {
        return false;
      }
    });
  }

  showSwalInfo(title: string, text: string) {
    Swal.fire({
      title: title,
      text: text,
      type: 'info',
      showCancelButton: false,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'OK',
      allowOutsideClick: false
    }).then((result) => {
      if (result.value) {
        return false;
      }
    });
  }

  onSuccess($event, attribute) {
    this.users[attribute] = 1* $event.id;
  }

  onSuccessWasabi($event, attribute) {
    this.users[attribute] = 1* $event.wasabiRowId;
  }

  onChange(files: FileList): void {
    if (files.length > 0) {
      const file = files.item(0);
      const reader = new FileReader();
      reader.onloadend  = (e) => {
        this.croppedKtp = reader.result;
      };
      reader.readAsDataURL(file);
    }
  }

  imageCropped(event: ImageCroppedEvent) {
    this.croppedFoto = event.base64;
  }

  imageLoaded() {
    // show cropper
  }

  cropperReady() {
    // cropper ready
  }

  loadImageFailed() {
    // show message
  }

  afterCrop(image) {
    this.croppedFoto = image;
  }

  onSuccessUpload($event, attribute, item) {
    const index = this.certificates.indexOf(item, 0);
    if (index > -1) {
      this.certificates[index][attribute] = 1* $event.wasabiRowId;
    }
  }

  trackById(index: number, obj: any): any {
    return obj.id;
  }

  addCertificate() {
    this.certificates.push({
      no: null,
      name: null,
      publisher: null,
      publish_date: null,
      expired_date: null,
      id_attachment: null,
      training_place: null,
      training_period_start: null,
      training_period_end: null,
      no_licence: null,
      id_attachment_licence: null,
      no_suket: null,
      id_attachment_suket: null
    });
  }

  dellCertificate(certf) {
    const index = this.certificates.indexOf(certf, 0);
    if (index > -1) {
      this.certificates.splice(index, 1);
    }
  }

}

export interface JenisKelamin {
  value: string;
  viewValue: string;
}

export interface Pendidikan {
  value: string;
  viewValue: string;
}

export interface Ketersediaan {
  value: string;
  viewValue: string;
}
