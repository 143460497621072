import { Component, Input, Output, EventEmitter } from '@angular/core';
import { SkillFormModel } from './skill-form.config';

@Component({
  selector: 'app-skill-form',
  templateUrl: './skill-form.component.html',
  styleUrls: ['./skill-form.component.scss']
})
export class SkillFormComponent {

  @Input() skillFormConfig: SkillFormModel;

  row_id: any = '';
  edit_model: Object = {};

  @Output() rowEdited = new EventEmitter<any>();

  constructor() { }

  isEdit(index: number, row: any) {
    this.row_id = index;
    this.edit_model = {
      vendor: row.vendor,
      skill: row.skill,
      system: row.system,
      level: row.level,
      experience_in_year: row.experience_in_year,
    };
  }
  isSave(row: any) {
    row.level = this.edit_model['level'];
    row.experience_in_year = this.edit_model['experience_in_year'];
    this.rowEdited.emit(row);
    this.row_id = null;
  }
  isCancel() {
    this.row_id = null;
  }

}
