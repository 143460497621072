import { HttpClient, HttpHeaders } from '@angular/common/http';
import { CookieService } from 'ngx-cookie-service';
import { map } from 'rxjs/operators';
import Swal from 'sweetalert2';
import { AuthenticationService } from '../auth/authentication.service';
import { GlobalService } from './global.service';
import { UserService } from './user.service';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "./global.service";
import * as i3 from "ngx-cookie-service";
import * as i4 from "../auth/authentication.service";
import * as i5 from "./user.service";
var API_GET_LIST = '/modules-menu/get-list';
var ModuleMenuService = /** @class */ (function () {
    function ModuleMenuService(http, globalService, cookieService, authenticationService, userService) {
        this.http = http;
        this.globalService = globalService;
        this.cookieService = cookieService;
        this.authenticationService = authenticationService;
        this.userService = userService;
    }
    ModuleMenuService.prototype.checkJWT = function () {
        var _this = this;
        this.userService.getAccessToken().subscribe(function (token) {
            if (token) {
                var expiry = (JSON.parse(atob(token.split('.')[1]))).exp;
                if ((Math.floor((new Date).getTime() / 1000)) >= expiry) {
                    Swal.fire('Sistem PTNW.NET', 'Sesi login Anda telah habis. Silakan melakukan login ulang. Terima Kasih.', 'warning');
                    _this.authenticationService.logout();
                }
            }
        });
    };
    ModuleMenuService.prototype.header = function () {
        return {
            headers: new HttpHeaders()
                .set('token', this.cookieService.get('_q'))
        };
    };
    ModuleMenuService.prototype.getList = function () {
        this.checkJWT();
        return this.http.post(this.globalService.apiVersionHost + API_GET_LIST, null, this.header()).pipe(map(function (response) { return response; }));
    };
    ModuleMenuService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ModuleMenuService_Factory() { return new ModuleMenuService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.GlobalService), i0.ɵɵinject(i3.CookieService), i0.ɵɵinject(i4.AuthenticationService), i0.ɵɵinject(i5.UserService)); }, token: ModuleMenuService, providedIn: "root" });
    return ModuleMenuService;
}());
export { ModuleMenuService };
