/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./lostpassword5g.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/router";
import * as i3 from "@angular/common";
import * as i4 from "@angular/forms";
import * as i5 from "./lostpassword5g.component";
import * as i6 from "../../../../auth/authentication.service";
var styles_Lostpassword5gComponent = [i0.styles];
var RenderType_Lostpassword5gComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_Lostpassword5gComponent, data: {} });
export { RenderType_Lostpassword5gComponent as RenderType_Lostpassword5gComponent };
function View_Lostpassword5gComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "button", [["class", "btn"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "img", [["src", "/assets/media/loader/timeline.svg"]], [[4, "height", "px"]], null, null, null, null))], null, function (_ck, _v) { var currVal_0 = 37; _ck(_v, 1, 0, currVal_0); }); }
function View_Lostpassword5gComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "input", [["class", "btn btn-dark mr-3"], ["type", "submit"], ["value", "Request Lost Password"]], [[8, "disabled", 0]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.isSubmited; _ck(_v, 0, 0, currVal_0); }); }
export function View_Lostpassword5gComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 38, "div", [["class", "login-form text-center text-dark"], ["style", "background: url(./assets/media/5g/login.jpg) no-repeat;"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 37, "div", [["class", "container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 36, "div", [["class", "row justify-content-center pt-0 pt-lg-5"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 35, "div", [["class", "col-xl-8 col-lg-9 col-md-6 col-11 pt-0 pt-lg-5"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 34, "div", [["class", "shadow login-form-wrap rounded"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 33, "div", [["class", "row align-items-center no-gutters"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 5, "div", [["class", "col-lg-6 login-form-left rounded text-center "]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 4, "div", [["class", "pt-5 pb-lg-5 pb-0 px-3"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 3, "a", [], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 9).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(9, 671744, null, 0, i2.RouterLinkWithHref, [i2.Router, i2.ActivatedRoute, i3.LocationStrategy], { routerLink: [0, "routerLink"] }, null), i1.ɵpad(10, 1), (_l()(), i1.ɵeld(11, 0, null, null, 0, "img", [["alt", ""], ["class", "login-logo"], ["src", "./assets/media/nds/logo.png"]], null, null, null, null, null)), (_l()(), i1.ɵeld(12, 0, null, null, 26, "div", [["class", "col-lg-6 login-form-right"]], null, null, null, null, null)), (_l()(), i1.ɵeld(13, 0, null, null, 25, "div", [["class", "py-5 px-4 px-md-5"]], null, null, null, null, null)), (_l()(), i1.ɵeld(14, 0, null, null, 1, "h3", [["class", "pb-4 font-weight-bold d-none d-lg-block"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Lost Password "])), (_l()(), i1.ɵeld(16, 0, null, null, 1, "div", [["class", "pb-4"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Please enter your username or email. A confirmation email will be sent automatically. You will need to click on the link to enter a new password. "])), (_l()(), i1.ɵeld(18, 0, null, null, 20, "form", [["id", "forgotForm"], ["novalidate", ""]], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "ngSubmit"], [null, "submit"], [null, "reset"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("submit" === en)) {
        var pd_0 = (i1.ɵnov(_v, 20).onSubmit($event) !== false);
        ad = (pd_0 && ad);
    } if (("reset" === en)) {
        var pd_1 = (i1.ɵnov(_v, 20).onReset() !== false);
        ad = (pd_1 && ad);
    } if (("ngSubmit" === en)) {
        var pd_2 = (_co.submitForgot(i1.ɵnov(_v, 20)) !== false);
        ad = (pd_2 && ad);
    } return ad; }, null, null)), i1.ɵdid(19, 16384, null, 0, i4.ɵangular_packages_forms_forms_z, [], null, null), i1.ɵdid(20, 4210688, [["forgotForm", 4]], 0, i4.NgForm, [[8, null], [8, null]], null, { ngSubmit: "ngSubmit" }), i1.ɵprd(2048, null, i4.ControlContainer, null, [i4.NgForm]), i1.ɵdid(22, 16384, null, 0, i4.NgControlStatusGroup, [[4, i4.ControlContainer]], null, null), (_l()(), i1.ɵeld(23, 0, null, null, 6, "div", [["class", "form-group"]], null, null, null, null, null)), (_l()(), i1.ɵeld(24, 0, null, null, 5, "input", [["class", "form-control"], ["name", "username"], ["placeholder", "Username or email"], ["type", "text"], ["value", ""]], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "ngModelChange"], [null, "input"], [null, "blur"], [null, "compositionstart"], [null, "compositionend"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("input" === en)) {
        var pd_0 = (i1.ɵnov(_v, 25)._handleInput($event.target.value) !== false);
        ad = (pd_0 && ad);
    } if (("blur" === en)) {
        var pd_1 = (i1.ɵnov(_v, 25).onTouched() !== false);
        ad = (pd_1 && ad);
    } if (("compositionstart" === en)) {
        var pd_2 = (i1.ɵnov(_v, 25)._compositionStart() !== false);
        ad = (pd_2 && ad);
    } if (("compositionend" === en)) {
        var pd_3 = (i1.ɵnov(_v, 25)._compositionEnd($event.target.value) !== false);
        ad = (pd_3 && ad);
    } if (("ngModelChange" === en)) {
        var pd_4 = ((_co.forgot_form.username = $event) !== false);
        ad = (pd_4 && ad);
    } return ad; }, null, null)), i1.ɵdid(25, 16384, null, 0, i4.DefaultValueAccessor, [i1.Renderer2, i1.ElementRef, [2, i4.COMPOSITION_BUFFER_MODE]], null, null), i1.ɵprd(1024, null, i4.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i4.DefaultValueAccessor]), i1.ɵdid(27, 671744, null, 0, i4.NgModel, [[2, i4.ControlContainer], [8, null], [8, null], [6, i4.NG_VALUE_ACCESSOR]], { name: [0, "name"], model: [1, "model"] }, { update: "ngModelChange" }), i1.ɵprd(2048, null, i4.NgControl, null, [i4.NgModel]), i1.ɵdid(29, 16384, null, 0, i4.NgControlStatus, [[4, i4.NgControl]], null, null), (_l()(), i1.ɵeld(30, 0, null, null, 8, "div", [["class", "form-group text-left pt-3"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_Lostpassword5gComponent_1)), i1.ɵdid(32, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_Lostpassword5gComponent_2)), i1.ɵdid(34, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(35, 0, null, null, 3, "a", [["class", "text-dark font-weight-bold mr-4"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 36).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(36, 671744, null, 0, i2.RouterLinkWithHref, [i2.Router, i2.ActivatedRoute, i3.LocationStrategy], { routerLink: [0, "routerLink"] }, null), i1.ɵpad(37, 1), (_l()(), i1.ɵted(-1, null, [" Login "])), (_l()(), i1.ɵeld(39, 0, null, null, 0, "div", [["class", "clear"]], null, null, null, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_2 = _ck(_v, 10, 0, "/"); _ck(_v, 9, 0, currVal_2); var currVal_17 = "username"; var currVal_18 = _co.forgot_form.username; _ck(_v, 27, 0, currVal_17, currVal_18); var currVal_19 = _co.isSubmited; _ck(_v, 32, 0, currVal_19); var currVal_20 = !_co.isSubmited; _ck(_v, 34, 0, currVal_20); var currVal_23 = _ck(_v, 37, 0, "/login"); _ck(_v, 36, 0, currVal_23); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 9).target; var currVal_1 = i1.ɵnov(_v, 9).href; _ck(_v, 8, 0, currVal_0, currVal_1); var currVal_3 = i1.ɵnov(_v, 22).ngClassUntouched; var currVal_4 = i1.ɵnov(_v, 22).ngClassTouched; var currVal_5 = i1.ɵnov(_v, 22).ngClassPristine; var currVal_6 = i1.ɵnov(_v, 22).ngClassDirty; var currVal_7 = i1.ɵnov(_v, 22).ngClassValid; var currVal_8 = i1.ɵnov(_v, 22).ngClassInvalid; var currVal_9 = i1.ɵnov(_v, 22).ngClassPending; _ck(_v, 18, 0, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8, currVal_9); var currVal_10 = i1.ɵnov(_v, 29).ngClassUntouched; var currVal_11 = i1.ɵnov(_v, 29).ngClassTouched; var currVal_12 = i1.ɵnov(_v, 29).ngClassPristine; var currVal_13 = i1.ɵnov(_v, 29).ngClassDirty; var currVal_14 = i1.ɵnov(_v, 29).ngClassValid; var currVal_15 = i1.ɵnov(_v, 29).ngClassInvalid; var currVal_16 = i1.ɵnov(_v, 29).ngClassPending; _ck(_v, 24, 0, currVal_10, currVal_11, currVal_12, currVal_13, currVal_14, currVal_15, currVal_16); var currVal_21 = i1.ɵnov(_v, 36).target; var currVal_22 = i1.ɵnov(_v, 36).href; _ck(_v, 35, 0, currVal_21, currVal_22); }); }
export function View_Lostpassword5gComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-lostpassword5g", [], null, null, null, View_Lostpassword5gComponent_0, RenderType_Lostpassword5gComponent)), i1.ɵdid(1, 114688, null, 0, i5.Lostpassword5gComponent, [i6.AuthenticationService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var Lostpassword5gComponentNgFactory = i1.ɵccf("app-lostpassword5g", i5.Lostpassword5gComponent, View_Lostpassword5gComponent_Host_0, {}, {}, []);
export { Lostpassword5gComponentNgFactory as Lostpassword5gComponentNgFactory };
