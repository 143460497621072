import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ResponseApiModel } from '../_models/response-api.model';
import { GlobalService } from './global.service';

@Injectable({
  providedIn: 'root'
})
export class ApprovalService {

  constructor(
    private http: HttpClient,
    private globalService: GlobalService,
    private cookieService: CookieService
  ) { }

  public header() {
    return {
      headers: new HttpHeaders()
        .set('token', this.cookieService.get('_q'))
    };
  }

  public generateLink(params: { module: string, idData: string, userid: number }): Observable<ResponseApiModel> {
    return this.http.post<ResponseApiModel>(
      this.globalService.apiVersionHost + '/approval/generate-link', params, this.header()
    ).pipe(
      map(response => response)
    );
  }

  public submitByLink(params: { token: string}): Observable<ResponseApiModel> {
    return this.http.post<ResponseApiModel>(
      this.globalService.apiVersionHost + '/approval/submit-approval-by-link', params
    ).pipe(
      map(response => response)
    );
  }

  public submitByLogin(params: { token: string, username: string, password: string}): Observable<ResponseApiModel> {
    return this.http.post<ResponseApiModel>(
      this.globalService.apiVersionHost + '/approval/submit-approval-by-login', params
    ).pipe(
      map(response => response)
    );
  }
}
