import { Injectable } from '@angular/core';
import {
  ANDROID,
  CHROME,
  CHROME_BOOK,
  CRIOS,
  CROS,
  EDGE,
  EDGE_IOS,
  FIREFOX,
  FX_IOS,
  GOOGLE_CHROME,
  INTERNET_EXPLORER,
  IOS,
  IPHONE,
  LINUX,
  MACOS,
  MICROSOFT_EDGE,
  MOZILLA_FIREFOX,
  MSIE_100,
  MSIE_60,
  MSIE_70,
  MSIE_80,
  MSIE_90,
  SAFARI,
  TRIDENT
} from './application-constants';


@Injectable({
  providedIn: 'root'
})
export class BrowserService {
  userAgent = '';
  constructor() {
    if (typeof window !== 'undefined') {
      this.userAgent = window.navigator.userAgent;
    }
  }

  /*
    Detect browser name
  */
  getBrowserName() {
    //Desktop browsers
    if (this.userAgent.indexOf(FIREFOX) !== -1) {
      return MOZILLA_FIREFOX;
    }
    else if (this.userAgent.indexOf(CHROME) !== -1 && this.userAgent.indexOf(SAFARI) !== -1 && this.userAgent.indexOf(EDGE) !== -1) {
      return MICROSOFT_EDGE;
    }
    else if (this.userAgent.indexOf(CHROME) !== -1 && this.userAgent.indexOf(SAFARI) !== -1 && this.userAgent.indexOf(EDGE) === -1) {
      return GOOGLE_CHROME;
    }
    else if (this.userAgent.indexOf(CHROME) === -1 && this.userAgent.indexOf(SAFARI) !== -1 && this.userAgent.indexOf(EDGE) === -1) {
      return SAFARI;
    }
    else if (this.userAgent.indexOf(MSIE_60) !== -1 || this.userAgent.indexOf(MSIE_70) !== -1 || this.userAgent.indexOf(MSIE_80) !== -1
      || this.userAgent.indexOf(MSIE_90) !== -1 || this.userAgent.indexOf(MSIE_100) !== -1 || this.userAgent.indexOf(TRIDENT) !== -1) {
      return INTERNET_EXPLORER;
    }

    //Mobile browsers
    else if (this.userAgent.indexOf(CRIOS) !== -1) {
      return CHROME;
    }
    else if (this.userAgent.indexOf(FX_IOS) !== -1) {
      return FIREFOX;
    }
    else if (this.userAgent.indexOf(EDGE_IOS) !== -1) {
      return EDGE;
    }
    else {
      return SAFARI;
    }
  }

  getBrowserVersion() {

  }

  /*
     Detect browser operating system
   */
  getBrowserOperatingSystem() {
    if (this.userAgent.indexOf(ANDROID) !== -1 && this.userAgent.indexOf(LINUX) !== -1) {
      return ANDROID;
    }

    else if (this.userAgent.indexOf(ANDROID) === -1 && this.userAgent.indexOf(LINUX) !== -1) {
      return LINUX;
    }

    else if (this.userAgent.indexOf(IPHONE) !== -1) {
      return IOS;
    }

    else if (this.userAgent.indexOf(MACOS) !== -1) {
      return MACOS;
    }

    else if (this.userAgent.indexOf(CROS) !== -1 || this.userAgent.indexOf(CHROME_BOOK) !== -1) {
      return "Chrome OS";
    }
  }

  isGoogleChrome() {
    return ((this.userAgent.indexOf(CHROME) !== -1 && this.userAgent.indexOf(SAFARI) !== -1 && this.userAgent.indexOf(EDGE) === -1) || (this.userAgent.indexOf(CRIOS) !== -1))
  }

  isMozillaFirefox() {
    return this.userAgent.indexOf(FIREFOX) !== -1 || this.userAgent.indexOf(FX_IOS) !== -1;
  }

  isMicroSoftEdge() {
    return this.userAgent.indexOf(EDGE_IOS) !== -1 || (this.userAgent.indexOf(CHROME) !== -1 && this.userAgent.indexOf(SAFARI) !== -1 && this.userAgent.indexOf(EDGE) !== -1);
  }

  isInternetExplorer() {
    return this.userAgent.indexOf(MSIE_60) !== -1 || this.userAgent.indexOf(MSIE_70) !== -1 || this.userAgent.indexOf(MSIE_80) !== -1
      || this.userAgent.indexOf(MSIE_90) !== -1 || this.userAgent.indexOf(MSIE_100) !== -1 || this.userAgent.indexOf(TRIDENT) !== -1;
  }

  isSafari() {
    return this.userAgent.indexOf(CHROME) === -1 && this.userAgent.indexOf(SAFARI) !== -1 && this.userAgent.indexOf(EDGE) === -1;
  }

  isMobile() {

  }

  isTablet() {

  }

  isDesktop() {

  }
}