<div id="footer" class="bg-white border-top px-0 px-lg-5">
  <div class="container-fluid">
      <div class="row pt-5">
          <div class="col-lg-3 col-md-5 mb-5 text-center text-lg-left">
              <div class="pb-2">
                  <img src="./assets/media/5g/svg/logo-nexwave-shadow.svg" alt="" width="180">
               </div>
               <div class="pl-2">
                  <a href="https://www.facebook.com/ptnexwave.indonesia/" class="btn btn-icon text-dark">
                      <span class="fab fa-2x fa-facebook"></span>
                  </a>
                  <!-- <a href="./landing/index.html#" class="btn btn-icon text-dark">
                      <span class="fab fa-2x fa-twitter"></span>
                  </a> -->
                  <a href="https://www.instagram.com/ptnexwave/" class="btn btn-icon text-dark">
                      <span class="fab fa-2x fa-instagram"></span>
                  </a>
                  <a href="https://www.youtube.com/channel/UC6p2-m9d_v6Jl4ByqTChmQw" class="btn btn-icon text-dark">
                      <span class="fab fa-2x fa-youtube"></span>
                  </a>
              </div>
          </div>
          <div class="col-lg-4 col-md-7 mb-4 d-none d-md-block">
              <div class="h5 pb-3 font-weight-bold">
                  OUR ADDRESS
              </div>
              <div>
                  <div class="py-2 d-flex align-items-center">
                      <span class="fa fa-location-arrow pr-3"></span>
                      <div>
                          Jl. Tebet Raya no.5 Tebet Barat, Jakarta Selatan, 12810
                      </div>
                  </div>
                  <div class="py-2 d-flex align-items-center">
                      <span class="fa fa-phone pr-3"></span>
                      <div>
                          (021) 8290809
                      </div>
                  </div>
                  <div class="py-2 d-flex align-items-center">
                      <span class="fa fa-fax pr-3"></span>
                      <div>
                          (021) 8292502
                      </div>
                  </div>
              </div>
          </div>
          <div class="col-lg-5 mb-4 d-none d-lg-block">
              <div class="h5 pb-3 font-weight-bold">
                  OPENING HOURS
              </div>
              <div>
                  <p>Indonesia Office: Jakarta, Bandung, Semarang, Surabaya, Denpasar, Bandar Lampung, Makassar, Balikpapan.</p>
                  <p>Mondays-Fridays: 9AM to 7PM</p>
                  <p>Saturdays-Sundays: Closed</p>
              </div>
          </div>
      </div>
  </div>
</div>
<div class="bg-white border-top px-0 px-lg-5">
  <div class="container-fluid">
      <div class="d-flex justify-content-between py-3 px-1">
          <div>Copyright by Nexwave {{currentDate}}</div>
          <div>Developed by NDS Team</div>
      </div>
  </div>
</div>