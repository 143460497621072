import { ReloadAuthorizationComponent } from './pages/reload-authorization/reload-authorization.component';
import { Routes, } from '@angular/router';
import { MainComponent } from './main.component';
import { NgxPermissionsGuard } from 'ngx-permissions';
import { AuthGuard } from 'src/app/auth/auth-guard.service';
import { ModuleComponent } from './module/module.component';
import { ModuleApprovalComponent } from './module-approval/module-approval.component';
import { AuthorizationComponent } from './pages/authorization/authorization.component';
var ɵ0 = {
    breadcrumb: 'All Menu',
    permissions: {
        only: ['USER'],
        redirectTo: '/login'
    }
}, ɵ1 = {
    breadcrumb: 'All Approval',
    permissions: {
        only: ['USER'],
        redirectTo: '/login'
    }
}, ɵ2 = {
    permissions: {
        only: ['USER'],
        redirectTo: '/login'
    }
}, ɵ3 = function () {
    return import("./pages/pm/pm.module.ngfactory").then(function (m) { return m.PmModuleNgFactory; });
}, ɵ4 = {
    permissions: {
        only: ['USER'],
        redirectTo: '/login'
    }
};
var routes = [
    {
        path: '',
        component: MainComponent,
        canActivate: [AuthGuard],
        children: [
            { path: '', redirectTo: 'pm', pathMatch: 'full' },
            {
                path: 'modules',
                component: ModuleComponent,
                canActivate: [NgxPermissionsGuard],
                data: ɵ0
            },
            {
                path: 'modules-approval',
                component: ModuleApprovalComponent,
                canActivate: [NgxPermissionsGuard],
                data: ɵ1
            },
            {
                path: 'authorization',
                component: AuthorizationComponent,
                canLoad: [NgxPermissionsGuard],
                data: ɵ2
            },
            {
                path: 'pm',
                loadChildren: ɵ3,
                canActivate: [NgxPermissionsGuard],
                data: ɵ4
            },
            {
                path: 'reload-authorization',
                component: ReloadAuthorizationComponent
            },
        ]
    }
];
var MainRoutingModule = /** @class */ (function () {
    function MainRoutingModule() {
    }
    return MainRoutingModule;
}());
export { MainRoutingModule };
export { ɵ0, ɵ1, ɵ2, ɵ3, ɵ4 };
