import { ReloadAuthorizationComponent } from './pages/reload-authorization/reload-authorization.component';
import { NgModule } from '@angular/core';
import {
  RouterModule,
  Routes,
} from '@angular/router';
import { MainComponent } from './main.component';
import { NgxPermissionsGuard } from 'ngx-permissions';
import { AuthGuard } from 'src/app/auth/auth-guard.service';
import { ModuleComponent } from './module/module.component';
import { ModuleApprovalComponent } from './module-approval/module-approval.component';
import { AuthorizationComponent } from './pages/authorization/authorization.component';

const routes: Routes = [
  {
    path: '',
    component: MainComponent,
    canActivate: [AuthGuard],
    children: [
      { path: '', redirectTo: 'pm', pathMatch: 'full' },
    
      {
        path: 'modules',
        component: ModuleComponent,
        canActivate: [NgxPermissionsGuard],
        data: {
          breadcrumb: 'All Menu',
          permissions: {
            only: ['USER'],
            redirectTo: '/login'
          }
        }
      },
      {
        path: 'modules-approval',
        component: ModuleApprovalComponent,
        canActivate: [NgxPermissionsGuard],
        data: {
          breadcrumb: 'All Approval',
          permissions: {
            only: ['USER'],
            redirectTo: '/login'
          }
        }
      },
    
      {
        path: 'authorization',
        component: AuthorizationComponent,
        canLoad: [NgxPermissionsGuard],
        data: {
          permissions: {
            only: ['USER'],
            redirectTo: '/login'
          }
        }
      },
     
      {
        path: 'pm',
        loadChildren: () =>
          import('./pages/pm/pm.module').then(m => m.PmModule),
        canActivate: [NgxPermissionsGuard],
        data: {
          permissions: {
            only: ['USER'],
            redirectTo: '/login'
          }
        }
      },
    
      {
        path: 'reload-authorization',
        component: ReloadAuthorizationComponent
      },
     
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class MainRoutingModule { }
