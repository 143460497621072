import { Component, OnInit } from '@angular/core';
import { LandingpageService } from 'src/app/_services/landingpage.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-gallery5g',
  templateUrl: './gallery5g.component.html',
  styleUrls: ['./gallery5g.component.scss']
})
export class Gallery5gComponent implements OnInit {

  dataGallery: any[];
  total: number;
  page: number = 1;
  pageSize: number = 12;

  constructor(
    private landingService: LandingpageService
  ) { }

  ngOnInit() {
    this.loadData();
  }

  loadData() {
    this.landingService.getGalleryContent({page: this.page, pageSize: this.pageSize}).subscribe(
      response => {
        if (response.status) {
          this.dataGallery = response.result.row;
          this.total = response.result.total;
        } else {
          Swal.fire('User Login', response.message, 'warning');
        }
      }, error => {
        Swal.fire('User Login', error.error.message, 'error');
      }
    );
  }

  previousPage() {
    this.page -= 1;
    this.loadData();
  }
  
  nextPage() {
    this.page += 1;
    this.loadData();
  }

}
