import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { GlobalService } from './global.service';
import { CookieService } from 'ngx-cookie-service';
import { ResponseApiModel } from '../_models/response-api.model';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';


const API_ADD_STATUS = '/home/add-status';
const API_DELETE_STATUS = '/home/delete-status';
const API_ADD_COMMENT = '/home/add-comment';
const API_DELETE_COMMENT = '/home/delete-comment';
const API_GET_STATUSES = '/home/get-statuses';
const API_GET_BLOGS = '/home/get-blogs';
const API_GET_TASKS = '/home/get-tasks';
const API_GET_FAQS = '/home/get-faqs';
const API_GET_DATA_USER = '/home/get-data-user';
const API_GET_MY_TASK = '/home/my-task';
const API_CHANGE_STATUS_TASK = '/home/change-status-task';
const API_MY_ASSET = '/home/my-asset';
const API_MY_APPROVAL = '/home/my-approval';
const API_READ_NOTIF = '/home/read-notif';
const API_APPROVAL = '/home/get-approval';

@Injectable({
  providedIn: 'root'
})
export class HomeService {

  constructor(
    private http: HttpClient,
    private globalService: GlobalService,
    private cookieService: CookieService
  ) { }

  public header() {
    return {
      headers: new HttpHeaders()
        .set('token', this.cookieService.get('_q'))
    };
  }

  public addStatus(param: { status: string, files: any }) {
    return this.http.post<ResponseApiModel>(
      this.globalService.apiVersionHost + API_ADD_STATUS, param, this.header()
    ).pipe(
      map(response => response)
    );
  }

  public deleteStatus(param: { id_status: number }) {
    return this.http.post<ResponseApiModel>(
      this.globalService.apiVersionHost + API_DELETE_STATUS, param, this.header()
    ).pipe(
      map(response => response)
    );
  }

  public addComment(param: { id_status: number, comment: string }) {
    return this.http.post<ResponseApiModel>(
      this.globalService.apiVersionHost + API_ADD_COMMENT, param, this.header()
    ).pipe(
      map(response => response)
    );
  }

  public deleteComment(param: { id_comment: number }) {
    return this.http.post<ResponseApiModel>(
      this.globalService.apiVersionHost + API_DELETE_COMMENT, param, this.header()
    ).pipe(
      map(response => response)
    );
  }

  public getStatuses(param: any) {
    return this.http.post<ResponseApiModel>(
      this.globalService.apiVersionHost + API_GET_STATUSES, param, this.header()
    ).pipe(
      map(response => response)
    );
  }

  public getBlogs() {
    return this.http.post<ResponseApiModel>(
      this.globalService.apiVersionHost + API_GET_BLOGS, null, this.header()
    ).pipe(
      map(response => response)
    );
  }

  public getTasks() {
    return this.http.post<ResponseApiModel>(
      this.globalService.apiVersionHost + API_GET_TASKS, null, this.header()
    ).pipe(
      map(response => response)
    );
  }

  public getFaqs() {
    return this.http.post<ResponseApiModel>(
      this.globalService.apiVersionHost + API_GET_FAQS, null, this.header()
    ).pipe(
      map(response => response)
    );
  }

  public getDataUser() {
    return this.http.post<ResponseApiModel>(
      this.globalService.apiVersionHost + API_GET_DATA_USER, null, this.header()
    ).pipe(
      map(response => response)
    );
  }

  public getMyTask(param: { pageSize: number, filter: any }): Observable<ResponseApiModel> {
    return this.http.post<ResponseApiModel>(
      this.globalService.apiVersionHost + API_GET_MY_TASK, param, this.header()
    ).pipe(
      map(response => response)
    );
  }

  public changeStatus(param: { hash: string, status: string }): Observable<ResponseApiModel> {
    return this.http.post<ResponseApiModel>(
      this.globalService.apiVersionHost + API_CHANGE_STATUS_TASK, param, this.header()
    ).pipe(
      map(response => response)
    );
  }

  public getMyAsset(param: { pageSize: number, page: number, filter: any }) {
    return this.http.post<ResponseApiModel>(
      this.globalService.apiVersionHost + API_MY_ASSET, param, this.header()
    ).pipe(
      map(response => response)
    );
  }

  public getMyApproval(param: any) {
    return this.http.post<ResponseApiModel>(
      this.globalService.apiVersionHost + API_MY_APPROVAL, param, this.header()
    ).pipe(
      map(response => response)
    );
  }

  public readNotif(param: { id: number }) {
    return this.http.post<ResponseApiModel>(
      this.globalService.apiVersionHost + API_READ_NOTIF, param, this.header()
    ).pipe(
      map(response => response)
    );
  }

  public getApproval() {
    return this.http.post<ResponseApiModel>(
      this.globalService.apiVersionHost + API_APPROVAL, null, this.header()
    ).pipe(
      map(response => response)
    );
  }

  public getNdsInfo() {
    return this.http.post<ResponseApiModel>(
      this.globalService.apiVersionHost + '/home/get-nds-info', null, this.header()
    ).pipe(
      map(response => response)
    );
  }
}
