import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { LandingpageService } from 'src/app/_services/landingpage.service';
import Swal from 'sweetalert2';
import { GlobalService } from 'src/app/_services/global.service';

@Component({
  selector: 'app-survey',
  templateUrl: './survey.component.html',
  styleUrls: ['./survey.component.scss']
})
export class SurveyComponent implements OnInit {

  id_voucher = this.actRoute.snapshot.params['voucher'];
  dataBefore: any;
  isStart: boolean = false;
  exams: any;
  answers: any;
  activeIndeks: number;
  answerMapping = { A : 0, B : 1, C : 2, D : 3, E : 4, F : 5, G : 6, H : 7, I : 8, J : 9 };
  examsSectionActive: any = {};
  oneExamsSectionActive: any = {};
  apiMapping = [ 'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J' ];
  total_section: any;
  listAnswer: any = [];
  apiHost: string;

  constructor(
    private actRoute: ActivatedRoute,
    private ngxLoader: NgxUiLoaderService,
    private landingService: LandingpageService,
    private router: Router,
    private globalService: GlobalService

  ) { }

  ngOnInit() {
    this.apiHost = this.globalService.apiHost;
    console.log(this.id_voucher);
    this.beforeExam();
  }

  beforeExam() {
    this.ngxLoader.start();
    this.landingService.beforeExam(this.id_voucher).subscribe(response => {
      (response.status) ? (
        this.dataBefore = response.result
      ) : this.showSwal('Info!', response.message);
      this.ngxLoader.stop();
    }, error => {
      this.ngxLoader.stop();
      this.showSwal('Perhatian..', error.error.message);
    });
  }

  reloadPage() {
    this.dataBefore = undefined;
    this.beforeExam();
  }

  doingExam() {
    this.ngxLoader.start();
    this.landingService.doingExam(this.id_voucher).subscribe(response => {
      if (response.status) {
        this.exams = response.result.row.model;
        this.answers = (response.result.row.jawaban) ? response.result.row.jawaban : {};
        this.total_section = response.result.row.total_section;
        this.isStart = true;
        this.formatAnswer();
        this.activeIndeks = 1;
        this.examsSectionActive = this.exams.filter(item => item.section == this.activeIndeks);
        this.oneExamsSectionActive = this.examsSectionActive[0];
       }
      else {
        this.showSwal('Info!', response.message);
      }
      this.ngxLoader.stop();
    }, error => {
      this.ngxLoader.stop();
      this.showSwal('Perhatian..', error.error.message);
    });
  }

  formatAnswer() {
    for (const i in this.answers) {
      if (Array.isArray(this.answers[i])) {
        const temp = ['', '', '', '', '', '', '', '', '', ''];
        for (let j = 0; j < this.answers[i].length; j++) {
          temp.splice(this.answerMapping[this.answers[i][j]], 1, this.answers[i][j]);
        }
        this.answers[i] = temp;
      }
    }
  }

  formatList(row) {
    this.listAnswer = [];
    for (let i = 0; i < 10; i++) {
      let getJawabanRow = row[this.apiMapping[i]];
      if (getJawabanRow !== null && getJawabanRow !== ''){
        this.listAnswer.push({key: getJawabanRow, val: getJawabanRow});
      }
    }
    return this.listAnswer;
  }

  formatQuestion(row) {
    return row.soal.split('] ')[1];
  }

  formatTipe(row) {
    return (row.soal.substring(
      row.soal.lastIndexOf('[') + 1, row.soal.lastIndexOf(']')
    )).toLowerCase();
  }

  onAnswering(idDetailUjian: number, answer: string) {
    this.answers[idDetailUjian] = answer;
  }

  onAnsweringMultiple(idDetailUjian: number, answer: string) {
    if (!this.answers[idDetailUjian]) {
      this.answers[idDetailUjian] = ['', '', '', '', '', '', '', '', '', ''];
    }
    (this.answers[idDetailUjian].includes(answer)) ? (
      this.answers[idDetailUjian].splice(this.answerMapping[answer], 1, '')
    ) : this.answers[idDetailUjian].splice(this.answerMapping[answer], 1, answer);
  }

  onAnsweringUpload($event, idDetailUjian: number, answer: string) {
    console.log($event);
    const urlWasaby = this.apiHost+'/site/download-profile/'+$event.id ;//$event.id.toString();
    const fileName = $event.filename.toString();
    const id_attachment = $event.id.toString();

    console.log(answer);
    console.log(id_attachment);

    this.answers[idDetailUjian] = id_attachment;
  }

  checkingAnswer(answers: any, answer: string) {
    return (answers) ? answers.includes(answer) : false;
  }

  navigation(next: boolean) {
    this.activeIndeks = (next) ? (this.activeIndeks + 1) : (this.activeIndeks - 1);
    this.examsSectionActive = this.exams.filter(item => item.section == this.activeIndeks);
    this.oneExamsSectionActive = this.examsSectionActive[0];
  }

  submitExam() {
    Swal.fire({
      title: 'Apakah anda yakin?',
      text: 'Ujian anda akan disimpan ke dalam sistem!',
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Ya, saya yakin!'
    }).then((result) => {
      if (result.value) {
        const param = {
          idujian: this.dataBefore.obj_exam.id_ujian,
          voucher: this.id_voucher,
          jawaban: this.answers
        };

        const tempJawaban = [];
        const tempExam = this.exams;
        param.jawaban.forEach(function(value, key) {
          console.log(key);
          console.log(value);
          if (value !== null) {
            // tempJawaban[key] = value;
            let findSoal = tempExam.find(item => item.id_detail_ujian == key);
            console.log(findSoal);
            if (findSoal) {
              let index1 = tempExam.indexOf(findSoal);
              if (index1 !== -1) {
                tempJawaban.push({soal: tempExam[index1]['soal'], jawaban_user: value, id_detail_ujian: key});
              }
            }
          }
        });
        console.log(tempJawaban);
        param.jawaban = tempJawaban;
    
        this.landingService.saveAnswer(param).subscribe(response => {
          if (response.status) {
            this.showSwal('Info!', 'Terima kasih. Jawaban Berhasil disimpan.'); 
            this.router.navigate(['/home']);
          } else {
            this.showSwal('Info!', response.message);
          }
        }, error => {
          this.showSwal('Perhatian..', error.error.message);
        });
      }
    });
  }

  showSwal(title: string, text: string) {
    Swal.fire({
      title: title,
      text: text,
      type: 'warning',
      showCancelButton: false,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'OK',
      allowOutsideClick: false
    }).then((result) => {
      if (result.value) {
        return false;
      }
    });
  }
}