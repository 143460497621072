/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./module-approval.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "@angular/router";
import * as i4 from "../../shared/_pipes/safe-html";
import * as i5 from "@angular/platform-browser";
import * as i6 from "../../../../../node_modules/ngx-ui-loader/ngx-ui-loader.ngfactory";
import * as i7 from "ngx-ui-loader";
import * as i8 from "./module-approval.component";
import * as i9 from "../../../_services/user.service";
import * as i10 from "../../../_services/module-approval.service";
import * as i11 from "@angular/service-worker";
var styles_ModuleApprovalComponent = [i0.styles];
var RenderType_ModuleApprovalComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ModuleApprovalComponent, data: {} });
export { RenderType_ModuleApprovalComponent as RenderType_ModuleApprovalComponent };
function View_ModuleApprovalComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "span", [["class", "badge-counter"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "span", [["class", "badge badge-danger"]], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, ["", ""]))], null, function (_ck, _v) { var currVal_0 = _v.parent.parent.context.$implicit.count; _ck(_v, 3, 0, currVal_0); }); }
function View_ModuleApprovalComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 4, "a", [["class", "icon-item rounded rounded-lg mx-auto d-block bg-white shadow mb-3"]], [[8, "href", 4]], null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ModuleApprovalComponent_3)), i1.ɵdid(3, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(4, 0, null, null, 1, ":svg:svg", [[":xmlns:xlink", "http://www.w3.org/1999/xlink"], ["class", "kt-svg-icon"], ["height", "24px"], ["version", "1.1"], ["viewBox", "0 0 24 24"], ["width", "24px"], ["xmlns", "http://www.w3.org/2000/svg"]], [[8, "innerHTML", 1]], null, null, null, null)), i1.ɵppd(5, 1), (_l()(), i1.ɵeld(6, 0, null, null, 1, "a", [["class", " text-white"]], [[8, "href", 4]], null, null, null, null)), (_l()(), i1.ɵted(7, null, [" ", " "]))], function (_ck, _v) { var currVal_1 = ((_v.parent.context.$implicit.serial > 1) && (_v.parent.context.$implicit.count > 0)); _ck(_v, 3, 0, currVal_1); }, function (_ck, _v) { var currVal_0 = i1.ɵinlineInterpolate(1, "", _v.parent.context.$implicit.url, ""); _ck(_v, 1, 0, currVal_0); var currVal_2 = i1.ɵunv(_v, 4, 0, _ck(_v, 5, 0, i1.ɵnov(_v.parent.parent, 0), _v.parent.context.$implicit.icon_svg)); _ck(_v, 4, 0, currVal_2); var currVal_3 = i1.ɵinlineInterpolate(1, "", _v.parent.context.$implicit.url, ""); _ck(_v, 6, 0, currVal_3); var currVal_4 = _v.parent.context.$implicit.menu_label; _ck(_v, 7, 0, currVal_4); }); }
function View_ModuleApprovalComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "span", [["class", "badge-counter"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "span", [["class", "badge badge-danger"]], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, ["", ""]))], null, function (_ck, _v) { var currVal_0 = _v.parent.parent.context.$implicit.count; _ck(_v, 3, 0, currVal_0); }); }
function View_ModuleApprovalComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 11, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 6, "a", [["class", "icon-item rounded rounded-lg mx-auto d-block bg-white shadow mb-3"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 2).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(2, 671744, null, 0, i3.RouterLinkWithHref, [i3.Router, i3.ActivatedRoute, i2.LocationStrategy], { routerLink: [0, "routerLink"] }, null), i1.ɵpad(3, 1), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ModuleApprovalComponent_5)), i1.ɵdid(5, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(6, 0, null, null, 1, ":svg:svg", [[":xmlns:xlink", "http://www.w3.org/1999/xlink"], ["class", "kt-svg-icon"], ["height", "24px"], ["version", "1.1"], ["viewBox", "0 0 24 24"], ["width", "24px"], ["xmlns", "http://www.w3.org/2000/svg"]], [[8, "innerHTML", 1]], null, null, null, null)), i1.ɵppd(7, 1), (_l()(), i1.ɵeld(8, 0, null, null, 3, "a", [["class", " text-white"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 9).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(9, 671744, null, 0, i3.RouterLinkWithHref, [i3.Router, i3.ActivatedRoute, i2.LocationStrategy], { routerLink: [0, "routerLink"] }, null), i1.ɵpad(10, 1), (_l()(), i1.ɵted(11, null, [" ", " "]))], function (_ck, _v) { var currVal_2 = _ck(_v, 3, 0, _v.parent.context.$implicit.url); _ck(_v, 2, 0, currVal_2); var currVal_3 = ((_v.parent.context.$implicit.serial > 1) && (_v.parent.context.$implicit.count > 0)); _ck(_v, 5, 0, currVal_3); var currVal_7 = _ck(_v, 10, 0, _v.parent.context.$implicit.url); _ck(_v, 9, 0, currVal_7); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 2).target; var currVal_1 = i1.ɵnov(_v, 2).href; _ck(_v, 1, 0, currVal_0, currVal_1); var currVal_4 = i1.ɵunv(_v, 6, 0, _ck(_v, 7, 0, i1.ɵnov(_v.parent.parent, 0), _v.parent.context.$implicit.icon_svg)); _ck(_v, 6, 0, currVal_4); var currVal_5 = i1.ɵnov(_v, 9).target; var currVal_6 = i1.ɵnov(_v, 9).href; _ck(_v, 8, 0, currVal_5, currVal_6); var currVal_8 = _v.parent.context.$implicit.menu_label; _ck(_v, 11, 0, currVal_8); }); }
function View_ModuleApprovalComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 4, "div", [["class", "col-4 col-md-3 col-lg-2 text-center mb-5 pb-4"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ModuleApprovalComponent_2)), i1.ɵdid(3, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ModuleApprovalComponent_4)), i1.ɵdid(5, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.cekLink(_v.context.$implicit.module_key); _ck(_v, 3, 0, currVal_0); var currVal_1 = !_co.cekLink(_v.context.$implicit.module_key); _ck(_v, 5, 0, currVal_1); }, null); }
export function View_ModuleApprovalComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i4.SafeHtml, [i5.DomSanitizer]), (_l()(), i1.ɵeld(1, 0, null, null, 7, "div", [["class", "kt-content kt-grid__item kt-grid__item--fluid"], ["id", "kt_content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 6, "div", [["class", "row justify-content-center pt-75 pb-5 "]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 5, "div", [["class", "col-lg-11"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 4, "div", [["class", "row justify-content-center search-icons"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 1, "ngx-ui-loader", [], null, null, null, i6.View_ɵb_0, i6.RenderType_ɵb)), i1.ɵdid(6, 770048, null, 0, i7.ɵb, [i5.DomSanitizer, i1.ChangeDetectorRef, i7.NgxUiLoaderService], { fgsType: [0, "fgsType"], loaderId: [1, "loaderId"], hasProgressBar: [2, "hasProgressBar"], text: [3, "text"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ModuleApprovalComponent_1)), i1.ɵdid(8, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "three-bounce"; var currVal_1 = "menu-loader"; var currVal_2 = false; var currVal_3 = "Please Wait..."; _ck(_v, 6, 0, currVal_0, currVal_1, currVal_2, currVal_3); var currVal_4 = _co.menuList; _ck(_v, 8, 0, currVal_4); }, null); }
export function View_ModuleApprovalComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-module-approval", [], null, null, null, View_ModuleApprovalComponent_0, RenderType_ModuleApprovalComponent)), i1.ɵdid(1, 114688, null, 0, i8.ModuleApprovalComponent, [i9.UserService, i10.ModuleApprovalService, i11.SwUpdate, i7.NgxUiLoaderService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ModuleApprovalComponentNgFactory = i1.ɵccf("app-module-approval", i8.ModuleApprovalComponent, View_ModuleApprovalComponent_Host_0, {}, {}, []);
export { ModuleApprovalComponentNgFactory as ModuleApprovalComponentNgFactory };
