import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { CookieService } from 'ngx-cookie-service';
import { GlobalService } from './global.service';
import { Observable, Subject } from 'rxjs';
import { ResponseApiModel } from '../_models/response-api.model';
import { map } from 'rxjs/operators';

const API_GET_MY_TIMESHEETS = '/timesheet/get-my-timesheets';
const API_SET_AS_FINISH = '/timesheet/set-as-finish';
const API_GET_MORE_TIMESHEETS = '/timesheet/get-more-timesheets';
const API_SAVE_TIMESHEET = '/timesheet/save';

@Injectable({
  providedIn: 'root'
})
export class TimesheetService {

  public newTimesheetEmit: Subject<boolean> = new Subject<boolean>();
  public loadNewTimesheetEmitObs = this.newTimesheetEmit.asObservable();

  constructor(
    private http: HttpClient,
    private cookieService: CookieService,
    private globalService: GlobalService
  ) {

  }

  public header() {
    return {
      headers: new HttpHeaders()
        .set('token', this.cookieService.get('_q'))
    };
  }

  public getMyTimesheets(param: { filter: any }): Observable<ResponseApiModel> {
    return this.http.post<ResponseApiModel>(
      this.globalService.apiVersionHost + API_GET_MY_TIMESHEETS, param, this.header()
    ).pipe(
      map(response => response)
    );
  }

  public getMoreTimesheets(param): Observable<ResponseApiModel> {
    return this.http.post<ResponseApiModel>(
      this.globalService.apiVersionHost + API_GET_MORE_TIMESHEETS, param, this.header()
    ).pipe(
      map(response => response)
    );
  }

  public setAsFinish(param): Observable<ResponseApiModel> {
    return this.http.post<ResponseApiModel>(
      this.globalService.apiVersionHost + API_SET_AS_FINISH, param, this.header()
    ).pipe(
      map(response => response)
    );
  }

  public saveTimesheet(param): Observable<ResponseApiModel> {
    return this.http.post<ResponseApiModel>(
      this.globalService.apiVersionHost + API_SAVE_TIMESHEET, param, this.header()
    ).pipe(
      map(response => response)
    );
  }

}
