import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-about5g',
  templateUrl: './about5g.component.html',
  styleUrls: ['./about5g.component.scss']
})
export class About5gComponent implements OnInit {

  show: boolean[] = [
    true,
    false,
    false,
    false,
    false,
    false,
    false,
    false
  ];

  constructor() { }

  ngOnInit() {
  }

  expand(i) {
    const temp = this.show[i];
    this.show = this.show.map( item => {
      item = false;
      return item;
    });
    this.show[i] = !temp;
  }

}
