import { Component, OnInit, Renderer2 } from '@angular/core';
import { LayoutService } from 'src/app/_services/layout.service';

@Component({
  selector: 'app-header-mobile',
  templateUrl: './header-mobile.component.html',
  styleUrls: ['./header-mobile.component.scss']
})
export class HeaderMobileComponent implements OnInit {

  sideMenu: boolean = false;
  headerMenu: boolean = false;
  topBar: boolean = false;

  constructor(
    private layoutSerice: LayoutService
  ) { 
    this.layoutSerice.mobileMenuToggleEmitObs.subscribe(
      res => {
        this.sideMenu = res;
      }
    );
    this.layoutSerice.mobileHeaderMenuToggleEmitObs.subscribe(
      res => {
        this.headerMenu = res;
      }
    );
    this.layoutSerice.topBarToggleEmitObs.subscribe(
      res => {
        this.topBar = res;
      }
    );
  }

  ngOnInit() {
  }

  toggleSideLeft(){
    this.sideMenu = !this.sideMenu;
    this.layoutSerice.mobileMenuToggleEmit.next(this.sideMenu);
  }

  toggleHeaderMenu(){
    this.headerMenu = !this.headerMenu;
    this.layoutSerice.mobileHeaderMenuToggleEmit.next(this.headerMenu);
  }

  toggleTopbar(){
    this.topBar = !this.topBar;
    this.layoutSerice.topBarToggleEmit.next(this.topBar);
  }


}
