<div id="nds_contents">
  <div class="bg-white navbar-spacer"></div>
  <div class="pagecomponentwrap text-center text-white " id="pageheader">
    <div class="container pb-5 pt-75">
      <div class="no-gutters row justify-content-center align-items-center aos-init aos-animate" data-aos="">
        <div class="col-md-10 aos-init aos-animate" data-aos="flip-up">
          <h2 class="stack_font2 h1 ">
            {{blog ? blog.title : 'Please Wait..'}}
          </h2>
          <nav class="stack_font3 text-white small pb-3 " aria-label="breadcrumb">
            <ol class="breadcrumb-page text-capitalize">
              <li class="breadcrumb-item-page">
                <a class="stack_font3 text-white small pb-3" [routerLink]="['/home']" routerLinkActive="router-link-active">
                  Home
                </a>
              </li>
              <li class="breadcrumb-item-page">
                /
              </li>
              <li class="breadcrumb-item-page">
                <a href="./blog/blog.html" class="stack_font3 text-white small pb-3" [routerLink]="['/blog']" routerLinkActive="router-link-active">
                  Blog
                </a>
              </li>
              <li class="breadcrumb-item-page">
                /
              </li>
              <li class="breadcrumb-item-page active">
                <a href="javascript:" (click)="reloadPage(blog.slug)" class="stack_font3 text-white small pb-3">
                  {{blog ? blog.title : ''}}
                </a>
              </li>
            </ol>
          </nav>
        </div>
      </div>
    </div>
  </div>
  <div class="bg-white pt-1">
    <div class="container">
      <div class="w-75 float-left">
        <div class="template-page-main">
          <ng-container *ngIf="blog else skeleton">
            <a id="komponen-402" class="clear"></a>
            <div class="pagecomponentwrap ">
              <div class=" pb-4 pt-4">
                <div class="no-gutters row justify-content-center aos-init aos-animate" data-aos="fade-down">
                  <div class="col-12 aos-init aos-animate" data-aos="">
                    <div class="description stack_font1 ">
                      <img [src]="blog.picture" alt="" class="w-75 h-75"><br /><br />
                      <div class="entry-content notopmargin" [innerHtml]="blog.content"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="clear"></div>
            <div class="row no-gutters mt-1 mb-4 pt-3 pb-1 bg-light">
              <div class="col-6 text-left pt-2 pl-3">
                <a [routerLink]="['/blog-category', blog.slug_category]" class="btn btn-sm bg-white text-color2 text-color2_dark-hover border">
                  <i class="fa fa-hashtag"></i>
                  {{blog.category}}
                </a>
                <a href="javascript" (click)="reloadPage(blog.slug)" class="btn btn-sm bg-white text-color2 text-color2_dark-hover border">
                  {{blog.title}}
                </a>
              </div>
              <div class="col-6 text-right">
                <div class="float-right pr-3">
                  <div class="media">
                    <div class="media-body">
                      <h6 class="mb-1">
                        {{blog.nama}}
                      </h6>
                      <p class="small text-secondary">
                        <i class="fa fa-calendar-alt"></i>
                        {{!blog.date ? 'not set' : blog.date | date : "d MMM y"}}
                        {{!blog.date ? '' : blog.date | date : "HH"}}
                        {{!blog.date ? '' : ':'}}
                        {{!blog.date ? '' : blog.date | date : "mm"}}
                      </p>
                    </div>
                    <div class="d-flex ml-3">
                      <img [src]="blog.photo" onerror="src='/assets/media/error/noimage.png'" class="rounded rounded border" width="40" height="40">
                    </div>
                  </div>
                </div>
                <div class="clear"></div>
              </div>
            </div>
          </ng-container>
        </div>
      </div>
      <app-sidebar [categories]="categories" [populars]="populars" (reloadPost)="reloadPage($event)"></app-sidebar>
      <div class="clear"></div>
    </div>
  </div>
  <div class="clear"></div>
</div>

<ng-template #skeleton>

  <div class="row">
    <div class="col-12">
      <div class="skeleton-loader"></div>
    </div>
  </div>

  <div class="row">
    <div class="col-12">
      <div class="skeleton-loader"></div>
    </div>
  </div>

  <div class="row">
    <div class="col-12">
      <div class="skeleton-loader tags-loader"></div>
    </div>
  </div>

</ng-template>