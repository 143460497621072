import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ResponseApiModel } from '../_models/response-api.model';
import { GlobalService } from './global.service';

const API_GET_LIST = '/modules-approval/get-list';

@Injectable({
  providedIn: 'root'
})
export class ModuleApprovalService {

  constructor(
    private http: HttpClient,
    private globalService: GlobalService,
    private cookieService: CookieService
  ) { }

  public header() {
    return {
      headers: new HttpHeaders()
        .set('token', this.cookieService.get('_q'))
    };
  }

  public getList(): Observable<ResponseApiModel> {
    return this.http.post<ResponseApiModel>(
      this.globalService.apiVersionHost + API_GET_LIST, null, this.header()
    ).pipe(
      map(response => response)
    );
  }
}
