import { Component, OnInit, Input, ViewChild, Output, EventEmitter, SimpleChanges } from '@angular/core';
import { Observable, Subject, merge } from 'rxjs';
import { debounceTime, map, distinctUntilChanged, filter } from 'rxjs/operators';
import { NgbTypeahead } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-dynamic-select',
  templateUrl: './dynamic-select.component.html',
  styleUrls: ['./dynamic-select.component.scss']
})
export class DynamicSelectComponent implements OnInit {

  @Input() id: string;
  @Input() placeholder: string;
  @Input() isDisabled = false;
  @Input() isReadonly = false;
  @Input() dataOptions: {key: any, val: string}[];
  @Input() selectedItem: any;
  @Input() classes = 'form-control';
  @Input() widthInput = '';
  @Input() nyicil: boolean;

  @ViewChild('instance', { static: false }) instance: NgbTypeahead;
  focus$ = new Subject<string>();
  click$ = new Subject<string>();
  dblclick$ = new Subject<string>();

  @Output() selected = new EventEmitter<any>();
  @Output() changed = new EventEmitter<any>();
  @Output() enter = new EventEmitter<any>();
  @Output() esc = new EventEmitter<any>();
  @Output() focus = new EventEmitter<any>();
  @Output() blur = new EventEmitter<any>();
  @Output() nyicilEvent = new EventEmitter<string>();

  public model: any;

  constructor() {}

  ngOnInit() { }

  search = (text$: Observable<string>) => {
    const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
    const clicksWithClosedPopup$ = this.click$.pipe(filter(() => this.instance.isPopupOpen()));
    const inputFocus$ = this.focus$;

    return merge(debouncedText$, inputFocus$, clicksWithClosedPopup$).pipe(
      map(term => (term === '' ? this.dataOptions
        : this.dataOptions.filter(v => v.val.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 10))
    );
  }

  formatter = (x: { val: string }) => x.val;

  getSelected($event) {
    this.selected.emit($event.item);
  }

  getIputChange($event) {
    const temp = this.dataOptions.filter((el: any) => el.key === $event)[0];
    this.changed.emit(temp);
  }

  getValueChange(val) {
    if (this.nyicil) { if (val.length >= 4) { this.nyicilEvent.emit(val); } }
  }

  getEnterChange(val) {
    const temp = this.dataOptions.filter((el: any) => el.key === val);
    this.enter.emit(temp[0]);
  }

  resetChange() {
    this.esc.emit(1);
  }

  onFocus() {
    this.focus.emit(1);
  }

  onBlur() {
    this.blur.emit(1);
  }

}
