import { AfterViewInit, Component, OnInit, Renderer2 } from '@angular/core';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { MenuConfig } from 'src/app/config/menu';
import { UserService } from 'src/app/_services/user.service';
import { LayoutService } from 'src/app/_services/layout.service';
import { PmcService } from 'src/app/_services/pmc.service';
import { AuthenticationService } from 'src/app/auth/authentication.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import Swal from 'sweetalert2';
import * as introJs from 'intro.js/intro.js';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit, AfterViewInit {

  currentUser: any = {};
  isDeveloper: boolean = false;
  isInternal: boolean;
  sidebarExpand: boolean = true;
  menuSection: string = '';
  moduleActive: string = '';
  pageActive: string = '';
  modules: any = this.menuConfig.config.menuSection;
  menuList: any = [];
  sideMenu: boolean;

  pmcMenuToggle: boolean = false;
  pmcItem: any[] = [];
  currentItem: any = {};
  pathActived: any = {};

  dashboardOpen: boolean;

  keyModule: any;

  infoDomain = 'https://info.ptnw.net/';

  constructor(
    private renderer: Renderer2,
    private router: Router,
    public menuConfig: MenuConfig,
    public activatedRoute: ActivatedRoute,
    public userService: UserService,
    private layoutSerice: LayoutService,
    private pmcService: PmcService,
    private authenticationService: AuthenticationService,
    private loader: NgxUiLoaderService
  ) {
    this.currentUser = this.userService.payloadToken().data;

    router.events.subscribe((val) => {
      if (val instanceof NavigationEnd) {
        let arr_url = this.router.url.split('/');
        // console.log(arr_url);
        if (arr_url[1] == 'main') {
          this.moduleActive = arr_url[2];
          this.keyModule = this.getKeyModule(this.moduleActive);
          this.menuSection = this.modules[this.moduleActive];

          const navigation = this.router.getCurrentNavigation();

          if (navigation) {
            this.pageActive = (navigation.extras.state) ? navigation.extras.state.pageActive : '';
          } else {
            this.pageActive = arr_url[arr_url.length - 1];
          }



          let temp = this.menuConfig.config.aside.filter(el => el.module === this.moduleActive);
          if (temp) {
            this.menuList = temp[0]['items'] ? temp[0]['items'] : [];
          }
          else {
            this.menuList = [];
          }

          if (this.moduleActive == 'pmc') {
            let hashCurrent = arr_url[4];
            this.getRootItem(hashCurrent);
          }


          if (this.pageActive == 'all-request' || this.pageActive == 'org-chart-official') {
            this.renderer.removeClass(document.body, 'kt-aside--minimize-hover');
            this.sidebarExpand = false;
          }

          this.reloadMenu();
        }
      }
    });

    this.layoutSerice.mobileMenuToggleEmitObs.subscribe(
      res => {
        this.sideMenu = res;
        if (this.sideMenu) {
          this.renderer.addClass(document.body, 'kt-aside--on');
        } else {
          this.renderer.removeClass(document.body, 'kt-aside--on');
        }
      }
    );
  }

  ngOnInit() {
    if (this.currentUser.user_ptnw_status == 'Internal') {
      let roles = this.authenticationService.getUserRoles('pmc');
      if (roles) {
        this.authenticationService.loadPermission(roles);
      }
      else {
        this.authenticationService.getRolesToken({ module: 'pmc' }).subscribe(
          response => {
            if (response.status) {
              let roles = this.authenticationService.getUserRoles('pmc');
              if (roles) {
                this.authenticationService.loadPermission(roles);
              }
            }
          }
        );
      }

    }

    let arr_url = this.router.url.split('/');
    this.menuSection = this.modules[arr_url[2]];
    let payload = this.userService.payloadToken();
    this.isDeveloper = (payload.data.position == 'Developer' || payload.data.position == 'Programmer' || payload.username == 'PTNW0385') ? true : false;
    this.isInternal = this.userService.getUserPtnwStatus();
  }

  ngAfterViewInit() {
    if (!localStorage.getItem('intro-my-approval') && this.currentUser.user_ptnw_status == 'Internal') {
      this.guideApprovalAll();
      localStorage.setItem('intro-my-approval', 'true');
    }
  }

  ngAfterContentInit() {
    let arr_url = this.router.url.split('/');
    if (arr_url[1] == 'main') {
      this.moduleActive = arr_url[2];

      this.menuSection = this.modules[this.moduleActive];

      const navigation = this.router.getCurrentNavigation();
      if (navigation) {
        this.pageActive = (navigation.extras.state) ? navigation.extras.state.pageActive : '';
      } else {
        this.pageActive = arr_url[arr_url.length - 1];
      }

      this.reloadMenu();
    }
  }

  reloadMenu() {
    let temp = this.menuConfig.config.aside.filter(el => el.module === this.moduleActive);
    if (temp && temp[0]) {
      this.menuList = temp[0]['items'];
    }
    else {
      this.menuList = [];
    }

    // get module for reload authorization
    if (this.moduleActive == 'vendor_care') { this.keyModule = 'vc'; }
    else if (this.moduleActive == 'blog') { this.keyModule = 'blog'; }
    else if (this.moduleActive == 'faq') { this.keyModule = 'faq'; }
    else { this.keyModule = this.moduleActive; }

    if (this.pageActive == 'all-request' || this.pageActive == 'org-chart-official') {
      this.renderer.removeClass(document.body, 'kt-aside--minimize-hover');
      this.sidebarExpand = false;
    }
  }

  sidebarToggle() {
    if (this.sidebarExpand) {
      this.renderer.addClass(document.body, 'kt-aside--minimize');
      this.sidebarExpand = !this.sidebarExpand;
    }
    else {
      this.renderer.removeClass(document.body, 'kt-aside--minimize-hover');
      this.sidebarExpand = !this.sidebarExpand;
    }
  }

  mouseEnter() {
    if (!this.sidebarExpand) {
      this.renderer.removeClass(document.body, 'kt-aside--minimize');
      this.renderer.addClass(document.body, 'kt-aside--minimize-hover');
    }
  }

  mouseLeave() {
    if (!this.sidebarExpand) {
      this.renderer.removeClass(document.body, 'kt-aside--minimize-hover');
      this.renderer.addClass(document.body, 'kt-aside--minimize');
    }
  }

  toggleSideLeft() {
    this.sideMenu = !this.sideMenu;
    if (this.sideMenu) {
      this.renderer.addClass(document.body, 'kt-aside--on');
    } else {
      this.renderer.removeClass(document.body, 'kt-aside--on');
    }
    this.layoutSerice.mobileMenuToggleEmit.next(this.sideMenu);
  }

  getRootItem(hashCurrent: any) {
    this.pmcService.getRootItem().subscribe(
      response => {
        if (response.status) {
          this.pmcItem = response.result;
          if (hashCurrent) {
            this.getPathItem(hashCurrent);
          }
        }
      }
    );
  }

  getChildItem() {
    this.pmcService.getChildItem({ hashParent: this.currentItem.hash }).subscribe(
      response => {
        if (response.status) {
          this.currentItem['childList'] = response.result;
        }
      }
    );
  }

  getPathItem(hash) {
    this.pmcService.getPathItem({ hash: hash }).subscribe(
      response => {
        if (response.status) {
          let pathList = response.result.pathHash;
          let i = 1;
          for (let hash of pathList) {
            this.pathActived[i] = hash;
            i++;
          }
          let pathItem = response.result.pathItem;

          let pointer = this.pmcItem.find(e => e.hash === pathItem[0].hash);
          this.setChild(pathItem, pointer, 0);
        }
      }
    );
  }

  setChild(pathItem: any[], pointer: any, iteration: number) {
    if (!pointer['childList'] && pathItem[iteration + 1]) {
      pointer['childList'] = [pathItem[iteration + 1]];
      this.setChild(pathItem, pointer['childList'][0], iteration + 1);
    }
  }

  findTree(item, level, hash) {
    let tempItem = item.find(e => e.hash === hash);
    if (tempItem != undefined) {
      this.currentItem = tempItem;
    }
    else {
      let parentItem = item.find(e => e.hash === this.pathActived[level]);
      this.findTree(parentItem.childList, level + 1, hash);
    }
  }

  navigateTo(hash, level) {
    if (this.pathActived[level] != hash) {
      this.pathActived[level] = hash;
      this.findTree(this.pmcItem, 1, hash);
      this.getChildItem();
    }
    else {
      this.pathActived[level] = undefined;
    }
  }

  reloadAuth() {
    this.loader.startLoader('view-loader');
    let modul = 'pm';

    this.authenticationService.reloadAuthorization(modul).subscribe(response => {
      if (response.status) {
        localStorage.setItem(modul, response.result.token);
      }
      else {
        // Swal.fire(response.message);
        Swal.fire({
          title: 'Sorry!',
          type: 'info',
          html: response.message
        });
      }
    }, error => {
      this.loader.stopLoader('view-loader');
      // Swal.fire(error.error.message);
      Swal.fire({
        title: 'Sorry!',
        type: 'info',
        html: error.error.message
      });
    });

    setTimeout(function () {
      Swal.fire('Module authorization has been updated');
    }, 1000);
    this.loader.stopLoader('view-loader');
  }

  guideApprovalAll() {
    const intro: any = introJs();
    intro.setOptions({
      steps: [
        {
          element: document.querySelector('#menu-approval-all'),
          intro: 'Anda bisa melakukan approval di setiap module melalui menu berikut.',
          position: 'right'
        },
      ],
      highlightClass: 'introjs-no-bg',
      overlayOpacity: 0.2
    });
    intro.start();
  }

  getKeyModule(mod: string) {
    let key: string;
    // get module for reload authorization
    if (mod == 'pmc') { key = 'pmc'; }
    else if (mod == 'crm') { key = 'crm'; }
    else if (mod == 'grab') { key = 'grab'; }
    else if (mod == 'pm') { key = 'pm'; }
    else if (mod == 'elearning') { key = 'eLearning'; }
    else if (mod == 'simcard') { key = 'simcard'; }
    else if (mod == 'car_monitoring') { key = 'carMonitoring'; }
    else if (mod == 'mrps') { key = 'mrps'; }
    else if (mod == 'vendor_care') { key = 'vc'; }
    else if (mod == 'blog') { key = 'blog'; }
    else if (mod == 'pr') { key = 'pr'; }
    else if (mod == 'material-request') { key = 'materialrequest'; }
    else if (mod == 'material-request-v2') { key = 'materialrequest'; }
    else if (mod == 'group') { key = 'group'; }
    else if (mod == 'certificate') { key = 'certificate'; }
    else if (mod == 'attendance') { key = 'attendance'; }
    else if (mod == 'asset') { key = 'asset'; }
    else if (mod == 'faq') { key = 'faq'; }
    else if (mod == 'vacancy') { key = 'vacancy'; }
    else if (mod == 'exit_clearance') { key = 'exitclearance'; }
    else if (mod == 'region_location') { key = 'regionLocation'; }
    else if (mod == 'employee') { key = 'employee'; }
    else if (mod == 'advanceclearance') { key = 'advanceclearance'; }
    else if (mod == 'whistle-blowing') { key = 'whistleBlowing'; }
    else if (mod == 'reimbursement') { key = 'reimbursement'; }
    else if (mod == 'geotaggingphoto') { key = 'geotaggingphoto'; }
    else if (mod == 'documentation') { key = 'documentation'; }
    else if (mod == 'sip') { key = 'sip'; }
    else if (mod == 'site_survey') { key = 'sitesurvey'; }
    else { key = mod; }
    return key;

  }

  gotoclassic(hash) {
    document.location.href = 'https://pmc.ptnw.net/main/pmc/classic/' + hash;

  }
}
