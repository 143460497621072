import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
var Header5gComponent = /** @class */ (function () {
    function Header5gComponent() {
    }
    Header5gComponent.prototype.ngOnInit = function () {
    };
    Header5gComponent.prototype.delay = function (ms) {
        return new Promise(function (resolve) { return setTimeout(resolve, ms); });
    };
    Header5gComponent.prototype.menuToggle = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.collapsing = !this.collapsing;
                        return [4 /*yield*/, this.delay(200)];
                    case 1:
                        _a.sent();
                        this.expandMenu = !this.expandMenu;
                        this.collapsing = !this.collapsing;
                        return [2 /*return*/];
                }
            });
        });
    };
    return Header5gComponent;
}());
export { Header5gComponent };
