const regex = new RegExp('galileo\.ptnw\.net', 'g');
let _host = (regex.test(document.URL)) ? 'https://api.ptnw.net' : 'https://api.nexwaveindonesia.com';
// let _host = (regex.test(document.URL)) ? 'https://apigalileo.ptnw.net' : 'https://api.nexwaveindonesia.com';

export const environment = {
  production: true,
  webhost: 'https://galileo.ptnw.net',
  host: 'https://apigalileo.ptnw.net',
  apiVersion: 'v2',
  firebase: {
    apiKey: "AIzaSyDoRvWltCa2_sS9yqOc8sPvLHu0Jto_lOA",
    authDomain: "nexwave-196303.firebaseapp.com",
    projectId: "nexwave-196303",
    storageBucket: "nexwave-196303.appspot.com",
    messagingSenderId: "652714280134",
    appId: "1:652714280134:web:68b80c5caa9e015b9583c7",
    measurementId: "G-4YKZ1X9JDG"
  },
  firebaseHost: 'https://asia-southeast2-nexwave-196303.cloudfunctions.net'
};
