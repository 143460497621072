
      <div class="kt-pagination kt-pagination--brand">
        <ul class="kt-pagination__links">
          <ngb-pagination [collectionSize]="paginateOption.total" [(page)]="paginateOption.pageNumber" [pageSize]="paginateOption.pageSize" [maxSize]="5" 
            [rotate]="true" [ellipses]="false" [boundaryLinks]="true" (pageChange)="changePage($event, true)"></ngb-pagination>
          <span class="pagination__desc mx-4">
            Page {{paginateOption.pageNumber}} of {{paginateOption.getTotalPage()}} &nbsp;
          </span>
        </ul>
        <div class="kt-pagination__toolbar">
          <select [(ngModel)]="currentPageSize" (change)="changePageSize()" class="form-control kt-font-brand" style="width: 60px">
            <option *ngFor="let number of pageSizeOption" [value]="number">{{number}}</option>
          </select>
          <span class="pagination__desc">
            Displaying {{(paginateOption.pageSize > paginateOption.total)?paginateOption.total:paginateOption.pageSize}} of {{paginateOption.total}} records
          </span>
        </div>
      </div>