<div class="wrapper">

    <ng-container *ngIf="!isFinish">
        <input type="text" class="form-control" placeholder="Ctrl+V" style="min-width: 70px;" (paste)="onPaste($event)">
        <button type="button" class="btn btn-primary" (click)="selectFile()"><span class="fa fa-upload"></span></button>
    </ng-container>
    <ng-container *ngIf="isFinish">
        <span class="fa fa-check"></span>
    </ng-container>

    <input type="file" #inputFile class="d-none" (change)="selectSingleFilePresignUrl($event)"
        [attr.accept]="acceptFile">

    <div class="loader" #loader>
        <img src="/assets/media/loader/timeline.svg" [style.height.px]="37">
    </div>
</div>