<div id="content" class="bg-white">
  <div class="row align-items-center no-gutters justify-content-end">
    <div class="col-lg-5 col-xl-5">
      <div class="pb-5 mb-5 pt-5 px-4 px-md-5 ml-0 ml-xl-5">
        <h1 class="display-3 text-primary mb-0 pb-0">Our Client</h1>
        <div class="text-dark lead pt-3 pt-md-4 pb-4 pb-md-5 line-height-180">Sudah lebih dari satu dekade perjalanan
          bisnis PT Nexwave, kami tetap dipercaya menjadi partner dari Operator maupun Vendor terkemuka. Berbagai
          penghargaan kami peroleh bersama partner adalah bukti nyata hasil teamwork yang kami kembangkan dan terus kami
          tingkatkan. "Innovation and Improvement" merupakan komitmen kami ke depan demi menjaga kepercayaan bisnis yang
          sudah terjalin.
        </div>
      </div>
    </div>
    <div class="col-lg-7 col-xl-7">
      <div class="pl-0 pl-lg-5">
        <img src="./assets/media/5g/client.jpg" class="w-100" alt="" />
      </div>
    </div>
  </div>
  <div class="bg-white pb-5 px-0 px-lg-5">
    <div class="container-fluid">
      <div class="row pt-5 pt-lg-100">
        <div class="col-6 col-md-4 col-lg-3">
          <img src="./assets/media/client/telkomsel.png" alt="Clients" class="w-100" title="Telkomsel">
        </div>
        <div class="col-6 col-md-4 col-lg-3">
          <img src="./assets/media/client/indosat.png" alt="Clients" class="w-100" title="Indosat">
        </div>
        <div class="col-6 col-md-4 col-lg-3">
          <img src="./assets/media/client/xl.png" alt="Clients" class="w-100" title="XL">
        </div>
        <div class="col-6 col-md-4 col-lg-3">
          <img src="./assets/media/client/three.png" alt="Clients" class="w-100" title="Three">
        </div>
        <div class="col-6 col-md-4 col-lg-3">
          <img src="./assets/media/client/smartfren.png" alt="Smartfren" class="w-100" title="Smartfren">
        </div>
        <div class="col-6 col-md-4 col-lg-3">
          <img src="./assets/media/client/wataniya.png" alt="Clients" class="w-100" title="Wataniya">
        </div>
        <div class="col-6 col-md-4 col-lg-3">
          <img src="./assets/media/client/ericsson.png" alt="Clients" class="w-100" title="Ericsson">
        </div>
        <div class="col-6 col-md-4 col-lg-3">
          <img src="./assets/media/client/huawei.png" alt="Clients" class="w-100" title="Huawei">
        </div>
        <div class="col-6 col-md-4 col-lg-3">
          <img src="./assets/media/client/m1.png" alt="Clients" class="w-100" title="M1">
        </div>
        <div class="col-6 col-md-4 col-lg-3">
          <img src="./assets/media/client/axis.png" alt="Clients" class="w-100" title="Axis">
        </div>
        <div class="col-6 col-md-4 col-lg-3">
          <img src="./assets/media/client/telechoice.png" alt="Clients" class="w-100" title="Telechoice">
        </div>
        <div class="col-6 col-md-4 col-lg-3">
          <img src="./assets/media/client/nsn.png" alt="Clients" class="w-100" title="NSN">
        </div>
        <div class="col-6 col-md-4 col-lg-3">
          <img src="./assets/media/client/audiocodes.png" alt="Clients" class="w-100" title="Audiocodes">
        </div>
        <div class="col-6 col-md-4 col-lg-3">
          <img src="./assets/media/client/nortel.png" alt="Clients" class="w-100" title="Nortel">
        </div>
        <div class="col-6 col-md-4 col-lg-3">
          <img src="./assets/media/client/singtel.png" alt="Clients" class="w-100" title="Singtel">
        </div>
        <div class="col-6 col-md-4 col-lg-3">
          <img src="./assets/media/client/zte.png" alt="Clients" class="w-100" title="ZTE">
        </div>
        <div class="col-6 col-md-4 col-lg-3">
          <img src="./assets/media/client/motorola.png" alt="Clients" class="w-100" title="Motorola">
        </div>
        <div class="col-6 col-md-4 col-lg-3">
          <img src="./assets/media/client/digi.png" alt="Clients" class="w-100" title="Digi">
        </div>
        <div class="col-6 col-md-4 col-lg-3">
          <img src="./assets/media/client/starhub.png" alt="Clients" class="w-100" title="Starhub">
        </div>
      </div>
    </div>
  </div>
</div>