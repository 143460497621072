import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LandingComponent } from './landing.component';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { LandingRoutingModule } from './landing-routing.module';
import { HomeComponent } from './pages/home/home.component';
import { LandingpageService } from 'src/app/_services/landingpage.service';
import { FormsModule } from '@angular/forms';
import { ListComponent } from './pages/vacancy/list/list.component';
import { RegistrationComponent } from './pages/vacancy/registration/registration.component';
import { SkillComponent } from './pages/vacancy/skill/skill.component';
import { SharedModule } from '../shared/shared.module';
import { VacancyService } from 'src/app/_services/vacancy.service';
import { ImageCropperModule } from 'ngx-image-cropper';
import { FormResetComponent } from './pages/form-reset/form-reset.component';
import { SkillFormComponent } from './pages/vacancy/skill/skill-form/skill-form.component';
import { SimcardComponent } from './pages/simcard/simcard.component';
import { AdsenseModule } from 'ng2-adsense';
import { CategoryComponent } from './pages/blog/category/category.component';
import { PostComponent } from './pages/blog/post/post.component';
import { ListBlogComponent } from './pages/blog/list-blog/list-blog.component';
import { SidebarComponent } from './pages/blog/sidebar/sidebar.component';
import { FaqComponent } from './pages/faq/faq.component';
import { FaqpostComponent } from './pages/faq/faqpost/faqpost.component';
import { SurveyComponent } from './pages/survey/survey.component';
import { DiscComponent } from './pages/disc/disc.component';
import { ELearningService } from 'src/app/_services/e-learning.service';
import { ExamComponent } from './pages/exam/exam.component';
import { WhistleBlowingComponent } from './pages/whistle-blowing/whistle-blowing.component';
import { TestComponent } from './pages/test/test.component';
import { KebijakanMutuComponent } from './pages/kebijakan-mutu/kebijakan-mutu.component';
import { WhatsappComponent } from './pages/whatsapp/whatsapp.component';
import { ApprovalComponent } from './pages/approval/approval.component';
import { Home5gComponent } from './theme5g/home5g/home5g.component';
import { About5gComponent } from './theme5g/about5g/about5g.component';
import { Register5gComponent } from './theme5g/register5g/register5g.component';
import { Lostpassword5gComponent } from './theme5g/lostpassword5g/lostpassword5g.component';
import { Login5gComponent } from './theme5g/login5g/login5g.component';
import { Career5gComponent } from './theme5g/career5g/career5g.component';
import { Blog5gComponent } from './theme5g/blog5g/blog5g.component';
import { Client5gComponent } from './theme5g/client5g/client5g.component';
import { Gallery5gComponent } from './theme5g/gallery5g/gallery5g.component';
import { Header5gComponent } from './theme5g/header5g/header5g.component';
import { Footer5gComponent } from './theme5g/footer5g/footer5g.component';
import { VideoExamComponent } from './pages/video-exam/video-exam.component';
import { CloudflareStreamModule } from '@cloudflare/stream-angular';
import { AngularTelegramLoginWidgetModule } from 'angular-telegram-login-widget';
import { NgbModalModule } from '@ng-bootstrap/ng-bootstrap';
import { CvComponent } from './theme5g/cv/cv.component';
import { SignatureComponent } from './pages/signature/signature.component';
import { SignaturePadModule } from 'angular2-signaturepad';

@NgModule({
  declarations: [
    LandingComponent,
    HeaderComponent,
    FooterComponent,
    HomeComponent,
    ListComponent,
    RegistrationComponent,
    SkillComponent,
    FormResetComponent,
    SkillFormComponent,
    SimcardComponent,
    CategoryComponent,
    PostComponent,
    ListBlogComponent,
    SidebarComponent,
    FaqComponent,
    FaqpostComponent,
    SurveyComponent,
    DiscComponent,
    ExamComponent,
    WhistleBlowingComponent,
    TestComponent,
    KebijakanMutuComponent,
    WhatsappComponent,
    ApprovalComponent,
    Home5gComponent,
    About5gComponent,
    Register5gComponent,
    Lostpassword5gComponent,
    Login5gComponent,
    Career5gComponent,
    Blog5gComponent,
    Client5gComponent,
    Gallery5gComponent,
    Header5gComponent,
    Footer5gComponent,
    VideoExamComponent,
    CvComponent,
    SignatureComponent
  ],
  imports: [
    CommonModule,
    LandingRoutingModule,
    FormsModule,
    SharedModule,
    ImageCropperModule,
    AdsenseModule.forRoot({
      adClient: 'ca-pub-4460520388028352',
      adSlot: 1025911797,
    }),
    CloudflareStreamModule,
    AngularTelegramLoginWidgetModule,
    NgbModalModule,
    SignaturePadModule
  ],
  exports: [
    LandingComponent,
    HeaderComponent,
    FooterComponent,
  ],
  providers: [
    LandingpageService,
    VacancyService,
    ELearningService,
  ]
})
export class LandingModule { }
