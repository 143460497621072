<div class="login-form text-center text-dark" style="background: url(./assets/media/5g/login.jpg) no-repeat;">
  <div class="container">
    <div class="row justify-content-center pt-0 pt-lg-5">
      <div class="col-xl-8 col-lg-9 col-md-6 col-11 pt-0 pt-lg-5">
        <div class="shadow login-form-wrap rounded">
          <div class="row align-items-center no-gutters">
            <div class="col-lg-6 login-form-left rounded text-center ">
              <div class="pt-5 pb-lg-5 pb-0 px-3">
                <a [routerLink]="['/']">
                  <img src="./assets/media/nds/logo.png" alt="" class="login-logo" />
                </a>
              </div>
            </div>
            <div class="col-lg-6 login-form-right">
              <div class="py-5 px-4 px-md-5">
                <h3 class="pb-4 font-weight-bold d-none d-lg-block">
                  Lost Password
                </h3>
                <div class="pb-4">
                  Please enter your username or email. A confirmation email will be sent automatically. You will need to
                  click on the link to enter a new password.
                </div>
                <form id="forgotForm" #forgotForm="ngForm" (ngSubmit)="submitForgot(forgotForm)">
                  <div class="form-group">
                    <input name="username" [(ngModel)]="forgot_form.username" type="text" class="form-control" placeholder="Username or email" value="">
                  </div>
                  <div class="form-group text-left pt-3">
                    <button class="btn" *ngIf="isSubmited">
                      <img src="/assets/media/loader/timeline.svg" [style.height.px]="37">
                    </button>
                    <input *ngIf="!isSubmited" [disabled]="isSubmited" type="submit" class="btn btn-dark mr-3" value="Request Lost Password">
                    <a [routerLink]="['/login']" class="text-dark font-weight-bold mr-4">
                      Login
                    </a>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="clear"></div>