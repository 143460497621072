import { HttpClient, HttpHeaders } from '@angular/common/http';
import { throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { CookieService } from 'ngx-cookie-service';
import { GlobalService } from './global.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AlertModalComponent } from '../layout/shared/alert-modal/alert-modal.component';
import Swal from 'sweetalert2';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "ngx-cookie-service";
import * as i3 from "./global.service";
import * as i4 from "@ng-bootstrap/ng-bootstrap";
var API_APPROVAL_DETAIL = '/simcard/prepaid/approval-detail';
var API_CHECK_ALLOWED = '/simcard/default/cek-user-approval';
var API_APPROVAL = '/simcard/prepaid/approval';
var API_APPROVE = '/simcard/prepaid/approve';
var API_EXPORT = '/simcard/prepaid/export';
var API_EXPORT_SUMMARY = '/simcard/prepaid/export-summary';
var API_RESEND_EMAIL = '/simcard/default/resend-email';
var API_SUCCESS = '/simcard/default/success';
var API_CREATE_MASTER = '/simcard/default/add-prepaid';
var API_CEK_PREPAID = '/simcard/default/cek-add-prepaid';
var API_PREPAID = '/simcard/default/get-all-prepaid';
var API_PREPAID_PIC = '/simcard/default/is-pic';
var API_GET_PIC_LIST = '/simcard/pic/get-all-pic';
var API_GET_PIC = '/simcard/pic/get-pic';
var API_GET_PIC_PCODE = '/simcard/pic/get-pic-by-project';
var API_DELETE_PIC = '/simcard/pic/delete-pic';
var API_ADD_PIC = '/simcard/pic/add-pic';
var API_IMPORT_PIC = '/simcard/pic/import-pic';
var API_ADD_PIC_DATA = '/simcard/pic/getcreatedata';
var API_PREPAID_DETAIL = '/simcard/default/prepaid-detail';
var API_USER_PHONE_NUMBER = '/simcard/default/get-users-phone';
var API_GET_MY_APPROVAL = '/simcard/default/get-my-approval';
var API_TYPEHEAD = '/simcard/default/get-typehead-data';
var API_TYPEHEAD_ENGINEER = '/simcard/default/get-typehead-data-engineer';
var API_START_APPROVAL = '/simcard/default/start-approval';
var API_CREATE_DETAIL = '/simcard/default/add-detail';
var API_UPDATE_MASTER = '/simcard/prepaid/update-master';
var API_UPDATE_DETAIL = '/simcard/default/update-detail';
var API_DELETE_DETAIL = '/simcard/default/delete-detail';
var API_USER = '/simcard/default/get-user';
var API_USER_BY_ADMIN = '/simcard/default/get-user-by-admin';
var API_CODE = '/simcard/default/get-code';
var API_CODE_BY_ADMIN = '/simcard/default/get-code-by-admin';
var API_IMPORT_DETAIL = '/simcard/default/import-detail';
var API_DELETE = '/simcard/prepaid/delete';
var API_CREATE_PARAMS = '/simcard/phonenumber/create-params';
var API_ADD_PHONE_NUMBER = '/simcard/phonenumber/add-phone-number';
var API_LIST_PHONE_NUMBER = '/simcard/phonenumber/get-list-phone';
var API_EDIT_PHONE_NUMBER = '/simcard/phonenumber/edit-phone-number';
var API_DELETE_PHONE_NUMBER = '/simcard/phonenumber/delete-phone-number';
var API_ADD_PHONE_CONFIG = '/simcard/maxphonenumber/add-max-phone';
var API_LIST_PHONE_CONFIG = '/simcard/maxphonenumber/get-max-phone';
var API_EDIT_PHONE_CONFIG = '/simcard/maxphonenumber/edit-max-phone';
var API_DELETE_PHONE_CONFIG = '/simcard/maxphonenumber/delete-max-phone';
var API_GET_PHONE_APPROVAL = '/simcard/phonenumber/get-phone-approval';
var API_LIST_APPROVAL_DETAIL = '/simcard/phonenumber/approval-detail';
var API_CONFIRM_APPROVAL_DETAIL = '/simcard/phonenumber/confirm-checkbox';
var API_GET_DATA_SUMMARY = '/simcard/prepaid/data-summary';
var API_APPROVAL_ALL = '/simcard/prepaid/approval-all';
var API_GET_OPERATOR = '/simcard/default/get-operator';
var API_GET_AREA = '/simcard/default/get-area';
var API_MY_NUMBER_REQUEST = '/simcard/default/my-number-request';
var API_LIST_PROJECT_CODE = '/simcard/default/list-project-code';
var API_RESTRICT_REQUEST = '/simcard/prepaid-admin/check-restrict';
var API_PREPARE_REQUEST = '/simcard/prepaid-admin/prepare-request';
var API_ADD_REQUEST = '/simcard/prepaid-admin/add-request';
var API_GET_REQUEST_DETAIL = '/simcard/prepaid-admin/get-request-detail';
var API_PREPARE_REQUEST_DETAIL = '/simcard/prepaid-admin/prepare-request-detail';
var API_ADD_REQUEST_DETAIL = '/simcard/prepaid-admin/add-request-detail';
var API_DELETE_REQUEST_DETAIL = '/simcard/prepaid-admin/delete-request-detail';
var API_START_APPROVAL_ADMIN = '/simcard/prepaid-admin/start-approval';
var API_FINISHED_REQUEST = '/simcard/prepaid/finished-request';
var API_EXPORT_EXCEL_FINISHED_REQUEST = '/simcard/prepaid/export-excel-finished-request';
var API_EXPORT_PDF_FINISHED_REQUEST = '/simcard/prepaid/export-pdf-finished-request';
var API_GET_ACTIVE_USERS = '/simcard/default/get-active-users';
var API_REMINDER_PULSA = '/simcard/dev/topup-reminder';
var API_PULSA_REMINDER_ONE = '/simcard/dev/send-reminder';
var API_PULSA_REMINDER_ALL = '/simcard/dev/send-reminder-all';
var API_RETOPUP = '/simcard/prepaid-admin/re-topup';
var API_RETOPUP_RESEND_EMAIL = '/simcard/prepaid-admin/resend-email';
var API_ALL_REQUEST = '/simcard/prepaid/all-request';
var API_PULSA_PAYMENT_TRACKER = '/simcard/pulsa-payment/tracker';
var API_PULSA_PAYMENT_TRACKER_DETAIL = '/simcard/pulsa-payment/tracker-detail';
var API_PULSA_PAYMENT_TRACKER_PROGRESS = '/simcard/pulsa-payment/tracker-progress';
var API_PULSA_PAYMENT_SUBMIT_RECEIPT = '/simcard/pulsa-payment/submit-receipt';
var API_PULSA_PAYMENT_SUBMIT_TRACKER = '/simcard/pulsa-payment/submit-tracker';
var API_PULSA_PAYMENT_TRACKER_APPROVAL = '/simcard/pulsa-payment/tracker-approval';
var API_PULSA_PAYMENT_SUBMIT_TRACKER_APPROVAL = '/simcard/pulsa-payment/submit-tracker-approval';
var API_PULSA_PAYMENT_EXPORT_PDF = '/simcard/pulsa-payment/export-pdf';
var API_PULSA_PAYMENT_GENERATE_PULSA_REPORT = '/simcard/pulsa-payment/generate-pulsa-report';
var API_PROCESS_UPLOAD = '/simcard/pulsa-payment/upload-attachment';
var API_GET_ALL_POSTPAID = '/simcard/postpaid/get-all-postpaid';
var API_GET_ALL_POSTPAID_MY_APPROVAL = '/simcard/postpaid/my-approval';
var API_IMPORT_POSTPAID = '/simcard/postpaid/import-postpaid';
var API_GET_POSTPAID_PROGRESS = '/simcard/postpaid/progress';
var API_GET_OPERATOR_LIST = '/simcard/postpaid/get-operator';
var API_GET_POSTPAID_DETAIL = '/simcard/postpaid/detail';
var API_SUBMIT_POSTPAID_APPROVAL = '/simcard/postpaid/submit-postpaid-approval';
var API_GENERATE_POSTPAID_REPORT = '/simcard/postpaid/generate-postpaid-report';
var API_POSTPAID_PAYMENT_EXPORT_PDF = '/simcard/postpaid/export-pdf';
var API_POSTPAID_PAYMENT_EXPORT_EXCEL = '/simcard/postpaid/generate-postpaid-report';
var API_GET_CURRENT_REQUEST = '/simcard/postpaid/get-current-request';
var API_EDIT_REQUEST = '/simcard/postpaid/edit-postpaid';
var SimcardService = /** @class */ (function () {
    function SimcardService(http, cookieService, globalService, modalService) {
        this.http = http;
        this.cookieService = cookieService;
        this.globalService = globalService;
        this.modalService = modalService;
    }
    SimcardService.prototype.alertModal = function (err, code) {
        if (code === 403) {
            return Swal.fire(err);
        }
        var modalRef = this.modalService.open(AlertModalComponent);
        modalRef.componentInstance.kategori = 'alert';
        modalRef.componentInstance.error = (err) ? err : 'Request Timeout.';
    };
    SimcardService.prototype.header = function () {
        return {
            headers: new HttpHeaders()
                .set('token', this.cookieService.get('_q'))
                .set('module', localStorage.getItem('simcard'))
                .set('group', this.cookieService.get('_g'))
        };
    };
    // START OF APPROVAL
    SimcardService.prototype.getApprovalDetail = function (hash) {
        return this.http.post(this.globalService.apiVersionHost + API_APPROVAL_DETAIL, { hash: hash }, this.header()).pipe(map(function (response) { return response; }), catchError(function (err) {
            return throwError(err);
        }));
    };
    SimcardService.prototype.phoneApprovalDetail = function (userid) {
        return this.http.post(this.globalService.apiVersionHost + API_LIST_APPROVAL_DETAIL, { userid: userid }, this.header()).pipe(map(function (response) { return response; }), catchError(function (err) {
            return throwError(err);
        }));
    };
    SimcardService.prototype.confirmCheckbox = function (param) {
        return this.http.post(this.globalService.apiVersionHost + API_CONFIRM_APPROVAL_DETAIL, param, this.header()).pipe(map(function (response) { return response; }));
    };
    SimcardService.prototype.actionCheckAllowed = function (hash) {
        return this.http.post(this.globalService.apiVersionHost + API_CHECK_ALLOWED, { hash: hash }, this.header()).pipe(map(function (response) { return response; }), catchError(function (err) {
            return throwError(err);
        }));
    };
    SimcardService.prototype.actionApproval = function (param) {
        return this.http.post(this.globalService.apiVersionHost + API_APPROVAL, param, this.header()).pipe(map(function (response) { return response; }), catchError(function (err) {
            return throwError(err);
        }));
    };
    SimcardService.prototype.actionApprove = function (params) {
        return this.http.post(this.globalService.apiVersionHost + API_APPROVE, params, this.header()).pipe(map(function (response) { return response; }), catchError(function (err) {
            return throwError(err);
        }));
    };
    SimcardService.prototype.exporTopup = function (param) {
        return this.http.post(this.globalService.apiVersionHost + API_EXPORT, param, this.header()).pipe(map(function (response) { return response; }), catchError(function (err) {
            return throwError(err);
        }));
    };
    SimcardService.prototype.exporTopupSummary = function (param) {
        return this.http.post(this.globalService.apiVersionHost + API_EXPORT_SUMMARY, param, this.header()).pipe(map(function (response) { return response; }), catchError(function (err) {
            return throwError(err);
        }));
    };
    SimcardService.prototype.actionSendEmail = function (hash) {
        return this.http.post(this.globalService.apiVersionHost + API_RESEND_EMAIL, { hash: hash }, this.header()).pipe(map(function (response) { return response; }), catchError(function (err) {
            return throwError(err);
        }));
    };
    SimcardService.prototype.actionSuccess = function (param) {
        return this.http.post(this.globalService.apiVersionHost + API_SUCCESS, param, this.header()).pipe(map(function (response) { return response; }), catchError(function (err) {
            return throwError(err);
        }));
    };
    // END OF APPROVAL
    SimcardService.prototype.addPrepaid = function (param) {
        return this.http.post(this.globalService.apiVersionHost + API_CREATE_MASTER, param, this.header()).pipe(map(function (response) { return response; }), catchError(function (err) {
            return throwError(err);
        }));
    };
    SimcardService.prototype.cekAddPrepaid = function () {
        return this.http.get(this.globalService.apiVersionHost + API_CEK_PREPAID, this.header()).pipe(map(function (response) { return response; }), catchError(function (err) {
            return throwError(err);
        }));
    };
    SimcardService.prototype.getPrepaid = function (param) {
        return this.http.post(this.globalService.apiVersionHost + API_PREPAID, param, this.header()).pipe(map(function (response) { return response; }), catchError(function (err) {
            return throwError(err);
        }));
    };
    SimcardService.prototype.getPostpaid = function (param) {
        return this.http.post(this.globalService.apiVersionHost + API_GET_ALL_POSTPAID, param, this.header()).pipe(map(function (response) { return response; }), catchError(function (err) {
            return throwError(err);
        }));
    };
    SimcardService.prototype.getPostpaidMyApproval = function (param) {
        return this.http.post(this.globalService.apiVersionHost + API_GET_ALL_POSTPAID_MY_APPROVAL, param, this.header()).pipe(map(function (response) { return response; }), catchError(function (err) {
            return throwError(err);
        }));
    };
    SimcardService.prototype.isPic = function () {
        return this.http.get(this.globalService.apiVersionHost + API_PREPAID_PIC, this.header()).pipe(map(function (response) { return response; }), catchError(function (err) {
            return throwError(err);
        }));
    };
    SimcardService.prototype.getAllPic = function (param) {
        return this.http.post(this.globalService.apiVersionHost + API_GET_PIC_LIST, param, this.header()).pipe(map(function (response) { return response; }), catchError(function (err) {
            return throwError(err);
        }));
    };
    SimcardService.prototype.getPic = function (param) {
        return this.http.post(this.globalService.apiVersionHost + API_GET_PIC, param, this.header()).pipe(map(function (response) { return response; }), catchError(function (err) {
            return throwError(err);
        }));
    };
    SimcardService.prototype.getPicByProject = function (param) {
        return this.http.post(this.globalService.apiVersionHost + API_GET_PIC_PCODE, param, this.header()).pipe(map(function (response) { return response; }), catchError(function (err) {
            return throwError(err);
        }));
    };
    SimcardService.prototype.getDeletePic = function (param) {
        return this.http.post(this.globalService.apiVersionHost + API_DELETE_PIC, param, this.header()).pipe(map(function (response) { return response; }), catchError(function (err) {
            return throwError(err);
        }));
    };
    SimcardService.prototype.importPic = function (param) {
        return this.http.post(this.globalService.apiVersionHost + API_IMPORT_PIC, param, this.header()).pipe(map(function (response) { return response; }), catchError(function (err) {
            return throwError(err);
        }));
    };
    SimcardService.prototype.addPic = function (param) {
        return this.http.post(this.globalService.apiVersionHost + API_ADD_PIC, param, this.header()).pipe(map(function (response) { return response; }), catchError(function (err) {
            return throwError(err);
        }));
    };
    SimcardService.prototype.getAddPicData = function () {
        return this.http.get(this.globalService.apiVersionHost + API_ADD_PIC_DATA, this.header()).pipe(map(function (response) { return response; }), catchError(function (err) {
            return throwError(err);
        }));
    };
    SimcardService.prototype.getMyapproval = function (param) {
        return this.http.post(this.globalService.apiVersionHost + API_GET_MY_APPROVAL, param, this.header()).pipe(map(function (response) { return response; }), catchError(function (err) {
            return throwError(err);
        }));
    };
    SimcardService.prototype.getPhoneapproval = function (param) {
        return this.http.post(this.globalService.apiVersionHost + API_GET_PHONE_APPROVAL, param, this.header()).pipe(map(function (response) { return response; }), catchError(function (err) {
            return throwError(err);
        }));
    };
    SimcardService.prototype.getPrepaidDetail = function (id) {
        return this.http.post(this.globalService.apiVersionHost + API_PREPAID_DETAIL, { id: id }, this.header()).pipe(map(function (response) { return response; }), catchError(function (err) {
            return throwError(err);
        }));
    };
    SimcardService.prototype.getUsersPhoneNumber = function (params) {
        return this.http.post(this.globalService.apiVersionHost + API_USER_PHONE_NUMBER, params, this.header()).pipe(map(function (response) { return response; }), catchError(function (err) {
            return throwError(err);
        }));
    };
    SimcardService.prototype.getTypeheadDataEngineer = function () {
        return this.http.get(this.globalService.apiVersionHost + API_TYPEHEAD_ENGINEER, this.header()).pipe(map(function (response) { return response; }));
    };
    SimcardService.prototype.getTypeheadData = function () {
        return this.http.get(this.globalService.apiVersionHost + API_TYPEHEAD, this.header()).pipe(map(function (response) { return response; }));
    };
    // ------ service for PIC Pulsa-------------------------------------------------------------
    SimcardService.prototype.checkRestrict = function () {
        return this.http.get(this.globalService.apiVersionHost + API_RESTRICT_REQUEST, this.header()).pipe(map(function (response) { return response; }), catchError(function (err) {
            return throwError(err);
        }));
    };
    SimcardService.prototype.prepareRequest = function () {
        return this.http.get(this.globalService.apiVersionHost + API_PREPARE_REQUEST, this.header()).pipe(map(function (response) { return response; }), catchError(function (err) {
            return throwError(err);
        }));
    };
    SimcardService.prototype.addRequest = function (param) {
        return this.http.post(this.globalService.apiVersionHost + API_ADD_REQUEST, param, this.header()).pipe(map(function (response) { return response; }), catchError(function (err) {
            return throwError(err);
        }));
    };
    SimcardService.prototype.getRequestDetail = function (param) {
        return this.http.post(this.globalService.apiVersionHost + API_GET_REQUEST_DETAIL, param, this.header()).pipe(map(function (response) { return response; }), catchError(function (err) {
            return throwError(err);
        }));
    };
    SimcardService.prototype.prepareRequestDetail = function (param) {
        return this.http.post(this.globalService.apiVersionHost + API_PREPARE_REQUEST_DETAIL, param, this.header()).pipe(map(function (response) { return response; }), catchError(function (err) {
            return throwError(err);
        }));
    };
    SimcardService.prototype.addRequestDetail = function (param) {
        return this.http.post(this.globalService.apiVersionHost + API_ADD_REQUEST_DETAIL, param, this.header()).pipe(map(function (response) { return response; }), catchError(function (err) {
            return throwError(err);
        }));
    };
    SimcardService.prototype.deteleRequestDetail = function (param) {
        return this.http.post(this.globalService.apiVersionHost + API_DELETE_REQUEST_DETAIL, param, this.header()).pipe(map(function (response) { return response; }), catchError(function (err) {
            return throwError(err);
        }));
    };
    SimcardService.prototype.startApprovalAdmin = function (param) {
        return this.http.post(this.globalService.apiVersionHost + API_START_APPROVAL_ADMIN, param, this.header()).pipe(map(function (response) { return response; }), catchError(function (err) {
            return throwError(err);
        }));
    };
    // -----------------------------------------------------------------------------------
    SimcardService.prototype.getCreateDetail = function (hash) {
        return this.http.get(this.globalService.apiVersionHost + '/simcard/default/create-detail?hash=' + hash, this.header());
    };
    SimcardService.prototype.startApproval = function (hash) {
        return this.http.post(this.globalService.apiVersionHost + API_START_APPROVAL, { hash: hash }, this.header()).pipe(map(function (response) { return response; }));
    };
    SimcardService.prototype.addDetail = function (param) {
        return this.http.post(this.globalService.apiVersionHost + API_CREATE_DETAIL, param, this.header()).pipe(map(function (response) { return response; }), catchError(function (err) {
            return throwError(err);
        }));
    };
    SimcardService.prototype.updateMaster = function (param) {
        return this.http.post(this.globalService.apiVersionHost + API_UPDATE_MASTER, param, this.header()).pipe(map(function (response) { return response; }), catchError(function (err) {
            return throwError(err);
        }));
    };
    SimcardService.prototype.updateDetail = function (param) {
        return this.http.post(this.globalService.apiVersionHost + API_UPDATE_DETAIL, param, this.header()).pipe(map(function (response) { return response; }), catchError(function (err) {
            return throwError(err);
        }));
    };
    SimcardService.prototype.deleteDetail = function (id) {
        return this.http.post(this.globalService.apiVersionHost + API_DELETE_DETAIL, id, this.header()).pipe(map(function (response) { return response; }));
    };
    SimcardService.prototype.getUser = function (id) {
        return this.http.get(this.globalService.apiVersionHost + API_USER + '?id=' + id, this.header()).pipe(map(function (response) { return response; }));
    };
    SimcardService.prototype.getUserByAdmin = function (param) {
        return this.http.post(this.globalService.apiVersionHost + API_USER_BY_ADMIN, param, this.header()).pipe(map(function (response) { return response; }));
    };
    SimcardService.prototype.getCode = function (params) {
        return this.http.post(this.globalService.apiVersionHost + API_CODE, params, this.header()).pipe(map(function (response) { return response; }));
    };
    SimcardService.prototype.getCodeByAdmin = function (params) {
        return this.http.post(this.globalService.apiVersionHost + API_CODE_BY_ADMIN, params, this.header()).pipe(map(function (response) { return response; }));
    };
    SimcardService.prototype.getOperator = function (params) {
        return this.http.post(this.globalService.apiVersionHost + API_GET_OPERATOR, params, this.header()).pipe(map(function (response) { return response; }));
    };
    SimcardService.prototype.getArea = function (params) {
        return this.http.post(this.globalService.apiVersionHost + API_GET_AREA, params, this.header()).pipe(map(function (response) { return response; }));
    };
    SimcardService.prototype.getFinish = function (param) {
        return this.http.post(this.globalService.apiVersionHost + '/simcard/default/finish', param, this.header()).pipe(map(function (response) { return response; }), catchError(function (err) {
            return throwError(err);
        }));
    };
    SimcardService.prototype.importDetail = function (params) {
        return this.http
            .post(this.globalService.apiVersionHost + API_IMPORT_DETAIL, params, this.header())
            .pipe(map(function (response) { return response; }));
    };
    SimcardService.prototype.delete = function (param) {
        return this.http.post(this.globalService.apiVersionHost + API_DELETE, param, this.header()).pipe(map(function (response) { return response; }));
    };
    // Begin Phone Number
    SimcardService.prototype.createParams = function () {
        return this.http.get(this.globalService.apiVersionHost + API_CREATE_PARAMS, this.header()).pipe(map(function (response) { return response; }));
    };
    SimcardService.prototype.addPhoneNumber = function (param) {
        return this.http.post(this.globalService.apiVersionHost + API_ADD_PHONE_NUMBER, param, this.header()).pipe(map(function (response) { return response; }));
    };
    SimcardService.prototype.listPhoneNumber = function (param) {
        return this.http.post(this.globalService.apiVersionHost + API_LIST_PHONE_NUMBER, param, this.header()).pipe(map(function (response) { return response; }));
    };
    SimcardService.prototype.editPhoneNumber = function (param) {
        return this.http.post(this.globalService.apiVersionHost + API_EDIT_PHONE_NUMBER, param, this.header()).pipe(map(function (response) { return response; }));
    };
    SimcardService.prototype.deletePhoneNumber = function (param) {
        return this.http.post(this.globalService.apiVersionHost + API_DELETE_PHONE_NUMBER, param, this.header()).pipe(map(function (response) { return response; }));
    };
    SimcardService.prototype.addPhoneConfig = function (param) {
        return this.http.post(this.globalService.apiVersionHost + API_ADD_PHONE_CONFIG, param, this.header()).pipe(map(function (response) { return response; }));
    };
    SimcardService.prototype.listPhoneConfig = function (param) {
        return this.http.post(this.globalService.apiVersionHost + API_LIST_PHONE_CONFIG, param, this.header()).pipe(map(function (response) { return response; }));
    };
    SimcardService.prototype.editPhoneConfig = function (param) {
        return this.http.post(this.globalService.apiVersionHost + API_EDIT_PHONE_CONFIG, param, this.header()).pipe(map(function (response) { return response; }));
    };
    SimcardService.prototype.deletePhoneConfig = function (param) {
        return this.http.post(this.globalService.apiVersionHost + API_DELETE_PHONE_CONFIG, param, this.header()).pipe(map(function (response) { return response; }));
    };
    SimcardService.prototype.approveAll = function (param) {
        return this.http.post(this.globalService.apiVersionHost + API_APPROVAL_ALL, param, this.header()).pipe(map(function (response) { return response; }));
    };
    SimcardService.prototype.getDataSummary = function (param) {
        return this.http.post(this.globalService.apiVersionHost + API_GET_DATA_SUMMARY, param, this.header()).pipe(map(function (response) { return response; }));
    };
    SimcardService.prototype.myNumberRequest = function (param) {
        return this.http.post(this.globalService.apiVersionHost + API_MY_NUMBER_REQUEST, param, this.header()).pipe(map(function (response) { return response; }), catchError(function (err) {
            return throwError(err);
        }));
    };
    SimcardService.prototype.listProjectCode = function (param) {
        return this.http.post(this.globalService.apiVersionHost + API_LIST_PROJECT_CODE, param, this.header()).pipe(map(function (response) { return response; }), catchError(function (err) {
            return throwError(err);
        }));
    };
    SimcardService.prototype.getFinishedRequest = function (param) {
        return this.http.post(this.globalService.apiVersionHost + API_FINISHED_REQUEST, param, this.header()).pipe(map(function (response) { return response; }));
    };
    SimcardService.prototype.exportExcelFinishedRequest = function (param) {
        return this.http.post(this.globalService.apiVersionHost + API_EXPORT_EXCEL_FINISHED_REQUEST, param, this.header()).pipe(map(function (response) { return response; }));
    };
    SimcardService.prototype.exportPdfFinishedRequest = function (param) {
        return this.http.post(this.globalService.apiVersionHost + API_EXPORT_PDF_FINISHED_REQUEST, param, this.header()).pipe(map(function (response) { return response; }));
    };
    // BEGIN TOPUP REMINDER
    SimcardService.prototype.getActiveUsers = function () {
        return this.http.post(this.globalService.apiVersionHost + API_GET_ACTIVE_USERS, {}, this.header()).pipe(map(function (response) { return response; }));
    };
    SimcardService.prototype.getReminderTopup = function (param) {
        return this.http.post(this.globalService.apiVersionHost + API_REMINDER_PULSA, param, this.header()).pipe(map(function (response) { return response; }));
    };
    SimcardService.prototype.remindById = function (params) {
        return this.http.post(this.globalService.apiVersionHost + API_PULSA_REMINDER_ONE, params, this.header()).pipe(map(function (response) { return response; }));
    };
    SimcardService.prototype.remindAll = function (params) {
        return this.http.post(this.globalService.apiVersionHost + API_PULSA_REMINDER_ALL, params, this.header()).pipe(map(function (response) { return response; }));
    };
    // END TOPUP REMINDER
    SimcardService.prototype.retopup = function (params) {
        return this.http.post(this.globalService.apiVersionHost + API_RETOPUP, params, this.header()).pipe(map(function (response) { return response; }));
    };
    SimcardService.prototype.retopupResendEmail = function (params) {
        return this.http.post(this.globalService.apiVersionHost + API_RETOPUP_RESEND_EMAIL, params, this.header()).pipe(map(function (response) { return response; }));
    };
    SimcardService.prototype.getAllRequest = function (param) {
        return this.http.post(this.globalService.apiVersionHost + API_ALL_REQUEST, param, this.header()).pipe(map(function (response) { return response; }));
    };
    SimcardService.prototype.getPulsaPaymentTracker = function (param) {
        return this.http.post(this.globalService.apiVersionHost + API_PULSA_PAYMENT_TRACKER, param, this.header()).pipe(map(function (response) { return response; }));
    };
    SimcardService.prototype.getPulsaPaymentTrackerDetail = function (param) {
        return this.http.post(this.globalService.apiVersionHost + API_PULSA_PAYMENT_TRACKER_DETAIL, param, this.header()).pipe(map(function (response) { return response; }));
    };
    SimcardService.prototype.getPulsaPaymentTrackerProgress = function (param) {
        return this.http.post(this.globalService.apiVersionHost + API_PULSA_PAYMENT_TRACKER_PROGRESS, param, this.header()).pipe(map(function (response) { return response; }));
    };
    SimcardService.prototype.pulsaPaymentSubmitReceipt = function (param) {
        return this.http.post(this.globalService.apiVersionHost + API_PULSA_PAYMENT_SUBMIT_RECEIPT, param, this.header()).pipe(map(function (response) { return response; }));
    };
    SimcardService.prototype.pulsaPaymentSubmitTracker = function (param) {
        return this.http.post(this.globalService.apiVersionHost + API_PULSA_PAYMENT_SUBMIT_TRACKER, param, this.header()).pipe(map(function (response) { return response; }));
    };
    SimcardService.prototype.getPulsaPaymentTrackerApproval = function (param) {
        return this.http.post(this.globalService.apiVersionHost + API_PULSA_PAYMENT_TRACKER_APPROVAL, param, this.header()).pipe(map(function (response) { return response; }));
    };
    SimcardService.prototype.pulsaPaymentSubmitApproval = function (param) {
        return this.http.post(this.globalService.apiVersionHost + API_PULSA_PAYMENT_SUBMIT_TRACKER_APPROVAL, param, this.header()).pipe(map(function (response) { return response; }));
    };
    SimcardService.prototype.pulsaPaymentExportPdf = function (param) {
        return this.http.post(this.globalService.apiVersionHost + API_PULSA_PAYMENT_EXPORT_PDF, param, this.header()).pipe(map(function (response) { return response; }));
    };
    SimcardService.prototype.postpaidPaymentExportPdf = function (param) {
        return this.http.post(this.globalService.apiVersionHost + API_POSTPAID_PAYMENT_EXPORT_PDF, param, this.header()).pipe(map(function (response) { return response; }));
    };
    SimcardService.prototype.postpaidPaymentExportExcel = function (param) {
        return this.http.post(this.globalService.apiVersionHost + API_POSTPAID_PAYMENT_EXPORT_EXCEL, param, this.header()).pipe(map(function (response) { return response; }));
    };
    SimcardService.prototype.pulsaPaymentGeneratePulsaReport = function (param) {
        return this.http.post(this.globalService.apiVersionHost + API_PULSA_PAYMENT_GENERATE_PULSA_REPORT, param, this.header()).pipe(map(function (response) { return response; }));
    };
    SimcardService.prototype.uploadFile = function (param) {
        return this.http.post(this.globalService.apiVersionHost + API_PROCESS_UPLOAD, param, this.header()).pipe(map(function (response) { return response; }));
    };
    SimcardService.prototype.importPostpaid = function (params) {
        return this.http.post(this.globalService.apiVersionHost + API_IMPORT_POSTPAID, params, this.header()).pipe(map(function (response) { return response; }));
    };
    SimcardService.prototype.getPostpaidProgress = function (param) {
        return this.http.post(this.globalService.apiVersionHost + API_GET_POSTPAID_PROGRESS, param, this.header()).pipe(map(function (response) { return response; }));
    };
    SimcardService.prototype.getOperatorList = function (param) {
        if (param === void 0) { param = null; }
        return this.http.post(this.globalService.apiVersionHost + API_GET_OPERATOR_LIST, param, this.header()).pipe(map(function (response) { return response; }));
    };
    SimcardService.prototype.getPostpaidDetail = function (param) {
        return this.http.post(this.globalService.apiVersionHost + API_GET_POSTPAID_DETAIL, param, this.header()).pipe(map(function (response) { return response; }));
    };
    SimcardService.prototype.submitPostpaidApproval = function (param) {
        return this.http.post(this.globalService.apiVersionHost + API_SUBMIT_POSTPAID_APPROVAL, param, this.header()).pipe(map(function (response) { return response; }));
    };
    SimcardService.prototype.generatePostpaidReport = function (param) {
        return this.http.post(this.globalService.apiVersionHost + API_GENERATE_POSTPAID_REPORT, param, this.header()).pipe(map(function (response) { return response; }));
    };
    SimcardService.prototype.getCurrentRequest = function (param) {
        return this.http.post(this.globalService.apiVersionHost + API_GET_CURRENT_REQUEST, param, this.header()).pipe(map(function (response) { return response; }));
    };
    SimcardService.prototype.edit = function (param) {
        return this.http.post(this.globalService.apiVersionHost + API_EDIT_REQUEST, param, this.header()).pipe(map(function (response) { return response; }));
    };
    SimcardService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function SimcardService_Factory() { return new SimcardService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.CookieService), i0.ɵɵinject(i3.GlobalService), i0.ɵɵinject(i4.NgbModal)); }, token: SimcardService, providedIn: "root" });
    return SimcardService;
}());
export { SimcardService };
