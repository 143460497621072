import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { PaginateModel } from 'src/app/_models/paginate.model';

@Component({
  selector: 'app-pagination-common',
  templateUrl: './pagination-common.component.html',
  styleUrls: ['./pagination-common.component.scss']
})
export class PaginationCommonComponent implements OnInit {

  @Input() paginateOption: PaginateModel;
  @Input() pageSizeOption: number[];

  currentPageSize: number;

  @Output() currentPageSizeEvent = new EventEmitter<number>();
  @Output() currentPageEvent = new EventEmitter<number>();

  constructor() { }

  ngOnInit() {
    this.currentPageSize = this.paginateOption.pageSize;
  }

  changePageSize() {
    this.currentPageSizeEvent.emit(this.currentPageSize);
  }

  changePage(page: number, allow: boolean) {
    if (allow) {
      this.currentPageEvent.emit(page);
    }
  }

}
