<!-- begin:: Page -->
<ng-container *ngIf="partialRender">
  <router-outlet></router-outlet>
</ng-container>
<ng-container *ngIf="!partialRender">
  <app-header-mobile></app-header-mobile>
  <div class="kt-grid kt-grid--hor kt-grid--root">
    <div class="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--ver kt-page">
      <app-sidebar></app-sidebar>
      <div class="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor kt-wrapper" id="kt_wrapper">
        <app-header></app-header>
        <div class="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor">
          <div [ngClass]="{'module-bg': onModulePage, 'module-approval-bg': onModuleApprovalPage}">
            <app-subheader *ngIf="subheaderShown"></app-subheader>
            <!-- begin:: Content -->
            <router-outlet></router-outlet>
            <!-- end:: Content -->
            <app-footer></app-footer>
          </div>
        </div>
      </div>
    </div>
    <!-- end:: Page -->
  </div>
  <!-- begin::Scrolltop -->
  <div id="kt_scrolltop" class="kt-scrolltop">
    <i class="fa fa-arrow-up"></i>
  </div>
  <!-- end::Scrolltop -->
  <app-toasts aria-live="polite" aria-atomic="true"></app-toasts>
</ng-container>
