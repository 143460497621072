import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-alert-common',
  templateUrl: './alert-common.component.html',
  styleUrls: ['./alert-common.component.scss']
})
export class AlertCommonComponent implements OnInit {

  @Input() errors: any;
  @Input() error: any;
  @Input() success: any;
  @Input() warning: any;


  constructor() {
    window.scroll(0, 0);
  }

  ngOnInit() {
  }
}
