<ng-container *ngIf="isAuthorized">
  Loading...
</ng-container>

<ng-container *ngIf="!isAuthorized">

  <form #loginForm="ngForm" (ngSubmit)="submit(loginForm)" class="form-signin">
    <h1 class="h3 mb-3 font-weight-normal">Please log in</h1>
    <label for="inputUsername" class="sr-only">Username</label>
    <input type="text" name="username" [(ngModel)]="login_form.username" id="inputUsername" class="form-control"
      placeholder="Username" required autofocus>
    <label for="inputPassword" class="sr-only">Password</label>
    <input type="password" name="password" [(ngModel)]="login_form.password" id="inputPassword" class="form-control"
      placeholder="Password" required>
    <div class="checkbox mb-3">
      <label>
        <input type="checkbox" value="remember-me"> Remember me
      </label>
    </div>
    <button class="btn btn-lg btn-primary btn-block" type="submit" [disabled]="isSubmited">Log in</button>
  </form>


</ng-container>