<div id="nds_contents">
  <div class="bg-white navbar-spacer"></div>
  <div class="pagecomponentwrap text-center text-white " id="pageheader">
    <div class="container pb-5 pt-75">
      <div class="no-gutters row justify-content-center align-items-center">
        <div class="col-md-10">
          <h2 class="stack_font2 h1 pb-2">
            Kebijakan Manajemen Terintegrasi Mutu & K3
          </h2>
          <h3 class="stack_font3">
             PT. NEXWAVE 
          </h3>
        </div>
      </div>
    </div>
  </div>

  <div class="pagecomponentwrap bg-white">
    <div class="container pt-75 pb-75">
      <div class="row justify-content-center align-items-center">
        <div class="col-lg-5">
          <img src="./assets/media/nds/safety.svg" alt="" class="w-100 mb-4 mb-lg-0">
        </div>
        <div class="col-lg-7">
          <div class="description">
            <div class="h5 ml-0 ml-lg-5">
              PT. Nexwave sebagai salah satu perusahaan yang bergerak dalam usaha perdagangan umum dan pelayanan di bidang telekomunikasi berkomitmen untuk terus berupaya untuk menghasilkan pelayanan dan produk unggulan yang sesuai dengan kebutuhan dan harapan pelanggan. Dalam mencapai tujuan yang sesuai dengan visi dan misi perusahaan, PT. Nexwave berkomitmen untuk menerapkan Sistem Manajemen Mutu ISO 9001:2015 dan Sistem Manajemen Keselamatan & Kesehatan Kerja ISO 45001:2018.
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div [innerHTML]="webContent.k3ContentAtas" id="k3ContentAtas" class="pagecomponentwrap bg-light">

  </div>
  <div [innerHTML]="webContent.k3ContentBawah" id="k3ContentBawah" class="pagecomponentwrap bg-light">

  </div>
</div>
