/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./gcs-upload.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../node_modules/@ng-bootstrap/ng-bootstrap/ng-bootstrap.ngfactory";
import * as i3 from "@ng-bootstrap/ng-bootstrap";
import * as i4 from "@angular/common";
import * as i5 from "../../../../../node_modules/ngx-image-cropper/ngx-image-cropper.ngfactory";
import * as i6 from "ngx-image-cropper";
import * as i7 from "@angular/platform-browser";
import * as i8 from "./gcs-upload.component";
import * as i9 from "../../../_services/upload.service";
import * as i10 from "ng2-img-max/dist/src/ng2-img-max.service";
var styles_GcsUploadComponent = [i0.styles];
var RenderType_GcsUploadComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_GcsUploadComponent, data: {} });
export { RenderType_GcsUploadComponent as RenderType_GcsUploadComponent };
function View_GcsUploadComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "p", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "span", [["class", "kt-link kt-link--state kt-link--primary"]], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.remark; _ck(_v, 2, 0, currVal_0); }); }
function View_GcsUploadComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, [[1, 0], ["inputFile", 1]], null, 0, "input", [["class", "custom-file-input"], ["type", "file"]], [[8, "disabled", 0], [1, "accept", 0]], [[null, "change"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("change" === en)) {
        var pd_0 = ((_co.cropper ? _co.fileChangeEvent($event) : _co.selectFile($event)) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.selectedFiles && !_co.isFinish); var currVal_1 = _co.acceptFile; _ck(_v, 0, 0, currVal_0, currVal_1); }); }
function View_GcsUploadComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, [[1, 0], ["inputFile", 1]], null, 0, "input", [["class", "custom-file-input"], ["type", "file"]], [[8, "disabled", 0]], [[null, "change"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("change" === en)) {
        var pd_0 = ((_co.cropper ? _co.fileChangeEvent($event) : _co.selectFile($event)) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.selectedFiles && !_co.isFinish); _ck(_v, 0, 0, currVal_0); }); }
function View_GcsUploadComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "label", [["class", "custom-file-label text-ellipsis"], ["for", "customFile"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.labelInput; _ck(_v, 1, 0, currVal_0); }); }
function View_GcsUploadComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "p", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "span", [["class", "kt-link kt-link--state kt-link--danger"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["invalid file type!"]))], null, null); }
function View_GcsUploadComponent_7(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "p", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "span", [["class", "kt-link kt-link--state kt-link--primary"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["file uploaded successfully."]))], null, null); }
function View_GcsUploadComponent_8(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "ngb-progressbar", [["type", "primary"]], null, null, null, i2.View_NgbProgressbar_0, i2.RenderType_NgbProgressbar)), i1.ɵdid(2, 49152, null, 0, i3.NgbProgressbar, [i3.NgbProgressbarConfig], { animated: [0, "animated"], striped: [1, "striped"], type: [2, "type"], value: [3, "value"] }, null), (_l()(), i1.ɵeld(3, 0, null, 0, 1, "i", [], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, ["", ""]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = true; var currVal_1 = true; var currVal_2 = "primary"; var currVal_3 = _co.progress; _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2, currVal_3); }, function (_ck, _v) { var _co = _v.component; var currVal_4 = (_co.isFinish ? "Upload finish!" : (_co.progress + "%")); _ck(_v, 4, 0, currVal_4); }); }
function View_GcsUploadComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 14, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 7, "div", [["class", "form-group mb-10"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 6, "div", [["class", "custom-file"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_GcsUploadComponent_3)), i1.ɵdid(4, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_GcsUploadComponent_4)), i1.ɵdid(6, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_GcsUploadComponent_5)), i1.ɵdid(8, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_GcsUploadComponent_6)), i1.ɵdid(10, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_GcsUploadComponent_7)), i1.ɵdid(12, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_GcsUploadComponent_8)), i1.ɵdid(14, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.acceptFile; _ck(_v, 4, 0, currVal_0); var currVal_1 = !_co.acceptFile; _ck(_v, 6, 0, currVal_1); var currVal_2 = (_co.labelInput != ""); _ck(_v, 8, 0, currVal_2); var currVal_3 = _co.errorMime; _ck(_v, 10, 0, currVal_3); var currVal_4 = _co.uploadSuccess; _ck(_v, 12, 0, currVal_4); var currVal_5 = (_co.selectedFiles && !_co.isFinish); _ck(_v, 14, 0, currVal_5); }, null); }
function View_GcsUploadComponent_10(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "a", [["class", "text-ellipsis kt-link kt-link--state kt-link--danger"], ["href", "javascript:;"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["invalid file type!"]))], null, null); }
function View_GcsUploadComponent_11(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "a", [["class", "text-ellipsis kt-link kt-link--state kt-link--primary"], ["href", "javascript:;"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["upload successful."]))], null, null); }
function View_GcsUploadComponent_12(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 4, "div", [["style", "width: 100%; height: 19.5px;"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 3, "ngb-progressbar", [["type", "primary"]], null, null, null, i2.View_NgbProgressbar_0, i2.RenderType_NgbProgressbar)), i1.ɵdid(3, 49152, null, 0, i3.NgbProgressbar, [i3.NgbProgressbarConfig], { animated: [0, "animated"], striped: [1, "striped"], type: [2, "type"], value: [3, "value"] }, null), (_l()(), i1.ɵeld(4, 0, null, 0, 1, "i", [], null, null, null, null, null)), (_l()(), i1.ɵted(5, null, ["", ""]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = true; var currVal_1 = true; var currVal_2 = "primary"; var currVal_3 = _co.progress; _ck(_v, 3, 0, currVal_0, currVal_1, currVal_2, currVal_3); }, function (_ck, _v) { var _co = _v.component; var currVal_4 = (_co.isFinish ? "Upload finish!" : (_co.progress + "%")); _ck(_v, 5, 0, currVal_4); }); }
function View_GcsUploadComponent_13(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\u00A0"]))], null, null); }
function View_GcsUploadComponent_9(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 12, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 10, "ul", [["class", "list-group"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 9, "li", [["class", "list-group-item list-group-item-nopadding d-flex justify-content-between align-items-center"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_GcsUploadComponent_10)), i1.ɵdid(4, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_GcsUploadComponent_11)), i1.ɵdid(6, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_GcsUploadComponent_12)), i1.ɵdid(8, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_GcsUploadComponent_13)), i1.ɵdid(10, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(11, 0, null, null, 0, "span", [["class", "fa fa-paperclip kt-font-primary cursor-pointer"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 12).click() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(12, 0, [["inputFileSmall", 1]], null, 0, "input", [["class", "custom-file-input"], ["style", "display: none;"], ["type", "file"]], [[8, "disabled", 0], [1, "accept", 0]], [[null, "change"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("change" === en)) {
        var pd_0 = ((_co.cropper ? _co.fileChangeEvent($event) : _co.selectFile($event)) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.errorMime; _ck(_v, 4, 0, currVal_0); var currVal_1 = _co.uploadSuccess; _ck(_v, 6, 0, currVal_1); var currVal_2 = (_co.selectedFiles && !_co.isFinish); _ck(_v, 8, 0, currVal_2); var currVal_3 = ((!_co.errorMime && !_co.uploadSuccess) && !(_co.selectedFiles && !_co.isFinish)); _ck(_v, 10, 0, currVal_3); }, function (_ck, _v) { var _co = _v.component; var currVal_4 = (_co.selectedFiles && !_co.isFinish); var currVal_5 = _co.acceptFile; _ck(_v, 12, 0, currVal_4, currVal_5); }); }
function View_GcsUploadComponent_14(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [["class", "modal-header"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "h4", [["class", "modal-title"], ["id", "modal-basic-title"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Profile update"])), (_l()(), i1.ɵeld(3, 0, null, null, 2, "button", [["aria-label", "Close"], ["class", "close"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (_v.context.$implicit.dismiss("Cross click") !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "span", [["aria-hidden", "true"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\u00D7"])), (_l()(), i1.ɵeld(6, 0, null, null, 2, "div", [["class", "modal-body"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 1, "image-cropper", [["format", "jpg"]], [[4, "text-align", null]], [[null, "imageCropped"], [null, "imageLoaded"], [null, "cropperReady"], [null, "loadImageFailed"], ["window", "resize"], ["document", "mousemove"], ["document", "touchmove"], ["document", "mouseup"], ["document", "touchend"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("window:resize" === en)) {
        var pd_0 = (i1.ɵnov(_v, 8).onResize() !== false);
        ad = (pd_0 && ad);
    } if (("document:mousemove" === en)) {
        var pd_1 = (i1.ɵnov(_v, 8).moveImg($event) !== false);
        ad = (pd_1 && ad);
    } if (("document:touchmove" === en)) {
        var pd_2 = (i1.ɵnov(_v, 8).moveImg($event) !== false);
        ad = (pd_2 && ad);
    } if (("document:mouseup" === en)) {
        var pd_3 = (i1.ɵnov(_v, 8).moveStop() !== false);
        ad = (pd_3 && ad);
    } if (("document:touchend" === en)) {
        var pd_4 = (i1.ɵnov(_v, 8).moveStop() !== false);
        ad = (pd_4 && ad);
    } if (("imageCropped" === en)) {
        var pd_5 = (_co.imageCropped($event) !== false);
        ad = (pd_5 && ad);
    } if (("imageLoaded" === en)) {
        var pd_6 = (_co.imageLoaded() !== false);
        ad = (pd_6 && ad);
    } if (("cropperReady" === en)) {
        var pd_7 = (_co.cropperReady() !== false);
        ad = (pd_7 && ad);
    } if (("loadImageFailed" === en)) {
        var pd_8 = (_co.loadImageFailed() !== false);
        ad = (pd_8 && ad);
    } return ad; }, i5.View_ImageCropperComponent_0, i5.RenderType_ImageCropperComponent)), i1.ɵdid(8, 573440, null, 0, i6.ImageCropperComponent, [i7.DomSanitizer, i1.ChangeDetectorRef, i1.NgZone], { imageChangedEvent: [0, "imageChangedEvent"], format: [1, "format"], maintainAspectRatio: [2, "maintainAspectRatio"], aspectRatio: [3, "aspectRatio"], resizeToWidth: [4, "resizeToWidth"] }, { imageCropped: "imageCropped", imageLoaded: "imageLoaded", cropperReady: "cropperReady", loadImageFailed: "loadImageFailed" }), (_l()(), i1.ɵeld(9, 0, null, null, 2, "div", [["class", "modal-footer"]], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 1, "button", [["class", "btn btn-outline-dark"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (_v.context.$implicit.close("crop") !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, [" Crop"]))], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.imageChangedEvent; var currVal_2 = "jpg"; var currVal_3 = true; var currVal_4 = (1 / 1); var currVal_5 = 640; _ck(_v, 8, 0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 8).alignImage; _ck(_v, 7, 0, currVal_0); }); }
export function View_GcsUploadComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(671088640, 1, { inputFileVariable: 0 }), i1.ɵqud(671088640, 2, { content: 0 }), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_GcsUploadComponent_1)), i1.ɵdid(3, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_GcsUploadComponent_2)), i1.ɵdid(5, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_GcsUploadComponent_9)), i1.ɵdid(7, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(0, [[2, 2], ["content", 2]], null, 0, null, View_GcsUploadComponent_14))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.remark; _ck(_v, 3, 0, currVal_0); var currVal_1 = (_co.theme == "default"); _ck(_v, 5, 0, currVal_1); var currVal_2 = (_co.theme == "small"); _ck(_v, 7, 0, currVal_2); }, null); }
export function View_GcsUploadComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-gcs-upload", [], null, null, null, View_GcsUploadComponent_0, RenderType_GcsUploadComponent)), i1.ɵdid(1, 114688, null, 0, i8.GcsUploadComponent, [i9.UploadService, i10.Ng2ImgMaxService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var GcsUploadComponentNgFactory = i1.ɵccf("app-gcs-upload", i8.GcsUploadComponent, View_GcsUploadComponent_Host_0, { fileType: "fileType", prefix: "prefix", acceptFile: "acceptFile", remark: "remark", cropper: "cropper", module: "module", tableTarget: "tableTarget", columnTarget: "columnTarget", maxLengthFilename: "maxLengthFilename", filesizeMaximum: "filesizeMaximum", filepixelMaximum: "filepixelMaximum", labelInput: "labelInput", autoReset: "autoReset", isPublicFile: "isPublicFile", theme: "theme" }, { success: "success", afterCrop: "afterCrop", change: "change" }, []);
export { GcsUploadComponentNgFactory as GcsUploadComponentNgFactory };
