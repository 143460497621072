import { HttpClient, HttpHeaders } from '@angular/common/http';
import { CookieService } from 'ngx-cookie-service';
import { map } from 'rxjs/operators';
import { GlobalService } from './global.service';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "./global.service";
import * as i3 from "ngx-cookie-service";
var API_GET_LIST = '/modules-approval/get-list';
var ModuleApprovalService = /** @class */ (function () {
    function ModuleApprovalService(http, globalService, cookieService) {
        this.http = http;
        this.globalService = globalService;
        this.cookieService = cookieService;
    }
    ModuleApprovalService.prototype.header = function () {
        return {
            headers: new HttpHeaders()
                .set('token', this.cookieService.get('_q'))
        };
    };
    ModuleApprovalService.prototype.getList = function () {
        return this.http.post(this.globalService.apiVersionHost + API_GET_LIST, null, this.header()).pipe(map(function (response) { return response; }));
    };
    ModuleApprovalService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ModuleApprovalService_Factory() { return new ModuleApprovalService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.GlobalService), i0.ɵɵinject(i3.CookieService)); }, token: ModuleApprovalService, providedIn: "root" });
    return ModuleApprovalService;
}());
export { ModuleApprovalService };
