<div class="kt-portlet kt-portlet--mobile" id="konten">
  <div class="kt-portlet__head portlet-blue">
    <div class="kt-portlet__head-label">
      <h3 class="kt-portlet__head-title">
        <i class="fa fa-child"></i> Employee Skill
      </h3>
    </div>
  </div>

  <div class="kt-portlet__body">
    <ngb-tabset type="pills" activeId="{{skill_id}}" (tabChange)="dataSource($event)">
        <ngb-tab id="RIGGER">
            <ng-template ngbTabTitle>RIGGER</ng-template>
            <ng-template ngbTabContent>
              <app-skill-form [skillFormConfig]="tableSkillConfig" (rowEdited)="saveSkill($event)"></app-skill-form>
            </ng-template>
        </ngb-tab>

        <ngb-tab id="DT">
            <ng-template ngbTabTitle>DT</ng-template>
            <ng-template ngbTabContent>
              <app-skill-form [skillFormConfig]="tableSkillConfig" (rowEdited)="saveSkill($event)"></app-skill-form>
            </ng-template>
        </ngb-tab>

        <ngb-tab id="RF">
          <ng-template ngbTabTitle>RF</ng-template>
            <ng-template ngbTabContent>
              <app-skill-form [skillFormConfig]="tableSkillConfig" (rowEdited)="saveSkill($event)"></app-skill-form>
            </ng-template>
        </ngb-tab>

        <ngb-tab id="OSS">
            <ng-template ngbTabTitle>OSS</ng-template>
            <ng-template ngbTabContent>
              <app-skill-form [skillFormConfig]="tableSkillConfig" (rowEdited)="saveSkill($event)"></app-skill-form>
            </ng-template>
        </ngb-tab>

        <ngb-tab id="COMMISIONING">
            <ng-template ngbTabTitle>COMMISIONING</ng-template>
            <ng-template ngbTabContent>
              <app-skill-form [skillFormConfig]="tableSkillConfig" (rowEdited)="saveSkill($event)"></app-skill-form>
            </ng-template>
        </ngb-tab>

        <ngb-tab id="RNO">
            <ng-template ngbTabTitle>RNO</ng-template>
            <ng-template ngbTabContent>
              <app-skill-form [skillFormConfig]="tableSkillConfig" (rowEdited)="saveSkill($event)"></app-skill-form>
            </ng-template>
        </ngb-tab>

        <ngb-tab id="RNP">
            <ng-template ngbTabTitle>RNP</ng-template>
            <ng-template ngbTabContent>
              <app-skill-form [skillFormConfig]="tableSkillConfig" (rowEdited)="saveSkill($event)"></app-skill-form>
            </ng-template>
        </ngb-tab>

        <ngb-tab id="TNP">
            <ng-template ngbTabTitle>TNP</ng-template>
            <ng-template ngbTabContent>
              <app-skill-form [skillFormConfig]="tableSkillConfig" (rowEdited)="saveSkill($event)"></app-skill-form>
            </ng-template>
        </ngb-tab>

        <ngb-tab id="TOOLS">
            <ng-template ngbTabTitle>TOOLS</ng-template>
            <ng-template ngbTabContent>
              <app-skill-form [skillFormConfig]="tableSkillConfig" (rowEdited)="saveSkill($event)"></app-skill-form>
            </ng-template>
        </ngb-tab>

        <ngb-tab id="PROGRAMMING">
            <ng-template ngbTabTitle>PROGRAMMING</ng-template>
            <ng-template ngbTabContent>
              <app-skill-form [skillFormConfig]="tableSkillConfig" (rowEdited)="saveSkill($event)"></app-skill-form>
            </ng-template>
        </ngb-tab>

        <ngb-tab id="TEAMLEADER">
            <ng-template ngbTabTitle>TEAM LEADER</ng-template>
            <ng-template ngbTabContent>
              <app-skill-form [skillFormConfig]="tableSkillConfig" (rowEdited)="saveSkill($event)"></app-skill-form>
            </ng-template>
        </ngb-tab>
    </ngb-tabset>
    <div class="row">
      <div class="col-lg-12 text-right">
          <button class="btn btn-primary" (click)="submitSkill()"><i class="fa fa-check"></i>SUBMIT SKILL</button>
      </div>
    </div>
  </div>
</div>