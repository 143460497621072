import { of } from 'rxjs';
import { CookieService } from 'ngx-cookie-service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { GlobalService } from './global.service';
import { map } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "ngx-cookie-service";
import * as i3 from "./global.service";
var API_LOGOUT = '/auth/logout';
var API_TELEGRAM_LOGIN = '/auth/telegram-login';
var API_LINK_TELEGRAM = '/auth/link-telegram';
var API_DROP_CV = '/users/default/drop-cv';
var UserService = /** @class */ (function () {
    function UserService(http, cookieService, globalService) {
        this.http = http;
        this.cookieService = cookieService;
        this.globalService = globalService;
    }
    UserService.prototype.header = function () {
        return {
            headers: new HttpHeaders()
                .set('token', this.cookieService.get('_q'))
        };
    };
    /**
     * Get access token
     * @returns {Observable<string>}
     */
    UserService.prototype.getAccessToken = function () {
        var token = this.cookieService.get('_q');
        return of(token);
    };
    /**
     * Get refresh token
     * @returns {Observable<string>}
     */
    UserService.prototype.getRefreshToken = function () {
        var token = this.cookieService.get('_w');
        return of(token);
    };
    /**
     * Get user roles in JSON string
     * @returns {Observable<any>}
     */
    UserService.prototype.getUserRoles = function (module) {
        var tokenRoles = localStorage.getItem(module);
        if (tokenRoles) {
            var roles = this.payloadToken(tokenRoles);
            return roles;
        }
        else {
            return null;
        }
    };
    UserService.prototype.getUserID = function () {
        var jwt = this.payloadToken();
        return jwt.data.userid;
    };
    UserService.prototype.getNama = function () {
        var jwt = this.payloadToken();
        return jwt.data.nama;
    };
    UserService.prototype.getName = function () {
        var jwt = this.payloadToken();
        return jwt.data.nama;
    };
    UserService.prototype.getProfile = function () {
        var jwt = this.payloadToken();
        return jwt.data.profile;
    };
    UserService.prototype.getUsername = function () {
        var jwt = this.payloadToken();
        return jwt.data.username;
    };
    UserService.prototype.getPosition = function () {
        var jwt = this.payloadToken();
        return jwt.data.position;
    };
    UserService.prototype.getDesignation = function () {
        var jwt = this.payloadToken();
        return jwt.data.designation;
    };
    UserService.prototype.getUserPtnwStatus = function () {
        var jwt = this.payloadToken();
        return (jwt.data.user_ptnw_status === 'Internal') ? true : false;
    };
    UserService.prototype.getFcmToken = function () {
        return this.cookieService.get('_ft');
    };
    UserService.prototype.getUserGroup = function () {
        var tokenRoles = this.cookieService.get('_g');
        if (tokenRoles) {
            var roles = this.payloadToken(tokenRoles);
            return roles;
        }
        else {
            return [];
        }
    };
    UserService.prototype.isInGroup = function (g) {
        var group = this.getUserGroup();
        return group.includes(g);
    };
    UserService.prototype.fcmTokenExist = function (token) {
        return (token === this.cookieService.get('_ft')) ? true : false;
    };
    /**
     * Set access token
     * @returns {UserService}
     */
    UserService.prototype.setAccessToken = function (token) {
        var jwt = this.payloadToken(token);
        if (window.location.hostname == 'ptnw.net') {
            this.cookieService.set('_q', token, jwt.exp, '/', '.ptnw.net', true);
        }
        else if (window.location.hostname == 'nexwaveindonesia.com') {
            this.cookieService.set('_q', token, jwt.exp, '/', '.nexwaveindonesia.com', true);
        }
        else {
            this.cookieService.set('_q', token, jwt.exp, '/');
        }
        return this;
    };
    /**
     * Set refresh token
     * @returns {UserService}
     */
    UserService.prototype.setRefreshToken = function (token) {
        var date = new Date();
        date.setDate(date.getDate() + 60);
        this.cookieService.set('_w', token, date, '/');
        return this;
    };
    /**
     * Set roles token
     * @returns {UserService}
     */
    UserService.prototype.setRolesToken = function (token, module) {
        localStorage.setItem(module, token);
        return this;
    };
    /**
     * Set refresh token
     * @returns {UserService}
     */
    UserService.prototype.setGroupToken = function (token) {
        // localStorage.setItem('_g', token);
        var date = new Date();
        date.setDate(date.getDate() + 60);
        if (/ptnw\.net/.test(window.location.hostname)) {
            this.cookieService.set('_g', token, date, '/', '.ptnw.net', true);
        }
        else if (/nexwaveindonesia\.com/.test(window.location.hostname)) {
            this.cookieService.set('_g', token, date, '/', '.nexwaveindonesia.com', true);
        }
        else {
            this.cookieService.set('_g', token, date, '/');
        }
        return this;
    };
    /**
     * Set user roles
     * @param roles
     * @returns {UserService}
     */
    UserService.prototype.setUserRoles = function (roles) {
        var tomorrow = new Date();
        tomorrow.setDate(tomorrow.getDate() + 1);
        if (roles != null) {
            this.cookieService.set('userRoles', JSON.stringify(roles), tomorrow, '/');
        }
        return this;
    };
    /**
     * Set vacancy token
     * @returns {UserService}
     */
    UserService.prototype.setVacancyToken = function (token) {
        localStorage.setItem('vacancy', token);
        return this;
    };
    UserService.prototype.setUsername = function (username) {
        this.cookieService.set('username', username);
        return this;
    };
    UserService.prototype.setUserFullname = function (fullname) {
        this.cookieService.set('fullname', fullname);
        return this;
    };
    UserService.prototype.setUserEmail = function (email) {
        this.cookieService.set('email', email);
        return this;
    };
    UserService.prototype.setUserPic = function (picture) {
        this.cookieService.set('picture', picture);
        return this;
    };
    UserService.prototype.setFcmToken = function (token) {
        this.cookieService.set('_ft', token, 365);
        return null;
    };
    UserService.prototype.payloadToken = function (jwt) {
        var token = jwt ? jwt : this.cookieService.get('_q');
        var tokenParsed = token.split('.');
        var payload = tokenParsed[1];
        try {
            var response = JSON.parse(atob(payload));
            return response;
        }
        catch (ex) {
            return null;
        }
    };
    UserService.prototype.startGeolocation = function () {
        return new Promise(function (resolve, reject) {
            navigator.geolocation.getCurrentPosition(function (resp) {
                resolve({ lng: resp.coords.longitude, lat: resp.coords.latitude });
            }, function (err) {
                reject(err);
            });
        });
    };
    UserService.prototype.logout = function (fingerprint) {
        return this.http.post(this.globalService.apiVersionHost + API_LOGOUT, { fingerprint: fingerprint }, this.header()).pipe(map(function (response) { return response; }));
    };
    UserService.prototype.telegramLogin = function (params) {
        return this.http.post(this.globalService.apiVersionHost + API_TELEGRAM_LOGIN, params, this.header()).pipe(map(function (response) { return response; }));
    };
    UserService.prototype.linkTelegram = function (params) {
        return this.http.post(this.globalService.apiVersionHost + API_LINK_TELEGRAM, params, this.header()).pipe(map(function (response) { return response; }));
    };
    UserService.prototype.dropCV = function (params) {
        return this.http.post(this.globalService.apiVersionHost + API_DROP_CV, params, this.header()).pipe(map(function (response) { return response; }));
    };
    UserService.prototype.listPosisi = function () {
        return this.http.post(this.globalService.apiVersionHost + '/users/default/list-posisi', null, this.header()).pipe(map(function (response) { return response; }));
    };
    UserService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function UserService_Factory() { return new UserService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.CookieService), i0.ɵɵinject(i3.GlobalService)); }, token: UserService, providedIn: "root" });
    return UserService;
}());
export { UserService };
