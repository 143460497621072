import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { NgForm } from '@angular/forms';
import { NgxPermissionsService } from 'ngx-permissions';
import { AuthenticationService } from 'src/app/auth/authentication.service';
import Swal from 'sweetalert2';
import { UserService } from 'src/app/_services/user.service';
import { MessagingService } from 'src/app/_services/messaging.service';


@Component({
  selector: 'app-sso',
  templateUrl: './sso.component.html',
  styleUrls: ['./sso.component.scss']
})
export class SsoComponent implements OnInit {

  public isAuthorized: boolean = false;
  public hide_password = true;
  public isSubmited = false;
  public login_form: any = { username: '', password: '', fcm_token: this.messagingService.getFcmToken() };
  public returnUrl: string;

  constructor(
    public authService: AuthenticationService,
    private router: Router,
    private route: ActivatedRoute,
    private permissionsService: NgxPermissionsService,
    private userService: UserService,
    private messagingService: MessagingService
  ) { }

  ngOnInit() {

    this.authService.isAuthorized().subscribe(value => {
      this.isAuthorized = value;
    });

    //this.authService.logout('sso');

    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/main/pm';
  }

  ngAfterViewInit() {
    if (this.isAuthorized) {
      this.authService.createSso().subscribe(response => {
        if (response.status) {
          let parsedUrl = new URL(this.returnUrl);
          let validationUrl = parsedUrl.origin + '/validate?token=' + response.result.token + '&returnUrl=' + encodeURIComponent(this.returnUrl);
          console.log(validationUrl);
          window.location.href = validationUrl;

        } else {
          Swal.fire(response.message);
        }
      }, error => {
        console.log(error);
      });
    }
  }

  submit(loginForm: NgForm) {
    this.isSubmited = true;
    if (this.validate(loginForm)) {
      this.authService.loginSso(this.login_form).subscribe(response => {
        if (response.status) {
          console.log(response.result);
          const group: any[] = this.userService.getUserGroup();
          group.push('USER');
          this.permissionsService.loadPermissions(group);
          //this.router.navigateByUrl(this.returnUrl);

          let parsedUrl = new URL(this.returnUrl);
          let validationUrl = parsedUrl.origin + '/validate?token=' + response.result.token + '&returnUrl=' + encodeURIComponent(this.returnUrl);
          console.log(validationUrl);
          window.location.href = validationUrl;
        } else {
          Swal.fire(response.message);
          this.isSubmited = false;
        }
      }, (error: any) => {
        Swal.fire(error.message);
        this.isSubmited = false;
      });
    } else {
      this.isSubmited = false;
    }
  }

  validate(loginForm: NgForm) {
    if (loginForm.form.status === 'VALID') {
      return true;
    } else {
      return false;
    }
  }

}
