import { HttpClient, HttpHeaders } from '@angular/common/http';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CookieService } from 'ngx-cookie-service';
import { GlobalService } from './global.service';
import { Observable, throwError, BehaviorSubject } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { AlertModalComponent } from '../layout/shared/alert-modal/alert-modal.component';
import { VacancyDetail } from '../_models/vacancy.model';
import Swal from 'sweetalert2';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "ngx-cookie-service";
import * as i3 from "./global.service";
import * as i4 from "@ng-bootstrap/ng-bootstrap";
// KARIR
var API_CREATE_MASTER = '/vacancy/dev/add-vacancy';
var API_GET_PROJECT = '/vacancy/dev/get-project';
var API_GET_SKILL_TOOL = '/vacancy/dev/get-skill-and-tools';
var API_GET_EDIT_MASTER = '/vacancy/dev/get-edit-master';
var API_GET_EDIT_DETAIL = '/vacancy/dev/get-edit-detail';
var API_EDIT_MASTER = '/vacancy/dev/edit-vacancy-master';
var API_EDIT_DETAIL_VACANCY = '/vacancy/dev/edit-vacancy-detail';
var API_CREATE_DETAIL = '/vacancy/dev/add-detail-vacancy';
var API_VACANCY = '/vacancy/default/get-vacancy';
var API_DELETE_DETAIL = '/vacancy/dev/delete-detail-vacancy';
var API_VACANCY_DETAIL = '/vacancy/default/vacancy-detail';
var API_APPLY_VACANCY = '/vacancy/default/apply-vacancy';
var API_LIST_APPLICANT = '/vacancy/default/list-applicant';
var API_LIST_CV = '/vacancy/default/list-cv';
// APPLICANT
var API_SAVE_SKILL = '/vacancy/recruitment/save-skill';
var API_EDIT_DETAIL_APPLICANT = '/vacancy/recruitment/edit-detail';
var API_GET_APPLICANT_LIST = '/vacancy/recruitment/get-applicant-list';
var API_GET_APPLICANT_LIST_SERVER = '/vacancy/recruitment/get-applicant-list-server';
var API_GET_NOT_FOUND_APPLICANT = '/vacancy/recruitment/get-not-found-applicant';
var API_GET_APPLICANT_DETAIL = '/vacancy/recruitment/get-applicant-detail';
var API_GET_APPLICANT_DOCUMENT = '/vacancy/recruitment/get-applicant-document';
var API_GET_APPLICATION = '/vacancy/employeeapplication/get-application';
var API_GET_STEP_RECRUITMENT = '/vacancy/recruitment/get-step-recruitment';
var API_GET_DATA_CANDIDATE = '/vacancy/recruitment/get-data-candidate';
var API_GET_DATA_INTERVIEW = '/vacancy/recruitment/get-interview-data';
var API_GET_DATA_NEGOTIATION = '/vacancy/recruitment/get-negotiation-data';
var API_SUBMIT_TECHNICAL_INTERVIEW = '/vacancy/recruitment/submit-technical-interview';
var API_SUBMIT_NEGOTIATION = '/vacancy/recruitment/submit-negotiation';
var API_SAVE_REGISTATION = '/vacancy/recruitment/save-registrasi';
var API_SAVE_APPLICATION = '/vacancy/employeeapplication/save-application';
var API_UPDATE_APPLICATION = '/vacancy/employeeapplication/update-application';
var API_DELETE_APPLICATION = '/vacancy/employeeapplication/delete-application';
var API_EDIT_TECHNICAL = '/vacancy/recruitment/edit-technical';
var API_EDIT_NEGOTIATION = '/vacancy/recruitment/edit-negotiation';
var API_GET_USER_ER = '/vacancy/recruitment/get-user-er';
var API_GET_APPLICANT_NAME = '/vacancy/recruitment/get-list-applicant-name';
var API_ER = '/vacancy/recruitment/er';
var API_TEMPLATE_SKILL = '/vacancy/recruitment/template-skill';
var API_UPDATE_SKILL = '/vacancy/recruitment/update-skill';
var API_SUBMIT_SKILL = '/vacancy/recruitment/submit-skill';
var API_SUBMIT_SKILL_NOT_REGISTER = '/vacancy/recruitment/submit-skill-not-register';
var API_FINISH_TECHNICAL = '/vacancy/recruitment/finish-technical';
var API_FINISH_NEGOTIATION = '/vacancy/recruitment/finish-negotiation';
var API_CANCEL_PROCESS = '/employee/hrd/cancel-proccess';
var API_APPLICANT_INFO = '/vacancy/recruitment/get-applicant-info';
var API_ADD_OTHER_VACANCY = '/vacancy/recruitment/add-other-vacancy';
var API_DELETE_APPLY_VACANCY = '/vacancy/recruitment/delete-other-vacancy';
var API_SUBMIT_DECLARATION = '/vacancy/employeeapplication/submit-declaration';
var API_AUTHORIZED = '/vacancy/employeeapplication/get-authorized';
var VacancyService = /** @class */ (function () {
    function VacancyService(http, cookieService, globalService, modalService) {
        this.http = http;
        this.cookieService = cookieService;
        this.globalService = globalService;
        this.modalService = modalService;
        this.enableidbEmit = new BehaviorSubject(false);
        this.enableidbEmitObs = this.enableidbEmit.asObservable();
    }
    VacancyService.prototype.alertModal = function (err, code) {
        if (code === 403) {
            return Swal.fire(err);
        }
        var modalRef = this.modalService.open(AlertModalComponent);
        modalRef.componentInstance.kategori = 'alert';
        modalRef.componentInstance.error = (err) ? err : 'Request Timeout.';
    };
    VacancyService.prototype.header = function () {
        return { headers: new HttpHeaders()
                .set('token', this.cookieService.get('_q'))
                .set('module', localStorage.getItem('vacancy'))
        };
    };
    // HEADER ONLY FOR CANCEL PROCESS
    VacancyService.prototype.header2 = function () {
        return { headers: new HttpHeaders()
                .set('token', this.cookieService.get('_q'))
                .set('module', localStorage.getItem('employee'))
        };
    };
    // TODO KARIR
    VacancyService.prototype.getVacancyDetail = function (param) {
        return this.http.post(this.globalService.apiVersionHost + API_VACANCY_DETAIL, param, this.header()).pipe(map(function (response) { return response; }), catchError(function (err) {
            return throwError(err);
        }));
    };
    VacancyService.prototype.getListApplicant = function (param) {
        return this.http.post(this.globalService.apiVersionHost + API_LIST_APPLICANT, param, this.header());
    };
    VacancyService.prototype.getListCv = function (param) {
        return this.http.post(this.globalService.apiVersionHost + API_LIST_CV, param, this.header());
    };
    VacancyService.prototype.addMaster = function (param) {
        return this.http.post(this.globalService.apiVersionHost + API_CREATE_MASTER, param, this.header()).pipe(map(function (response) { return response; }), catchError(function (err) {
            return throwError(err);
        }));
    };
    VacancyService.prototype.editMaster = function (param) {
        return this.http.post(this.globalService.apiVersionHost + API_EDIT_MASTER, param, this.header()).pipe(map(function (response) { return response; }), catchError(function (err) {
            return throwError(err);
        }));
    };
    VacancyService.prototype.editDetailVacancy = function (param) {
        return this.http.post(this.globalService.apiVersionHost + API_EDIT_DETAIL_VACANCY, param, this.header()).pipe(map(function (response) { return response; }), catchError(function (err) {
            return throwError(err);
        }));
    };
    VacancyService.prototype.getProject = function () {
        return this.http.get(this.globalService.apiVersionHost + API_GET_PROJECT, this.header()).pipe(map(function (response) { return response; }));
    };
    VacancyService.prototype.getSkillTool = function () {
        return this.http.get(this.globalService.apiVersionHost + API_GET_SKILL_TOOL, this.header()).pipe(map(function (response) { return response; }));
    };
    VacancyService.prototype.getEditMaster = function (id) {
        return this.http.post(this.globalService.apiVersionHost + API_GET_EDIT_MASTER, { id: id }, this.header()).pipe(map(function (response) { return response; }));
    };
    VacancyService.prototype.getEditDetail = function (id) {
        return this.http.post(this.globalService.apiVersionHost + API_GET_EDIT_DETAIL, { id: id }, this.header()).pipe(map(function (response) { return response; }));
    };
    VacancyService.prototype.createDetailVacancy = function (param) {
        return this.http.post(this.globalService.apiVersionHost + API_CREATE_DETAIL, param, this.header()).pipe(map(function (response) { return response; }));
    };
    VacancyService.prototype.getVacancy = function (param) {
        return this.http.post(this.globalService.apiVersionHost + API_VACANCY, param, this.header()).pipe(map(function (response) { return response; }));
    };
    VacancyService.prototype.deleteDetail = function (id) {
        return this.http.post(this.globalService.apiVersionHost + API_DELETE_DETAIL, { id: id }, this.header()).pipe(map(function (response) { return response; }));
    };
    VacancyService.prototype.applyVacancy = function (param) {
        return this.http.post(this.globalService.apiVersionHost + API_APPLY_VACANCY, param, this.header()).pipe(map(function (response) { return response; }));
    };
    // TODO APPLICANT
    VacancyService.prototype.getApplicant = function (param) {
        return this.http.post(this.globalService.apiVersionHost + API_GET_APPLICANT_LIST, param, this.header()).pipe(map(function (response) { return response; }));
    };
    VacancyService.prototype.getApplicantFromServer = function (param) {
        return this.http.post(this.globalService.apiVersionHost + API_GET_APPLICANT_LIST_SERVER, param, this.header()).pipe(map(function (response) { return response; }));
    };
    VacancyService.prototype.getNotFoundApplicant = function (filter) {
        return this.http.post(this.globalService.apiVersionHost + API_GET_NOT_FOUND_APPLICANT, { filter: filter }, this.header()).pipe(map(function (response) { return response; }), catchError(function (err) {
            return throwError(err);
        }));
    };
    VacancyService.prototype.getApplicantDetail = function (userid) {
        return this.http.post(this.globalService.apiVersionHost + API_GET_APPLICANT_DETAIL, { userid: userid }, this.header()).pipe(map(function (response) { return response; }), catchError(function (err) {
            return throwError(err);
        }));
    };
    VacancyService.prototype.getApplicantDocument = function (username) {
        return this.http.post(this.globalService.apiVersionHost + API_GET_APPLICANT_DOCUMENT, { username: username }, this.header()).pipe(map(function (response) { return response; }), catchError(function (err) {
            return throwError(err);
        }));
    };
    VacancyService.prototype.getStepRecruitment = function (id_apply_vacancy) {
        return this.http.post(this.globalService.apiVersionHost + API_GET_STEP_RECRUITMENT, { id_apply_vacancy: id_apply_vacancy }, this.header()).pipe(map(function (response) { return response; }));
    };
    VacancyService.prototype.getApplication = function (destination) {
        return this.http.post(this.globalService.apiVersionHost + API_GET_APPLICATION, { destination: destination }, this.header()).pipe(map(function (response) { return response; }), catchError(function (err) {
            return throwError(err);
        }));
    };
    VacancyService.prototype.saveRegistration = function (param) {
        return this.http.post(this.globalService.apiVersionHost + API_SAVE_REGISTATION, param, this.header()).pipe(map(function (response) { return response; }));
    };
    VacancyService.prototype.saveApplication = function (param) {
        return this.http.post(this.globalService.apiVersionHost + API_SAVE_APPLICATION, param, this.header()).pipe(map(function (response) { return response; }), catchError(function (err) {
            return throwError(err);
        }));
    };
    VacancyService.prototype.updateApplication = function (param) {
        return this.http.post(this.globalService.apiVersionHost + API_UPDATE_APPLICATION, param, this.header()).pipe(map(function (response) { return response; }), catchError(function (err) {
            return throwError(err);
        }));
    };
    VacancyService.prototype.deleteApplication = function (param) {
        return this.http.post(this.globalService.apiVersionHost + API_DELETE_APPLICATION, param, this.header()).pipe(map(function (response) { return response; }), catchError(function (err) {
            return throwError(err);
        }));
    };
    VacancyService.prototype.getDataCandidate = function (param) {
        return this.http.post(this.globalService.apiVersionHost + API_GET_DATA_CANDIDATE, param, this.header()).pipe(map(function (response) { return response; }));
    };
    VacancyService.prototype.editTechnical = function (param) {
        return this.http.post(this.globalService.apiVersionHost + API_EDIT_TECHNICAL, param, this.header()).pipe(map(function (response) { return response; }), catchError(function (err) {
            return throwError(err);
        }));
    };
    VacancyService.prototype.editNegotiation = function (param) {
        return this.http.post(this.globalService.apiVersionHost + API_EDIT_NEGOTIATION, param, this.header()).pipe(map(function (response) { return response; }), catchError(function (err) {
            return throwError(err);
        }));
    };
    VacancyService.prototype.getUserEr = function () {
        return this.http.get(this.globalService.apiVersionHost + API_GET_USER_ER, this.header()).pipe(map(function (response) { return response; }), catchError(function (err) {
            return throwError(err);
        }));
    };
    VacancyService.prototype.getApplicantName = function () {
        return this.http.get(this.globalService.apiVersionHost + API_GET_APPLICANT_NAME, this.header()).pipe(map(function (response) { return response; }), catchError(function (err) {
            return throwError(err);
        }));
    };
    VacancyService.prototype.saveSkill = function (param) {
        return this.http.post(this.globalService.apiVersionHost + API_SAVE_SKILL, param, this.header()).pipe(map(function (response) { return response; }), catchError(function (err) {
            return throwError(err);
        }));
    };
    VacancyService.prototype.editDetailApplicant = function (param) {
        return this.http.post(this.globalService.apiVersionHost + API_EDIT_DETAIL_APPLICANT, param, this.header()).pipe(map(function (response) { return response; }), catchError(function (err) {
            return throwError(err);
        }));
    };
    VacancyService.prototype.addEr = function (param) {
        return this.http.post(this.globalService.apiVersionHost + API_ER, param, this.header()).pipe(map(function (response) { return response; }), catchError(function (err) {
            return throwError(err);
        }));
    };
    VacancyService.prototype.templateSkill = function (param) {
        return this.http.post(this.globalService.apiVersionHost + API_TEMPLATE_SKILL, param, this.header()).pipe(map(function (response) { return response; }), catchError(function (err) {
            return throwError(err);
        }));
    };
    VacancyService.prototype.updateSkill = function (param) {
        return this.http.post(this.globalService.apiVersionHost + API_UPDATE_SKILL, param, this.header()).pipe(map(function (response) { return response; }), catchError(function (err) {
            return throwError(err);
        }));
    };
    VacancyService.prototype.submitSkill = function (param) {
        return this.http.post(this.globalService.apiVersionHost + API_SUBMIT_SKILL, param, this.header()).pipe(map(function (response) { return response; }), catchError(function (err) {
            return throwError(err);
        }));
    };
    VacancyService.prototype.submitSkillNotRegister = function (param) {
        return this.http.post(this.globalService.apiVersionHost + API_SUBMIT_SKILL_NOT_REGISTER, param, this.header()).pipe(map(function (response) { return response; }), catchError(function (err) {
            return throwError(err);
        }));
    };
    VacancyService.prototype.finishTechnical = function (param) {
        return this.http.post(this.globalService.apiVersionHost + API_FINISH_TECHNICAL, param, this.header()).pipe(map(function (response) { return response; }), catchError(function (err) {
            return throwError(err);
        }));
    };
    VacancyService.prototype.finishNegotiation = function (param) {
        return this.http.post(this.globalService.apiVersionHost + API_FINISH_NEGOTIATION, param, this.header()).pipe(map(function (response) { return response; }), catchError(function (err) {
            return throwError(err);
        }));
    };
    VacancyService.prototype.cancelProcess = function (param) {
        return this.http.post(this.globalService.apiVersionHost + API_CANCEL_PROCESS, param, this.header2()).pipe(map(function (response) { return response; }), catchError(function (err) {
            return throwError(err);
        }));
    };
    VacancyService.prototype.getApplicantInfo = function (userid) {
        return this.http.post(this.globalService.apiVersionHost + API_APPLICANT_INFO, { userid: userid }, this.header()).pipe(map(function (response) { return response; }));
    };
    VacancyService.prototype.addOtherVacancy = function (param) {
        return this.http.post(this.globalService.apiVersionHost + API_ADD_OTHER_VACANCY, param, this.header()).pipe(map(function (response) { return response; }));
    };
    VacancyService.prototype.deleteApplyVacancy = function (param) {
        return this.http.post(this.globalService.apiVersionHost + API_DELETE_APPLY_VACANCY, param, this.header()).pipe(map(function (response) { return response; }));
    };
    VacancyService.prototype.submitDeclaration = function (userid) {
        return this.http.post(this.globalService.apiVersionHost + API_SUBMIT_DECLARATION, { userid: userid }, this.header()).pipe(map(function (response) { return response; }), catchError(function (err) {
            return throwError(err);
        }));
    };
    VacancyService.prototype.getAuthorized = function () {
        return this.http.get(this.globalService.apiVersionHost + API_AUTHORIZED, this.header()).pipe(map(function (response) { return response; }));
    };
    VacancyService.prototype.getInterviewData = function (param) {
        return this.http.post(this.globalService.apiVersionHost + API_GET_DATA_INTERVIEW, param, this.header()).pipe(map(function (response) { return response; }));
    };
    VacancyService.prototype.getNegotiationData = function (param) {
        return this.http.post(this.globalService.apiVersionHost + API_GET_DATA_NEGOTIATION, param, this.header()).pipe(map(function (response) { return response; }));
    };
    VacancyService.prototype.submitInterview = function (param) {
        return this.http.post(this.globalService.apiVersionHost + API_SUBMIT_TECHNICAL_INTERVIEW, param, this.header()).pipe(map(function (response) { return response; }));
    };
    VacancyService.prototype.submitNegotation = function (param) {
        return this.http.post(this.globalService.apiVersionHost + API_SUBMIT_NEGOTIATION, param, this.header()).pipe(map(function (response) { return response; }));
    };
    VacancyService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function VacancyService_Factory() { return new VacancyService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.CookieService), i0.ɵɵinject(i3.GlobalService), i0.ɵɵinject(i4.NgbModal)); }, token: VacancyService, providedIn: "root" });
    return VacancyService;
}());
export { VacancyService };
