/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./image-uploader.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./image-uploader.component";
import * as i4 from "../../../_services/upload.service";
import * as i5 from "@ng-bootstrap/ng-bootstrap";
import * as i6 from "ng2-img-max/dist/src/ng2-img-max.service";
var styles_ImageUploaderComponent = [i0.styles];
var RenderType_ImageUploaderComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ImageUploaderComponent, data: {} });
export { RenderType_ImageUploaderComponent as RenderType_ImageUploaderComponent };
function View_ImageUploaderComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "input", [["class", "form-control"], ["placeholder", "Ctrl+V"], ["style", "min-width: 70px;"], ["type", "text"]], null, [[null, "paste"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("paste" === en)) {
        var pd_0 = (_co.onPaste($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "button", [["class", "btn btn-primary"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.selectFile() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 0, "span", [["class", "fa fa-upload"]], null, null, null, null, null))], null, null); }
function View_ImageUploaderComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "span", [["class", "fa fa-check"]], null, null, null, null, null))], null, null); }
export function View_ImageUploaderComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(671088640, 1, { loader: 0 }), i1.ɵqud(671088640, 2, { inputFileVariable: 0 }), i1.ɵqud(671088640, 3, { content: 0 }), (_l()(), i1.ɵeld(3, 0, null, null, 7, "div", [["class", "wrapper"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ImageUploaderComponent_1)), i1.ɵdid(5, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ImageUploaderComponent_2)), i1.ɵdid(7, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(8, 0, [[2, 0], ["inputFile", 1]], null, 0, "input", [["class", "d-none"], ["type", "file"]], [[1, "accept", 0]], [[null, "change"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("change" === en)) {
        var pd_0 = (_co.selectSingleFilePresignUrl($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(9, 0, [[1, 0], ["loader", 1]], null, 1, "div", [["class", "loader"]], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 0, "img", [["src", "/assets/media/loader/timeline.svg"]], [[4, "height", "px"]], null, null, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.isFinish; _ck(_v, 5, 0, currVal_0); var currVal_1 = _co.isFinish; _ck(_v, 7, 0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.acceptFile; _ck(_v, 8, 0, currVal_2); var currVal_3 = 37; _ck(_v, 10, 0, currVal_3); }); }
export function View_ImageUploaderComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-image-uploader", [], null, null, null, View_ImageUploaderComponent_0, RenderType_ImageUploaderComponent)), i1.ɵdid(1, 114688, null, 0, i3.ImageUploaderComponent, [i4.UploadService, i5.NgbModal, i6.Ng2ImgMaxService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ImageUploaderComponentNgFactory = i1.ɵccf("app-image-uploader", i3.ImageUploaderComponent, View_ImageUploaderComponent_Host_0, { singleUpload: "singleUpload", prefix: "prefix", acceptFile: "acceptFile", remark: "remark", fileChunkSize: "fileChunkSize", cropper: "cropper", fileType: "fileType", module: "module", tableTarget: "tableTarget", columnTarget: "columnTarget", boxUpload: "boxUpload", maxLengthFilename: "maxLengthFilename", filesizeMaximum: "filesizeMaximum", filepixelMaximum: "filepixelMaximum", isPublicFile: "isPublicFile" }, { success: "success", afterCrop: "afterCrop", change: "change" }, []);
export { ImageUploaderComponentNgFactory as ImageUploaderComponentNgFactory };
