<div id="nds_contents">
  <div class="bg-white navbar-spacer"></div>
  <div class="pagecomponentwrap text-center text-white " id="pageheader">
    <div class="container pb-5 pt-75">
      <div class="no-gutters row justify-content-center align-items-center aos-init aos-animate" data-aos="">
        <div class="col-md-10 aos-init aos-animate" data-aos="flip-up">
          <h2 class="stack_font2 h1 ">
            {{category_data ? category_data.category : 'Please Wait..'}}
          </h2>
          <nav class="stack_font3 text-white small pb-3 " aria-label="breadcrumb">
            <ol class="breadcrumb-page text-capitalize">
              <li class="breadcrumb-item-page">
                <a class="stack_font3 text-white small pb-3" [routerLink]="['/home']" routerLinkActive="router-link-active" >
                  Home
                </a>
              </li>
              <li class="breadcrumb-item-page">
                /
              </li>
              <li class="breadcrumb-item-page">
                <a class="stack_font3 text-white small pb-3" [routerLink]="['/blog']" routerLinkActive="router-link-active" >
                  Blog
                </a>
              </li>
              <li class="breadcrumb-item-page">
                /
              </li>
              <li class="breadcrumb-item-page active">
                <a href="javascript:" (click)="reloadPage(category_data.slug)" class="stack_font3 text-white small pb-3">
                  {{category_data ? category_data.category : ''}}
                </a>
              </li>
            </ol>
          </nav>
        </div>
      </div>
    </div>
  </div>
  <div class="bg-white pt-1">
    <div class="container">
      <div class="w-75 float-left">
        <div class="template-page-main">
          <div class="pagecomponentwrap text-left ">
            <div class=" pb-3 ">
              <div class="elementborder">
                <ng-container *ngIf="blogs else skeleton">
                  <div class="row">
                    <ng-container *ngFor="let blog of blogs; index as ind">
                      <div class="elementitemwrap col-12 col-sm-6 col-md-6 mb-4 aos-init aos-animate" data-aos="fade-up">
                        <div class="image pb-3">
                          <a [routerLink]="['/blog-post', blog.slug]">
                            <img [src]="blog.picture" onerror="src='/assets/media/error/noimage.png'" [alt]="blog.title" class="rounded shadow w-100 mb-1 " id="elementimage-25-0">
                          </a>
                        </div>
                        <h4 class="stack_font3 h5 pl-3 pr-3 text-center text-capitalize">
                          <a [routerLink]="['/blog-post', blog.slug]" class="text-color1">
                            {{blog.title}}
                          </a>
                        </h4>
                        <div class="description stack_font1 pb-4 pl-3 pr-3 ">
                          <p class="m-0 p-0">
                            {{blog.content}}
                          </p>
                          <p class="small text-secondary">
                            <i class="fa fa-calendar-alt"></i>
                            {{!blog.date ? 'not set' : blog.date | date : "d MMM y"}}
                            {{!blog.date ? '' : blog.date | date : "HH"}}
                            {{!blog.date ? '' : ':'}}
                            {{!blog.date ? '' : blog.date | date : "mm"}}
                            -
                            <i class="fa fa-user-tie"></i>
                            {{blog.nama}}
                          </p>
                        </div>
                      </div>
                    </ng-container>
                  </div>
                  <div class="row">
                    <div class="col-12" [class.d-none]="hideLoader">
                      <div style="text-align: center; margin: 0 0 20px 0">
                        <div class="text-center">
                          <img src="/assets/media/loader/timeline.svg">
                        </div>
                      </div>
                    </div>
                    <div class="col-6">
                      <div style="text-align: left; margin: 0 0 20px 0">
                        <a class="btn btn-light" href="javascript:" (click)="previousPage()" [class.d-none]="!hideLoader || page === 1">
                          &larr; Halaman Sebelumnya
                        </a>
                      </div>
                    </div>
                    <div class="col-6 text right">
                      <div style="text-align: right; margin: 0 0 20px 0">
                        <a class="btn btn-light" href="javascript:" (click)="nextPage()" [class.d-none]="!hideLoader || lastData">
                          Halaman Selanjutnya &rarr;
                        </a>
                      </div>
                    </div>
                  </div>
                </ng-container>
              </div>
            </div>
          </div>
        </div>
      </div>
      <app-sidebar [categories]="categories" [populars]="populars" (reloadCategory)="reloadPage($event)"></app-sidebar>
      <div class="clear"></div>
    </div>
  </div>
  <div class="clear"></div>
</div>

<ng-template #skeleton>

  <div class="row">
    <div class="col-6">
      <div class="skeleton-loader"></div>
    </div>

    <div class="col-6">
      <div class="skeleton-loader"></div>
    </div>
  </div>

  <div class="row">
    <div class="col-6">
      <div class="skeleton-loader"></div>
    </div>

    <div class="col-6">
      <div class="skeleton-loader"></div>
    </div>
  </div>

</ng-template>