/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./alert-common.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../node_modules/@ng-bootstrap/ng-bootstrap/ng-bootstrap.ngfactory";
import * as i3 from "@ng-bootstrap/ng-bootstrap";
import * as i4 from "@angular/common";
import * as i5 from "./alert-common.component";
var styles_AlertCommonComponent = [i0.styles];
var RenderType_AlertCommonComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AlertCommonComponent, data: {} });
export { RenderType_AlertCommonComponent as RenderType_AlertCommonComponent };
function View_AlertCommonComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "ngb-alert", [["class", "alert"], ["role", "alert"], ["type", "danger"]], [[2, "alert-dismissible", null]], null, null, i2.View_NgbAlert_0, i2.RenderType_NgbAlert)), i1.ɵdid(1, 638976, null, 0, i3.NgbAlert, [i3.NgbAlertConfig, i1.Renderer2, i1.ElementRef], { dismissible: [0, "dismissible"], type: [1, "type"] }, null), (_l()(), i1.ɵeld(2, 0, null, 0, 1, "strong", [], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, ["", ""]))], function (_ck, _v) { var currVal_1 = false; var currVal_2 = "danger"; _ck(_v, 1, 0, currVal_1, currVal_2); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).dismissible; _ck(_v, 0, 0, currVal_0); var currVal_3 = _v.context.$implicit; _ck(_v, 3, 0, currVal_3); }); }
function View_AlertCommonComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "div", [["class", "card mb-5"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AlertCommonComponent_2)), i1.ɵdid(3, 278528, null, 0, i4.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.errors; _ck(_v, 3, 0, currVal_0); }, null); }
function View_AlertCommonComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "ngb-alert", [["class", "alert"], ["role", "alert"], ["type", "danger"]], [[2, "alert-dismissible", null]], null, null, i2.View_NgbAlert_0, i2.RenderType_NgbAlert)), i1.ɵdid(1, 638976, null, 0, i3.NgbAlert, [i3.NgbAlertConfig, i1.Renderer2, i1.ElementRef], { dismissible: [0, "dismissible"], type: [1, "type"] }, null), (_l()(), i1.ɵeld(2, 0, null, 0, 1, "strong", [], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, ["", ""]))], function (_ck, _v) { var currVal_1 = false; var currVal_2 = "danger"; _ck(_v, 1, 0, currVal_1, currVal_2); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).dismissible; _ck(_v, 0, 0, currVal_0); var currVal_3 = _v.context.$implicit; _ck(_v, 3, 0, currVal_3); }); }
function View_AlertCommonComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AlertCommonComponent_4)), i1.ɵdid(2, 278528, null, 0, i4.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.error; _ck(_v, 2, 0, currVal_0); }, null); }
function View_AlertCommonComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "ngb-alert", [["class", "alert"], ["role", "alert"], ["type", "success"]], [[2, "alert-dismissible", null]], null, null, i2.View_NgbAlert_0, i2.RenderType_NgbAlert)), i1.ɵdid(1, 638976, null, 0, i3.NgbAlert, [i3.NgbAlertConfig, i1.Renderer2, i1.ElementRef], { dismissible: [0, "dismissible"], type: [1, "type"] }, null), (_l()(), i1.ɵeld(2, 0, null, 0, 1, "strong", [], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, ["", ""]))], function (_ck, _v) { var currVal_1 = false; var currVal_2 = "success"; _ck(_v, 1, 0, currVal_1, currVal_2); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).dismissible; _ck(_v, 0, 0, currVal_0); var currVal_3 = _v.context.$implicit; _ck(_v, 3, 0, currVal_3); }); }
function View_AlertCommonComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AlertCommonComponent_6)), i1.ɵdid(2, 278528, null, 0, i4.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.success; _ck(_v, 2, 0, currVal_0); }, null); }
function View_AlertCommonComponent_8(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "ngb-alert", [["class", "alert"], ["role", "alert"], ["type", "warning"]], [[2, "alert-dismissible", null]], [[null, "close"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("close" === en)) {
        var pd_0 = ((_co.warning = []) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_NgbAlert_0, i2.RenderType_NgbAlert)), i1.ɵdid(1, 638976, null, 0, i3.NgbAlert, [i3.NgbAlertConfig, i1.Renderer2, i1.ElementRef], { dismissible: [0, "dismissible"], type: [1, "type"] }, { close: "close" }), (_l()(), i1.ɵeld(2, 0, null, 0, 1, "strong", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Perhatian!"])), (_l()(), i1.ɵted(4, 0, ["\u00A0", " "]))], function (_ck, _v) { var currVal_1 = true; var currVal_2 = "warning"; _ck(_v, 1, 0, currVal_1, currVal_2); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).dismissible; _ck(_v, 0, 0, currVal_0); var currVal_3 = _v.context.$implicit; _ck(_v, 4, 0, currVal_3); }); }
function View_AlertCommonComponent_7(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AlertCommonComponent_8)), i1.ɵdid(2, 278528, null, 0, i4.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.warning; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_AlertCommonComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_AlertCommonComponent_1)), i1.ɵdid(1, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AlertCommonComponent_3)), i1.ɵdid(3, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AlertCommonComponent_5)), i1.ɵdid(5, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AlertCommonComponent_7)), i1.ɵdid(7, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.errors; _ck(_v, 1, 0, currVal_0); var currVal_1 = _co.error; _ck(_v, 3, 0, currVal_1); var currVal_2 = _co.success; _ck(_v, 5, 0, currVal_2); var currVal_3 = _co.warning; _ck(_v, 7, 0, currVal_3); }, null); }
export function View_AlertCommonComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-alert-common", [], null, null, null, View_AlertCommonComponent_0, RenderType_AlertCommonComponent)), i1.ɵdid(1, 114688, null, 0, i5.AlertCommonComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AlertCommonComponentNgFactory = i1.ɵccf("app-alert-common", i5.AlertCommonComponent, View_AlertCommonComponent_Host_0, { errors: "errors", error: "error", success: "success", warning: "warning" }, {}, []);
export { AlertCommonComponentNgFactory as AlertCommonComponentNgFactory };
