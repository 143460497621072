<div id="content"  class="bg-white">
  <div class="row align-items-center no-gutters justify-content-end">
      <div class="col-lg-5 col-xl-4">
          <div class="pb-5 mb-5 pt-5 px-4 px-md-5 ml-0 ml-xl-5">
              <h1 class="display-3 text-primary mb-0 pb-0">PT Nexwave</h1>
              <div class="text-dark lead pt-3 pt-md-4 pb-4 pb-md-5 line-height-180">Kami Terus Tumbuh Dan Berkembang, Membuat Kami Tetap Dipercaya Sebagai Mitra Kerja Dengan Berbagai Vendor Telekomunikasi Seperti Nokia, Huawei Dan Erricsson Untuk Proyek Di Indonesia
              </div>
              <div>
                  <a [routerLink]="['/about']" class="btn btn-lg btn-outline-primary rounded-pill">Read More</a>
              </div>
          </div>
      </div>
      <div class="col-lg-7 col-xl-8">
          <div class="pl-0 pl-lg-5">
              <img src="./assets/media/5g/home1.jpg" class="w-100" alt="" />
          </div>
      </div>
  </div>
  <div class="container-fluid">
      <div class=" px-4 px-lg-4 pt-5 pb-5 mr-lg-5 mr-0">
          <div class="row justify-content-center align-items-center">
              <div class="col-lg-6 mb-5 mb-lg-0 d-none d-lg-block">
                  <div class="px-0 px-md-5 pt-0 pt-md-5 pb-5">
                      <img src="./assets/media/5g/home2.jpg" alt="" class="w-100">
                  </div>
              </div>
              <div class="col-lg-6 mb-5 mb-lg-0">
                  <div class="display-3 text-primary mb-4">Easy &amp; Complete Solution</div>
                  <div class="lead line-height-180">
                      <p>PT NexWave adalah salah satu subkontraktor telekomunikasi terbesar di Indonesia. PT NexWave Indonesia hadir sejak 2004, dan hingga kini PT NexWave sudah bekerja sama dengan operator telekomunikasi di Indonesia, Singapore, Philipines, Malaysia</p>
                  </div>
                  <div class="py-3">
                      <div class="row">
                          <div class="col-xl-3 col-lg-6 col-md-3 col-6 mb-3">
                              <div class="d-flex border p-3 rounded-lg">
                                  <div class="mr-3">
                                      <img src="./assets/media/5g/svg/employee.svg" width="40">
                                  </div>
                                  <div>
                                      <div class="h3 text-info mb-0">3000+</div>
                                      <div>Employee</div>
                                  </div>
                              </div>
                          </div>
                          <div class="col-xl-3 col-lg-6 col-md-3 col-6 mb-3">
                              <div class="d-flex border p-3 rounded-lg">
                                  <div class="mr-3">
                                      <img src="./assets/media/5g/svg/branch.svg" width="40">
                                  </div>
                                  <div>
                                      <div class="h3 text-info mb-0">11+</div>
                                      <div>Branch</div>
                                  </div>
                              </div>
                          </div>
                          <div class="col-xl-3 col-lg-6 col-md-3 col-6 mb-3">
                              <div class="d-flex border p-3 rounded-lg">
                                  <div class="mr-3">
                                      <img src="./assets/media/5g/svg/project.svg" width="40">
                                  </div>
                                  <div>
                                      <div class="h3 text-info mb-0">100+</div>
                                      <div>Projects</div>
                                  </div>
                              </div>
                          </div>
                          <div class="col-xl-3 col-lg-6 col-md-3 col-6 mb-3">
                              <div class="d-flex border p-3 rounded-lg">
                                  <div class="mr-3">
                                      <img src="./assets/media/5g/svg/customer.svg" width="40">
                                  </div>
                                  <div>
                                      <div class="h3 text-info mb-0">20+</div>
                                      <div>Customer</div>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </div>
  </div>
  <div class="row align-items-center no-gutters justify-content-end">
      <div class="col-lg-5 col-xl-5">
          <div class="pb-5 mb-5 pt-5 px-4 px-md-5 ml-0 ml-xl-5">
              <h1 class="display-3 text-primary mb-0 pb-0">Join with Us!</h1>
              <div class="text-dark lead pt-3 pt-md-4 pb-4 pb-md-5 line-height-180">Lebih dari 3000 profesional telah bergabung dengan PT Nexwave Indonesia. Sekarang saatnya mengoptimalkan potensi keahlian Anda. Silahkan kunjungi halaman karir kami di Vacancy.Ptnw.Net
              </div>
              <div>
                  <a [routerLink]="['/karir']" class="btn btn-lg btn-outline-primary rounded-pill">Send Application</a>
              </div>
          </div>
      </div>
      <div class="col-lg-7 col-xl-7">
          <div class="pl-0 pl-lg-5">
              <img src="./assets/media/5g/home3.jpg" class="w-100" alt="" />
          </div>
      </div>
  </div>
</div>