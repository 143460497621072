import { Subject } from 'rxjs';
import * as i0 from "@angular/core";
var LayoutService = /** @class */ (function () {
    function LayoutService() {
        this.mobileMenuToggleEmit = new Subject();
        this.mobileMenuToggleEmitObs = this.mobileMenuToggleEmit.asObservable();
        this.mobileHeaderMenuToggleEmit = new Subject();
        this.mobileHeaderMenuToggleEmitObs = this.mobileHeaderMenuToggleEmit.asObservable();
        this.topBarToggleEmit = new Subject();
        this.topBarToggleEmitObs = this.topBarToggleEmit.asObservable();
        this.menuModuleEmit = new Subject();
        this.menuModuleEmitObs = this.menuModuleEmit.asObservable();
        this.setModuleFullScreen = new Subject();
        this.setModuleFullScreenObs = this.setModuleFullScreen.asObservable();
        this.mobileMenuToggleEmit.next(false);
        this.mobileHeaderMenuToggleEmit.next(false);
        this.topBarToggleEmit.next(false);
        this.menuModuleEmit.next(false);
        this.setModuleFullScreen.next(false);
    }
    LayoutService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function LayoutService_Factory() { return new LayoutService(); }, token: LayoutService, providedIn: "root" });
    return LayoutService;
}());
export { LayoutService };
