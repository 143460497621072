import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from 'src/app/layout/shared/shared.module';
import { AuthorizationComponent } from './authorization.component';
import { FormsModule } from '@angular/forms';

@NgModule({
  declarations: [
    AuthorizationComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    SharedModule
  ]
})
export class AuthorizationModule { }
