<div *ngIf="permissionValid" class="kt-content  kt-grid__item kt-grid__item--fluid" id="kt_content">
  <div class="row" style="margin: -25px; background-color: white;">
    <div class="col-lg-12">
      <div class="" style="background-color: white; position: fixed;">
        <form>
          <table class="table">
            <thead>
              <tr>
                <th>Column Name</th>
                <th>Column Operator</th>
                <th>Column Value</th>
                <th>Module</th>
                <th>Controller</th>
                <th>Action</th>
                <th>Priority</th>
                <th>Save</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <input name="authorizationData_column_name" [(ngModel)]="authorizationData.column_name" type="text"
                    class="form-control">
                </td>
                <td>
                  <select name="authorizationData_column_operator" [(ngModel)]="authorizationData.column_operator"
                    class="form-control">
                    <ng-container *ngFor="let symbol of symbols">
                      <option value="{{symbol}}">{{symbol}}</option>
                    </ng-container>
                  </select>
                </td>
                <td>
                  <input name="authorizationData_column_value" [(ngModel)]="authorizationData.column_value" type="text"
                    class="form-control">
                </td>
                <td>
                  <input name="authorizationData_module" [(ngModel)]="authorizationData.module" type="text"
                    class="form-control">
                </td>
                <td>
                  <input name="authorizationData_controller" [(ngModel)]="authorizationData.controller" type="text"
                    class="form-control">
                </td>
                <td>
                  <input name="authorizationData_action" [(ngModel)]="authorizationData.action" type="text"
                    class="form-control">
                </td>
                <td>
                  <input name="authorizationData_priority" [(ngModel)]="authorizationData.priority" type="text"
                    class="form-control">
                </td>
                <td>
                  <button type="button" class="btn btn-primary btn-icon btn-sm" (click)="add()">
                    <i class="fa fa-save"></i>
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </form>
      </div>
      <div class="" style="margin-top: 135px;">
        <app-table-advance [tableAdvanceConfig]="tableConfig" (pageSizeEvent)="getCurrentPageSize($event)"
          (pageEvent)="getCurrentPage($event)" (filterInput)="getFilterInput($event)">
        </app-table-advance>
      </div>
    </div>
  </div>
</div>
