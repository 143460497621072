import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { LandingpageService } from 'src/app/_services/landingpage.service';

@Component({
  selector: 'app-post',
  templateUrl: './post.component.html',
  styleUrls: ['./post.component.scss']
})
export class PostComponent implements OnInit {

  slug = this.actRoute.snapshot.params['slug'];

  blog: any;
  categories: any;
  populars: any;

  constructor(
    private actRoute: ActivatedRoute,
    private landing: LandingpageService
  ) { }

  ngOnInit() {
    this.loadData();
  }

  loadData() {
    this.landing.getBlogDetail({slug: this.slug})
      .subscribe(arg => {
        if (arg.status) {
          this.blog = arg.result.row.blog;
          this.categories = arg.result.row.category;
          this.populars = arg.result.row.popular;
        } else {
          this.landing.alertModal(arg.message);
        }
      }, error => {
        this.landing.alertModal(error.error.message, error.error.status_code);
      });
  }

  reloadPage($event) {
    this.slug = $event;
    this.blog = undefined;
    this.loadData();
  }

}
