<div class="kt-content kt-grid__item kt-grid__item--fluid" id="kt_content">
  <div class="row justify-content-center pt-75 pb-5 ">
    <div class="col-lg-11">
      <div class="row justify-content-center search-icons">
        <ngx-ui-loader [fgsType]="'three-bounce'" [hasProgressBar]="false" [text]="'Please Wait...'" [loaderId]="'menu-loader'"></ngx-ui-loader>
        <ng-container *ngFor="let menu of menuList">
          <div class="col-4 col-md-3 col-lg-2 text-center mb-5 pb-4">
            <ng-container *ngIf="cekLink(menu.module_key) ">
              <a href="{{menu.url}}" class="icon-item rounded rounded-lg mx-auto d-block bg-white shadow mb-3">
                <ng-container *ngIf="menu.serial > 1 && menu.count > 0">
                  <span class="badge-counter">
                    <!-- <span class="badge" [ngClass]="{'badge-danger': menu.count > 0, 'badge-warning': menu.count == 0}">{{menu.count}}</span> -->
                    <span class="badge badge-danger">{{menu.count}}</span>
                  </span>
                </ng-container>
                <svg [innerHtml]="menu.icon_svg | safeHtml" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
                  width="24px" height="24px" viewBox="0 0 24 24" version="1.1" class="kt-svg-icon">
                </svg>
              </a>
              <a href="{{menu.url}}" class=" text-white">
                {{menu.menu_label}}
              </a>
            </ng-container>
            <ng-container *ngIf="!cekLink(menu.module_key) ">
              <a [routerLink]="[menu.url]" class="icon-item rounded rounded-lg mx-auto d-block bg-white shadow mb-3">
                <ng-container *ngIf="menu.serial > 1 && menu.count > 0">
                  <span class="badge-counter">
                    <!-- <span class="badge" [ngClass]="{'badge-danger': menu.count > 0, 'badge-warning': menu.count == 0}">{{menu.count}}</span> -->
                    <span class="badge badge-danger">{{menu.count}}</span>
                  </span>
                </ng-container>
                <svg [innerHtml]="menu.icon_svg | safeHtml" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
                  width="24px" height="24px" viewBox="0 0 24 24" version="1.1" class="kt-svg-icon">
                </svg>
              </a>
              <a [routerLink]="[menu.url]" class=" text-white">
                {{menu.menu_label}}
              </a>
            </ng-container>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
</div>
