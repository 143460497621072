import { Component, OnInit } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import { ModuleApprovalService } from 'src/app/_services/module-approval.service';
import { UserService } from 'src/app/_services/user.service';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';
import { NgxUiLoaderService } from 'ngx-ui-loader';

@Component({
  selector: 'app-module-approval',
  templateUrl: './module-approval.component.html',
  styleUrls: ['./module-approval.component.scss']
})
export class ModuleApprovalComponent implements OnInit {

  currentUser: any = {};
  menuList: any[] = [];
  linkBukanBeta: any = [];

  constructor(
    private userService: UserService,
    private moduleApprovalService: ModuleApprovalService,
    updates: SwUpdate,
    public loader: NgxUiLoaderService,

  ) {
    let payload = this.userService.payloadToken();
    this.currentUser = payload.data;

    // if (environment.production) {
    //   updates.activateUpdate().then(() => {
    //     document.location.reload();
    //   });
    // }
  }

  ngOnInit() {
    this.loadData();
  }

  cekLink(key) {
    if (this.linkBukanBeta.includes(key)) {
      return true;
    }
    return false;
  }

  loadData() {
    this.loader.startLoader('menu-loader');
    this.moduleApprovalService.getList().subscribe(
      response => {
        this.loader.stopLoader('menu-loader');
        if (response.status) {
          this.menuList = response.result.menu;
          this.linkBukanBeta = response.result.link_http;
        } else {
          Swal.fire({
            title: 'My Approval',
            type: 'error',
            text: 'Failed to get data.'
          });
        }
      },
      error => {
        this.loader.stopLoader('menu-loader');
        Swal.fire({
          title: 'All Menu',
          type: 'error',
          text: 'terjadi kesalahan : '+error
        });
      }
    );
  }

}
