<p *ngIf="remark"><span class="kt-link kt-link--state kt-link--primary">{{remark}}</span></p>
<ng-container *ngIf="boxUpload">
  <a href="javascript:" (click)="openWindows()" class="btn btn-outline-secondary">
    <span class="fa fa-file-upload fa-3x"></span>
    <span class="d-block small pt-1">
      Upload Baru
    </span>
  </a>
</ng-container>
<div class="form-group mb-10" [class.d-none]="boxUpload">
  <div class="custom-file">
    <input #inputFile *ngIf="acceptFile" type="file" class="custom-file-input" (change)="cropper ? fileChangeEvent($event) : (singleUpload) ? selectSingleFilePresignUrl($event) : selectFilePresignUrlPart($event)" [disabled]="selectedFiles && !isFinish" [attr.accept]="acceptFile" [id]="id">
    <input #inputFile *ngIf="!acceptFile" type="file" class="custom-file-input" (change)="cropper ? fileChangeEvent($event) : (singleUpload) ? selectSingleFilePresignUrl($event) : selectFilePresignUrlPart($event)" [disabled]="selectedFiles && !isFinish" [id]="id">
    <label class="custom-file-label text-ellipsis" for="customFile">{{labelInput}}</label>
  </div>
</div>
<p *ngIf="errorMime"><span class="kt-link kt-link--state kt-link--danger">Invalid file type!</span></p>
<p *ngIf="uploadSuccess"><span class="kt-link kt-link--state kt-link--primary">file uploaded successfully.</span></p>
<ng-container *ngIf="selectedFiles && !isFinish">
  <ngb-progressbar type="primary" [value]="progress" [striped]="true" [animated]="true"><i>{{(isFinish)? 'Upload finish!':progress+'%' }}</i></ngb-progressbar>
</ng-container>

<ng-template #content let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Profile update</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <image-cropper [imageChangedEvent]="imageChangedEvent" [maintainAspectRatio]="true" [aspectRatio]="1/1"
      [resizeToWidth]="640" format="jpg" (imageCropped)="imageCropped($event)" (imageLoaded)="imageLoaded()"
      (cropperReady)="cropperReady()" (loadImageFailed)="loadImageFailed()"></image-cropper>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-dark" (click)="modal.close('crop')"> Crop</button>
  </div>
</ng-template>