import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ApprovalService } from 'src/app/_services/approval.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-approval',
  templateUrl: './approval.component.html',
  styleUrls: ['./approval.component.scss']
})
export class ApprovalComponent implements OnInit {

  token: string;
  username: string;
  password: string;

  @ViewChild("modalLogin", { static: false }) modalLogin;

  constructor(
    private route: ActivatedRoute,
    private approvalService: ApprovalService,
    public modalService: NgbModal,
  ) { 
    this.token = this.route.snapshot.paramMap.get('token');
    console.log(this.token);
  }

  ngOnInit() {
    if (this.token) {
      this.submitByToken();
    } else {
      Swal.fire('Direct Approval', 'Halaman ini dikhususkan untuk melakukan approval melelui link pada module tertentu. Link tersebut dibuat oleh tim admin pada sistem ptnw.net. Apabila ada kebutuhan terkait hal tersebut, silakan menghubungi tim NDS Care melalui email dev.nexwave@gmail.com.', 'info');
    }
  }

  submitByToken() {
    this.approvalService.submitByLink({token: this.token}).subscribe(
      response => {
        if (response.status) {
          Swal.fire('Direct Approval', 'Approval berhasil dilakukan.', 'success');
        } else {
          if (response.message == 'token_expired') {
            Swal.fire('Direct Approval', 'Token telah kadarluasa, approval tetap dapat dilakukan dengan memasukkan username dan password Anda.', 'info');
            this.showLoginForm();
          } else {
            Swal.fire('Direct Approval', response.message, 'warning');
          }
        }
      }, error => {
        Swal.fire('Direct Approval', error.error.message, 'error');
      }
    );
  }

  showLoginForm() {
    this.modalService.open(this.modalLogin, { ariaLabelledBy: "modal-basic-title", scrollable: true, size: "sm", centered: true });
  }

  submitByLogin() {
    this.modalService.dismissAll();
    let params = {
      token: this.token,
      username: this.username,
      password: this.password
    };
    this.approvalService.submitByLogin(params).subscribe(
      response => {
        if (response.status) {
          Swal.fire('Direct Approval', 'Approval berhasil dilakukan.', 'success');
        } else {
          Swal.fire('Direct Approval', response.message, 'warning');
        }
      }, error => {
        Swal.fire('Direct Approval', error.error.message, 'error');
      }
    );
  }

}
