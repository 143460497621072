import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxPermissionsService } from 'ngx-permissions';
import { AuthenticationService } from 'src/app/auth/authentication.service';
import { PaginateModel } from 'src/app/_models/paginate.model';
import { LandingpageService } from 'src/app/_services/landingpage.service';
import { UserService } from 'src/app/_services/user.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-career5g',
  templateUrl: './career5g.component.html',
  styleUrls: ['./career5g.component.scss']
})
export class Career5gComponent implements OnInit {

  isLoading = false;
  showError = false;
  loginError = '';
  route: string;
  dataSource: any[] = [];
  host: string;
  pageSizeOption: number[] = [6, 12, 18, 24, 30];
  paging = new PaginateModel({ pageSize: 6 });
  filter: any = {
    title: null
  };
  closeResult: string;

  selectedVacancy = {};

  public isSubmited = false;
  public login_form: any = { username: '', password: '', id_vacancy_detail: null };
  public returnUrl: string;

  constructor(
    private router: Router,
    private landingService: LandingpageService,
    private modalService: NgbModal,
    private authService: AuthenticationService,
    private permissionsService: NgxPermissionsService,
    private userService: UserService
  ) { }

  ngOnInit() {
    this.reloadData();
  }

  reloadData() {
    this.isLoading = true;
    window.scroll(0, 200);
    const param = { pageSize: this.paging.pageSize, page: this.paging.pageNumber, filter: [this.filter] };
    this.landingService.getAllVacancyLanding(param).subscribe(response => {
      this.dataSource = response.result.row;
      this.paging.total = response.result.total;
      this.host = response.result.host;
      this.paging.totalPage = Math.ceil(this.paging.total / this.paging.pageSize);
      for (let i = 0; i < this.dataSource.length; i++) {
        if (this.dataSource[i]['gambar'] !== '') {
          this.dataSource[i]['gambar'] = this.dataSource[i]['gambar'];
        }
      }
      this.isLoading = false;
    }, error => {
      this.isLoading = false;
      Swal.fire(error.error.message);
    });
  }

  getCurrentPageSize($event) {
    this.paging.pageSize = $event;
    this.reloadData();
  }

  getCurrentPage($event) {
    this.paging.pageNumber = $event;
    this.reloadData();
  }

  open(id_detail, modal: any, vacancy: any) {

    this.login_form.id_vacancy_detail = vacancy.id;
    this.selectedVacancy = vacancy;

    Swal.fire({
      title: 'Sudah punya akun?',
      text: "Jika sudah punya, silakan klik login. Jika belum punya, silakan klik registrasi",
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#30d638',
      cancelButtonColor: '#3067d6',
      confirmButtonText: 'Login',
      cancelButtonText: 'Registrasi',
      showCloseButton: true,
      closeButtonAriaLabel: 'Batal'
    }).then((result) => {
      console.log(result);
      if (result.value) {
        this.openModal(modal)
      } else if (result.dismiss) {
        if (result.dismiss.toString() == 'cancel') {
          this.router.navigate(['/registration/' + id_detail]);
        }
      }
    });
  }

  openModal(content) {
    this.showError = false;
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  submit(loginForm: NgForm, modal: any) {
    this.isSubmited = true;
    this.showError = false;
    if (this.validate(loginForm)) {
      this.authService.applyVacancy(this.login_form).subscribe(response => {
        if (response.status) {
          const group: any[] = this.userService.getUserGroup();
          group.push('USER');
          this.permissionsService.loadPermissions(group);
          modal.close('Success');
          localStorage.setItem('vacancy', response.result._x);
          this.authService.getPermission('vacancy');

          if (response.result.haveApplied){
            Swal.fire('You have applied this job before').then((result) => {
              this.router.navigateByUrl('/main/vacancy/index');
            });
          }
          else {
            this.router.navigateByUrl('/main/vacancy/skill/' + response.result.userid);
          }
          
        }
        else {
          this.loginError = response.message;
          this.showError = true;
          this.isSubmited = false;
        }
      }, (error: any) => {
        Swal.fire(error.message);
        this.loginError = error.error.message;
        this.showError = true;
        this.isSubmited = false;
      });
    }
    else {
      this.isSubmited = false;
    }
  }

  validate(loginForm: NgForm) {
    if (loginForm.form.status === 'VALID') {
      return true;
    } else {
      return false;
    }
  }

}
