import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LandingpageService } from 'src/app/_services/landingpage.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-exam',
  templateUrl: './exam.component.html',
  styleUrls: ['./exam.component.scss']
})
export class ExamComponent implements OnInit {

  constructor(
    private landingService: LandingpageService,
    private router: Router
  ) { }

  ngOnInit() {
  }

  submit(voucher: string) {
    voucher.replace(/\s/g, "");
    if (voucher == '') {
      this.showSwal('Peringatan!', 'Silakan masukkan kode voucher!');
    } else {
      this.landingService.submitVoucher({ voucher: voucher }).subscribe(response => {
        if (response.status) {
          if (response.result.pesan == 'Berhasil') {
            if (response.result.data.type == 'Disc') {
              this.router.navigate(['/disc/' + voucher]);
            } else if (response.result.data.type == 'video') {
              this.router.navigate(['/video-exam/' + voucher]);
            } else {
              this.router.navigate(['/survey/' + voucher]);
            }
          } else {
            this.showSwal('Peringatan!', response.result.pesan);
          }
        } else {
          this.showSwal('Peringatan!', response.message);
        }
      }, err => {
        // console.log(err.error);
        this.showSwal('Terjadi Kesalahan', err.error.message);
      });
    }
  }

  showSwal(title: string, text: string) {
    Swal.fire({
      title: title,
      text: text,
      type: 'warning',
      showCancelButton: false,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'OK',
      allowOutsideClick: false
    }).then((result) => {
      if (result.value) {
        return false;
      }
    });
  }

}
