import { BehaviorSubject, Subject } from 'rxjs';
import * as i0 from "@angular/core";
var RouterService = /** @class */ (function () {
    function RouterService() {
        this.route = new BehaviorSubject('');
        this.currentRoute = this.route.asObservable();
        this.idMaster = new Subject();
    }
    RouterService.prototype.changeRoute = function (route) {
        this.route.next(route);
    };
    RouterService.prototype.changeId = function (id) {
        this.idMaster.next(id);
    };
    RouterService.prototype.getId = function () {
        return this.idMaster.asObservable();
    };
    RouterService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function RouterService_Factory() { return new RouterService(); }, token: RouterService, providedIn: "root" });
    return RouterService;
}());
export { RouterService };
