import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LayoutService {

  public mobileMenuToggleEmit: Subject<boolean> = new Subject<boolean>();
  public mobileMenuToggleEmitObs = this.mobileMenuToggleEmit.asObservable();
  public mobileHeaderMenuToggleEmit: Subject<boolean> = new Subject<boolean>();
  public mobileHeaderMenuToggleEmitObs = this.mobileHeaderMenuToggleEmit.asObservable();
  public topBarToggleEmit: Subject<boolean> = new Subject<boolean>();
  public topBarToggleEmitObs = this.topBarToggleEmit.asObservable();
  public menuModuleEmit: Subject<boolean> = new Subject<boolean>();
  public menuModuleEmitObs = this.menuModuleEmit.asObservable();
  public setModuleFullScreen: Subject<boolean> = new Subject<boolean>();
  public setModuleFullScreenObs = this.setModuleFullScreen.asObservable();

  constructor() {
    this.mobileMenuToggleEmit.next(false);
    this.mobileHeaderMenuToggleEmit.next(false);
    this.topBarToggleEmit.next(false);
    this.menuModuleEmit.next(false);
    this.setModuleFullScreen.next(false);
   }
}
