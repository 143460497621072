<div class="kt-content  kt-grid__item kt-grid__item--fluid" id="kt_content">
  <div class="row">
    <div class="col">
      <div class="alert alert-light alert-elevate fade show" role="alert">
        <div class="alert-icon"><i class="flaticon2-list-3 kt-font-brand"></i></div>
        <div class="alert-text">
          Reload Authorization
        </div>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-md-12">
      <div class="kt-portlet">
        <div class="kt-portlet__head">
          <div class="kt-portlet__head-label">
            <span class="kt-portlet__head-icon">
              <i class="la la-gear"></i>
            </span>
            <h3 class="kt-portlet__head-title">Tracker</h3>
          </div>
          <div class="kt-portlet__head-toolbar">
            <div class="kt-portlet__head-actions">

            </div>
          </div>
        </div>
        <div class="kt-portlet__body">

          <ngx-ui-loader [fgsType]="'three-bounce'" [hasProgressBar]="false" [loaderId]="'view-loader'">
          </ngx-ui-loader>

          <div class="kt-widget4">
            <div class="kt-widget4__item">
              <div class="kt-widget4__pic kt-widget4__pic--pic">
                <svg _ngcontent-fjd-c8="" class="kt-svg-icon" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg"><path _ngcontent-fjd-c8="" d="M139.61 35.5a12 12 0 0 0-17 0L58.93 98.81l-22.7-22.12a12 12 0 0 0-17 0L3.53 92.41a12 12 0 0 0 0 17l47.59 47.4a12.78 12.78 0 0 0 17.61 0l15.59-15.62L156.52 69a12.09 12.09 0 0 0 .09-17zm0 159.19a12 12 0 0 0-17 0l-63.68 63.72-22.7-22.1a12 12 0 0 0-17 0L3.53 252a12 12 0 0 0 0 17L51 316.5a12.77 12.77 0 0 0 17.6 0l15.7-15.69 72.2-72.22a12 12 0 0 0 .09-16.9zM64 368c-26.49 0-48.59 21.5-48.59 48S37.53 464 64 464a48 48 0 0 0 0-96zm432 16H208a16 16 0 0 0-16 16v32a16 16 0 0 0 16 16h288a16 16 0 0 0 16-16v-32a16 16 0 0 0-16-16zm0-320H208a16 16 0 0 0-16 16v32a16 16 0 0 0 16 16h288a16 16 0 0 0 16-16V80a16 16 0 0 0-16-16zm0 160H208a16 16 0 0 0-16 16v32a16 16 0 0 0 16 16h288a16 16 0 0 0 16-16v-32a16 16 0 0 0-16-16z"></path></svg>
              </div>
              <div class="kt-widget4__info">
                <a href="javascript:;" class="kt-widget4__username">
                  All Module
                </a>
                <p class="kt-widget4__text">
                    Update all module authorization
                </p>
              </div>
              <ng-container *ngIf="!loadingAllModuleAuth">
                <a (click)="reloadAll()" class="btn btn-sm btn-label-brand btn-bold"><span
                  class="fa fa-refresh"></span> Reload</a>
              </ng-container>
              <ng-container *ngIf="loadingAllModuleAuth">
                <img src="/assets/media/loader/timeline.svg" [style.height.px]="37">
              </ng-container>
            </div>
            <ng-container *ngFor="let x of moduleToken | keyvalue">
              <div class="kt-widget4__item">
                <div class="kt-widget4__pic kt-widget4__pic--pic"
                  [innerHTML]="(moduleFormat.hasOwnProperty(x.key) ? moduleFormat[x.key].icon : defaultIcon) | safeHtml">

                </div>
                <div class="kt-widget4__info">
                  <a [routerLink]="'/main/' + x.key" class="kt-widget4__username">
                    {{moduleFormat[x.key] ? moduleFormat[x.key].label : x.key}}
                  </a>
                  <p class="kt-widget4__text">
                      {{moduleFormat[x.key] ? moduleFormat[x.key].description : ''}}
                  </p>
                </div>
                <a (click)="reload(x.key)" class="btn btn-sm btn-label-brand btn-bold"><span
                    class="fa fa-refresh"></span> Reload</a>
              </div>
            </ng-container>


          </div>

        </div>
      </div>
    </div>
  </div>

</div>