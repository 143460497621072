import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TableCommonModel } from 'src/app/layout/shared/table-common/table-common.config';
import { PaginateModel } from 'src/app/_models/paginate.model';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { LandingpageService } from 'src/app/_services/landingpage.service';
import { GlobalService } from 'src/app/_services/global.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-simcard',
  templateUrl: './simcard.component.html',
  styleUrls: ['./simcard.component.scss']
})
export class SimcardComponent implements OnInit {

  hash = this.actRoute.snapshot.params['hash'];

  vendor: string;
  date_top_up: any;

  constructor(
    private actRoute: ActivatedRoute,
    private ngxLoader: NgxUiLoaderService,
    private landingService: LandingpageService,
    public globalHost: GlobalService
  ) { }

  tableConfig: TableCommonModel = {
    dataSource: [],
    displayedColumns: ['phone_number', 'username', 'nama', 'nominal', 'utilitation', 'remark'],
    columnOption: [
      {
        column: 'phone_number',
        label: 'Phone Number',
        showFilter: false,
      },
      {
        column: 'username',
        label: 'Username',
        showFilter: false,
      },
      {
        column: 'nama',
        label: 'Nama',
        showFilter: false,
      },
      {
        column: 'nominal',
        label: 'Nominal',
        showFilter: false,
      },
      {
        column: 'utilitation',
        label: 'Utilization',
        showFilter: false,
      },
      {
        column: 'remark',
        label: 'Remark',
        showFilter: false,
      }
    ],
    pageSizeOption: [10, 20, 30, 50, 100],
    paginateOption: new PaginateModel({}),
    filter: {},
    buttonOption: [],
    advanceOption: {
      showActionColumn: false,
      showExpandable: false,
      showFilterColumn: false,
      showPagination: true,
    }
  };

  ngOnInit() {
    this.getPrepaidDetail();
  }

  getPrepaidDetail() {
    this.ngxLoader.startLoader('simcard');
    this.landingService.getPrepaidDetail(this.hash).subscribe((response) => {
      this.ngxLoader.stopLoader('simcard');
      if (response.status) {
        this.vendor = response.result.row.prepaid_master.vendor;
        this.date_top_up = response.result.row.prepaid_master.date_top_up;
        this.tableConfig.dataSource = response.result.row.prepaid_detail;
        this.tableConfig.paginateOption.total = response.result.total;
        // tslint:disable-next-line: max-line-length
        this.tableConfig.paginateOption.totalPage = Math.ceil(this.tableConfig.paginateOption.total / this.tableConfig.paginateOption.pageSize);
        return;
      }
      Swal.fire(response.message);
    }, (error: any) => {
      Swal.fire(error.error.message);
      this.ngxLoader.stopLoader('simcard');
    });
  }

  getCurrentPageSize($event) {
    this.tableConfig.paginateOption.pageNumber = 1;
    this.tableConfig.paginateOption.pageSize = $event;
    this.getPrepaidDetail();
  }
  getCurrentPage($event) {
    this.tableConfig.paginateOption.pageNumber = Math.ceil($event);
    this.getPrepaidDetail();
  }

}
