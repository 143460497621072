import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { OnInit, EventEmitter, ElementRef } from '@angular/core';
import { UploadService } from 'src/app/_services/upload.service';
import Swal from 'sweetalert2';
import { Ng2ImgMaxService } from 'ng2-img-max';
var ImageUploaderComponent = /** @class */ (function () {
    function ImageUploaderComponent(uploadService, modal, maxPixelService) {
        var _this = this;
        this.uploadService = uploadService;
        this.modal = modal;
        this.maxPixelService = maxPixelService;
        this.singleUpload = false;
        this.fileChunkSize = 50000000;
        this.cropper = false;
        this.module = null;
        this.maxLengthFilename = 200;
        this.isPublicFile = true;
        this.labelInput = 'Choose file';
        this.tempEvent = undefined;
        this.uncommonFiletype = {
            jpeg: 'image/jpeg',
            jpg: 'image/jpeg',
            png: 'image/png',
            pdf: 'application/pdf',
            doc: 'application/msword',
            docx: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
            ppt: 'application/vnd.ms-powerpoint',
            pptx: 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
            xls: 'application/vnd.ms-excel',
            xlsx: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            txt: 'text/plain',
            zip: 'application/x-zip-compressed',
            csv: 'text/plain',
        };
        this.success = new EventEmitter();
        this.afterCrop = new EventEmitter();
        this.change = new EventEmitter();
        this.imageChangedEvent = '';
        this.croppedImage = '';
        this.fileExtension = '';
        this.fileChunkSize = (this.fileChunkSize < 5242880) ? 5242880 : this.fileChunkSize;
        this.uploadService.progressEmitObs.subscribe(function (res) {
            if (_this.singleUpload) {
                _this.progress = res;
            }
            else {
                _this.progress = Math.floor((100 * _this.currentPart - 100 + res) / _this.numChunks);
            }
        });
    }
    ImageUploaderComponent.prototype.selectFile = function () {
        this.inputFileVariable.nativeElement.click();
    };
    ImageUploaderComponent.prototype.ngOnInit = function () {
    };
    ImageUploaderComponent.prototype.openWindows = function () {
        document.getElementById('formGroup').click();
    };
    ImageUploaderComponent.prototype.uniqueTime = function () {
        var d = new Date(), newMonth = '' + (d.getMonth()), newDay = '' + d.getDate(), newYear = '' + d.getFullYear(), newHours = '' + d.getHours(), newMinutes = '' + d.getMinutes(), newSeconds = '' + d.getSeconds(), newMiliSeconds = '' + d.getMilliseconds();
        if (newMonth.length < 2)
            newMonth = '0' + newMonth;
        if (newDay.length < 2)
            newDay = '0' + newDay;
        return '' + newYear + newMonth + newDay + newHours + newMinutes + newSeconds + newMiliSeconds;
    };
    ImageUploaderComponent.prototype.checkLengthFilename = function (file) {
        if (file.name.length > this.maxLengthFilename) {
            return false;
        }
        else {
            return true;
        }
    };
    ImageUploaderComponent.prototype.selectSingleFilePresignUrl = function (event) {
        var _this = this;
        this.loader.nativeElement.style.display = 'flex';
        this.change.emit(event.target.files);
        var objFile;
        var file = objFile = event.target.files[0];
        var filesize = event.target.files[0].size;
        if ((this.filesizeMaximum && filesize <= this.filesizeMaximum) || (!this.filesizeMaximum)) {
            if (this.checkLengthFilename(file)) {
                this.progress = 0;
                this.uploadPartsArray = [];
                this.isFinish = false;
                this.errorMime = false;
                this.uploadSuccess = false;
                if (this.filepixelMaximum) {
                    objFile = this.changePixel(event.target.files);
                }
                else {
                    objFile = event.target.files[0];
                }
                var file_1 = objFile;
                if (file_1) {
                    if (this.mimeChecker(file_1)) {
                        console.log('mimeChecker');
                        var _filename = file_1.name;
                        var filename = _filename.split('.').slice(0, -1).join('_');
                        var ext = _filename.split('.').pop();
                        var temp_filename = filename.replace(/[\W\s\n]/gm, "");
                        this.labelInput = this.uniqueTime() + '_' + temp_filename + '.' + ext;
                        var fileSize = file_1.size;
                        this.numChunks = Math.floor(fileSize / this.fileChunkSize) + 1;
                        this.uploadService.getPresignedUrlGcs({
                            prefix: this.prefix,
                            filename: this.labelInput,
                            mime: file_1.type,
                            size: fileSize,
                            module: this.module,
                            tableTarget: this.tableTarget,
                            columnTarget: this.columnTarget,
                            isPublicFile: this.isPublicFile
                        }).subscribe(function (arg) {
                            if (arg.status) {
                                _this.uploadFile(arg.result, file_1);
                            }
                        }, function (error) {
                        });
                    }
                    else {
                        this.isFinish = undefined;
                        this.progress = 0;
                        this.selectedFiles = undefined;
                        this.inputFileVariable.nativeElement.value = '';
                        this.errorMime = true;
                    }
                }
            }
            else {
                Swal.fire('Max length filename is ' + this.maxLengthFilename);
                this.progress = 0;
                this.uploadPartsArray = [];
                this.isFinish = false;
                this.errorMime = false;
                this.uploadSuccess = false;
                this.selectedFiles = undefined;
            }
        }
        else {
            Swal.fire({
                title: 'File Input',
                type: 'info',
                text: 'Ukuruan file tidak boleh lebih dari : ' + this.bytesToSize(this.filesizeMaximum)
            });
        }
    };
    ImageUploaderComponent.prototype.selectFilePresignUrlPart = function (event) {
        var _this = this;
        this.change.emit(event.target.files);
        var objFile;
        var file = event.target.files[0];
        var filesize = event.target.files[0].size;
        if ((this.filesizeMaximum && filesize <= this.filesizeMaximum) || (!this.filesizeMaximum)) {
            if (this.checkLengthFilename(file)) {
                this.progress = 0;
                this.uploadPartsArray = [];
                this.isFinish = false;
                this.errorMime = false;
                this.uploadSuccess = false;
                if (this.filepixelMaximum) {
                    objFile = this.changePixel(event.target.files);
                }
                else {
                    objFile = event.target.files[0];
                }
                //const file = objFile;
                if (file) {
                    if (this.mimeChecker(file)) {
                        var _filename = file.name;
                        var filename = _filename.split('.').slice(0, -1).join('_');
                        var ext = _filename.split('.').pop();
                        var temp_filename = filename.replace(/[\W\s\n]/gm, "");
                        this.labelInput = this.uniqueTime() + '_' + temp_filename + '.' + ext;
                        var fileSize = file.size;
                        this.numChunks = Math.floor(fileSize / this.fileChunkSize) + 1;
                        this.uploadService.createMultipartUpload({ prefix: this.prefix, filename: this.labelInput, mime: file.type, size: fileSize, totalPart: this.numChunks, tableTarget: this.tableTarget, columnTarget: this.columnTarget, isPublicFile: this.isPublicFile }).subscribe(function (response) {
                            if (response.status) {
                                _this.uploadId = response.result.uploadId;
                                _this.listPresignUrlPart = response.result.listPresignedUrl;
                                _this.queueUpload(1);
                            }
                        });
                    }
                    else {
                        this.isFinish = undefined;
                        this.progress = 0;
                        this.selectedFiles = undefined;
                        this.inputFileVariable.nativeElement.value = "";
                        this.errorMime = true;
                    }
                }
            }
            else {
                Swal.fire('Max length filename is ' + this.maxLengthFilename);
                this.progress = 0;
                this.uploadPartsArray = [];
                this.isFinish = false;
                this.errorMime = false;
                this.uploadSuccess = false;
                this.selectedFiles = undefined;
            }
        }
        else {
            Swal.fire({
                title: 'File Input',
                type: 'info',
                text: 'Ukuruan file tidak boleh lebih dari : ' + this.bytesToSize(this.filesizeMaximum)
            });
        }
    };
    ImageUploaderComponent.prototype.uploadFile = function (param, file) {
        var _this = this;
        this.uploadService.putFileGcs(param.signedUploadURL, file).subscribe(function (event) {
            if (event.status == 'done') {
                _this.loader.nativeElement.style.display = 'none';
                _this.isFinish = true;
                _this.uploadSuccess = true;
                _this.success.emit(param);
            }
        });
    };
    ImageUploaderComponent.prototype.queueUpload = function (index) {
        var _this = this;
        this.currentPart = index;
        var file = this.selectedFiles.item(0);
        if (index <= this.numChunks) {
            var start = void 0, end = void 0, blob = void 0;
            start = (index - 1) * this.fileChunkSize;
            end = (index) * this.fileChunkSize;
            blob = (index < this.numChunks) ? file.slice(start, end) : file.slice(start);
            this.uploadService.putFilePart(this.listPresignUrlPart[index], blob).subscribe(function (event) {
                if (event.status == 'done') {
                    var etag = event.val;
                    etag = etag.replace(/^"(.*)"$/, '$1');
                    var param = {
                        ETag: etag,
                        PartNumber: index
                    };
                    _this.uploadPartsArray.push(param);
                    _this.queueUpload(index + 1);
                }
            });
        }
        else {
            this.uploadService.complateMultipartUpload({ prefix: this.prefix, filename: this.labelInput, parts: this.uploadPartsArray, uploadId: this.uploadId }).subscribe(function (response) {
                if (response.status) {
                    _this.isFinish = true;
                    _this.success.emit(response.result);
                    _this.progress = 0;
                    _this.selectedFiles = undefined;
                    _this.inputFileVariable.nativeElement.value = "";
                    _this.loader.nativeElement.style.display = 'none';
                    _this.uploadSuccess = true;
                }
            });
        }
    };
    ImageUploaderComponent.prototype.mimeChecker = function (file) {
        console.log(file);
        if (!this.acceptFile) {
            return true;
        }
        var result = false;
        var listAcceptFile = this.acceptFile.split(',');
        for (var i = 0; i < listAcceptFile.length; i++) {
            var filetype = listAcceptFile[i].trim();
            var parseFilename = file.name.split('.');
            var extention = parseFilename[parseFilename.length - 1];
            var temp = file.name.split('.');
            var tempType = '.' + file.type.split('/')[1];
            console.log(filetype, file.type);
            if (filetype == file.type || filetype === tempType) {
                result = true;
                break;
            }
            else if (extention == filetype.replace('.', '') && this.uncommonFiletype[filetype.replace('.', '')] == file.type) {
                result = true;
                break;
            }
            else if (file.type.indexOf(extention) != -1) {
                result = true;
                break;
            }
        }
        return result;
    };
    ImageUploaderComponent.prototype.dataURLtoFile = function (dataurl, filename) {
        var arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1], bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
        while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
        }
        return new File([u8arr], filename, { type: mime });
    };
    ImageUploaderComponent.prototype.fileChangeEvent = function (event) {
        var _this = this;
        this.imageChangedEvent = event;
        this.change.emit(event.target.files);
        this.modal.open(this.content).result.then(function (result) {
            _this.closeResult = "Closed with: " + result;
            _this.selectFilePresignUrlPart(_this.imageChangedEvent);
        }, function (reason) {
        });
    };
    ImageUploaderComponent.prototype.imageCropped = function (event) {
        this.afterCrop.emit(event.base64);
    };
    ImageUploaderComponent.prototype.imageLoaded = function () {
        // show cropper
    };
    ImageUploaderComponent.prototype.cropperReady = function () {
        // cropper ready
    };
    ImageUploaderComponent.prototype.loadImageFailed = function () {
        // show message
    };
    ImageUploaderComponent.prototype.bytesToSize = function (bytes) {
        var sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
        if (bytes == 0) {
            return '0 Byte';
        }
        else {
            var i = Math.floor(Math.log(bytes) / Math.log(1024));
            return Math.round(bytes / Math.pow(1024, i)) + ' ' + sizes[i];
        }
    };
    ImageUploaderComponent.prototype.changePixel = function (file) {
        var _this = this;
        var fileCompress;
        var maxWidth;
        var maxHeight;
        console.log('size File sebelum compress: ' + file[0].size);
        var fr = new FileReader();
        fr.onload = function () {
            var img = new Image();
            img.onload = function () {
                var pixelImg = img.width * img.height;
                if (_this.filepixelMaximum < pixelImg) {
                    maxWidth = (img.width * _this.filepixelMaximum) / pixelImg;
                    maxHeight = (img.height * _this.filepixelMaximum) / pixelImg;
                }
            };
        };
        var image = file[0];
        this.maxPixelService.resizeImage(image, maxWidth, maxHeight).subscribe(function (result) {
            _this.compressedFile = result;
            console.log('size File setelah compress: ' + _this.compressedFile.size);
        }, function (error) {
            console.log('😢 Oh no!', error);
        });
        this.compressedFile;
    };
    ImageUploaderComponent.prototype.createFileList = function () {
        var args = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            args[_i] = arguments[_i];
        }
        var getDataTransfer = function () { return new DataTransfer(); };
        var concat = Array.prototype.concat;
        try {
            getDataTransfer();
        }
        catch (_a) {
            getDataTransfer = function () { return new ClipboardEvent('').clipboardData; };
        }
        // eslint-disable-next-line prefer-rest-params
        var files = concat.apply([], args);
        var index = 0;
        var length = files.length;
        var dataTransfer = getDataTransfer();
        for (; index < length; index++) {
            dataTransfer.items.add(files[index]);
        }
        return dataTransfer.files;
    };
    ImageUploaderComponent.prototype.onPaste = function (event) {
        var _this = this;
        var items = (event.clipboardData || event.originalEvent.clipboardData).items;
        var file = items[0].getAsFile();
        if (file !== null) {
            this.loader.nativeElement.style.display = 'flex';
            var fileList = this.createFileList(file);
            this.selectedFiles = fileList;
            this.change.emit(fileList);
            var objFile = void 0;
            var filesize = file.size;
            if ((this.filesizeMaximum && filesize <= this.filesizeMaximum) || (!this.filesizeMaximum)) {
                if (this.checkLengthFilename(file)) {
                    this.progress = 0;
                    this.uploadPartsArray = [];
                    this.isFinish = false;
                    this.errorMime = false;
                    this.uploadSuccess = false;
                    if (this.filepixelMaximum) {
                        objFile = this.changePixel(fileList);
                    }
                    else {
                        objFile = file;
                    }
                    if (file) {
                        if (this.mimeChecker(file)) {
                            console.log('mimeChecker');
                            var _filename = file.name;
                            var filename = _filename.split('.').slice(0, -1).join('_');
                            var ext = _filename.split('.').pop();
                            var temp_filename = filename.replace(/[\W\s\n]/gm, "");
                            this.labelInput = this.uniqueTime() + '_' + temp_filename + '.' + ext;
                            var fileSize = file.size;
                            this.numChunks = Math.floor(fileSize / this.fileChunkSize) + 1;
                            console.log('createMultipartUpload');
                            this.uploadService.createMultipartUpload({
                                prefix: this.prefix,
                                filename: this.labelInput,
                                mime: file.type,
                                size: fileSize,
                                totalPart: this.numChunks,
                                tableTarget: this.tableTarget,
                                columnTarget: this.columnTarget,
                                isPublicFile: this.isPublicFile
                            }).subscribe(function (response) {
                                if (response.status) {
                                    _this.uploadId = response.result.uploadId;
                                    _this.listPresignUrlPart = response.result.listPresignedUrl;
                                    _this.queueUpload(1);
                                }
                            }, function (error) {
                            });
                        }
                        else {
                            this.isFinish = undefined;
                            this.progress = 0;
                            this.selectedFiles = undefined;
                            this.inputFileVariable.nativeElement.value = '';
                            this.errorMime = true;
                        }
                    }
                }
                else {
                    Swal.fire('Max length filename is ' + this.maxLengthFilename);
                    this.progress = 0;
                    this.uploadPartsArray = [];
                    this.isFinish = false;
                    this.errorMime = false;
                    this.uploadSuccess = false;
                    this.selectedFiles = undefined;
                }
            }
            else {
                Swal.fire({
                    title: 'File Input',
                    type: 'info',
                    text: 'Ukuruan file tidak boleh lebih dari : ' + this.bytesToSize(this.filesizeMaximum)
                });
            }
        }
        else {
            console.log('file null');
        }
    };
    return ImageUploaderComponent;
}());
export { ImageUploaderComponent };
