<div id="nds_contents">
  <div class="bg-white navbar-spacer"></div>
  <div class="pagecomponentwrap text-center text-white " id="pageheader">
    <div class="container pb-5 pt-75">
      <div class="no-gutters row justify-content-center align-items-center aos-init aos-animate" data-aos="">
        <div class="col-md-10 aos-init aos-animate" data-aos="flip-up">
          <h2 class="stack_font2 h1 ">
            Register
          </h2>
          <nav class="stack_font3 text-white small pb-3 " aria-label="breadcrumb">
            <ol class="breadcrumb-page text-capitalize">
              <li class="breadcrumb-item-page">
                <a href="javascript:;" class="stack_font3 text-white small pb-3">
                  Home
                </a>
              </li>
              <li class="breadcrumb-item-page">
                /
              </li>
              <li class="breadcrumb-item-page active">
                <a href="javascript:;" class="stack_font3 text-white small pb-3">
                  Register
                </a>
              </li>
            </ol>
          </nav>
        </div>
      </div>
    </div>
  </div>
  <div class="pagecomponentwrap text-left ">
    <div class="container pb-5 pt-5 px-4">
      <div class="kt-grid">
        <div class="kt-grid__item kt-grid__item--fluid kt-wizard-v4__wrapper">

          <!--begin: Form Wizard Form-->
          <!--begin: Form Wizard Step 1-->
          <div class="kt-wizard-v4__content text-center">
            <div class="kt-section kt-section--first">
              <div class="kt-wizard-v4__form">
                <div class="font-italic">Note : isian bertanda * wajib diisi.</div>
                <app-alert-common *ngIf="errors.length !== 0" [errors]="errors"></app-alert-common>
                <form class="kt-form" #registration="ngForm" (ngSubmit)="save(registration)">
                  <div class="row justify-content-center text-left">
                    <div class="col-lg-10">
                      <div class="kt-section__body">
                        <div class="form-group row">
                          <div class="col-12">
                            <h3 class="bg-color2 text-white px-3 py-3 rounded h5">
                              Login Information
                            </h3>
                          </div>
                        </div>
                        <div class="form-group row">
                          <label class="col-lg-3 col-form-label">
                            Email Address*
                          </label>
                          <div class="col-lg-9">
                            <div class="input-group">
                              <div class="input-group-prepend">
                                <span class="input-group-text">
                                  <i class="la la-at"></i>
                                </span>
                              </div>
                              <input #email="ngModel" type="text" class="form-control" id="email" name="email"
                                placeholder="contoh : email@contoh.com" [(ngModel)]="users.email" required
                                [class.is-invalid]="email.invalid && email.touched"
                                pattern="^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$">
                            </div>
                            <span class="form-text text-muted">
                              Masukan alamat email Anda yang aktif
                            </span>
                            <span class="help-block" [hidden]="email.valid || email.untouched">
                              <div class="red-text" *ngIf="email.errors && email.errors.required">*email tidak boleh
                                kosong.</div>
                              <div class="red-text" *ngIf="email.errors && email.errors.pattern">*email tidak valid.
                              </div>
                            </span>
                          </div>
                        </div>
                        <div class="form-group row">
                          <label class="col-xl-3 col-lg-3 col-form-label">
                            Password*
                          </label>
                          <div class="col-lg-9 col-xl-9">
                            <div class="input-group">
                              <div class="input-group-prepend">
                                <span class="input-group-text">
                                  <i class="la la-lock"></i>
                                </span>
                              </div>
                              <input #password="ngModel" type="password" class="form-control" id="password"
                                name="password" placeholder="********" [(ngModel)]="users.password" required
                                [class.is-invalid]="password.invalid && password.touched"
                                pattern="^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[A-Za-z\d]{8,}$">
                            </div>
                            <span class="form-text text-muted">
                              Password minimal 8 karakter, terdiri dari minimal satu huruf besar, satu huruf kecil dan
                              satu angka. Tidak diijinkan menggunakan karakter unik dan simbol.
                            </span>
                            <span class="help-block" [hidden]="password.valid || password.untouched">
                              <div class="red-text" *ngIf="password.errors && password.errors.required">*password tidak
                                boleh kosong.</div>
                              <div class="red-text" *ngIf="password.errors && password.errors.pattern">*password tidak
                                valid.</div>
                            </span>
                          </div>
                        </div>
                        <div class="form-group row">
                          <label class="col-xl-3 col-lg-3 col-form-label">
                            Confirm Password*
                          </label>
                          <div class="col-lg-9 col-xl-9">
                            <div class="input-group">
                              <div class="input-group-prepend">
                                <span class="input-group-text">
                                  <i class="la la-lock"></i>
                                </span>
                              </div>
                              <input #repassword="ngModel" type="password" class="form-control" id="repassword"
                                name="repassword" placeholder="********" [(ngModel)]="users.repassword" required
                                [class.is-invalid]="repassword.invalid && repassword.touched">
                            </div>
                            <span class="form-text text-muted">
                              Masukan ulang password Anda
                            </span>
                            <span class="help-block" [class.d-none]="repassword.valid || repassword.untouched">
                              <div class="red-text">*retype password tidak boleh kosong.</div>
                            </span>
                          </div>
                        </div>
                        <div class="form-group row pt-5">
                          <div class="col-12">
                            <h3 class="bg-color2 text-white px-3 py-3 rounded h5">
                              Personal Information
                            </h3>
                          </div>
                        </div>
                        <div class="form-group row">
                          <label class="col-lg-3 col-form-label">
                            Nama Lengkap*
                          </label>
                          <div class="col-lg-9">
                            <div class="input-group">
                              <div class="input-group-prepend">
                                <span class="input-group-text">
                                  <i class="la la-user"></i>
                                </span>
                              </div>
                              <input #nama="ngModel" class="form-control" type="text" id="nama" name="nama"
                                [(ngModel)]="users.nama" required [class.is-invalid]="nama.invalid && nama.touched">
                            </div>
                            <span class="form-text text-muted">
                              Masukan nama sesuai dengan KTP
                            </span>
                            <span class="help-block" [class.d-none]="nama.valid || nama.untouched">
                              <div class="red-text">*nama lengkap tidak boleh kosong.</div>
                            </span>
                          </div>
                        </div>
                        <div class="form-group row">
                          <label class="col-lg-3 col-form-label">
                            No. KTP*
                          </label>
                          <div class="col-lg-9">
                            <div class="input-group">
                              <div class="input-group-prepend">
                                <span class="input-group-text">
                                  <i class="fa fa-address-card"></i>
                                </span>
                              </div>
                              <input #ktp="ngModel" type="text" class="form-control" id="ktp" name="ktp"
                                [(ngModel)]="users.ktp" required [class.is-invalid]="ktp.invalid && ktp.touched"
                                pattern="^[0-9]{16}$">
                            </div>
                            <span class="form-text text-muted">
                              Masukan No. KTP Anda
                            </span>
                            <span class="help-block" [hidden]="ktp.valid || ktp.untouched">
                              <div class="red-text" *ngIf="ktp.errors && ktp.errors.required">*No. KTP tidak boleh
                                kosong.</div>
                              <div class="red-text" *ngIf="ktp.errors && ktp.errors.pattern">*No. KTP tidak valid.</div>
                            </span>
                          </div>
                        </div>
                        <div class="form-group row">
                          <label class="col-lg-3 col-form-label">
                            HP*
                          </label>
                          <div class="col-lg-9">
                            <div class="input-group">
                              <div class="input-group-prepend">
                                <span class="input-group-text">
                                  <i class="la la-phone"></i>
                                </span>
                              </div>
                              <input #hp="ngModel" type="text" class="form-control" id="hp" name="hp"
                                [(ngModel)]="users.hp" required [class.is-invalid]="hp.invalid && hp.touched"
                                pattern="(08|628)[0-9]{7,15}">
                            </div>
                            <span class="form-text text-muted">
                              Cukup masukan angka saja tanpa tambahan karakter lain
                            </span>
                            <span class="help-block" [hidden]="hp.valid || hp.untouched">
                              <div class="red-text" *ngIf="hp.errors && hp.errors.required">*No. HP tidak boleh kosong.
                              </div>
                              <div class="red-text" *ngIf="hp.errors && hp.errors.pattern">*No. HP tidak valid.</div>
                            </span>
                          </div>
                        </div>
                        <div class="form-group row" [ngStyle]="{'overflow-x':'visible','overflow-y':'visible'}">
                          <label class="col-lg-3 col-form-label">
                            Tgl Lahir*
                          </label>
                          <div class="col-lg-9">
                            <div class="input-group">
                              <div class="input-group-prepend">
                                <span class="input-group-text">
                                  <i class="la la-calendar"></i>
                                </span>
                              </div>
                              <input #tgl_lahir="ngModel" type="text" class="form-control" id="tgl_lahir"
                                name="tgl_lahir" [(ngModel)]="users.tgl_lahir" required
                                [class.is-invalid]="tgl_lahir.invalid && tgl_lahir.touched" ngbDatepicker
                                [minDate]="{year: 1950, month: 1, day: 1}" #tgllahir="ngbDatepicker" readOnly
                                (click)="tgllahir.toggle()">
                            </div>
                            <span class="form-text text-muted">
                              Masukan tanggal lahir dengan format YYYY-MM-DD
                            </span>
                            <span class="help-block" [class.d-none]="tgl_lahir.valid || tgl_lahir.untouched">
                              <div class="red-text">*tanggal lahir tidak boleh kosong.</div>
                            </span>
                          </div>
                        </div>
                        <div class="form-group row">
                          <label class="col-lg-3 col-form-label">
                            Kota*
                          </label>
                          <div class="col-lg-9">
                            <div class="input-group">
                              <div class="input-group-prepend">
                                <span class="input-group-text">
                                  <i class="la la-home"></i>
                                </span>
                              </div>
                              <input #kota_asal="ngModel" type="text" class="form-control" id="kota_asal"
                                name="kota_asal" [(ngModel)]="users.kota_asal" required
                                [class.is-invalid]="kota_asal.invalid && kota_asal.touched" pattern="^[a-zA-Z ]+$">
                            </div>
                            <span class="form-text text-muted">
                              Masukan alamat kota tempat anda tinggal
                            </span>
                            <span class="help-block" [hidden]="kota_asal.valid || kota_asal.untouched">
                              <div class="red-text" *ngIf="kota_asal.errors && kota_asal.errors.required">*kota asal
                                tidak boleh kosong.</div>
                              <div class="red-text" *ngIf="kota_asal.errors && kota_asal.errors.pattern">*kota asal
                                hanya boleh diisi dengan huruf.
                              </div>
                            </span>
                          </div>
                        </div>
                        <div class="form-group row">
                          <label class="col-lg-3 col-form-label">
                            Alamat Sesuai KTP*
                          </label>
                          <div class="col-lg-9">
                            <textarea #alamat="ngModel" class="alamat" id="alamat" name="alamat" class="form-control" placeholder="Alamat Sesuai KTP"
                              rows="3" [(ngModel)]="users.alamat" required
                              [class.is-invalid]="alamat.invalid && alamat.touched"></textarea>
                            <div class="red-text">*Alamat wajib lengkap sesuai KTP.</div>
                            <span class="help-block" [class.d-none]="alamat.valid || alamat.untouched">
                              <div class="red-text">*Alamat tidak boleh kosong.</div>
                            </span>
                          </div>
                        </div>
                        <div class="form-group row">
                          <label class="col-lg-3 col-form-label">
                            Jenis Kelamin*
                          </label>
                          <div class="col-lg-3">
                            <div class="input-group">
                              <select #jenis_kelamin="ngModel" id="jenis_kelamin" name="jenis_kelamin"
                                class="form-control" [(ngModel)]="users.jenis_kelamin" required
                                [class.is-invalid]="jenis_kelamin.invalid && jenis_kelamin.touched">
                                <option *ngFor="let rel of jeniskelamin" [ngValue]="rel.value">
                                  {{rel.viewValue}}
                                </option>
                              </select>
                            </div>
                            <span class="help-block" [class.d-none]="jenis_kelamin.valid || jenis_kelamin.untouched">
                              <div class="red-text">*jenis kelamin tidak boleh kosong.</div>
                            </span>
                          </div>
                        </div>
                        <div class="form-group row">
                          <label class="col-lg-3 col-form-label">
                            Pendidikan Terakhir*
                          </label>
                          <div class="col-lg-3">
                            <select #education="ngModel" id="education" name="education" class="form-control"
                              [(ngModel)]="users.education" required
                              [class.is-invalid]="education.invalid && education.touched">
                              <option *ngFor="let rel of pendidikan" [ngValue]="rel.value">
                                {{rel.viewValue}}
                              </option>
                            </select>
                            <span class="help-block" [class.d-none]="education.valid || education.untouched">
                              <div class="red-text">*pendidikan terakhir tidak boleh kosong.</div>
                            </span>
                          </div>
                        </div>
                        <div class="form-group row pt-5">
                          <div class="col-12">
                            <h3 class="bg-color2 text-white px-3 py-3 rounded h5">
                              Job Information
                            </h3>
                          </div>
                        </div>
                        <div class="form-group row">
                          <label class="col-lg-3 col-form-label">
                            Ketersedian*
                          </label>
                          <div class="col-lg-3">
                            <div class="input-group">
                              <select #availability="ngModel" id="availability" name="availability" class="form-control"
                                [(ngModel)]="users.availability" required
                                [class.is-invalid]="availability.invalid && availability.touched">
                                <option *ngFor="let rel of ketersediaan" [ngValue]="rel.value">
                                  {{rel.viewValue}}
                                </option>
                              </select>
                              <span class="help-block" [class.d-none]="availability.valid || availability.untouched">
                                <div class="red-text">*ketersediaan tidak boleh kosong.</div>
                              </span>
                            </div>
                          </div>
                        </div>
                        <div class="form-group row" [ngStyle]="{'overflow-x':'visible','overflow-y':'visible'}">
                          <label class="col-lg-3 col-form-label">
                            Dapat mulai bergabung*
                          </label>
                          <div class="col-lg-9">
                            <div class="input-group">
                              <div class="input-group-prepend">
                                <span class="input-group-text">
                                  <i class="la la-calendar"></i>
                                </span>
                              </div>
                              <input #join_date="ngModel" type="text" class="form-control" id="join_date"
                                name="join_date" [(ngModel)]="users.join_date" required
                                [class.is-invalid]="join_date.invalid && join_date.touched" ngbDatepicker
                                #joindate="ngbDatepicker" readOnly (click)="joindate.toggle()">
                            </div>
                            <span class="form-text text-muted">
                              Masukan tanggal dapat mulai bergabung dengan format YYYY-MM-DD
                            </span>
                            <span class="help-block" [class.d-none]="join_date.valid || join_date.untouched">
                              <div class="red-text">*tanggal bergabung tidak boleh kosong.</div>
                            </span>
                          </div>
                        </div>
                        <div class="form-group row">
                          <label class="col-lg-3 col-form-label">
                            Gaji yang diharapkan*
                          </label>
                          <div class="col-lg-9">
                            <div class="input-group">
                              <div class="input-group-prepend">
                                <span class="input-group-text">
                                  <i class="la la-money"></i>
                                </span>
                              </div>
                              <input #salary="ngModel" type="number" class="form-control" id="salary" name="salary"
                                placeholder="contoh : 1000000" [(ngModel)]="users.salary" required
                                [class.is-invalid]="salary.invalid && salary.touched">
                            </div>
                            <span class="form-text text-muted">
                              Isikan gaji yang diharapkan dengan angka saja dengan panjang 7 s/d 9 digit angka
                            </span>
                            <span class="help-block" [class.d-none]="salary.valid || salary.untouched">
                              <div class="red-text">*gaji tidak boleh kosong.</div>
                            </span>
                          </div>
                        </div>
                        <div class="form-group row pt-5">
                          <div class="col-12">
                            <h3 class="bg-color2 text-white px-3 py-3 rounded h5">
                              Upload Document
                            </h3>
                          </div>
                        </div>

                        <div class="row">

                          <div class="col-lg-4 mb-4">
                            <div class="text-center">
                              <img [class.d-none]="!croppedFoto" class="img-fluid" [src]="croppedFoto">
                            </div>
                          </div>
                          <div class="col-lg-4 mb-4">
                            <div class="text-center">
                              <img [class.d-none]="!croppedKtp" class="img-fluid" [src]="croppedKtp">
                            </div>
                          </div>
                          <div class="col-lg-4 mb-4"></div>
                        </div>

                        <div class="form-group row">
                          <div class="col-lg-4 mb-4" id="form-upload-foto">
                            <label class="col-12 col-form-label">
                              Lampiran Foto*
                            </label>
                            <div class="col-12">
                              <app-gcs-upload [acceptFile]="['image/jpg','image/jpeg','image/png']" [remark]="''"
                              [isPublicFile]="true" [prefix]="'uploads/foto/'" [module]="'vacancy'" [tableTarget]="'users'" [columnTarget]="'foto'"
                                (success)="onSuccess($event, 'foto')" >
                              </app-gcs-upload>
                              <div class="red-text">Silakan upload foto yang sesuai, karena akan dijadikan sebagai Foto ID Card karyawan dan harus dalam format jpg/jpeg/png</div>
                            </div>
                          </div>
                          <div class="col-lg-4 mb-4" id="form-upload-ktp">
                            <label class="col-12 col-form-label">
                              Lampiran KTP*
                            </label>
                            <div class="col-12">
                              <app-gcs-upload [acceptFile]="['image/jpg','image/jpeg','image/png']" [remark]="''"
                                [prefix]="'uploads/ktp/'" [module]="'vacancy'" [tableTarget]="'users'" [columnTarget]="'ktp_attachment'"
                                (success)="onSuccess($event, 'ktp_attachment')">
                              </app-gcs-upload>
                              <div class="red-text">File wajib terlihat dengan jelas dan harus dalam format jpg/jpeg/png</div>
                            </div>
                          </div>
                          <div class="col-lg-4 mb-4" id="form-upload-kk">
                            <label class="col-12 col-form-label">
                              Lampiran KK / Kartu Keluarga*
                            </label>
                            <div class="col-12">
                              <app-gcs-upload [acceptFile]="['image/jpg','image/jpeg','image/png']" [remark]="''"
                                [prefix]="'uploads/kk/'" [module]="'vacancy'" [tableTarget]="'users'" [columnTarget]="'kk_attachment'"
                                (success)="onSuccess($event, 'kk_attachment')">
                              </app-gcs-upload>
                              <div class="red-text">File wajib terlihat dengan jelas dan harus dalam format jpg/jpeg/png</div>
                            </div>
                          </div>
                          <div class="col-lg-4 mb-4" id="form-upload-cv">
                            <label class="col-12 col-form-label">
                              Lampiran CV*
                            </label>
                            <div class="col-12">
                              <app-gcs-upload [acceptFile]="['application/pdf']" [remark]="''"
                              [isPublicFile]="true" [prefix]="'uploads/cv/'" [module]="'vacancy'" [tableTarget]="'users'" [columnTarget]="'cv_attachment'"
                                (success)="onSuccess($event, 'cv_attachment')">
                              </app-gcs-upload>
                              <div class="red-text">File harus dalam format pdf</div>
                            </div>
                          </div>
                        </div>
                        <div class="form-group row pt-5">
                          <div class="col-12">
                            <h3 class="bg-color2 text-white px-3 py-3 rounded h5">
                              Passport Information (Optional)
                            </h3>
                          </div>
                        </div>
                        <div class="form-group row">
                          <div class="col-12">
                            Silakan diisi jika memiliki Passport
                          </div>
                        </div>
                        <div class="form-group row">
                          <label class="col-lg-3 col-form-label">
                            Passport No
                          </label>
                          <div class="col-lg-9">
                            <div class="input-group">
                              <input #passport_no="ngModel" [(ngModel)]="users.passport_no" type="text"
                                class="form-control" id="no_passport" name="no_passport">
                            </div>
                          </div>
                        </div>
                        <div class="form-group row">
                          <label class="col-lg-3 col-form-label">
                            Passport Country
                          </label>
                          <div class="col-lg-9">
                            <div class="input-group">
                              <input #passport_country="ngModel" [(ngModel)]="users.passport_country" type="text"
                                class="form-control" id="passport_country" name="passport_country">
                            </div>
                          </div>
                        </div>
                        <div class="form-group row">
                          <label class="col-lg-3 col-form-label">
                            Expired Passport
                          </label>
                          <div class="col-lg-9">
                            <div class="input-group">
                              <div class="input-group-prepend">
                                <span class="input-group-text">
                                  <i class="la la-calendar"></i>
                                </span>
                              </div>
                              <input #expired_passport="ngModel" type="text" class="form-control" id="expired_passport"
                                name="expired_passport" [(ngModel)]="users.expired_passport" ngbDatepicker
                                #expiredpassport="ngbDatepicker" readOnly (click)="expiredpassport.toggle()">
                            </div>
                          </div>
                        </div>
                        <div class="form-group row pt-5">
                          <div class="col-12">
                            <h3 class="bg-color2 text-white px-3 py-3 rounded h5">
                              Sertifikat (Optional)
                            </h3>
                          </div>
                        </div>
                        <div class="form-group row">
                          <div class="col-12">
                            Silakan diisi jika memiliki Sertifikat
                          </div>
                        </div>
                        <ng-container *ngFor="let cerft of certificates; let i = index; trackBy:trackById;">
                          <div class="form-group row">
                            <div class="col-lg-6 col-md-12">
                              <div class="row">
                                <label class="col-lg-3 col-form-label">
                                    Tanggal Terbit Sertifikat / Lisensi
                                </label>
                                <div class="col-lg-9">
                                  <div class="input-group">
                                      <input type="text" class="form-control" name="certificate_{{i}}_publish_date" [(ngModel)]="certificates[i].publish_date"
                                      ngbDatepicker #publishDate="ngbDatepicker" readOnly (click)="publishDate.toggle()">
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="col-lg-6 col-md-12">
                              <div class="row">
                                <label class="col-lg-3 col-form-label">
                                    Tempat Training
                                </label>
                                <div class="col-lg-9">
                                    <input name="certificate_{{i}}_training_place" [(ngModel)]="certificates[i].training_place" type="text" class="form-control">
                                </div>
                              </div>
                            </div>                          
                          </div>
                          <div class="form-group row">
                              <div class="col-lg-6 col-md-12">
                                <div class="row">
                                  <label class="col-lg-3 col-form-label">
                                      Periode Training dimulai
                                  </label>
                                  <div class="col-lg-9">
                                    <div class="input-group">
                                        <input type="text" class="form-control" name="certificate_{{i}}_training_period_start" [(ngModel)]="certificates[i].training_period_start"
                                        ngbDatepicker #trainingPeriodStart="ngbDatepicker" readOnly (click)="trainingPeriodStart.toggle()">
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div class="col-lg-6 col-md-12">
                                <div class="row">
                                  <label class="col-lg-3 col-form-label">
                                      Periode Training selesai
                                  </label>
                                  <div class="col-lg-9">
                                    <input type="text" class="form-control" name="certificate_{{i}}_training_period_end" [(ngModel)]="certificates[i].training_period_end"
                                    ngbDatepicker #trainingPeriodend="ngbDatepicker" readOnly (click)="trainingPeriodend.toggle()">
                                  </div>
                                </div>
                              </div>                          
                            </div>
                          <div class="form-group row">
                            <label class="col-lg-3 col-form-label">
                              Nomor Sertifikat
                            </label>
                            <div class="col-lg-9">
                              <div class="input-group">
                                <input name="certificate_no_{{i}}" [(ngModel)]="certificates[i].no" type="text" class="form-control">
                              </div>
                            </div>
                          </div>
                          <div class="form-group row">
                            <label class="col-lg-3 col-form-label">
                              Nama Sertifikat
                            </label>
                            <div class="col-lg-9">
                              <div class="input-group">
                                <input name="certificate_na_{{i}}me" [(ngModel)]="certificates[i].name" type="text" class="form-control">
                              </div>
                            </div>
                          </div>
                          <div class="form-group row">
                            <label class="col-lg-3 col-form-label">
                              Badan / Lembaga Penerbit
                            </label>
                            <div class="col-lg-9">
                              <div class="input-group">
                                <input name="certificate_pu_{{i}}blisher" [(ngModel)]="certificates[i].publisher" type="text" class="form-control">
                              </div>
                            </div>
                          </div>
                          <div class="form-group row">
                            <label class="col-lg-3 col-form-label">
                                Tanggal expired Sertifikat / Lisensi
                            </label>
                            <div class="col-lg-9">
                              <div class="input-group">
                                <div class="input-group-prepend">
                                  <span class="input-group-text">
                                    <i class="la la-calendar"></i>
                                  </span>
                                </div>
                                <input type="text" class="form-control" name="certificate_{{i}}_expired_date" [(ngModel)]="certificates[i].expired_date"
                                ngbDatepicker #expiredDate="ngbDatepicker" readOnly (click)="expiredDate.toggle()">
                              </div>
                            </div>
                          </div>
                          <div class="form-group row">
                            <label class="col-lg-3 col-form-label">
                              File Serfikat
                              <div class="red-text">(file harus dalam format pdf)</div>
                            </label>
                            <div class="col-lg-9">
                              <div class="input-group">
                                <app-direct-upload  [tableTarget]="'certificate'" [columnTarget]="'id_attachment'" [prefix]="'uploads/certificate/'"
                                    [acceptFile]="'.pdf'" [filesizeMaximum]="10000000" (success)="onSuccessUpload($event, 'id_attachment' , certificates[i])">
                                </app-direct-upload>
                              </div>
                            </div>
                          </div>
                          <div class="form-group row">
                            <label class="col-lg-3 col-form-label">
                              Nomor Lisensi
                            </label>
                            <div class="col-lg-9">
                              <div class="input-group">
                                <input name="certificate_no_licence_{{i}}" [(ngModel)]="certificates[i].no_licence" type="text" class="form-control">
                              </div>
                            </div>
                          </div>
                          <div class="form-group row">
                            <label class="col-lg-3 col-form-label">
                                Upload Lisensi
                                <div class="red-text">(file harus dalam format pdf)</div>
                            </label>
                            <div class="col-lg-9">
                              <div class="input-group">
                                <div class="input-group-prepend">
                                  <app-direct-upload  [tableTarget]="'certificate'" [columnTarget]="'id_attachment_licence'" [prefix]="'uploads/certificate/'"
                                    [acceptFile]="'.pdf'" [filesizeMaximum]="10000000" (success)="onSuccessUpload($event, 'id_attachment_licence' , certificates[i])">
                                  </app-direct-upload>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="form-group row">
                              <label class="col-lg-3 col-form-label">
                                  No SUKET
                              </label>
                              <div class="col-lg-9">
                                <div class="input-group">
                                  <input name="certificate_no_suket_{{i}}" [(ngModel)]="certificates[i].no_suket" type="text" class="form-control">
                                </div>
                              </div>
                            </div>
                            <div class="form-group row">
                              <label class="col-lg-3 col-form-label">
                                  Upload SUKET
                                  <div class="red-text">(file harus dalam format pdf)</div>
                              </label>
                              <div class="col-lg-9">
                                <div class="input-group">
                                  <div class="input-group-prepend">
                                    <app-direct-upload  [tableTarget]="'certificate'" [columnTarget]="'id_attachment_suket'" [prefix]="'uploads/certificate/'"
                                      [acceptFile]="'.pdf'" [filesizeMaximum]="10000000" (success)="onSuccessUpload($event, 'id_attachment_suket' , certificates[i])">
                                    </app-direct-upload>
                                  </div>
                                </div>
                              </div>
                            </div>
                          <div class="col-lg-2">
                            <button type="button" class="btn btn-danger" (click)="dellCertificate(cerft)">Hapus</button>
                          </div>
                          <div class="row">
                            <div class="col-lg-12">
                              <hr>
                            </div>
                          </div>
                        </ng-container>
                        <div class="row">
                          <div class="col-lg-10">
                            &nbsp;
                          </div>
                          <div class="col-lg-2">
                            <button type="button" class="btn btn-success" (click)="addCertificate()">Tambah Sertifikat</button>
                          </div>
                        </div>
                        <div class="form-group row pt-5">
                          <div class="col-12">
                            <h3 class="bg-color2 text-white px-3 py-3 rounded h5">
                              Vaccine Information
                            </h3>
                          </div>
                        </div>
                        <div class="form-group row">
                          <div class="col-12">
                            Silahkan melengkapi data vaksin covid-19 dengan data yang valid.
                          </div>
                        </div>
                        <div class="form-group row">
                          <div class="col-lg-6 col-md-12">
                            <div class="row">
                              <label class="col-lg-3 col-form-label">
                                Tanggal Vaksin Pertama*
                              </label>
                              <div class="col-lg-9">
                                <div class="input-group">
                                  <input type="text" class="form-control"
                                    name="vaccine_1_date" [(ngModel)]="users.vaccine_1_date" ngbDatepicker
                                    #vaccineFirstDate="ngbDatepicker" readOnly (click)="vaccineFirstDate.toggle()">
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="col-lg-6 col-md-12">
                            <div class="row">
                              <label class="col-lg-3 col-form-label">
                                Sertifikat Vaksin Pertama*
                              </label>
                              <div class="col-lg-9">
                                <app-direct-upload  [tableTarget]="'users'" [columnTarget]="'vaccine_1_attach'" [prefix]="'uploads/vaksin/'"
                                  [acceptFile]="'.jpg,.jpeg,.png,.pdf'" (success)="onSuccessWasabi($event, 'vaccine_1_attach')">
                                </app-direct-upload>
                              </div>
                            </div>
                          </div>                          
                        </div>
                        <div class="form-group row">
                          <div class="col-lg-6 col-md-12">
                            <div class="row">
                              <label class="col-lg-3 col-form-label">
                                Tanggal Vaksin Kedua*
                              </label>
                              <div class="col-lg-9">
                                <div class="input-group">
                                  <input type="text" class="form-control"
                                    name="vaccine_2_date" [(ngModel)]="users.vaccine_2_date" ngbDatepicker
                                    #vaccineSecondDate="ngbDatepicker" readOnly (click)="vaccineSecondDate.toggle()">
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="col-lg-6 col-md-12">
                            <div class="row">
                              <label class="col-lg-3 col-form-label">
                                Sertifikat Vaksin Kedua*
                              </label>
                              <div class="col-lg-9">
                                <app-direct-upload  [tableTarget]="'users'" [columnTarget]="'vaccine_2_attach'" [prefix]="'uploads/vaksin/'"
                                  [acceptFile]="'.jpg,.jpeg,.png,.pdf'" (success)="onSuccessWasabi($event, 'vaccine_2_attach')">
                                </app-direct-upload>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="form-group row">
                          <div class="col-lg-6 col-md-12">
                            <div class="row">
                              <label class="col-lg-3 col-form-label">
                                Tanggal Vaksin Ketiga*
                              </label>
                              <div class="col-lg-9">
                                <div class="input-group">
                                  <input type="text" class="form-control"
                                    name="vaccine_3_date" [(ngModel)]="users.vaccine_3_date" ngbDatepicker
                                    #vaccineThreeDate="ngbDatepicker" readOnly (click)="vaccineThreeDate.toggle()">
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="col-lg-6 col-md-12">
                            <div class="row">
                              <label class="col-lg-3 col-form-label">
                                Sertifikat Vaksin Ketiga*
                              </label>
                              <div class="col-lg-9">
                                <app-direct-upload  [tableTarget]="'users'" [columnTarget]="'vaccine_3_attach'" [prefix]="'uploads/vaksin/'"
                                  [acceptFile]="'.jpg,.jpeg,.png,.pdf'" (success)="onSuccessWasabi($event, 'vaccine_3_attach')">
                                </app-direct-upload>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div class="text-center">
                          <ng-adsense [className]="'adsbygoogle'" [adClient]="'ca-pub-4460520388028352'" [adSlot]="1025911797"
                            [display]="'inline-block'" [width]="970" [height]="90"></ng-adsense>
                        </div>

                        <div class="form-group row bg-light px-3 py-3 border rounded">
                          <div class="col-6 text-left"></div>
                          <div class="col-6 text-right">
                            <button class="btn btn-brand btn-md btn-tall btn-wide kt-font-bold kt-font-transform-u">
                              Daftar
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>

          <!--end: Form Wizard Step 1-->
          <!--end: Form Wizard Form-->
        </div>
      </div>
    </div>
  </div>
  <div class="clear"></div>
</div>
