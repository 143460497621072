<div class="w-25 float-right">
  <div class="template-page-sidebar row">
    <div class="bg-light mb-2 p-3 col-12">
      <div class="category2">
        <h3 class="text-center pb-3 h3 stack_font2 m-0">
          Kategori Blog
        </h3>
        <ng-container *ngIf="categories else skeletonSide">
          <ul style="list-style-type:none; margin:0; padding:0;">
            <ng-container *ngFor="let category of categories">
              <li>
                <a class="d-block text-dark border-top p-2 font-weight-bold" [routerLink]="['/blog-category', category.slug]" routerLinkActive="router-link-active" (click)="reloadCategory.emit(category.slug)">
                  {{category.category}}
                </a>
              </li>
            </ng-container>
          </ul>
        </ng-container>
      </div>
    </div>
    <div class="mb-4 px-3 py-3 col-12 bg-color2" id="blogpopular">
      <h3 class="text-center pb-3 h3 stack_font2 m-0 text-white rounded-top">
        Popular Post
      </h3>
      <ng-container *ngIf="populars else skeletonSide">
        <ng-container *ngFor="let popular of populars">
          <div class="media py-3 border-top border-color2_light">
            <a [routerLink]="['/blog-post', popular.slug]" routerLinkActive="router-link-active" (click)="reloadPost.emit(popular.slug)">
              <img [src]="popular.picture" onerror="src='/assets/media/error/noimage.png'" class="mr-3" alt="" style="width:60px; height:40px;">
            </a>
            <div class="media-body">
              <p class="mt-0 mb-1">
                <a [routerLink]="['/blog-post', popular.slug]" routerLinkActive="router-link-active" class="text-color4 text-white-hover text-capitalize font-weight-bold" (click)="reloadPost.emit(popular.slug)">
                  {{popular.title}}
                </a>
              </p>
              <div class="small text-light">
                {{!popular.date ? 'not set' : popular.date | date : "d MMM y"}}
                {{!popular.date ? '' : popular.date | date : "HH"}}
                {{!popular.date ? '' : ':'}}
                {{!popular.date ? '' : popular.date | date : "mm"}}
              </div>
            </div>
          </div>
        </ng-container>
      </ng-container>
      <div class="clear"></div>
    </div>
  </div>
</div>

<ng-template #skeletonSide>

  <div class="row">
    <div class="col-12">
      <div class="skeleton-loader"></div>
    </div>
  </div>

  <div class="row">
    <div class="col-12">
      <div class="skeleton-loader"></div>
    </div>
  </div>

  <div class="row">
    <div class="col-12">
      <div class="skeleton-loader"></div>
    </div>
  </div>

  <div class="row">
    <div class="col-12">
      <div class="skeleton-loader"></div>
    </div>
  </div>

</ng-template>