import { HttpClient, HttpHeaders, HttpRequest, HttpEventType } from '@angular/common/http';
import { CookieService } from 'ngx-cookie-service';
import { GlobalService } from './global.service';
import { Observable, Subject, throwError } from 'rxjs';
import { map, last, catchError } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "ngx-cookie-service";
import * as i3 from "./global.service";
var API_GET_PRESIGN_URL = '/upload/get-presigned-url';
var API_GET_PRESIGN_URL_GCS = '/upload/get-presigned-url-gcs';
var API_COMPLETE_UPLOAD = '/upload/complate-upload';
var API_COMPLETE_UPLOAD_OBJ_GCS = '/upload/complate-upload-obj-gcs';
var API_CREATE_MULTIPART_UPLOAD = '/upload/create-multipart-upload';
var API_WEBCAM_UPLOAD = '/upload/webcam-upload';
var API_COMPLETE_MULTIPART_UPLOAD = '/upload/complate-multipart-upload';
var API_GET_WASABI_SUMMARY = '/upload/wasabi-summary';
var API_DOWNLOAD_URL_OBJ_GCS = '/upload/download-url-obj-gcs';
var API_GET_PRESIGN_UPLOAD_URL_GCS_FROM_FB = '/presignUrlUplaodGcs';
var API_GET_PRESIGN_DOWNLOAD_URL_GCS_FROM_FB = '/presignUrlDownloadGcs';
var API_SIGNED_WASABI_URL = '/upload/signed-wasabi-url';
var UploadService = /** @class */ (function () {
    function UploadService(http, cookieService, globalService) {
        this.http = http;
        this.cookieService = cookieService;
        this.globalService = globalService;
        this.progressEmit = new Subject();
        this.progressEmitObs = this.progressEmit.asObservable();
    }
    UploadService.prototype.header = function () {
        return {
            headers: new HttpHeaders()
                .set('token', this.cookieService.get('_q'))
        };
    };
    UploadService.prototype.headerForFb = function () {
        return {
            headers: new HttpHeaders()
        };
    };
    // ---single upload---- //
    UploadService.prototype.getPresignedUrl = function (params) {
        return this.http.post(this.globalService.apiVersionHost + API_GET_PRESIGN_URL, params, this.header()).pipe(map(function (response) { return response; }));
    };
    UploadService.prototype.getPresignedUrlGcs = function (params) {
        return this.http.post(this.globalService.apiVersionHost + API_GET_PRESIGN_URL_GCS, params, this.header()).pipe(map(function (response) { return response; }));
    };
    UploadService.prototype.getSignedUploadUrlGcsFromFb = function (params) {
        return this.http.post(this.globalService.firebaseHost + API_GET_PRESIGN_UPLOAD_URL_GCS_FROM_FB, params, this.headerForFb()).pipe(map(function (response) { return response; }));
    };
    UploadService.prototype.getSignedDownloadUrlGcsFromFb = function (params) {
        return this.http.post(this.globalService.firebaseHost + API_GET_PRESIGN_DOWNLOAD_URL_GCS_FROM_FB, params, this.headerForFb()).pipe(map(function (response) { return response; }));
    };
    // Upload Using Presigned Url
    UploadService.prototype.putFile = function (url, file) {
        return this.http.put(url, file).pipe(map(function (response) { return response; }));
    };
    UploadService.prototype.putFileGcs = function (url, file) {
        var _this = this;
        var req = new HttpRequest('PUT', url, file, {
            reportProgress: true,
            headers: new HttpHeaders()
                .set('ngsw-bypass', 'true')
                .set('Content-Type', 'application/octet-stream')
        });
        this.progressEmit.next(0);
        return this.http.request(req).pipe(map(function (event) { return _this.getEventMessageGCSUpload(event); }), last());
    };
    UploadService.prototype.putFileGcsFb = function (url, file) {
        var _this = this;
        var req = new HttpRequest('PUT', url, file, {
            reportProgress: true,
            headers: new HttpHeaders()
                .set('Content-Type', 'application/octet-stream')
        });
        this.progressEmit.next(0);
        return this.http.request(req).pipe(map(function (event) { return _this.getEventMessageGCSUpload(event); }), last());
    };
    UploadService.prototype.getFileGcs = function (url) {
        var _this = this;
        var req = new HttpRequest('GET', url, {
            responseType: 'blob',
            reportProgress: true,
            headers: new HttpHeaders()
                .set('Content-Type', 'application/json')
        });
        this.progressEmit.next(0);
        return this.http.request(req).pipe(map(function (event) { return _this.getEventMessageGCSDownload(event); }), last());
    };
    UploadService.prototype.downloadUrlObjGcs = function (params) {
        return this.http.post(this.globalService.apiVersionHost + API_DOWNLOAD_URL_OBJ_GCS, params, this.header()).pipe(map(function (response) { return response; }));
    };
    UploadService.prototype.complateUpload = function (params) {
        return this.http.post(this.globalService.apiVersionHost + API_COMPLETE_UPLOAD, params, this.header()).pipe(map(function (response) { return response; }));
    };
    UploadService.prototype.complateUploadObjGcs = function (params) {
        return this.http.post(this.globalService.apiVersionHost + API_COMPLETE_UPLOAD_OBJ_GCS, params, this.header()).pipe(map(function (response) { return response; }));
    };
    // end
    // ---multipart upload---- //
    // tslint:disable-next-line: max-line-length
    UploadService.prototype.createMultipartUpload = function (params) {
        return this.http.post(this.globalService.apiVersionHost + API_CREATE_MULTIPART_UPLOAD, params, this.header()).pipe(map(function (response) { return response; }));
    };
    // upload from webcam
    UploadService.prototype.webcamImageUpload = function (params) {
        return this.http.post(this.globalService.apiVersionHost + API_WEBCAM_UPLOAD, params, this.header()).pipe(map(function (response) { return response; }));
    };
    UploadService.prototype.putFilePart = function (url, file) {
        var _this = this;
        var req = new HttpRequest('PUT', url, file, {
            reportProgress: true,
            headers: new HttpHeaders().set('ngsw-bypass', 'true')
        });
        return this.http.request(req).pipe(map(function (event) { return _this.getEventMessage(event, file); }), last());
    };
    UploadService.prototype.getEventMessage = function (event, file) {
        switch (event.type) {
            case HttpEventType.UploadProgress:
                var percentDone = Math.round(100 * event.loaded / event.total);
                this.progressEmit.next(percentDone);
                return {
                    status: 'progress',
                    val: percentDone
                };
            case HttpEventType.Response:
                return {
                    status: 'done',
                    val: event.headers.get('etag')
                };
            default:
                return {
                    status: 'unknow',
                    val: null
                };
        }
    };
    UploadService.prototype.getEventMessageGCSUpload = function (event) {
        switch (event.type) {
            case HttpEventType.UploadProgress:
                var percentDone = Math.round(100 * event.loaded / event.total);
                this.progressEmit.next(percentDone);
                return {
                    status: 'progress',
                    val: percentDone
                };
            case HttpEventType.Response:
                return {
                    status: 'done',
                    val: event
                };
            default:
                return {
                    status: 'unknow',
                    val: null
                };
        }
    };
    UploadService.prototype.getEventMessageGCSDownload = function (event) {
        switch (event.type) {
            case HttpEventType.DownloadProgress:
                var percentDone = Math.round(100 * event.loaded / event.total);
                this.progressEmit.next(percentDone);
                return {
                    status: 'progress',
                    val: percentDone
                };
            case HttpEventType.Response:
                return {
                    status: 'done',
                    val: event
                };
            default:
                return {
                    status: 'unknow',
                    val: null
                };
        }
    };
    UploadService.prototype.complateMultipartUpload = function (params) {
        return this.http.post(this.globalService.apiVersionHost + API_COMPLETE_MULTIPART_UPLOAD, params, this.header()).pipe(map(function (response) { return response; }));
    };
    UploadService.prototype.getSignedWasabiUrl = function (params) {
        return this.http.post(this.globalService.apiVersionHost + API_SIGNED_WASABI_URL, params, this.header()).pipe(map(function (response) { return response; }));
    };
    UploadService.prototype.downloadFile = function (path) {
        var req = new HttpRequest('GET', path, {
            reportProgress: true,
            responseType: 'blob'
        });
        return this.http.request(req).pipe(map(function (res) { return res; }), catchError(function (err) {
            return throwError(err);
        }));
    };
    UploadService.prototype.getWasabiSummary = function () {
        return this.http.post(this.globalService.apiVersionHost + API_GET_WASABI_SUMMARY, null, this.header()).pipe(map(function (response) { return response; }));
    };
    UploadService.prototype.getListCloud = function (params) {
        return this.http
            .post(this.globalService.apiVersionHost + '/upload/list-cloud-storage', params, this.header())
            .pipe(map(function (response) { return response; }));
    };
    UploadService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function UploadService_Factory() { return new UploadService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.CookieService), i0.ɵɵinject(i3.GlobalService)); }, token: UploadService, providedIn: "root" });
    return UploadService;
}());
export { UploadService };
