import { Component, OnInit } from '@angular/core';
import { WhistleBlowingService } from 'src/app/_services/whistle-blowing.service';
import Swal from 'sweetalert2';
import { GlobalService } from 'src/app/_services/global.service';

@Component({
  selector: 'app-whistle-blowing',
  templateUrl: './whistle-blowing.component.html',
  styleUrls: ['./whistle-blowing.component.scss']
})
export class WhistleBlowingComponent implements OnInit {

  master: any = {};
  detail: { id_file: number }[] = [];
  listAttach: { id_file: number, url: string, ext: string }[] = [];
  apiHost: string;

  constructor(
    private whistleBlowingService: WhistleBlowingService,
    private globalService: GlobalService

  ) { }

  ngOnInit() {
    this.apiHost = this.globalService.apiHost;

  }

  onUploaded(event) {
    let temp = { id_file: event.id };
    this.detail.push(temp);
    let url = this.apiHost + '/site/download/' + event.id;//$event.id.toString();
    let dataAttach = { id_file: event.id, url: url, ext: url.split('.').pop() };
    this.listAttach.push(dataAttach);
    console.log(this.listAttach);
  }

  onSuccessWasabi(event) {
    let temp = { id_file: 1 * event.wasabiRowId };
    this.detail.push(temp);
    let url = event.wasabiRespone.Location;
    let dataAttach = { id_file: event.wasabiRowId, url: url, ext: url.split('.').pop() };
    this.listAttach.push(dataAttach);
    console.log(this.listAttach);
  }

  deleteAttach(id) {
    this.listAttach = this.listAttach.filter(el => el.id_file !== id);
    this.detail = this.detail.filter(el => el.id_file !== id);
  }

  submitWb() {
    this.whistleBlowingService.create({ master: this.master, detail: this.detail }).subscribe(
      (response: any) => {
        if (response.status) {
          Swal.fire({
            title: 'Pelaporan Kecurangan',
            type: 'success',
            text: 'Laporan Anda terlah berhasil disubmit. Terimakasih atas partisipasi Anda.'
          });
        } else {
          Swal.fire({
            title: 'Pelaporan Kecurangan',
            type: 'error',
            text: 'Laporan Anda terlah gagal disubmit.'
          });
        }
        this.master = {};
        this.listAttach = [];
        this.detail = [];
      }
    );
  }

}
