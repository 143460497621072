/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./test.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../shared/image-uploader/image-uploader.component.ngfactory";
import * as i3 from "../../../shared/image-uploader/image-uploader.component";
import * as i4 from "../../../../_services/upload.service";
import * as i5 from "@ng-bootstrap/ng-bootstrap";
import * as i6 from "ng2-img-max/dist/src/ng2-img-max.service";
import * as i7 from "./test.component";
var styles_TestComponent = [i0.styles];
var RenderType_TestComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_TestComponent, data: {} });
export { RenderType_TestComponent as RenderType_TestComponent };
export function View_TestComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["id", "nds_contents"], ["style", "margin-top: 70px"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "div", [["style", "width: 100px"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "app-image-uploader", [], null, [[null, "success"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("success" === en)) {
        var pd_0 = (_co.onSuccess($event, "foto") !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_ImageUploaderComponent_0, i2.RenderType_ImageUploaderComponent)), i1.ɵdid(3, 114688, null, 0, i3.ImageUploaderComponent, [i4.UploadService, i5.NgbModal, i6.Ng2ImgMaxService], { prefix: [0, "prefix"], acceptFile: [1, "acceptFile"], cropper: [2, "cropper"], tableTarget: [3, "tableTarget"], columnTarget: [4, "columnTarget"], isPublicFile: [5, "isPublicFile"] }, { success: "success" })], function (_ck, _v) { var currVal_0 = "uploads/foto/"; var currVal_1 = ".jpg,.jpeg,.png"; var currVal_2 = true; var currVal_3 = "users"; var currVal_4 = "foto"; var currVal_5 = true; _ck(_v, 3, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5); }, null); }
export function View_TestComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-test", [], null, null, null, View_TestComponent_0, RenderType_TestComponent)), i1.ɵdid(1, 114688, null, 0, i7.TestComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var TestComponentNgFactory = i1.ɵccf("app-test", i7.TestComponent, View_TestComponent_Host_0, {}, {}, []);
export { TestComponentNgFactory as TestComponentNgFactory };
