import { SignatureComponent } from './pages/signature/signature.component';
import { Routes } from '@angular/router';
import { LandingComponent } from './landing.component';
import { RegistrationComponent } from './pages/vacancy/registration/registration.component';
import { SkillComponent } from './pages/vacancy/skill/skill.component';
import { FormResetComponent } from './pages/form-reset/form-reset.component';
import { SimcardComponent } from './pages/simcard/simcard.component';
import { CategoryComponent } from './pages/blog/category/category.component';
import { PostComponent } from './pages/blog/post/post.component';
import { FaqComponent } from './pages/faq/faq.component';
import { FaqpostComponent } from './pages/faq/faqpost/faqpost.component';
import { SurveyComponent } from './pages/survey/survey.component';
import { DiscComponent } from './pages/disc/disc.component';
import { ExamComponent } from './pages/exam/exam.component';
import { WhistleBlowingComponent } from './pages/whistle-blowing/whistle-blowing.component';
import { TestComponent } from './pages/test/test.component';
import { AuthGuard } from 'src/app/auth/auth-guard.service';
import { KebijakanMutuComponent } from './pages/kebijakan-mutu/kebijakan-mutu.component';
import { WhatsappComponent } from './pages/whatsapp/whatsapp.component';
import { ApprovalComponent } from './pages/approval/approval.component';
import { Home5gComponent } from './theme5g/home5g/home5g.component';
import { Login5gComponent } from './theme5g/login5g/login5g.component';
import { Lostpassword5gComponent } from './theme5g/lostpassword5g/lostpassword5g.component';
import { About5gComponent } from './theme5g/about5g/about5g.component';
import { Gallery5gComponent } from './theme5g/gallery5g/gallery5g.component';
import { Client5gComponent } from './theme5g/client5g/client5g.component';
import { Blog5gComponent } from './theme5g/blog5g/blog5g.component';
import { Career5gComponent } from './theme5g/career5g/career5g.component';
import { VideoExamComponent } from './pages/video-exam/video-exam.component';
import { CvComponent } from './theme5g/cv/cv.component';
var ɵ0 = {
    permissions: {
        only: ['USER'],
        redirectTo: '/login'
    }
};
var routes = [
    {
        path: '',
        component: LandingComponent,
        children: [
            { path: '', redirectTo: 'home', pathMatch: 'full' },
            {
                path: 'home',
                component: Home5gComponent
            },
            {
                path: 'login',
                component: Login5gComponent
            },
            {
                path: 'cv',
                component: CvComponent
            },
            {
                path: 'forgot-password',
                component: Lostpassword5gComponent
            },
            {
                path: 'hp/:hash',
                component: WhatsappComponent
            },
            {
                path: 'reset-password/:token',
                component: FormResetComponent
            },
            {
                path: 'faq',
                component: FaqComponent
            },
            {
                path: 'faqpost/:hash',
                component: FaqpostComponent
            },
            {
                path: 'karir',
                component: Career5gComponent
            },
            {
                path: 'registration/:id',
                component: RegistrationComponent
            },
            {
                path: 'skill/:userid',
                component: SkillComponent
            },
            {
                path: 'simcard/:hash',
                component: SimcardComponent
            },
            {
                path: 'blog',
                component: Blog5gComponent
            },
            {
                path: 'blog-category/:slug',
                component: CategoryComponent
            },
            {
                path: 'blog-post/:slug',
                component: PostComponent
            },
            {
                path: 'survey/:voucher',
                component: SurveyComponent
            },
            {
                path: 'disc/:voucher',
                component: DiscComponent
            },
            {
                path: 'exam',
                component: ExamComponent
            },
            {
                path: 'whistle-blowing',
                component: WhistleBlowingComponent
            },
            {
                path: 'quality-policy',
                component: KebijakanMutuComponent
            },
            {
                path: 'test',
                component: TestComponent,
                canActivate: [AuthGuard],
                data: ɵ0
            },
            {
                path: 'approval',
                component: ApprovalComponent
            },
            {
                path: 'approval/:token',
                component: ApprovalComponent
            },
            {
                path: 'about',
                component: About5gComponent
            },
            {
                path: 'gallery',
                component: Gallery5gComponent
            },
            {
                path: 'client',
                component: Client5gComponent
            },
            {
                path: 'video-exam/:voucher',
                component: VideoExamComponent
            },
            {
                path: 'signature/:access_token',
                component: SignatureComponent
            },
        ]
    }
];
var LandingRoutingModule = /** @class */ (function () {
    function LandingRoutingModule() {
    }
    return LandingRoutingModule;
}());
export { LandingRoutingModule };
export { ɵ0 };
