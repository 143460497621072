import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { CookieService } from 'ngx-cookie-service';
import { ResponseApiModel } from '../_models/response-api.model';
import { GlobalService } from './global.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AlertModalComponent } from '../layout/shared/alert-modal/alert-modal.component';
import Swal from 'sweetalert2';

const API_GET_BANKSOAL = '/eLearning/banksoal/get-list-banksoal';
const API_GET_LIST_VIDEO_EXAM = '/eLearning/video-exam/get-list-video-exam';

const API_GET_EXAM = '/eLearning/exam/get-list-exam';
const API_GET_VIDEO_EXAMS = '/eLearning/video-exam/get-exams';
const API_GET_VIEW_EXAM = '/eLearning/exam/daftarsoal-exam';
const API_GET_VIEW_SOAL = '/eLearning/exam/daftarsoal';
const API_GET_ADD_EXAM = '/eLearning/exam/list-tambahsoal-exam';
const API_GET_PARTICIPANT = '/eLearning/exam/view-participants-exam';
const API_GET_USER = '/eLearning/exam/get-user-participants';
const API_ADD_BANKSOAL = '/eLearning/banksoal/add-banksoal';
const API_ADD_EXAM = '/eLearning/exam/add-exam';
const API_ADD_SOAL = '/eLearning/exam/add-soal-exam';
const API_ADD_PARTICIPANT = '/eLearning/exam/add-participants-exam';
const API_JAWAB = '/eLearning/exam/add-resultone-exam';
const API_JAWAB_LAST = '/eLearning/exam/add-resultlast-exam';
const API_EDIT_BANKSOAL = '/eLearning/banksoal/edit-banksoal';
const API_EDIT_EXAM = '/eLearning/exam/edit-exam';
const API_RESET_VOUCHER = '/eLearning/exam/regenerate-voucher-exam';
const API_CEK_VOUCHER = '/eLearning/exam/cek-voucher-exam';
const API_BEFORE_EXAM = '/eLearning/exam/view-before-startexam';
const API_DOING_EXAM = '/eLearning/exam/doing-exam';
const API_DELETE_BANKSOAL = '/eLearning/banksoal/delete-banksoal';
const API_DELETE_EXAM = '/eLearning/exam/delete-exam';
const API_DELETE_SOAL = '/eLearning/exam/hapussoal';
const API_DELETE_PARTICIPANT = '/eLearning/exam/delete-participants-exam';
const API_EXAM_RESULT = '/eLearning/exam/exam-result';
const API_EXAM_RESULT_BY_ID = '/eLearning/exam/exam-result-by-id';
const EXPORT_RESULT = '/eLearning/exam/export-result';
const API_SURVEY = '/eLearning/exam/survey';
const API_SURVEY_RESULT = '/eLearning/exam/survey-result';
const API_DISC = '/eLearning/exam/disc';
const API_DISC_RESULT = '/eLearning/exam/disc-result';
const API_ADD_SOAL_NEW = '/eLearning/exam/add-soal-new';
const API_ADD_SOAL_PRE_POST = '/eLearning/exam/add-soal-pre-post';
const API_LIST_IMPORT = '/eLearning/exam/get-ujian-except';
const API_GET_IMPORT = '/eLearning/exam/get-detail-section';

const API_LIST_TRAINING = '/eLearning/training/get-list-training';
const API_DELETE_TRAINING = '/eLearning/training/delete-training';
const API_PREPARE_CREATE_TRAINING = '/eLearning/training/data-needed';
const API_GET_DATA = '/eLearning/training/get-data';
const API_ADD_TRAINING = '/eLearning/training/add-data';
const API_EDIT_TRAINING = '/eLearning/training/edit-data';
const API_VIEW_DETAIL = '/eLearning/training/view-detail';
const API_PROCESS_APPROVAL = '/eLearning/training/process-approval';
const API_PROCESS_VOTE = '/eLearning/training/process-vote';
const API_VIEW_VIDEO_EXAM = '/eLearning/video-exam/view';
const API_ADD_VIDEO_EXAM = '/eLearning/video-exam/add';
@Injectable()
export class ELearningService {

  constructor(
    private http: HttpClient,
    private cookieService: CookieService,
    private globalService: GlobalService,
    private modalService: NgbModal,
  ) { }

  alertModal(err: string, code?: number) {
    if (code === 403) {
      return Swal.fire(err);
    }
    if (code === 200) {
      return Swal.fire(err);
    }
    const modalRef = this.modalService.open(AlertModalComponent);
    modalRef.componentInstance.kategori = 'alert';
    modalRef.componentInstance.error = (err) ? err : 'Request Timeout.';
  }

  public header() {
    return {
      headers: new HttpHeaders()
        .set('token', this.cookieService.get('_q'))
        .set('module', localStorage.getItem('eLearning'))
    };
  }

  public getBankSoal(param: { pageSize: number, page: number, filter: any }): Observable<ResponseApiModel> {
    return this.http.post<ResponseApiModel>(
      this.globalService.apiVersionHost + API_GET_BANKSOAL, param, this.header()
    ).pipe(
      map(response => response),
      catchError(err => {
        return throwError(err);
      })
    );
  }

  public getListVideoExam(param: { pageSize: number, page: number, filter: any }): Observable<ResponseApiModel> {
    return this.http.post<ResponseApiModel>(
      this.globalService.apiVersionHost + API_GET_LIST_VIDEO_EXAM, param, this.header()
    ).pipe(
      map(response => response),
      catchError(err => {
        return throwError(err);
      })
    );
  }

  public getExam(param: { pageSize: number, page: number, filter: any }): Observable<ResponseApiModel> {
    return this.http.post<ResponseApiModel>(
      this.globalService.apiVersionHost + API_GET_EXAM, param, this.header()
    ).pipe(
      map(response => response),
      catchError(err => {
        return throwError(err);
      })
    );
  }

  public getVideoExams(param: { pageSize: number, page: number, filter: any }): Observable<ResponseApiModel> {
    return this.http.post<ResponseApiModel>(
      this.globalService.apiVersionHost + API_GET_VIDEO_EXAMS, param, this.header()
    ).pipe(
      map(response => response),
      catchError(err => {
        return throwError(err);
      })
    );
  }

  public getViewExam(param: { id: number }): Observable<ResponseApiModel> {
    return this.http.post<ResponseApiModel>(
      this.globalService.apiVersionHost + API_GET_VIEW_EXAM, param, this.header()
    ).pipe(
      map(response => response),
      catchError(err => {
        return throwError(err);
      })
    );
  }

  public getViewPretest(param: { id: number, remark:any }): Observable<ResponseApiModel> {
    return this.http.post<ResponseApiModel>(
      this.globalService.apiVersionHost + API_GET_VIEW_SOAL, param, this.header()
    ).pipe(
      map(response => response),
      catchError(err => {
        return throwError(err);
      })
    );
  }

  public getViewPosttest(param: { id: number, remark:any }): Observable<ResponseApiModel> {
    return this.http.post<ResponseApiModel>(
      this.globalService.apiVersionHost + API_GET_VIEW_SOAL, param, this.header()
    ).pipe(
      map(response => response),
      catchError(err => {
        return throwError(err);
      })
    );
  }

  public getAddExam(param: { pageSize: number, page: number, filter: any, id: number }): Observable<ResponseApiModel> {
    return this.http.post<ResponseApiModel>(
      this.globalService.apiVersionHost + API_GET_ADD_EXAM, param, this.header()
    ).pipe(
      map(response => response),
      catchError(err => {
        return throwError(err);
      })
    );
  }
  public getParticipant(param: { pageSize: number, page: number, filter: any, id: number }): Observable<ResponseApiModel> {
    return this.http.post<ResponseApiModel>(
      this.globalService.apiVersionHost + API_GET_PARTICIPANT, param, this.header()
    ).pipe(
      map(response => response),
      catchError(err => {
        return throwError(err);
      })
    );
  }
  public getUser(): Observable<ResponseApiModel> {
    return this.http.get<ResponseApiModel>(
      this.globalService.apiVersionHost + API_GET_USER, this.header()
    ).pipe(
      map(response => response),
      catchError(err => {
        return throwError(err);
      })
    );
  }

  public addBankSoal(param): Observable<ResponseApiModel> {
    return this.http.post<ResponseApiModel>(
      this.globalService.apiVersionHost + API_ADD_BANKSOAL, param, this.header()
    ).pipe(
      map(response => response),
      catchError(err => {
        return throwError(err);
      })
    );
  }

  public addExam(param): Observable<ResponseApiModel> {
    return this.http.post<ResponseApiModel>(
      this.globalService.apiVersionHost + API_ADD_EXAM, param, this.header()
    ).pipe(
      map(response => response),
      catchError(err => {
        return throwError(err);
      })
    );
  }

  public addVideoExam(param): Observable<ResponseApiModel> {
    return this.http.post<ResponseApiModel>(
      this.globalService.apiVersionHost + API_ADD_VIDEO_EXAM, param, this.header()
    ).pipe(
      map(response => response),
      catchError(err => {
        return throwError(err);
      })
    );
  }

  public addSoal(param: { idUjian: number, idSoal: number }): Observable<ResponseApiModel> {
    return this.http.post<ResponseApiModel>(
      this.globalService.apiVersionHost + API_ADD_SOAL, param, this.header()
    ).pipe(
      map(response => response),
      catchError(err => {
        return throwError(err);
      })
    );
  }
  public addParticipant(param): Observable<ResponseApiModel> {
    return this.http.post<ResponseApiModel>(
      this.globalService.apiVersionHost + API_ADD_PARTICIPANT, param, this.header()
    ).pipe(
      map(response => response),
      catchError(err => {
        return throwError(err);
      })
    );
  }
  public saveOneAnswer(param): Observable<ResponseApiModel> {
    return this.http.post<ResponseApiModel>(
      this.globalService.apiVersionHost + API_JAWAB, param, this.header()
    ).pipe(
      map(response => response),
      catchError(err => {
        return throwError(err);
      })
    );
  }

  public saveLastAnswer(param): Observable<ResponseApiModel> {
    return this.http.post<ResponseApiModel>(
      this.globalService.apiVersionHost + API_JAWAB_LAST, param, this.header()
    ).pipe(
      map(response => response),
      catchError(err => {
        return throwError(err);
      })
    );
  }

  public editBankSoal(param): Observable<ResponseApiModel> {
    return this.http.post<ResponseApiModel>(
      this.globalService.apiVersionHost + API_EDIT_BANKSOAL, param, this.header()
    ).pipe(
      map(response => response),
      catchError(err => {
        return throwError(err);
      })
    );
  }
  public editExam(param): Observable<ResponseApiModel> {
    return this.http.post<ResponseApiModel>(
      this.globalService.apiVersionHost + API_EDIT_EXAM, param, this.header()
    ).pipe(
      map(response => response),
      catchError(err => {
        return throwError(err);
      })
    );
  }
  public resetVoucher(param): Observable<ResponseApiModel> {
    return this.http.post<ResponseApiModel>(
      this.globalService.apiVersionHost + API_RESET_VOUCHER, param, this.header()
    ).pipe(
      map(response => response),
      catchError(err => {
        return throwError(err);
      })
    );
  }
  public cekVoucher(voucher): Observable<ResponseApiModel> {
    return this.http.post<ResponseApiModel>(
      this.globalService.apiVersionHost + API_CEK_VOUCHER, { voucher }, this.header()
    ).pipe(
      map(response => response),
      catchError(err => {
        return throwError(err);
      })
    );
  }
  public beforeExam(voucher): Observable<ResponseApiModel> {
    return this.http.post<ResponseApiModel>(
      this.globalService.apiVersionHost + API_BEFORE_EXAM, { voucher }, this.header()
    ).pipe(
      map(response => response),
      catchError(err => {
        return throwError(err);
      })
    );
  }
  public doingExam(voucher): Observable<ResponseApiModel> {
    return this.http.post<ResponseApiModel>(
      this.globalService.apiVersionHost + API_DOING_EXAM, { voucher }, this.header()
    ).pipe(
      map(response => response),
      catchError(err => {
        return throwError(err);
      })
    );
  }

  public deleteBankSoal(id): Observable<ResponseApiModel> {
    return this.http.post<ResponseApiModel>(
      this.globalService.apiVersionHost + API_DELETE_BANKSOAL, { id }, this.header()
    ).pipe(
      map(response => response),
      catchError(err => {
        return throwError(err);
      })
    );
  }
  public deleteExam(id): Observable<ResponseApiModel> {
    return this.http.post<ResponseApiModel>(
      this.globalService.apiVersionHost + API_DELETE_EXAM, { id }, this.header()
    ).pipe(
      map(response => response),
      catchError(err => {
        return throwError(err);
      })
    );
  }
  public deleteSoal(param: { idUjian: number, idSoal: number }): Observable<ResponseApiModel> {
    return this.http.post<ResponseApiModel>(
      this.globalService.apiVersionHost + API_DELETE_SOAL, param, this.header()
    ).pipe(
      map(response => response),
      catchError(err => {
        return throwError(err);
      })
    );
  }
  public deleteParticipant(id): Observable<ResponseApiModel> {
    return this.http.post<ResponseApiModel>(
      this.globalService.apiVersionHost + API_DELETE_PARTICIPANT, { id }, this.header()
    ).pipe(
      map(response => response),
      catchError(err => {
        return throwError(err);
      })
    );
  }
  public getExamResult(param: { pageSize: number, page: number, filter: any }): Observable<ResponseApiModel> {
    return this.http.post<ResponseApiModel>(
      this.globalService.apiVersionHost + API_EXAM_RESULT, param, this.header()
    ).pipe(
      map(response => response),
      catchError(err => {
        return throwError(err);
      })
    );
  }
  public getExamResultById(param: { id:number, pageSize: number, page: number, filter: any }): Observable<ResponseApiModel> {
    return this.http.post<ResponseApiModel>(
      this.globalService.apiVersionHost + API_EXAM_RESULT_BY_ID, param, this.header()
    ).pipe(
      map(response => response),
      catchError(err => {
        return throwError(err);
      })
    );
  }
  public exportResult(param: { id:number, pageSize: number, page: number, filter: any }): Observable<ResponseApiModel> {
    return this.http.post<ResponseApiModel>(
      this.globalService.apiVersionHost + EXPORT_RESULT, param, this.header()
    ).pipe(
      map(response => response),
      catchError(err => {
        return throwError(err);
      })
    );
  }
  public getSurvey(param: { pageSize: number, page: number, filter: any }): Observable<ResponseApiModel> {
    return this.http.post<ResponseApiModel>(
      this.globalService.apiVersionHost + API_SURVEY, param, this.header()
    ).pipe(
      map(response => response),
      catchError(err => {
        return throwError(err);
      })
    );
  }
  public getSurveyResult(param: { id: number }): Observable<ResponseApiModel> {
    return this.http.post<ResponseApiModel>(
      this.globalService.apiVersionHost + API_SURVEY_RESULT, param, this.header()
    ).pipe(
      map(response => response),
      catchError(err => {
        return throwError(err);
      })
    );
  }
  public getDisc(param: { pageSize: number, page: number, filter: any }): Observable<ResponseApiModel> {
    return this.http.post<ResponseApiModel>(
      this.globalService.apiVersionHost + API_DISC, param, this.header()
    ).pipe(
      map(response => response),
      catchError(err => {
        return throwError(err);
      })
    );
  }
  public getDiscResult(param: { id: number }): Observable<ResponseApiModel> {
    return this.http.post<ResponseApiModel>(
      this.globalService.apiVersionHost + API_DISC_RESULT, param, this.header()
    ).pipe(
      map(response => response),
      catchError(err => {
        return throwError(err);
      })
    );
  }
  public addSoalNew(param: { id_ujian: number, section: any }): Observable<ResponseApiModel> {
    return this.http.post<ResponseApiModel>(
      this.globalService.apiVersionHost + API_ADD_SOAL_NEW, param, this.header()
    ).pipe(
      map(response => response),
      catchError(err => {
        return throwError(err);
      })
    );
  }
  public addSoalPrePost(param: { id_ujian: number, section: any, remark: any }): Observable<ResponseApiModel> {
    return this.http.post<ResponseApiModel>(
      this.globalService.apiVersionHost + API_ADD_SOAL_PRE_POST, param, this.header()
    ).pipe(
      map(response => response),
      catchError(err => {
        return throwError(err);
      })
    );
  }

  public getListImport(param: { id_ujian: number, filter: any }): Observable<ResponseApiModel> {
    return this.http.post<ResponseApiModel>(
      this.globalService.apiVersionHost + API_LIST_IMPORT, param, this.header()
    ).pipe(
      map(response => response),
      catchError(err => {
        return throwError(err);
      })
    );
  }
  public getDataImport(param: { id_ujian: number }): Observable<ResponseApiModel> {
    return this.http.post<ResponseApiModel>(
      this.globalService.apiVersionHost + API_GET_IMPORT, param, this.header()
    ).pipe(
      map(response => response),
      catchError(err => {
        return throwError(err);
      })
    );
  }

  public getListTraining(param: { pageSize: number, page: number, filter: any }): Observable<ResponseApiModel> {
    return this.http.post<ResponseApiModel>(
      this.globalService.apiVersionHost + API_LIST_TRAINING, param, this.header()
    ).pipe(
      map(response => response),
      catchError(err => {
        return throwError(err);
      })
    );
  }
  public deleteTraining(param): Observable<ResponseApiModel> {
    return this.http.post<ResponseApiModel>(
      this.globalService.apiVersionHost + API_DELETE_TRAINING, param, this.header()
    ).pipe(
      map(response => response)
    );
  }
  public dataNeeded(): Observable<ResponseApiModel> {
    return this.http.post<ResponseApiModel>(
      this.globalService.apiVersionHost + API_PREPARE_CREATE_TRAINING, null, this.header()
    ).pipe(
      map(response => response)
    );
  }
  public getCurrentData(param: { id: number }): Observable<ResponseApiModel> {
    return this.http.post<ResponseApiModel>(
      this.globalService.apiVersionHost + API_GET_DATA, param, this.header()
    ).pipe(
      map(response => response)
    );
  }
  public addTraining(param): Observable<ResponseApiModel> {
    return this.http.post<ResponseApiModel>(
      this.globalService.apiVersionHost + API_ADD_TRAINING, param, this.header()
    ).pipe(
      map(response => response)
    );
  }

  public editTraining(param): Observable<ResponseApiModel> {
    return this.http.post<ResponseApiModel>(
      this.globalService.apiVersionHost + API_EDIT_TRAINING, param, this.header()
    ).pipe(
      map(response => response)
    );
  }

  public getViewDetail(param): Observable<ResponseApiModel> {
    return this.http.post<ResponseApiModel>(
      this.globalService.apiVersionHost + API_VIEW_DETAIL, param, this.header()
    ).pipe(
      map(response => response),
      catchError(err => {
        return throwError(err);
      })
    );
  }

  public processApproval(param): Observable<ResponseApiModel> {
    return this.http.post<ResponseApiModel>(
      this.globalService.apiVersionHost + API_PROCESS_APPROVAL, param, this.header()
    ).pipe(
      map(response => response)
    );
  }

  public processVote(param): Observable<ResponseApiModel> {
    return this.http.post<ResponseApiModel>(
      this.globalService.apiVersionHost + API_PROCESS_VOTE, param, this.header()
    ).pipe(
      map(response => response)
    );
  }

  public viewVideoExam(id: number): Observable<ResponseApiModel> {
    return this.http.post<ResponseApiModel>(
      this.globalService.apiVersionHost + API_VIEW_VIDEO_EXAM, { id }, this.header()
    ).pipe(
      map(response => response)
    );
  }
}
