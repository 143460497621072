import { HttpClient, HttpHeaders } from '@angular/common/http';
import { GlobalService } from './global.service';
import { Observable, throwError, Subject } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { CookieService } from 'ngx-cookie-service';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "./global.service";
import * as i3 from "ngx-cookie-service";
var API_GET_DATA_PERSONAL = '/employeeProfile/profile/get-data-personal';
var API_GET_DATA_ASSIGNMENT = '/employeeProfile/profile/get-data-assignment';
var API_GET_DATA_PAYSLIP = '/employeeProfile/profile/get-data-payslip';
var API_GET_DATA_IDENTITY = '/employeeProfile/profile/get-data-identity';
var API_GET_DATA_RECRUITMENT = '/employeeProfile/profile/get-data-recruitment';
var API_GET_DATA_SYSTEM_INFO = '/employeeProfile/profile/get-data-system-info';
var API_GET_DATA_FAMILY_INFO = '/employeeProfile/profile/get-data-family-info';
var API_GET_DATA_EMERGENCY_CONTACT = '/employeeProfile/profile/get-data-emergency-contact';
var API_GET_DATA_ASSET = '/employeeProfile/profile/get-data-asset';
var API_GET_DATA_PROJECT_CODE = '/employeeProfile/profile/get-data-project-code';
var API_GET_DATA_SKILLS = '/employeeProfile/profile/get-data-skills';
var API_GET_DATA_TRAINING = '/employeeProfile/profile/get-data-training';
var API_GET_DATA_EXAMS = '/employeeProfile/profile/get-data-exams';
var API_GET_DATA_CERTIFICATE = '/employeeProfile/profile/get-data-certificate';
var API_SAVE_ATTACHMENT = '/employeeProfile/profile/save-attachment';
var API_SAVE_KERABAT = '/employeeProfile/profile/add-sibling';
var API_DELETE_SIBLING = '/employeeProfile/profile/delete-sibling';
var ProfileService = /** @class */ (function () {
    function ProfileService(http, globalService, cookieService) {
        this.http = http;
        this.globalService = globalService;
        this.cookieService = cookieService;
        this.mobileToggleEmit = new Subject();
        this.mobileToggleEmitObs = this.mobileToggleEmit.asObservable();
        this.mobileToggleEmit.next(false);
    }
    ProfileService.prototype.header = function () {
        return {
            headers: new HttpHeaders()
                .set('token', this.cookieService.get('_q'))
                .set('module', localStorage.getItem('employeeProfile'))
        };
    };
    ProfileService.prototype.getDataPersonal = function () {
        return this.http.post(this.globalService.apiVersionHost + API_GET_DATA_PERSONAL, null, this.header()).pipe(map(function (response) { return response; }));
    };
    ProfileService.prototype.getDataFamilyInfo = function (param) {
        return this.http.post(this.globalService.apiVersionHost + API_GET_DATA_FAMILY_INFO, param, this.header()).pipe(map(function (response) { return response; }));
    };
    ProfileService.prototype.getDataEmergencyContact = function (param) {
        return this.http.post(this.globalService.apiVersionHost + API_GET_DATA_EMERGENCY_CONTACT, param, this.header()).pipe(map(function (response) { return response; }));
    };
    ProfileService.prototype.getDataAsset = function (param) {
        return this.http.post(this.globalService.apiVersionHost + API_GET_DATA_ASSET, param, this.header()).pipe(map(function (response) { return response; }));
    };
    ProfileService.prototype.getDataProjectCode = function (param) {
        return this.http.post(this.globalService.apiVersionHost + API_GET_DATA_PROJECT_CODE, param, this.header()).pipe(map(function (response) { return response; }));
    };
    ProfileService.prototype.getDataSkills = function (param) {
        return this.http.post(this.globalService.apiVersionHost + API_GET_DATA_SKILLS, param, this.header()).pipe(map(function (response) { return response; }));
    };
    ProfileService.prototype.getDataTraining = function (param) {
        return this.http.post(this.globalService.apiVersionHost + API_GET_DATA_TRAINING, param, this.header()).pipe(map(function (response) { return response; }));
    };
    ProfileService.prototype.getDataExams = function (param) {
        return this.http.post(this.globalService.apiVersionHost + API_GET_DATA_EXAMS, param, this.header()).pipe(map(function (response) { return response; }));
    };
    ProfileService.prototype.getDataCertificate = function (param) {
        return this.http.post(this.globalService.apiVersionHost + API_GET_DATA_CERTIFICATE, param, this.header()).pipe(map(function (response) { return response; }));
    };
    ProfileService.prototype.saveAttachment = function (param) {
        return this.http.post(this.globalService.apiVersionHost + API_GET_DATA_CERTIFICATE, param, this.header()).pipe(map(function (response) { return response; }));
    };
    ProfileService.prototype.saveKerabat = function (param) {
        return this.http.post(this.globalService.apiVersionHost + API_SAVE_KERABAT, param, this.header()).pipe(map(function (response) { return response; }), catchError(function (err) {
            return throwError(err);
        }));
    };
    ProfileService.prototype.deleteApplication = function (param) {
        return this.http.post(this.globalService.apiVersionHost + API_DELETE_SIBLING, param, this.header()).pipe(map(function (response) { return response; }), catchError(function (err) {
            return throwError(err);
        }));
    };
    ProfileService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ProfileService_Factory() { return new ProfileService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.GlobalService), i0.ɵɵinject(i3.CookieService)); }, token: ProfileService, providedIn: "root" });
    return ProfileService;
}());
export { ProfileService };
