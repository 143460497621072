<div class="kt-grid kt-grid--ver kt-grid--root full-height">
  <div class="kt-grid__item kt-grid__item--fluid kt-grid  kt-error-v5"
    style="background-image: url(./assets/media//error/bg5.jpg);">
    <div class="kt-error_container">
      <span class="kt-error_title">
        <h1>Oops!</h1>
      </span>
      <p class="kt-error_subtitle">
        You dont have permission to access this page.
      </p>
      <p class="kt-error_description">
        Please contact NDS Customer Service <br> 
        via email [dev.nexwave@gmail.com] <br>
        for more information.
      </p>
    </div>
  </div>
</div>