import { Component, OnInit } from '@angular/core';
import { EmployeeService } from 'src/app/_services/employee.service';
import Swal from 'sweetalert2';
import { Router } from '@angular/router';
import { AuthenticationService } from 'src/app/auth/authentication.service';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit {
  permissionLoaded: boolean = false;
  oldPass: string;
  newPass: string;
  newPass1: string;
  eye1: string = 'fa fa-eye text-secondary';
  type1: string = 'password';
  eye2: string = 'fa fa-eye text-secondary';
  type2: string = 'password';
  eye3: string = 'fa fa-eye text-secondary';
  type3: string = 'password';
  public isSubmited = false;
  formForgot = false;
  forgot_form: any = { username: '' };
  constructor(
    private router: Router,
    private empService: EmployeeService,
    public authService: AuthenticationService,
  ) {
    let roles2 = this.authService.getUserRoles('employeeProfile');
    if (roles2) {
      this.authService.loadPermission(roles2);
      this.permissionLoaded = true;
    }
    else {
      this.authService.getRolesToken({ module: 'employeeProfile' }).subscribe(
        response => {
          if (response.status) {
            let roles2 = this.authService.getUserRoles('employeeProfile');
            if (roles2) {
              this.authService.loadPermission(roles2);
              this.permissionLoaded = true;
            }
          }
        }
      );
    }

    Swal.fire({
      title: 'Keamanan Sistem',
      type: 'info',
      text: 'Password Anda telah kadarluasa, demi keamanan sistem silahkan perbarui password Anda.'
    });
  }

  ngOnInit() {

  }

  showText(x) {
    if (x == 'old-password') {
      if (this.type1 == 'password') {
        this.type1 = 'text';
        this.eye1 = 'fa fa-eye text-primary';
      } else {
        this.type1 = 'password';
        this.eye1 = 'fa fa-eye text-secondary';
      }
    } else if (x == 'new-password') {
      if (this.type2 == 'password') {
        this.type2 = 'text';
        this.eye2 = 'fa fa-eye text-primary';
      } else {
        this.type2 = 'password';
        this.eye2 = 'fa fa-eye text-secondary';
      }
    } else if (x == 'new1-password') {
      if (this.type3 == 'password') {
        this.type3 = 'text';
        this.eye3 = 'fa fa-eye text-primary';
      } else {
        this.type3 = 'password';
        this.eye3 = 'fa fa-eye text-secondary';
      }
    }
  }
  savePassword() {
    let param = {
      curPass: this.oldPass,
      newPass: this.newPass,
      newPass1: this.newPass1
    }
    
    this.empService.changePassword(param).subscribe(
      response => {
        if (response.status) {
          Swal.fire(response.result).then((result) => {
            this.router.navigate(['/main/pm']);
          });
        } else {
          Swal.fire({
            title: 'Change Password',
            type: 'info',
            text: response.message
          });
        }
      }
    );
  }

  submitForgot(forgotForm: NgForm) {
    this.isSubmited = true;
    if (this.validate(forgotForm)) {
      this.authService.forgot(this.forgot_form).subscribe(response => {
        this.isSubmited = false;
        if (response.status) {
          const res = response.result;
          let text = '';
          if (res.repeat) {
            text = 'Permintaan forgot password sebelumnya telah terkirim ke email <b style="color: #DC143C;">' + res.email + '</b>. Apabila tidak terdapat pada email masuk utama Anda, silahkan cek pada email spam. Token hanya berlaku selama 10 menit, silahkan request forgot password kembali jika token telah expired.';
          } else {
            text = 'Kode Verifikasi telah dikirim ke email <b style="color: #DC143C;">' + res.email + '</b>. Mohon cek email Anda. Apabila tidak terdapat pada email masuk utama Anda, silahkan cek pada email spam. Token hanya berlaku selama 10 menit, silahkan request forgot password kembali jika token telah expired.';
          }
          Swal.fire({
            title: 'Forgot Password',
            type: 'success',
            html: text
          });
          this.formForgot = false;
          return;
        }
        Swal.fire(response.message);
      }, (error: any) => {
        Swal.fire(error.error.message);
        this.isSubmited = false;
      });
    } else {
      this.isSubmited = false;
    }
  }

  validate(Form: NgForm) {
    if (Form.form.status === 'VALID') {
      return true;
    } else {
      return false;
    }
  }
}
