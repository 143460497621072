import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxPermissionsService } from 'ngx-permissions';
import { AuthenticationService } from 'src/app/auth/authentication.service';
import { UserService } from 'src/app/_services/user.service';
import Swal from 'sweetalert2';
import Fingerprint2 from '@fingerprintjs/fingerprintjs';
import { Title } from '@angular/platform-browser';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-login5g',
  templateUrl: './login5g.component.html',
  styleUrls: ['./login5g.component.scss']
})
export class Login5gComponent implements OnInit {

  public login_form: any = { username: '', password: '', fingerprint: '' };
  public isSubmited: boolean = false;
  public returnUrl: string;

  isLoad: boolean = false;
  isLoadError: boolean = false;

  botName = "ptnw2_bot";
  telegramAccount = "";
  telegramId = "";
  telegramUsername = "";
  telegramPtnwUsername = "";
  telegramPtnwPassword = "";
  telegramModal: any = null;

  constructor(
    private authService: AuthenticationService,
    private router: Router,
    private route: ActivatedRoute,
    private permissionsService: NgxPermissionsService,
    private userService: UserService,
    private title: Title,
    private ngxLoader: NgxUiLoaderService,
    private modalService: NgbModal
  ) { }

  ngOnInit() {
    this.title.setTitle('Login | PT NexWave');

    this.authService.isAuthorized().subscribe(response => {
      if (response) {
        this.router.navigate(['/main/pm']);
      }
    });

    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/main/pm';

    Fingerprint2.get((components) => {
      let values = components.map(function (component) { return component.value });
      this.login_form.fingerprint = Fingerprint2.x64hash128(values.join(''), 31);
    });
  }

  validate(Form: NgForm) {
    if (Form.form.status === 'VALID') {
      return true;
    } else {
      return false;
    }
  }

  submit(loginForm: NgForm) {
    this.isSubmited = true;
    if (this.validate(loginForm)) {
      this.ngxLoader.startLoader('login-loader');
      this.authService.login(this.login_form).subscribe(response => {
        this.ngxLoader.stopLoader('login-loader');
        if (response.status) {
          const group: any[] = this.userService.getUserGroup();
          group.push('USER');
          this.permissionsService.loadPermissions(group);

          if (this.userService.getUsername() == 'PTNW0385') {
            this.router.navigateByUrl('/new/home');
          }
          else if (response.result.expired_password === 1) {
            this.router.navigateByUrl('/change-password');
          }
          else {
            this.router.navigateByUrl(this.returnUrl);
          }

        } else {
          Swal.fire('User Login', response.message, 'warning');
          this.isSubmited = false;
        }
      }, (error: any) => {
        this.ngxLoader.stopLoader('login-loader');
        Swal.fire('User Login', error.message, 'error');
        this.isSubmited = false;
      });
    } else {
      this.isSubmited = false;
    }
  }

  onLoad() {
    this.isLoad = true;
  }

  onLoadError() {
    this.isLoadError = true;
  }

  onLogin(user: any) {
    console.log(user);

    this.telegramId = user.id;
    this.telegramUsername = user.username;
    this.telegramAccount = user.first_name + ' ' + user.last_name;
    if (user.username.length > 0) {
      this.telegramAccount += ' (@' + user.username + ')';
    }

    this.isSubmited = true;
    this.ngxLoader.startLoader('login-loader');

    this.userService.telegramLogin(user).subscribe(response => {
      this.ngxLoader.stopLoader('login-loader');
      this.isSubmited = false;

      if (response.status) {

        this.userService
          .setAccessToken(response.result._q)
          .setRefreshToken(response.result._w)
          .setGroupToken(response.result._g);

        const group: any[] = this.userService.getUserGroup();
        group.push('USER');
        this.permissionsService.loadPermissions(group);
        this.router.navigate(['/main/pm']);
      } else {
        if (response.message == 'User not found') {
          setTimeout(function () {
            document.getElementById('tg-btn').click();
          }, 1000);
        } else {
          Swal.fire(response.message);
        }
      }
    }, error => {
      this.ngxLoader.stopLoader('login-loader');
      this.isSubmited = false;
      console.error(error);
    });
  }

  open(content) {
    this.telegramModal = this.modalService.open(content, {});
    this.telegramModal.result.then((result) => {
      console.log(`Closed with: ${result}`);
    }, (reason) => {
      console.log(`Dismissed`);
    });
  }

  linkTelegram() {
    this.isSubmited = true;
    this.ngxLoader.startLoader('telegram-loader');

    const params = {
      id_telegram: this.telegramId,
      username_telegram: this.telegramUsername,
      username: this.telegramPtnwUsername,
      password: this.telegramPtnwPassword
    };

    this.userService.linkTelegram(params).subscribe(response => {

      if (response.status) {

        this.userService
          .setAccessToken(response.result._q)
          .setRefreshToken(response.result._w)
          .setGroupToken(response.result._g);

        const group: any[] = this.userService.getUserGroup();
        group.push('USER');
        this.permissionsService.loadPermissions(group);
        this.router.navigate(['/dashboard']);
        this.telegramModal.close();
      } else {
        this.ngxLoader.stopLoader('telegram-loader');
        this.isSubmited = false;

        if (response.message == 'User not found') {
          document.getElementById('tg-btn').click();
        } else {
          document.getElementById('error-message').innerText = response.message;
        }
      }
    }, error => {
      this.telegramModal.close();
      this.ngxLoader.stopLoader('telegram-loader');
      this.isSubmited = false;
      console.error(error);
    });
  }

}
