import * as tslib_1 from "tslib";
import { TemplateRef } from '@angular/core';
import * as i0 from "@angular/core";
var ToastService = /** @class */ (function () {
    function ToastService() {
        this.toasts = [];
    }
    ToastService.prototype.show = function (textOrTpl, options) {
        if (options === void 0) { options = {}; }
        this.toasts.push(tslib_1.__assign({ textOrTpl: textOrTpl }, options));
    };
    ToastService.prototype.remove = function (toast) {
        this.toasts = this.toasts.filter(function (t) { return t.textOrTpl !== toast; });
    };
    ToastService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ToastService_Factory() { return new ToastService(); }, token: ToastService, providedIn: "root" });
    return ToastService;
}());
export { ToastService };
