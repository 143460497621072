<div class="kt-content kt-grid__item kt-grid__item--fluid" id="kt_content">
  <div class="row">
    <div class="col-12 mb-5">&nbsp;</div>
  </div>
  <div class="row justify-content-center pt-5 pb-2 ">
    <ngx-ui-loader [fgsType]="'three-bounce'" [hasProgressBar]="false" [text]="'Please Wait...'"
      [loaderId]="'menu-loader'"></ngx-ui-loader>
    <ng-container *ngIf="currentUser.user_ptnw_status == 'Internal'">
      <div class="col-lg-11">
        <div class="row justify-content-center search-icons">
          <ng-container *ngFor="let menu of menuList">
            <ng-container *ngIf="menu.user_ptnw_status == 'Internal'">
              <div class="col-4 col-md-2 text-center mb-5 pb-4">
                <ng-container *ngIf="cekLink(menu.module_key) ">
                  <a href="{{menu.url}}" class="icon-item rounded rounded-circle mx-auto d-block bg-white shadow mb-3">
                    <div [innerHtml]="menu.icon_svg | safeHtml" class="search-icons"></div>
                  </a>
                  <a href="{{menu.url}}" class=" text-white">
                    {{menu.menu_label}}
                  </a>
                </ng-container>
                <ng-container *ngIf="!cekLink(menu.module_key) ">

                  <ng-container *ngIf="menu.menu_label == 'SIP' else bukanSip">
                    <a href="{{menu.url}}"
                      class="icon-item rounded rounded-circle mx-auto d-block bg-white shadow mb-3">
                      <div [innerHtml]="menu.icon_svg | safeHtml" class="search-icons"></div>
                    </a>
                    <a href="{{menu.url}}" class=" text-white">
                      {{menu.menu_label}}
                    </a>
                  </ng-container>
                  <ng-template #bukanSip>
                    <ng-container *ngIf="menu.menu_label == 'PO Request Management' else other">
                      <ng-container *ngIf="userService.isInGroup('Project Support Subcon Huawei') else myRequestRoute">
                        <a [routerLink]="[menu.url]"
                          class="icon-item rounded rounded-circle mx-auto d-block bg-white shadow mb-3">
                          <div [innerHtml]="menu.icon_svg | safeHtml" class="search-icons"></div>
                        </a>
                        <a [routerLink]="[menu.url]" class=" text-white">
                          {{menu.menu_label}}
                        </a>
                      </ng-container>
                      <ng-template #myRequestRoute>
                        <a [routerLink]="['/main/pr/my-request/index']"
                          class="icon-item rounded rounded-circle mx-auto d-block bg-white shadow mb-3">
                          <div [innerHtml]="menu.icon_svg | safeHtml" class="search-icons"></div>
                        </a>
                        <a [routerLink]="['/main/pr/my-request/index']" class=" text-white">
                          {{menu.menu_label}}
                        </a>
                      </ng-template>
                    </ng-container>
                    <ng-template #other>
                      <ng-container
                        *ngIf="(menu.menu_label !== 'Blog Management' && menu.menu_label !== 'FAQ' && menu.menu_label !== 'Documentation') else info">
                        <a [routerLink]="[menu.url]"
                          class="icon-item rounded rounded-circle mx-auto d-block bg-white shadow mb-3">
                          <div [innerHtml]="menu.icon_svg | safeHtml" class="search-icons"></div>
                        </a>
                        <a [routerLink]="[menu.url]" class=" text-white">
                          {{menu.menu_label}}
                        </a>
                      </ng-container>
                      <ng-template #info>
                        <a [href]="infoDomain + menu.url"
                          class="icon-item rounded rounded-circle mx-auto d-block bg-white shadow mb-3">
                          <div [innerHtml]="menu.icon_svg | safeHtml" class="search-icons"></div>
                        </a>
                        <a [href]="infoDomain + menu.url" class=" text-white">
                          {{menu.menu_label}}
                        </a>
                      </ng-template>
                    </ng-template>
                  </ng-template>

                </ng-container>
              </div>
            </ng-container>
          </ng-container>
        </div>
      </div>
    </ng-container>
    <ng-container *ngIf="currentUser.user_ptnw_status == 'External'">
      <div class="col-lg-11">
        <div class="row justify-content-center search-icons">
          <ng-container *ngFor="let menu of menuList">
            <ng-container *ngIf="menu.user_ptnw_status == 'External'">
              <div class="col-4 col-md-2 text-center mb-5 pb-4">
                <ng-container *ngIf="cekLink(menu.module_key) ">
                  <a href="{{menu.url}}" class="icon-item rounded rounded-circle mx-auto d-block bg-white shadow mb-3">
                    <div [innerHtml]="menu.icon_svg | safeHtml" class="search-icons"></div>
                  </a>
                  <a href="{{menu.url}}" class=" text-white">
                    {{menu.menu_label}}
                  </a>
                </ng-container>
                <ng-container *ngIf="!cekLink(menu.module_key) ">

                  <ng-container *ngIf="menu.menu_label == 'SIP' else bukanSip">
                    <a href="{{menu.url}}"
                      class="icon-item rounded rounded-circle mx-auto d-block bg-white shadow mb-3">
                      <div [innerHtml]="menu.icon_svg | safeHtml" class="search-icons"></div>
                    </a>
                    <a href="{{menu.url}}" class=" text-white">
                      {{menu.menu_label}}
                    </a>
                  </ng-container>
                  <ng-template #bukanSip>
                    <a [routerLink]="[menu.url]"
                      class="icon-item rounded rounded-circle mx-auto d-block bg-white shadow mb-3">
                      <div [innerHtml]="menu.icon_svg | safeHtml" class="search-icons"></div>
                    </a>
                    <a [routerLink]="[menu.url]" class=" text-white">
                      {{menu.menu_label}}
                    </a>
                  </ng-template>

                </ng-container>
              </div>
            </ng-container>
          </ng-container>
        </div>
      </div>
    </ng-container>
  </div>
</div>