import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from 'src/app/auth/authentication.service';
import { Router, ActivatedRoute } from '@angular/router';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { NgForm } from '@angular/forms';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-form-reset',
  templateUrl: './form-reset.component.html',
  styleUrls: ['./form-reset.component.scss']
})
export class FormResetComponent implements OnInit {

  public users: any = {};
  errors: any = [];
  token = this.actRoute.snapshot.params['token'];

  constructor(
    private authService: AuthenticationService,
    private router: Router,
    private actRoute: ActivatedRoute,
    private ngxLoader: NgxUiLoaderService,
  ) { }

  ngOnInit() {
    window.scroll(0, 0);
    //check & validate reset password Token
    const param = {
      token: this.token
    };
    this.authService.checkTokenResetPassword(param).subscribe((data: any) => {
      if (!data.status) {
        this.showSwal('Form Reset Password', data.message);
        this.router.navigate(['/forgot-password']);
      }
    }, error => {
      this.ngxLoader.stop();
      Swal.fire(error.error.message);
    });


  }

  save(registration) {
    this.ngxLoader.start();
    if (this.validate(registration)) {
      const param = {
        password: this.users.password,
        token: this.token
      };
      this.authService.resetPassword(param).subscribe((data: any) => {
        this.ngxLoader.stop();
        // tslint:disable-next-line: max-line-length
        if (data.result) {
          this.suksesSwal('Sukses Reset Password', 'Reset password telah berhasil.');
          this.router.navigate(['/home']);
        }
        else {
          this.showSwal('Gagal Menyimpan.', data.message);
        }
      }, error => {
        this.ngxLoader.stop();
        Swal.fire(error.error.message);
      });
    }
  }

  validate(form_item: NgForm) {
    console.log(form_item);
    this.errors = [];
    const value: any = form_item.form.value;
    if (value.password === '' || value.password === undefined || value.repassword === '' || value.repassword === undefined) {
      this.ngxLoader.stop();
      this.showSwal('Gagal Menyimpan. Silakan lengkapi isian pada bagian Personal Account.', 'Lengkapi semua isian yang bertanda *.');
      return false;
    }
    else if (value.password !== value.repassword) {
      this.ngxLoader.stop();
      this.showSwal('Gagal Menyimpan. Password dan Retype Password tidak sesuai.', 'Isian Password dan Retype Password harus sama.');
      return false;
    }
    else if (form_item.form.status === 'VALID') {
      this.errors = [];
      return true;
    }
    else {
      this.ngxLoader.stop();
      this.showSwal('Password tidak Valid!', 'Password harus sesuai dengan ketentuan (Minimal 8 karakter, dan minimal ada 1 Huruf Kapital, 1 Huruf Kecil dan 1 Angka).');
      return false;
    }
  }

  showSwal(title: string, text: string) {
    Swal.fire({
      title: title,
      text: text,
      type: 'warning',
      showCancelButton: false,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'OK',
      allowOutsideClick: false
    }).then((result) => {
      if (result.value) {
        return false;
      }
    });
  }
  suksesSwal(title: string, text: string) {
    Swal.fire({
      title: title,
      text: text,
      type: 'success',
      showCancelButton: false,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'OK',
      allowOutsideClick: false
    }).then((result) => {
      if (result.value) {
        return false;
      }
    });
  }
}
