import { Injectable } from '@angular/core';
import { NgxPermissionsService } from 'ngx-permissions';
import { AuthenticationService } from '../auth/authentication.service';
import { UserService } from './user.service';
import { AngularFirestore } from '@angular/fire/firestore';

@Injectable({
  providedIn: 'root'
})
export class ModuleService {

  constructor(
    private firestore: AngularFirestore,
    private userService: UserService,
    private authenticationService: AuthenticationService,
    private permissionsService: NgxPermissionsService
  ) { }

  getModuleToken(username: string, module: string) {
    return this.firestore
      .collection("module_token", ref => ref.where('username', '==', username).where('module', '==', module))
      .snapshotChanges();
  }

  async syncModuleToken(moduleName: string) {
    console.log('syncModuleToken');
    if (location.hostname != "localhost" && location.hostname != "127.0.0.1") {
      let username = this.userService.getUsername();

      this.getModuleToken(username, moduleName).subscribe(res => {

        let data: any = res.map(e => {
          return {
            documentId: e.payload.doc.id,
            ...(e.payload.doc.data() as {})
          } as {};
        }).shift();

        if (localStorage.getItem(moduleName) != data.token) {

          localStorage.setItem(moduleName, data.token);

          let roles = this.authenticationService.getUserRoles(moduleName);
          const group: any[] = this.userService.getUserGroup();
          group.push('USER');

          this.permissionsService.flushPermissions();

          if (roles) {
            this.authenticationService.loadPermission(group);
            this.authenticationService.loadPermission(roles);
          }

          document.location.reload();

        }
      });
    }
  }
}
