import { isNullOrUndefined } from 'util';
import Swal from 'sweetalert2';
import { Subject } from 'rxjs';
import * as i0 from "@angular/core";
var FormService = /** @class */ (function () {
    function FormService() {
        this.resetFormEmit = new Subject();
        this.resetFormEmitObs = this.resetFormEmit.asObservable();
    }
    // begin:: Form Multiple Config
    FormService.prototype.parseRowOption = function (obj, find, val, get) {
        var temp = obj.filter(function (el) { return el[find] === val; });
        return temp[0][get];
    };
    FormService.prototype.parseInputOption = function (obj, row, get) {
        var temp = this.parseRowOption(obj, 'row', row, 'inputOption');
        return temp[get];
    };
    FormService.prototype.setValidation = function (row, rowOption, dataSource) {
        if (this.parseInputOption(rowOption, row, 'required') !== false) {
            if (dataSource === null) {
                return { touch: false, valid: false, pattern: false };
            }
            else {
                if (dataSource[row] === '' || isNullOrUndefined(dataSource[row])) {
                    return { touch: false, valid: false, pattern: false };
                }
                else {
                    return { touch: false, valid: true, pattern: true };
                }
            }
        }
        else {
            return { touch: false, valid: true, pattern: true };
        }
    };
    // end:: Form Multiple Config
    // begin:: Get Array Biar ndak manual
    FormService.prototype.getArray = function (rows, row) {
        return rows.indexOf(row);
    };
    // end:: Get Array Biar ndak manual
    // begin:: Input Validation
    // Value Validation
    FormService.prototype.valueValidation = function (value, required) {
        // SET VALID OR INVALID BY VALUE
        if (value !== '' && !isNullOrUndefined(value)) {
            return true;
        }
        else {
            return !required;
        }
    };
    FormService.prototype.patternValidation = function (value, type, validator, required) {
        // Start Comment For Debugging
        // console.log(value, type, validator, required);
        // End Comment For Debugging
        // SET VALID OR INVALID BY PATTERN
        if (type === 'text') {
            if (required !== false) {
                if (validator === 'ktp') {
                    var pattern = /^[0-9]{16}$/;
                    return pattern.test(value);
                }
                else if (validator === 'email') {
                    var pattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
                    return pattern.test(value);
                }
                else if (validator === 'phone') {
                    var pattern = /^(0|62)[0-9]{6,14}/;
                    return pattern.test(value);
                }
                else if (validator === 'mobile') {
                    var pattern = /^(08|628)[0-9]{7,15}/;
                    return pattern.test(value);
                }
                else if (validator === 'mobile_with_prefix') {
                    var pattern = /^(8)[0-9]{6,14}/;
                    return pattern.test(value);
                }
                else if (validator === 'allowance') {
                    var pattern = /^[0-9]{1,}/;
                    return pattern.test(value);
                }
                else if (validator === 'justification') {
                    var pattern = /^[1-9]+[0-9]{4,}/;
                    return pattern.test(value);
                }
                else if (validator === 'comma') {
                    var pattern = /^-?\d*(\.\d*)?$/;
                    return pattern.test(value);
                }
                else if (validator === 'nama') {
                    var pattern = /^[a-zA-Z .,']+[a-zA-Z.]$/;
                    return pattern.test(value);
                }
                else if (validator === 'none') {
                    return true;
                }
                else {
                    var pattern = /^[a-zA-Z ']+$/;
                    return pattern.test(value);
                }
            }
        }
        else if (type === 'password') {
            if (required !== false) {
                var pattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[A-Za-z\d]{8,}$/;
                return pattern.test(value);
            }
        }
        else if (type === 'number') {
            if (required !== false) {
                if (validator === 'postal_code') {
                    var pattern = /^[0-9]{5}$/;
                    return pattern.test(value);
                }
                else if (validator === 'age') {
                    var pattern = /^[0-9]{1,}$/;
                    return pattern.test(value);
                }
                else if (validator === 'year') {
                    var pattern = /^[0-9]{4}$/;
                    var tempResult = pattern.test(value);
                    if (value >= 1960 && value <= 2070) {
                        tempResult = true;
                    }
                    else {
                        tempResult = false;
                    }
                    return tempResult;
                }
                else {
                    return true;
                }
            }
            // tslint:disable-next-line: max-line-length
        }
        else if (type === 'select' || type === 'select_trigger' || type === 'textarea' || type === 'date' || type === 'typehead' || type === 'typehead_trigger' || type === 'file' || type === 'chips') {
            return true;
        }
        return true;
    };
    // end:: Input Validation
    // Validation
    FormService.prototype.validate = function (row, skipError) {
        var tempRow = row;
        // Debugging
        // console.log(row, tempRow);
        // INVALID ERROR
        var errors = [];
        var errors_pattern = [];
        var tempTotal = 0;
        // tslint:disable-next-line: forin
        for (var key in row) {
            if (!tempRow[key]['valid']) {
                errors.push('Ini' + ' Tidak Boleh Kosong');
            }
            if (!tempRow[key]['pattern']) {
                errors_pattern.push('Ini' + ' Tidak Valid');
            }
            tempTotal++;
        }
        if (errors.length === 0 && errors_pattern.length === 0) {
            return true;
        }
        if (skipError) { // Penanganan Khusus (Vacancy Detail, Site Survey Template Detail)
            if (errors.length === tempTotal) {
                return true;
            }
        }
        this.showSwal('Gagal Menyimpan.', 'Lengkapi semua isian yang bertanda (*) !');
        return false;
    };
    FormService.prototype.reactiveValidation = function (data) {
        var keys = Object.keys(data);
        for (var index = 0; index < keys.length; index++) {
            if (data[keys[index]].length > 0) {
                console.log(data[keys[index]]);
                return data[keys[index]];
            }
        }
        return true;
    };
    // TODO Setting up Row Name
    FormService.prototype.setRowName = function (obj, row, name) {
        if (name) {
            if (this.parseInputOption(obj, row, 'input_type') === 'image' ||
                this.parseInputOption(obj, row, 'input_type') === 'document') {
                return name;
            }
        }
        return row;
    };
    // TODO Setting up Value
    FormService.prototype.setValue = function (required) {
        if (required !== false) {
            return false;
        }
        return true;
    };
    // TODO Setting up Value on Update
    FormService.prototype.setValueOnUpdate = function (required, dataSource) {
        if (required !== false && (isNullOrUndefined(dataSource) || dataSource === '')) {
            return false;
        }
        return true;
    };
    FormService.prototype.showSwal = function (title, text) {
        Swal.fire({
            title: title,
            text: text,
            type: 'warning',
            showCancelButton: false,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'OK'
        }).then(function (result) {
            if (result.value) {
                return false;
            }
        });
    };
    FormService.prototype.alertModal = function (error) {
        Swal.fire({
            type: 'error',
            title: 'Terjadi Error.',
            text: 'Error : ' + error,
            footer: '<a href>Hubungi Customer Care?</a>'
        });
    };
    FormService.prototype.errorValidation = function (title, text) {
        Swal.fire({
            title: title,
            text: text,
            type: 'warning',
            showCancelButton: false,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'OK',
            allowOutsideClick: false
        }).then(function (result) {
            if (result.value) {
                return false;
            }
        });
    };
    FormService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function FormService_Factory() { return new FormService(); }, token: FormService, providedIn: "root" });
    return FormService;
}());
export { FormService };
