import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Router, Params, ActivatedRoute, PRIMARY_OUTLET, NavigationEnd } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { deleteDB } from 'idb';
import { AuthenticationService } from 'src/app/auth/authentication.service';
import { UserService } from 'src/app/_services/user.service';
import { MessagingService } from 'src/app/_services/messaging.service';
import { FormService } from 'src/app/_services/form.service';
import { ProfileService } from 'src/app/_services/profile.service';
import { RouterService } from 'src/app/_services/router.service';
import { PmcService } from 'src/app/_services/pmc.service';
import { isNullOrUndefined } from 'util';
import { PrecalcService } from 'src/app/_services/precalc.service';
import { VacancyService } from 'src/app/_services/vacancy.service';
import { EmployeeService } from 'src/app/_services/employee.service';
import { TimesheetService } from 'src/app/_services/timesheet.service';
import Swal from 'sweetalert2';
import { SimcardService } from 'src/app/_services/simcard.service';

interface IBreadcrumb {
  label: string;
  params?: Params;
  url: string;
  breadcrumb?: { step: string, path: string }[];
}
@Component({
  selector: 'app-subheader',
  templateUrl: './subheader.component.html',
  styleUrls: ['./subheader.component.scss']
})
export class SubheaderComponent implements OnInit {

  // @ViewChild('sprintName',{static: false}) SprintTitleInput: ElementRef;

  public breadcrumbs: IBreadcrumb[];
  moduleActive = '';
  pageActive = '';
  subPageActive = '';

  isInternal: boolean;
  hash: string;

  breadcrumb = '';
  currentModule = '';
  profileSide = false;


  enableIndexedDB = false;
  allowNotification: boolean;
  enableSidebar = false;

  date: string;
  date2: string;

  listEmployee: any;
  listEmployee2: any;
  public task: any = {
    nama_item: '',
    pic: [],
    desc_item: '',
    category: []
  };

  listItemChecker: any;

  sprintModal = null;
  sprint: any = {
    nama_item: '',
    duration: 1,
    category: 'Development'
  };

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private routerService: RouterService,
    private authService: AuthenticationService,
    private userService: UserService,
    private messagingService: MessagingService,
    private formService: FormService,
    private profileService: ProfileService,
    private pmcService: PmcService,
    private vacancyService: VacancyService,
    private employeeService: EmployeeService,
    private timesheetService: TimesheetService,
    private simcardService: SimcardService,
    private modalService: NgbModal,
    private precalcService: PrecalcService,
    private ngxLoader: NgxUiLoaderService
  ) {
    this.breadcrumbs = [];
    this.profileService.mobileToggleEmitObs.subscribe(
      res => {
        this.profileSide = res;
      }
    );


    this.router.events.subscribe((val) => {
      if (val instanceof NavigationEnd) {
        this.initRoute();
      }
    });

    this.vacancyService.enableidbEmitObs.subscribe(
      res => {
        this.enableIndexedDB = res;
      }
    );
  }

  ngOnInit() {
    // check notification permission
    this.allowNotification = (Notification.permission !== 'denied' && Notification.permission !== 'default') ? true : false;
    this.isInternal = this.userService.getUserPtnwStatus();
  }

  // ngAfterViewInit() {
  //   this.initRoute();
  // }

  initRoute() {
    // console.log('initRoute');
    const arr_url = this.router.url.split('/');
    if (arr_url[1] === 'main') {
      this.moduleActive = arr_url[2];
      this.pageActive = arr_url[3];
      if (this.moduleActive === 'pmc') {
        this.hash = arr_url[4];
      } else {
        this.subPageActive = arr_url[4];
      }
    }

    this.moduleChecker(arr_url);
  }

  moduleChecker(arr_url) {

    if (this.moduleActive === 'pmc' && this.hash) {
      this.getBreadcrumsPMC();
    } else {
      const root: ActivatedRoute = this.activatedRoute.root;
      this.breadcrumbs = this.getBreadcrumbs(this.router.url, root);
    }

    if (this.moduleActive === 'pm' && this.pageActive === 'precalc' && this.subPageActive === 'view') {
      this.hash = arr_url[5];
    }

    if (this.moduleActive === 'simcard' && this.pageActive === 'prepaid' && this.subPageActive === 'summary-approve') {
      this.hash = arr_url[5];
    }

    if (this.moduleActive === 'simcard' && this.pageActive === 'prepaid' && this.subPageActive === 'summary-approve-all') {
      this.listItemChecker = arr_url[5];
    }

  }

  private formatStep(temp: string, tempPath) {
    let tempListBreadcrum = { step: '', path: '' };
    if (temp.search(/-/gi) !== -1) {
      tempListBreadcrum = { step: temp.replace(/-/g, ' '), path: tempPath };
    } else if (temp.search(/_/gi) !== -1) {
      tempListBreadcrum = { step: temp.replace(/_/g, ' '), path: tempPath };
    } else {
      tempListBreadcrum = { step: temp, path: tempPath };
    }
    return tempListBreadcrum;
  }

  private getBreadcrumbs(pathUrl: string, route: ActivatedRoute, url: string = '', breadcrumbs: IBreadcrumb[] = []): IBreadcrumb[] {
    const ROUTE_DATA_BREADCRUMB = 'breadcrumb';
    const ROUTE_DATA_HAVE_SUBMODULE = 'haveSubModule';
    const ROUTE_DATA_FORCEREDIRECT = 'forceRedirect';

    // get the child routes
    const children: ActivatedRoute[] = route.children;

    // return if there are no more children
    if (children.length === 0) {
      return breadcrumbs;
    }

    // iterate over each children
    for (const child of children) {
      // verify primary route
      if (child.outlet !== PRIMARY_OUTLET) {
        continue;
      }

      // verify the custom data property 'breadcrumb' is specified on the route
      if (!child.snapshot.data.hasOwnProperty(ROUTE_DATA_BREADCRUMB)) {
        const temp = pathUrl.split('/');
        if (temp[1] === 'main') {
          this.currentModule = temp[2];
        }
        return this.getBreadcrumbs(pathUrl, child, url, breadcrumbs);
      }

      // get the route's URL segment
      const routeURL: string = child.snapshot.url.map(segment => segment.path).join('/');

      // append route URL to URL
      url += `/${routeURL}`;

      // set breadcrumb param
      const subModule = child.snapshot.data[ROUTE_DATA_HAVE_SUBMODULE];
      const forceRedirect = child.snapshot.data[ROUTE_DATA_FORCEREDIRECT];

      // set breadcrumb
      const bredtemp = this.setBreadcrum(pathUrl, url, subModule, forceRedirect);

      // add breadcrumb
      const breadcrumb: IBreadcrumb = {
        label: child.snapshot.data[ROUTE_DATA_BREADCRUMB],
        params: child.snapshot.params,
        url: url,
        breadcrumb: bredtemp
      };
      breadcrumbs.push(breadcrumb);

      // recursive
      return this.getBreadcrumbs(pathUrl, child, url, breadcrumbs);
    }
  }

  setBreadcrum(pathUrl: string, currentUrl: string, subModule: boolean, forceRedirect: string) {
    const listBreadcrum: { step: string, path: string }[] = [];
    const temp = pathUrl.split('/');
    if (temp[1] === 'main') {
      this.currentModule = temp[2];
      let tempPath = '/' + temp[1] + '/' + temp[2];
      for (let i = 2; i < pathUrl.split('/').length; i++) {
        if (currentUrl === '/' + temp[i] || currentUrl === '/' + temp[i] + '/' + temp[i + 1]) {
          return listBreadcrum;
        }

        if (temp[i] === temp[2]) {
          if (subModule) {
            // ROUTES FOR MODULE ITSELF
            const tempPathModule = tempPath + '/';
            listBreadcrum.push(this.formatStep(temp[i], tempPathModule));

            // ROUTES FOR SUBMODULE
            if (temp[i + 2] === 'index') {
              continue;
            }
            if (!isNullOrUndefined(forceRedirect)) {
              tempPath += '/' + forceRedirect;
              listBreadcrum.push(this.formatStep(forceRedirect, tempPath));
            } else {
              tempPath += '/' + temp[i + 1] + '/index';
              listBreadcrum.push(this.formatStep(temp[i + 1], tempPath));
            }
          } else {
            if (temp[i + 1] === 'index') {
              continue;
            }

            // ROUTES FOR MODULE ITSELF
            tempPath += '/index';
            listBreadcrum.push(this.formatStep(temp[i], tempPath));
          }
        } else {
          if (subModule && temp[i] === temp[3]) {
            continue;
          }
          const lastTemp = tempPath.split('/');
          this.routerService.getId().subscribe(arg => {
            let newPath: string;
            if (arg === '') {
              newPath = temp[i];
            } else {
              newPath = temp[i] + '/' + arg;
            }
            tempPath = tempPath.replace(lastTemp[tempPath.split('/').length - 1], newPath);
            listBreadcrum.push(this.formatStep(temp[i], tempPath));
          });
        }
      }
    }
  }

  toggleProfileAside() {
    this.profileSide = !this.profileSide;
    this.profileService.mobileToggleEmit.next(this.profileSide);
  }

  getBreadcrumsPMC() {
    this.pmcService.getHirarki({ hash: this.hash }).subscribe(
      response => {
        if (response.status) {
          const temp: IBreadcrumb = {
            label: 'PMC',
            url: 'https://pmc.ptnw.net/main/pmc',
            breadcrumb: []
          };

          temp.breadcrumb = response.result.map(x => {
            return { step: x.nama_item, path: x.hash };
          });
          this.breadcrumbs = [temp];
        }
      }
    );
  }

  
  open(content) {
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title', size: 'lg' });
  }
  createSimple(content, hash = '') {
    this.hash = (hash === '') ? null : hash;
    this.prepareCreate(content);
  }

  prepareCreate(content) {
    this.pmcService.prepareCreate({ hashParent: this.hash }).subscribe(response => {
      if (response.status) {
        this.listEmployee = this.listEmployee2 = [];
        this.task = { nama_item: '', pic: [], desc_item: '', category: [] };
        this.listEmployee = response.result.row.list_user;
        this.listEmployee2 = response.result.row.list_user2;
        response.result.row.parent_pic.forEach(element => {
          const tempFilter = this.listEmployee2.filter(ter => ter.value === element.userid)[0];
          if (tempFilter) {
            this.task.pic.push(tempFilter);
          }
        });
        this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title', size: 'lg' });
      }
    });
  }

  checkingData() {
    const lastPic = this.task.pic[this.task.pic.length - 1];
    const duplicate = this.task.pic.filter(dt => dt.key === lastPic.key);
    if (duplicate.length > 1) {
      this.task.pic.splice(this.task.pic.length - 1, 1);
    }
  }

  saveTask(simpleTask) {
    this.ngxLoader.start();
    if (this.validate(simpleTask)) {
      const tempPic = [];
      this.task.pic.forEach(element => {
        const tempFilter = this.listEmployee.filter(ter => ter.userid === element.value)[0];
        if (tempFilter) {
          tempPic.push(tempFilter);
        }
      });

      const tempDate = [new Date(), new Date(new Date().getTime() + (28 * 24 * 60 * 60 * 1000))];
      const date = [];

      for (let index = 0; index < tempDate.length; index++) {
        const element = tempDate[index];
        const dd = element.getDate();
        const mm = element.getMonth() + 1;
        const yyyy = element.getFullYear();

        date[index] = yyyy + '-' + mm + '-' + dd;
      }
      const param = {
        hashParent: this.hash,
        nama_item: this.task.nama_item,
        pic: tempPic,
        desc_item: this.task.desc_item,
        category: this.task.category,
        plan_start: date[0],
        plan_end: date[1],
        tipe: 'General',
        occurance: 'Once',
        priority: '3',
        weight: 1,
        access: 'Private'
      };
      this.pmcService.saveItem(param).subscribe(response => {
        this.ngxLoader.stop();
        if (response.status && response.result) {
          Swal.fire('Task has been created.');
          this.pmcService.reloadDataEmit.next(true);
          return;
        }
        Swal.fire(response.message);
      }, () => {
        this.ngxLoader.stop();
        Swal.fire('Not Authorized');
      });
    }
  }

  createSprint(modal) {
    this.sprintModal = this.modalService.open(modal, { ariaLabelledBy: 'modal-basic-title', size: 'lg' });
    //set autofocus
    console.log("sprintModal", this.sprintModal);
    // this.SprintTitleInput.nativeElement.focus();
  }

  validateSprint(form_item) {
    const value: any = form_item;
    if (value.nama_item === '') {
      this.formService.errorValidation('Error', 'Title cannot be blank');
      return false;
    }

    let duration = parseInt(this.sprint.duration);
    if (duration <= 0 || duration > 8) {
      this.formService.errorValidation('Error', 'Duration can only between 1 and 8');
      return false;
    }

    return true;
  }

  addHourse(date: Date, hours: number) {
    date.setTime(date.getTime() + (hours * 60 * 60 * 1000));
    return date;
  }

  saveSprint(sprint) {

    if (this.validateSprint(sprint)) {
      this.ngxLoader.start();
      const tempPic = [{
        "key": this.userService.getUserID(),
        "val": this.userService.getUsername() + "-" + this.userService.getName(),
        "userid": this.userService.getUserID(),
        "username": this.userService.getUsername()
      }];

      // this.task.pic.forEach(element => {
      //   const tempFilter = this.listEmployee.filter(ter => ter.userid === element.value)[0];
      //   if (tempFilter) {
      //     tempPic.push(tempFilter);
      //   }
      // });

      const tempDate = [new Date(), new Date(new Date().getTime() + (28 * 24 * 60 * 60 * 1000))];
      const date = [];

      for (let index = 0; index < tempDate.length; index++) {
        const element = tempDate[index];
        const dd = element.getDate();
        const mm = element.getMonth() + 1;
        const yyyy = element.getFullYear();

        date[index] = yyyy + '-' + mm + '-' + dd;
      }
      const param = {
        hashParent: this.hash,
        nama_item: this.sprint.nama_item,
        pic: tempPic,
        desc_item: "",
        category: [{
          value: this.sprint.category
        }],
        tipe: 'General',
        occurance: 'Once',
        priority: '3',
        weight: 1,
        access: 'Private',
        duration: this.sprint.duration
      };
      this.pmcService.saveSprint(param).subscribe(response => {
        this.ngxLoader.stop();
        if (response.status) {
          this.sprintModal.close();
          this.pmcService.reloadDataEmit.next(true);
          return;
        } else {
          //this.sprintModal.close();
          Swal.fire(response.message);
        }

      }, () => {
        this.ngxLoader.stop();
        Swal.fire('Not Authorized');
      });

      this.ngxLoader.stop();
    }
  }

  validate(form_item) {
    const value: any = form_item;
    if (value.nama_item === '' || !(value.pic.length > 0)) {
      this.ngxLoader.stop();
      this.formService.errorValidation('Gagal Menyimpan.', 'Lengkapi semua isian yang bertanda *.');
      return false;
    }
    return true;
  }

  notifyMe() {
    // Let's check if the browser supports notifications
    if (!('Notification' in window)) {
      alert('This browser does not support desktop notification\nPlease use other browser\nThank you');

      // Let's check whether notification permissions have already been granted
    } else if (Notification.permission === 'granted') {
      // If it's okay let's create a notification
      this.reloadIndexedDb();

      // Otherwise, we need to ask the user for permission
    } else if (Notification.permission !== 'denied') {
      Notification.requestPermission().then(function (permission) {
        // If the user accepts, let's create a notification
        if (permission === 'granted') {
          this.reloadIndexedDb();
        }
      });
    }

    // At last, if the user has denied notifications,
    // we can do nothing.
  }

  reloadIndexedDb() {
    const userId = this.userService.getUsername();
    this.messagingService.requestPermission(userId, true);
    setTimeout(() => {
      // console.log('hi', this.messagingService.currentToken);
      this.authService.reloadIdb({ username: userId, fcm_token: this.messagingService.currentToken, old_token: null }).subscribe(arg => {
        arg.status ? this.deleteDB() : console.log(arg.message);
      }, error => {
        console.log(error);
        // Swal.fire(error.error.message);
      });
    }, 10000);
  }

  deleteDB() {
    console.log('Database has been Reloaded');
    deleteDB('DatabaseNds'); // Clear IndexedDB
    location.reload();
  }

  enableIDBToggle() {
    this.enableIndexedDB = !this.enableIndexedDB;
    this.vacancyService.enableidbEmit.next(this.enableIndexedDB);
  }

  enabledSidebar() {
    this.employeeService.mobileToggleEmit.next(!this.enableIndexedDB);
  }

  newTimesheet() {
    this.timesheetService.newTimesheetEmit.next(true);
  }

  setApproval(aksi: string) {
    this.ngxLoader.start();
    const param = { hash: this.hash, action: aksi };
    this.simcardService.actionApproval(param).subscribe(response => {
      if (response.status) {
        Swal.fire(response.message);
        this.ngxLoader.stop();
        this.router.navigate(['/main/simcard/prepaid/my-approval']);
      }
    }, (error: any) => {
      this.ngxLoader.stop();
      Swal.fire(error.message);
    });
  }

  approveAll(aksi: string) {
    this.ngxLoader.start();
    this.simcardService.approveAll({ action: aksi, listIdMaster: this.listItemChecker }).subscribe(
      response => {
        if (response.result) {
          this.ngxLoader.stop();
          this.router.navigate(['/main/simcard/prepaid/my-approval']);
        }
        else {
          this.ngxLoader.stop();
          Swal.fire(response.message);
        }
      },
      error => {
        this.ngxLoader.stop();
        Swal.fire(error.message);
      }
    );
  }
}
