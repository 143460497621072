/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./signature.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../../node_modules/angular2-signaturepad/angular2-signaturepad.ngfactory";
import * as i3 from "angular2-signaturepad";
import * as i4 from "./signature.component";
import * as i5 from "@angular/router";
import * as i6 from "ngx-ui-loader";
import * as i7 from "../../../../_services/users-management.service";
var styles_SignatureComponent = [i0.styles];
var RenderType_SignatureComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_SignatureComponent, data: {} });
export { RenderType_SignatureComponent as RenderType_SignatureComponent };
export function View_SignatureComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(671088640, 1, { signaturePad: 0 }), (_l()(), i1.ɵeld(1, 0, null, null, 16, "div", [["class", "row"], ["style", "padding: 20px"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 15, "div", [["class", "col"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 4, "div", [["class", "alert alert-light alert-elevate fade show"], ["role", "alert"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "div", [["class", "alert-icon"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 0, "i", [["class", "flaticon2-list-3 kt-font-brand"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 1, "div", [["class", "alert-text"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Silakan bubuhkan tanda tangan anda di sini, kemudian klik \"Simpan\" untuk menyimpannya. "])), (_l()(), i1.ɵeld(8, 0, null, null, 2, "div", [["style", "border:solid 2px #333;margin:0 auto ;width: 450px"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 1, "signature-pad", [], null, [[null, "onEndEvent"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("onEndEvent" === en)) {
        var pd_0 = (_co.drawComplete($event, i1.ɵnov(_v, 10)) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_SignaturePad_0, i2.RenderType_SignaturePad)), i1.ɵdid(10, 1228800, [[1, 4], ["datasignature", 4]], 0, i3.SignaturePad, [i1.ElementRef], { options: [0, "options"] }, { onEndEvent: "onEndEvent" }), (_l()(), i1.ɵeld(11, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵeld(12, 0, null, null, 5, "div", [["style", "margin:0 auto ;width: 450px"]], null, null, null, null, null)), (_l()(), i1.ɵeld(13, 0, null, null, 1, "button", [["class", "btn btn-warning"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.clear(i1.ɵnov(_v, 10)) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Ulangi"])), (_l()(), i1.ɵted(-1, null, [" \u00A0"])), (_l()(), i1.ɵeld(16, 0, null, null, 1, "button", [["class", "btn btn-primary"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.submit(i1.ɵnov(_v, 10)) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Simpan"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.signaturePadOptions; _ck(_v, 10, 0, currVal_0); }, null); }
export function View_SignatureComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-signature", [], null, null, null, View_SignatureComponent_0, RenderType_SignatureComponent)), i1.ɵdid(1, 114688, null, 0, i4.SignatureComponent, [i5.Router, i6.NgxUiLoaderService, i5.ActivatedRoute, i7.UsersManagementService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var SignatureComponentNgFactory = i1.ɵccf("app-signature", i4.SignatureComponent, View_SignatureComponent_Host_0, {}, {}, []);
export { SignatureComponentNgFactory as SignatureComponentNgFactory };
