import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { LandingpageService } from 'src/app/_services/landingpage.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-whatsapp',
  templateUrl: './whatsapp.component.html',
  styleUrls: ['./whatsapp.component.scss']
})
export class WhatsappComponent implements OnInit {

  hash = this.actRoute.snapshot.params['hash'] + '';
  userid: number;
  isVerified = false;
  errNik: string = undefined;
  nik: string;

  constructor(
    private router: Router,
    private actRoute: ActivatedRoute,
    private landingService: LandingpageService,
  ) { }

  ngOnInit() {
    const arr: any = this.hash.split('_');
    this.userid = arr[0] * 1;
    this.hash = arr[1];
    this.landingService.cekWa({ x: this.hash, id: this.userid }).subscribe(response => {
      if (response.status) {
        if (response.result == 1) {
          this.isVerified = true;
        }
      } else {
        Swal.fire(response.message);
      }
    }, error => {
      Swal.fire(error.error.message);
    }
    );
  }

  verifyMyNumber() {
    this.errNik = undefined;
    if (!this.nik) {
      this.errNik = 'Mohon masukkan nik anda.';
    }

    this.landingService.verificationWa({ x: this.hash, id: this.userid, nik: this.nik }).subscribe(response => {
      if (response.status) {
        if (response.result == 1) {
          this.isVerified = true;
        }
      } else {
        Swal.fire(response.message);
      }
    }, error => {
      Swal.fire(error.error.message);
    }
    );
  }

}
