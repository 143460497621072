import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AuthenticationService } from './authentication.service';
import { Observable } from 'rxjs';

@Injectable()
export class AuthGuard implements CanActivate {

  public isAuthorized: boolean;

  constructor(
    private _authService: AuthenticationService,
    private _router: Router) {
  }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    this._authService.isAuthorized().subscribe(
      token => {
        this.isAuthorized = token;
      }
    );    

    if (this.isAuthorized) {
      return true;
    }
    else {
      // navigate to login page
      this._router.navigate(['/login'], { queryParams: { returnUrl: state.url } });
      // you can save redirect url so after authing we can move them back to the page they requested
      return false;
    }
  }

}
