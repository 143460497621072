import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CookieService } from 'ngx-cookie-service';
import { GlobalService } from './global.service';
import { Observable, throwError, Subject, BehaviorSubject } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { AlertModalComponent } from '../layout/shared/alert-modal/alert-modal.component';

import { Er } from '../_models/er.model';
import { ResponseApiModel } from '../_models/response-api.model';
import { Vacancy, VacancyDetail } from '../_models/vacancy.model';
import Swal from 'sweetalert2';

// KARIR
const API_CREATE_MASTER = '/vacancy/dev/add-vacancy';
const API_GET_PROJECT = '/vacancy/dev/get-project';
const API_GET_SKILL_TOOL = '/vacancy/dev/get-skill-and-tools';
const API_GET_EDIT_MASTER = '/vacancy/dev/get-edit-master';
const API_GET_EDIT_DETAIL = '/vacancy/dev/get-edit-detail';
const API_EDIT_MASTER = '/vacancy/dev/edit-vacancy-master';
const API_EDIT_DETAIL_VACANCY = '/vacancy/dev/edit-vacancy-detail';
const API_CREATE_DETAIL = '/vacancy/dev/add-detail-vacancy';
const API_VACANCY = '/vacancy/default/get-vacancy';
const API_DELETE_DETAIL = '/vacancy/dev/delete-detail-vacancy';
const API_VACANCY_DETAIL = '/vacancy/default/vacancy-detail';
const API_APPLY_VACANCY = '/vacancy/default/apply-vacancy';
const API_LIST_APPLICANT = '/vacancy/default/list-applicant';
const API_LIST_CV = '/vacancy/default/list-cv';

// APPLICANT
const API_SAVE_SKILL = '/vacancy/recruitment/save-skill';
const API_EDIT_DETAIL_APPLICANT = '/vacancy/recruitment/edit-detail';
const API_GET_APPLICANT_LIST = '/vacancy/recruitment/get-applicant-list';
const API_GET_APPLICANT_LIST_SERVER = '/vacancy/recruitment/get-applicant-list-server';
const API_GET_NOT_FOUND_APPLICANT = '/vacancy/recruitment/get-not-found-applicant';
const API_GET_APPLICANT_DETAIL = '/vacancy/recruitment/get-applicant-detail';
const API_GET_APPLICANT_DOCUMENT = '/vacancy/recruitment/get-applicant-document';
const API_GET_APPLICATION = '/vacancy/employeeapplication/get-application';
const API_GET_STEP_RECRUITMENT = '/vacancy/recruitment/get-step-recruitment';
const API_GET_DATA_CANDIDATE = '/vacancy/recruitment/get-data-candidate';
const API_GET_DATA_INTERVIEW = '/vacancy/recruitment/get-interview-data';
const API_GET_DATA_NEGOTIATION = '/vacancy/recruitment/get-negotiation-data';
const API_SUBMIT_TECHNICAL_INTERVIEW = '/vacancy/recruitment/submit-technical-interview';
const API_SUBMIT_NEGOTIATION = '/vacancy/recruitment/submit-negotiation';
const API_SAVE_REGISTATION = '/vacancy/recruitment/save-registrasi';
const API_SAVE_APPLICATION = '/vacancy/employeeapplication/save-application';
const API_UPDATE_APPLICATION = '/vacancy/employeeapplication/update-application';
const API_DELETE_APPLICATION = '/vacancy/employeeapplication/delete-application';
const API_EDIT_TECHNICAL = '/vacancy/recruitment/edit-technical';
const API_EDIT_NEGOTIATION = '/vacancy/recruitment/edit-negotiation';
const API_GET_USER_ER = '/vacancy/recruitment/get-user-er';
const API_GET_APPLICANT_NAME = '/vacancy/recruitment/get-list-applicant-name';
const API_ER = '/vacancy/recruitment/er';
const API_TEMPLATE_SKILL = '/vacancy/recruitment/template-skill';
const API_UPDATE_SKILL = '/vacancy/recruitment/update-skill';
const API_SUBMIT_SKILL = '/vacancy/recruitment/submit-skill';
const API_SUBMIT_SKILL_NOT_REGISTER = '/vacancy/recruitment/submit-skill-not-register';
const API_FINISH_TECHNICAL = '/vacancy/recruitment/finish-technical';
const API_FINISH_NEGOTIATION = '/vacancy/recruitment/finish-negotiation';
const API_CANCEL_PROCESS = '/employee/hrd/cancel-proccess';
const API_APPLICANT_INFO = '/vacancy/recruitment/get-applicant-info';
const API_ADD_OTHER_VACANCY = '/vacancy/recruitment/add-other-vacancy';
const API_DELETE_APPLY_VACANCY = '/vacancy/recruitment/delete-other-vacancy';
const API_SUBMIT_DECLARATION = '/vacancy/employeeapplication/submit-declaration';
const API_AUTHORIZED = '/vacancy/employeeapplication/get-authorized';

@Injectable({
  providedIn: 'root'
})
export class VacancyService {

  public enableidbEmit: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public enableidbEmitObs = this.enableidbEmit.asObservable();

  constructor(
    private http: HttpClient,
    private cookieService: CookieService,
    private globalService: GlobalService,
    private modalService: NgbModal,
  ) { }

  alertModal(err: string, code?: number) {
    if (code === 403) {
      return Swal.fire(err);
    }
    const modalRef = this.modalService.open(AlertModalComponent);
    modalRef.componentInstance.kategori = 'alert';
    modalRef.componentInstance.error = (err) ? err : 'Request Timeout.';
  }

  public header() {
    return { headers: new HttpHeaders()
      .set('token', this.cookieService.get('_q'))
      .set('module', localStorage.getItem('vacancy'))
    };
  }

  // HEADER ONLY FOR CANCEL PROCESS
  public header2() {
    return { headers: new HttpHeaders()
      .set('token', this.cookieService.get('_q'))
      .set('module', localStorage.getItem('employee'))
    };
  }

  // TODO KARIR
  public getVacancyDetail(param): Observable<ResponseApiModel> {
    return this.http.post<ResponseApiModel>(
      this.globalService.apiVersionHost + API_VACANCY_DETAIL , param, this.header()
    ).pipe(
      map(response => response),
      catchError(err => {
        return throwError(err);
      })
    );
  }
  public getListApplicant(param: { pageSize: number, filter: any, id: number }): Observable<ResponseApiModel> {
    return this.http.post<ResponseApiModel>(
      this.globalService.apiVersionHost + API_LIST_APPLICANT, param, this.header()
    );
  }
  public getListCv(param: { id: number }): Observable<ResponseApiModel> {
    return this.http.post<ResponseApiModel>(
      this.globalService.apiVersionHost + API_LIST_CV, param, this.header()
    );
  }

  public addMaster(param): Observable<ResponseApiModel> {
    return this.http.post<ResponseApiModel>(
      this.globalService.apiVersionHost + API_CREATE_MASTER, param, this.header()
    ).pipe(
      map(response => response),
      catchError(err => {
        return throwError(err);
      })
    );
  }
  public editMaster(param): Observable<ResponseApiModel> {
    return this.http.post<ResponseApiModel>(
      this.globalService.apiVersionHost + API_EDIT_MASTER, param, this.header()
    ).pipe(
      map(response => response),
      catchError(err => {
        return throwError(err);
      })
    );
  }
  public editDetailVacancy(param): Observable<ResponseApiModel> {
    return this.http.post<ResponseApiModel>(
      this.globalService.apiVersionHost + API_EDIT_DETAIL_VACANCY, param, this.header()
    ).pipe(
      map(response => response),
      catchError(err => {
        return throwError(err);
      })
    );
  }

  public getProject(): Observable<ResponseApiModel> {
    return this.http.get<ResponseApiModel>(
      this.globalService.apiVersionHost + API_GET_PROJECT, this.header()
    ).pipe(
      map(response => response)
    );
  }

  public getSkillTool(): Observable<ResponseApiModel> {
    return this.http.get<ResponseApiModel>(
      this.globalService.apiVersionHost + API_GET_SKILL_TOOL, this.header()
    ).pipe(
      map(response => response)
    );
  }
  public getEditMaster(id: number): Observable<ResponseApiModel> {
    return this.http.post<ResponseApiModel>(
      this.globalService.apiVersionHost + API_GET_EDIT_MASTER, {id}, this.header()
    ).pipe(
      map(response => response)
    );
  }
  public getEditDetail(id: number): Observable<ResponseApiModel> {
    return this.http.post<ResponseApiModel>(
      this.globalService.apiVersionHost + API_GET_EDIT_DETAIL, {id}, this.header()
    ).pipe(
      map(response => response)
    );
  }
  public createDetailVacancy(param: {detail: VacancyDetail, id: any}): Observable<ResponseApiModel> {
    return this.http.post<ResponseApiModel>(
      this.globalService.apiVersionHost + API_CREATE_DETAIL, param, this.header()
    ).pipe(
      map(response => response)
    );
  }
  public getVacancy(param: { pageSize: number, page: number, filter: any }): Observable<ResponseApiModel> {
    return this.http.post<ResponseApiModel>(
      this.globalService.apiVersionHost + API_VACANCY, param, this.header()
    ).pipe(
      map(response => response)
    );
  }
  public deleteDetail(id): Observable<ResponseApiModel> {
    return this.http.post<ResponseApiModel>(
      this.globalService.apiVersionHost + API_DELETE_DETAIL, {id}, this.header()
    ).pipe(
      map(response => response)
    );
  }

  public applyVacancy(param): Observable<ResponseApiModel> {
    return this.http.post<ResponseApiModel>(
      this.globalService.apiVersionHost + API_APPLY_VACANCY, param, this.header()
    ).pipe(
      map(response => response)
    );
  }



  // TODO APPLICANT
  public getApplicant(param: {
    pageSize: number,
    page: number,
    filter: any
  }): Observable<ResponseApiModel> {
    return this.http.post<ResponseApiModel>(
      this.globalService.apiVersionHost + API_GET_APPLICANT_LIST, param, this.header()
    ).pipe(
      map(response => response)
    );
  }
  public getApplicantFromServer(param: {
    pageSize: number,
    page: number,
    filter: any,
    processFilter: string
  }): Observable<ResponseApiModel> {
    return this.http.post<ResponseApiModel>(
      this.globalService.apiVersionHost + API_GET_APPLICANT_LIST_SERVER, param, this.header()
    ).pipe(
      map(response => response)
    );
  }
  public getNotFoundApplicant(filter): Observable<ResponseApiModel> {
    return this.http.post<ResponseApiModel>(
      this.globalService.apiVersionHost + API_GET_NOT_FOUND_APPLICANT, {filter}, this.header()
    ).pipe(
      map(response => response),
      catchError(err => {
        return throwError(err);
      })
    );
  }
  public getApplicantDetail(userid: string): Observable<ResponseApiModel> {
    return this.http.post<ResponseApiModel>(
      this.globalService.apiVersionHost + API_GET_APPLICANT_DETAIL, {userid}, this.header()
    ).pipe(
      map(response => response),
      catchError(err => {
        return throwError(err);
      })
    );
  }
  public getApplicantDocument(username: string): Observable<ResponseApiModel> {
    return this.http.post<ResponseApiModel>(
      this.globalService.apiVersionHost + API_GET_APPLICANT_DOCUMENT, {username}, this.header()
    ).pipe(
      map(response => response),
      catchError(err => {
        return throwError(err);
      })
    );
  }
  public getStepRecruitment(id_apply_vacancy: any): Observable<ResponseApiModel> {
    return this.http.post<ResponseApiModel>(
      this.globalService.apiVersionHost + API_GET_STEP_RECRUITMENT, {id_apply_vacancy}, this.header()
    ).pipe(
      map(response => response)
    );
  }

  public getApplication(destination: string): Observable<ResponseApiModel> {
    return this.http.post<ResponseApiModel>(
      this.globalService.apiVersionHost + API_GET_APPLICATION, {destination}, this.header()
    ).pipe(
      map(response => response),
      catchError(err => {
        return throwError(err);
      })
    );
  }

  public saveRegistration(param: {regis: any, id: number}): Observable<ResponseApiModel> {
    return this.http.post<ResponseApiModel>(
      this.globalService.apiVersionHost + API_SAVE_REGISTATION, param, this.header()
    ).pipe(
      map(response => response)
    );
  }
  public saveApplication(param: {destination: string, data: any}): Observable<ResponseApiModel> {
    return this.http.post<ResponseApiModel>(
      this.globalService.apiVersionHost + API_SAVE_APPLICATION, param, this.header()
    ).pipe(
      map(response => response),
      catchError(err => {
        return throwError(err);
      })
    );
  }
  public updateApplication(param: {destination: string, data: any}): Observable<ResponseApiModel> {
    return this.http.post<ResponseApiModel>(
      this.globalService.apiVersionHost + API_UPDATE_APPLICATION, param, this.header()
    ).pipe(
      map(response => response),
      catchError(err => {
        return throwError(err);
      })
    );
  }
  public deleteApplication(param: {destination: string, id: any}): Observable<ResponseApiModel> {
    return this.http.post<ResponseApiModel>(
      this.globalService.apiVersionHost + API_DELETE_APPLICATION, param, this.header()
    ).pipe(
      map(response => response),
      catchError(err => {
        return throwError(err);
      })
    );
  }

  public getDataCandidate(param: {userid: number}): Observable<ResponseApiModel> {
    return this.http.post<ResponseApiModel>(
      this.globalService.apiVersionHost + API_GET_DATA_CANDIDATE, param, this.header()
    ).pipe(
      map(response => response)
    );
  }

  public editTechnical(param: any): Observable<ResponseApiModel> {
    return this.http.post<ResponseApiModel>(
      this.globalService.apiVersionHost + API_EDIT_TECHNICAL, param, this.header()
    ).pipe(
      map(response => response),
      catchError(err => {
        return throwError(err);
      })
    );
  }

  public editNegotiation(param: any): Observable<ResponseApiModel> {
    return this.http.post<ResponseApiModel>(
      this.globalService.apiVersionHost + API_EDIT_NEGOTIATION, param, this.header()
    ).pipe(
      map(response => response),
      catchError(err => {
        return throwError(err);
      })
    );
  }

  public getUserEr(): Observable<ResponseApiModel> {
    return this.http.get<ResponseApiModel>(
      this.globalService.apiVersionHost + API_GET_USER_ER, this.header()
    ).pipe(
      map(response => response),
      catchError(err => {
        return throwError(err);
      })
    );
  }
  public getApplicantName(): Observable<ResponseApiModel> {
    return this.http.get<ResponseApiModel>(
      this.globalService.apiVersionHost + API_GET_APPLICANT_NAME, this.header()
    ).pipe(
      map(response => response),
      catchError(err => {
        return throwError(err);
      })
    );
  }

  public saveSkill(param: {data: any, username: any}): Observable<ResponseApiModel> {
    return this.http.post<ResponseApiModel>(
      this.globalService.apiVersionHost + API_SAVE_SKILL, param, this.header()
    ).pipe(
      map(response => response),
      catchError(err => {
        return throwError(err);
      })
    );
  }

  public editDetailApplicant(param: {data: any, userid: any}): Observable<ResponseApiModel> {
    return this.http.post<ResponseApiModel>(
      this.globalService.apiVersionHost + API_EDIT_DETAIL_APPLICANT, param, this.header()
    ).pipe(
      map(response => response),
      catchError(err => {
        return throwError(err);
      })
    );
  }

  public addEr(param: {data: Er, username: any}): Observable<ResponseApiModel> {
    return this.http.post<ResponseApiModel>(
      this.globalService.apiVersionHost + API_ER, param, this.header()
    ).pipe(
      map(response => response),
      catchError(err => {
        return throwError(err);
      })
    );
  }

  public templateSkill(param: {id: any}): Observable<ResponseApiModel> {
    return this.http.post<ResponseApiModel>(
      this.globalService.apiVersionHost + API_TEMPLATE_SKILL, param, this.header()
    ).pipe(
      map(response => response),
      catchError(err => {
        return throwError(err);
      })
    );
  }

  public updateSkill(param: {data: any, id: any}): Observable<ResponseApiModel> {
    return this.http.post<ResponseApiModel>(
      this.globalService.apiVersionHost + API_UPDATE_SKILL, param, this.header()
    ).pipe(
      map(response => response),
      catchError(err => {
        return throwError(err);
      })
    );
  }

  public submitSkill(param: {id: any}): Observable<ResponseApiModel> {
    return this.http.post<ResponseApiModel>(
    this.globalService.apiVersionHost + API_SUBMIT_SKILL, param, this.header()
    ).pipe(
      map(response => response),
      catchError(err => {
        return throwError(err);
      })
    );
  }

  public submitSkillNotRegister(param: {id: any, skills: any}): Observable<ResponseApiModel> {
    return this.http.post<ResponseApiModel>(
    this.globalService.apiVersionHost + API_SUBMIT_SKILL_NOT_REGISTER, param, this.header()
    ).pipe(
      map(response => response),
      catchError(err => {
        return throwError(err);
      })
    );
  }

  public finishTechnical(param: {username: any, updated_skills: any, updated_ir: any}): Observable<ResponseApiModel> {
    return this.http.post<ResponseApiModel>(
    this.globalService.apiVersionHost + API_FINISH_TECHNICAL, param, this.header()
    ).pipe(
      map(response => response),
      catchError(err => {
        return throwError(err);
      })
    );
  }

  public finishNegotiation(param: {username: any, updated_nego: any}): Observable<ResponseApiModel> {
    return this.http.post<ResponseApiModel>(
    this.globalService.apiVersionHost + API_FINISH_NEGOTIATION, param, this.header()
    ).pipe(
      map(response => response),
      catchError(err => {
        return throwError(err);
      })
    );
  }

  public cancelProcess(param): Observable<ResponseApiModel> {
    return this.http.post<ResponseApiModel>(
    this.globalService.apiVersionHost + API_CANCEL_PROCESS, param, this.header2()
    ).pipe(
      map(response => response),
      catchError(err => {
        return throwError(err);
      })
    );
  }
  public getApplicantInfo(userid): Observable<ResponseApiModel> {
    return this.http.post<ResponseApiModel>(
      this.globalService.apiVersionHost + API_APPLICANT_INFO, {userid}, this.header()
    ).pipe(
      map(response => response)
    );
  }
  public addOtherVacancy(param): Observable<ResponseApiModel> {
    return this.http.post<ResponseApiModel>(
      this.globalService.apiVersionHost + API_ADD_OTHER_VACANCY, param, this.header()
    ).pipe(
      map(response => response)
    );
  }
  public deleteApplyVacancy(param): Observable<ResponseApiModel> {
    return this.http.post<ResponseApiModel>(
      this.globalService.apiVersionHost + API_DELETE_APPLY_VACANCY, param, this.header()
    ).pipe(
      map(response => response)
    );
  }

  public submitDeclaration(userid): Observable<ResponseApiModel> {
    return this.http.post<ResponseApiModel>(
      this.globalService.apiVersionHost + API_SUBMIT_DECLARATION, {userid}, this.header()
    ).pipe(
      map(response => response),
      catchError(err => {
        return throwError(err);
      })
    );
  }
  public getAuthorized(): Observable<ResponseApiModel> {
    return this.http.get<ResponseApiModel>(
      this.globalService.apiVersionHost + API_AUTHORIZED, this.header()
    ).pipe(
      map(response => response)
    );
  }

  public getInterviewData(param: {userid: number}): Observable<ResponseApiModel> {
    return this.http.post<ResponseApiModel>(
      this.globalService.apiVersionHost + API_GET_DATA_INTERVIEW, param, this.header()
    ).pipe(
      map(response => response)
    );
  }

  public getNegotiationData(param: {userid: number}): Observable<ResponseApiModel> {
    return this.http.post<ResponseApiModel>(
      this.globalService.apiVersionHost + API_GET_DATA_NEGOTIATION, param, this.header()
    ).pipe(
      map(response => response)
    );
  }

  public submitInterview(param: any): Observable<ResponseApiModel> {
    return this.http.post<ResponseApiModel>(
      this.globalService.apiVersionHost + API_SUBMIT_TECHNICAL_INTERVIEW, param, this.header()
    ).pipe(
      map(response => response)
    );
  }

  public submitNegotation(param: any): Observable<ResponseApiModel> {
    return this.http.post<ResponseApiModel>(
      this.globalService.apiVersionHost + API_SUBMIT_NEGOTIATION, param, this.header()
    ).pipe(
      map(response => response)
    );
  }
}
