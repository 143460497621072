import { Component, OnInit } from '@angular/core';
import { LandingpageService } from 'src/app/_services/landingpage.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.scss']
})
export class FaqComponent implements OnInit {

  isLoading = false;
  filter: any = {
    question: null,
    nama_category: null
  };
  dataSource: any[] = [];
  dataCategory: any[] = [];

  constructor(
    private landingService: LandingpageService,
  ) { }

  ngOnInit() {
    this.reloadData();
  }

  reloadData() {
    this.isLoading = true;
    const param = { filter: [this.filter] };
    this.landingService.getFaqLanding(param).subscribe(response => {
      this.dataSource = response.result.row;
      this.dataCategory = response.result.row_category;
      this.isLoading = false;
    }, error => {
      this.isLoading = false;
      Swal.fire(error.error.message);
    });
  }

  reloadCategory(category) {
    this.filter.nama_category = category;
    this.reloadData();
  }
}
