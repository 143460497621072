import { HttpClient, HttpHeaders } from '@angular/common/http';
import { GlobalService } from './global.service';
import { CookieService } from 'ngx-cookie-service';
import { map } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "./global.service";
import * as i3 from "ngx-cookie-service";
var API_ADD_STATUS = '/home/add-status';
var API_DELETE_STATUS = '/home/delete-status';
var API_ADD_COMMENT = '/home/add-comment';
var API_DELETE_COMMENT = '/home/delete-comment';
var API_GET_STATUSES = '/home/get-statuses';
var API_GET_BLOGS = '/home/get-blogs';
var API_GET_TASKS = '/home/get-tasks';
var API_GET_FAQS = '/home/get-faqs';
var API_GET_DATA_USER = '/home/get-data-user';
var API_GET_MY_TASK = '/home/my-task';
var API_CHANGE_STATUS_TASK = '/home/change-status-task';
var API_MY_ASSET = '/home/my-asset';
var API_MY_APPROVAL = '/home/my-approval';
var API_READ_NOTIF = '/home/read-notif';
var API_APPROVAL = '/home/get-approval';
var HomeService = /** @class */ (function () {
    function HomeService(http, globalService, cookieService) {
        this.http = http;
        this.globalService = globalService;
        this.cookieService = cookieService;
    }
    HomeService.prototype.header = function () {
        return {
            headers: new HttpHeaders()
                .set('token', this.cookieService.get('_q'))
        };
    };
    HomeService.prototype.addStatus = function (param) {
        return this.http.post(this.globalService.apiVersionHost + API_ADD_STATUS, param, this.header()).pipe(map(function (response) { return response; }));
    };
    HomeService.prototype.deleteStatus = function (param) {
        return this.http.post(this.globalService.apiVersionHost + API_DELETE_STATUS, param, this.header()).pipe(map(function (response) { return response; }));
    };
    HomeService.prototype.addComment = function (param) {
        return this.http.post(this.globalService.apiVersionHost + API_ADD_COMMENT, param, this.header()).pipe(map(function (response) { return response; }));
    };
    HomeService.prototype.deleteComment = function (param) {
        return this.http.post(this.globalService.apiVersionHost + API_DELETE_COMMENT, param, this.header()).pipe(map(function (response) { return response; }));
    };
    HomeService.prototype.getStatuses = function (param) {
        return this.http.post(this.globalService.apiVersionHost + API_GET_STATUSES, param, this.header()).pipe(map(function (response) { return response; }));
    };
    HomeService.prototype.getBlogs = function () {
        return this.http.post(this.globalService.apiVersionHost + API_GET_BLOGS, null, this.header()).pipe(map(function (response) { return response; }));
    };
    HomeService.prototype.getTasks = function () {
        return this.http.post(this.globalService.apiVersionHost + API_GET_TASKS, null, this.header()).pipe(map(function (response) { return response; }));
    };
    HomeService.prototype.getFaqs = function () {
        return this.http.post(this.globalService.apiVersionHost + API_GET_FAQS, null, this.header()).pipe(map(function (response) { return response; }));
    };
    HomeService.prototype.getDataUser = function () {
        return this.http.post(this.globalService.apiVersionHost + API_GET_DATA_USER, null, this.header()).pipe(map(function (response) { return response; }));
    };
    HomeService.prototype.getMyTask = function (param) {
        return this.http.post(this.globalService.apiVersionHost + API_GET_MY_TASK, param, this.header()).pipe(map(function (response) { return response; }));
    };
    HomeService.prototype.changeStatus = function (param) {
        return this.http.post(this.globalService.apiVersionHost + API_CHANGE_STATUS_TASK, param, this.header()).pipe(map(function (response) { return response; }));
    };
    HomeService.prototype.getMyAsset = function (param) {
        return this.http.post(this.globalService.apiVersionHost + API_MY_ASSET, param, this.header()).pipe(map(function (response) { return response; }));
    };
    HomeService.prototype.getMyApproval = function (param) {
        return this.http.post(this.globalService.apiVersionHost + API_MY_APPROVAL, param, this.header()).pipe(map(function (response) { return response; }));
    };
    HomeService.prototype.readNotif = function (param) {
        return this.http.post(this.globalService.apiVersionHost + API_READ_NOTIF, param, this.header()).pipe(map(function (response) { return response; }));
    };
    HomeService.prototype.getApproval = function () {
        return this.http.post(this.globalService.apiVersionHost + API_APPROVAL, null, this.header()).pipe(map(function (response) { return response; }));
    };
    HomeService.prototype.getNdsInfo = function () {
        return this.http.post(this.globalService.apiVersionHost + '/home/get-nds-info', null, this.header()).pipe(map(function (response) { return response; }));
    };
    HomeService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function HomeService_Factory() { return new HomeService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.GlobalService), i0.ɵɵinject(i3.CookieService)); }, token: HomeService, providedIn: "root" });
    return HomeService;
}());
export { HomeService };
