/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./landing.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./theme5g/header5g/header5g.component.ngfactory";
import * as i3 from "./theme5g/header5g/header5g.component";
import * as i4 from "@angular/router";
import * as i5 from "./theme5g/footer5g/footer5g.component.ngfactory";
import * as i6 from "./theme5g/footer5g/footer5g.component";
import * as i7 from "@angular/common";
import * as i8 from "./landing.component";
var styles_LandingComponent = [i0.styles];
var RenderType_LandingComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_LandingComponent, data: {} });
export { RenderType_LandingComponent as RenderType_LandingComponent };
function View_LandingComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 8, "div", [["class", "nds_public"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 7, "div", [["id", "nds_contents"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 0, "a", [["name", "toppage"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "app-header5g", [], null, null, null, i2.View_Header5gComponent_0, i2.RenderType_Header5gComponent)), i1.ɵdid(5, 114688, null, 0, i3.Header5gComponent, [], null, null), (_l()(), i1.ɵeld(6, 16777216, null, null, 1, "router-outlet", [], null, null, null, null, null)), i1.ɵdid(7, 212992, null, 0, i4.RouterOutlet, [i4.ChildrenOutletContexts, i1.ViewContainerRef, i1.ComponentFactoryResolver, [8, null], i1.ChangeDetectorRef], null, null), (_l()(), i1.ɵeld(8, 0, null, null, 1, "app-footer5g", [], null, null, null, i5.View_Footer5gComponent_0, i5.RenderType_Footer5gComponent)), i1.ɵdid(9, 114688, null, 0, i6.Footer5gComponent, [], null, null)], function (_ck, _v) { _ck(_v, 5, 0); _ck(_v, 7, 0); _ck(_v, 9, 0); }, null); }
function View_LandingComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 16777216, null, null, 1, "router-outlet", [], null, null, null, null, null)), i1.ɵdid(2, 212992, null, 0, i4.RouterOutlet, [i4.ChildrenOutletContexts, i1.ViewContainerRef, i1.ComponentFactoryResolver, [8, null], i1.ChangeDetectorRef], null, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { _ck(_v, 2, 0); }, null); }
export function View_LandingComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_LandingComponent_1)), i1.ɵdid(1, 16384, null, 0, i7.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_LandingComponent_2)), i1.ɵdid(3, 16384, null, 0, i7.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.partialPage; _ck(_v, 1, 0, currVal_0); var currVal_1 = _co.partialPage; _ck(_v, 3, 0, currVal_1); }, null); }
export function View_LandingComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-landing", [], null, null, null, View_LandingComponent_0, RenderType_LandingComponent)), i1.ɵdid(1, 114688, null, 0, i8.LandingComponent, [i4.Router], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var LandingComponentNgFactory = i1.ɵccf("app-landing", i8.LandingComponent, View_LandingComponent_Host_0, {}, {}, []);
export { LandingComponentNgFactory as LandingComponentNgFactory };
