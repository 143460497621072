<div id="nds_contents">
  <div class="bg-white navbar-spacer"></div>
  <div class="pagecomponentwrap text-center text-white " id="pageheader">
    <div class="container pb-5 pt-75">
      <div class="no-gutters row justify-content-center align-items-center aos-init aos-animate" data-aos="">
        <div class="col-md-10 aos-init aos-animate" data-aos="flip-up">
          <h2 class="stack_font2 h1 ">
            Reset Password
          </h2>
        </div>
      </div>
    </div>
  </div>
  <div class="pagecomponentwrap text-left ">
    <div class="container pb-5 pt-5 px-4">
      <div class="kt-grid">
        <div class="kt-grid__item kt-grid__item--fluid kt-wizard-v4__wrapper">
          <div class="kt-wizard-v4__content text-center">
            <div class="kt-section kt-section--first">
              <div class="kt-wizard-v4__form">
                <div class="font-italic">Note : isian bertanda * wajib diisi.</div>
                <app-alert-common *ngIf="errors.length !== 0" [errors]="errors"></app-alert-common>
                <form class="kt-form" #registration="ngForm" (ngSubmit)="save(registration)">
                  <div class="row justify-content-center text-left">
                    <div class="col-lg-10">
                      <div class="kt-section__body">
                        <div class="form-group row">
                          <div class="col-12">
                            <h3 class="bg-color2 text-white px-3 py-3 rounded h5">
                              New Password
                            </h3>
                          </div>
                        </div>
                        <div class="form-group row">
                          <label class="col-xl-3 col-lg-3 col-form-label">
                            Password*
                          </label>
                          <div class="col-lg-9 col-xl-9">
                            <div class="input-group">
                              <div class="input-group-prepend">
                                <span class="input-group-text">
                                  <i class="la la-lock"></i>
                                </span>
                              </div>
                              <input #password="ngModel" type="password" class="form-control" id="password"
                                name="password" placeholder="********" [(ngModel)]="users.password" required
                                [class.is-invalid]="password.invalid && password.touched"
                                pattern="^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[A-Za-z\d]{8,}$">
                            </div>
                            <span class="form-text text-muted">
                              Password minimal 8 karakter, terdiri dari minimal satu huruf besar, satu huruf kecil dan
                              satu angka. Tidak diijinkan menggunakan karakter unik dan simbol.
                            </span>
                            <span class="help-block" [hidden]="password.valid || password.untouched">
                              <div class="red-text" *ngIf="password.errors && password.errors.required">*password tidak
                                boleh kosong.</div>
                              <div class="red-text" *ngIf="password.errors && password.errors.pattern">*password tidak
                                valid.</div>
                            </span>
                          </div>
                        </div>
                        <div class="form-group row">
                          <label class="col-xl-3 col-lg-3 col-form-label">
                            Confirm Password*
                          </label>
                          <div class="col-lg-9 col-xl-9">
                            <div class="input-group">
                              <div class="input-group-prepend">
                                <span class="input-group-text">
                                  <i class="la la-lock"></i>
                                </span>
                              </div>
                              <input #repassword="ngModel" type="password" class="form-control" id="repassword"
                                name="repassword" placeholder="********" [(ngModel)]="users.repassword" required
                                [class.is-invalid]="repassword.invalid && repassword.touched">
                            </div>
                            <span class="form-text text-muted">
                              Masukan ulang password Anda
                            </span>
                            <span class="help-block" [class.d-none]="repassword.valid || repassword.untouched">
                              <div class="red-text">*confirm password tidak boleh kosong.</div>
                            </span>
                          </div>
                        </div>
                        <div class="form-group row bg-light px-3 py-3 border rounded">
                          <div class="col-6 text-left"></div>
                          <div class="col-6 text-right">
                            <button class="btn btn-brand btn-md btn-tall btn-wide kt-font-bold kt-font-transform-u">
                              Submit
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>

          <!--end: Form Wizard Step 1-->
          <!--end: Form Wizard Form-->
        </div>
      </div>
    </div>
  </div>
  <div class="clear"></div>
</div>