import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { GlobalService } from './global.service';
import { Observable } from 'rxjs';
import { ResponseApiModel } from '../_models/response-api.model';
import { map } from 'rxjs/operators';
import { CookieService } from 'ngx-cookie-service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AlertModalComponent } from '../layout/shared/alert-modal/alert-modal.component';
import Swal from 'sweetalert2';


const API_GET_ATTENDANCE = '/attendance/attendance/get-list-attendances';
const API_GET_ATTENDANCE_V2 = '/attendance/default/list-attendance-v2';
const API_GET_CLOCK_OUT = '/attendance/default/get-clock-out';
const API_GET_NEAREST_OFFICE = '/attendance/attendance/nearest-office';
const API_NEW_ATTENDANCE = '/attendance/attendance/new-attendance';
const API_MOVE_OFFICE = '/attendance/attendance/move-office';
const API_GO_HOME = '/attendance/attendance/go-home';
const API_GET_MY_ATTENDANCE = '/attendance/default/get-my-attendance';
const API_GET_MY_ATTENDANCE_REPORT = '/attendance/default/get-my-attendance-report';
const API_EXPORT_MY_ATTENDANCE_REPORT = '/attendance/default/export-my-attendance-report';
const API_GET_MY_ATTENDANCE_DETAIL = '/attendance/default/get-my-attendance-detail';
const API_GET_MY_TEAM_ATTENDANCE_SUMMARY = '/attendance/default/get-my-team-attendance-summary';
const API_GET_MY_TEAM_ATTENDANCE = '/attendance/default/get-my-team-attendance';
const API_GET_MY_TEAM_TODAY = '/attendance/default/get-my-team-today';
const API_GET_LIST_MY_TEAM = '/attendance/default/get-list-my-team';
const API_GET_MY_TEAM_ATTENDANCE_DETAIL = '/attendance/default/get-my-team-attendance-detail';
const API_GET_DAILY_ATTENDANCE_SUMMARY = '/attendance/default/get-daily-attendance-summary';
const API_GET_DAILY_ATTENDANCE_SUMMARY_DETAIL = '/attendance/default/get-daily-attendance-summary-detail';
const API_GET_MONTHLY_ATTENDANCE_DETAIL = '/attendance/default/get-monthly-attendance-detail';
const API_GET_LOW_PERFORMANCE = '/attendance/default/low-performance';
const API_GET_SUMMARY_ATTENDANCE = '/attendance/default/get-summary-attendance';
const API_GET_MY_PERMISSION_REQUEST = '/attendance/default/get-my-permission-request';
const API_GET_UNPAID_LEAVE_REQUEST = '/attendance/default/get-unpaid-leave-request';
const API_GET_PERMISSION_DETAIL = '/attendance/default/get-permission-detail';
const API_GET_UNPAID_LEAVE_DETAIL = '/attendance/default/get-unpaid-leave-detail';
const API_GET_TL = '/attendance/default/get-team';
const API_GET_REASONS = '/attendance/default/get-reasons';
const API_GET_MY_TEAM = '/attendance/default/get-my-team';
const API_GET_NOT_RECORDED = '/attendance/default/get-not-recorded-attendance';
const API_CREATE_PERMISSION_REQUEST = '/attendance/default/create-permission-request';
const API_CREATE_UNPAID_LEAVE_REQUEST = '/attendance/default/create-unpaid-leave-request';
const API_APPROVAL_PERMISSION = '/attendance/approval/request-approval';
const API_GET_MY_APPROVAL = '/attendance/approval/get-my-approval';
const API_GET_LIST_APPROVAL = '/attendance/approval/get-list-approval';
const API_REMINDER = '/attendance/approval/send-reminder';
const API_REMINDER_ALL = '/attendance/approval/send-reminder-all';
const API_APPROVAL_UNPAID_LEAVE = '/attendance/default/approval-unpaid-leave';
const API_EXPORT_UNPAID_LEAVE = '/attendance/default/export-unpaid-leave';
const API_APPROVE = '/attendance/approval/approve';
const API_REJECT = '/attendance/approval/reject';
const API_CONFIRM = '/attendance/approval/confirm';
const API_GET_PERMISSION_RULE = '/attendance/default/get-permission-rule';
const API_JUSTIFICATION = '/attendance/dev/justification';
const API_CHECK_JUSTIFICATION = '/attendance/dev/check-justification';
const API_CHECK_ATTENDANCE = '/home/check-attendance';
const API_HOME = '/attendance/home/get-home-all';
const API_HOME_DETAIL = '/attendance/home/get-home';
const API_VIEW_UPLOAD = '/attendance/home/view-upload';
const API_CHANGE_STATUS = '/attendance/home/change-status';
const API_USERS_HOME = '/attendance/dev/users-home';
const API_APPROVE_HOME = '/attendance/dev/approve-home';
const API_NOCLOCKOUT = '/attendance/dev/no-clock-out';
const API_NOCLOCKOUTEXCEL = '/attendance/dev/no-clock-out-excel';
const API_EXPORT_MY_TEAM_ATTENDANCE = '/attendance/default/export-my-team-attendance';
const API_EXPORT_MY_TEAM_ATTENDANCE_SUMMARY = '/attendance/default/export-my-team-attendance-summary';
const API_JUSTIFICATION_REQUEST = '/attendance/default/justification-request';
const API_JUSTIFICATION_REQUEST_PROGRESS = '/attendance/default/justification-request-progress';
const API_UNPAID_LEAVE_REQUEST_PROGRESS = '/attendance/default/unpaid-leave-request-progress';
const API_JUSTIFICATION_REQUEST_DETAIL = '/attendance/default/justification-request-detail';
const API_SUBMIT_JUSTIFICATION_REQUEST = '/attendance/default/submit-justification-request';
const API_JUSTIFICATION_APPROVAL = '/attendance/default/justification-approval';
const API_SUBMIT_JUSTIFICATION_APPROVAL = '/attendance/default/submit-justification-approval';
const API_IMPORT_OFFICELIST = '/attendance/default/import-officelist';
const API_PICTURE_ATTENDANCE = '/attendance/default/get-picture-attendance';
const API_GET_VIEW_EXPORT_ATTENDANCE = '/attendance/default/get-attendance';
const API_EXPORT_ATTENDANCE = '/attendance/default/export-attendance';
const API_EXPORT_JUSTIFICATION_REQUEST = '/attendance/default/export-justification-request';
const API_EXPORT_JUSTIFICATION_APPROVAL = '/attendance/default/export-justification-approval';
const API_APPROVAL_ALL = '/attendance/default/approve-all-justification';
const API_GET_MY_TEAM_PREVIEW = '/attendance/default/get-my-team-attendance';//apinya sama, tapi hanya di ambil image saja
@Injectable({
  providedIn: 'root'
})
export class AttendanceService {

  constructor(
    private http: HttpClient,
    private globalService: GlobalService,
    private cookieService: CookieService,
    private modalService: NgbModal

  ) { }

  public attendanceHeader() {
    return {
      headers: new HttpHeaders()
        .set('token', this.cookieService.get('_q'))
        .set('module', localStorage.getItem('attendance'))
        .set('group', this.cookieService.get('_g'))
    };
  }

  getAttendance(param?: { pageSize: number }): Observable<ResponseApiModel> {
    return this.http.post<ResponseApiModel>(
      this.globalService.apiVersionHost + API_GET_ATTENDANCE, param, this.attendanceHeader()
    ).pipe(
      map(response => response)
    );
  }

  getAttendancev2(param): Observable<ResponseApiModel> {
    return this.http.post<ResponseApiModel>(
      this.globalService.apiVersionHost + API_GET_ATTENDANCE_V2, param, this.attendanceHeader()
    ).pipe(
      map(response => response)
    );
  }

  getClockOut(param): Observable<ResponseApiModel> {
    return this.http.post<ResponseApiModel>(
      this.globalService.apiVersionHost + API_GET_CLOCK_OUT, param, this.attendanceHeader()
    ).pipe(
      map(response => response)
    );
  }

  getNearestOffice(param?: { latitude: number, longitude: number }): Observable<ResponseApiModel> {
    return this.http.post<ResponseApiModel>(
      this.globalService.apiVersionHost + API_GET_NEAREST_OFFICE, param, this.attendanceHeader()
    ).pipe(
      map(response => response)
    );
  }

  newAttendance(param?: {
    id_office: number, image_id: number, timezone: any, latitude: string, longitude: string
  }): Observable<ResponseApiModel> {
    return this.http.post<ResponseApiModel>(
      this.globalService.apiVersionHost + API_NEW_ATTENDANCE, param, this.attendanceHeader()
    ).pipe(
      map(response => response)
    );
  }

  moveOffice(param?: {
    id_office: number, image_id: number, timezone: any, latitude: string, longitude: string
  }): Observable<ResponseApiModel> {
    return this.http.post<ResponseApiModel>(
      this.globalService.apiVersionHost + API_MOVE_OFFICE, param, this.attendanceHeader()
    ).pipe(
      map(response => response)
    );
  }

  goHome(param?: {
    id_office: number, image_id: number, timezone: any, latitude: string, longitude: string
  }): Observable<ResponseApiModel> {
    return this.http.post<ResponseApiModel>(
      this.globalService.apiVersionHost + API_GO_HOME, param, this.attendanceHeader()
    ).pipe(
      map(response => response)
    );
  }

  public getSummaryAttendance(params): Observable<ResponseApiModel> {
    return this.http
      .post<ResponseApiModel>(
        this.globalService.apiVersionHost +
        API_GET_SUMMARY_ATTENDANCE,
        params,
        this.attendanceHeader()
      )
      .pipe(map(response => response));
  }

  getMyAttendance(param?: { userid: any, fromDate: string, toDate: string }): Observable<ResponseApiModel> {
    return this.http.post<ResponseApiModel>(
      this.globalService.apiVersionHost + API_GET_MY_ATTENDANCE, param, this.attendanceHeader()
    ).pipe(
      map(response => response)
    );
  }

  getMyAttendanceReport(param?: { userid: any, fromDate: string, toDate: string }): Observable<ResponseApiModel> {
    return this.http.post<ResponseApiModel>(
      this.globalService.apiVersionHost + API_GET_MY_ATTENDANCE_REPORT, param, this.attendanceHeader()
    ).pipe(
      map(response => response)
    );
  }

  exportMyAttendanceReport(param?: { userid: any, fromDate: string, toDate: string }): Observable<ResponseApiModel> {
    return this.http.post<ResponseApiModel>(
      this.globalService.apiVersionHost + API_EXPORT_MY_ATTENDANCE_REPORT, param, this.attendanceHeader()
    ).pipe(
      map(response => response)
    );
  }

  exportMyTeamAttendance(param): Observable<ResponseApiModel> {
    return this.http.post<ResponseApiModel>(
      this.globalService.apiVersionHost + API_EXPORT_MY_TEAM_ATTENDANCE, param, this.attendanceHeader()
    ).pipe(map(response => response));
  }

  exportMyTeamAttendanceSummary(param): Observable<ResponseApiModel> {
    return this.http.post<ResponseApiModel>(
      this.globalService.apiVersionHost + API_EXPORT_MY_TEAM_ATTENDANCE_SUMMARY, param, this.attendanceHeader()
    ).pipe(map(response => response));
  }

  getMyAttendanceDetail(param?: { userid: any, fromDate: string, toDate: string }): Observable<ResponseApiModel> {
    return this.http.post<ResponseApiModel>(
      this.globalService.apiVersionHost + API_GET_MY_ATTENDANCE_DETAIL, param, this.attendanceHeader()
    ).pipe(
      map(response => response)
    );
  }

  getMyTeamAttendanceSummary(param?: { fromDate: string, toDate: string }): Observable<ResponseApiModel> {
    return this.http.post<ResponseApiModel>(
      this.globalService.apiVersionHost + API_GET_MY_TEAM_ATTENDANCE_SUMMARY, param, this.attendanceHeader()
    ).pipe(
      map(response => response)
    );
  }

  getMyTeamAttendance(param?: { fromDate: string, toDate: string }): Observable<ResponseApiModel> {
    return this.http.post<ResponseApiModel>(
      this.globalService.apiVersionHost + API_GET_MY_TEAM_ATTENDANCE, param, this.attendanceHeader()
    ).pipe(
      map(response => response)
    );
  }

  getViewExportAttendance(param?: { fromDate: string, toDate: string }): Observable<ResponseApiModel> {
    return this.http.post<ResponseApiModel>(
      this.globalService.apiVersionHost + API_GET_VIEW_EXPORT_ATTENDANCE, param, this.attendanceHeader()
    ).pipe(
      map(response => response)
    );
  }

  getExportAttendance(param?: {fromDate: string, toDate: string}): Observable<ResponseApiModel> {
    return this.http.post<ResponseApiModel>(
      this.globalService.apiVersionHost + API_EXPORT_ATTENDANCE, param, this.attendanceHeader()
    ).pipe(
      map(response => response)
    );
  }

  getMyTeamToday(param: { currentDate: string, page: number, pageSize: number, filter: any }): Observable<ResponseApiModel> {
    return this.http.post<ResponseApiModel>(
      this.globalService.apiVersionHost + API_GET_MY_TEAM_TODAY, param, this.attendanceHeader()
    ).pipe(
      map(response => response)
    );
  }

  getListMyTeam(): Observable<ResponseApiModel> {
    return this.http.get<ResponseApiModel>(
      this.globalService.apiVersionHost + API_GET_LIST_MY_TEAM, this.attendanceHeader()
    ).pipe(
      map(response => response)
    );
  }

  getMyTeam(): Observable<ResponseApiModel> {
    return this.http.get<ResponseApiModel>(
      this.globalService.apiVersionHost + API_GET_MY_TEAM, this.attendanceHeader()
    ).pipe(
      map(response => response)
    );
  }

  getMyTeamAttendanceDetail(param?: { fromDate: string, toDate: string }): Observable<ResponseApiModel> {
    return this.http.post<ResponseApiModel>(
      this.globalService.apiVersionHost + API_GET_MY_TEAM_ATTENDANCE_DETAIL, param, this.attendanceHeader()
    ).pipe(
      map(response => response)
    );
  }

  getDailyAttendanceSummary(param?: { date: string }): Observable<ResponseApiModel> {
    return this.http.post<ResponseApiModel>(
      this.globalService.apiVersionHost + API_GET_DAILY_ATTENDANCE_SUMMARY, param, this.attendanceHeader()
    ).pipe(
      map(response => response)
    );
  }

  getDailyAttendanceSummaryDetail(param?: { date: string, remark: string }): Observable<ResponseApiModel> {
    return this.http.post<ResponseApiModel>(
      this.globalService.apiVersionHost + API_GET_DAILY_ATTENDANCE_SUMMARY_DETAIL, param, this.attendanceHeader()
    ).pipe(
      map(response => response)
    );
  }

  getMonthlyAttendanceDetail(param: { userid: number, firstDay: string, lastDay: string }): Observable<ResponseApiModel> {
    return this.http.post<ResponseApiModel>(
      this.globalService.apiVersionHost + API_GET_MONTHLY_ATTENDANCE_DETAIL, param, this.attendanceHeader()
    ).pipe(
      map(response => response)
    );
  }

  getLowPerformance(param: any): Observable<ResponseApiModel> {
    return this.http.post<ResponseApiModel>(
      this.globalService.apiVersionHost + API_GET_LOW_PERFORMANCE, param, this.attendanceHeader()
    ).pipe(
      map(response => response)
    );
  }

  getSummaryPermission(param:{filter:any,from:string,to:string}): Observable<ResponseApiModel> {
    return this.http.post<ResponseApiModel>(
      this.globalService.apiVersionHost + "/attendance/default/summary-excused-absence", param, this.attendanceHeader()
    ).pipe(
      map(response => response)
    );
  }
  getDashboardAttendance(param:{from:string,to:string}): Observable<ResponseApiModel> {
    return this.http.post<ResponseApiModel>(
      this.globalService.apiVersionHost + "/attendance/default/dashboard-attendance", param, this.attendanceHeader()
    ).pipe(
      map(response => response)
    );
  }

  getUnpaidLeaveRequest(param: { pageSize: number, page: number, filter: any }): Observable<ResponseApiModel> {
    return this.http.post<ResponseApiModel>(
      this.globalService.apiVersionHost + API_GET_UNPAID_LEAVE_REQUEST, param, this.attendanceHeader()
    ).pipe(
      map(response => response)
    );
  }

  getUnpaidLeaveDetail(param: { id_unpaid_leave: number }): Observable<ResponseApiModel> {
    return this.http.post<ResponseApiModel>(
      this.globalService.apiVersionHost + API_GET_UNPAID_LEAVE_DETAIL, param, this.attendanceHeader()
    ).pipe(
      map(response => response)
    );
  }

  getMyRequestPermission(param: { pageSize: number, page: number, filter: any }): Observable<ResponseApiModel> {
    return this.http.post<ResponseApiModel>(
      this.globalService.apiVersionHost + API_GET_MY_PERMISSION_REQUEST, param, this.attendanceHeader()
    ).pipe(
      map(response => response)
    );
  }

  getPermissionDetail(param: { id_attendance_permission: number }): Observable<ResponseApiModel> {
    return this.http.post<ResponseApiModel>(
      this.globalService.apiVersionHost + API_GET_PERMISSION_DETAIL, param, this.attendanceHeader()
    ).pipe(
      map(response => response)
    );
  }
  getCrew(): Observable<ResponseApiModel> {
    return this.http.get<ResponseApiModel>(
      this.globalService.apiVersionHost + API_GET_TL, this.attendanceHeader()
    ).pipe(
      map(response => response)
    );
  }

  getReasons(): Observable<ResponseApiModel> {
    return this.http.post<ResponseApiModel>(
      this.globalService.apiVersionHost + API_GET_REASONS, null, this.attendanceHeader()
    ).pipe(
      map(response => response)
    );
  }

  // tslint:disable-next-line: max-line-length
  getNotRecorded(param: { page: number, pageSize: number, filter: any, order: string, range: string, underBy: string }): Observable<ResponseApiModel> {
    return this.http.post<ResponseApiModel>(
      this.globalService.apiVersionHost + API_GET_NOT_RECORDED, param, this.attendanceHeader()
    ).pipe(
      map(response => response)
    );
  }

  createPermissionRequest(param: any): Observable<ResponseApiModel> {
    return this.http.post<ResponseApiModel>(
      this.globalService.apiVersionHost + API_CREATE_PERMISSION_REQUEST, param, this.attendanceHeader()
    ).pipe(
      map(response => response)
    );
  }

  createUnpaidLeaveRequest(param: any): Observable<ResponseApiModel> {
    return this.http.post<ResponseApiModel>(
      this.globalService.apiVersionHost + API_CREATE_UNPAID_LEAVE_REQUEST, param, this.attendanceHeader()
    ).pipe(
      map(response => response)
    );
  }

  approvalPermissionRequest(param: any): Observable<ResponseApiModel> {
    return this.http.post<ResponseApiModel>(
      this.globalService.apiVersionHost + API_APPROVAL_PERMISSION, param, this.attendanceHeader()
    ).pipe(
      map(response => response)
    );
  }

  getMyApproval(param: any): Observable<ResponseApiModel> {
    return this.http.post<ResponseApiModel>(
      this.globalService.apiVersionHost + API_GET_MY_APPROVAL, param, this.attendanceHeader()
    ).pipe(
      map(response => response)
    );
  }

  getListPermission(param: any): Observable<ResponseApiModel> {
    return this.http.post<ResponseApiModel>(
      this.globalService.apiVersionHost + API_GET_LIST_APPROVAL, param, this.attendanceHeader()
    ).pipe(
      map(response => response)
    );
  }

  remindById(param: any): Observable<ResponseApiModel> {
    return this.http.post<ResponseApiModel>(
      this.globalService.apiVersionHost + API_REMINDER, param, this.attendanceHeader()
    ).pipe(
      map(response => response)
    );
  }

  remindAll(param: any): Observable<ResponseApiModel> {
    return this.http.post<ResponseApiModel>(
      this.globalService.apiVersionHost + API_REMINDER_ALL, param, this.attendanceHeader()
    ).pipe(
      map(response => response)
    );
  }

  approve(id: number): Observable<ResponseApiModel> {
    return this.http.post<ResponseApiModel>(
      this.globalService.apiVersionHost + API_APPROVE, { id: id }, this.attendanceHeader()
    ).pipe(
      map(response => response)
    );
  }

  reject(param: { id: number, reject_note: string }): Observable<ResponseApiModel> {
    return this.http.post<ResponseApiModel>(
      this.globalService.apiVersionHost + API_REJECT, param, this.attendanceHeader()
    ).pipe(
      map(response => response)
    );
  }

  confirm(param: { id: number, proof: string }): Observable<ResponseApiModel> {
    return this.http.post<ResponseApiModel>(
      this.globalService.apiVersionHost + API_CONFIRM, param, this.attendanceHeader()
    ).pipe(
      map(response => response)
    );
  }

  getPermissionRule(permission: string): Observable<ResponseApiModel> {
    return this.http.post<ResponseApiModel>(
      this.globalService.apiVersionHost + API_GET_PERMISSION_RULE, { permission: permission }, this.attendanceHeader()
    ).pipe(
      map(response => response)
    );
  }

  justification(params: { userid: number, from: string, to: string, startWork: string, endWork: string }): Observable<ResponseApiModel> {
    return this.http.post<ResponseApiModel>(
      this.globalService.apiVersionHost + API_JUSTIFICATION, params, this.attendanceHeader()
    ).pipe(
      map(response => response)
    );
  }

  checkJustification(params): Observable<ResponseApiModel> {
    return this.http.post<ResponseApiModel>(
      this.globalService.apiVersionHost + API_CHECK_JUSTIFICATION, params, this.attendanceHeader()
    ).pipe(
      map(response => response)
    );
  }

  checkAttendance(): Observable<ResponseApiModel> {
    const headers = {
      headers: new HttpHeaders()
        .set('token', this.cookieService.get('_q'))
    };

    return this.http.post<ResponseApiModel>(
      this.globalService.apiVersionHost + API_CHECK_ATTENDANCE, null, headers
    ).pipe(
      map(response => response)
    );
  }


  getHomeAll(param: { pageSize: number, page: number, filter: any }): Observable<ResponseApiModel> {
    return this.http.post<ResponseApiModel>(
      this.globalService.apiVersionHost + API_HOME, param, this.attendanceHeader()
    ).pipe(
      map(response => response)
    );
  }

  alertModal(err: string, code?: number) {
    if (code === 403) {
      return Swal.fire(err);
    }
    const modalRef = this.modalService.open(AlertModalComponent);
    modalRef.componentInstance.kategori = 'alert';
    modalRef.componentInstance.error = (err) ? err : 'Request Timeout.';
  }

  getHomeDetail(param): Observable<ResponseApiModel> {
    return this.http.post<ResponseApiModel>(
      this.globalService.apiVersionHost + API_HOME_DETAIL, param, this.attendanceHeader()
    ).pipe(
      map(response => response)
    );
  }

  getViewUpload(param): Observable<ResponseApiModel> {
    return this.http.post<ResponseApiModel>(
      this.globalService.apiVersionHost + API_VIEW_UPLOAD, param, this.attendanceHeader()
    ).pipe(
      map(response => response)
    );
  }

  changeStatus(param): Observable<ResponseApiModel> {
    return this.http.post<ResponseApiModel>(
      this.globalService.apiVersionHost + API_CHANGE_STATUS, param, this.attendanceHeader()
    ).pipe(
      map(response => response)
    );
  }

  getUsersHome(param: { page: number, pageSize: number, filter: any }): Observable<ResponseApiModel> {
    return this.http.post<ResponseApiModel>(
      this.globalService.apiVersionHost + API_USERS_HOME, param, this.attendanceHeader()
    ).pipe(
      map(response => response)
    );
  }

  approveHome(): Observable<ResponseApiModel> {
    return this.http.post<ResponseApiModel>(
      this.globalService.apiVersionHost + API_APPROVE_HOME, null, this.attendanceHeader()
    ).pipe(
      map(response => response)
    );
  }

  getNoClockOut(param): Observable<ResponseApiModel> {
    return this.http.post<ResponseApiModel>(
      this.globalService.apiVersionHost + API_NOCLOCKOUT, param, this.attendanceHeader()
    ).pipe(
      map(response => response)
    );
  }

  noClockOutExcel(param): Observable<ResponseApiModel> {
    return this.http.post<ResponseApiModel>(
      this.globalService.apiVersionHost + API_NOCLOCKOUTEXCEL, param, this.attendanceHeader()
    ).pipe(
      map(response => response)
    );
  }

  getJustificationRequest(param: { page: number, pageSize: number, filter: any }): Observable<ResponseApiModel> {
    return this.http.post<ResponseApiModel>(
      this.globalService.apiVersionHost + API_JUSTIFICATION_REQUEST, param, this.attendanceHeader()
    ).pipe(
      map(response => response)
    );
  }

  exportJustificationRequest(param: { filter: any }): Observable<ResponseApiModel> {
    return this.http.post<ResponseApiModel>(
      this.globalService.apiVersionHost + API_EXPORT_JUSTIFICATION_REQUEST, param, this.attendanceHeader()
    ).pipe(map(response => response));
  }

  getJustificationRequestProgress(param: { id: number }): Observable<ResponseApiModel> {
    return this.http.post<ResponseApiModel>(
      this.globalService.apiVersionHost + API_JUSTIFICATION_REQUEST_PROGRESS, param, this.attendanceHeader()
    ).pipe(
      map(response => response)
    );
  }
  listAttendancePoint(param: { page: number, pageSize: number, filter: any }): Observable<ResponseApiModel> {
    return this.http.post<ResponseApiModel>(
      this.globalService.apiVersionHost + '/attendance/default/list-attendance-point', param, this.attendanceHeader()
    ).pipe(
      map(response => response)
    );
  }
  listApprovalAttendancePoint(param: { page: number, pageSize: number, filter: any }): Observable<ResponseApiModel> {
    return this.http.post<ResponseApiModel>(
      this.globalService.apiVersionHost + '/attendance/default/list-approval-attendance-point', param, this.attendanceHeader()
    ).pipe(
      map(response => response)
    );
  }
  getAttendancePointProgress(param: { id: number }): Observable<ResponseApiModel> {
    return this.http.post<ResponseApiModel>(
      this.globalService.apiVersionHost + "/attendance/default/attendance-point-progress", param, this.attendanceHeader()
    ).pipe(
      map(response => response)
    );
  }
  newAttendancePoint(param: { data: any }): Observable<ResponseApiModel> {
    return this.http.post<ResponseApiModel>(
      this.globalService.apiVersionHost + "/attendance/default/new-attendance-point", param, this.attendanceHeader()
    ).pipe(
      map(response => response)
    );
  }
  detailAttendancePoint(param: { id:number }): Observable<ResponseApiModel> {
    return this.http.post<ResponseApiModel>(
      this.globalService.apiVersionHost + "/attendance/default/attendance-point-detail", param, this.attendanceHeader()
    ).pipe(
      map(response => response)
    );
  }
  approvalAttendancePoint(param: { id:number,action:string }): Observable<ResponseApiModel> {
    return this.http.post<ResponseApiModel>(
      this.globalService.apiVersionHost + "/attendance/default/attendance-point-approval", param, this.attendanceHeader()
    ).pipe(
      map(response => response)
    );
  }

  getUnpaidLeaveRequestProgress(param: { id: number }): Observable<ResponseApiModel> {
    return this.http.post<ResponseApiModel>(
      this.globalService.apiVersionHost + API_UNPAID_LEAVE_REQUEST_PROGRESS, param, this.attendanceHeader()
    ).pipe(
      map(response => response)
    );
  }

  approvalUnpaidLeave(id: number, reject_note: string , type: string ): Observable<ResponseApiModel> {
    return this.http.post<ResponseApiModel>(
      this.globalService.apiVersionHost + API_APPROVAL_UNPAID_LEAVE, { id: id,reject_note:reject_note,type:type }, this.attendanceHeader()
    ).pipe(
      map(response => response)
    );
  }

  exportTrackerUnpaidLeave(param: { filter: any }): Observable<ResponseApiModel> {
    return this.http.post<ResponseApiModel>(
      this.globalService.apiVersionHost + API_EXPORT_UNPAID_LEAVE, param, this.attendanceHeader()
    ).pipe(
      map(response => response)
    );
  }

  getJustificationRequestDetail(param: { id: number }): Observable<ResponseApiModel> {
    return this.http.post<ResponseApiModel>(
      this.globalService.apiVersionHost + API_JUSTIFICATION_REQUEST_DETAIL, param, this.attendanceHeader()
    ).pipe(
      map(response => response)
    );
  }

  submitJustificationRequest(param: any): Observable<ResponseApiModel> {
    return this.http.post<ResponseApiModel>(
      this.globalService.apiVersionHost + API_SUBMIT_JUSTIFICATION_REQUEST, param, this.attendanceHeader()
    ).pipe(
      map(response => response)
    );
  }

  getJustificationApproval(param: { page: number, pageSize: number, filter: any }): Observable<ResponseApiModel> {
    return this.http.post<ResponseApiModel>(
      this.globalService.apiVersionHost + API_JUSTIFICATION_APPROVAL, param, this.attendanceHeader()
    ).pipe(
      map(response => response)
    );
  }

  exportJustificationApproval(param: { filter: any }): Observable<ResponseApiModel> {
    return this.http.post<ResponseApiModel>(
      this.globalService.apiVersionHost + API_EXPORT_JUSTIFICATION_APPROVAL, param, this.attendanceHeader()
    ).pipe(map(response => response));
  }

  submitJustificationApproval(param: any): Observable<ResponseApiModel> {
    return this.http.post<ResponseApiModel>(
      this.globalService.apiVersionHost + API_SUBMIT_JUSTIFICATION_APPROVAL, param, this.attendanceHeader()
    ).pipe(
      map(response => response)
    );
  }

  approveAll(param: { listId: any }): Observable<ResponseApiModel> {
    return this.http.post<ResponseApiModel>(
      this.globalService.apiVersionHost + API_APPROVAL_ALL, param, this.attendanceHeader()
    ).pipe(
      map(response => response)
    );
  }

  importOfficelist(param: any): Observable<ResponseApiModel> {
    return this.http.post<ResponseApiModel>(
      this.globalService.apiVersionHost + API_IMPORT_OFFICELIST, param, this.attendanceHeader()
    ).pipe(
      map(response => response)
    );
  }

  public getPictureAttendance(param): Observable<ResponseApiModel> {
    return this.http.post<ResponseApiModel>(
      this.globalService.apiVersionHost + API_PICTURE_ATTENDANCE, param, this.attendanceHeader()
    ).pipe(
      map(response => response)
    );
  }

  getWfo(param? : {fromDate: string, toDate: string}): Observable<ResponseApiModel> {
    return this.http.post<ResponseApiModel>(
      this.globalService.apiVersionHost + '/attendance/default/get-wfo', param, this.attendanceHeader()
    ).pipe(
      map(response => response)
    );
  }

  exportWfo(param): Observable<ResponseApiModel> {
    return this.http.post<ResponseApiModel>(
      this.globalService.apiVersionHost + '/attendance/default/export-wfo', param, this.attendanceHeader()
    ).pipe(
      map(response => response)
    );
  }

  getMyTeamPreview(param?: { fromDate: string, toDate: string }): Observable<ResponseApiModel> {
    return this.http.post<ResponseApiModel>(
      this.globalService.apiVersionHost + API_GET_MY_TEAM_PREVIEW, param, this.attendanceHeader()
    ).pipe(
      map(response => response)
    );
  }
}
