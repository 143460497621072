import { Injectable } from '@angular/core';
import { DexieService } from 'ngx-dexie';

@Injectable({
  providedIn: 'root'
})
export class IndexedDbService {

  constructor(
    private dexieService: DexieService
  ) { }

  addOne(table: string, object: Object) {
    return this.dexieService.addOne(table, object);
  }

  addMultiple(table: string, objects: Object[]) {
      return this.dexieService.addMultiple(table, objects);
  }

  count(table: string) {
      return this.dexieService.count(table);
  }

  addOrUpdateOne(table: string, object: Object, key?: any) {
      if (key) {
          return this.dexieService.addOrUpdateOne(table, object, key);
      } else {
          return this.dexieService.addOrUpdateOne(table, object);
      }
  }

  addOrUpdateMultiple(table: string, objects: Object[], keys?: any) {
      if (keys) {
          return this.dexieService.addOrUpdateMultiple(table, objects, keys);
      } else {
          return this.dexieService.addOrUpdateMultiple(table, objects);
      }
  }

  deleteOne(table: string, primaryKey: any) {
      return this.dexieService.deleteOne(table, primaryKey);
  }

  deleteMultiple(table: string, primaryKeys: any[]) {
      return this.dexieService.deleteMultiple(table, primaryKeys);
  }

  clearAll(table: string) {
      return this.dexieService.clearAll(table);
  }

  operateOnEach(table: string, callback: (item: any, idbCursor: any) => any) {
      return this.dexieService.operateOnEach(table, callback);
  }

  filter(table: string, filterFunction: (value: any) => boolean) {
      return this.dexieService.filter(table, filterFunction);
  }

  getByPrimaryKey(table: string, primaryKey: any, callback?: (item: Object) => any) {
      if (callback) {
          return this.dexieService.getByPrimaryKey(table, primaryKey, callback);
      } else {
          return this.dexieService.getByPrimaryKey(table, primaryKey);
      }
  }

  getByKeyToValueMap(table: string, keyValueMap: Object, callback?: (item: Object) => any) {
      if (callback) {
          return this.dexieService.getByKeyToValueMap(table, keyValueMap, callback);
      } else {
          return this.dexieService.getByKeyToValueMap(table, keyValueMap);
      }
  }

  limit(table: string, num: number) {
      return this.dexieService.getFirstNItemsOfTable(table, num);
  }

  orderBy(table: string, index: string) {
      return this.dexieService.orderBy(table, index);
  }

  offset(table: string, ignoreUpto: number) {
      return this.dexieService.offset(table, ignoreUpto);
  }

  reverse(table: string) {
      return this.dexieService.reverse(table);
  }

  toArray(table: string, callback?: (objects: Object[]) => any) {
      if (callback) {
          return this.dexieService.toArray(table, callback);
      } else {
          return this.dexieService.toArray(table);
      }
  }

  toCollection(table: string) {
      return this.dexieService.toCollection(table);
  }

  update(table: string, key: any, changes: Object) {
      return this.dexieService.update(table, key, changes);
  }
}
