import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { PageScrollService } from 'ngx-page-scroll-core';

@Component({
  selector: 'app-header5g',
  templateUrl: './header5g.component.html',
  styleUrls: ['./header5g.component.scss']
})
export class Header5gComponent implements OnInit {

  expandMenu: boolean;
  collapsing: boolean;

  constructor() { }

  ngOnInit() {
  }

  delay(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  async menuToggle() {
    this.collapsing = !this.collapsing;
    await this.delay(200);
    this.expandMenu = !this.expandMenu;
    this.collapsing = !this.collapsing;
  }

}
