import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { GlobalService } from './global.service';
import { Observable, throwError, Subject, forkJoin } from 'rxjs';
import { ResponseApiModel } from '../_models/response-api.model';
import { map, catchError } from 'rxjs/operators';
import { CookieService } from 'ngx-cookie-service';
import { userprojectcode } from '../_models/userprojectcode.model';
import { Editfull } from '../_models/editfull.model';
import Swal from 'sweetalert2';
import { AlertModalComponent } from '../layout/shared/alert-modal/alert-modal.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

const API_EMPLOYEES = '/employee/hrd/get-users';
const API_EDIT_EMPLOYEE = '/employee/hrd/edit-user';
const API_GET_REMINDER = '/employee/users/get-reminder';
const API_GET_APPROVAL_EMPLOYEE = '/employee/users/list-approval-employee';
const API_GET_DETAIL_APPROVAL = '/employee/users/detail-approval';
const API_APPROVE_UPDATE_DATA = '/employee/hrd/approve-update-data';
const API_ALL_LOCATION = '/employee/users/get-all-location';
const API_LIST_PCODE = '/employeeAdmin/dev/get-list-projectcode';
const API_LIST_PCODE_SELECT2 = '/employeeAdmin/dev/get-list-projectcode-select2';
const API_LIST_CV = '/employeeAdmin/dev/list-cvby-projectcode';
const API_CHANGE_PASSWORD = '/employeeProfile/profile/change-password';
const API_ALL_EMP_EXPORT_EXCEL = '/employeeAdmin/dev/all-emp-export-excel';
const API_ALL_OFFICE = '/employeeAdmin/dev/get-all-office';
const API_GET_OFFICE = '/employeeAdmin/dev/get-office';
const API_UPDATE_OFFICE = '/employeeAdmin/dev/update-office';
const API_ACTIVE_OFFICE = '/employeeAdmin/dev/active-office';
const API_NEW_OFFICE = '/employeeAdmin/dev/create-office';
const API_CANCEL_JOIN = '/employee/hrd/cancel-join';
const API_LIST_HISTORY = '/employee/hrd/list-history';
const API_FIND_EMPLOYEE = '/employee/data/find';
const API_PREPARE_EMAIL_FORM = '/email/prepare-form';
const API_HR_ACHIEVEMENT = '/employee/hrd/hr-achievement';
const API_LIST_HR_ACHIEVEMENT = '/employee/hrd/list-hr-achievement';
const API_GET_DOCUMENT = '/employee/data/get-document';

// new employee movement api
const API_GET_EMPLOYEE_MOV = '/employee/default/get-employee-mov';
const API_SUBMIT = '/employee/default/submit';
const API_RELEASE_TICKET = '/employee/default/release-ticket';

//field ops productivity
const API_GET_DATA_FIELD_OPS_PROD = '/productivity/productivity/get-data';
const API_EXPORT_DATA_FIELD_OPS_PROD = '/productivity/productivity/export-data';

const API_GET_DATA_VACCINE = '/employee/hrd/vaccine-data';
const API_EXPORT_DATA_VACCINE = '/employee/hrd/export-vaccine-data';
const API_GET_EMPLOYEE_PROFILE = '/employee/hrd/get-employee-profile';
const API_GET_ACTIVE_USERS = "/employee/hrd/get-active-users";
const API_GET_RESIGN_REASON = "/employee/hrd/get-resign-reason";

// NEW API MOVEMENT
const API_GET_MOVEMENT_DATA = '/movement/movement/get-movement-data';
const API_GET_PIC_MOVEMENT = '/movement/movement/get-pic-movement';
const API_SUBMIT_MOVEMENT = '/movement/movement/submit-movement';
const API_GET_MOVEMENT_TRACKER = '/movement/movement/get-movement-tracker';
const API_GET_MY_APPROVAL_MOVEMENT = '/movement/movement/get-my-approval-movement';
const API_GET_STEP_MOVEMENT = '/movement/movement/get-step-movement';
const API_CANCEL_MOVEMENT = '/movement/movement/cancel-movement';
const API_GET_DETAIL_MOVEMENT = '/movement/movement/get-detail-movement';
const API_APPROVE_MOVEMENT = '/movement/movement/approve-movement';
const API_RELEASE_TICKET_MOVEMENT = '/movement/movement/release-ticket-movement';

@Injectable({
  providedIn: 'root'
})
export class EmployeeService {

  public mobileToggleEmit: Subject<boolean> = new Subject<boolean>();
  public mobileToggleEmitObs = this.mobileToggleEmit.asObservable();
  public loadBankAccountEmit: Subject<boolean> = new Subject<boolean>();
  public loadBankAccountEmitObs = this.loadBankAccountEmit.asObservable();

  constructor(
    private http: HttpClient,
    private globalService: GlobalService,
    private cookieService: CookieService,
    private modalService: NgbModal
  ) {
    this.mobileToggleEmit.next(false);
    this.loadBankAccountEmit.next(false);
  }

  public header(module = 'employee') {
    return {
      headers: new HttpHeaders()
        .set('token', this.cookieService.get('_q'))
        .set('module', localStorage.getItem(module))
        .set('group', this.cookieService.get('_g'))
    };
  }

  alertModal(err: string, code?: number) {
    if (code === 403) {
      return Swal.fire(err);
    }
    const modalRef = this.modalService.open(AlertModalComponent);
    modalRef.componentInstance.kategori = 'alert';
    modalRef.componentInstance.error = (err) ? err : 'Request Timeout.';
  }

  public getActiveUsers(): Observable<ResponseApiModel> {
    return this.http.post<ResponseApiModel>(
      this.globalService.apiVersionHost + API_GET_ACTIVE_USERS, {}, this.header()
    ).pipe(
      map(response => response)
    );
  }

  public getResignReason(params: any): Observable<ResponseApiModel> {
    return this.http.post<ResponseApiModel>(
      this.globalService.apiVersionHost + API_GET_RESIGN_REASON, params, this.header()
    ).pipe(
      map(response => response)
    );
  }

  public submitResignEmployee(dataForm: any): Observable<ResponseApiModel> {
    return this.http.post<ResponseApiModel>(
      this.globalService.apiVersionHost + '/employee/hrd/submit-resign', dataForm, this.header()
    );
  }

  public getHrAchievement(params: any): Observable<ResponseApiModel> {
    return this.http.post<ResponseApiModel>(
      this.globalService.apiVersionHost + API_HR_ACHIEVEMENT, params, this.header()
    ).pipe(
      map(response => response)
    );
  }

  public getListHrAchievement(params: any): Observable<ResponseApiModel> {
    return this.http.post<ResponseApiModel>(
      this.globalService.apiVersionHost + API_LIST_HR_ACHIEVEMENT, params, this.header()
    ).pipe(
      map(response => response)
    );
  }

  public getAllOffice(param: {
    pageSize: number;
    page: number;
    filter: any;
  }) {
    return this.http.post<ResponseApiModel>(
      this.globalService.apiVersionHost + API_ALL_OFFICE, param, this.header('employeeAdmin')
    )
      .pipe(map(response => response));
  }

  public listHistory(param: {
    pageSize: number;
    page: number;
    filter: any;
  }): Observable<ResponseApiModel> {
    return this.http
      .post<ResponseApiModel>(
        this.globalService.apiVersionHost + API_LIST_HISTORY,
        param,
        this.header()
      )
      .pipe(map(response => response));
  }
  public getOffice(param: {
    id: number;
  }): Observable<ResponseApiModel> {
    return this.http
      .post<ResponseApiModel>(
        this.globalService.apiVersionHost + API_GET_OFFICE,
        param,
        this.header('employeeAdmin')
      )
      .pipe(map(response => response));
  }

  public newOffice(param: {
    data: any;
  }): Observable<ResponseApiModel> {
    return this.http
      .post<ResponseApiModel>(
        this.globalService.apiVersionHost + API_NEW_OFFICE,
        param,
        this.header('employeeAdmin')
      )
      .pipe(map(response => response));
  }

  public setActiveOffice(param: {
    id: number;
    data: any;
  }): Observable<ResponseApiModel> {
    return this.http
      .post<ResponseApiModel>(
        this.globalService.apiVersionHost + API_ACTIVE_OFFICE,
        param,
        this.header('employeeAdmin')
      )
      .pipe(map(response => response));
  }

  public updateOffice(param: {
    id: number;
    data: any;
  }): Observable<ResponseApiModel> {
    return this.http
      .post<ResponseApiModel>(
        this.globalService.apiVersionHost + API_UPDATE_OFFICE,
        param,
        this.header('employeeAdmin')
      )
      .pipe(map(response => response));
  }

  public getEmployees(param: {
    pageSize: number;
    page: number;
    filter: any;
  }): Observable<ResponseApiModel> {
    return this.http
      .post<ResponseApiModel>(
        this.globalService.apiVersionHost + API_EMPLOYEES,
        param,
        this.header()
      )
      .pipe(map(response => response));
  }

  public listAll(param: {
    pageSize: number;
    page: number;
    filter: any;
  }): Observable<ResponseApiModel> {
    return this.http
      .post<ResponseApiModel>(
        this.globalService.apiVersionHost + '/employeeAdmin/dev/list-all',
        param,
        this.header('employeeAdmin')
      )
      .pipe(map(response => response));
  }

  public historyListAll(param: {
    pageSize: number;
    page: number;
    q: any;
  }): Observable<ResponseApiModel> {
    return this.http
      .post<ResponseApiModel>(
        this.globalService.apiVersionHost + '/employee/hrd/history-list-all',
        param,
        this.header()
      )
      .pipe(map(response => response));
  }

  public getEmployeeById(params: {
    id: number;
    fields: any;
  }): Observable<ResponseApiModel> {
    return this.http.post<ResponseApiModel>(
      this.globalService.apiVersionHost + '/employee/users/get-user-by-id',
      params,
      this.header()
    );
  }

  // public getResignReason(params: {
  //   is_blacklisted: boolean;
  // }): Observable<ResponseApiModel> {
  //   return this.http.post<ResponseApiModel>(
  //     this.globalService.apiVersionHost + '/employee/users/get-resign-reason',
  //     params,
  //     this.header()
  //   );
  // }

  public discResult(params: {
    id: number;
  }): Observable<ResponseApiModel> {
    return this.http.post<ResponseApiModel>(
      this.globalService.apiVersionHost + '/employee/hrd/disc-result',
      params,
      this.header()
    );
  }

  public generateDiscResult(params: {
    id: number;
  }): Observable<ResponseApiModel> {
    return this.http.post<ResponseApiModel>(
      this.globalService.apiVersionHost + '/employee/hrd/generate-disc',
      params,
      this.header()
    );
  }

  public generateCorruptionStatement(params: {
    id: number;
  }): Observable<ResponseApiModel> {
    return this.http.post<ResponseApiModel>(
      this.globalService.apiVersionHost + '/employee/hrd/generate-cs',
      params,
      this.header()
    );
  }

  public generateEhsStatement(params: {
    id: number;
  }): Observable<ResponseApiModel> {
    return this.http.post<ResponseApiModel>(
      this.globalService.apiVersionHost + '/employee/hrd/generate-ehss',
      params,
      this.header()
    );
  }
  public generateCyberSecurityStatement(params: {
    id: number;
  }): Observable<ResponseApiModel> {
    return this.http.post<ResponseApiModel>(
      this.globalService.apiVersionHost + '/employee/hrd/generate-css',
      params,
      this.header()
    );
  }

  public EmployeeReq(params: {
    username: string;
    data: any;
  }): Observable<ResponseApiModel> {
    return this.http.post<ResponseApiModel>(
      this.globalService.apiVersionHost + '/employee/hrd/employee-request',
      params,
      this.header()
    );
  }
  public EmployeeReqUserList(): Observable<ResponseApiModel> {
    return this.http.get<ResponseApiModel>(
      this.globalService.apiVersionHost + '/employee/hrd/employee-request-user-list',
      this.header()
    );
  }
  public EmployeeReqUserListSelect2(param): Observable<ResponseApiModel> {
    return this.http.post<ResponseApiModel>(
      this.globalService.apiVersionHost + '/employee/hrd/employee-request-user-list-select2',
      param,
      this.header()
    );
  }

  public resignEmployee(params: any): Observable<ResponseApiModel> {
    return this.http.post<ResponseApiModel>(
      this.globalService.apiVersionHost + '/employee/hrd/resign',
      params,
      this.header()
    );
  }

  public employeeVsTaskRemark(params: {
    id: string;
    remark: string;
  }): Observable<ResponseApiModel> {
    return this.http.post<ResponseApiModel>(
      this.globalService.apiVersionHost + '/employee/users/employeevsassetremark',
      params,
      this.header()
    );
  }

  public inputResult(params: {
    id: number;
    exam_result: number;
    interview_result: any;
  }): Observable<ResponseApiModel> {
    return this.http.post<ResponseApiModel>(
      this.globalService.apiVersionHost + '/employee/hrd/inputresult',
      params,
      this.header()
    );
  }

  public getDetail(params: { id: number }): Observable<ResponseApiModel> {
    return this.http.post<ResponseApiModel>(
      this.globalService.apiVersionHost + '/employee/hrd/detail',
      params,
      this.header()
    );
    // .pipe(
    //   retry(1),
    //   catchError(this.handleError)
    // )
  }

  public getDataVacancies(params: { id: number }): Observable<ResponseApiModel> {
    return this.http.post<ResponseApiModel>(
      this.globalService.apiVersionHost + '/employee/hrd/get-data-vacancies',
      params,
      this.header()
    );
    // .pipe(
    //   retry(1),
    //   catchError(this.handleError)
    // )
  }

  public getPositionVacancy(params: { id: number }): Observable<ResponseApiModel> {
    return this.http.post<ResponseApiModel>(
      this.globalService.apiVersionHost + '/employee/hrd/get-position-by-vacancy',
      params,
      this.header()
    );
    // .pipe(
    //   retry(1),
    //   catchError(this.handleError)
    // )
  }

  public getGetDataApply(params: { id: number }): Observable<ResponseApiModel> {
    return this.http.post<ResponseApiModel>(
      this.globalService.apiVersionHost + '/employee/hrd/get-data-apply',
      params,
      this.header()
    );
  }

  public addToApply(params: { id_user: number, id_det: number }): Observable<ResponseApiModel> {
    return this.http.post<ResponseApiModel>(
      this.globalService.apiVersionHost + '/employee/hrd/add-to-vacancy',
      params,
      this.header()
    );
  }

  public deleteApply(params: { id: number }): Observable<ResponseApiModel> {
    return this.http.post<ResponseApiModel>(
      this.globalService.apiVersionHost + '/employee/hrd/delete-apply',
      params,
      this.header()
    );
  }

  public addProjectCodeTemp(
    param: userprojectcode
  ): Observable<ResponseApiModel> {
    return this.http
      .post<ResponseApiModel>(
        this.globalService.apiVersionHost + '/employee/hrd/add-project-temp',
        param,
        this.header()
      )
      .pipe(
        map(response => response),
        catchError(err => {
          return throwError(err);
        })
      );
  }

  public deleteProjectCodeTemp(param): Observable<ResponseApiModel> {
    return this.http
      .post<ResponseApiModel>(
        this.globalService.apiVersionHost +
        '/employee/hrd/delete-project-temp',
        param,
        this.header()
      )
      .pipe(
        map(response => response),
        catchError(err => {
          return throwError(err);
        })
      );
  }

  public getListEmployeeByRegion(): Observable<ResponseApiModel> {
    return this.http
      .get<ResponseApiModel>(
        this.globalService.apiVersionHost +
        '/employee/users/getlistemployeebyregion',
        this.header()
      )
      .pipe(
        map(response => response),
        catchError(err => {
          return throwError(err);
        })
      );
  }

  public getViewfull(id): Observable<ResponseApiModel> {
    return this.http.get<ResponseApiModel>(
      this.globalService.apiVersionHost + '/employeeAdmin/dev/viewfull?id=' + id,
      this.header('employeeAdmin')
    );
    // .pipe(
    //   retry(1),
    //   catchError(this.handleError)
    // )
  }

  public getEditfull(id): Observable<ResponseApiModel> {
    return this.http.get<ResponseApiModel>(
      this.globalService.apiVersionHost + '/employeeAdmin/dev/editfull?id=' + id,
      this.header('employeeAdmin')
    );
    // .pipe(
    //   retry(1),
    //   catchError(this.handleError)
    // )
  }

  public prepareEditFull(id): Observable<ResponseApiModel> {
    return this.http.post<ResponseApiModel>(
      this.globalService.apiVersionHost + '/employeeAdmin/dev/prepare-edit-full?id=' + id,
      null,
      this.header('employeeAdmin')
    );
  }

  // editFullSubmit
  public editFullSubmit(id, param: Editfull): Observable<ResponseApiModel> {
    console.log('param', param);
    return this.http
      .post<ResponseApiModel>(
        this.globalService.apiVersionHost + '/employeeAdmin/dev/editfull?id=' + id,
        param,
        this.header('employeeAdmin')
      )
      .pipe(
        map(response => response),
        catchError(err => {
          return throwError(err);
        })
      );
  }

  public movementSubmit(params): Observable<ResponseApiModel> {
    return this.http
      .post<ResponseApiModel>(
        this.globalService.apiVersionHost + '/employee/hrd/movement',
        params,
        this.header()
      )
      .pipe(
        map(response => response),
        catchError(err => {
          return throwError(err);
        })
      );
  }

  public getListProjectCode(): Observable<ResponseApiModel> {
    return this.http.get<ResponseApiModel>(
      this.globalService.apiVersionHost +
      '/employee/users/get-list-projectcode',
      this.header()
    );
    // .pipe(
    //   retry(1),
    //   catchError(this.handleError)
    // )
  }

  public getListProjectCodeSelect2(): Observable<ResponseApiModel> {
    return this.http.get<ResponseApiModel>(
      this.globalService.apiVersionHost +
      '/employee/users/get-list-projectcode-select2',
      this.header()
    );
    // .pipe(
    //   retry(1),
    //   catchError(this.handleError)
    // )
  }

  public listSelectAddEmployee(): Observable<ResponseApiModel> {
    return this.http.get<ResponseApiModel>(
      this.globalService.apiVersionHost +
      '/employee/hrd/list-select-add-employee',
      this.header()
    );
    // .pipe(
    //   retry(1),
    //   catchError(this.handleError)
    // )
  }

  public getUserProjectCodeTemp(id): Observable<ResponseApiModel> {
    return this.http.get<ResponseApiModel>(
      this.globalService.apiVersionHost +
      '/employee/users/getusersprojectcodetemp?id=' +
      id,
      this.header()
    );
    // .pipe(
    //   retry(1),
    //   catchError(this.handleError)
    // )
  }

  public getUsersProjectCodeTempWithoutDelete(
    id = null
  ): Observable<ResponseApiModel> {
    return this.http.get<ResponseApiModel>(
      this.globalService.apiVersionHost +
      '/employee/users/getusersprojectcodetempwithoutdelete?id=' +
      id,
      this.header()
    );
    // .pipe(
    //   retry(1),
    //   catchError(this.handleError)
    // )
  }

  public getResignList(param: {
    pageSize: number;
    page: number;
    filter: any;
  }): Observable<ResponseApiModel> {
    return this.http
      .post<ResponseApiModel>(
        this.globalService.apiVersionHost + '/employee/hrd/get-resign-list',
        param,
        this.header()
      )
      .pipe(
        map(response => response),
        catchError(err => {
          return throwError(err);
        })
      );
  }

  public getNoticePeriodList(param: {
    pageSize: number;
    page: number;
    filter: any;
  }): Observable<ResponseApiModel> {
    return this.http.post<ResponseApiModel>(
      this.globalService.apiVersionHost + '/employee/hrd/get-notice-period-list',
      param,
      this.header()
    ).pipe(
      map(response => response),
      catchError(err => {
        return throwError(err);
      })
    );
  }

  public getMyNoticePeriod(param): Observable<ResponseApiModel> {
    return this.http.post<ResponseApiModel>(
      this.globalService.apiVersionHost + '/employee/default/get-my-notice-period',
      param,
      this.header()
    ).pipe(
      map(response => response),
      catchError(err => {
        return throwError(err);
      })
    );
  }


  public getCancelResignList(param: {
    pageSize: number;
    page: number;
    filter: any;
  }): Observable<ResponseApiModel> {
    return this.http
      .post<ResponseApiModel>(
        this.globalService.apiVersionHost +
        '/employee/hrd/cancel-resign-list',
        param,
        this.header()
      )
      .pipe(
        map(response => response),
        catchError(err => {
          return throwError(err);
        })
      );
  }

  public getHistoryMovement(param: {
    pageSize: number;
    page: number;
    filter: any;
  }): Observable<ResponseApiModel> {
    return this.http
      .post<ResponseApiModel>(
        this.globalService.apiVersionHost +
        '/employee/hrd/get-history-movement',
        param,
        this.header()
      )
      .pipe(
        map(response => response),
        catchError(err => {
          return throwError(err);
        })
      );
  }

  public getUsersHistory(param: { id: number }): Observable<ResponseApiModel> {
    return this.http
      .post<ResponseApiModel>(
        this.globalService.apiVersionHost + '/employee/hrd/get-users-history',
        param,
        this.header()
      )
      .pipe(
        map(response => response),
        catchError(err => {
          return throwError(err);
        })
      );
  }

  public CancelResign(param: { id: number, broadcast: string }): Observable<ResponseApiModel> {
    return this.http
      .post<ResponseApiModel>(
        this.globalService.apiVersionHost + '/employeeAdmin/dev/cancel-resign',
        param,
        this.header('employeeAdmin')
      )
      .pipe(
        map(response => response),
        catchError(err => {
          return throwError(err);
        })
      );
  }

  public CancelResignFromNotice(param: { id: number, broadcast: string }): Observable<ResponseApiModel> {
    return this.http.post<ResponseApiModel>(
      this.globalService.apiVersionHost + '/employee/hrd/cancel-resign-from-notice',
      param,
      this.header()
    ).pipe(
      map(response => response),
      catchError(err => {
        return throwError(err);
      })
    );
  }

  public getListProject(): Observable<ResponseApiModel> {
    return this.http.get<ResponseApiModel>(
      this.globalService.apiVersionHost + '/employee/users/getlistproject',
      this.header()
    );
  }

  public getListNWAssignment(): Observable<ResponseApiModel> {
    return this.http.get<ResponseApiModel>(
      this.globalService.apiVersionHost + '/employee/users/getlistnwassignment',
      this.header()
    );
  }
  //
  public getListPosition(): Observable<ResponseApiModel> {
    return this.http.get<ResponseApiModel>(
      this.globalService.apiVersionHost + '/employee/users/getlistposition',
      this.header()
    );
  }

  public getListPm(): Observable<ResponseApiModel> {
    return this.http.get<ResponseApiModel>(
      this.globalService.apiVersionHost + '/employee/users/getlistpm',
      this.header()
    );
  }

  public getListAtasan(): Observable<ResponseApiModel> {
    return this.http.get<ResponseApiModel>(
      this.globalService.apiVersionHost + '/employee/default/list-atasan', this.header()
    ).pipe(
      map(response => response),
      catchError(err => {
        return throwError(err);
      })
    );
  }

  public getListAtasanSelect2(): Observable<ResponseApiModel> {
    return this.http.get<ResponseApiModel>(
      this.globalService.apiVersionHost + '/employee/default/list-atasan-select2', this.header()
    ).pipe(
      map(response => response),
      catchError(err => {
        return throwError(err);
      })
    );
  }

  public getDataMovement(params: { id: number }): Observable<ResponseApiModel> {
    return this.http.post<ResponseApiModel>(
      this.globalService.apiVersionHost + '/employee/hrd/get-movement-data', params,
      this.header()
    );
  }

  public getEmpMovement(params): Observable<ResponseApiModel> {
    return this.http.post<ResponseApiModel>(
      this.globalService.apiVersionHost + '/employee/hrd/get-employee-movement', params,
      this.header()
    );
  }

  public rejectMovement(params): Observable<ResponseApiModel> {
    return this.http.post<ResponseApiModel>(
      this.globalService.apiVersionHost + '/employee/hrd/reject-movement', params, this.header()
    ).pipe(
      map(response => response)
    );
  }

  public getListNamaPm(): Observable<ResponseApiModel> {
    return this.http.post<ResponseApiModel>(
      this.globalService.apiVersionHost + '/employee/users/get-list-nama-pm', null,
      this.header()
    );
  }

  public getUserById(param: {
    id: number;
    fields: string;
  }): Observable<ResponseApiModel> {
    return this.http
      .post<ResponseApiModel>(
        this.globalService.apiVersionHost + '/employee/users/get-user-by-id',
        param,
        this.header()
      )
      .pipe(map(response => response));
  }

  public getListNamaTl(): Observable<ResponseApiModel> {
    return this.http.post<ResponseApiModel>(
      this.globalService.apiVersionHost + '/employee/users/get-list-nama-tl', null,
      this.header()
    );
  }

  public prepareEmailForm(): Observable<ResponseApiModel> {
    return this.http.post<ResponseApiModel>(
      this.globalService.apiVersionHost + API_PREPARE_EMAIL_FORM, null, this.header()
    ).pipe(
      map(response => response)
    );
  }

  public getListNamaEmployee(): Observable<ResponseApiModel> {
    return this.http.post<ResponseApiModel>(
      this.globalService.apiVersionHost + '/employee/users/get-list-nama-employee', null,
      this.header()
    ).pipe(map(response => response));
  }

  public getListNamaEmployeeSelect2(): Observable<ResponseApiModel> {
    return this.http.post<ResponseApiModel>(
      this.globalService.apiVersionHost + '/employee/users/get-list-nama-employee-select2', null,
      this.header()
    ).pipe(map(response => response));
  }

  public getNamaEmployeeV2(action: string): Observable<ResponseApiModel> {
    return this.http.post<ResponseApiModel>(
      this.globalService.apiVersionHost + '/employee/users/get-list-nama-employee', { action },
      this.header()
    );
  }

  public getListRegion(): Observable<ResponseApiModel> {
    return this.http.get<ResponseApiModel>(
      this.globalService.apiVersionHost + '/employee/users/getlistregion',
      this.header()
    );
  }

  public collectedResponse() {
    const request1 = this.getNwAssignmentMitraKerja();
    const request2 = this.getPositionMitraKerja();
    const request3 = this.getListAtasanSelect2();
    const request4 = this.getListRegion();
    return forkJoin([request1, request2, request3, request4]);
  }

  public getAllRegion(): Observable<ResponseApiModel> {
    return this.http.get<ResponseApiModel>(
      this.globalService.apiVersionHost + '/employee/users/get-all-region', this.header()
    );
  }

  public getListTl(pm): Observable<ResponseApiModel> {
    return this.http.get<ResponseApiModel>(
      this.globalService.apiVersionHost + '/employee/users/getlisttl?pm=' + pm,
      this.header()
    );
  }

  public getListTlMov(): Observable<ResponseApiModel> {
    return this.http.get<ResponseApiModel>(
      this.globalService.apiVersionHost + '/employee/users/getlisttl-mov',
      this.header()
    );
  }

  public getListLocation(location): Observable<ResponseApiModel> {
    return this.http.get<ResponseApiModel>(
      this.globalService.apiVersionHost +
      '/employee/users/getlistlocation?location=' +
      location,
      this.header()
    );
  }

  public getListLocationMov(): Observable<ResponseApiModel> {
    return this.http.get<ResponseApiModel>(
      this.globalService.apiVersionHost +
      '/employee/users/getlistlocation-mov',
      this.header()
    );
  }

  public updateEmployee(param: {
    employee: any;
  }): Observable<ResponseApiModel> {
    return this.http
      .post<ResponseApiModel>(
        this.globalService.apiVersionHost + API_EDIT_EMPLOYEE,
        param,
        this.header()
      )
      .pipe(
        map(response => response),
        catchError(this.handleError)
      );
  }

  public updateProfile(params: {
    id: number;
    employee: any;
  }): Observable<ResponseApiModel> {
    return this.http
      .post<ResponseApiModel>(
        this.globalService.apiVersionHost + '/employee/users/update-profile',
        params,
        this.header()
      )
      .pipe(
        map(response => response),
        catchError(this.handleError)
      );
  }

  public updateVaccine(params: {
    employee: any;
  }): Observable<ResponseApiModel> {
    return this.http
      .post<ResponseApiModel>(
        this.globalService.apiVersionHost + '/employeeProfile/profile/save-vaccine',
        params,
        this.header('employeeProfile')
      )
      .pipe(
        map(response => response),
        catchError(this.handleError)
      );
  }

  public saveProject(params: {
    project_code: string;
    sub_project: string;
    portion: number;
    id: number;
  }): Observable<ResponseApiModel> {
    return this.http
      .post<ResponseApiModel>(
        this.globalService.apiVersionHost + '/employee/hrd/save-project',
        params,
        this.header()
      )
      .pipe(
        map(response => response),
        catchError(this.handleError)
      );
  }

  public importUserProject(params: {
    data: any;
    id: number;
  }): Observable<ResponseApiModel> {
    return this.http
      .post<ResponseApiModel>(
        this.globalService.apiVersionHost + '/employee/hrd/import-user-project',
        params,
        this.header()
      )
      .pipe(
        map(response => response),
        catchError(this.handleError)
      );
  }

  public cekUsername(params: {
    Username: string;
  }): Observable<ResponseApiModel> {
    return this.http
      .post<ResponseApiModel>(
        this.globalService.apiVersionHost + '/employee/users/cek-username',
        params,
        this.header()
      )
      .pipe(
        map(response => response),
        catchError(this.handleError)
      );
  }
  public cekEmailAddress(params: {
    EmailAddress: string;
  }): Observable<ResponseApiModel> {
    return this.http
      .post<ResponseApiModel>(
        this.globalService.apiVersionHost + '/employee/users/cek-email',
        params,
        this.header()
      )
      .pipe(
        map(response => response),
        catchError(this.handleError)
      );
  }
  public getTlByPm(params: { PM: string }): Observable<ResponseApiModel> {
    return this.http
      .post<ResponseApiModel>(
        this.globalService.apiVersionHost + '/employee/users/show-tl-by-pm',
        params,
        this.header()
      )
      .pipe(
        map(response => response),
        catchError(this.handleError)
      );
  }
  public getLocationByRegion(params: {
    region: string;
  }): Observable<ResponseApiModel> {
    return this.http
      .post<ResponseApiModel>(
        this.globalService.apiVersionHost +
        '/employee/users/show-location-by-region',
        params,
        this.header()
      )
      .pipe(
        map(response => response),
        catchError(this.handleError)
      );
  }

  public getLocationPic(params: {
    region: string;
  }): Observable<ResponseApiModel> {
    return this.http
      .post<ResponseApiModel>(
        this.globalService.apiVersionHost +
        '/employee/users/show-location-pic',
        params,
        this.header()
      )
      .pipe(
        map(response => response),
        catchError(this.handleError)
      );
  }

  public addEmployee(params: {
    model: any;
    pass_id_name: string;
    profile_name: string;
    ttd_name: string;
    pass_id_attachment: string;
    profile: string;
    ttd: string;
  }): Observable<ResponseApiModel> {
    return this.http
      .post<ResponseApiModel>(
        this.globalService.apiVersionHost + '/employee/hrd/add-employee',
        params,
        this.header()
      )
      .pipe(
        map(response => response),
        catchError(this.handleError)
      );
  }

  public submitPmUpdate(params: {
    currentPm: string;
    proposedPm: string;
  }): Observable<ResponseApiModel> {
    return this.http
      .post<ResponseApiModel>(
        this.globalService.apiVersionHost + '/employee/hrd/submit-pm-update',
        params,
        this.header()
      )
      .pipe(
        map(response => response),
        catchError(this.handleError)
      );
  }

  public submitPmMovement(params: {
    currentTl: string;
    currentTlUserid: number;
    proposedPm: string;
  }): Observable<ResponseApiModel> {
    return this.http
      .post<ResponseApiModel>(
        this.globalService.apiVersionHost +
        '/employee/hrd/submit-pm-movement',
        params,
        this.header()
      )
      .pipe(
        map(response => response),
        catchError(this.handleError)
      );
  }

  public submitTlUpdate(params: {
    currentTl: string;
    proposedTl: string;
  }): Observable<ResponseApiModel> {
    return this.http
      .post<ResponseApiModel>(
        this.globalService.apiVersionHost + '/employee/hrd/submit-tl-update',
        params,
        this.header()
      )
      .pipe(
        map(response => response),
        catchError(this.handleError)
      );
  }

  public submitTlMovement(params: {
    userid: number;
    proposedTl: string;
    proposedTlUsername: string;
    proposedPm: string;
    proposedPmUsername: string;
  }): Observable<ResponseApiModel> {
    return this.http
      .post<ResponseApiModel>(
        this.globalService.apiVersionHost +
        '/employee/hrd/submit-tl-movement',
        params,
        this.header()
      )
      .pipe(
        map(response => response),
        catchError(this.handleError)
      );
  }

  public uploadFile(params: {
    id: number;
    strFile: string;
    fileName: string;
    file: string;
  }): Observable<ResponseApiModel> {
    return this.http
      .post<ResponseApiModel>(
        this.globalService.apiVersionHost + '/employee/users/upload-file',
        params,
        this.header()
      )
      .pipe(
        map(response => response),
        catchError(this.handleError)
      );
  }
  public deleteProjectTemp(param: { id: number }): Observable<ResponseApiModel> {
    return this.http
      .post<ResponseApiModel>(
        this.globalService.apiVersionHost +
        '/employee/hrd/delete-project-temp',
        param,
        this.header()
      )
      .pipe(
        map(response => response),
        catchError(this.handleError)
      );
  }

  public getProjectcode(id): Observable<ResponseApiModel> {
    return null;
  }

  handleError(error) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // Get client-side error
      errorMessage = error.error.message;
    } else {
      // Get server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    window.alert(errorMessage);
    return throwError(errorMessage);
  }

  public getListBankAccount(param: {
    pageSize: number;
    page: number;
    filter: any;
  }): Observable<ResponseApiModel> {
    return this.http
      .post<ResponseApiModel>(
        this.globalService.apiVersionHost +
        '/payslip/bank-account/get-data',
        param,
        this.header('payslip')
      )
      .pipe(
        map(response => response),
        catchError(err => {
          return throwError(err);
        })
      );
  }

  public viewBank(param: { id: any }): Observable<ResponseApiModel> {
    return this.http
      .post<ResponseApiModel>(
        this.globalService.apiVersionHost + '/payslip/bank-account/view-data',
        param,
        this.header('payslip')
      )
      .pipe(
        map(response => response),
        catchError(err => {
          return throwError(err);
        })
      );
  }

  public editBank(param: { user: any }): Observable<ResponseApiModel> {
    return this.http
      .post<ResponseApiModel>(
        this.globalService.apiVersionHost + '/payslip/bank-account/edit-data',
        param,
        this.header('payslip')
      )
      .pipe(
        map(response => response),
        catchError(err => {
          return throwError(err);
        })
      );
  }

  public getListMtdw(param: {
    pageSize: number;
    page: number;
    filter: any;
  }): Observable<ResponseApiModel> {
    return this.http
      .post<ResponseApiModel>(
        this.globalService.apiVersionHost + '/employee/hrd/list-mtdw',
        param,
        this.header()
      )
      .pipe(
        map(response => response),
        catchError(err => {
          return throwError(err);
        })
      );
  }

  public getListMk(param: {
    pageSize: number;
    page: number;
    filter: any;
  }): Observable<ResponseApiModel> {
    return this.http
      .post<ResponseApiModel>(
        this.globalService.apiVersionHost + '/employee/hrd/list-mk',
        param,
        this.header()
      )
      .pipe(
        map(response => response),
        catchError(err => {
          return throwError(err);
        })
      );
  }

  public getNwAssignmentResign(param): Observable<ResponseApiModel> {
    return this.http
      .post<ResponseApiModel>(
        this.globalService.apiVersionHost +
        '/employee/hrd/nw-assignment-from-resign',
        param,
        this.header()
      )
      .pipe(
        map(response => response),
        catchError(err => {
          return throwError(err);
        })
      );
  }

  public getNwAssignmentSelect2(param): Observable<ResponseApiModel> {
    return this.http
      .post<ResponseApiModel>(
        this.globalService.apiVersionHost +
        '/employee/hrd/get-nw-assignment-select2',
        param,
        this.header()
      )
      .pipe(
        map(response => response),
        catchError(err => {
          return throwError(err);
        })
      );
  }

  public getNwAssignmentApplicant(): Observable<ResponseApiModel> {
    return this.http
      .get<ResponseApiModel>(
        this.globalService.apiVersionHost +
        '/employee/hrd/nw-assignment-from-applicant',
        this.header()
      )
      .pipe(
        map(response => response),
        catchError(err => {
          return throwError(err);
        })
      );
  }

  public getNwAssignmentMtdw(): Observable<ResponseApiModel> {
    return this.http
      .get<ResponseApiModel>(
        this.globalService.apiVersionHost +
        '/employee/users/nw-assignment-mtdw',
        this.header()
      )
      .pipe(
        map(response => response),
        catchError(err => {
          return throwError(err);
        })
      );
  }

  public getNwAssignmentMitraKerja(): Observable<ResponseApiModel> {
    return this.http
      .get<ResponseApiModel>(
        this.globalService.apiVersionHost +
        '/employee/users/nw-assignment-mitra-kerja',
        this.header()
      )
      .pipe(
        map(response => response),
        catchError(err => {
          return throwError(err);
        })
      );
  }

  public getPositionMtdw(): Observable<ResponseApiModel> {
    return this.http
      .get<ResponseApiModel>(
        this.globalService.apiVersionHost + '/employee/users/position-mtdw',
        this.header()
      )
      .pipe(
        map(response => response),
        catchError(err => {
          return throwError(err);
        })
      );
  }

  public getPositionMitraKerja(): Observable<ResponseApiModel> {
    return this.http
      .get<ResponseApiModel>(
        this.globalService.apiVersionHost + '/employee/users/position-mitra-kerja',
        this.header()
      )
      .pipe(
        map(response => response),
        catchError(err => {
          return throwError(err);
        })
      );
  }

  public getPositionResign(param): Observable<ResponseApiModel> {
    return this.http
      .post<ResponseApiModel>(
        this.globalService.apiVersionHost + '/employee/hrd/position-from-resign',
        param,
        this.header()
      )
      .pipe(
        map(response => response),
        catchError(err => {
          return throwError(err);
        })
      );
  }

  public getPositionApplicant(): Observable<ResponseApiModel> {
    return this.http
      .get<ResponseApiModel>(
        this.globalService.apiVersionHost + '/employee/hrd/position-from-applicant',
        this.header()
      )
      .pipe(
        map(response => response),
        catchError(err => {
          return throwError(err);
        })
      );
  }

  public getPositionSelect2(param): Observable<ResponseApiModel> {
    return this.http
      .post<ResponseApiModel>(
        this.globalService.apiVersionHost + '/employee/hrd/get-position-select2',
        param,
        this.header()
      )
      .pipe(
        map(response => response),
        catchError(err => {
          return throwError(err);
        })
      );
  }

  public saveMtdw(param): Observable<ResponseApiModel> {
    return this.http
      .post<ResponseApiModel>(
        this.globalService.apiVersionHost + '/employee/hrd/save-mtdw',
        param,
        this.header()
      )
      .pipe(
        map(response => response),
        catchError(err => {
          return throwError(err);
        })
      );
  }

  public saveMk(param): Observable<ResponseApiModel> {
    return this.http
      .post<ResponseApiModel>(
        this.globalService.apiVersionHost + '/employee/hrd/save-mk',
        param,
        this.header()
      )
      .pipe(
        map(response => response),
        catchError(err => {
          return throwError(err);
        })
      );
  }

  public getUpdateMtdw(id): Observable<ResponseApiModel> {
    return this.http
      .post<ResponseApiModel>(
        this.globalService.apiVersionHost + '/employee/hrd/update-mtdw',
        { id },
        this.header()
      )
      .pipe(
        map(response => response),
        catchError(err => {
          return throwError(err);
        })
      );
  }

  public saveUpdateMtdw(param): Observable<ResponseApiModel> {
    return this.http
      .post<ResponseApiModel>(
        this.globalService.apiVersionHost + '/employee/hrd/save-update-mtdw',
        param,
        this.header()
      )
      .pipe(
        map(response => response),
        catchError(err => {
          return throwError(err);
        })
      );
  }

  public saveUpdateLastDay(param): Observable<ResponseApiModel> {
    return this.http
      .post<ResponseApiModel>(
        this.globalService.apiVersionHost + '/employee/hrd/save-update-last-day',
        param,
        this.header()
      )
      .pipe(
        map(response => response),
        catchError(err => {
          return throwError(err);
        })
      );
  }

  public convertToMtdw(param): Observable<ResponseApiModel> {
    return this.http
      .post<ResponseApiModel>(
        this.globalService.apiVersionHost + '/employee/hrd/convert-to-mtdw',
        param,
        this.header()
      )
      .pipe(
        map(response => response),
        catchError(err => {
          return throwError(err);
        })
      );
  }
  public convertToMk(param): Observable<ResponseApiModel> {
    return this.http
      .post<ResponseApiModel>(
        this.globalService.apiVersionHost + '/employee/hrd/convert-to-mk',
        param,
        this.header()
      )
      .pipe(
        map(response => response),
        catchError(err => {
          return throwError(err);
        })
      );
  }

  public toInternal(param): Observable<ResponseApiModel> {
    return this.http
      .post<ResponseApiModel>(
        this.globalService.apiVersionHost + '/employee/hrd/to-internal',
        param,
        this.header()
      )
      .pipe(
        map(response => response),
        catchError(err => {
          return throwError(err);
        })
      );
  }

  public getTree(param: any): Observable<ResponseApiModel> {
    return this.http
      .post<ResponseApiModel>(
        this.globalService.apiVersionHost + '/employee/org-chart/get-tree',
        param,
        this.header()
      )
      .pipe(
        map(response => response),
        catchError(err => {
          return throwError(err);
        })
      );
  }

  public getTreeOfficial(param: any): Observable<ResponseApiModel> {
    return this.http
      .post<ResponseApiModel>(
        this.globalService.apiVersionHost + '/employee/org-chart/get-tree-official',
        param,
        this.header()
      )
      .pipe(
        map(response => response),
        catchError(err => {
          return throwError(err);
        })
      );
  }

  public getReminder(param: {
    pageSize: number;
    page: number;
    filter: any;
  }): Observable<ResponseApiModel> {
    return this.http
      .post<ResponseApiModel>(
        this.globalService.apiVersionHost + API_GET_REMINDER,
        param,
        this.header()
      )
      .pipe(
        map(response => response),
        catchError(err => {
          return throwError(err);
        })
      );
  }

  public getApprovalEmployee(param): Observable<ResponseApiModel> {
    return this.http.post<ResponseApiModel>(
      this.globalService.apiVersionHost + API_GET_APPROVAL_EMPLOYEE, param, this.header()
    ).pipe(
      map(response => response)
    );
  }

  public getDetailApproval(param): Observable<ResponseApiModel> {
    return this.http.post<ResponseApiModel>(
      this.globalService.apiVersionHost + API_GET_DETAIL_APPROVAL, param, this.header()
    ).pipe(
      map(response => response)
    );
  }

  public approveUpdateData(param): Observable<ResponseApiModel> {
    return this.http.post<ResponseApiModel>(
      this.globalService.apiVersionHost + API_APPROVE_UPDATE_DATA, param, this.header()
    ).pipe(
      map(response => response)
    );
  }

  public getAllLocation(): Observable<ResponseApiModel> {
    return this.http.get<ResponseApiModel>(
      this.globalService.apiVersionHost + API_ALL_LOCATION, this.header()
    );
  }

  public getListPcode(): Observable<ResponseApiModel> {
    return this.http.post<ResponseApiModel>(
      this.globalService.apiVersionHost + API_LIST_PCODE, null, this.header('employeeAdmin')
    );
  }
  public getListPcodeSelect2(): Observable<ResponseApiModel> {
    return this.http.post<ResponseApiModel>(
      this.globalService.apiVersionHost + API_LIST_PCODE_SELECT2, null, this.header('employeeAdmin')
    );
  }
  public getListCv(param: { project_code: string }): Observable<ResponseApiModel> {
    return this.http.post<ResponseApiModel>(
      this.globalService.apiVersionHost + API_LIST_CV, param, this.header('employeeAdmin')
    );
  }

  public changePassword(param: { curPass: string, newPass: string, newPass1: string, }): Observable<ResponseApiModel> {
    return this.http.post<ResponseApiModel>(
      this.globalService.apiVersionHost + API_CHANGE_PASSWORD, param, this.header('employeeProfile')
    );
  }

  public getListDataMovement(param): Observable<ResponseApiModel> {
    return this.http
      .post<ResponseApiModel>(
        this.globalService.apiVersionHost + '/employee/hrd/list-data-movement',
        param,
        this.header()
      )
      .pipe(map(response => response));
  }

  public getMovementTracking(param): Observable<ResponseApiModel> {
    return this.http
      .post<ResponseApiModel>(
        this.globalService.apiVersionHost + '/employee/hrd/get-movement-track',
        param,
        this.header()
      )
      .pipe(map(response => response));
  }

  public getMovementStep(param: { id: number }): Observable<ResponseApiModel> {
    return this.http
      .post<ResponseApiModel>(
        this.globalService.apiVersionHost + '/employee/hrd/get-movement-step',
        param,
        this.header()
      )
      .pipe(map(response => response));
  }

  public exportExcel(param): Observable<ResponseApiModel> {
    return this.http.post<ResponseApiModel>(
      this.globalService.apiVersionHost + '/employee/hrd/export-excel', param, this.header()
    ).pipe(
      map(response => response)
    );
  }

  public getListExportResign(param): Observable<ResponseApiModel> {
    return this.http
      .post<ResponseApiModel>(
        this.globalService.apiVersionHost + '/employee/hrd/list-export-resign',
        param,
        this.header()
      )
      .pipe(map(response => response));
  }

  public getListExportVaccine(param): Observable<ResponseApiModel> {
    return this.http
      .post<ResponseApiModel>(
        this.globalService.apiVersionHost + '/employee/hrd/list-export-vaccine',
        param,
        this.header()
      )
      .pipe(map(response => response));
  }
  public resignExportExcel(param): Observable<ResponseApiModel> {
    return this.http.post<ResponseApiModel>(
      this.globalService.apiVersionHost + '/employee/hrd/resign-export-excel', param, this.header()
    ).pipe(
      map(response => response)
    );
  }

  public vaccineExportExcel(param): Observable<ResponseApiModel> {
    return this.http.post<ResponseApiModel>(
      this.globalService.apiVersionHost + '/employee/hrd/vaccine-export-excel', param, this.header()
    ).pipe(
      map(response => response)
    );
  }

  public generateEAF(param: { id: number }): Observable<ResponseApiModel> {
    return this.http.post<ResponseApiModel>(
      this.globalService.apiVersionHost + '/employee/hrd/generate-eaf', param, this.header()
    ).pipe(
      map(response => response)
    );
  }

  public generateEAFpdf(param: { id: number }): Observable<ResponseApiModel> {
    return this.http.post<ResponseApiModel>(
      this.globalService.apiVersionHost + '/employee/hrd/generate-eaf-pdf', param, this.header()
    ).pipe(
      map(response => response)
    );
  }

  public allEmpExportExcel(): Observable<ResponseApiModel> {
    return this.http.post<ResponseApiModel>(
      this.globalService.apiVersionHost + API_ALL_EMP_EXPORT_EXCEL, this.header('employeeAdmin')
    ).pipe(
      map(response => response)
    );
  }

  public cancelJoin(param: any): Observable<ResponseApiModel> {
    return this.http.post<ResponseApiModel>(
      this.globalService.apiVersionHost + API_CANCEL_JOIN, param, this.header()
    ).pipe(
      map(response => response)
    );
  }

  public findEmployee(param: { pageSize: number, page: number, filter: any }): Observable<ResponseApiModel> {
    return this.http.post<ResponseApiModel>(
      this.globalService.apiVersionHost + API_FIND_EMPLOYEE, param, this.header()
    ).pipe(
      map(response => response)
    );
  }

  public listPosition(param): Observable<ResponseApiModel> {
    return this.http.post<ResponseApiModel>(
      this.globalService.apiVersionHost + '/employeeAdmin/dev/list-position', param, this.header('employeeAdmin')
    );
  }

  public addPosition(param): Observable<ResponseApiModel> {
    return this.http.post<ResponseApiModel>(
      this.globalService.apiVersionHost + '/employeeAdmin/dev/create-position', param, this.header('employeeAdmin')
    );
  }

  public editPosition(param): Observable<ResponseApiModel> {
    return this.http.post<ResponseApiModel>(
      this.globalService.apiVersionHost + '/employeeAdmin/dev/edit-position', param, this.header('employeeAdmin')
    );
  }

  public listNWAssignment(param): Observable<ResponseApiModel> {
    return this.http.post<ResponseApiModel>(
      this.globalService.apiVersionHost + '/employeeAdmin/dev/list-nw-assignment', param, this.header('employeeAdmin')
    );
  }

  public addNWAssignment(param): Observable<ResponseApiModel> {
    return this.http.post<ResponseApiModel>(
      this.globalService.apiVersionHost + '/employeeAdmin/dev/create-nw-assignment', param, this.header('employeeAdmin')
    );
  }

  public editNWAssignment(param): Observable<ResponseApiModel> {
    return this.http.post<ResponseApiModel>(
      this.globalService.apiVersionHost + '/employeeAdmin/dev/edit-nw-assignment', param, this.header('employeeAdmin')
    );
  }

  public listMappingPaPcode(param): Observable<ResponseApiModel> {
    return this.http.post<ResponseApiModel>(
      this.globalService.apiVersionHost + '/employeeAdmin/dev/mapping-pa-pcode', param, this.header('employeeAdmin')
    );
  }

  public addMappingPaPcode(param): Observable<ResponseApiModel> {
    return this.http.post<ResponseApiModel>(
      this.globalService.apiVersionHost + '/employeeAdmin/dev/create-mapping-pa-pcode', param, this.header('employeeAdmin')
    );
  }

  public deleteMappingPaPcode(id): Observable<ResponseApiModel> {
    return this.http.post<ResponseApiModel>(
      this.globalService.apiVersionHost + '/employeeAdmin/dev/delete-mapping-pa-pcode', { id }, this.header('employeeAdmin')
    );
  }

  public getEmployeeMov(param): Observable<ResponseApiModel> {
    return this.http.post<ResponseApiModel>(
      this.globalService.apiVersionHost + API_GET_EMPLOYEE_MOV,
      param,
      this.header()
    );
  }

  public submit(param): Observable<ResponseApiModel> {
    return this.http.post<ResponseApiModel>(
      this.globalService.apiVersionHost + API_SUBMIT,
      param,
      this.header()
    );
  }

  public releaseTicket(param): Observable<ResponseApiModel> {
    return this.http.post<ResponseApiModel>(
      this.globalService.apiVersionHost + API_RELEASE_TICKET,
      param,
      this.header()
    );
  }

  public getDataFieldOpsProd(param): Observable<ResponseApiModel> {
    return this.http.post<ResponseApiModel>(
      this.globalService.apiVersionHost + API_GET_DATA_FIELD_OPS_PROD,
      param,
      this.header('productivity')
    );
  }

  public exportDataFieldOpsProd(param): Observable<ResponseApiModel> {
    return this.http.post<ResponseApiModel>(
      this.globalService.apiVersionHost + API_EXPORT_DATA_FIELD_OPS_PROD,
      param,
      this.header('productivity')
    );
  }

  public getDataVaccine(param): Observable<ResponseApiModel> {
    return this.http.post<ResponseApiModel>(
      this.globalService.apiVersionHost + API_GET_DATA_VACCINE,
      param,
      this.header('employee')
    );
  }

  public exportDataVaccine(param): Observable<ResponseApiModel> {
    return this.http.post<ResponseApiModel>(
      this.globalService.apiVersionHost + API_EXPORT_DATA_VACCINE,
      param,
      this.header('employee')
    );
  }

  public getEmployeeProfile(param): Observable<ResponseApiModel> {
    return this.http.post<ResponseApiModel>(
      this.globalService.apiVersionHost + API_GET_EMPLOYEE_PROFILE,
      param,
      this.header('employee')
    );
  }
  public getListEmployeePiutang(): Observable<ResponseApiModel> {
    return this.http.post<ResponseApiModel>(
      this.globalService.apiVersionHost + "/employee/piutang/list-employee-piutang",
      null,
      this.header('employee')
    );
  }
  public getEmployeeReceiveble(param: any): Observable<ResponseApiModel> {
    return this.http.post<ResponseApiModel>(
      this.globalService.apiVersionHost + "/employee/piutang/list-receiveble",
      param,
      this.header('employee')
    );
  }
  public submitBayarReceiveble(param: any): Observable<ResponseApiModel> {
    return this.http.post<ResponseApiModel>(
      this.globalService.apiVersionHost + "/employee/piutang/submit-bayar",
      param,
      this.header('employee')
    );
  }
  public importBayar(param: { data: any }): Observable<ResponseApiModel> {
    return this.http.post<ResponseApiModel>(
      this.globalService.apiVersionHost + "/employee/hrd/import-bayar",
      param,
      this.header('employee')
    );
  }
  public submitHutangReceiveble(param: any): Observable<ResponseApiModel> {
    return this.http.post<ResponseApiModel>(
      this.globalService.apiVersionHost + "/employee/piutang/submit-hutang",
      param,
      this.header('employee')
    );
  }
  public getDashboardSummary(params: any): Observable<ResponseApiModel> {
    return this.http.post<ResponseApiModel>(
      this.globalService.apiVersionHost + '/employee/default/get-dashboard-summary', params, this.header()
    ).pipe(
      map(response => response)
    );
  }

  public checkAgreement(params): Observable<ResponseApiModel> {
    return this.http.post<ResponseApiModel>(
      this.globalService.apiVersionHost + '/employee/default/check-agreement', params, this.header()
    ).pipe(
      map(response => response)
    );
  }

  public submitAgreement(params): Observable<ResponseApiModel> {
    return this.http.post<ResponseApiModel>(
      this.globalService.apiVersionHost + '/employee/default/submit-agreement', params, this.header()
    ).pipe(
      map(response => response)
    );
  }
}