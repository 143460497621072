/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./approval.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/forms";
import * as i3 from "./approval.component";
import * as i4 from "@angular/router";
import * as i5 from "../../../../_services/approval.service";
import * as i6 from "@ng-bootstrap/ng-bootstrap";
var styles_ApprovalComponent = [i0.styles];
var RenderType_ApprovalComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ApprovalComponent, data: {} });
export { RenderType_ApprovalComponent as RenderType_ApprovalComponent };
function View_ApprovalComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [["class", "modal-header"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "h4", [["class", "modal-title"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Login Form"])), (_l()(), i1.ɵeld(3, 0, null, null, 2, "button", [["aria-label", "Close"], ["class", "close"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (_v.context.$implicit.dismiss("Cross click") !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "span", [["aria-hidden", "true"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\u00D7"])), (_l()(), i1.ɵeld(6, 0, null, null, 22, "div", [["class", "modal-body"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 10, "div", [["class", "form-group row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 1, "label", [["class", "col-lg-3 col-form-label"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Username "])), (_l()(), i1.ɵeld(10, 0, null, null, 7, "div", [["class", "col-lg-9"]], null, null, null, null, null)), (_l()(), i1.ɵeld(11, 0, null, null, 6, "div", [["class", "input-group"]], null, null, null, null, null)), (_l()(), i1.ɵeld(12, 0, null, null, 5, "input", [["class", "form-control"], ["name", "username"], ["type", "text"]], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "ngModelChange"], [null, "input"], [null, "blur"], [null, "compositionstart"], [null, "compositionend"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("input" === en)) {
        var pd_0 = (i1.ɵnov(_v, 13)._handleInput($event.target.value) !== false);
        ad = (pd_0 && ad);
    } if (("blur" === en)) {
        var pd_1 = (i1.ɵnov(_v, 13).onTouched() !== false);
        ad = (pd_1 && ad);
    } if (("compositionstart" === en)) {
        var pd_2 = (i1.ɵnov(_v, 13)._compositionStart() !== false);
        ad = (pd_2 && ad);
    } if (("compositionend" === en)) {
        var pd_3 = (i1.ɵnov(_v, 13)._compositionEnd($event.target.value) !== false);
        ad = (pd_3 && ad);
    } if (("ngModelChange" === en)) {
        var pd_4 = ((_co.username = $event) !== false);
        ad = (pd_4 && ad);
    } return ad; }, null, null)), i1.ɵdid(13, 16384, null, 0, i2.DefaultValueAccessor, [i1.Renderer2, i1.ElementRef, [2, i2.COMPOSITION_BUFFER_MODE]], null, null), i1.ɵprd(1024, null, i2.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i2.DefaultValueAccessor]), i1.ɵdid(15, 671744, null, 0, i2.NgModel, [[8, null], [8, null], [8, null], [6, i2.NG_VALUE_ACCESSOR]], { name: [0, "name"], model: [1, "model"] }, { update: "ngModelChange" }), i1.ɵprd(2048, null, i2.NgControl, null, [i2.NgModel]), i1.ɵdid(17, 16384, null, 0, i2.NgControlStatus, [[4, i2.NgControl]], null, null), (_l()(), i1.ɵeld(18, 0, null, null, 10, "div", [["class", "form-group row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(19, 0, null, null, 1, "label", [["class", "col-lg-3 col-form-label"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Password "])), (_l()(), i1.ɵeld(21, 0, null, null, 7, "div", [["class", "col-lg-9"]], null, null, null, null, null)), (_l()(), i1.ɵeld(22, 0, null, null, 6, "div", [["class", "input-group"]], null, null, null, null, null)), (_l()(), i1.ɵeld(23, 0, null, null, 5, "input", [["class", "form-control"], ["name", "password"], ["type", "password"]], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "ngModelChange"], [null, "input"], [null, "blur"], [null, "compositionstart"], [null, "compositionend"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("input" === en)) {
        var pd_0 = (i1.ɵnov(_v, 24)._handleInput($event.target.value) !== false);
        ad = (pd_0 && ad);
    } if (("blur" === en)) {
        var pd_1 = (i1.ɵnov(_v, 24).onTouched() !== false);
        ad = (pd_1 && ad);
    } if (("compositionstart" === en)) {
        var pd_2 = (i1.ɵnov(_v, 24)._compositionStart() !== false);
        ad = (pd_2 && ad);
    } if (("compositionend" === en)) {
        var pd_3 = (i1.ɵnov(_v, 24)._compositionEnd($event.target.value) !== false);
        ad = (pd_3 && ad);
    } if (("ngModelChange" === en)) {
        var pd_4 = ((_co.password = $event) !== false);
        ad = (pd_4 && ad);
    } return ad; }, null, null)), i1.ɵdid(24, 16384, null, 0, i2.DefaultValueAccessor, [i1.Renderer2, i1.ElementRef, [2, i2.COMPOSITION_BUFFER_MODE]], null, null), i1.ɵprd(1024, null, i2.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i2.DefaultValueAccessor]), i1.ɵdid(26, 671744, null, 0, i2.NgModel, [[8, null], [8, null], [8, null], [6, i2.NG_VALUE_ACCESSOR]], { name: [0, "name"], model: [1, "model"] }, { update: "ngModelChange" }), i1.ɵprd(2048, null, i2.NgControl, null, [i2.NgModel]), i1.ɵdid(28, 16384, null, 0, i2.NgControlStatus, [[4, i2.NgControl]], null, null), (_l()(), i1.ɵeld(29, 0, null, null, 4, "div", [["class", "modal-footer"]], null, null, null, null, null)), (_l()(), i1.ɵeld(30, 0, null, null, 1, "button", [["class", "btn btn-light"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (_v.context.$implicit.close("Close click") !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Close"])), (_l()(), i1.ɵeld(32, 0, null, null, 1, "button", [["class", "btn btn-success"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.submitByLogin() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Save"]))], function (_ck, _v) { var _co = _v.component; var currVal_7 = "username"; var currVal_8 = _co.username; _ck(_v, 15, 0, currVal_7, currVal_8); var currVal_16 = "password"; var currVal_17 = _co.password; _ck(_v, 26, 0, currVal_16, currVal_17); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 17).ngClassUntouched; var currVal_1 = i1.ɵnov(_v, 17).ngClassTouched; var currVal_2 = i1.ɵnov(_v, 17).ngClassPristine; var currVal_3 = i1.ɵnov(_v, 17).ngClassDirty; var currVal_4 = i1.ɵnov(_v, 17).ngClassValid; var currVal_5 = i1.ɵnov(_v, 17).ngClassInvalid; var currVal_6 = i1.ɵnov(_v, 17).ngClassPending; _ck(_v, 12, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); var currVal_9 = i1.ɵnov(_v, 28).ngClassUntouched; var currVal_10 = i1.ɵnov(_v, 28).ngClassTouched; var currVal_11 = i1.ɵnov(_v, 28).ngClassPristine; var currVal_12 = i1.ɵnov(_v, 28).ngClassDirty; var currVal_13 = i1.ɵnov(_v, 28).ngClassValid; var currVal_14 = i1.ɵnov(_v, 28).ngClassInvalid; var currVal_15 = i1.ɵnov(_v, 28).ngClassPending; _ck(_v, 23, 0, currVal_9, currVal_10, currVal_11, currVal_12, currVal_13, currVal_14, currVal_15); }); }
export function View_ApprovalComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(671088640, 1, { modalLogin: 0 }), (_l()(), i1.ɵeld(1, 0, null, null, 9, "div", [["class", "kt-grid kt-grid--ver kt-grid--root full-height"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 8, "div", [["class", "kt-grid__item kt-grid__item--fluid kt-grid  kt-error-v3"], ["style", "background-image: url(./assets/media/error/bg4.jpg);"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 7, "div", [["class", "kt-error_container"], ["style", "height: 1000px;"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 2, "span", [["class", "kt-error_number"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 1, "h1", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["PTNW"])), (_l()(), i1.ɵeld(7, 0, null, null, 1, "p", [["class", "kt-error_title kt-font-light"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Approval with one link... "])), (_l()(), i1.ɵeld(9, 0, null, null, 1, "p", [["class", "kt-error_subtitle"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Approval with one link... "])), (_l()(), i1.ɵand(0, [[1, 2], ["modalLogin", 2]], null, 0, null, View_ApprovalComponent_1))], null, null); }
export function View_ApprovalComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-approval", [], null, null, null, View_ApprovalComponent_0, RenderType_ApprovalComponent)), i1.ɵdid(1, 114688, null, 0, i3.ApprovalComponent, [i4.ActivatedRoute, i5.ApprovalService, i6.NgbModal], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ApprovalComponentNgFactory = i1.ɵccf("app-approval", i3.ApprovalComponent, View_ApprovalComponent_Host_0, {}, {}, []);
export { ApprovalComponentNgFactory as ApprovalComponentNgFactory };
