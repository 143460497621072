import { HttpClient, HttpHeaders } from "@angular/common/http";
import { GlobalService } from "./global.service";
import { CookieService } from "ngx-cookie-service";
import { Observable, Subject } from "rxjs";
import { map } from "rxjs/operators";
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "./global.service";
import * as i3 from "ngx-cookie-service";
var API_PM_GET_HEALTHY = "/pm/precalc/get-healthy";
var API_ADDITIONAL = "/pm/precalc/get-forecast-temp";
var API_PM_GET_SUMMARY = "/pm/precalc/get-summary";
var API_PM_GET_HEALTHY_LIVE = "/pm/precalc/get-healthy-live";
var API_PM_GET_SUMMARY_LIVE = "/pm/precalc/get-summary-live";
var API_PM_GET_SUMMARY_ALL = "/pm/precalc/get-summary-all";
var API_PM_GET_PRECALC_BY_HASH = "/pm/precalc/get-precalc-by-hash";
var API_DELETE_FORECAST_COST_ALL = "/pm/tracker/delete-forecast-cost";
var API_DELETE_FORECAST_INCOME_ALL = "/pm/tracker/delete-forecast-income";
var API_APPROVE = "/pm/gm/approve-forecast";
var API_APPROVE_ALL = "/pm/gm/approve-forecast-all";
var API_PM_GET_PROJECT_BY_HASH = "/pm/precalc/get-project-by-hash";
var API_PM_CLOSE_PRECALC = "/pm/pmo/close-precalc";
var API_PM_GET_CASH_FLOW = "/pm/precalc/get-cash-flow";
var API_PM_SAVE_CASH_FLOW = "/pm/precalc/save-cash-flow";
var API_PM_EDIT_CASH_FLOW = "/pm/precalc/edit-cash-flow";
var API_PM_GET_PC_BY_PROJECT = "/pm/precalc/get-pc-by-project";
var API_GM_LIST_APPROVAL = "/pm/gm/list-approval";
var API_PM_GET_MONTHLY_COST = "/pm/precalc/get-monthly-cost";
var API_PM_CLOSE_PRECALCS = '/pm/pmo/close-precalcs';
var API_PM_GET_UNBILLED = "/pm/unbilled/get-unbilled";
var API_PM_GET_UNBILLED_PROJECT = "/pm/unbilled/get-project";
var API_PM_ADD_UNBILLED = "/pm/unbilled/add-unbilled";
var API_PM_UPDATE_UNBILLED = "/pm/unbilled/edit-unbilled";
var API_PM_UPDATE_UNBILLED_PERCELL = "/pm/unbilled/edit-unbilled-percell";
var API_PM_DELETE_UNBILLED = "/pm/unbilled/delete-unbilled";
var API_PM_GET_PROJECT_CLIENT = "/pm/unbilled/get-project-client";
var API_PM_IMPORT_UNBILLED = "/pm/unbilled/import-unbilled";
var API_PM_ADD_FORECAST = "/pm/tracker/new-forecast-cost";
var API_PM_IMPORT_NEW_FORECAST = "/pm/tracker/import-forecast-cost";
var API_PM_IMPORT_NEW_FORECAST_TEMP = "/pm/tracker/import-forecast-cost-temp";
var API_PM_UPDATE_FORECAST = "/pm/tracker/update-forecast";
var API_PM_GET_FORECASTS = "/pm/tracker/get-forecasts";
var API_PM_GET_FORECAST = "/pm/tracker/get-forecast";
var API_PM_DELETE_FORECAST = "/pm/tracker/delete-forecast";
var API_PM_DELETE_FORECAST_SELECTED = "/pm/tracker/delete-forecast-selected";
var API_PM_IMPORT_FORECAST = "/pm/tracker/import";
var API_PM_REVISE_FORECAST = "/pm/tracker/revise-forecast";
var PrecalcService = /** @class */ (function () {
    function PrecalcService(http, globalService, cookieService) {
        this.http = http;
        this.globalService = globalService;
        this.cookieService = cookieService;
        this.isPrecalcOpenEmit = new Subject();
        this.isPrecalcOpenEmitObs = this.isPrecalcOpenEmit.asObservable();
        this.pcodeChange = new Subject();
        this.pcodeChangeObs = this.pcodeChange.asObservable();
        this.isPrecalcOpenEmit.next(false);
        this.pcodeChange.next(false);
    }
    PrecalcService.prototype.header = function () {
        return {
            headers: new HttpHeaders()
                .set("token", this.cookieService.get("_q"))
                .set("module", localStorage.getItem("pm"))
                .set("group", this.cookieService.get("_g"))
        };
    };
    PrecalcService.prototype.getAdditional = function (param) {
        return this.http
            .post(this.globalService.apiVersionHost + API_ADDITIONAL, param, this.header())
            .pipe(map(function (response) { return response; }));
    };
    PrecalcService.prototype.getHealthy = function (param) {
        return this.http
            .post(this.globalService.apiVersionHost + API_PM_GET_HEALTHY, param, this.header())
            .pipe(map(function (response) { return response; }));
    };
    PrecalcService.prototype.getHealthyNew = function (param) {
        return this.http
            .post(this.globalService.apiVersionHost + '/pm/precalc/get-healthy-new', param, this.header())
            .pipe(map(function (response) { return response; }));
    };
    PrecalcService.prototype.getHealthyPcode = function (param) {
        return this.http
            .post(this.globalService.apiVersionHost + '/pm/precalc/get-healthy-pcode', param, this.header())
            .pipe(map(function (response) { return response; }));
    };
    PrecalcService.prototype.getSummary = function (param) {
        return this.http
            .post(this.globalService.apiVersionHost + API_PM_GET_SUMMARY, param, this.header())
            .pipe(map(function (response) { return response; }));
    };
    PrecalcService.prototype.getDataPrecalcAnalysis = function (param) {
        return this.http
            .post(this.globalService.apiVersionHost + '/pm/precalc/precalc-analysis', param, this.header())
            .pipe(map(function (response) { return response; }));
    };
    PrecalcService.prototype.getHealthyLive = function (param) {
        return this.http
            .post(this.globalService.apiVersionHost + API_PM_GET_HEALTHY_LIVE, param, this.header())
            .pipe(map(function (response) { return response; }));
    };
    PrecalcService.prototype.getHealthyLiveNew = function (param) {
        return this.http
            .post(this.globalService.apiVersionHost + "/pm/precalc/get-healthy-live-new", param, this.header())
            .pipe(map(function (response) { return response; }));
    };
    PrecalcService.prototype.getHealthyLivePcode = function (param) {
        return this.http
            .post(this.globalService.apiVersionHost + "/pm/precalc/get-healthy-live-pcode", param, this.header())
            .pipe(map(function (response) { return response; }));
    };
    PrecalcService.prototype.getSummaryAll = function (param) {
        return this.http
            .post(this.globalService.apiVersionHost + API_PM_GET_SUMMARY_ALL, param, this.header())
            .pipe(map(function (response) { return response; }));
    };
    PrecalcService.prototype.getSummaryLive = function (param) {
        return this.http
            .post(this.globalService.apiVersionHost + API_PM_GET_SUMMARY_LIVE, param, this.header())
            .pipe(map(function (response) { return response; }));
    };
    PrecalcService.prototype.saveUpdateItemValue = function (param) {
        return this.http
            .post(this.globalService.apiVersionHost + '/pm/tracker/update-item-value', param, this.header())
            .pipe(map(function (response) { return response; }));
    };
    PrecalcService.prototype.getPrecalcByHash = function (params) {
        return this.http
            .post(this.globalService.apiVersionHost + API_PM_GET_PRECALC_BY_HASH, params, this.header())
            .pipe(map(function (response) { return response; }));
    };
    PrecalcService.prototype.deleteForecastCostAll = function (params) {
        return this.http
            .post(this.globalService.apiVersionHost + API_DELETE_FORECAST_COST_ALL, params, this.header())
            .pipe(map(function (response) { return response; }));
    };
    PrecalcService.prototype.deleteForecastIncomeAll = function (params) {
        return this.http
            .post(this.globalService.apiVersionHost + API_DELETE_FORECAST_INCOME_ALL, params, this.header())
            .pipe(map(function (response) { return response; }));
    };
    PrecalcService.prototype.approveForecast = function (params) {
        return this.http
            .post(this.globalService.apiVersionHost + API_APPROVE, params, this.header())
            .pipe(map(function (response) { return response; }));
    };
    PrecalcService.prototype.approveForecastAll = function (params) {
        return this.http
            .post(this.globalService.apiVersionHost + API_APPROVE_ALL, params, this.header())
            .pipe(map(function (response) { return response; }));
    };
    PrecalcService.prototype.getProjectByHash = function (params) {
        return this.http
            .post(this.globalService.apiVersionHost + API_PM_GET_PROJECT_BY_HASH, params, this.header())
            .pipe(map(function (response) { return response; }));
    };
    PrecalcService.prototype.closePrecalc = function (params) {
        return this.http
            .post(this.globalService.apiVersionHost + API_PM_CLOSE_PRECALC, params, this.header())
            .pipe(map(function (response) { return response; }));
    };
    PrecalcService.prototype.getCashFlow = function (params) {
        return this.http
            .post(this.globalService.apiVersionHost + API_PM_GET_CASH_FLOW, params, this.header())
            .pipe(map(function (response) { return response; }));
    };
    PrecalcService.prototype.saveCashFlow = function (params) {
        return this.http
            .post(this.globalService.apiVersionHost + API_PM_SAVE_CASH_FLOW, params, this.header())
            .pipe(map(function (response) { return response; }));
    };
    PrecalcService.prototype.editCashFlow = function (params) {
        return this.http
            .post(this.globalService.apiVersionHost + API_PM_EDIT_CASH_FLOW, params, this.header())
            .pipe(map(function (response) { return response; }));
    };
    PrecalcService.prototype.getPCByPRoject = function (params) {
        return this.http
            .post(this.globalService.apiVersionHost + API_PM_GET_PC_BY_PROJECT, params, this.header())
            .pipe(map(function (response) { return response; }));
    };
    PrecalcService.prototype.listApproval = function (params) {
        return this.http
            .post(this.globalService.apiVersionHost + API_GM_LIST_APPROVAL, params, this.header())
            .pipe(map(function (response) { return response; }));
    };
    PrecalcService.prototype.getMonthlyCost = function (params) {
        return this.http
            .post(this.globalService.apiVersionHost + API_PM_GET_MONTHLY_COST, params, this.header())
            .pipe(map(function (response) { return response; }));
    };
    PrecalcService.prototype.closePrecalcs = function (params) {
        return this.http
            .post(this.globalService.apiVersionHost + API_PM_CLOSE_PRECALCS, params, this.header())
            .pipe(map(function (response) { return response; }));
    };
    PrecalcService.prototype.getUnbilled = function (params) {
        return this.http
            .post(this.globalService.apiVersionHost + API_PM_GET_UNBILLED, params, this.header())
            .pipe(map(function (response) { return response; }));
    };
    PrecalcService.prototype.getProjectUnbilled = function (params) {
        return this.http
            .post(this.globalService.apiVersionHost + API_PM_GET_UNBILLED_PROJECT, params, this.header())
            .pipe(map(function (response) { return response; }));
    };
    PrecalcService.prototype.addUnbilled = function (params) {
        return this.http
            .post(this.globalService.apiVersionHost + API_PM_ADD_UNBILLED, params, this.header())
            .pipe(map(function (response) { return response; }));
    };
    PrecalcService.prototype.editUnbilled = function (params) {
        return this.http
            .post(this.globalService.apiVersionHost + API_PM_UPDATE_UNBILLED, params, this.header())
            .pipe(map(function (response) { return response; }));
    };
    PrecalcService.prototype.editUnbilledPercell = function (data) {
        return this.http
            .post(this.globalService.apiVersionHost + API_PM_UPDATE_UNBILLED_PERCELL, { data: data }, this.header())
            .pipe(map(function (response) { return response; }));
    };
    PrecalcService.prototype.deleteUnbilled = function (params) {
        return this.http
            .post(this.globalService.apiVersionHost + API_PM_DELETE_UNBILLED, params, this.header())
            .pipe(map(function (response) { return response; }));
    };
    PrecalcService.prototype.getProjectClient = function () {
        return this.http
            .get(this.globalService.apiVersionHost + API_PM_GET_PROJECT_CLIENT, this.header())
            .pipe(map(function (response) { return response; }));
    };
    PrecalcService.prototype.importUnbilled = function (params) {
        return this.http
            .post(this.globalService.apiVersionHost + API_PM_IMPORT_UNBILLED, params, this.header())
            .pipe(map(function (response) { return response; }));
    };
    PrecalcService.prototype.importForecastCost = function (param) {
        return this.http
            .post(this.globalService.apiVersionHost + API_PM_IMPORT_NEW_FORECAST, param, this.header())
            .pipe(map(function (response) { return response; }));
    };
    PrecalcService.prototype.importForecastCostTemp = function (param) {
        return this.http
            .post(this.globalService.apiVersionHost + API_PM_IMPORT_NEW_FORECAST_TEMP, param, this.header())
            .pipe(map(function (response) { return response; }));
    };
    PrecalcService.prototype.addForecast = function (param) {
        return this.http
            .post(this.globalService.apiVersionHost + API_PM_ADD_FORECAST, param, this.header())
            .pipe(map(function (response) { return response; }));
    };
    PrecalcService.prototype.updateForecast = function (param) {
        return this.http
            .post(this.globalService.apiVersionHost + API_PM_UPDATE_FORECAST, param, this.header())
            .pipe(map(function (response) { return response; }));
    };
    PrecalcService.prototype.getForecasts = function (param) {
        return this.http
            .post(this.globalService.apiVersionHost + API_PM_GET_FORECASTS, param, this.header())
            .pipe(map(function (response) { return response; }));
    };
    PrecalcService.prototype.getForecastcost = function (x) {
        return this.http
            .post(this.globalService.apiVersionHost + "/pm/tracker/get-forecast-cost", { hash: x }, this.header())
            .pipe(map(function (response) { return response; }));
    };
    PrecalcService.prototype.exportForecastcost = function (x) {
        return this.http
            .post(this.globalService.apiVersionHost + "/pm/tracker/export-forecast-cost", { hash: x }, this.header())
            .pipe(map(function (response) { return response; }));
    };
    PrecalcService.prototype.exportForecastIncome = function (x) {
        return this.http
            .post(this.globalService.apiVersionHost + "/pm/tracker/export-forecast-income", { hash: x }, this.header())
            .pipe(map(function (response) { return response; }));
    };
    PrecalcService.prototype.getForecast = function (x) {
        return this.http
            .post(this.globalService.apiVersionHost + API_PM_GET_FORECAST, { hash: x }, this.header())
            .pipe(map(function (response) { return response; }));
    };
    PrecalcService.prototype.deleteForecast = function (x) {
        return this.http
            .post(this.globalService.apiVersionHost + API_PM_DELETE_FORECAST, { id: x }, this.header())
            .pipe(map(function (response) { return response; }));
    };
    PrecalcService.prototype.deleteForecastSelected = function (id_selected) {
        return this.http
            .post(this.globalService.apiVersionHost + API_PM_DELETE_FORECAST_SELECTED, { id_selected: id_selected }, this.header())
            .pipe(map(function (response) { return response; }));
    };
    PrecalcService.prototype.reviseForecast = function (param) {
        return this.http
            .post(this.globalService.apiVersionHost + API_PM_REVISE_FORECAST, param, this.header())
            .pipe(map(function (response) { return response; }));
    };
    PrecalcService.prototype.import = function (params) {
        return this.http
            .post(this.globalService.apiVersionHost + API_PM_IMPORT_FORECAST, params, this.header())
            .pipe(map(function (response) { return response; }));
    };
    PrecalcService.prototype.getCostCategory = function (param) {
        return this.http.post(this.globalService.apiVersionHost + '/pm/costcategory/get-cost-category', param, this.header()).pipe(map(function (response) { return response; }));
    };
    PrecalcService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function PrecalcService_Factory() { return new PrecalcService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.GlobalService), i0.ɵɵinject(i3.CookieService)); }, token: PrecalcService, providedIn: "root" });
    return PrecalcService;
}());
export { PrecalcService };
