<div id="nds_contents">
  <div class="bg-white navbar-spacer"></div>
  <div class="pagecomponentwrap text-center text-white " id="pageheader">
    <ng-container *ngIf="!isStart">
      <div class="container pb-5 pt-75">
        <div class="no-gutters row justify-content-center align-items-center aos-init aos-animate" data-aos="">
          <div class="col-md-10 aos-init aos-animate" data-aos="flip-up">
            <h2 class="stack_font2 h1 ">
              {{dataBefore ? dataBefore.obj_exam.nama_ujian : 'Please Wait..'}}
            </h2>
            <nav class="stack_font3 text-white small pb-3 " aria-label="breadcrumb">
              <ol class="breadcrumb-page text-capitalize">
                <li class="breadcrumb-item-page">
                  <a class="stack_font3 text-white small pb-3" [routerLink]="['/home']" routerLinkActive="router-link-active">
                    Home
                  </a>
                </li>
                <li class="breadcrumb-item-page">
                  /
                </li>
                <li class="breadcrumb-item-page active">
                  <a href="javascript:" (click)="reloadPage()" class="stack_font3 text-white small pb-3">
                    {{dataBefore ? dataBefore.obj_exam.nama_ujian : ''}}
                  </a>
                </li>
              </ol>
            </nav>
          </div>
        </div>
      </div>
    </ng-container>
  </div>

  <!-- DESC EXAMS -->
  <ng-container *ngIf="dataBefore && !isStart">
    <div class="pagecomponentwrap bg-white">
      <div class="container pb-100 pt-75">
        <div class="row align-items-center">
          <div class="col-md-3">
            <img src="/assets/media/nds/examp.svg" alt="" class="w-100 px-4">
          </div>
          <div class="col-md-8 mb-4">
            <div class="border px-3 px-md-4 py-3 py-md-4 rounded mb-5 bg-light">
              <div class="row">
                <div class="col-md-6 text-center">
                  <h3 class="kt-portlet__head-title">{{dataBefore.arr_participant.start_valid}}</h3>
                  <span style="color:#51b0e7"><strong>BERLAKU MULAI</strong></span>
                </div>
                <div class="col-md-6 text-center">
                  <h3 class="kt-portlet__head-title">{{dataBefore.arr_participant.end_valid}}</h3>
                  <span style="color:#51b0e7"><strong>BERLAKU HINGGA</strong></span>
                </div>
              </div>
              <hr>
              <div class="row">
                <div class="col-md-9">
                  <span style="font-size: 16.5px">Kode Voucher : </span>
                </div>
              </div>
              <div class="row">
                <div class="input-group">
                  <input value="{{dataBefore.arr_participant.voucher}}" type="text" class="form-control" placeholder="Kode voucher" aria-label="voucher" aria-describedby="basic-addon2" disabled>
                  <div class="input-group-append">
                    <button class="btn btn-primary" type="button" (click)="doingExam()">
                      START EXAM
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <div class="mb-3">
                Jika ada kesulitan Anda dapat menghubungi NDS Care melalui kontak beriut:
              </div>
              <div>
                <a class="btn btn-sm btn-primary mr-2" href="https://t.me/NdsCare">
                  <span class="fab fa-telegram-plane mr-1"></span>
                   @NdsCare
                </a>
                <a class="btn btn-sm btn-primary" href="https://www.google.com/gmail/">
                  <span class="fa fa-envelope mr-1"></span>
                   dev.nexwave@gmail.com
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- <div class="row" style="margin-left: 0px; margin-right: 0px;">
      <div class="col-md-3">
        <img src="https://ptnw.net/images/header/notif.png" alt="" style="width: inherit">
      </div>
      <div class="col-md-9">
        <div class="kt-portlet">
          <div class="kt-portlet__body">
            <div class="row">
              <div class="col-md-6 text-center">
                <h3 class="kt-portlet__head-title">{{dataBefore.arr_participant.start_valid}}</h3>
                <span style="color:#51b0e7"><strong>BERLAKU MULAI</strong></span>
              </div>
              <div class="col-md-6 text-center">
                <h3 class="kt-portlet__head-title">{{dataBefore.arr_participant.end_valid}}</h3>
                <span style="color:#51b0e7"><strong>BERLAKU HINGGA</strong></span>
              </div>
            </div>
            <hr>
            <div class="row">
              <div class="col-md-12">
                <div class="kt-portlet kt-portlet--mobile kt-portlet--bordered">
                  <div class="kt-portlet__body portlet-bg">
                    <div class="row">
                      <div class="col-md-9">
                        <span style="font-size: 16.5px">Kode Voucher : <strong>{{dataBefore.arr_participant.voucher}}</strong></span><br/>
                      </div>
                      <div class="col-md-3">
                        <button class="btn btn-lg btn-primary" (click)="doingExam()">START EXAM</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row mt-5">
              <div class="col-md-12">
                <div>
                  <span style="font-size: 13px">Jika ada kesulitan Anda dapat menghubungi NDS Care melalui kontak beriut:</span>
                </div>
                <div class="mt-3">
                  <span class="mr-2"><i class="fa fa-telegram"></i></span>
                  <a href="https://t.me/NdsCare">@NdsCare</a>
                </div>
                <div class="mt-3">
                  <span class="mr-2"><i class="fa fa-envelope-open-o"></i></span>
                  <a href="https://www.google.com/gmail/">dev.nexwave@gmail.com</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div> -->
  </ng-container>

  <!-- DOING EXAM -->
  <ng-container *ngIf="isStart">
    <!--begin::Form-->
    <form class="bg-light py-5 border-botttom">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-lg-11">
            <div id="form-section">
              <div class="kt-portlet">
                <div class="kt-portlet__head bg-primary">
                  <div class="kt-portlet__head-label">
                    <h3 class="kt-portlet__head-title text-white">
                      {{oneExamsSectionActive ? oneExamsSectionActive.judul_form : 'Judul Section'}}
                    </h3>
                  </div>
                </div>
                <div class="kt-portlet__body">
                  <ng-container *ngIf="oneExamsSectionActive && oneExamsSectionActive.description_form">
                    <div class="form-section-description">
                      {{oneExamsSectionActive ? oneExamsSectionActive.description_form : 'Deskripsi section'}}
                    </div>
                  </ng-container>
                  <div class="py-3 py-md-4 px-3 px-md-4 mb-3 mb-md-4 form-component rounded" *ngFor="let row of examsSectionActive">
                    <div class="pt-1 pb-2 pb-md-3 h5">
                      {{formatQuestion(row)}} <strong class="text-danger">{{row.required === 1 ? '*' : ''}}</strong>
                    </div>
                    <div class="d-flex justify-content-start">
                      <div class="pt-2 pb-2 pr-2">
                         Jawaban 
                      </div>
                    </div>
                    <ng-container *ngIf="formatTipe(row) === 'single choice' || formatTipe(row) === 'singlechoice' || formatTipe(row) === 'true/false' || formatTipe(row) === 'multiple choice' || formatTipe(row) === 'multiple_choice'">
                      <div class="row pt-2 pt-md-3">
                        <ng-container *ngIf="row.image_attachment || row.video_attachment">
                          <div class="col-md-6">
                            <div class="pb-3 pb-md-4 form-component-image">
                              <img [src]="'https://s3.wasabisys.com/s3.ptnw.net/uploads/exam/' + row.image_attachment" class="w-100" alt="image" [class.d-none]="!row.image_attachment">
                              <video class="w-100" alt="video" [class.d-none]="!row.video_attachment" controls>
                                <source [src]="'https://s3.wasabisys.com/s3.ptnw.net/uploads/exam/' + row.video_attachment">
                                Your browser does not support the video tag.
                              </video>
                            </div>
                          </div>
                        </ng-container>
                        <div class="col-12 pb-3 mb-3">
                          <ng-container
                            [ngTemplateOutlet]="formatTipe(row) === 'single choice' ? templateSingleChoice : formatTipe(row) === 'true/false' ? templateSingleChoice : formatTipe(row) === 'singlechoice' ? templateSingleChoice : templateMultipleChoice"
                            [ngTemplateOutletContext]="{row: row}">
                          </ng-container>
                        </div>
                      </div>
                    </ng-container>
                    <ng-container *ngIf="formatTipe(row) === 'dropdown'">
                      <div class="row pt-2 pt-md-3">
                        <ng-container *ngIf="row.image_attachment || row.video_attachment">
                          <div class="col-md-12">
                            <div class="pb-3 pb-md-4 form-component-image">
                              <img [src]="'https://s3.wasabisys.com/s3.ptnw.net/uploads/exam/' + row.image_attachment" class="w-100" alt="image" [class.d-none]="!row.image_attachment">
                              <video class="w-100" alt="video" [class.d-none]="!row.video_attachment" controls>
                                <source [src]="'https://s3.wasabisys.com/s3.ptnw.net/uploads/exam/' + row.video_attachment">
                                Your browser does not support the video tag.
                              </video>
                            </div>
                          </div>
                        </ng-container>
                        <div class="col-12 pb-3 mb-3">
                          <div class="pb-12 form-component-description">
                            {{row.deskripsi_pertanyaan}}
                          </div>
                        </div>
                        <div class="col-12 pb-3 mb-3">
                          <div class="pb-1 pb-md-2">
                            <select id="{{'jawaban_user'+row.id_detail_ujian}}" name="{{'jawaban_user'+row.id_detail_ujian}}"
                              class="form-control" [(ngModel)]="answers[row.id_detail_ujian]" (change)="onAnswering(row.id_detail_ujian, answers[row.id_detail_ujian])">
                              <option *ngFor="let rel of formatList(row)" [ngValue]="rel.key">
                                {{rel.val}}
                              </option>
                            </select>
                          </div>
                        </div>
                      </div>
                    </ng-container>
                    <ng-container *ngIf="formatTipe(row) === 'short_answer'">
                      <div class="row pt-2 pt-md-3">
                        <ng-container *ngIf="row.image_attachment || row.video_attachment">
                          <div class="col-md-12">
                            <div class="pb-3 pb-md-4 form-component-image">
                              <img [src]="'https://s3.wasabisys.com/s3.ptnw.net/uploads/exam/' + row.image_attachment" class="w-100" alt="image" [class.d-none]="!row.image_attachment">
                              <video class="w-100" alt="video" [class.d-none]="!row.video_attachment" controls>
                                <source [src]="'https://s3.wasabisys.com/s3.ptnw.net/uploads/exam/' + row.video_attachment">
                                Your browser does not support the video tag.
                              </video>
                            </div>
                          </div>
                        </ng-container>
                        <div class="col-12 pb-3 mb-3">
                          <div class="pb-12 form-component-description">
                            {{row.deskripsi_pertanyaan}}
                          </div>
                        </div>
                        <div class="col-12 pb-3 mb-3">
                          <div class="pb-1 pb-md-2">
                            <input class="form-control" name="{{'jawaban_user'+row.id_detail_ujian}}" [(ngModel)]="answers[row.id_detail_ujian]" id="{{'jawaban_user'+row.id_detail_ujian}}" (keyup.enter)="onAnswering(row.id_detail_ujian, answers[row.id_detail_ujian])" [placeholder]="answers[row.id_detail_ujian] ? answers[row.id_detail_ujian] : 'Jawaban Anda'">
                          </div>
                        </div>
                      </div>
                    </ng-container>
                    <ng-container *ngIf="formatTipe(row) === 'paragraph'">
                      <div class="row pt-2 pt-md-3">
                        <ng-container *ngIf="row.image_attachment || row.video_attachment">
                          <div class="col-md-12">
                            <div class="pb-3 pb-md-4 form-component-image">
                              <img [src]="'https://s3.wasabisys.com/s3.ptnw.net/uploads/exam/' + row.image_attachment" class="w-100" alt="image" [class.d-none]="!row.image_attachment">
                              <video class="w-100" alt="video" [class.d-none]="!row.video_attachment" controls>
                                <source [src]="'https://s3.wasabisys.com/s3.ptnw.net/uploads/exam/' + row.video_attachment">
                                Your browser does not support the video tag.
                              </video>
                            </div>
                          </div>
                        </ng-container>
                        <div class="col-12 pb-3 mb-3">
                          <div class="pb-12 form-component-description">
                            {{row.deskripsi_pertanyaan}}
                          </div>
                        </div>
                        <div class="col-12 pb-3 mb-3">
                          <div class="pb-1 pb-md-2">
                            <textarea class="form-control" name="{{'jawaban_user'+row.id_detail_ujian}}" [(ngModel)]="answers[row.id_detail_ujian]" id="{{'jawaban_user'+row.id_detail_ujian}}" (keyup.enter)="onAnswering(row.id_detail_ujian, answers[row.id_detail_ujian])" [placeholder]="answers[row.id_detail_ujian] ? answers[row.id_detail_ujian] : 'Jawaban Anda'"></textarea>
                          </div>
                        </div>
                      </div>
                    </ng-container>
                    <ng-container *ngIf="formatTipe(row) === 'date'">
                      <div class="row pt-2 pt-md-3">
                        <ng-container *ngIf="row.image_attachment || row.video_attachment">
                          <div class="col-md-12">
                            <div class="pb-3 pb-md-4 form-component-image">
                              <img [src]="'https://s3.wasabisys.com/s3.ptnw.net/uploads/exam/' + row.image_attachment" class="w-100" alt="image" [class.d-none]="!row.image_attachment">
                              <video class="w-100" alt="video" [class.d-none]="!row.video_attachment" controls>
                                <source [src]="'https://s3.wasabisys.com/s3.ptnw.net/uploads/exam/' + row.video_attachment">
                                Your browser does not support the video tag.
                              </video>
                            </div>
                          </div>
                        </ng-container>
                        <div class="col-12 pb-3 mb-3">
                          <div class="pb-12 form-component-description">
                            {{row.deskripsi_pertanyaan}}
                          </div>
                        </div>
                        <div class="col-12 pb-3 mb-3">
                          <div class="pb-1 pb-md-2">
                            <input type="date" class="form-control" name="{{'jawaban_user'+row.id_detail_ujian}}" [(ngModel)]="answers[row.id_detail_ujian]" id="{{'jawaban_user'+row.id_detail_ujian}}" (keyup.enter)="onAnswering(row.id_detail_ujian, answers[row.id_detail_ujian])" [placeholder]="answers[row.id_detail_ujian] ? answers[row.id_detail_ujian] : 'Jawaban Anda'">
                          </div>
                        </div>
                      </div>
                    </ng-container>
                    <ng-container *ngIf="formatTipe(row) === 'time'">
                      <div class="row pt-2 pt-md-3">
                        <ng-container *ngIf="row.image_attachment || row.video_attachment">
                          <div class="col-md-12">
                            <div class="pb-3 pb-md-4 form-component-image">
                              <img [src]="'https://s3.wasabisys.com/s3.ptnw.net/uploads/exam/' + row.image_attachment" class="w-100" alt="image" [class.d-none]="!row.image_attachment">
                              <video class="w-100" alt="video" [class.d-none]="!row.video_attachment" controls>
                                <source [src]="'https://s3.wasabisys.com/s3.ptnw.net/uploads/exam/' + row.video_attachment">
                                Your browser does not support the video tag.
                              </video>
                            </div>
                          </div>
                        </ng-container>
                        <div class="col-12 pb-3 mb-3">
                          <div class="pb-12 form-component-description">
                            {{row.deskripsi_pertanyaan}}
                          </div>
                        </div>
                        <div class="col-12 pb-3 mb-3">
                          <div class="pb-1 pb-md-2">
                            <input type="time" class="form-control" name="{{'jawaban_user'+row.id_detail_ujian}}" [(ngModel)]="answers[row.id_detail_ujian]" id="{{'jawaban_user'+row.id_detail_ujian}}" (keyup.enter)="onAnswering(row.id_detail_ujian, answers[row.id_detail_ujian])" [placeholder]="answers[row.id_detail_ujian] ? answers[row.id_detail_ujian] : 'Jawaban Anda'">
                          </div>
                        </div>
                      </div>
                    </ng-container>
                    <ng-container *ngIf="formatTipe(row) === 'file_upload'">
                      <div class="row pt-2 pt-md-3">
                        <ng-container *ngIf="row.image_attachment || row.video_attachment">
                          <div class="col-md-12">
                            <div class="pb-3 pb-md-4 form-component-image">
                              <img [src]="'https://s3.wasabisys.com/s3.ptnw.net/uploads/exam/' + row.image_attachment" class="w-100" alt="image" [class.d-none]="!row.image_attachment">
                              <video class="w-100" alt="video" [class.d-none]="!row.video_attachment" controls>
                                <source [src]="'https://s3.wasabisys.com/s3.ptnw.net/uploads/exam/' + row.video_attachment">
                                Your browser does not support the video tag.
                              </video>
                            </div>
                          </div>
                        </ng-container>
                        <div class="col-12 pb-3 mb-3">
                          <div class="pb-12 form-component-description">
                            {{row.deskripsi_pertanyaan}}
                          </div>
                        </div>
                        <div class="col-12 pb-3 mb-3">
                          <div class="pb-1 pb-md-2">
                            <app-gcs-upload [acceptFile]="['image/jpg','image/jpeg','image/png']" [remark]="''"
                                [prefix]="'uploads/exam/'" [module]="'exam'" [tableTarget]="'detail_ujian'" [columnTarget]="'image_attachment'"
                                (success)="onAnsweringUpload($event, row.id_detail_ujian, 'image_attachment')" >
                              </app-gcs-upload>
                          </div>
                        </div>
                      </div>
                    </ng-container>
                    <hr/>
                  </div>

                  <div class="d-flex justify-content-between">
                    <ng-container *ngIf="activeIndeks !== 1">
                      <a
                        href="javascript:"
                        class="btn btn-sm btn-primary"
                        (click)="navigation(false)">
                        <span>
                          <i class="fa fa-arrow-alt-circle-left"></i> SEBELUMNYA
                        </span>
                      </a>
                    </ng-container>
                    <ng-container *ngIf="activeIndeks !== total_section">
                      <a
                        href="javascript:"
                        class="btn btn-sm btn-primary"
                        (click)="navigation(true)">
                        <span>
                          SELANJUTNYA <i class="fa fa-arrow-alt-circle-right"></i>
                        </span>
                      </a>
                    </ng-container>
                    <ng-container *ngIf="activeIndeks === total_section">
                      <a
                        href="javascript:"
                        class="btn btn-sm btn-success"
                        (click)="submitExam()">
                        <span>
                          <i class="fa fa-send"></i> SIMPAN
                        </span>
                      </a>
                    </ng-container>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  </ng-container>
</div>

<!-- Template Single Choice -->
<ng-template #templateSingleChoice let-row='row'>
  <ng-container *ngFor="let mapping of apiMapping">
    <ng-container *ngIf="row[mapping]">
      <a href="javascript:" class="btn btn-outline text-left"
        [ngClass]="answers[row.id_detail_ujian] === mapping ? 'btn-primary' : 'btn-secondary'"
        (click)="onAnswering(row.id_detail_ujian, mapping)">
        <b>{{mapping}}. </b>
         {{row[mapping]}}
      </a>
    </ng-container>
  </ng-container>
</ng-template>

<!-- Template Multiple Choice -->
<ng-template #templateMultipleChoice let-row='row'>
  <ng-container *ngFor="let mapping of apiMapping">
    <ng-container *ngIf="row[mapping]">
      <label>
        <input type="checkbox" class="btn btn-outline text-left"
        [ngClass]="checkingAnswer(answers[row.id_detail_ujian], mapping) ? 'btn-primary' : 'btn-secondary'"
        (click)="onAnsweringMultiple(row.id_detail_ujian, mapping)"/>
        <b> {{mapping}}. </b>
         {{row[mapping]}}
      </label>
    </ng-container>
  </ng-container>
</ng-template>
