import { Component, OnInit, ElementRef, HostListener, Renderer2 } from '@angular/core';
import { AuthenticationService } from 'src/app/auth/authentication.service';
import { Router, ActivatedRoute } from '@angular/router';
import { MenuConfig } from 'src/app/config/menu';
import { UserService } from 'src/app/_services/user.service';
import { GlobalService } from 'src/app/_services/global.service';
import { LayoutService } from 'src/app/_services/layout.service';
import { HomeService } from 'src/app/_services/home.service';
import { MessagingService } from 'src/app/_services/messaging.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import Swal from 'sweetalert2';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import Fingerprint2 from '@fingerprintjs/fingerprintjs';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  apiHost: string;

  user: any = {};
  notifications: any[] = [];
  firstName: string;
  userDropDownShowed: boolean = false;
  notifDropDownShowed: boolean = false;
  moduleDropDownShowed: boolean = false;
  headerWidth: number;
  userDropdownOffset: number;
  moduleActive: string = '';
  pageActive: string = '';
  menuHeader: any[] = [];
  hash: string;
  total_notif: any;
  headerMenu: boolean;
  topBar: boolean;
  sideMenu: boolean;
  moduleMenu: boolean;
  allowNotification: boolean;
  fingerprint: string;

  constructor(
    private renderer: Renderer2,
    private eRef: ElementRef,
    public authService: AuthenticationService,
    private router: Router,
    private route: ActivatedRoute,
    public menuConfig: MenuConfig,
    private userService: UserService,
    private globalService: GlobalService,
    private layoutService: LayoutService,
    public homeService: HomeService,
    public messagingService: MessagingService,
    private modalService: NgbModal,
    private ngxLoader: NgxUiLoaderService
  ) {
    this.router.events.subscribe((val) => {
      let arr_url = this.router.url.split('/');
      if (arr_url[1] == 'main') {
        this.moduleActive = arr_url[2];
        this.pageActive = arr_url[3];
        this.hash = arr_url[4];
        let temp = this.menuConfig.config.header.filter(el => el.module === this.moduleActive);
        if (temp.length > 0) {
          this.menuHeader = temp[0]['items'];
        }
        else {
          this.menuHeader = [];
        }
      }
    });
    this.layoutService.mobileHeaderMenuToggleEmitObs.subscribe(res => {
      this.headerMenu = res;
      if (this.headerMenu) {
        this.renderer.addClass(document.body, 'kt-header-menu-wrapper--on');
      } else {
        this.renderer.removeClass(document.body, 'kt-header-menu-wrapper--on');
      }
    });
    this.layoutService.topBarToggleEmitObs.subscribe(res => {
      this.topBar = res;
      if (this.topBar) {
        this.renderer.addClass(document.body, 'kt-header__topbar--mobile-on');
      } else {
        this.renderer.removeClass(document.body, 'kt-header__topbar--mobile-on');
      }
    });
    this.layoutService.mobileMenuToggleEmitObs.subscribe(res => {
      this.sideMenu = res;
      if (this.sideMenu) {
        this.renderer.addClass(document.body, 'kt-aside--on');
      } else {
        this.renderer.removeClass(document.body, 'kt-aside--on');
      }
    });
    this.layoutService.menuModuleEmitObs.subscribe(
      res => {
        this.moduleMenu = res;
      }
    );
    this.messagingService.loadNotificationEmitObs.subscribe(
      response => {
        if (response) { this.getApproval(); }
      }
    );
  }

  ngOnInit() {
    this.apiHost = this.globalService.apiHost;
    let payload = this.userService.payloadToken();
    this.user = payload.data;
    let splitName = this.user.nama.split(' ');
    this.firstName = splitName[0];
    this.getApproval();
    this.allowNotification = (Notification.permission !== 'denied' && Notification.permission !== 'default') ? true : false;
    if (this.allowNotification) { this.notifyMe(); }

    Fingerprint2.get((components) => {
      let values = components.map(function (component) { return component.value });
      this.fingerprint = Fingerprint2.x64hash128(values.join(''), 31);
    });
  }

  
  reloadAuth() {
    let modul = 'pm';

    this.authService.reloadAuthorization(modul).subscribe(response => {
      if (response.status) {
        localStorage.setItem(modul, response.result.token);
      }
      else {
        // Swal.fire(response.message);
        Swal.fire({
          title: 'Sorry!',
          type: 'info',
          html: response.message
        });
      }
    }, error => {
      // Swal.fire(error.error.message);
      Swal.fire({
        title: 'Sorry!',
        type: 'info',
        html: error.error.message
      });
    });

    setTimeout(function () {
      Swal.fire('Module authorization has been updated');
    }, 1000);
  }


  @HostListener('document:click', ['$event'])
  clickout(event) {
    if (this.eRef.nativeElement.contains(event.target)) {
      this.headerWidth = this.eRef.nativeElement.children[0].offsetWidth;
      this.userDropdownOffset = this.headerWidth - 400;
    } else {
      this.toggleMenuHeader();
    }
  }

  getApproval() {
    this.homeService.getApproval().subscribe(response => {
      this.notifications = response.result.data;
      if (response.result.total > 99) {
        this.total_notif = '99+';
      } else {
        this.total_notif = response.result.total;
      }
    });
  }

  onResize($event) {
    this.headerWidth = this.eRef.nativeElement.children[0].offsetWidth;
    this.userDropdownOffset = this.headerWidth - 400;
  }

  logout() {
    this.ngxLoader.start();

    this.userService.logout(this.fingerprint).subscribe(response => {
      this.ngxLoader.stop();
      if (!response.status) {
        console.log(response.message);
      }

      this.authService.logout();
    }, error => {
      this.ngxLoader.stop();
      console.log(error);
      this.authService.logout();
    });
  }

  toggleMenuHeader(i = null, u = false, m = false) {
    this.menuHeader = this.menuHeader.map((el, index) => {
      if (index == i) {
        el.toggle = !el.toggle;
      }
      else {
        el.toggle = false;
      }
      return el;
    });
    this.userDropDownShowed = u;
    this.moduleDropDownShowed = m;
    this.notifDropDownShowed = m;
  }

  toggleMenuHeaderMobile() {
    this.headerMenu = !this.headerMenu;
    if (this.headerMenu) {
      this.renderer.addClass(document.body, 'kt-header-menu-wrapper--on');
    } else {
      this.renderer.removeClass(document.body, 'kt-header-menu-wrapper--on');
    }
    this.layoutService.mobileHeaderMenuToggleEmit.next(this.headerMenu);
  }

  toggleSideLeft() {
    this.sideMenu = !this.sideMenu;
    if (this.sideMenu) {
      this.renderer.addClass(document.body, 'kt-aside--on');
    } else {
      this.renderer.removeClass(document.body, 'kt-aside--on');
    }
    this.layoutService.mobileMenuToggleEmit.next(this.sideMenu);
  }

  toggleHeaderAndSide() {
    this.toggleMenuHeaderMobile();
    this.toggleSideLeft();
  }

  toggleMenuModule() {
    this.moduleMenu = !this.moduleMenu;
    this.layoutService.menuModuleEmit.next(this.moduleMenu);
  }

  clickNotif($event) {
    console.log($event);
    this.homeService.readNotif({ id: $event.id_notification }).subscribe(response => {
      if (response.status) {
        this.getApproval();
        console.log('read success');
      } else {
        console.log('read failed');
      }
    });
    if ($event.url.includes('/main/')) {
      this.router.navigate([$event.url]);
    } else {
      if ($event.controller === 'advanceclearance') {
        this.router.navigate(['/main/advanceclearance/view/' + $event.id_modul]);
      } else if ($event.controller === 'postpaidmaster') {
        if ($event.hash === null) {
          this.router.navigate(['/main/simcard/prepaid/my-approval']);
        } else {
          this.router.navigate(['/main/simcard/prepaid/my-approval/detail/' + $event.hash]);
        }
      } else if ($event.controller === 'asset') {
        this.router.navigate(['//main/asset/view/' + $event.id_modul]);
      } else if ($event.controller === 'grab') {
        this.router.navigate(['/main/grab/detail/' + $event.id_modul]);
      } else if ($event.controller === 'pr') {
        this.router.navigate(['/main/pr/tracker/view/' + $event.id_modul]);
      } else if ($event.controller === 'reimbursement') {
        this.router.navigate(['/main/reimbursement/tracker/invoice/' + $event.id_modul]);
      }
    }
  }

  open(content) {
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title', size: 'lg' });
  }

  notifyMe() {
    // Let's check if the browser supports notifications
    if (!('Notification' in window)) {
      alert('This browser does not support desktop notification\nPlease use other browser\nThank you');

      // Let's check whether notification permissions have already been granted
    } else if (Notification.permission === 'granted') {
      // If it's okay let's create a notification
      this.storeToken();

      // Otherwise, we need to ask the user for permission
    } else if (Notification.permission !== 'denied') {
      Notification.requestPermission().then(function (permission) {
        // If the user accepts, let's create a notification
        if (permission === 'granted') {
          this.storeToken();
        }
      });
    }

    // At last, if the user has denied notifications,
    // we can do nothing.
  }

  storeToken() {
    const userId = this.userService.getUsername();
    this.messagingService.requestPermission(userId);
    setTimeout(() => {
      // console.log('hi', this.messagingService.currentToken);
      const old_token = (this.userService.getFcmToken()) ? (
        this.userService.fcmTokenExist(this.messagingService.currentToken) ? null : this.userService.getFcmToken()
      ) : this.userService.setFcmToken(this.messagingService.currentToken);

      if (old_token && !this.userService.fcmTokenExist(this.messagingService.currentToken)) {
        this.userService.setFcmToken(this.messagingService.currentToken);
      }

      this.authService.reloadIdb({
        username: userId, fcm_token: this.messagingService.currentToken, old_token: old_token
      }).subscribe(arg => {
        (arg.status) ? console.log('token stored ok!') : Swal.fire(arg.message);
      }, error => {
        console.log(error);
        //Swal.fire(error.error.message);
      });
    }, 7000);
  }
}
