<div class="kt-portlet ">
  <div class="kt-portlet__body">

    <!--begin::Accordion-->
    <div *ngIf="!formForgot else forgotPassword" class="accordion accordion-solid" id="user-accordion">
      <div id="collapse-personal" class="collapse show text-center">
        <div class="card">
          <div class="card-header" id="heading-personal">
            <div class="card-title bg-primary text-white" data-toggle="collapse"
              data-target="#collapse-personal-disable" aria-expanded="true" aria-controls="collapse-personal-disable">
              <i class="fa fa-key text-white"></i>
              Change Password
            </div>
          </div>
          <div class="card-body">
            <div class="col-md-8">
              <form class="form-horizontal" role="form">
                <div class="form-body">

                  <div class="form-group row mt-4">
                    <label for="old-password" class="col-4 text-right col-form-label">Old Password</label>
                    <div class="col-8 input-group">
                      <input class="form-control" [(ngModel)]="oldPass" [type]="type1" id="old-password"
                        [ngModelOptions]="{standalone: true}">
                      <div class="input-group-append" (click)="showText('old-password')"><span
                          class="input-group-text"><i [class]="eye1"></i></span></div>

                    </div>
                  </div>

                  <div class="form-group row">
                    <label for="new-password" class="col-4 text-right col-form-label">New Password</label>
                    <div class="col-8 input-group">
                      <input class="form-control" [(ngModel)]="newPass" [type]="type2" id="new-password"
                        [ngModelOptions]="{standalone: true}">
                      <div class="input-group-append" (click)="showText('new-password')"><span
                          class="input-group-text"><i [class]="eye2"></i></span></div>
                    </div>
                  </div>

                  <div class="form-group row">
                    <label for="new1-password" class="col-4 text-right col-form-label">Retype New Password</label>
                    <div class="col-8 input-group">
                      <input class="form-control" [(ngModel)]="newPass1" [type]="type3" id="new1-password"
                        [ngModelOptions]="{standalone: true}">
                      <div class="input-group-append" (click)="showText('new1-password')"><span
                          class="input-group-text"><i [class]="eye3"></i></span></div>
                    </div>
                  </div>

                  <div *ngIf="permissionLoaded" class="form-group row pull-right">
                    <div class="col-12">
                      <button (click)="savePassword()" class="btn btn-success">Submit</button>
                    </div>
                  </div>
                  
                  <div class="form-group row pull-right pr-2">
                    <div class="col-12">
                      <a href="javascript:;" class="btn btn-secondary" (click)="formForgot = true">Lupa Password?</a>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      <!--end::Accordion-->
    </div>
    <ng-template #forgotPassword>
      <div class=" text-center text-dark border rounded shadow">
        <div class="container pb-3 pt-5">
          <div class="row justify-content-center align-items-center">
            <div class="col-md-10" data-aos="zoom-in">
              <h2 class="stack_font2 h3 pb-4">
                Reset your Password
              </h2>
              <div class="contact-form text-left">
                <form id="forgotForm" #forgotForm="ngForm" (ngSubmit)="submitForgot(forgotForm)" class="form">
                  <input type="hidden" name="task" value="add" />
                  <input type="hidden" name="slug" value="erp" />
    
                  <!-- Row Start -->
                  <div class="row">
                    <div class="form-group col-12">
                      <label for="username" class="">
                        USERNAME / EMAIL
                      </label>
                      <div class="">
                        <input name="username" [(ngModel)]="forgot_form.username" placeholder="" type="text"
                          class="form-control" id="inputUsername" />
                      </div>
                    </div>
                    <div class="form-group col-12">
                      <p>Please input your Username or Email Address above. We will send you an email containing a temporary
                        password for you to login to the site</p>
                    </div>
                    <div class="clear"></div>
                  </div>
    
                  <!-- Row End -->
                  <div class="row">
                    <div class="col-12 py-3">
                      <button class="btn" *ngIf="isSubmited">
                        <img src="/assets/media/loader/timeline.svg" [style.height.px]="37">
                      </button>
                      <button *ngIf="!isSubmited" class="btn btn-info" type="submit" aria-pressed="true"
                        [disabled]="isSubmited">
                        Reset
                      </button>
                      &nbsp;
                      <button class="btn btn-light" type="button" aria-pressed="true" (click)="formForgot = false">
                        Cancel
                      </button>
                    </div>
                  </div>
                  <div class="row pt-3">
                    <div class="col-12">
                      <div class="notify" id="notify"></div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ng-template>
  </div>
</div>
