import { Routes, ExtraOptions } from '@angular/router';
import { NotFoundComponent } from './layout/not-found/not-found.component';
import { ForbiddenComponent } from './layout/forbidden/forbidden.component';
import { SsoComponent } from './layout/sso/sso.component';
import { ChangePasswordComponent } from './layout/change-password/change-password.component';
import { NgxPermissionsGuard } from 'ngx-permissions';
var ɵ0 = function () { return import("./layout/landing/landing.module.ngfactory").then(function (m) { return m.LandingModuleNgFactory; }); }, ɵ1 = function () { return import("./layout/main/main.module.ngfactory").then(function (m) { return m.MainModuleNgFactory; }); }, ɵ2 = function () { return import("./layout/customer-management/customer-management.module.ngfactory").then(function (m) { return m.CustomerManagementModuleNgFactory; }); }, ɵ3 = {
    permissions: {
        only: ['USER'],
        redirectTo: '/'
    }
};
var routes = [
    {
        path: '',
        loadChildren: ɵ0,
    },
    {
        path: 'main',
        loadChildren: ɵ1,
    },
    {
        path: 'customer',
        loadChildren: ɵ2,
    },
    {
        path: 'sso',
        component: SsoComponent,
    },
    {
        path: 'change-password',
        component: ChangePasswordComponent,
        canActivate: [NgxPermissionsGuard],
        data: ɵ3
    },
    {
        path: '404', component: NotFoundComponent
    },
    {
        path: '403', component: ForbiddenComponent
    },
    {
        path: '**',
        redirectTo: '/404',
        pathMatch: 'full'
    },
];
var routerOptions = {
    useHash: false,
    anchorScrolling: 'enabled',
};
var AppRoutingModule = /** @class */ (function () {
    function AppRoutingModule() {
    }
    return AppRoutingModule;
}());
export { AppRoutingModule };
export { ɵ0, ɵ1, ɵ2, ɵ3 };
