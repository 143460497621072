import { ApplicationRef, Component } from '@angular/core';
import { NgxPermissionsService } from 'ngx-permissions';
import { AuthenticationService } from './auth/authentication.service';
import { Router, NavigationEnd } from '@angular/router';
import { SwUpdate } from '@angular/service-worker';
import { concat, interval } from 'rxjs';
import { first } from 'rxjs/operators';
import { environment } from '../environments/environment';
import Swal from 'sweetalert2';

declare let gtag: Function;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  isLoading = true;
  title = 'ptnw-v3';
  updateAvailable: boolean = false;
  buildVersion: string = '-';

  constructor(
    private permissionsService: NgxPermissionsService,
    public authService: AuthenticationService,
    private router: Router,
    appRef: ApplicationRef,
    updates: SwUpdate,
    private authenticationService: AuthenticationService,
  ) {
    if (environment.production) {
      const appIsStable$ = appRef.isStable.pipe(first(isStable => isStable === true));
      const everyTenMinutes$ = interval(10 * 60 * 1000);
      const everyTenMinutesOnceAppIsStable$ = concat(appIsStable$, everyTenMinutes$);

      everyTenMinutesOnceAppIsStable$.subscribe(() => updates.checkForUpdate());

      updates.available.subscribe(event => {
        updates.activateUpdate().then(() => {
          this.updateAvailable = true;
          Swal.fire({
            width: '300px',
            position: 'bottom-end',
            html: 'Ada update di sistem, silahkan klik <b>Oke</b> memuat ulang web ini. <br> <span style="color: red; font-size: smaller">*Pastikan anda tidak sedang melakukan data entry, karena form akan tereset menjadi kosong.</span>',
            showCancelButton: true,
            confirmButtonText: 'Oke',
            cancelButtonText: 'Nanti',
            backdrop: false,
            timer: 5000,
            customClass: {
              confirmButton: 'btn btn-sm btn-primary',
              cancelButton: 'btn btn-sm btn-secondary'
            },
            buttonsStyling: false,
            showCloseButton: true
          }).then((result) => {
            if (result.value) {
              this.reloadPage();
            } else {
              //   Swal.fire({
              //     title: 'Memuat Ulang Web',
              //     type: 'info',
              //     html: 'Anda dapat memuat ulang web ini nanti dengan cara klik tombol <i>icon refresh</i> yang ada di pojok kanan bawah layar Anda.',
              //     backdrop: `
              //   rgba(182, 195, 194, 0.4)
              //   url("/assets/media/nds/arrow.png")
              //   right bottom
              //   no-repeat
              // `
              //   });
            }
          });

          this.router.events.subscribe((val) => {
            if (val instanceof NavigationEnd) {
              const arr_url = this.router.url.split('/');
              if (arr_url[2] === 'home' || arr_url[1] === 'home') {
                this.reloadPage();
              }
            }
          });
        });
      });
      updates.activated.subscribe(event => {
        console.log('[app] new version is', event.current);
        this.buildVersion = event.current.hash;
      });
    }

    this.permissionsService.hasPermission('USER').then(loggedIn => {
      if (loggedIn) {
        this.authenticationService.checkPasswordExp().subscribe(
          response => {
            if (response.status) {
              if (response.result === 1) {
                const arr_url = this.router.url.split('/');
                if (arr_url[1] !== 'reset-password') {
                  this.router.navigateByUrl('/change-password');
                }
              }
            }
          }
        );
      }
    });
  }

  ngOnInit(): void {

    if (location.hostname != 'localhost') {
      environment.host = (/ptnw\.net/.test(document.URL)) ? 'https://api.ptnw.net' : 'https://api.nexwaveindonesia.com';
    }

    this.permissionsService.flushPermissions();
    this.authService.isAuthorized().subscribe(
      isAuthorized => {
        const roles = isAuthorized ? ['USER'] : ['GUEST'];
        this.permissionsService.loadPermissions(roles);
      }
    );

    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        gtag('config', 'UA-84485819-3',
          {
            'page_path': event.urlAfterRedirects
          }
        );
      }
    });

    this.isLoading = false;
  }

  logout() {
    this.authService.logout();
  }

  reloadPage() {
    document.location.reload();
  }

  test() {
    Swal.fire({
      width: '300px',
      position: 'bottom-end',
      html: 'Ada update di sistem, silahkan klik <b>Oke</b> memuat ulang web ini. <br> <span style="color: red; font-size: smaller">*Pastikan anda tidak sedang melakukan data entry, karena form akan tereset menjadi kosong.</span>',
      showCancelButton: true,
      confirmButtonText: 'Oke',
      cancelButtonText: 'Nanti',
      backdrop: false,
      // timer: 2000,
      customClass: {
        confirmButton: 'btn btn-sm btn-primary',
        cancelButton: 'btn btn-sm btn-secondary'
      },
      buttonsStyling: false,
      showCloseButton: true
    }).then((result) => {
      if (result.value) {
        this.reloadPage();
      } else {
        Swal.fire({
          position: 'top-end',
          html: 'Anda dapat memuat ulang web ini nanti dengan cara klik tombol <i>icon refresh</i> yang ada di pojok kanan bawah layar Anda.',
          backdrop: `
              rgba(182, 195, 194, 0.4)
              url("/assets/media/nds/arrow.png")
              right bottom
              no-repeat
            `
        });
      }
    });
  }
}
