<div class="kt-grid kt-grid--ver kt-grid--root full-height">
  <div class="kt-grid__item kt-grid__item--fluid kt-grid  kt-error-v3"
    style="background-image: url(./assets/media/error/bg4.jpg);">
    <div class="kt-error_container" style="height: 1000px;">
      <span class="kt-error_number">
        <h1>PTNW</h1>
      </span>
      <p class="kt-error_title kt-font-light">
        Approval with one link...
      </p>
      <p class="kt-error_subtitle">
        Approval with one link...
      </p>
    </div>
  </div>
</div>

<ng-template #modalLogin let-modal>
  <div class="modal-header">
    <h4 class="modal-title">Login Form</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="form-group row">
      <label class="col-lg-3 col-form-label">
        Username
      </label>
      <div class="col-lg-9">
        <div class="input-group">
          <input [(ngModel)]="username" name="username" type="text" class="form-control">
        </div>
      </div>
    </div>
    <div class="form-group row">
      <label class="col-lg-3 col-form-label">
        Password
      </label>
      <div class="col-lg-9">
        <div class="input-group">
          <input [(ngModel)]="password" name="password" type="password" class="form-control">
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-light" (click)="modal.close('Close click')">Close</button>
    <button type="button" class="btn btn-success" (click)="submitByLogin()">Save</button>
  </div>
</ng-template>