<!-- begin:: Aside -->
<button (click)="toggleSideLeft()" class="kt-aside-close " id="kt_aside_close_btn"><i class="la la-close"></i></button>
<div (mouseenter)="mouseEnter()" (mouseleave)="mouseLeave()"
  class="kt-aside kt-aside--fixed kt-grid__item kt-grid kt-grid--desktop kt-grid--hor-desktop"
  [ngClass]="{'kt-aside--on': sideMenu}" id="kt_aside">
  <!-- begin:: Aside -->
  <div class="kt-aside__brand kt-grid__item " id="kt_aside_brand">
    <div class="kt-aside__brand-logo">
      <a [routerLink]="['/main/modules']" routerLinkActive="router-link-active">
        <img alt="Logo" src="./assets/media/logos/logonds3.png" style="height:75px;" />
      </a>
    </div>
    <div class="kt-aside__brand-tools">
      <button (click)="sidebarToggle()" class="kt-aside__brand-aside-toggler" id="kt_aside_toggler">
        <span><svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px"
            height="24px" viewbox="0 0 24 24" version="1.1" class="kt-svg-icon">
            <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
              <polygon id="Shape" points="0 0 24 0 24 24 0 24" />
              <path
                d="M5.29288961,6.70710318 C4.90236532,6.31657888 4.90236532,5.68341391 5.29288961,5.29288961 C5.68341391,4.90236532 6.31657888,4.90236532 6.70710318,5.29288961 L12.7071032,11.2928896 C13.0856821,11.6714686 13.0989277,12.281055 12.7371505,12.675721 L7.23715054,18.675721 C6.86395813,19.08284 6.23139076,19.1103429 5.82427177,18.7371505 C5.41715278,18.3639581 5.38964985,17.7313908 5.76284226,17.3242718 L10.6158586,12.0300721 L5.29288961,6.70710318 Z"
                id="Path-94" fill="#000000" fill-rule="nonzero"
                transform="translate(8.999997, 11.999999) scale(-1, 1) translate(-8.999997, -11.999999) " />
              <path
                d="M10.7071009,15.7071068 C10.3165766,16.0976311 9.68341162,16.0976311 9.29288733,15.7071068 C8.90236304,15.3165825 8.90236304,14.6834175 9.29288733,14.2928932 L15.2928873,8.29289322 C15.6714663,7.91431428 16.2810527,7.90106866 16.6757187,8.26284586 L22.6757187,13.7628459 C23.0828377,14.1360383 23.1103407,14.7686056 22.7371482,15.1757246 C22.3639558,15.5828436 21.7313885,15.6103465 21.3242695,15.2371541 L16.0300699,10.3841378 L10.7071009,15.7071068 Z"
                id="Path-94" fill="#000000" fill-rule="nonzero" opacity="0.3"
                transform="translate(15.999997, 11.999999) scale(-1, 1) rotate(-270.000000) translate(-15.999997, -11.999999) " />
            </g>
          </svg></span>
        <span><svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px"
            height="24px" viewbox="0 0 24 24" version="1.1" class="kt-svg-icon">
            <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
              <polygon id="Shape" points="0 0 24 0 24 24 0 24" />
              <path
                d="M12.2928955,6.70710318 C11.9023712,6.31657888 11.9023712,5.68341391 12.2928955,5.29288961 C12.6834198,4.90236532 13.3165848,4.90236532 13.7071091,5.29288961 L19.7071091,11.2928896 C20.085688,11.6714686 20.0989336,12.281055 19.7371564,12.675721 L14.2371564,18.675721 C13.863964,19.08284 13.2313966,19.1103429 12.8242777,18.7371505 C12.4171587,18.3639581 12.3896557,17.7313908 12.7628481,17.3242718 L17.6158645,12.0300721 L12.2928955,6.70710318 Z"
                id="Path-94" fill="#000000" fill-rule="nonzero" />
              <path
                d="M3.70710678,15.7071068 C3.31658249,16.0976311 2.68341751,16.0976311 2.29289322,15.7071068 C1.90236893,15.3165825 1.90236893,14.6834175 2.29289322,14.2928932 L8.29289322,8.29289322 C8.67147216,7.91431428 9.28105859,7.90106866 9.67572463,8.26284586 L15.6757246,13.7628459 C16.0828436,14.1360383 16.1103465,14.7686056 15.7371541,15.1757246 C15.3639617,15.5828436 14.7313944,15.6103465 14.3242754,15.2371541 L9.03007575,10.3841378 L3.70710678,15.7071068 Z"
                id="Path-94" fill="#000000" fill-rule="nonzero" opacity="0.3"
                transform="translate(9.000003, 11.999999) rotate(-270.000000) translate(-9.000003, -11.999999) " />
            </g>
          </svg></span>
      </button>
      <!--
				<button class="kt-aside__brand-aside-toggler kt-aside__brand-aside-toggler--left" id="kt_aside_toggler"><span></span></button>
				 -->
    </div>
  </div>
  <!-- end:: Aside -->
  <!-- begin:: Aside Menu -->
  <div class="kt-aside-menu-wrapper kt-grid__item kt-grid__item--fluid" id="kt_aside_menu_wrapper"
    style="overflow: auto; height: 90%">
    <div id="kt_aside_menu" class="kt-aside-menu kt-scroll ps ps--active-y" data-ktmenu-vertical="1"
      data-ktmenu-scroll="1" data-ktmenu-dropdown-timeout="500">
      <ul class="kt-menu__nav ">
        <ng-container *ngIf="menuSection !== 'Application'">
          <ng-container *ngIf="currentUser?.user_ptnw_status == 'Internal'">
            <li id="menu-approval-all" class="kt-menu__item" aria-haspopup="true"
              [ngClass]="{'kt-menu__item--active': pageActive == 'modules-approval'}">
              <a href="https://ptnw.net/main/modules-approval" class="kt-menu__link " (click)="toggleSideLeft()"
              >
                <span class="kt-menu__link-icon">
                  <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px"
                    height="24px" viewBox="0 0 24 24" version="1.1" class="kt-svg-icon">
                    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                      <rect id="bound" x="0" y="0" width="24" height="24" />
                      <path
                        d="M8,3 L8,3.5 C8,4.32842712 8.67157288,5 9.5,5 L14.5,5 C15.3284271,5 16,4.32842712 16,3.5 L16,3 L18,3 C19.1045695,3 20,3.8954305 20,5 L20,21 C20,22.1045695 19.1045695,23 18,23 L6,23 C4.8954305,23 4,22.1045695 4,21 L4,5 C4,3.8954305 4.8954305,3 6,3 L8,3 Z"
                        id="Combined-Shape" fill="#000000" opacity="0.3" />
                      <path
                        d="M10.875,15.75 C10.6354167,15.75 10.3958333,15.6541667 10.2041667,15.4625 L8.2875,13.5458333 C7.90416667,13.1625 7.90416667,12.5875 8.2875,12.2041667 C8.67083333,11.8208333 9.29375,11.8208333 9.62916667,12.2041667 L10.875,13.45 L14.0375,10.2875 C14.4208333,9.90416667 14.9958333,9.90416667 15.3791667,10.2875 C15.7625,10.6708333 15.7625,11.2458333 15.3791667,11.6291667 L11.5458333,15.4625 C11.3541667,15.6541667 11.1145833,15.75 10.875,15.75 Z"
                        id="check-path" fill="#000000" />
                      <path
                        d="M11,2 C11,1.44771525 11.4477153,1 12,1 C12.5522847,1 13,1.44771525 13,2 L14.5,2 C14.7761424,2 15,2.22385763 15,2.5 L15,3.5 C15,3.77614237 14.7761424,4 14.5,4 L9.5,4 C9.22385763,4 9,3.77614237 9,3.5 L9,2.5 C9,2.22385763 9.22385763,2 9.5,2 L11,2 Z"
                        id="Combined-Shape" fill="#000000" />
                    </g>
                  </svg>
                </span>
                <span class="kt-menu__link-text">My Approval (All)</span>
              </a>
            </li>
          </ng-container>
          <li class="kt-menu__item" aria-haspopup="true" [ngClass]="{'kt-menu__item--active': pageActive == 'modules'}">
            <a href="https://ptnw.net/main/modules" class="kt-menu__link " (click)="toggleSideLeft()"
            >
              <span class="kt-menu__link-icon">
                <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px"
                  height="24px" viewBox="0 0 24 24" version="1.1" class="kt-svg-icon">
                  <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                    <rect id="bound" x="0" y="0" width="24" height="24" />
                    <rect id="Rectangle-20" fill="#000000" x="4" y="5" width="16" height="3" rx="1.5" />
                    <path
                      d="M5.5,15 L18.5,15 C19.3284271,15 20,15.6715729 20,16.5 C20,17.3284271 19.3284271,18 18.5,18 L5.5,18 C4.67157288,18 4,17.3284271 4,16.5 C4,15.6715729 4.67157288,15 5.5,15 Z M5.5,10 L18.5,10 C19.3284271,10 20,10.6715729 20,11.5 C20,12.3284271 19.3284271,13 18.5,13 L5.5,13 C4.67157288,13 4,12.3284271 4,11.5 C4,10.6715729 4.67157288,10 5.5,10 Z"
                      id="Combined-Shape" fill="#000000" opacity="0.3" />
                  </g>
                </svg>
              </span>
              <span class="kt-menu__link-text">All Menu</span>
            </a>
          </li>
          <ng-container *ngIf="currentUser?.user_ptnw_status == 'Internal'">
            <li class="kt-menu__item" aria-haspopup="true"
              [ngClass]="{'kt-menu__item--active': pageActive == 'search'}">
              <a href="https://ptnw.net/main/search" class="kt-menu__link " (click)="toggleSideLeft()"
                >
                <span class="kt-menu__link-icon">
                  <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px"
                    height="24px" viewBox="0 0 24 24" version="1.1" class="kt-svg-icon">
                    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                      <rect id="bound" x="0" y="0" width="24" height="24" />
                      <path
                        d="M14.2928932,16.7071068 C13.9023689,16.3165825 13.9023689,15.6834175 14.2928932,15.2928932 C14.6834175,14.9023689 15.3165825,14.9023689 15.7071068,15.2928932 L19.7071068,19.2928932 C20.0976311,19.6834175 20.0976311,20.3165825 19.7071068,20.7071068 C19.3165825,21.0976311 18.6834175,21.0976311 18.2928932,20.7071068 L14.2928932,16.7071068 Z"
                        id="Path-2" fill="#000000" fill-rule="nonzero" opacity="0.3" />
                      <path
                        d="M11,16 C13.7614237,16 16,13.7614237 16,11 C16,8.23857625 13.7614237,6 11,6 C8.23857625,6 6,8.23857625 6,11 C6,13.7614237 8.23857625,16 11,16 Z M11,18 C7.13400675,18 4,14.8659932 4,11 C4,7.13400675 7.13400675,4 11,4 C14.8659932,4 18,7.13400675 18,11 C18,14.8659932 14.8659932,18 11,18 Z"
                        id="Path" fill="#000000" fill-rule="nonzero" />
                    </g>
                  </svg>
                </span>
                <span class="kt-menu__link-text">Search</span>
              </a>
            </li>
            <li class="kt-menu__item" aria-haspopup="true" [ngClass]="{'kt-menu__item--active': pageActive == 'home'}">
              <a href="https://ptnw.net/home" class="kt-menu__link " (click)="toggleSideLeft()"
               >
                <span class="kt-menu__link-icon">
                  <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px"
                    height="24px" viewBox="0 0 24 24" version="1.1" class="kt-svg-icon">
                    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                      <rect id="bound" x="0" y="0" width="24" height="24" />
                      <path
                        d="M3.95709826,8.41510662 L11.47855,3.81866389 C11.7986624,3.62303967 12.2013376,3.62303967 12.52145,3.81866389 L20.0429,8.41510557 C20.6374094,8.77841684 21,9.42493654 21,10.1216692 L21,19.0000642 C21,20.1046337 20.1045695,21.0000642 19,21.0000642 L4.99998155,21.0000673 C3.89541205,21.0000673 2.99998155,20.1046368 2.99998155,19.0000673 L2.99999828,10.1216672 C2.99999935,9.42493561 3.36258984,8.77841732 3.95709826,8.41510662 Z M10,13 C9.44771525,13 9,13.4477153 9,14 L9,17 C9,17.5522847 9.44771525,18 10,18 L14,18 C14.5522847,18 15,17.5522847 15,17 L15,14 C15,13.4477153 14.5522847,13 14,13 L10,13 Z"
                        id="Combined-Shape" fill="#000000" />
                    </g>
                  </svg>
                </span>
                <span class="kt-menu__link-text">Home</span>
              </a>
            </li>
            <li class="kt-menu__item" aria-haspopup="true"
              [ngClass]="{'kt-menu__item--active': pageActive == 'org-chart'}">
              <a href="https://ptnw.net/main/org-chart" class="kt-menu__link " (click)="toggleSideLeft()"
                >
                <span class="kt-menu__link-icon">
                  <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px"
                    height="24px" viewBox="0 0 24 24" version="1.1" class="kt-svg-icon">
                    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                      <polygon id="Shape" points="0 0 24 0 24 24 0 24" />
                      <path
                        d="M18,14 C16.3431458,14 15,12.6568542 15,11 C15,9.34314575 16.3431458,8 18,8 C19.6568542,8 21,9.34314575 21,11 C21,12.6568542 19.6568542,14 18,14 Z M9,11 C6.790861,11 5,9.209139 5,7 C5,4.790861 6.790861,3 9,3 C11.209139,3 13,4.790861 13,7 C13,9.209139 11.209139,11 9,11 Z"
                        id="Combined-Shape" fill="#000000" fill-rule="nonzero" opacity="0.3" />
                      <path
                        d="M17.6011961,15.0006174 C21.0077043,15.0378534 23.7891749,16.7601418 23.9984937,20.4 C24.0069246,20.5466056 23.9984937,21 23.4559499,21 L19.6,21 C19.6,18.7490654 18.8562935,16.6718327 17.6011961,15.0006174 Z M0.00065168429,20.1992055 C0.388258525,15.4265159 4.26191235,13 8.98334134,13 C13.7712164,13 17.7048837,15.2931929 17.9979143,20.2 C18.0095879,20.3954741 17.9979143,21 17.2466999,21 C13.541124,21 8.03472472,21 0.727502227,21 C0.476712155,21 -0.0204617505,20.45918 0.00065168429,20.1992055 Z"
                        id="Combined-Shape" fill="#000000" fill-rule="nonzero" />
                    </g>
                  </svg>
                </span>
                <span class="kt-menu__link-text">Org Chart</span>
              </a>
            </li>
            <li class="kt-menu__item kt-menu__item--submenu" [ngClass]="{'kt-menu__item--open': dashboardOpen}"
              aria-haspopup="true" data-ktmenu-submenu-toggle="hover">
              <a (click)="dashboardOpen = !dashboardOpen" href="javascript:;" class="kt-menu__link kt-menu__toggle">
                <span class="kt-menu__link-icon">
                  <i class="flaticon-layer"></i>
                </span>
                <span class="kt-menu__link-text">
                  Dashboard
                </span>
                <i class="kt-menu__ver-arrow la la-angle-right"></i>
              </a>
              <div class="kt-menu__submenu ">
                <span class="kt-menu__arrow"></span>
                <ul class="kt-menu__subnav">
                  <li class="kt-menu__item kt-menu__item--parent" aria-haspopup="true">
                    <span class="kt-menu__link">
                      <span class="kt-menu__link-text">
                        Dashboard
                      </span>
                    </span>
                  </li>
                  <li class="kt-menu__item " aria-haspopup="true">
                    <a href="https://ptnw.net/main/employee/dashboard-employee" class="kt-menu__link ">
                      <span class="kt-menu__link-icon">
                        <i class="flaticon2-user"></i>
                      </span>
                      <span class="kt-menu__link-text">
                        Employee
                      </span>
                    </a>
                  </li>
                  <li class="kt-menu__item " aria-haspopup="true">
                    <a href="https://ptnw.net/main/summary/postpaid" class="kt-menu__link ">
                      <span class="kt-menu__link-icon">
                        <i class="flaticon2-phone"></i>
                      </span>
                      <span class="kt-menu__link-text">
                        Postpaid
                      </span>
                    </a>
                  </li>
                  <li class="kt-menu__item " aria-haspopup="true">
                    <a  href="https://ptnw.net/main/summary/prepaid" class="kt-menu__link ">
                      <span class="kt-menu__link-icon">
                        <i class="flaticon-price-tag"></i>
                      </span>
                      <span class="kt-menu__link-text">
                        Prepaid
                      </span>
                    </a>
                  </li>
                  <li class="kt-menu__item " aria-haspopup="true">
                    <a href="https://ptnw.net/main/summary/reservation" class="kt-menu__link ">
                      <span class="kt-menu__link-icon">
                        <i class="flaticon2-poll-symbol"></i>
                      </span>
                      <span class="kt-menu__link-text">
                        Reservation
                      </span>
                    </a>
                  </li>
                  <li class="kt-menu__item " aria-haspopup="true">
                    <a href="https://ptnw.net/main/summary/attendance"  class="kt-menu__link ">
                      <span class="kt-menu__link-icon">
                        <i class="flaticon2-chronometer"></i>
                      </span>
                      <span class="kt-menu__link-text">
                        Attendance
                      </span>
                    </a>
                  </li>
                </ul>
              </div>
            </li>
          
            <li class="kt-menu__item" aria-haspopup="true"
              [ngClass]="{'kt-menu__item--active': pageActive == 'timesheet'}">
              <a href="https://ptnw.net/main/timesheet/index" class="kt-menu__link " (click)="toggleSideLeft()"
                >
                <span class="kt-menu__link-icon">
                  <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px"
                    height="24px" viewBox="0 0 24 24" version="1.1" class="kt-svg-icon">
                    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                      <rect id="bound" x="0" y="0" width="24" height="24" />
                      <path
                        d="M12,22 C7.02943725,22 3,17.9705627 3,13 C3,8.02943725 7.02943725,4 12,4 C16.9705627,4 21,8.02943725 21,13 C21,17.9705627 16.9705627,22 12,22 Z"
                        id="Mask" fill="#000000" opacity="0.3" />
                      <path
                        d="M11.9630156,7.5 L12.0475062,7.5 C12.3043819,7.5 12.5194647,7.69464724 12.5450248,7.95024814 L13,12.5 L16.2480695,14.3560397 C16.403857,14.4450611 16.5,14.6107328 16.5,14.7901613 L16.5,15 C16.5,15.2109164 16.3290185,15.3818979 16.1181021,15.3818979 C16.0841582,15.3818979 16.0503659,15.3773725 16.0176181,15.3684413 L11.3986612,14.1087258 C11.1672824,14.0456225 11.0132986,13.8271186 11.0316926,13.5879956 L11.4644883,7.96165175 C11.4845267,7.70115317 11.7017474,7.5 11.9630156,7.5 Z"
                        id="Path-107" fill="#000000" />
                    </g>
                  </svg>
                </span>
                <span class="kt-menu__link-text">My Timesheet</span>
              </a>
            </li>

            <li class="kt-menu__item" aria-haspopup="true" [ngClass]="{'kt-menu__item--active': pageActive == 'faq'}">
              <a href="https://ptnw.net/faq" class="kt-menu__link " (click)="toggleSideLeft()"
               >
                <span class="kt-menu__link-icon">
                  <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px"
                    height="24px" viewBox="0 0 24 24" version="1.1" class="kt-svg-icon">
                    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                      <rect id="bound" x="0" y="0" width="24" height="24" />
                      <circle id="Oval-5" fill="#000000" opacity="0.3" cx="12" cy="12" r="10" />
                      <path
                        d="M12,16 C12.5522847,16 13,16.4477153 13,17 C13,17.5522847 12.5522847,18 12,18 C11.4477153,18 11,17.5522847 11,17 C11,16.4477153 11.4477153,16 12,16 Z M10.591,14.868 L10.591,13.209 L11.851,13.209 C13.447,13.209 14.602,11.991 14.602,10.395 C14.602,8.799 13.447,7.581 11.851,7.581 C10.234,7.581 9.121,8.799 9.121,10.395 L7.336,10.395 C7.336,7.875 9.31,5.922 11.851,5.922 C14.392,5.922 16.387,7.875 16.387,10.395 C16.387,12.915 14.392,14.868 11.851,14.868 L10.591,14.868 Z"
                        id="Combined-Shape" fill="#000000" />
                    </g>
                  </svg>
                </span>
                <span class="kt-menu__link-text">FAQ</span>
              </a>
            </li>
            <li class="kt-menu__item" aria-haspopup="true"
              [ngClass]="{'kt-menu__item--active': pageActive == 'telegram'}">
              <a href="https://ptnw.net/main/telegram" class="kt-menu__link " (click)="toggleSideLeft()"
               >
                <span class="kt-menu__link-icon">
                  <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px"
                    height="24px" viewBox="0 0 24 24" version="1.1" class="kt-svg-icon">
                    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                      <rect id="bound" x="0" y="0" width="24" height="24" />
                      <circle id="Oval-5" fill="#000000" opacity="0.3" cx="12" cy="12" r="10" />
                      <path
                        d="M12,16 C12.5522847,16 13,16.4477153 13,17 C13,17.5522847 12.5522847,18 12,18 C11.4477153,18 11,17.5522847 11,17 C11,16.4477153 11.4477153,16 12,16 Z M10.591,14.868 L10.591,13.209 L11.851,13.209 C13.447,13.209 14.602,11.991 14.602,10.395 C14.602,8.799 13.447,7.581 11.851,7.581 C10.234,7.581 9.121,8.799 9.121,10.395 L7.336,10.395 C7.336,7.875 9.31,5.922 11.851,5.922 C14.392,5.922 16.387,7.875 16.387,10.395 C16.387,12.915 14.392,14.868 11.851,14.868 L10.591,14.868 Z"
                        id="Combined-Shape" fill="#000000" />
                    </g>
                  </svg>
                </span>
                <span class="kt-menu__link-text">Telegram</span>
              </a>
            </li>
            <li class="kt-menu__item" aria-haspopup="true"
              [ngClass]="{'kt-menu__item--active': pageActive == 'reload-authorization'}">
              <a class="kt-menu__link " (click)="reloadAuth()">
                <span class="kt-menu__link-icon">
                  <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="47px" height="47px"
                    viewBox="0 0 47 47" style="enable-background:new 0 0 47 47;" xml:space="preserve">
                    <g>
                      <path d="M34.451,18.851v-7.893C34.451,4.915,29.539,0,23.497,0c-6.04,0-10.952,4.915-10.952,10.958v7.893H7.471V47h32.059V18.851
                      H34.451z M18.026,10.958c0-3.023,2.452-5.481,5.47-5.481s5.47,2.458,5.47,5.481v7.893h-10.94V10.958z M21.817,41.934l-10.171-7.216
                      l3.188-5.985l7.277,6.119l10.479-10.27l2.761,4.82L21.817,41.934z" fill="#000000" />
                    </g>
                  </svg>
                </span>
                <span class="kt-menu__link-text">Reload Authorization</span>
              </a>
            </li>

          </ng-container>
        </ng-container>
        <ng-container *ngIf="menuList.length !== 0">
          <li class="kt-menu__section ">
            <h4 class="kt-menu__section-text">{{menuSection}}</h4>
            <i class="kt-menu__section-icon flaticon-more-v2"></i>
          </li>
        </ng-container>
        <ng-container *ngFor="let menu of menuList">
          <ng-template [ngxPermissionsOnly]="menu.permissions">
            <li class="kt-menu__item" aria-haspopup="true"
              [ngClass]="{'kt-menu__item--active': pageActive == menu.keyRoute}">
              <a [routerLink]="menu.route" class="kt-menu__link" [state]="{pageActive: menu.keyRoute}"
                (click)="toggleSideLeft()">
                <i *ngIf="menu.icon" class="kt-menu__link-icon" [ngClass]="menu.icon"></i>
                <span class="kt-menu__link-text">{{menu.title}}</span>
              </a>
            </li>
          </ng-template>
        </ng-container>
        <ng-container *ngIf="moduleActive == 'pmc'">
          <ng-container *ngTemplateOutlet="childTemplate; context: { listChild: pmcItem, level: 1 }"></ng-container>
        </ng-container>
      </ul>
    </div>
  </div>
  <!-- end:: Aside Menu -->
</div>
<ng-container *ngIf="sideMenu">
  <div class="kt-aside-overlay" (click)="toggleSideLeft()"></div>
</ng-container>
<!-- end:: Aside -->
<ng-template #childTemplate let-child="listChild" let-level="level">
  <ng-container *ngFor="let item of child">
    <li class="kt-menu__item"
      [ngClass]="{'kt-menu__item--open': pathActived[level] == item.hash, 'kt-menu__item--submenu': item.child }"
      aria-haspopup="true" data-ktmenu-submenu-toggle="hover">
      <a href="javascript:;" class="kt-menu__link kt-menu__toggle">
        <i *ngIf="item.icon == null" class="kt-menu__link-icon fa fa-cubes"></i>
        <i *ngIf="item.icon != null" class="kt-menu__link-icon fa" [ngClass]="item.icon"></i>
        <span ng-click="gotoclassic(item.hash)" class="kt-menu__link-text" [style.min-width.px]="0">
          <div class="ellipsis">{{item.nama_item}}</div>
        </span>
        <i *ngIf="item.child" (click)="navigateTo(item.hash, level)" class="kt-menu__ver-arrow la la-angle-right"></i>
      </a>
      <ng-container *ngIf="item.child">
        <div class="kt-menu__submenu"><span class="kt-menu__arrow"></span>
          <ul class="kt-menu__subnav">
            <ng-container *ngTemplateOutlet="childTemplate; context: { listChild: item.childList, level: level+1 }">
            </ng-container>
          </ul>
        </div>
      </ng-container>
    </li>
  </ng-container>
</ng-template>
