<div class="col-md-12 mt-5 p-3" *ngIf="!isVerified">

  <div class="form-group">
    <label class="col-lg-12 col-form-label ">
      Silakan masukkan NIK PTNW (Nomor Induk Karyawan) anda dan klik "Verify" :
    </label>
    <div class="col-lg-4 mb-2">
      <input type="text" class="form-control" [(ngModel)]="nik">
      <div *ngIf="errNik" class="text-danger">
        <i>{{errNik}}</i>
      </div>
    </div>
    <div class="col-lg-12 mb-3">
      <button (click)="verifyMyNumber()" class="btn btn-primary">Verify</button>
    </div>
  </div>


</div>

<div class="col-md-12 mt-5 p-3" *ngIf="isVerified">
  <h4>Terima kasih, nomor HP anda telah terverifikasi.</h4>
</div>
