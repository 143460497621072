<div class="kt-content  kt-grid__item kt-grid__item--fluid" id="kt_content" style="margin-top: 60px">
  <div class="kt-portlet bg-none">
    <div class="kt-portlet__body">
      <div class="row">
        <div class="col-md-5 d-none d-md-inline"></div>
        <div class="col-12 col-md-2 mb-4">
          <qrcode [qrdata]="this.globalHost.webHost + '/simcard/' + hash" size="200" [level]="'M'"></qrcode>
        </div>
        <div class="col-md-5 d-none d-md-inline"></div>
        <div class="col-12">
          Kepada Yth<br/>
          {{vendor}}<br/>
          Berikut list topup<br/>
        </div>
      </div>
    </div>
  </div>
  
  <div class="kt-portlet bg-none">
    <div class="kt-portlet__head">
        <div class="kt-portlet__head-label">
            <h3 class="kt-portlet__head-title">
                <i class="fa fa-list pr-3"></i>TOP UP PULSA : {{vendor}} / {{date_top_up}}
            </h3>
        </div>
    </div>
    <div class="kt-portlet__body">
        <!-- Detail -->
        <div class="row">
          <div class="col-md-12 mb-0 text-left">
            <ngx-ui-loader [fgsType]="'three-bounce'" [hasProgressBar]="false" [text]="'Please Wait..'" [loaderId]="'simcard'"></ngx-ui-loader>
            <app-table-common [tableCommonConfig]="tableConfig"
              (currentPageSizeEvent)="getCurrentPageSize($event)" 
              (currentPageEvent)="getCurrentPage($event)">
            </app-table-common>
          </div>
      </div>
    </div>
  </div>
</div>