import { Injectable } from '@angular/core';
import { isNullOrUndefined } from 'util';
import Swal from 'sweetalert2';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FormService {

  public resetFormEmit: Subject<boolean> = new Subject<boolean>();
  public resetFormEmitObs = this.resetFormEmit.asObservable();

  constructor() { }

  // begin:: Form Multiple Config
  parseRowOption(obj: any, find: string, val: any, get: string) {
    const temp = obj.filter((el: any) => el[find] === val);
    return temp[0][get];
  }
  parseInputOption(obj: any, row: string, get: string) {
    const temp = this.parseRowOption(obj, 'row', row, 'inputOption');
    return temp[get];
  }
  setValidation(row: string, rowOption: any, dataSource: any) {

    if (this.parseInputOption(rowOption, row, 'required') !== false) {
      if (dataSource === null) {
        return { touch: false, valid: false, pattern: false };
      } else {

        if (dataSource[row] === '' || isNullOrUndefined(dataSource[row])) {
          return { touch: false, valid: false, pattern: false };
        } else {
          return { touch: false, valid: true, pattern: true };
        }

      }

    } else {
      return { touch: false, valid: true, pattern: true };
    }
  }
  // end:: Form Multiple Config


  // begin:: Get Array Biar ndak manual
  getArray(rows: any, row: string) {
    return rows.indexOf(row);
  }
  // end:: Get Array Biar ndak manual


  // begin:: Input Validation
  // Value Validation
  valueValidation(value: any, required: boolean) {
    // SET VALID OR INVALID BY VALUE
    if (value !== '' && !isNullOrUndefined(value)) {
      return true;
    } else {
      return !required;
    }
  }
  patternValidation(value: any, type: string, validator: string, required: boolean) {
    // Start Comment For Debugging
    // console.log(value, type, validator, required);
    // End Comment For Debugging

    // SET VALID OR INVALID BY PATTERN
    if (type === 'text') {
      if (required !== false) {
        if (validator === 'ktp') {
          const pattern = /^[0-9]{16}$/;
          return pattern.test(value);
        } else if (validator === 'email') {
          const pattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
          return pattern.test(value);
        } else if (validator === 'phone') {
          const pattern = /^(0|62)[0-9]{6,14}/;
          return pattern.test(value);
        } else if (validator === 'mobile') {
          const pattern = /^(08|628)[0-9]{7,15}/;
          return pattern.test(value);
        } else if (validator === 'mobile_with_prefix') {
          const pattern = /^(8)[0-9]{6,14}/;
          return pattern.test(value);
        } else if (validator === 'allowance') {
          const pattern = /^[0-9]{1,}/;
          return pattern.test(value);
        } else if (validator === 'justification') {
          const pattern = /^[1-9]+[0-9]{4,}/;
          return pattern.test(value);
        } else if (validator === 'comma') {
          const pattern = /^-?\d*(\.\d*)?$/;
          return pattern.test(value);
        } else if (validator === 'nama') {
          const pattern = /^[a-zA-Z .,']+[a-zA-Z.]$/;
          return pattern.test(value);
        } else if (validator === 'none') {
          return true;
        } else {
          const pattern = /^[a-zA-Z ']+$/;
          return pattern.test(value);
        }
      }
    } else if (type === 'password') {
      if (required !== false) {
        const pattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[A-Za-z\d]{8,}$/;
        return pattern.test(value);
      }
    } else if (type === 'number') {
      if (required !== false) {
        if (validator === 'postal_code') {
          const pattern = /^[0-9]{5}$/;
          return pattern.test(value);
        } else if (validator === 'age') {
          const pattern = /^[0-9]{1,}$/;
          return pattern.test(value);
        } else if (validator === 'year') {
          const pattern = /^[0-9]{4}$/;
          let tempResult = pattern.test(value);
          if (value >= 1960 && value <= 2070) {
            tempResult = true;
          } else {
            tempResult = false;
          }
          return tempResult;
        } else {
          return true;
        }
      }
      // tslint:disable-next-line: max-line-length
    } else if (type === 'select' || type === 'select_trigger' || type === 'textarea' || type === 'date' || type === 'typehead' || type === 'typehead_trigger' || type === 'file' || type === 'chips') {
      return true;
    }

    return true;
  }
  // end:: Input Validation


  // Validation
  validate(row: any, skipError?: boolean) {
    const tempRow = row;

    // Debugging
    // console.log(row, tempRow);

    // INVALID ERROR
    const errors = [];
    const errors_pattern = [];

    let tempTotal = 0;

    // tslint:disable-next-line: forin
    for (const key in row) {
      if (!tempRow[key]['valid']) {
        errors.push('Ini' + ' Tidak Boleh Kosong');
      }
      if (!tempRow[key]['pattern']) {
        errors_pattern.push('Ini' + ' Tidak Valid');
      }
      tempTotal++;
    }

    if (errors.length === 0 && errors_pattern.length === 0) {
      return true;
    }

    if (skipError) { // Penanganan Khusus (Vacancy Detail, Site Survey Template Detail)
      if (errors.length === tempTotal) {
        return true;
      }
    }

    this.showSwal('Gagal Menyimpan.', 'Lengkapi semua isian yang bertanda (*) !');

    return false;
  }

  reactiveValidation(data: any) {
    const keys = Object.keys(data);
    for (let index = 0; index < keys.length; index++) {
      if (data[keys[index]].length > 0) {
        console.log(data[keys[index]]);
        return data[keys[index]];
      }
    }
    return true;
  }


  // TODO Setting up Row Name
  setRowName(obj, row: string, name: string) {
    if (name) {
      if (this.parseInputOption(obj, row, 'input_type') === 'image' ||
        this.parseInputOption(obj, row, 'input_type') === 'document') {
        return name;
      }
    } return row;
  }
  // TODO Setting up Value
  setValue(required: boolean) {
    if (required !== false) { return false; } return true;
  }
  // TODO Setting up Value on Update
  setValueOnUpdate(required: boolean, dataSource) {
    if (required !== false && (isNullOrUndefined(dataSource) || dataSource === '')) { return false; } return true;
  }

  showSwal(title: string, text: string) {
    Swal.fire({
      title: title,
      text: text,
      type: 'warning',
      showCancelButton: false,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'OK'
    }).then((result) => {
      if (result.value) {
        return false;
      }
    });
  }

  alertModal(error: string) {
    Swal.fire({
      type: 'error',
      title: 'Terjadi Error.',
      text: 'Error : ' + error,
      footer: '<a href>Hubungi Customer Care?</a>'
    });
  }

  errorValidation(title: string, text: string) {
    Swal.fire({
      title: title,
      text: text,
      type: 'warning',
      showCancelButton: false,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'OK',
      allowOutsideClick: false
    }).then((result) => {
      if (result.value) {
        return false;
      }
    });
  }
}
