import { Component, Input, OnInit } from '@angular/core';
import { UploadService } from 'src/app/_services/upload.service';
import * as FileSaver from 'file-saver';
import { ToastService } from 'src/app/_services/toast.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-gcs-download',
  templateUrl: './gcs-download.component.html',
  styleUrls: ['./gcs-download.component.scss']
})
export class GcsDownloadComponent implements OnInit {

  @Input() path: string = null;
  @Input() idPath: number = null;
  @Input() filename: string;
  @Input() buttonLable: string = 'Download';
  @Input() buttonClass: string = 'btn btn-primary';

  downloadProgress: number;

  constructor(
    public uploadService: UploadService,
    public toastService: ToastService
  ) {
    this.uploadService.progressEmitObs.subscribe(
      res => {
        this.downloadProgress = res;
      }
    );
  }

  ngOnInit() {

  }

  download(contentDownloading) {
    let valid = false;
    if (this.path || this.idPath) {
      if (!this.filename && this.path) {
        let parsePath: string[] = this.path.split('/');
        this.filename = parsePath[parsePath.length-1];
        valid = true;
      } else if (this.filename && this.filename != '') {
        valid = true;
      }
      
      if (valid) {
        this.toastService.show('Preparing Download ' + this.filename + '...');
        this.uploadService.downloadUrlObjGcs({ path: this.path, idPath: this.idPath }).subscribe(
          response => {
            this.toastService.remove('Preparing Download ' + this.filename + '...');
            this.toastService.show(contentDownloading);
            let url = response.result;
            this.uploadService.getFileGcs(url).subscribe(
              event => {
                if (event.status == 'done') {
                  this.toastService.remove(contentDownloading);
                  FileSaver.saveAs(event.val.body, this.filename);
                }
              },
              error => {
                this.toastService.remove(contentDownloading);
                if (error.status == 404) {
                  Swal.fire({
                    title: 'Download Attachment',
                    type: 'error',
                    text: 'Failed download attachment. File not found.'
                  });
                } else {
                  Swal.fire({
                    title: 'Download Attachment',
                    type: 'error',
                    text: 'Failed download attachment. Unknown errors.'
                  });
                }
              }
            );
          }
        );
      } else {
        Swal.fire({
          title: 'Download Attachment',
          type: 'error',
          text: 'Undefined filename.'
        });
      }
    } else {
      Swal.fire({
        title: 'Download Attachment',
        type: 'error',
        text: 'Failed download attachment. Invalid data attachment.'
      });
    }
  }

}
