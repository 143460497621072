import { ANDROID, CHROME, CHROME_BOOK, CRIOS, CROS, EDGE, EDGE_IOS, FIREFOX, FX_IOS, GOOGLE_CHROME, INTERNET_EXPLORER, IOS, IPHONE, LINUX, MACOS, MICROSOFT_EDGE, MOZILLA_FIREFOX, MSIE_100, MSIE_60, MSIE_70, MSIE_80, MSIE_90, SAFARI, TRIDENT } from './application-constants';
import * as i0 from "@angular/core";
var BrowserService = /** @class */ (function () {
    function BrowserService() {
        this.userAgent = '';
        if (typeof window !== 'undefined') {
            this.userAgent = window.navigator.userAgent;
        }
    }
    /*
      Detect browser name
    */
    BrowserService.prototype.getBrowserName = function () {
        //Desktop browsers
        if (this.userAgent.indexOf(FIREFOX) !== -1) {
            return MOZILLA_FIREFOX;
        }
        else if (this.userAgent.indexOf(CHROME) !== -1 && this.userAgent.indexOf(SAFARI) !== -1 && this.userAgent.indexOf(EDGE) !== -1) {
            return MICROSOFT_EDGE;
        }
        else if (this.userAgent.indexOf(CHROME) !== -1 && this.userAgent.indexOf(SAFARI) !== -1 && this.userAgent.indexOf(EDGE) === -1) {
            return GOOGLE_CHROME;
        }
        else if (this.userAgent.indexOf(CHROME) === -1 && this.userAgent.indexOf(SAFARI) !== -1 && this.userAgent.indexOf(EDGE) === -1) {
            return SAFARI;
        }
        else if (this.userAgent.indexOf(MSIE_60) !== -1 || this.userAgent.indexOf(MSIE_70) !== -1 || this.userAgent.indexOf(MSIE_80) !== -1
            || this.userAgent.indexOf(MSIE_90) !== -1 || this.userAgent.indexOf(MSIE_100) !== -1 || this.userAgent.indexOf(TRIDENT) !== -1) {
            return INTERNET_EXPLORER;
        }
        //Mobile browsers
        else if (this.userAgent.indexOf(CRIOS) !== -1) {
            return CHROME;
        }
        else if (this.userAgent.indexOf(FX_IOS) !== -1) {
            return FIREFOX;
        }
        else if (this.userAgent.indexOf(EDGE_IOS) !== -1) {
            return EDGE;
        }
        else {
            return SAFARI;
        }
    };
    BrowserService.prototype.getBrowserVersion = function () {
    };
    /*
       Detect browser operating system
     */
    BrowserService.prototype.getBrowserOperatingSystem = function () {
        if (this.userAgent.indexOf(ANDROID) !== -1 && this.userAgent.indexOf(LINUX) !== -1) {
            return ANDROID;
        }
        else if (this.userAgent.indexOf(ANDROID) === -1 && this.userAgent.indexOf(LINUX) !== -1) {
            return LINUX;
        }
        else if (this.userAgent.indexOf(IPHONE) !== -1) {
            return IOS;
        }
        else if (this.userAgent.indexOf(MACOS) !== -1) {
            return MACOS;
        }
        else if (this.userAgent.indexOf(CROS) !== -1 || this.userAgent.indexOf(CHROME_BOOK) !== -1) {
            return "Chrome OS";
        }
    };
    BrowserService.prototype.isGoogleChrome = function () {
        return ((this.userAgent.indexOf(CHROME) !== -1 && this.userAgent.indexOf(SAFARI) !== -1 && this.userAgent.indexOf(EDGE) === -1) || (this.userAgent.indexOf(CRIOS) !== -1));
    };
    BrowserService.prototype.isMozillaFirefox = function () {
        return this.userAgent.indexOf(FIREFOX) !== -1 || this.userAgent.indexOf(FX_IOS) !== -1;
    };
    BrowserService.prototype.isMicroSoftEdge = function () {
        return this.userAgent.indexOf(EDGE_IOS) !== -1 || (this.userAgent.indexOf(CHROME) !== -1 && this.userAgent.indexOf(SAFARI) !== -1 && this.userAgent.indexOf(EDGE) !== -1);
    };
    BrowserService.prototype.isInternetExplorer = function () {
        return this.userAgent.indexOf(MSIE_60) !== -1 || this.userAgent.indexOf(MSIE_70) !== -1 || this.userAgent.indexOf(MSIE_80) !== -1
            || this.userAgent.indexOf(MSIE_90) !== -1 || this.userAgent.indexOf(MSIE_100) !== -1 || this.userAgent.indexOf(TRIDENT) !== -1;
    };
    BrowserService.prototype.isSafari = function () {
        return this.userAgent.indexOf(CHROME) === -1 && this.userAgent.indexOf(SAFARI) !== -1 && this.userAgent.indexOf(EDGE) === -1;
    };
    BrowserService.prototype.isMobile = function () {
    };
    BrowserService.prototype.isTablet = function () {
    };
    BrowserService.prototype.isDesktop = function () {
    };
    BrowserService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function BrowserService_Factory() { return new BrowserService(); }, token: BrowserService, providedIn: "root" });
    return BrowserService;
}());
export { BrowserService };
