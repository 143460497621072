import { SafeHtml } from './_pipes/safe-html';
import { NgModule } from '@angular/core';
import { TableCommonComponent } from './table-common/table-common.component';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PaginationCommonComponent } from './pagination-common/pagination-common.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormatProjectCodePipe } from 'src/app/layout/shared/_pipes/format-project-code.pipe';
import { RouterModule } from '@angular/router';
import { DropzoneModule } from 'ngx-dropzone-wrapper';
import { AlertCommonComponent } from './alert-common/alert-common.component';
// tslint:disable-next-line: max-line-length
import {
  AlertModalComponent,
  ConfirmModalComponent
} from './alert-modal/alert-modal.component';
import { FormatValuePipe } from './_pipes/format-value.pipe';
import {
  PerfectScrollbarConfigInterface,
  PerfectScrollbarModule,
  PERFECT_SCROLLBAR_CONFIG
} from 'ngx-perfect-scrollbar';
import { DynamicSelectComponent } from './dynamic-select/dynamic-select.component';
import { TableEditableComponent } from './table-editable/table-editable.component';
import { DescriptionCommonComponent } from './description-common/description-common.component';
import { FormCommonComponent } from './form-common/form-common.component';
import { DynamicDropzoneComponent } from './dynamic-dropzone/dynamic-dropzone.component';
import { ExportButtonComponent } from './export-button/export-button.component';
import { FormMultipleComponent } from './form-multiple/form-multiple.component';
import { NgxUiLoaderModule } from 'ngx-ui-loader';
import { ClickOutsideModule } from 'ng4-click-outside';
import { TextareaAutosizeModule } from 'ngx-textarea-autosize';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { TagInputModule } from 'ngx-chips';
import { DirectUploadComponent } from './direct-upload/direct-upload.component';
import { ParseImportComponent } from './parse-import/parse-import.component';
import { ToastsComponent } from './toasts/toasts.component';
import { SimpleSpreadsheetComponent } from './simple-spreadsheet/simple-spreadsheet.component';
import { NgxPageScrollCoreModule } from 'ngx-page-scroll-core';
import { ImageCropperModule } from 'ngx-image-cropper';
import { AdvancedSearchComponent } from './advanced-search/advanced-search.component';
import { NgxPermissionsModule } from 'ngx-permissions';
import { ContextMenuModule } from 'ngx-contextmenu';
import { ParseButtonComponent } from './parse-button/parse-button.component';
import { NgxCurrencyModule } from 'ngx-currency';
import { QRCodeModule } from 'angularx-qrcode';
import { AutoLinkPipe } from './_pipes/auto-link.pipe';
import { TableEditedComponent } from './table-edited/table-edited.component';
import {
  SocialLoginModule,
  AuthServiceConfig,
  GoogleLoginProvider,
  FacebookLoginProvider,
  LinkedinLoginProvider,
} from 'angular-6-social-login';
import { DynamicFormMultipleComponent } from './dynamic-form-multiple/dynamic-form-multiple.component';
import { FaInputComponent } from './fa-input/fa-input.component';
import { TypeheadFormComponent } from './typehead-form/typehead-form.component';
import { TableReminderComponent } from './table-reminder/table-reminder.component';
import { DynamicFormCommonComponent } from './dynamic-form-common/dynamic-form-common.component';
import { FullscreenDirective } from './_directives/fullscreen.directive';
import { Ng2ImgMaxModule } from 'ng2-img-max';
import { TableAdvanceComponent } from './table-advance/table-advance.component';
import { GcsUploadComponent } from './gcs-upload/gcs-upload.component';
import { GcsDownloadComponent } from './gcs-download/gcs-download.component';
import { Select2Module } from 'ng-select2-component';
import { ImageUploaderComponent } from './image-uploader/image-uploader.component';
import { DirectDownloadComponent } from './direct-download/direct-download.component';
import { SafeUrl } from './_pipes/safe-url';
import { OnlyNumber } from './_directives/OnlyNumber';

// Configs
export function getAuthServiceConfigs() {
  let config = new AuthServiceConfig(
    [
      {
        id: FacebookLoginProvider.PROVIDER_ID,
        provider: new FacebookLoginProvider("660136134567466")
      },
      {
        id: GoogleLoginProvider.PROVIDER_ID,
        provider: new GoogleLoginProvider("667983529003-33efj498j57kjgajocm68dclp5udcc0d.apps.googleusercontent.com")
      },
      {
        id: LinkedinLoginProvider.PROVIDER_ID,
        provider: new LinkedinLoginProvider("86lqdt3190nyp1")
      }
    ]
  );
  return config;
}


const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true
};

@NgModule({
  declarations: [
    TableCommonComponent,
    PaginationCommonComponent,
    FormatProjectCodePipe,
    AlertCommonComponent,
    AlertModalComponent,
    ConfirmModalComponent,
    FormatValuePipe,
    DynamicSelectComponent,
    TableEditableComponent,
    DescriptionCommonComponent,
    FormCommonComponent,
    DynamicDropzoneComponent,
    ExportButtonComponent,
    FormMultipleComponent,
    DirectUploadComponent,
    ParseImportComponent,
    ToastsComponent,
    SimpleSpreadsheetComponent,
    SafeHtml,
    SafeUrl,
    AdvancedSearchComponent,
    ParseButtonComponent,
    AutoLinkPipe,
    TableEditedComponent,
    DynamicFormMultipleComponent,
    FaInputComponent,
    TypeheadFormComponent,
    TableReminderComponent,
    DynamicFormCommonComponent,
    FullscreenDirective,
    TableAdvanceComponent,
    GcsUploadComponent,
    GcsDownloadComponent,
    ImageUploaderComponent,
    DirectDownloadComponent,
    OnlyNumber,
  ],
  providers: [
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
    },
    {
      provide: AuthServiceConfig,
      useFactory: getAuthServiceConfigs
    }
  ],
  imports: [
    CommonModule,
    FormsModule,
    NgbModule,
    RouterModule,
    PerfectScrollbarModule,
    DropzoneModule,
    NgxUiLoaderModule,
    ClickOutsideModule,
    TextareaAutosizeModule,
    AngularEditorModule,
    TagInputModule,
    // BrowserAnimationsModule,
    ReactiveFormsModule,
    NgxPageScrollCoreModule.forRoot({ duration: 500 }),
    ImageCropperModule,
    NgxPermissionsModule,
    ContextMenuModule.forRoot({
      useBootstrap4: true,
    }),
    NgxCurrencyModule,
    QRCodeModule,
    SocialLoginModule,
    Ng2ImgMaxModule,
    Select2Module
  ],
  exports: [
    TableCommonComponent,
    PaginationCommonComponent,
    NgbModule,
    FormatProjectCodePipe,
    RouterModule,
    AlertCommonComponent,
    FormatValuePipe,
    PerfectScrollbarModule,
    DynamicSelectComponent,
    TableEditableComponent,
    DescriptionCommonComponent,
    FormCommonComponent,
    DynamicDropzoneComponent,
    ExportButtonComponent,
    DropzoneModule,
    FormMultipleComponent,
    NgxUiLoaderModule,
    ClickOutsideModule,
    TextareaAutosizeModule,
    AngularEditorModule,
    TagInputModule,
    ReactiveFormsModule,
    DirectUploadComponent,
    ParseImportComponent,
    ToastsComponent,
    SimpleSpreadsheetComponent,
    SafeHtml,
    SafeUrl,
    AdvancedSearchComponent,
    ParseButtonComponent,
    AutoLinkPipe,
    TableEditedComponent,
    QRCodeModule,
    SocialLoginModule,
    DynamicFormMultipleComponent,
    TableReminderComponent,
    DynamicFormCommonComponent,
    FullscreenDirective,
    TableAdvanceComponent,
    GcsUploadComponent,
    GcsDownloadComponent,
    Select2Module,
    ImageUploaderComponent,
    DirectDownloadComponent
  ],
  entryComponents: [
    AlertModalComponent,
    ConfirmModalComponent
  ]
})
export class SharedModule { }
