import * as tslib_1 from "tslib";
import { NgxPermissionsService } from 'ngx-permissions';
import { AuthenticationService } from '../auth/authentication.service';
import { UserService } from './user.service';
import { AngularFirestore } from '@angular/fire/firestore';
import * as i0 from "@angular/core";
import * as i1 from "@angular/fire/firestore";
import * as i2 from "./user.service";
import * as i3 from "../auth/authentication.service";
import * as i4 from "ngx-permissions";
var ModuleService = /** @class */ (function () {
    function ModuleService(firestore, userService, authenticationService, permissionsService) {
        this.firestore = firestore;
        this.userService = userService;
        this.authenticationService = authenticationService;
        this.permissionsService = permissionsService;
    }
    ModuleService.prototype.getModuleToken = function (username, module) {
        return this.firestore
            .collection("module_token", function (ref) { return ref.where('username', '==', username).where('module', '==', module); })
            .snapshotChanges();
    };
    ModuleService.prototype.syncModuleToken = function (moduleName) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var username;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                console.log('syncModuleToken');
                if (location.hostname != "localhost" && location.hostname != "127.0.0.1") {
                    username = this.userService.getUsername();
                    this.getModuleToken(username, moduleName).subscribe(function (res) {
                        var data = res.map(function (e) {
                            return tslib_1.__assign({ documentId: e.payload.doc.id }, e.payload.doc.data());
                        }).shift();
                        if (localStorage.getItem(moduleName) != data.token) {
                            localStorage.setItem(moduleName, data.token);
                            var roles = _this.authenticationService.getUserRoles(moduleName);
                            var group = _this.userService.getUserGroup();
                            group.push('USER');
                            _this.permissionsService.flushPermissions();
                            if (roles) {
                                _this.authenticationService.loadPermission(group);
                                _this.authenticationService.loadPermission(roles);
                            }
                            document.location.reload();
                        }
                    });
                }
                return [2 /*return*/];
            });
        });
    };
    ModuleService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ModuleService_Factory() { return new ModuleService(i0.ɵɵinject(i1.AngularFirestore), i0.ɵɵinject(i2.UserService), i0.ɵɵinject(i3.AuthenticationService), i0.ɵɵinject(i4.NgxPermissionsService)); }, token: ModuleService, providedIn: "root" });
    return ModuleService;
}());
export { ModuleService };
