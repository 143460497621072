import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { GlobalService } from "./global.service";
import { CookieService } from "ngx-cookie-service";
import { Observable, throwError, Subject } from "rxjs";
import { ResponseApiModel } from "../_models/response-api.model";
import { map, catchError } from "rxjs/operators";

const API_PM_GET_HEALTHY = "/pm/precalc/get-healthy";
const API_ADDITIONAL = "/pm/precalc/get-forecast-temp";
const API_PM_GET_SUMMARY = "/pm/precalc/get-summary";
const API_PM_GET_HEALTHY_LIVE = "/pm/precalc/get-healthy-live";
const API_PM_GET_SUMMARY_LIVE = "/pm/precalc/get-summary-live";
const API_PM_GET_SUMMARY_ALL = "/pm/precalc/get-summary-all";
const API_PM_GET_PRECALC_BY_HASH = "/pm/precalc/get-precalc-by-hash";
const API_DELETE_FORECAST_COST_ALL = "/pm/tracker/delete-forecast-cost";
const API_DELETE_FORECAST_INCOME_ALL = "/pm/tracker/delete-forecast-income";
const API_APPROVE = "/pm/gm/approve-forecast";
const API_APPROVE_ALL = "/pm/gm/approve-forecast-all";
const API_PM_GET_PROJECT_BY_HASH = "/pm/precalc/get-project-by-hash";
const API_PM_CLOSE_PRECALC = "/pm/pmo/close-precalc";
const API_PM_GET_CASH_FLOW = "/pm/precalc/get-cash-flow";
const API_PM_SAVE_CASH_FLOW = "/pm/precalc/save-cash-flow";
const API_PM_EDIT_CASH_FLOW = "/pm/precalc/edit-cash-flow";
const API_PM_GET_PC_BY_PROJECT = "/pm/precalc/get-pc-by-project";
const API_GM_LIST_APPROVAL = "/pm/gm/list-approval";
const API_PM_GET_MONTHLY_COST = "/pm/precalc/get-monthly-cost";
const API_PM_CLOSE_PRECALCS = '/pm/pmo/close-precalcs';
const API_PM_GET_UNBILLED = "/pm/unbilled/get-unbilled";
const API_PM_GET_UNBILLED_PROJECT = "/pm/unbilled/get-project";
const API_PM_ADD_UNBILLED = "/pm/unbilled/add-unbilled";
const API_PM_UPDATE_UNBILLED = "/pm/unbilled/edit-unbilled";
const API_PM_UPDATE_UNBILLED_PERCELL = "/pm/unbilled/edit-unbilled-percell";
const API_PM_DELETE_UNBILLED = "/pm/unbilled/delete-unbilled";
const API_PM_GET_PROJECT_CLIENT = "/pm/unbilled/get-project-client";
const API_PM_IMPORT_UNBILLED = "/pm/unbilled/import-unbilled";
const API_PM_ADD_FORECAST = "/pm/tracker/new-forecast-cost";
const API_PM_IMPORT_NEW_FORECAST = "/pm/tracker/import-forecast-cost";
const API_PM_IMPORT_NEW_FORECAST_TEMP = "/pm/tracker/import-forecast-cost-temp";
const API_PM_UPDATE_FORECAST = "/pm/tracker/update-forecast";
const API_PM_GET_FORECASTS = "/pm/tracker/get-forecasts";
const API_PM_GET_FORECAST = "/pm/tracker/get-forecast";
const API_PM_DELETE_FORECAST = "/pm/tracker/delete-forecast";
const API_PM_DELETE_FORECAST_SELECTED = "/pm/tracker/delete-forecast-selected";
const API_PM_IMPORT_FORECAST = "/pm/tracker/import";
const API_PM_REVISE_FORECAST = "/pm/tracker/revise-forecast";

@Injectable({
  providedIn: 'root'
})
export class PrecalcService {

  public isPrecalcOpenEmit: Subject<boolean> = new Subject<boolean>();
  public isPrecalcOpenEmitObs = this.isPrecalcOpenEmit.asObservable();

  public pcodeChange: Subject<boolean> = new Subject<boolean>();
  public pcodeChangeObs = this.pcodeChange.asObservable();

  constructor(
    private http: HttpClient,
    private globalService: GlobalService,
    private cookieService: CookieService
  ) {
    this.isPrecalcOpenEmit.next(false);
    this.pcodeChange.next(false);
  }

  public header() {
    return {
      headers: new HttpHeaders()
        .set("token", this.cookieService.get("_q"))
        .set("module", localStorage.getItem("pm"))
        .set("group", this.cookieService.get("_g"))
    };
  }

  public getAdditional(param: {
    hash: string;
  }): Observable<ResponseApiModel> {
    return this.http
      .post<ResponseApiModel>(
        this.globalService.apiVersionHost + API_ADDITIONAL,
        param,
        this.header()
      )
      .pipe(map(response => response));
  }

  public getHealthy(param: {
    hash: string;
  }): Observable<ResponseApiModel> {
    return this.http
      .post<ResponseApiModel>(
        this.globalService.apiVersionHost + API_PM_GET_HEALTHY,
        param,
        this.header()
      )
      .pipe(map(response => response));
  }
  public getHealthyNew(param: {
    account: string;
  }): Observable<ResponseApiModel> {
    return this.http
      .post<ResponseApiModel>(
        this.globalService.apiVersionHost + '/pm/precalc/get-healthy-new',
        param,
        this.header()
      )
      .pipe(map(response => response));
  }
  public getHealthyPcode(param: {
    account: string;
    project_code: string;
  }): Observable<ResponseApiModel> {
    return this.http
      .post<ResponseApiModel>(
        this.globalService.apiVersionHost + '/pm/precalc/get-healthy-pcode',
        param,
        this.header()
      )
      .pipe(map(response => response));
  }

  public getSummary(param: {
    hash: string;
  }): Observable<ResponseApiModel> {
    return this.http
      .post<ResponseApiModel>(
        this.globalService.apiVersionHost + API_PM_GET_SUMMARY,
        param,
        this.header()
      )
      .pipe(map(response => response));
  }

  public getDataPrecalcAnalysis(param: {
    hash: string;
  }): Observable<ResponseApiModel> {
    return this.http
      .post<ResponseApiModel>(
        this.globalService.apiVersionHost + '/pm/precalc/precalc-analysis',
        param,
        this.header()
      )
      .pipe(map(response => response));
  }


  public getHealthyLive(param: {
    hash: string;
  }): Observable<ResponseApiModel> {
    return this.http
      .post<ResponseApiModel>(
        this.globalService.apiVersionHost + API_PM_GET_HEALTHY_LIVE,
        param,
        this.header()
      )
      .pipe(map(response => response));
  }
  public getHealthyLiveNew(param: {
    account: string;
  }): Observable<ResponseApiModel> {
    return this.http
      .post<ResponseApiModel>(
        this.globalService.apiVersionHost + "/pm/precalc/get-healthy-live-new",
        param,
        this.header()
      )
      .pipe(map(response => response));
  }
  public getHealthyLivePcode(param: {
    account: string;
    project_code: string;
  }): Observable<ResponseApiModel> {
    return this.http
      .post<ResponseApiModel>(
        this.globalService.apiVersionHost + "/pm/precalc/get-healthy-live-pcode",
        param,
        this.header()
      )
      .pipe(map(response => response));
  }


  public getSummaryAll(param: any): Observable<ResponseApiModel> {
    return this.http
      .post<ResponseApiModel>(
        this.globalService.apiVersionHost + API_PM_GET_SUMMARY_ALL,
        param,
        this.header()
      )
      .pipe(map(response => response));
  }
  public getSummaryLive(param: {
    hash: string;
    finance_cost: number;
  }): Observable<ResponseApiModel> {
    return this.http
      .post<ResponseApiModel>(
        this.globalService.apiVersionHost + API_PM_GET_SUMMARY_LIVE,
        param,
        this.header()
      )
      .pipe(map(response => response));
  }

  public saveUpdateItemValue(param: any): Observable<ResponseApiModel> {
    return this.http
      .post<ResponseApiModel>(
        this.globalService.apiVersionHost + '/pm/tracker/update-item-value',
        param,
        this.header()
      )
      .pipe(map(response => response));
  }

  public getPrecalcByHash(params: { hash: string }): Observable<ResponseApiModel> {
    return this.http
      .post<ResponseApiModel>(
        this.globalService.apiVersionHost + API_PM_GET_PRECALC_BY_HASH,
        params,
        this.header()
      )
      .pipe(map(response => response));
  }
  public deleteForecastCostAll(params: { hash: string }): Observable<ResponseApiModel> {
    return this.http
      .post<ResponseApiModel>(
        this.globalService.apiVersionHost + API_DELETE_FORECAST_COST_ALL,
        params,
        this.header()
      )
      .pipe(map(response => response));
  }
  public deleteForecastIncomeAll(params: { hash: string }): Observable<ResponseApiModel> {
    return this.http
      .post<ResponseApiModel>(
        this.globalService.apiVersionHost + API_DELETE_FORECAST_INCOME_ALL,
        params,
        this.header()
      )
      .pipe(map(response => response));
  }

  public approveForecast(params: { id_project: number; approved: number }): Observable<ResponseApiModel> {
    return this.http
      .post<ResponseApiModel>(
        this.globalService.apiVersionHost + API_APPROVE,
        params,
        this.header()
      )
      .pipe(map(response => response));
  }
  public approveForecastAll(params: { id_project: any; approved: number }): Observable<ResponseApiModel> {
    return this.http
      .post<ResponseApiModel>(
        this.globalService.apiVersionHost + API_APPROVE_ALL,
        params,
        this.header()
      )
      .pipe(map(response => response));
  }

  public getProjectByHash(params: { hash: string }): Observable<ResponseApiModel> {
    return this.http
      .post<ResponseApiModel>(
        this.globalService.apiVersionHost + API_PM_GET_PROJECT_BY_HASH,
        params,
        this.header()
      )
      .pipe(map(response => response));
  }

  public closePrecalc(params: { hash: string }): Observable<ResponseApiModel> {
    return this.http
      .post<ResponseApiModel>(
        this.globalService.apiVersionHost + API_PM_CLOSE_PRECALC,
        params,
        this.header()
      )
      .pipe(map(response => response));
  }

  public getCashFlow(params: { hash: string }): Observable<ResponseApiModel> {
    return this.http
      .post<ResponseApiModel>(
        this.globalService.apiVersionHost + API_PM_GET_CASH_FLOW,
        params,
        this.header()
      )
      .pipe(map(response => response));
  }

  public saveCashFlow(params: { hash: string, cash_flow: any }): Observable<ResponseApiModel> {
    return this.http
      .post<ResponseApiModel>(
        this.globalService.apiVersionHost + API_PM_SAVE_CASH_FLOW,
        params,
        this.header()
      )
      .pipe(map(response => response));
  }

  public editCashFlow(params: { hash: string, cash_flow: any }): Observable<ResponseApiModel> {
    return this.http
      .post<ResponseApiModel>(
        this.globalService.apiVersionHost + API_PM_EDIT_CASH_FLOW,
        params,
        this.header()
      )
      .pipe(map(response => response));
  }

  public getPCByPRoject(params: {
    id_project: number;
    pageSize: number;
    page: number;
    filter: any;
  }): Observable<ResponseApiModel> {
    return this.http
      .post<ResponseApiModel>(
        this.globalService.apiVersionHost + API_PM_GET_PC_BY_PROJECT,
        params,
        this.header()
      )
      .pipe(map(response => response));
  }

  public listApproval(params: {
    pageSize: number;
    page: number;
    filter: any;
  }): Observable<ResponseApiModel> {
    return this.http
      .post<ResponseApiModel>(
        this.globalService.apiVersionHost + API_GM_LIST_APPROVAL,
        params,
        this.header()
      )
      .pipe(map(response => response));
  }

  public getMonthlyCost(params: {
    idMaster: number;
  }): Observable<ResponseApiModel> {
    return this.http
      .post<ResponseApiModel>(
        this.globalService.apiVersionHost + API_PM_GET_MONTHLY_COST,
        params,
        this.header()
      )
      .pipe(map(response => response));
  }

  public closePrecalcs(params: any): Observable<ResponseApiModel> {
    return this.http
      .post<ResponseApiModel>(
        this.globalService.apiVersionHost + API_PM_CLOSE_PRECALCS,
        params,
        this.header()
      )
      .pipe(map(response => response));
  }

  public getUnbilled(params: {
    pageSize: number;
    page: number;
    filter: any;
    id: number;
  }): Observable<ResponseApiModel> {
    return this.http
      .post<ResponseApiModel>(
        this.globalService.apiVersionHost + API_PM_GET_UNBILLED,
        params,
        this.header()
      )
      .pipe(map(response => response));
  }

  public getProjectUnbilled(params: {
    id: number;
  }): Observable<ResponseApiModel> {
    return this.http
      .post<ResponseApiModel>(
        this.globalService.apiVersionHost + API_PM_GET_UNBILLED_PROJECT,
        params,
        this.header()
      )
      .pipe(map(response => response));
  }

  public addUnbilled(params): Observable<ResponseApiModel> {
    return this.http
      .post<ResponseApiModel>(
        this.globalService.apiVersionHost + API_PM_ADD_UNBILLED,
        params,
        this.header()
      )
      .pipe(map(response => response));
  }

  public editUnbilled(params): Observable<ResponseApiModel> {
    return this.http
      .post<ResponseApiModel>(
        this.globalService.apiVersionHost + API_PM_UPDATE_UNBILLED,
        params,
        this.header()
      )
      .pipe(map(response => response));
  }

  public editUnbilledPercell(data): Observable<ResponseApiModel> {
    return this.http
      .post<ResponseApiModel>(
        this.globalService.apiVersionHost + API_PM_UPDATE_UNBILLED_PERCELL,
        { data },
        this.header()
      )
      .pipe(map(response => response));
  }

  public deleteUnbilled(params): Observable<ResponseApiModel> {
    return this.http
      .post<ResponseApiModel>(
        this.globalService.apiVersionHost + API_PM_DELETE_UNBILLED,
        params,
        this.header()
      )
      .pipe(map(response => response));
  }

  public getProjectClient(): Observable<ResponseApiModel> {
    return this.http
      .get<ResponseApiModel>(
        this.globalService.apiVersionHost + API_PM_GET_PROJECT_CLIENT,
        this.header()
      )
      .pipe(map(response => response));
  }

  public importUnbilled(params): Observable<ResponseApiModel> {
    return this.http
      .post<ResponseApiModel>(
        this.globalService.apiVersionHost + API_PM_IMPORT_UNBILLED,
        params,
        this.header()
      )
      .pipe(map(response => response));
  }

  public importForecastCost(param: { id_project: string, data: any, team: any }): Observable<ResponseApiModel> {
    return this.http
      .post<ResponseApiModel>(
        this.globalService.apiVersionHost + API_PM_IMPORT_NEW_FORECAST,
        param,
        this.header()
      )
      .pipe(map(response => response));
  }
  public importForecastCostTemp(param: { id_project: string, data: any, team: any }): Observable<ResponseApiModel> {
    return this.http
      .post<ResponseApiModel>(
        this.globalService.apiVersionHost + API_PM_IMPORT_NEW_FORECAST_TEMP,
        param,
        this.header()
      )
      .pipe(map(response => response));
  }

  public addForecast(param: { id_project: string, period: any, data: any }): Observable<ResponseApiModel> {
    return this.http
      .post<ResponseApiModel>(
        this.globalService.apiVersionHost + API_PM_ADD_FORECAST,
        param,
        this.header()
      )
      .pipe(map(response => response));
  }

  public updateForecast(param: any): Observable<ResponseApiModel> {
    return this.http
      .post<ResponseApiModel>(
        this.globalService.apiVersionHost + API_PM_UPDATE_FORECAST,
        param,
        this.header()
      )
      .pipe(map(response => response));
  }

  public getForecasts(param): Observable<ResponseApiModel> {
    return this.http
      .post<ResponseApiModel>(
        this.globalService.apiVersionHost + API_PM_GET_FORECASTS,
        param,
        this.header()
      )
      .pipe(map(response => response));
  }

  public getForecastcost(x): Observable<ResponseApiModel> {
    return this.http
      .post<ResponseApiModel>(
        this.globalService.apiVersionHost + "/pm/tracker/get-forecast-cost",
        { hash: x },
        this.header()
      )
      .pipe(map(response => response));
  }

  public exportForecastcost(x): Observable<ResponseApiModel> {
    return this.http
      .post<ResponseApiModel>(
        this.globalService.apiVersionHost + "/pm/tracker/export-forecast-cost",
        { hash: x },
        this.header()
      )
      .pipe(map(response => response));
  }

  public exportForecastIncome(x): Observable<ResponseApiModel> {
    return this.http
      .post<ResponseApiModel>(
        this.globalService.apiVersionHost + "/pm/tracker/export-forecast-income",
        { hash: x },
        this.header()
      )
      .pipe(map(response => response));
  }

  public getForecast(x): Observable<ResponseApiModel> {
    return this.http
      .post<ResponseApiModel>(
        this.globalService.apiVersionHost + API_PM_GET_FORECAST,
        { hash: x },
        this.header()
      )
      .pipe(map(response => response));
  }

  public deleteForecast(x): Observable<ResponseApiModel> {
    return this.http
      .post<ResponseApiModel>(
        this.globalService.apiVersionHost + API_PM_DELETE_FORECAST,
        { id: x },
        this.header()
      )
      .pipe(map(response => response));
  }

  public deleteForecastSelected(
    id_selected: number[]
  ): Observable<ResponseApiModel> {
    return this.http
      .post<ResponseApiModel>(
        this.globalService.apiVersionHost + API_PM_DELETE_FORECAST_SELECTED,
        { id_selected: id_selected },
        this.header()
      )
      .pipe(map(response => response));
  }

  public reviseForecast(param: any): Observable<ResponseApiModel> {
    return this.http
      .post<ResponseApiModel>(
        this.globalService.apiVersionHost + API_PM_REVISE_FORECAST,
        param,
        this.header()
      )
      .pipe(map(response => response));
  }

  public import(params: {
    hash: string;
    data: any[];
  }): Observable<ResponseApiModel> {
    return this.http
      .post<ResponseApiModel>(
        this.globalService.apiVersionHost + API_PM_IMPORT_FORECAST,
        params,
        this.header()
      )
      .pipe(map(response => response));
  }
  
  public getCostCategory(param): Observable<ResponseApiModel> {
    return this.http.post<ResponseApiModel>(
      this.globalService.apiVersionHost + '/pm/costcategory/get-cost-category', param, this.header()
    ).pipe(map(response => response));
  }
}
