import { Component, OnInit } from '@angular/core';
import { UserService } from 'src/app/_services/user.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-cv',
  templateUrl: './cv.component.html',
  styleUrls: ['./cv.component.scss']
})
export class CvComponent implements OnInit {
  invalid: any = {};
  data: any = {};
  posisiKerja: any = [];
  posisiKerjaOri: any = [
  ];

  constructor(
    private userService: UserService

  ) { }

  ngOnInit() {
    this.getPosisi();
  }

  getPosisi() {
    this.userService.listPosisi().subscribe(response => {
      if (response.status) {
        this.posisiKerjaOri = response.result.row;
        this.posisiKerja=this.posisiKerjaOri;
      } else {
        Swal.fire('Gagal upload CV.');
      }
    }, error => {
      Swal.fire(error.error.message);
    });
  }

  
  updateSelectePosisi(event) {
    this.data.posisi = event.value;

  }

  searchSelectePosisi(event) {
    let search = event.search;
    if (search.length >= 1) {
      this.posisiKerja = this.posisiKerjaOri.filter(item => item.label.toLowerCase().indexOf(search.toLowerCase()) >= 0);
    }
  }

  clearHp(){
    this.data.hp=this.data.hp.replace(/\D/g,'');
  }

  saveCV() {
    if (this.data.email) {
      if (!this.validasi('email')) {
        Swal.fire('Email tidak valid.');
      }
    }
    if (this.data.nama && this.data.file) {
      this.userService.dropCV({ data: this.data }).subscribe(response => {
        if (response.status) {
          this.successModal('Upload CV sukses.');
        } else {
          Swal.fire('Gagal upload CV.');
        }

      }, error => {
        Swal.fire(error.error.message);
      });
    } else {
      Swal.fire('Mohon lengkapi isian.');

    }
  }


  successModal(text: string) {
    Swal.fire({
      type: 'success',
      // title: 'Sorry..',
      text: text,
      footer: ''
    });
  }

  validasi(row: string) {
    this.invalid[row] = false;
    if (row != 'email') {
      if (this.data[row] == '' || this.data[row] == undefined) {
        this.invalid[row] = true;
        return false;
      }
    } else {
      const regexp = new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
      if (!regexp.test(this.data[row])) {
        this.invalid[row] = true;
        return false;
      }
    }
    return true;
  }


  onSuccess($event, attribute) {
    this.data[attribute] = 1*$event.id;
  }

  onUploaded($event, attribute) {
    this.data[attribute] =1* $event.wasabiRowId;
  }


}
