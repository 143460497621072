<div id="nds_contents">
  <div class="bg-white navbar-spacer"></div>
  <div class="pagecomponentwrap text-center text-white " id="pageheader">
    
  </div>

  <!-- DESC EXAMS -->
  <div class="pagecomponentwrap bg-white">
    <div class="container pb-100 pt-75">
      <div class="row align-items-center">
        <div class="col-md-4 mb-4">
          <img src="/assets/media/nds/examp.svg" alt="" class="w-100 px-4">
        </div>
        <div class="col-md-8 mb-4">
          <div class="border px-3 px-md-4 py-3 py-md-4 rounded mb-5 bg-light">
            <div class="h5 font-weight-bold mb-3">
              Silakan Masukkan Kode Voucher Ujian :
            </div>
            <div class="input-group">
              <input #voucher type="text" class="form-control" placeholder="Kode voucher" aria-label="voucher" aria-describedby="basic-addon2">
              <div class="input-group-append">
                <button class="btn btn-primary" type="button" (click)="submit(voucher.value)">
                  Selanjutnya
                </button>
              </div>
            </div>
          </div>
          <div>
            <div class="mb-3">
              Jika ada kesulitan Anda dapat menghubungi NDS Care melalui kontak beriut:
            </div>
            <div>
              <a class="btn btn-sm btn-primary mr-2" href="https://t.me/NdsCare">
                <span class="fab fa-telegram-plane mr-1"></span>
                 @NdsCare
              </a>
              <a class="btn btn-sm btn-primary" href="https://www.google.com/gmail/">
                <span class="fa fa-envelope mr-1"></span>
                 dev.nexwave@gmail.com
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
