import { HttpClient, HttpHeaders } from '@angular/common/http';
import { GlobalService } from './global.service';
import { map } from 'rxjs/operators';
import { CookieService } from 'ngx-cookie-service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AlertModalComponent } from '../layout/shared/alert-modal/alert-modal.component';
import Swal from 'sweetalert2';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "./global.service";
import * as i3 from "ngx-cookie-service";
import * as i4 from "@ng-bootstrap/ng-bootstrap";
var API_GET_ATTENDANCE = '/attendance/attendance/get-list-attendances';
var API_GET_ATTENDANCE_V2 = '/attendance/default/list-attendance-v2';
var API_GET_CLOCK_OUT = '/attendance/default/get-clock-out';
var API_GET_NEAREST_OFFICE = '/attendance/attendance/nearest-office';
var API_NEW_ATTENDANCE = '/attendance/attendance/new-attendance';
var API_MOVE_OFFICE = '/attendance/attendance/move-office';
var API_GO_HOME = '/attendance/attendance/go-home';
var API_GET_MY_ATTENDANCE = '/attendance/default/get-my-attendance';
var API_GET_MY_ATTENDANCE_REPORT = '/attendance/default/get-my-attendance-report';
var API_EXPORT_MY_ATTENDANCE_REPORT = '/attendance/default/export-my-attendance-report';
var API_GET_MY_ATTENDANCE_DETAIL = '/attendance/default/get-my-attendance-detail';
var API_GET_MY_TEAM_ATTENDANCE_SUMMARY = '/attendance/default/get-my-team-attendance-summary';
var API_GET_MY_TEAM_ATTENDANCE = '/attendance/default/get-my-team-attendance';
var API_GET_MY_TEAM_TODAY = '/attendance/default/get-my-team-today';
var API_GET_LIST_MY_TEAM = '/attendance/default/get-list-my-team';
var API_GET_MY_TEAM_ATTENDANCE_DETAIL = '/attendance/default/get-my-team-attendance-detail';
var API_GET_DAILY_ATTENDANCE_SUMMARY = '/attendance/default/get-daily-attendance-summary';
var API_GET_DAILY_ATTENDANCE_SUMMARY_DETAIL = '/attendance/default/get-daily-attendance-summary-detail';
var API_GET_MONTHLY_ATTENDANCE_DETAIL = '/attendance/default/get-monthly-attendance-detail';
var API_GET_LOW_PERFORMANCE = '/attendance/default/low-performance';
var API_GET_SUMMARY_ATTENDANCE = '/attendance/default/get-summary-attendance';
var API_GET_MY_PERMISSION_REQUEST = '/attendance/default/get-my-permission-request';
var API_GET_UNPAID_LEAVE_REQUEST = '/attendance/default/get-unpaid-leave-request';
var API_GET_PERMISSION_DETAIL = '/attendance/default/get-permission-detail';
var API_GET_UNPAID_LEAVE_DETAIL = '/attendance/default/get-unpaid-leave-detail';
var API_GET_TL = '/attendance/default/get-team';
var API_GET_REASONS = '/attendance/default/get-reasons';
var API_GET_MY_TEAM = '/attendance/default/get-my-team';
var API_GET_NOT_RECORDED = '/attendance/default/get-not-recorded-attendance';
var API_CREATE_PERMISSION_REQUEST = '/attendance/default/create-permission-request';
var API_CREATE_UNPAID_LEAVE_REQUEST = '/attendance/default/create-unpaid-leave-request';
var API_APPROVAL_PERMISSION = '/attendance/approval/request-approval';
var API_GET_MY_APPROVAL = '/attendance/approval/get-my-approval';
var API_GET_LIST_APPROVAL = '/attendance/approval/get-list-approval';
var API_REMINDER = '/attendance/approval/send-reminder';
var API_REMINDER_ALL = '/attendance/approval/send-reminder-all';
var API_APPROVAL_UNPAID_LEAVE = '/attendance/default/approval-unpaid-leave';
var API_EXPORT_UNPAID_LEAVE = '/attendance/default/export-unpaid-leave';
var API_APPROVE = '/attendance/approval/approve';
var API_REJECT = '/attendance/approval/reject';
var API_CONFIRM = '/attendance/approval/confirm';
var API_GET_PERMISSION_RULE = '/attendance/default/get-permission-rule';
var API_JUSTIFICATION = '/attendance/dev/justification';
var API_CHECK_JUSTIFICATION = '/attendance/dev/check-justification';
var API_CHECK_ATTENDANCE = '/home/check-attendance';
var API_HOME = '/attendance/home/get-home-all';
var API_HOME_DETAIL = '/attendance/home/get-home';
var API_VIEW_UPLOAD = '/attendance/home/view-upload';
var API_CHANGE_STATUS = '/attendance/home/change-status';
var API_USERS_HOME = '/attendance/dev/users-home';
var API_APPROVE_HOME = '/attendance/dev/approve-home';
var API_NOCLOCKOUT = '/attendance/dev/no-clock-out';
var API_NOCLOCKOUTEXCEL = '/attendance/dev/no-clock-out-excel';
var API_EXPORT_MY_TEAM_ATTENDANCE = '/attendance/default/export-my-team-attendance';
var API_EXPORT_MY_TEAM_ATTENDANCE_SUMMARY = '/attendance/default/export-my-team-attendance-summary';
var API_JUSTIFICATION_REQUEST = '/attendance/default/justification-request';
var API_JUSTIFICATION_REQUEST_PROGRESS = '/attendance/default/justification-request-progress';
var API_UNPAID_LEAVE_REQUEST_PROGRESS = '/attendance/default/unpaid-leave-request-progress';
var API_JUSTIFICATION_REQUEST_DETAIL = '/attendance/default/justification-request-detail';
var API_SUBMIT_JUSTIFICATION_REQUEST = '/attendance/default/submit-justification-request';
var API_JUSTIFICATION_APPROVAL = '/attendance/default/justification-approval';
var API_SUBMIT_JUSTIFICATION_APPROVAL = '/attendance/default/submit-justification-approval';
var API_IMPORT_OFFICELIST = '/attendance/default/import-officelist';
var API_PICTURE_ATTENDANCE = '/attendance/default/get-picture-attendance';
var API_GET_VIEW_EXPORT_ATTENDANCE = '/attendance/default/get-attendance';
var API_EXPORT_ATTENDANCE = '/attendance/default/export-attendance';
var API_EXPORT_JUSTIFICATION_REQUEST = '/attendance/default/export-justification-request';
var API_EXPORT_JUSTIFICATION_APPROVAL = '/attendance/default/export-justification-approval';
var API_APPROVAL_ALL = '/attendance/default/approve-all-justification';
var API_GET_MY_TEAM_PREVIEW = '/attendance/default/get-my-team-attendance'; //apinya sama, tapi hanya di ambil image saja
var AttendanceService = /** @class */ (function () {
    function AttendanceService(http, globalService, cookieService, modalService) {
        this.http = http;
        this.globalService = globalService;
        this.cookieService = cookieService;
        this.modalService = modalService;
    }
    AttendanceService.prototype.attendanceHeader = function () {
        return {
            headers: new HttpHeaders()
                .set('token', this.cookieService.get('_q'))
                .set('module', localStorage.getItem('attendance'))
                .set('group', this.cookieService.get('_g'))
        };
    };
    AttendanceService.prototype.getAttendance = function (param) {
        return this.http.post(this.globalService.apiVersionHost + API_GET_ATTENDANCE, param, this.attendanceHeader()).pipe(map(function (response) { return response; }));
    };
    AttendanceService.prototype.getAttendancev2 = function (param) {
        return this.http.post(this.globalService.apiVersionHost + API_GET_ATTENDANCE_V2, param, this.attendanceHeader()).pipe(map(function (response) { return response; }));
    };
    AttendanceService.prototype.getClockOut = function (param) {
        return this.http.post(this.globalService.apiVersionHost + API_GET_CLOCK_OUT, param, this.attendanceHeader()).pipe(map(function (response) { return response; }));
    };
    AttendanceService.prototype.getNearestOffice = function (param) {
        return this.http.post(this.globalService.apiVersionHost + API_GET_NEAREST_OFFICE, param, this.attendanceHeader()).pipe(map(function (response) { return response; }));
    };
    AttendanceService.prototype.newAttendance = function (param) {
        return this.http.post(this.globalService.apiVersionHost + API_NEW_ATTENDANCE, param, this.attendanceHeader()).pipe(map(function (response) { return response; }));
    };
    AttendanceService.prototype.moveOffice = function (param) {
        return this.http.post(this.globalService.apiVersionHost + API_MOVE_OFFICE, param, this.attendanceHeader()).pipe(map(function (response) { return response; }));
    };
    AttendanceService.prototype.goHome = function (param) {
        return this.http.post(this.globalService.apiVersionHost + API_GO_HOME, param, this.attendanceHeader()).pipe(map(function (response) { return response; }));
    };
    AttendanceService.prototype.getSummaryAttendance = function (params) {
        return this.http
            .post(this.globalService.apiVersionHost +
            API_GET_SUMMARY_ATTENDANCE, params, this.attendanceHeader())
            .pipe(map(function (response) { return response; }));
    };
    AttendanceService.prototype.getMyAttendance = function (param) {
        return this.http.post(this.globalService.apiVersionHost + API_GET_MY_ATTENDANCE, param, this.attendanceHeader()).pipe(map(function (response) { return response; }));
    };
    AttendanceService.prototype.getMyAttendanceReport = function (param) {
        return this.http.post(this.globalService.apiVersionHost + API_GET_MY_ATTENDANCE_REPORT, param, this.attendanceHeader()).pipe(map(function (response) { return response; }));
    };
    AttendanceService.prototype.exportMyAttendanceReport = function (param) {
        return this.http.post(this.globalService.apiVersionHost + API_EXPORT_MY_ATTENDANCE_REPORT, param, this.attendanceHeader()).pipe(map(function (response) { return response; }));
    };
    AttendanceService.prototype.exportMyTeamAttendance = function (param) {
        return this.http.post(this.globalService.apiVersionHost + API_EXPORT_MY_TEAM_ATTENDANCE, param, this.attendanceHeader()).pipe(map(function (response) { return response; }));
    };
    AttendanceService.prototype.exportMyTeamAttendanceSummary = function (param) {
        return this.http.post(this.globalService.apiVersionHost + API_EXPORT_MY_TEAM_ATTENDANCE_SUMMARY, param, this.attendanceHeader()).pipe(map(function (response) { return response; }));
    };
    AttendanceService.prototype.getMyAttendanceDetail = function (param) {
        return this.http.post(this.globalService.apiVersionHost + API_GET_MY_ATTENDANCE_DETAIL, param, this.attendanceHeader()).pipe(map(function (response) { return response; }));
    };
    AttendanceService.prototype.getMyTeamAttendanceSummary = function (param) {
        return this.http.post(this.globalService.apiVersionHost + API_GET_MY_TEAM_ATTENDANCE_SUMMARY, param, this.attendanceHeader()).pipe(map(function (response) { return response; }));
    };
    AttendanceService.prototype.getMyTeamAttendance = function (param) {
        return this.http.post(this.globalService.apiVersionHost + API_GET_MY_TEAM_ATTENDANCE, param, this.attendanceHeader()).pipe(map(function (response) { return response; }));
    };
    AttendanceService.prototype.getViewExportAttendance = function (param) {
        return this.http.post(this.globalService.apiVersionHost + API_GET_VIEW_EXPORT_ATTENDANCE, param, this.attendanceHeader()).pipe(map(function (response) { return response; }));
    };
    AttendanceService.prototype.getExportAttendance = function (param) {
        return this.http.post(this.globalService.apiVersionHost + API_EXPORT_ATTENDANCE, param, this.attendanceHeader()).pipe(map(function (response) { return response; }));
    };
    AttendanceService.prototype.getMyTeamToday = function (param) {
        return this.http.post(this.globalService.apiVersionHost + API_GET_MY_TEAM_TODAY, param, this.attendanceHeader()).pipe(map(function (response) { return response; }));
    };
    AttendanceService.prototype.getListMyTeam = function () {
        return this.http.get(this.globalService.apiVersionHost + API_GET_LIST_MY_TEAM, this.attendanceHeader()).pipe(map(function (response) { return response; }));
    };
    AttendanceService.prototype.getMyTeam = function () {
        return this.http.get(this.globalService.apiVersionHost + API_GET_MY_TEAM, this.attendanceHeader()).pipe(map(function (response) { return response; }));
    };
    AttendanceService.prototype.getMyTeamAttendanceDetail = function (param) {
        return this.http.post(this.globalService.apiVersionHost + API_GET_MY_TEAM_ATTENDANCE_DETAIL, param, this.attendanceHeader()).pipe(map(function (response) { return response; }));
    };
    AttendanceService.prototype.getDailyAttendanceSummary = function (param) {
        return this.http.post(this.globalService.apiVersionHost + API_GET_DAILY_ATTENDANCE_SUMMARY, param, this.attendanceHeader()).pipe(map(function (response) { return response; }));
    };
    AttendanceService.prototype.getDailyAttendanceSummaryDetail = function (param) {
        return this.http.post(this.globalService.apiVersionHost + API_GET_DAILY_ATTENDANCE_SUMMARY_DETAIL, param, this.attendanceHeader()).pipe(map(function (response) { return response; }));
    };
    AttendanceService.prototype.getMonthlyAttendanceDetail = function (param) {
        return this.http.post(this.globalService.apiVersionHost + API_GET_MONTHLY_ATTENDANCE_DETAIL, param, this.attendanceHeader()).pipe(map(function (response) { return response; }));
    };
    AttendanceService.prototype.getLowPerformance = function (param) {
        return this.http.post(this.globalService.apiVersionHost + API_GET_LOW_PERFORMANCE, param, this.attendanceHeader()).pipe(map(function (response) { return response; }));
    };
    AttendanceService.prototype.getSummaryPermission = function (param) {
        return this.http.post(this.globalService.apiVersionHost + "/attendance/default/summary-excused-absence", param, this.attendanceHeader()).pipe(map(function (response) { return response; }));
    };
    AttendanceService.prototype.getDashboardAttendance = function (param) {
        return this.http.post(this.globalService.apiVersionHost + "/attendance/default/dashboard-attendance", param, this.attendanceHeader()).pipe(map(function (response) { return response; }));
    };
    AttendanceService.prototype.getUnpaidLeaveRequest = function (param) {
        return this.http.post(this.globalService.apiVersionHost + API_GET_UNPAID_LEAVE_REQUEST, param, this.attendanceHeader()).pipe(map(function (response) { return response; }));
    };
    AttendanceService.prototype.getUnpaidLeaveDetail = function (param) {
        return this.http.post(this.globalService.apiVersionHost + API_GET_UNPAID_LEAVE_DETAIL, param, this.attendanceHeader()).pipe(map(function (response) { return response; }));
    };
    AttendanceService.prototype.getMyRequestPermission = function (param) {
        return this.http.post(this.globalService.apiVersionHost + API_GET_MY_PERMISSION_REQUEST, param, this.attendanceHeader()).pipe(map(function (response) { return response; }));
    };
    AttendanceService.prototype.getPermissionDetail = function (param) {
        return this.http.post(this.globalService.apiVersionHost + API_GET_PERMISSION_DETAIL, param, this.attendanceHeader()).pipe(map(function (response) { return response; }));
    };
    AttendanceService.prototype.getCrew = function () {
        return this.http.get(this.globalService.apiVersionHost + API_GET_TL, this.attendanceHeader()).pipe(map(function (response) { return response; }));
    };
    AttendanceService.prototype.getReasons = function () {
        return this.http.post(this.globalService.apiVersionHost + API_GET_REASONS, null, this.attendanceHeader()).pipe(map(function (response) { return response; }));
    };
    // tslint:disable-next-line: max-line-length
    AttendanceService.prototype.getNotRecorded = function (param) {
        return this.http.post(this.globalService.apiVersionHost + API_GET_NOT_RECORDED, param, this.attendanceHeader()).pipe(map(function (response) { return response; }));
    };
    AttendanceService.prototype.createPermissionRequest = function (param) {
        return this.http.post(this.globalService.apiVersionHost + API_CREATE_PERMISSION_REQUEST, param, this.attendanceHeader()).pipe(map(function (response) { return response; }));
    };
    AttendanceService.prototype.createUnpaidLeaveRequest = function (param) {
        return this.http.post(this.globalService.apiVersionHost + API_CREATE_UNPAID_LEAVE_REQUEST, param, this.attendanceHeader()).pipe(map(function (response) { return response; }));
    };
    AttendanceService.prototype.approvalPermissionRequest = function (param) {
        return this.http.post(this.globalService.apiVersionHost + API_APPROVAL_PERMISSION, param, this.attendanceHeader()).pipe(map(function (response) { return response; }));
    };
    AttendanceService.prototype.getMyApproval = function (param) {
        return this.http.post(this.globalService.apiVersionHost + API_GET_MY_APPROVAL, param, this.attendanceHeader()).pipe(map(function (response) { return response; }));
    };
    AttendanceService.prototype.getListPermission = function (param) {
        return this.http.post(this.globalService.apiVersionHost + API_GET_LIST_APPROVAL, param, this.attendanceHeader()).pipe(map(function (response) { return response; }));
    };
    AttendanceService.prototype.remindById = function (param) {
        return this.http.post(this.globalService.apiVersionHost + API_REMINDER, param, this.attendanceHeader()).pipe(map(function (response) { return response; }));
    };
    AttendanceService.prototype.remindAll = function (param) {
        return this.http.post(this.globalService.apiVersionHost + API_REMINDER_ALL, param, this.attendanceHeader()).pipe(map(function (response) { return response; }));
    };
    AttendanceService.prototype.approve = function (id) {
        return this.http.post(this.globalService.apiVersionHost + API_APPROVE, { id: id }, this.attendanceHeader()).pipe(map(function (response) { return response; }));
    };
    AttendanceService.prototype.reject = function (param) {
        return this.http.post(this.globalService.apiVersionHost + API_REJECT, param, this.attendanceHeader()).pipe(map(function (response) { return response; }));
    };
    AttendanceService.prototype.confirm = function (param) {
        return this.http.post(this.globalService.apiVersionHost + API_CONFIRM, param, this.attendanceHeader()).pipe(map(function (response) { return response; }));
    };
    AttendanceService.prototype.getPermissionRule = function (permission) {
        return this.http.post(this.globalService.apiVersionHost + API_GET_PERMISSION_RULE, { permission: permission }, this.attendanceHeader()).pipe(map(function (response) { return response; }));
    };
    AttendanceService.prototype.justification = function (params) {
        return this.http.post(this.globalService.apiVersionHost + API_JUSTIFICATION, params, this.attendanceHeader()).pipe(map(function (response) { return response; }));
    };
    AttendanceService.prototype.checkJustification = function (params) {
        return this.http.post(this.globalService.apiVersionHost + API_CHECK_JUSTIFICATION, params, this.attendanceHeader()).pipe(map(function (response) { return response; }));
    };
    AttendanceService.prototype.checkAttendance = function () {
        var headers = {
            headers: new HttpHeaders()
                .set('token', this.cookieService.get('_q'))
        };
        return this.http.post(this.globalService.apiVersionHost + API_CHECK_ATTENDANCE, null, headers).pipe(map(function (response) { return response; }));
    };
    AttendanceService.prototype.getHomeAll = function (param) {
        return this.http.post(this.globalService.apiVersionHost + API_HOME, param, this.attendanceHeader()).pipe(map(function (response) { return response; }));
    };
    AttendanceService.prototype.alertModal = function (err, code) {
        if (code === 403) {
            return Swal.fire(err);
        }
        var modalRef = this.modalService.open(AlertModalComponent);
        modalRef.componentInstance.kategori = 'alert';
        modalRef.componentInstance.error = (err) ? err : 'Request Timeout.';
    };
    AttendanceService.prototype.getHomeDetail = function (param) {
        return this.http.post(this.globalService.apiVersionHost + API_HOME_DETAIL, param, this.attendanceHeader()).pipe(map(function (response) { return response; }));
    };
    AttendanceService.prototype.getViewUpload = function (param) {
        return this.http.post(this.globalService.apiVersionHost + API_VIEW_UPLOAD, param, this.attendanceHeader()).pipe(map(function (response) { return response; }));
    };
    AttendanceService.prototype.changeStatus = function (param) {
        return this.http.post(this.globalService.apiVersionHost + API_CHANGE_STATUS, param, this.attendanceHeader()).pipe(map(function (response) { return response; }));
    };
    AttendanceService.prototype.getUsersHome = function (param) {
        return this.http.post(this.globalService.apiVersionHost + API_USERS_HOME, param, this.attendanceHeader()).pipe(map(function (response) { return response; }));
    };
    AttendanceService.prototype.approveHome = function () {
        return this.http.post(this.globalService.apiVersionHost + API_APPROVE_HOME, null, this.attendanceHeader()).pipe(map(function (response) { return response; }));
    };
    AttendanceService.prototype.getNoClockOut = function (param) {
        return this.http.post(this.globalService.apiVersionHost + API_NOCLOCKOUT, param, this.attendanceHeader()).pipe(map(function (response) { return response; }));
    };
    AttendanceService.prototype.noClockOutExcel = function (param) {
        return this.http.post(this.globalService.apiVersionHost + API_NOCLOCKOUTEXCEL, param, this.attendanceHeader()).pipe(map(function (response) { return response; }));
    };
    AttendanceService.prototype.getJustificationRequest = function (param) {
        return this.http.post(this.globalService.apiVersionHost + API_JUSTIFICATION_REQUEST, param, this.attendanceHeader()).pipe(map(function (response) { return response; }));
    };
    AttendanceService.prototype.exportJustificationRequest = function (param) {
        return this.http.post(this.globalService.apiVersionHost + API_EXPORT_JUSTIFICATION_REQUEST, param, this.attendanceHeader()).pipe(map(function (response) { return response; }));
    };
    AttendanceService.prototype.getJustificationRequestProgress = function (param) {
        return this.http.post(this.globalService.apiVersionHost + API_JUSTIFICATION_REQUEST_PROGRESS, param, this.attendanceHeader()).pipe(map(function (response) { return response; }));
    };
    AttendanceService.prototype.listAttendancePoint = function (param) {
        return this.http.post(this.globalService.apiVersionHost + '/attendance/default/list-attendance-point', param, this.attendanceHeader()).pipe(map(function (response) { return response; }));
    };
    AttendanceService.prototype.listApprovalAttendancePoint = function (param) {
        return this.http.post(this.globalService.apiVersionHost + '/attendance/default/list-approval-attendance-point', param, this.attendanceHeader()).pipe(map(function (response) { return response; }));
    };
    AttendanceService.prototype.getAttendancePointProgress = function (param) {
        return this.http.post(this.globalService.apiVersionHost + "/attendance/default/attendance-point-progress", param, this.attendanceHeader()).pipe(map(function (response) { return response; }));
    };
    AttendanceService.prototype.newAttendancePoint = function (param) {
        return this.http.post(this.globalService.apiVersionHost + "/attendance/default/new-attendance-point", param, this.attendanceHeader()).pipe(map(function (response) { return response; }));
    };
    AttendanceService.prototype.detailAttendancePoint = function (param) {
        return this.http.post(this.globalService.apiVersionHost + "/attendance/default/attendance-point-detail", param, this.attendanceHeader()).pipe(map(function (response) { return response; }));
    };
    AttendanceService.prototype.approvalAttendancePoint = function (param) {
        return this.http.post(this.globalService.apiVersionHost + "/attendance/default/attendance-point-approval", param, this.attendanceHeader()).pipe(map(function (response) { return response; }));
    };
    AttendanceService.prototype.getUnpaidLeaveRequestProgress = function (param) {
        return this.http.post(this.globalService.apiVersionHost + API_UNPAID_LEAVE_REQUEST_PROGRESS, param, this.attendanceHeader()).pipe(map(function (response) { return response; }));
    };
    AttendanceService.prototype.approvalUnpaidLeave = function (id, reject_note, type) {
        return this.http.post(this.globalService.apiVersionHost + API_APPROVAL_UNPAID_LEAVE, { id: id, reject_note: reject_note, type: type }, this.attendanceHeader()).pipe(map(function (response) { return response; }));
    };
    AttendanceService.prototype.exportTrackerUnpaidLeave = function (param) {
        return this.http.post(this.globalService.apiVersionHost + API_EXPORT_UNPAID_LEAVE, param, this.attendanceHeader()).pipe(map(function (response) { return response; }));
    };
    AttendanceService.prototype.getJustificationRequestDetail = function (param) {
        return this.http.post(this.globalService.apiVersionHost + API_JUSTIFICATION_REQUEST_DETAIL, param, this.attendanceHeader()).pipe(map(function (response) { return response; }));
    };
    AttendanceService.prototype.submitJustificationRequest = function (param) {
        return this.http.post(this.globalService.apiVersionHost + API_SUBMIT_JUSTIFICATION_REQUEST, param, this.attendanceHeader()).pipe(map(function (response) { return response; }));
    };
    AttendanceService.prototype.getJustificationApproval = function (param) {
        return this.http.post(this.globalService.apiVersionHost + API_JUSTIFICATION_APPROVAL, param, this.attendanceHeader()).pipe(map(function (response) { return response; }));
    };
    AttendanceService.prototype.exportJustificationApproval = function (param) {
        return this.http.post(this.globalService.apiVersionHost + API_EXPORT_JUSTIFICATION_APPROVAL, param, this.attendanceHeader()).pipe(map(function (response) { return response; }));
    };
    AttendanceService.prototype.submitJustificationApproval = function (param) {
        return this.http.post(this.globalService.apiVersionHost + API_SUBMIT_JUSTIFICATION_APPROVAL, param, this.attendanceHeader()).pipe(map(function (response) { return response; }));
    };
    AttendanceService.prototype.approveAll = function (param) {
        return this.http.post(this.globalService.apiVersionHost + API_APPROVAL_ALL, param, this.attendanceHeader()).pipe(map(function (response) { return response; }));
    };
    AttendanceService.prototype.importOfficelist = function (param) {
        return this.http.post(this.globalService.apiVersionHost + API_IMPORT_OFFICELIST, param, this.attendanceHeader()).pipe(map(function (response) { return response; }));
    };
    AttendanceService.prototype.getPictureAttendance = function (param) {
        return this.http.post(this.globalService.apiVersionHost + API_PICTURE_ATTENDANCE, param, this.attendanceHeader()).pipe(map(function (response) { return response; }));
    };
    AttendanceService.prototype.getWfo = function (param) {
        return this.http.post(this.globalService.apiVersionHost + '/attendance/default/get-wfo', param, this.attendanceHeader()).pipe(map(function (response) { return response; }));
    };
    AttendanceService.prototype.exportWfo = function (param) {
        return this.http.post(this.globalService.apiVersionHost + '/attendance/default/export-wfo', param, this.attendanceHeader()).pipe(map(function (response) { return response; }));
    };
    AttendanceService.prototype.getMyTeamPreview = function (param) {
        return this.http.post(this.globalService.apiVersionHost + API_GET_MY_TEAM_PREVIEW, param, this.attendanceHeader()).pipe(map(function (response) { return response; }));
    };
    AttendanceService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AttendanceService_Factory() { return new AttendanceService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.GlobalService), i0.ɵɵinject(i3.CookieService), i0.ɵɵinject(i4.NgbModal)); }, token: AttendanceService, providedIn: "root" });
    return AttendanceService;
}());
export { AttendanceService };
