/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./forbidden.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./forbidden.component";
var styles_ForbiddenComponent = [i0.styles];
var RenderType_ForbiddenComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ForbiddenComponent, data: {} });
export { RenderType_ForbiddenComponent as RenderType_ForbiddenComponent };
export function View_ForbiddenComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 13, "div", [["class", "kt-grid kt-grid--ver kt-grid--root full-height"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 12, "div", [["class", "kt-grid__item kt-grid__item--fluid kt-grid  kt-error-v5"], ["style", "background-image: url(./assets/media//error/bg5.jpg);"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 11, "div", [["class", "kt-error_container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 2, "span", [["class", "kt-error_title"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "h1", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Oops!"])), (_l()(), i1.ɵeld(6, 0, null, null, 1, "p", [["class", "kt-error_subtitle"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" You dont have permission to access this page. "])), (_l()(), i1.ɵeld(8, 0, null, null, 5, "p", [["class", "kt-error_description"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Please contact NDS Customer Service "])), (_l()(), i1.ɵeld(10, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" via email [dev.nexwave@gmail.com] "])), (_l()(), i1.ɵeld(12, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" for more information. "]))], null, null); }
export function View_ForbiddenComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-forbidden", [], null, null, null, View_ForbiddenComponent_0, RenderType_ForbiddenComponent)), i1.ɵdid(1, 114688, null, 0, i2.ForbiddenComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ForbiddenComponentNgFactory = i1.ɵccf("app-forbidden", i2.ForbiddenComponent, View_ForbiddenComponent_Host_0, {}, {}, []);
export { ForbiddenComponentNgFactory as ForbiddenComponentNgFactory };
