import { Component, OnInit } from '@angular/core';
import { LandingpageService } from 'src/app/_services/landingpage.service';

@Component({
  selector: 'app-blog5g',
  templateUrl: './blog5g.component.html',
  styleUrls: ['./blog5g.component.scss']
})
export class Blog5gComponent implements OnInit {

  blogs: any;
  categories: any;
  populars: any;
  page = 1;
  pageSize = 5;
  pageLength = 0;
  hideLoader = true;
  lastData = false;

  constructor(
    private landing: LandingpageService
  ) { }

  ngOnInit() {
    this.loadData();
  }

  loadData(isPrev = false) {
    this.landing.getBlogList({pageSize: this.pageSize, page: this.page})
      .subscribe(arg => {
        if (arg.status) {
          this.blogs = arg.result.row.blog;
          this.categories = arg.result.row.category;
          this.populars = arg.result.row.popular;
          if (!isPrev) {
            this.pageLength += this.blogs.length;
          }
          this.lastData = (this.pageLength === arg.result.total);
        } else {
          this.landing.alertModal(arg.message);
        }
        this.hideLoader = true;
      }, error => {
        this.landing.alertModal(error.error.message, error.error.status_code);
        this.hideLoader = true;
      });
  }

  previousPage() {
    this.hideLoader = false;
    this.page -= 1;
    this.loadData(true);
  }
  
  nextPage() {
    this.hideLoader = false;
    this.page += 1;
    this.loadData();
  }

}
