import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { CookieService } from 'ngx-cookie-service';
import { GlobalService } from './global.service';
import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

import { ResponseApiModel } from '../_models/response-api.model';
import Swal from 'sweetalert2';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AlertModalComponent } from '../layout/shared/alert-modal/alert-modal.component';

const API_ALL_VACANCY = '/vacancy/guest/all-vacancy';
const API_SAVE_REGISTRATION = '/vacancy/guest/save-registration';
const API_TEMPLATE_SKILL = '/vacancy/guest/template-skill';
const API_UPDATE_SKILL = '/vacancy/guest/update-skill';
const API_SUBMIT_SKILL = '/vacancy/guest/submit-skill';

const API_GET_BODY_CONTENT = '/landing/get-body-content';
const API_GET_HEADER_CONTENT = '/landing/get-header-content';
const API_GET_FOOTER_CONTENT = '/landing/get-footer-content';
const API_GET_GALLERY_CONTENT = '/landing/get-gallery';

const API_PREPAID_EXTERNAL = '/simcard/guest/get-prepaid';

const API_CEK_WA = '/wa/cek-wa';
const API_VERIFICATION = '/wa/verified';

const API_BLOG_LIST = '/blog/guest/all-blog';
const API_BLOG_CATEGORY = '/blog/guest/category-blog';
const API_BLOG_DETAIL = '/blog/guest/detail-blog';

const API_FAQ = '/faq/guest/list-faq';
const API_POST_FAQ = '/faq/guest/post-faq';

const API_BEFORE_EXAM = '/eLearning/guest/view-before-startexam';
const API_DOING_EXAM = '/eLearning/guest/doing-exam';
const API_JAWAB = '/eLearning/guest/add-result-exam';
const API_SUBMIT_VOUCHER = '/eLearning/guest/submit-voucher';
const API_JAWAB_VIDEO = '/eLearning/video-exam/add-result-exam';
const API_JAWAB_PREPOST = '/eLearning/video-exam/add-result-pre-post';
const API_GET_JAWAB_PREPOST = '/eLearning/video-exam/get-result';

const API_VIEW_VIDEO_EXAM = '/eLearning/guest/view';
const API_JAWAB_PREPOST_LANDING = '/eLearning/guest/add-result-pre-post';
const API_JAWAB_VIDEO_LANDING = '/eLearning/guest/add-result-exam-video';
@Injectable()
export class LandingpageService {

  constructor(
    private http: HttpClient,
    private cookieService: CookieService,
    private globalService: GlobalService,
    private modalService: NgbModal,
  ) { }

  public header() {
    return {
      headers: new HttpHeaders()
        .set('token', this.cookieService.get('_q'))
        .set('module', localStorage.getItem('eLearning'))
    };
  }

  alertModal(err: string, code?: number) {
    if (code === 403) {
      return Swal.fire(err);
    }
    const modalRef = this.modalService.open(AlertModalComponent);
    modalRef.componentInstance.kategori = 'alert';
    modalRef.componentInstance.error = (err) ? err : 'Request Timeout.';
  }

  // api vacancy
  public getAllVacancyLanding(param: { pageSize: number, page: number, filter: any }): Observable<ResponseApiModel> {
    return this.http.post<ResponseApiModel>(
      this.globalService.apiVersionHost + API_ALL_VACANCY, param
    ).pipe(
      map(response => response)
    );
  }

  public saveRegistration(param): Observable<ResponseApiModel> {
    return this.http.post<ResponseApiModel>(
      this.globalService.apiVersionHost + API_SAVE_REGISTRATION, param
    ).pipe(
      map(response => response)
    );
  }
  public templateSkill(param: { id: number }): Observable<ResponseApiModel> {
    return this.http.post<ResponseApiModel>(
      this.globalService.apiVersionHost + API_TEMPLATE_SKILL, param
    ).pipe(
      map(response => response),
      catchError(err => {
        return throwError(err);
      })
    );
  }
  // public updateSkill(param: { data: any, id: any }): Observable<ResponseApiModel> {
  //   return this.http.post<ResponseApiModel>(
  //     this.globalService.apiVersionHost + API_UPDATE_SKILL, param
  //   ).pipe(
  //     map(response => response),
  //     catchError(err => {
  //       return throwError(err);
  //     })
  //   );
  // }
  public submitSkill(param: { id: number, skills: any }): Observable<ResponseApiModel> {
    return this.http.post<ResponseApiModel>(
      this.globalService.apiVersionHost + API_SUBMIT_SKILL, param
    ).pipe(
      map(response => response),
      catchError(err => {
        return throwError(err);
      })
    );
  }

  public getBodyContent(): Observable<ResponseApiModel> {
    return this.http.post<ResponseApiModel>(
      this.globalService.apiVersionHost + API_GET_BODY_CONTENT, null
    ).pipe(
      map(response => response)
    );
  }
  public getHeaderContent(): Observable<ResponseApiModel> {
    return this.http.post<ResponseApiModel>(
      this.globalService.apiVersionHost + API_GET_HEADER_CONTENT, null
    ).pipe(
      map(response => response)
    );
  }
  public getFooterContent(): Observable<ResponseApiModel> {
    return this.http.post<ResponseApiModel>(
      this.globalService.apiVersionHost + API_GET_FOOTER_CONTENT, null
    ).pipe(
      map(response => response)
    );
  }
  public getGalleryContent(params: any): Observable<ResponseApiModel> {
    return this.http.post<ResponseApiModel>(
      this.globalService.apiVersionHost + API_GET_GALLERY_CONTENT, params
    ).pipe(
      map(response => response)
    );
  }

  // api simcard
  public getPrepaidDetail(id): Observable<ResponseApiModel> {
    return this.http.post<ResponseApiModel>(
      this.globalService.apiVersionHost + API_PREPAID_EXTERNAL, { id }
    ).pipe(
      map(response => response),
      catchError(err => {
        return throwError(err);
      })
    );
  }

  // api blog
  public getBlogList(param): Observable<ResponseApiModel> {
    return this.http.post<ResponseApiModel>(
      this.globalService.apiVersionHost + API_BLOG_LIST, param
    ).pipe(
      map(response => response),
      catchError(err => {
        return throwError(err);
      })
    );
  }
  public getBlogCategory(param): Observable<ResponseApiModel> {
    return this.http.post<ResponseApiModel>(
      this.globalService.apiVersionHost + API_BLOG_CATEGORY, param
    ).pipe(
      map(response => response),
      catchError(err => {
        return throwError(err);
      })
    );
  }
  public getBlogDetail(param): Observable<ResponseApiModel> {
    return this.http.post<ResponseApiModel>(
      this.globalService.apiVersionHost + API_BLOG_DETAIL, param
    ).pipe(
      map(response => response),
      catchError(err => {
        return throwError(err);
      })
    );
  }

  // Update WA
  public cekWa(param: { x: string; id: number }): Observable<ResponseApiModel> {
    return this.http.post<ResponseApiModel>(
      this.globalService.apiVersionHost + API_CEK_WA, param
    ).pipe(
      map(response => response)
    );
  }
  public verificationWa(param: { x: string; id: number; nik: string }): Observable<ResponseApiModel> {
    return this.http.post<ResponseApiModel>(
      this.globalService.apiVersionHost + API_VERIFICATION, param
    ).pipe(
      map(response => response)
    );
  }

  // api faq
  public getFaqLanding(param: { filter: any }): Observable<ResponseApiModel> {
    return this.http.post<ResponseApiModel>(
      this.globalService.apiVersionHost + API_FAQ, param
    ).pipe(
      map(response => response)
    );
  }
  public getPostFaqLanding(param): Observable<ResponseApiModel> {
    return this.http.post<ResponseApiModel>(
      this.globalService.apiVersionHost + API_POST_FAQ, param
    ).pipe(
      map(response => response)
    );
  }

  // api exam survey
  public beforeExam(voucher): Observable<ResponseApiModel> {
    return this.http.post<ResponseApiModel>(
      this.globalService.apiVersionHost + API_BEFORE_EXAM, { voucher }
    ).pipe(
      map(response => response),
      catchError(err => {
        return throwError(err);
      })
    );
  }
  public doingExam(voucher): Observable<ResponseApiModel> {
    return this.http.post<ResponseApiModel>(
      this.globalService.apiVersionHost + API_DOING_EXAM, { voucher }
    ).pipe(
      map(response => response),
      catchError(err => {
        return throwError(err);
      })
    );
  }
  public saveAnswer(param): Observable<ResponseApiModel> {
    return this.http.post<ResponseApiModel>(
      this.globalService.apiVersionHost + API_JAWAB, param
    ).pipe(
      map(response => response),
      catchError(err => {
        return throwError(err);
      })
    );
  }

  public submitVoucher(param: { voucher: string }): Observable<ResponseApiModel> {
    return this.http.post<ResponseApiModel>(
      this.globalService.apiVersionHost + API_SUBMIT_VOUCHER, param
    ).pipe(
      map(response => response),
      catchError(err => {
        return throwError(err);
      })
    );
  }

  public saveVideoAnswer(param): Observable<ResponseApiModel> {
    return this.http
      .post<ResponseApiModel>(
        this.globalService.apiVersionHost + API_JAWAB_VIDEO,
        param,
        this.header()
      )
      .pipe(map(response => response));
  }

  public savePrePostAnswer(param): Observable<ResponseApiModel> {
    return this.http
      .post<ResponseApiModel>(
        this.globalService.apiVersionHost + API_JAWAB_PREPOST,
        param,
        this.header()
      )
      .pipe(map(response => response));
  }

  // api prepost result
  public getPrePostResult(param: { pageSize: number, page: number, filter: any, id_ujian: number }): Observable<ResponseApiModel> {
    return this.http
      .post<ResponseApiModel>(
        this.globalService.apiVersionHost + API_GET_JAWAB_PREPOST, param,
        this.header()
      )
      .pipe(map(response => response));
  }

  public viewVideoExam(param:{id: number,voucher:string}): Observable<ResponseApiModel> {
    return this.http.post<ResponseApiModel>(
      this.globalService.apiVersionHost + API_VIEW_VIDEO_EXAM, param
    ).pipe(
      map(response => response),
      catchError(err => {
        return throwError(err);
      })
    );
  }
  public savePrePostAnswerLanding(param): Observable<ResponseApiModel> {
    return this.http
      .post<ResponseApiModel>(
        this.globalService.apiVersionHost + API_JAWAB_PREPOST_LANDING,
        param
      )
      .pipe(map(response => response));
  }
  public saveVideoAnswerLanding(param): Observable<ResponseApiModel> {
    return this.http
      .post<ResponseApiModel>(
        this.globalService.apiVersionHost + API_JAWAB_VIDEO_LANDING,
        param
      )
      .pipe(map(response => response));
  }
}
