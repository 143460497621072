import { Component, OnInit, ViewChild } from '@angular/core';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ActivatedRoute, Router } from '@angular/router';
import { SignaturePad } from 'angular2-signaturepad';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UsersManagementService } from 'src/app/_services/users-management.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-signature',
  templateUrl: './signature.component.html',
  styleUrls: ['./signature.component.scss']
})
export class SignatureComponent implements OnInit {

  access_token: string;
  
  show_message: boolean;
  show_form: boolean;

  signatureImg: string;

  @ViewChild(SignaturePad, { static: false }) signaturePad: SignaturePad;

  signaturePadOptions: Object = {
    'minWidth': 2,
    'canvasWidth': 450,
    'canvasHeight': 300,
  };

  modal: any;
  closeResult = '';
  message = '';

  formData: any = {};

  constructor(
    private router: Router,
    private ngxLoader: NgxUiLoaderService,
    private route: ActivatedRoute,
    private umService: UsersManagementService,
  ) { }

  ngOnInit() {
    
  }

  drawComplete(event, datasignature) {
    this.formData['signature'] = datasignature.toDataURL().replace('data:image/png;base64,', '');
  }

  submit(datasignature){
    this.access_token = this.route.snapshot.paramMap.get('access_token');
    this.formData.signature = datasignature.toDataURL().replace('data:image/png;base64,', '');
    this.formData.userhash = this.route.snapshot.paramMap.get('access_token');
    this.umService.saveSignatureUsers(this.formData).subscribe(
      response => {
        if (response.status) {
          this.message = response.message;
          Swal.fire({
            title: 'User Signature',
            type: 'info',
            text: response.message
          });
          datasignature.clear();
          this.router.navigate(['/main/pm']);
        }else{
          Swal.fire({
            title: 'User Signature',
            type: 'warning',
            text: response.message
          });
        }

        
        //this.vcService.alertModal(response.message);
      }, error => {
        Swal.fire({
          title: 'User Signature',
          type: 'warning',
          text: error.error

        });
      }
    );
    console.log('nice');
    
  }

  clear(datasignature){
    datasignature.clear();
  }

}
