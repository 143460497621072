import { map } from "rxjs/operators";
import { Subject } from "rxjs";
import { CookieService } from "ngx-cookie-service";
import { GlobalService } from "src/app/_services/global.service";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "./global.service";
import * as i3 from "ngx-cookie-service";
var API_PMC_DASHBOARD = "/pmc/dashboard/get-dashboard";
var API_PMC_GET_PROJECT_ITEM = "/pmc/dashboard/get-project-item";
var API_PMC_GET_FEEDS = "/pmc/feed/get-feeds";
var API_PMC_PREPARE_CREATE = "/pmc/item/prepare-create";
var API_PMC_SAVE_ITEM = "/pmc/item/save-item";
var API_PMC_SAVE_SPRINT = "/pmc/item/save-sprint";
var API_PMC_GET_MY_TASKS = "/pmc/my-task/get-my-tasks";
var API_PMC_GET_TASKS_FRONT = "/pmc/my-task/get-tasks-front";
var API_PMC_GET_TASKS_FRONT_NEW = "/pmc/my-task/get-tasks-front-new";
var API_PMC_GET_MY_APPROVALS = "/pmc/my-approval/get-my-approvals";
var API_PMC_GET_CLASSIC = "/pmc/classic/prepare";
var API_PMC_CHANGE_STATUS = "/pmc/classic/change-status";
var API_PMC_GET_TREE = "/pmc/tree-view/get-project-items";
var API_PMC_GET_DOCUMENTS = "/pmc/documents/get-documents";
var API_PMC_GET_ISSUES = "/pmc/issue/get-issues";
var API_PMC_GET_KANBAN = "/pmc/kanban/get-kanban";
var API_PMC_GET_TABULAR = "/pmc/tabular/get-tabular";
var API_PMC_SAVE_FOLDER = "/pmc/documents/save-folder";
var API_PMC_ATTENDANCE_VS_TASK = "/pmc/report/attendance-vs-task";
var API_PMC_GANTT_CHART = "/pmc/report/gantt-chart";
var API_PMC_GANTT = "/pmc/gantt/gantt-data";
var API_PMC_GET_CUSTOM_FIELDS = "/pmc/item/get-custom-fields";
var API_PMC_GET_ROOT = "/pmc/navigation/get-root";
var API_PMC_GET_CHILD = "/pmc/navigation/get-child";
var API_PMC_GET_PATH = "/pmc/navigation/get-path";
var API_PMC_LOAD_CHILDS = "/pmc/classic/load-childs";
var API_PMC_LOAD_MORE_CHILDS = "/pmc/classic/load-more-childs";
var API_PMC_LOAD_ISSUES = "/pmc/classic/load-issues";
var API_PMC_LOAD_MORE_ISSUES = "/pmc/classic/load-more-issues";
var API_PMC_CUSTOM_REPORT = "/pmc/report/custom-report";
var API_PMC_GET_USER_PROJECT = "/pmc/classic/get-user-project";
var API_PMC_GET_DASHBOARD_PROJECT = "/pmc/dashboard-project/get-dashboard";
var API_PMC_UPDATE_PIC_MEMBER = "/pmc/classic/update-pic-member";
var API_PMC_GET_HIRARKI = "/pmc/classic/get-hirarki";
var API_PMC_ADD_DOCUMENT = "/pmc/documents/add-document";
var API_PMC_DELETE_DOCUMENT = "/pmc/documents/delete-document";
var PmcService = /** @class */ (function () {
    function PmcService(http, globalService, cookieService) {
        this.http = http;
        this.globalService = globalService;
        this.cookieService = cookieService;
        this.reloadDataEmit = new Subject();
        this.reloadDataEmitObs = this.reloadDataEmit.asObservable();
    }
    PmcService.prototype.header = function () {
        return {
            headers: new HttpHeaders()
                .set('token', this.cookieService.get('_q'))
                .set('module', localStorage.getItem('pmc'))
        };
    };
    PmcService.prototype.getDashboard = function () {
        return this.http
            .post(this.globalService.apiVersionHost + API_PMC_DASHBOARD, null, this.header())
            .pipe(map(function (response) { return response; }));
    };
    PmcService.prototype.getProjectItem = function (id) {
        return this.http
            .post(this.globalService.apiVersionHost + API_PMC_GET_PROJECT_ITEM, { id: id }, this.header())
            .pipe(map(function (response) { return response; }));
    };
    PmcService.prototype.getFeeds = function (param) {
        return this.http
            .post(this.globalService.apiVersionHost + API_PMC_GET_FEEDS, param, this.header())
            .pipe(map(function (response) { return response; }));
    };
    PmcService.prototype.getMyTasks = function (param) {
        return this.http
            .post(this.globalService.apiVersionHost + API_PMC_GET_MY_TASKS, param, this.header())
            .pipe(map(function (response) { return response; }));
    };
    PmcService.prototype.getTasksFront = function (param) {
        return this.http
            .post(this.globalService.apiVersionHost + API_PMC_GET_TASKS_FRONT, param, this.header())
            .pipe(map(function (response) { return response; }));
    };
    PmcService.prototype.getTasksFrontNew = function (param) {
        return this.http
            .post(this.globalService.apiVersionHost + API_PMC_GET_TASKS_FRONT_NEW, param, this.header())
            .pipe(map(function (response) { return response; }));
    };
    PmcService.prototype.getMyApprovals = function (param) {
        return this.http
            .post(this.globalService.apiVersionHost + API_PMC_GET_MY_APPROVALS, param, this.header())
            .pipe(map(function (response) { return response; }));
    };
    PmcService.prototype.getClassic = function (param) {
        return this.http
            .post(this.globalService.apiVersionHost + API_PMC_GET_CLASSIC, param, this.header())
            .pipe(map(function (response) { return response; }));
    };
    PmcService.prototype.prepareCreate = function (param) {
        return this.http
            .post(this.globalService.apiVersionHost + API_PMC_PREPARE_CREATE, param, this.header())
            .pipe(map(function (response) { return response; }));
    };
    PmcService.prototype.saveItem = function (param) {
        return this.http
            .post(this.globalService.apiVersionHost + API_PMC_SAVE_ITEM, param, this.header())
            .pipe(map(function (response) { return response; }));
    };
    PmcService.prototype.saveSprint = function (param) {
        return this.http
            .post(this.globalService.apiVersionHost + API_PMC_SAVE_SPRINT, param, this.header())
            .pipe(map(function (response) { return response; }));
    };
    PmcService.prototype.changeStatus = function (param) {
        return this.http
            .post(this.globalService.apiVersionHost + API_PMC_CHANGE_STATUS, param, this.header())
            .pipe(map(function (response) { return response; }));
    };
    PmcService.prototype.getTree = function (param) {
        return this.http
            .post(this.globalService.apiVersionHost + API_PMC_GET_TREE, param, this.header())
            .pipe(map(function (response) { return response; }));
    };
    PmcService.prototype.getDocuments = function (param) {
        return this.http
            .post(this.globalService.apiVersionHost + API_PMC_GET_DOCUMENTS, param, this.header())
            .pipe(map(function (response) { return response; }));
    };
    PmcService.prototype.getIssues = function (param) {
        return this.http
            .post(this.globalService.apiVersionHost + API_PMC_GET_ISSUES, param, this.header())
            .pipe(map(function (response) { return response; }));
    };
    PmcService.prototype.getFileInfo = function (url) {
        return this.http
            .get(url, { responseType: 'arraybuffer' })
            .pipe(map(function (response) { return response; }));
    };
    PmcService.prototype.getKanban = function (param) {
        return this.http
            .post(this.globalService.apiVersionHost + API_PMC_GET_KANBAN, param, this.header())
            .pipe(map(function (response) { return response; }));
    };
    PmcService.prototype.getTabular = function (param) {
        return this.http
            .post(this.globalService.apiVersionHost + API_PMC_GET_TABULAR, param, this.header())
            .pipe(map(function (response) { return response; }));
    };
    PmcService.prototype.saveFolder = function (param) {
        return this.http
            .post(this.globalService.apiVersionHost + API_PMC_SAVE_FOLDER, param, this.header())
            .pipe(map(function (response) { return response; }));
    };
    PmcService.prototype.ganttChart = function (param) {
        return this.http
            .post(this.globalService.apiVersionHost + API_PMC_GANTT_CHART, param, this.header())
            .pipe(map(function (response) { return response; }));
    };
    PmcService.prototype.getGantt = function (param) {
        return this.http
            .post(this.globalService.apiVersionHost + API_PMC_GANTT, param, this.header())
            .pipe(map(function (response) { return response; }));
    };
    PmcService.prototype.getAttendanceVsTask = function (param) {
        return this.http
            .post(this.globalService.apiVersionHost + API_PMC_ATTENDANCE_VS_TASK, param, this.header())
            .pipe(map(function (response) { return response; }));
    };
    PmcService.prototype.getCustomFields = function (param) {
        return this.http
            .post(this.globalService.apiVersionHost + API_PMC_GET_CUSTOM_FIELDS, param, this.header())
            .pipe(map(function (response) { return response; }));
    };
    PmcService.prototype.getRootItem = function () {
        return this.http
            .post(this.globalService.apiVersionHost + API_PMC_GET_ROOT, null, this.header())
            .pipe(map(function (response) { return response; }));
    };
    PmcService.prototype.getChildItem = function (param) {
        return this.http
            .post(this.globalService.apiVersionHost + API_PMC_GET_CHILD, param, this.header())
            .pipe(map(function (response) { return response; }));
    };
    PmcService.prototype.getPathItem = function (param) {
        return this.http
            .post(this.globalService.apiVersionHost + API_PMC_GET_PATH, param, this.header())
            .pipe(map(function (response) { return response; }));
    };
    PmcService.prototype.loadChilds = function (param) {
        return this.http
            .post(this.globalService.apiVersionHost + API_PMC_LOAD_CHILDS, param, this.header())
            .pipe(map(function (response) { return response; }));
    };
    PmcService.prototype.loadMoreChilds = function (param) {
        return this.http
            .post(this.globalService.apiVersionHost + API_PMC_LOAD_MORE_CHILDS, param, this.header())
            .pipe(map(function (response) { return response; }));
    };
    PmcService.prototype.loadIssues = function (param) {
        return this.http
            .post(this.globalService.apiVersionHost + API_PMC_LOAD_ISSUES, param, this.header())
            .pipe(map(function (response) { return response; }));
    };
    PmcService.prototype.loadMoreIssues = function (param) {
        return this.http
            .post(this.globalService.apiVersionHost + API_PMC_LOAD_MORE_ISSUES, param, this.header())
            .pipe(map(function (response) { return response; }));
    };
    PmcService.prototype.getCustomReport = function (param) {
        return this.http
            .post(this.globalService.apiVersionHost + API_PMC_CUSTOM_REPORT, param, this.header())
            .pipe(map(function (response) { return response; }));
    };
    PmcService.prototype.getUserProject = function (param) {
        return this.http
            .post(this.globalService.apiVersionHost + API_PMC_GET_USER_PROJECT, param, this.header())
            .pipe(map(function (response) { return response; }));
    };
    PmcService.prototype.getDashboardProject = function (param) {
        return this.http
            .post(this.globalService.apiVersionHost + API_PMC_GET_DASHBOARD_PROJECT, param, this.header())
            .pipe(map(function (response) { return response; }));
    };
    PmcService.prototype.updatePicMember = function (param) {
        return this.http
            .post(this.globalService.apiVersionHost + API_PMC_UPDATE_PIC_MEMBER, param, this.header())
            .pipe(map(function (response) { return response; }));
    };
    PmcService.prototype.getHirarki = function (param) {
        return this.http
            .post(this.globalService.apiVersionHost + API_PMC_GET_HIRARKI, param, this.header())
            .pipe(map(function (response) { return response; }));
    };
    PmcService.prototype.addDocument = function (param) {
        return this.http
            .post(this.globalService.apiVersionHost + API_PMC_ADD_DOCUMENT, param, this.header())
            .pipe(map(function (response) { return response; }));
    };
    PmcService.prototype.deleteDocument = function (param) {
        return this.http
            .post(this.globalService.apiVersionHost + API_PMC_DELETE_DOCUMENT, param, this.header())
            .pipe(map(function (response) { return response; }));
    };
    PmcService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function PmcService_Factory() { return new PmcService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.GlobalService), i0.ɵɵinject(i3.CookieService)); }, token: PmcService, providedIn: "root" });
    return PmcService;
}());
export { PmcService };
