import { FirebaseMessage } from './../_models/firebase_message.model';
import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFireMessaging } from '@angular/fire/messaging';
import { IndexedDbService } from 'src/app/_services/indexed-db.service';
import { take } from 'rxjs/operators';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import Swal from 'sweetalert2';
import { ResponseApiModel } from '../_models/response-api.model';
import { GlobalService } from './global.service';
import { CookieService } from 'ngx-cookie-service';
import { map } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BrowserService } from './browser.service';

const API_SAVE_TOKEN = '/auth/save-token';

@Injectable({
  providedIn: 'root'
})
export class MessagingService {

  public loadNotificationEmit: Subject<boolean> = new Subject<boolean>();
  public loadNotificationEmitObs = this.loadNotificationEmit.asObservable();
  currentToken: string;

  constructor(
    private angularFireAuth: AngularFireAuth,
    private angularFireMessaging: AngularFireMessaging,
    private idbService: IndexedDbService,
    private http: HttpClient,
    private globalService: GlobalService,
    private cookieService: CookieService,
    private browserService: BrowserService
  ) {
    this.angularFireMessaging.messaging.subscribe(
      (_messaging) => {
        _messaging.onMessage = _messaging.onMessage.bind(_messaging);
        _messaging.onTokenRefresh = _messaging.onTokenRefresh.bind(_messaging);
      }
    );
  }

  /**
   * update token in firebase database
   *
   * @param userId userId as a key
   * @param token token as a value
   */
  updateToken(userId, token) {
    // we can change this function to request our backend service
    this.angularFireAuth.authState.pipe(take(1)).subscribe(
      () => {
        const data = {};
        data[userId] = token;
      });
  }

  /**
   * request permission for notification from firebase cloud messaging
   *
   * @param userId userId
   */
  requestPermission(userId, isReload?: boolean) {
    this.angularFireMessaging.requestToken.subscribe(
      (token) => {
        this.currentToken = token;

        this.updateToken(userId, token);
        this.saveToken(token).subscribe(response => {
          if (!response.status){
            console.log(response);
          }
        }, error => {
          console.log(error);
        });
      }, (err) => {
        if (isReload) {
          Swal.fire('Please reload browser (Ctrl + R)\nThank you');
        }
        console.log('Unable to get permission to notify.', err);
      }
    );
  }

  /**
	 * Get access token
	 */
  public getFcmToken() {
    return this.currentToken;
  }

  /**
   * hook method when new notification received in foreground
   */
  receiveMessage() {
    this.angularFireMessaging.messages.subscribe(
      (payload: FirebaseMessage) => {
        // console.log('new message received. ', (payload.data.globalNotif) ? true : false);
        // syncronization here
        (payload.data.globalNotif === 'true') ? this.cookNotif(payload.data) : this.synchronization(payload.data);
      });
  }

  cookNotif(data) {
    const tempNotif = JSON.parse(data.notification);
    Swal.fire(tempNotif.title + '\nPlease check your notification bar.');
    this.loadNotificationEmit.next(true);
  }

  synchronization(param) {
    // Jika payload null proses stop
    if (param.data === 'false') { return console.log('Empty Data Payload on ' + param.objectStore + '.\nSyncronization aborted.'); }

    // Syncronization Code
    const objectStore = param.objectStore;
    const tempData = JSON.parse(param.data);

    // Idb Check In
    this.idbService.count(objectStore).then(arg => {
      (arg !== 0) ? this.idbService.toArray(objectStore).then((objAll) => {

          const targetData = objAll.filter(obj => obj.EmailAddress === tempData['EmailAddress'])[0];

          // Checking tempData if is_deleted = 1 brati request tambah data
          if (tempData['is_deleted'] === 1) {
            this.idbService.deleteOne(objectStore, targetData.id).then(() => {
              console.log('Success - data has been removed!');
              return;
              Swal.fire('Data on going!\nYour data has been syncronize.');
            }, error => {
              this.alertModal(error);
            });
            return;
          }

          // Checking targetData if undefined brati request tambah data
          if (!targetData) {
            this.idbService.addOne(objectStore, tempData).then(() => {
              console.log('Success - data has been added!');
              return;
              Swal.fire('Data in coming!\nYour data has been syncronize.');
            }, error => {
              this.alertModal(error);
            });
            return;
          }

          this.idbService.update(objectStore, targetData.id, tempData).then(() => {
            console.log('Success - your data is updated!');
            return;
            Swal.fire('Data in coming!\nYour data has been syncronize.');
          }, error => {
            this.alertModal(error);
          });
        }) : this.idbService.addOne(objectStore, tempData).then(() => {
          console.log('Success - data has been added!');
          return;
          Swal.fire('Data in coming!\nYour data has been syncronize.');
        }, error => {
          this.alertModal(error);
        });
    }, error => {
      return console.log(error);
      this.alertModal(error);
    });
  }

  alertModal(error: string) {
    Swal.fire({
      type: 'error',
      title: 'Terjadi Error.',
      text: 'Error : ' + error,
      footer: '<a href>Hubungi Customer Care?</a>'
    });
  }

  public header() {
    return {
      headers: new HttpHeaders()
        .set('token', this.cookieService.get('_q'))
    };
  }

  public saveToken(token): Observable<ResponseApiModel> {

    const browserName = this.browserService.getBrowserName();
    const browserVersion = this.browserService.getBrowserVersion();
    const browserOs = this.browserService.getBrowserOperatingSystem();

    return this.http.post<ResponseApiModel>(
      this.globalService.apiVersionHost + API_SAVE_TOKEN, {
        token: token,
        brand: browserName,
        version: browserVersion,
        os: browserOs
      }, this.header()
    ).pipe(
      map(response => response)
    );
  }
}
