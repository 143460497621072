import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthenticationService } from 'src/app/auth/authentication.service';

@Component({
  selector: 'app-home5g',
  templateUrl: './home5g.component.html',
  styleUrls: ['./home5g.component.scss']
})
export class Home5gComponent implements OnInit {

  public returnUrl: string;

  constructor(
    public authService: AuthenticationService,
    private router: Router,
    private route: ActivatedRoute,
  ) { }

  ngOnInit() {
    this.authService.isAuthorized().subscribe(response => {
      if (response) {
        this.router.navigate(['/main/pm']);
      }
    });

    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/main/pm';
  }

}
