<div id="content" class="bg-white">
  <div class="row align-items-center no-gutters justify-content-end">
    <div class="col-lg-5 col-xl-5">
      <div class="pb-5 mb-5 pt-5 px-4 px-md-5 ml-0 ml-xl-5">
        <h1 class="display-4 text-primary mb-0 pb-0">About PT Nexwave</h1>
        <div class="text-dark lead pt-3 pt-md-4 pb-4 pb-md-5 line-height-180">PT NexWave adalah salah satu subkontraktor
          telekomunikasi terbesar di Indonesia. PT NexWave merupakan salah satu dari anak perusahaan TeleChoice
          International Limited yang berdiri di Singapore pada tanggal 28 April 1998. PT NexWave Indonesia hadir sejak
          2004, dan hingga kini PT NexWave sudah bekerja sama dengan operator telekomunikasi di Indonesia, Singapore,
          Philipines, Malaysia. Operator Indonesia yang sudah bekerja sama dengan PT NexWave diantaranya Telkomsel, XL,
          Indosat, HCPT (Three), Axis, Smartfren.
        </div>
        <div>
          <a [routerLink]="['/client']" class="btn btn-lg btn-outline-primary rounded-pill">Our Client</a>
        </div>
      </div>
    </div>
    <div class="col-lg-7 col-xl-7">
      <div class="pl-0 pl-lg-5">
        <img src="./assets/media/5g/about.jpg" class="w-100" alt="" />
      </div>
    </div>
  </div>
  <div class="bg-light px-0 px-lg-5">
    <div class="container-fluid pb-3 pl-5 pr-4 pt-5">
      <div class="row justify-content-start align-items" data-aos="">
        <div class="col-md-4">
          <h2 class="text-dark h3 text-left pb-3 ">
            WHY CHOOSE US
          </h2>
          <div class="line-height-180 pb-3">
            <ul>
              <li>
                Incorporated since Early 2004
              </li>
              <li>
                Approx 2000 staffs
              </li>
              <li>
                11 offices across Indonesia
              </li>
            </ul>
          </div>
        </div>
        <div class="col-md-4">
          <h2 class="text-dark h3 text-left pb-3 ">
            MAIN BUSINESS
          </h2>
          <div class="line-height-180 pb-3">
            <ul>
              <li>
                RNP/RNO
              </li>
              <li>
                Inbuilding (IBC)
              </li>
              <li>
                Products Solution (Power Suply)
              </li>
            </ul>
          </div>
        </div>
        <div class="col-md-4">
          <h2 class="text-dark h3 text-left pb-3 ">
            STRATEGIC SUPPORT SYSTEM
          </h2>
          <div class="line-height-180 pb-3">
            <ul>
              <li>
                NexWave Design and Strategic
              </li>
              <li>
                Troubleshoot Problem Supporting
              </li>
              <li>
                Training
              </li>
              <li>
                Network Profiling
              </li>
              <li>
                Capacity Monitoring
              </li>
              <li>
                Network Coverage Assessment
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row align-items-center no-gutters pt-5">
    <div class="col-lg-5 col-xl-5">
      <div class="pb-5 mb-5 pt-5 px-4 px-md-5 ml-0 ml-xl-5">
        <h1 class="display-4 text-primary mb-0 pb-0">Layanan Profesional PT NexWave</h1>
        <div class="text-dark lead pt-3 pt-md-4 pb-2 pb-md-2 line-height-180">Terpercaya lebih dari 10 tahun menjadi
          partner dari Operator maupun Vendor terkemuka
        </div>
        <div id="accordion" class="accordion justify-content-center mb-4">
          <div class="card mb-0 ">
            <div id="accordion-h-1" class="card-header bg-white px-3 py-2 border-0">
              <h5 class="mb-0 text-left text-capitalize ">
                <a href="javascript:;" (click)="expand(0)" class="d-block position-relative collapsible-link py-2">
                  <i class="fa-fw mr-1 fas fa-broadcast-tower"></i> RADIO NETWORK PLANNING &amp; OPTIMIZATION
                </a>
              </h5>
            </div>
            <div id="accordion-c-1" class="collapse" [ngClass]="{'show': show[0]}">
              <div class="card-body text-dark line-height-180 bg-white border-top">
                <ul>
                  <li>
                    Propagation Model Tuning </li>
                  <li>
                    Radio Network Planning </li>
                  <li>
                    Network optimization </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="card mb-0 ">
            <div id="accordion-h-2" class="card-header bg-white px-3 py-2 border-0">
              <h5 class="mb-0 text-left text-capitalize ">
                <a href="javascript:;" (click)="expand(1)" class="d-block position-relative collapsible-link py-2">
                  <i class="fa-fw mr-1 fas fa-project-diagram"></i> NETWORK BENCHMARKING / AUDIT
                </a>
              </h5>
            </div>
            <div id="accordion-c-2" class="collapse" [ngClass]="{'show': show[1]}">
              <div class="card-body text-dark line-height-180 bg-white border-top">
                <ul>
                  <li>
                    Analysis of OMC statistics </li>
                  <li>
                    Analysis of outdoor and indoor test data </li>
                  <li>
                    Establishing network performance KPIs </li>
                  <li>
                    Benchmarking KPIs against competing networks </li>
                  <li>
                    Recommendations for KPIs improvement </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="card mb-0 ">
            <div id="accordion-h-3" class="card-header bg-white px-3 py-2 border-0">
              <h5 class="mb-0 text-left text-capitalize ">
                <a href="javascript:;" (click)="expand(2)" data-toggle="collapse" class="d-block position-relative collapsible-link py-2">
                  <i class="fa-fw mr-1 far fa-building"></i> TURNKEY INDOOR COVERAGE SOLUTIONS
                </a>
              </h5>
            </div>
            <div id="accordion-c-3" class="collapse" [ngClass]="{'show': show[2]}">
              <div class="card-body text-dark line-height-180 bg-white border-top">
                <ul>
                  <li>
                    Analysis of OMC statistics </li>
                  <li>
                    Analysis of outdoor and indoor test data </li>
                  <li>
                    Establishing network performance KPIs </li>
                  <li>
                    Benchmarking KPIs against competing networks </li>
                  <li>
                    Recommendations for KPIs improvement </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="card mb-0 ">
            <div id="accordion-h-4" class="card-header bg-white px-3 py-2 border-0">
              <h5 class="mb-0 text-left text-capitalize ">
                <a href="javascript:;" (click)="expand(3)" data-toggle="collapse" class="d-block position-relative collapsible-link py-2">
                  <i class="fa-fw mr-1 fas fa-draw-polygon"></i> TRANSMISSION NETWORK PLANNING
                </a>
              </h5>
            </div>
            <div id="accordion-c-4" class="collapse" [ngClass]="{'show': show[3]}">
              <div class="card-body text-dark line-height-180 bg-white border-top">
                <ul>
                  <li>
                    Network dimensioning </li>
                  <li>
                    Sites search and selection </li>
                  <li>
                    LoS survey, path loss analysis &amp; frequency planning </li>
                  <li>
                    Link routing and capacity, network synchronization &amp; address planning </li>
                  <li>
                    Transmission optimization such as frequency interference &amp; link availability analysis </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="card mb-0 ">
            <div id="accordion-h-5" class="card-header bg-white px-3 py-2 border-0">
              <h5 class="mb-0 text-left text-capitalize ">
                <a href="javascript:;" (click)="expand(4)" class="d-block position-relative collapsible-link py-2">
                  <i class="fa-fw mr-1 fas fa-coins"></i> NEXGEN CORE &amp; ACCESS NETWORKS
                </a>
              </h5>
            </div>
            <div id="accordion-c-5" class="collapse" [ngClass]="{'show': show[4]}">
              <div class="card-body text-dark line-height-180 bg-white border-top">
                <ul>
                  <li>
                    Project Management </li>
                  <li>
                    Implementation of core &amp; access network </li>
                  <li>
                    Testing &amp; Commissioning of STM-n, CWDM, DWDM, E3 &amp; E1 &amp; GB circuits </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="card mb-0 ">
            <div id="accordion-h-6" class="card-header bg-white px-3 py-2 border-0">
              <h5 class="mb-0 text-left text-capitalize ">
                <a href="javascript:;" (click)="expand(5)" class="d-block position-relative collapsible-link py-2">
                  <i class="fa-fw mr-1 fas fa-car-battery"></i>  POWER SUPPLY SOLUTION
                </a>
              </h5>
            </div>
            <div id="accordion-c-6" class="collapse" [ngClass]="{'show': show[5]}">
              <div class="card-body text-dark line-height-180 bg-white border-top">
                <ul>
                  <li>
                    Rectifier </li>
                  <li>
                    Battery </li>
                  <li>
                    UPS </li>
                  <li>
                    Power Supply Cabinet </li>
                  <li>
                    Installation and commissioning </li>
                  <li>
                    1st Level repair service for Delta Rectifier Module </li>
                  <li>
                    Charge Discharge Monitoring Solution </li>
                  <li>
                    Alternative Power Solution (Fuel Cell, Solar Cell etc) </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="card mb-0 ">
            <div id="accordion-h-7" class="card-header bg-white px-3 py-2 border-0">
              <h5 class="mb-0 text-left text-capitalize ">
                <a href="javascript:;" (click)="expand(6)" class="d-block position-relative collapsible-link py-2">
                  <i class="fa-fw mr-1 fas fa-wifi"></i> OUTDOOR/INDOOR WIFI
                </a>
              </h5>
            </div>
            <div id="accordion-c-7" class="collapse" [ngClass]="{'show': show[6]}">
              <div class="card-body text-dark line-height-180 bg-white border-top">
                <p>
                  Application
                </p>
                <ul>
                  <li>
                    Hotel, resort, golf course </li>
                  <li>
                    Camera surveillance </li>
                  <li>
                    University campus, dormitory, school </li>
                  <li>
                    Commercial Estate, Stadium, Convention Centre </li>
                  <li>
                    Train Station </li>
                  <li>
                    Sea port, Air port </li>
                  <li>
                    Mining Area </li>
                  <li>
                    Citywide </li>
                  <li>
                    Township </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="card mb-0 ">
            <div id="accordion-h-8" class="card-header bg-white px-3 py-2 border-0">
              <h5 class="mb-0 text-left text-capitalize ">
                <a href="javascript:;" (click)="expand(7)" class="d-block position-relative collapsible-link py-2">
                  <i class="fa-fw mr-1 fas fa-code-branch"></i> IBC RELATED PRODUCTS
                </a>
              </h5>
            </div>
            <div id="accordion-c-8" class="collapse" [ngClass]="{'show': show[7]}">
              <div class="card-body text-dark line-height-180 bg-white border-top">
                <ul>
                  <li>
                    Feeder Cable &amp; Accessories (Hansen) </li>
                  <li>
                    IBC Passive Components (Winhap) </li>
                  <li>
                    2G/3G RF Repeater (Winhap) </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-lg-7 col-xl-7">
      <div class="pl-0 pl-lg-5">
        <img src="./assets/media/5g/about2.jpg" class="w-100" alt="" />
      </div>
    </div>
  </div>
</div>