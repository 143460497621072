import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ResponseApiModel } from '../_models/response-api.model';
import { GlobalService } from './global.service';

@Injectable({
  providedIn: 'root'
})
export class UsersManagementService {

  constructor(
    private http: HttpClient,
    private globalService: GlobalService,
    private cookieService: CookieService,
  ) { }

  public header() {
    return {
      headers: new HttpHeaders()
        .set('token', this.cookieService.get('_q'))
        .set('module', localStorage.getItem('users'))
        .set('group', this.cookieService.get('_g'))
    };
  }

  public getUsers(params: {pageSize: number, page: number, filter: string}): Observable<ResponseApiModel> {
    return this.http.post<ResponseApiModel>(
      this.globalService.apiVersionHost + '/users/default/get-users', params, this.header()
    ).pipe(
      map(response => response)
    );
  }

  public getDocument(param: { pageSize: number; page: number; filter: any; }): Observable<ResponseApiModel> {
    return this.http.post<ResponseApiModel>(
      this.globalService.apiVersionHost + '/users/default/get-document', param,
      this.header()
    ).pipe(map(response => response));
  }

  public getFile(param: { pageSize: number; page: number; filter: any; }): Observable<ResponseApiModel> {
    return this.http.post<ResponseApiModel>(
      this.globalService.apiVersionHost + '/users/default/get-file', param,
      this.header()
    ).pipe(
      map(response => response)
    );
  }

  public saveVerification(param: any): Observable<ResponseApiModel> {
    return this.http.post<ResponseApiModel>(
      this.globalService.apiVersionHost + '/users/default/save-verification', param,
      this.header()
    ).pipe(
      map(response => response)
    );
  }

  public listDropCV(params: {pageSize: number, page: number, filter: string}): Observable<ResponseApiModel> {
    return this.http.post<ResponseApiModel>(
      this.globalService.apiVersionHost + '/users/default/list-drop-cv', params, this.header()
    ).pipe(
      map(response => response)
    );
  }

  public getProject(params: {userid: number}): Observable<ResponseApiModel> {
    return this.http.post<ResponseApiModel>(
      this.globalService.apiVersionHost + '/users/default/get-project', params, this.header()
    ).pipe(
      map(response => response)
    );
  }

  public getApplyVacancy(params: {userid: number}): Observable<ResponseApiModel> {
    return this.http.post<ResponseApiModel>(
      this.globalService.apiVersionHost + '/users/default/get-apply-vacancy', params, this.header()
    ).pipe(
      map(response => response)
    );
  }

  public getStepRecruitment(params: {id_apply_vacancy: number}): Observable<ResponseApiModel> {
    return this.http.post<ResponseApiModel>(
      this.globalService.apiVersionHost + '/users/default/get-step-recruitment', params, this.header()
    ).pipe(
      map(response => response)
    );
  }

  public getExitClearance(params: {userid: number}): Observable<ResponseApiModel> {
    return this.http.post<ResponseApiModel>(
      this.globalService.apiVersionHost + '/users/default/get-exit-clearance', params, this.header()
    ).pipe(
      map(response => response)
    );
  }

  public getAssetUser(param): Observable<ResponseApiModel> {
    return this.http.post<ResponseApiModel>(
      this.globalService.apiVersionHost + '/users/default/asset-user', param, this.header()
    ).pipe(
      map(response => response)
    );
  }

  public getDataSkill(param: {userid: number, maingroup: string}): Observable<ResponseApiModel> {
    return this.http.post<ResponseApiModel>(
      this.globalService.apiVersionHost + '/users/default/get-data-skill', param, this.header()
    ).pipe(
      map(response => response)
    );
  }

  public updateDetailSkill(param: any): Observable<ResponseApiModel> {
    return this.http.post<ResponseApiModel>(
      this.globalService.apiVersionHost + '/users/default/update-detail-skill', param, this.header()
    ).pipe(
      map(response => response)
    );
  }

  public updateDataSkill(param: any): Observable<ResponseApiModel> {
    return this.http.post<ResponseApiModel>(
      this.globalService.apiVersionHost + '/users/default/update-data-skill', param, this.header()
    ).pipe(
      map(response => response)
    );
  }

  public prepareSkillForm(): Observable<ResponseApiModel> {
    return this.http.post<ResponseApiModel>(
      this.globalService.apiVersionHost + '/users/default/prepare-skill-form', null, this.header()
    ).pipe(
      map(response => response)
    );
  }

  public saveDraftUsers(param): Observable<ResponseApiModel> {
    return this.http.post<ResponseApiModel>(
      this.globalService.apiVersionHost + '/users/default/save-draft-users', param, this.header()
    ).pipe(
      map(response => response)
    );
  }

  public saveSignatureUsers(param): Observable<ResponseApiModel> {
    return this.http.post<ResponseApiModel>(
      this.globalService.apiVersionHost + '/users/default/save-signature-users', param
    ).pipe(
      map(response => response)
    );
  }

  public getDraftUsers(param: { pageSize: number, page: number, filter: any }): Observable<ResponseApiModel> {
    return this.http.post<ResponseApiModel>(
      this.globalService.apiVersionHost + '/users/default/get-draft-users', param, this.header()
    ).pipe(
      map(response => response)
    );
  }


  public exportSkill(param: { filter: any }): Observable<ResponseApiModel> {
    return this.http.post<ResponseApiModel>(
      this.globalService.apiVersionHost + '/users/default/export-skill', param, this.header()
    ).pipe(
      map(response => response)
    );
  }

  public confirmDraftUsers(param): Observable<ResponseApiModel> {
    return this.http.post<ResponseApiModel>(
      this.globalService.apiVersionHost + '/users/default/confirm-draft-users', param, this.header()
    ).pipe(
      map(response => response)
    );
  }
}
