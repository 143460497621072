import { Component, OnInit } from '@angular/core';
import { LandingpageService } from 'src/app/_services/landingpage.service';
import { Router, ActivatedRoute } from '@angular/router';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { SkillFormModel } from './skill-form/skill-form.config';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-skill-landing',
  templateUrl: './skill.component.html',
  styleUrls: ['./skill.component.scss']
})
export class SkillComponent implements OnInit {

  id: number = +this.actRoute.snapshot.params['userid'];
  skill_id: string;
  rigger: any = [];
  dt: any = [];
  programming: any = [];
  oss: any = [];
  tools: any = [];
  teamleader: any = [];
  rf: any = [];
  rnp: any = [];
  comissioning: any = [];
  rno: any = [];
  tnp: any = [];

  updated_skills: any = [];

  tableSkillConfig: SkillFormModel = {
    primaryKey: 'id_skill',
    dataSource: [],
  };

  constructor(
    public landingpageService: LandingpageService,
    public router: Router,
    public actRoute: ActivatedRoute,
    private ngxLoader: NgxUiLoaderService
  ) { }

  ngOnInit() {
    this.templateSkill();
  }

  templateSkill() {
    this.ngxLoader.start();
    const param = {
      id: this.id,
    };
    this.landingpageService.templateSkill(param).subscribe((response) => {
      this.ngxLoader.stop();
      if (response.status) {
        this.rigger = response.result.row.rigger;
        this.dt = response.result.row.dt;
        this.programming = response.result.row.programming;
        this.oss = response.result.row.oss;
        this.tools = response.result.row.tools;
        this.teamleader = response.result.row.teamleader;
        this.rf = response.result.row.rf;
        this.rnp = response.result.row.rnp;
        this.comissioning = response.result.row.comissioning;
        this.rno = response.result.row.rno;
        this.tnp = response.result.row.tnp;

        this.tableSkillConfig.dataSource = this.rigger;
        this.skill_id = 'RIGGER';
        return;
      }
      Swal.fire(response.message);
    }, error => {
      this.ngxLoader.stop();
      Swal.fire(error.error.message);
    });
  }

  dataSource($event) {
    const skill = $event.nextId;
    if (skill === 'RIGGER') {
      this.tableSkillConfig.dataSource = this.rigger;
    } else if (skill === 'RF') {
      this.tableSkillConfig.dataSource = this.rf;
    } else if (skill === 'DT') {
      this.tableSkillConfig.dataSource = this.dt;
    } else if (skill === 'OSS') {
      this.tableSkillConfig.dataSource = this.oss;
    } else if (skill === 'COMMISIONING') {
      this.tableSkillConfig.dataSource = this.comissioning;
    } else if (skill === 'RNO') {
      this.tableSkillConfig.dataSource = this.rno;
    } else if (skill === 'RNP') {
      this.tableSkillConfig.dataSource = this.rnp;
    } else if (skill === 'TNP') {
      this.tableSkillConfig.dataSource = this.tnp;
    } else if (skill === 'TOOLS') {
      this.tableSkillConfig.dataSource = this.tools;
    } else if (skill === 'PROGRAMMING') {
      this.tableSkillConfig.dataSource = this.programming;
    } else if (skill === 'TEAMLEADER') {
      this.tableSkillConfig.dataSource = this.teamleader;
    }
    this.skill_id = $event.nextId;
    this.ngxLoader.stop();
  }

  saveSkill($event) {
    let tempSkill: any;

    const tempSource = this.tableSkillConfig.dataSource.filter(le => le.id_skill === $event.id_skill)[0];

    const tempUpdatedSkill = this.updated_skills.filter(le => le.id_skill === $event.id_skill)[0];
    const index = this.updated_skills.indexOf(tempUpdatedSkill);
    if (index !== -1) {
      tempSkill = tempUpdatedSkill;
      this.updated_skills[index] = tempSkill;
    } else {
      tempSkill = tempSource;
      this.updated_skills[this.updated_skills.length] = tempSkill;
    }

    const indexSource = this.tableSkillConfig.dataSource.indexOf(tempSource);

    this.tableSkillConfig.dataSource[indexSource] = tempSkill;

    console.log(this.updated_skills);
  }

  submitSkill() {
    this.ngxLoader.start();
    const param = {
      id: this.id,
      skills : this.updated_skills
    };
    this.landingpageService.submitSkill(param).subscribe((response) => {
      this.ngxLoader.stop();
      (response.status) ?
        Swal.fire({
          title: 'Data Skill Berhasil Tersimpan.',
          // tslint:disable-next-line: max-line-length
          text: 'Proses pendaftaran berhasil. Silakan menunggu infromasi dari Tim Recruitment untuk tahap selanjutnya. Untuk selanjutnya silakan login ke website ptnw.net silakan menggunakan email dan password yang telah di daftarkan. Terimakasih.',
          type: 'success',
          showCancelButton: false,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'OK',
          allowOutsideClick: false
        }).then((result) => {
          if (result.value) {
            this.router.navigate(['/login']);
            // this.router.navigate(['/main/recruitment/skill']);
          }
        }) : Swal.fire(response.message);
    }, error => {
      this.ngxLoader.stop();
      Swal.fire(error.error.message);
    });
  }

}
