import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { AuthenticationService } from 'src/app/auth/authentication.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-lostpassword5g',
  templateUrl: './lostpassword5g.component.html',
  styleUrls: ['./lostpassword5g.component.scss']
})
export class Lostpassword5gComponent implements OnInit {

  isSubmited = false;
  public formForgot = false;
  forgot_form: any = { username: '' };

  constructor(
    private authService: AuthenticationService,
  ) { }

  ngOnInit() {
  }

  validate(Form: NgForm) {
    if (Form.form.status === 'VALID') {
      return true;
    } else {
      return false;
    }
  }

  submitForgot(forgotForm: NgForm) {
    this.isSubmited = true;
    if (this.validate(forgotForm)) {
      this.authService.forgot(this.forgot_form).subscribe(response => {
        this.isSubmited = false;
        if (response.status) {
          const res = response.result;
          let text = '';
          if (res.repeat) {
            text = 'Permintaan forgot password sebelumnya telah terkirim ke email <b style="color: #DC143C;">' + res.email + '</b>. Apabila tidak terdapat pada email masuk utama Anda, silahkan cek pada email spam. Token hanya berlaku selama 10 menit, silahkan request forgot password kembali jika token telah expired.';
          } else {
            text = 'Kode Verifikasi telah dikirim ke email <b style="color: #DC143C;">' + res.email + '</b>. Mohon cek email Anda. Apabila tidak terdapat pada email masuk utama Anda, silahkan cek pada email spam. Token hanya berlaku selama 10 menit, silahkan request forgot password kembali jika token telah expired.';
          }
          Swal.fire({
            title: 'Forgot Password',
            type: 'success',
            html: text
          });
          this.formForgot = false;
          return;
        }
        Swal.fire(response.message);
      }, (error: any) => {
        Swal.fire(error.error.message);
        this.isSubmited = false;
      });
    } else {
      this.isSubmited = false;
    }
  }

}
