<ng-container *ngIf="!partialPage">
  <div class="nds_public">
    <div id="nds_contents">
      <a name="toppage"></a>
      <app-header5g></app-header5g>
      <router-outlet></router-outlet>
      <app-footer5g></app-footer5g>
    </div>
  </div>
</ng-container>
<ng-container *ngIf="partialPage">
  <router-outlet></router-outlet>
</ng-container>

