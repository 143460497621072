<!-- end:: Subheader -->
<!-- begin:: Content -->
<div class="kt-content kt-grid__item kt-grid__item--fluid" id="kt_content">
  <div class="full-table bg-white" style="width: auto;">
    <div class="sheet" style="width: px;">
      <table class="table table-bordered">
        <thead>
          <tr>
            <th class="text-center" [ngClass]="!tableAdvanceConfig.hasSubheader ? 'th-subheader' : 'th-nosubheader'">
              <div ngbDropdown class="dropdown dropdown-inline" #dropMore="ngbDropdown" placement="bottom-left">
                <button class="btn btn-sm btn-icon" [ngClass]="hasSelectedValue ? 'btn-primary' : 'btn-light'" id="dropDownStatus" ngbDropdownToggle [disabled]="!hasSelectedValue">
                  <i class="flaticon-more-1 pr-0"></i>
                </button>
                <div ngbDropdownMenu aria-labelledby="dropDownStatus">
                  <ul class="kt-nav">
                    <li>
                      <h6 class="dropdown-header">Change Status to</h6>
                    </li>
                    <ng-container *ngFor="let button of tableAdvanceConfig.buttonOption">
                      <ng-template *ngIf="!button.visible else AdditionalButton" [ngxPermissionsOnly]="((button.permissions) ? button.permissions : ['USER'])">
                        <li ngbDropdownItem class="kt-nav__item">
                          <a class="kt-nav__link px-0" href="javascript:" (click)="changeStatus(button.action)">
                            <i class="fa fa-fw mr-2" [ngClass]="generateIcon(button.icon)"></i>
                            <span class="kt-nav__link-text">{{button.label}}</span>
                          </a>
                        </li>
                      </ng-template>
                      <ng-template #AdditionalButton>
                        <li ngbDropdownItem class="kt-nav__item">
                          <a class="kt-nav__link px-0" href="javascript:" (click)="changeStatus(button.action)">
                            <i class="fa fa-fw mr-2" [ngClass]="generateIcon(button.icon)"></i>
                            <span class="kt-nav__link-text">{{button.label}}</span>
                          </a>
                        </li>
                      </ng-template>
                    </ng-container>
                  </ul>
                </div>
              </div>
            </th>
            <th *ngFor="let col of tableAdvanceConfig.columnOption" class="thdata" [ngClass]="!tableAdvanceConfig.hasSubheader ? 'th-subheader' : 'th-nosubheader'" [style.min-width.px]="200">{{ col.label }}</th>
          </tr>
        </thead>
        <tbody>
          <!-- Filter -->
          <tr>
            <th class="text-center">
              <div>
                <label class="kt-checkbox kt-checkbox--bold kt-checkbox--brand">
                  <input [(ngModel)]="selectAll" type="checkbox" class="checkbox" (change)="actionSelectAll()">
                  <span></span>
                </label>
              </div>
            </th>
            <ng-container *ngFor="let col of tableAdvanceConfig.columnOption">
              <ng-container *ngIf="col.showFilter else FilterFalse">
                <th>
                  <ng-container [ngSwitch]="parseColumnOption(tableAdvanceConfig.columnOption,'name',col.name,'setPipe')">
                    <ng-container *ngSwitchCase="'date'">
                      <div style="width: 250px;" class='input-group pull-right' [id]="'daterangepicker_'+col.name">
                        <input class="form-control form-control-sm" placeholder="Select date range" ngbDatepicker
                          #datePicker="ngbDatepicker" [autoClose]="'outside'" (dateSelect)="onDateSelection($event, col.name)"
                          [displayMonths]="2" [dayTemplate]="customPicker" [navigation]="'select'" [outsideDays]="'visible'"
                          [showWeekNumbers]="false" [startDate]="dateRange[col.name].fromDate" [maxDate]="toDay" name="rangeDate"
                          [value]="(dateRange[col.name].strFromDate | date : 'd MMM y') +' s/d '+ (dateRange[col.name].strToDate | date : 'd MMM y')"
                          readonly>
                        <div class="input-group-append">
                          <span class="input-group-text" (click)="datePicker.toggle()">
                            <i class="la la-calendar-check-o"></i>
                          </span>
                        </div>
                      </div>
                      <ng-template #customPicker let-date let-focused="focused">
                        <span class="custom-day" [class.focused]="focused" [class.range]="isRange(date, col.name)"
                          [class.faded]="isHovered(date, col.name) || isInside(date, col.name)" (mouseenter)="dateRange[col.name].hoveredDate = date"
                          (mouseleave)="dateRange[col.name].hoveredDate = null">
                          {{ date.day }}
                        </span>
                      </ng-template>
                    </ng-container>
                    <ng-container *ngSwitchDefault>
                      <input class="form-control form-control-sm" [(ngModel)]="tableAdvanceConfig.filter[col.name]"
                        (keyup.enter)="submitFilter()" type="text">
                    </ng-container>
                  </ng-container>
                </th>
              </ng-container>
            </ng-container>
            <ng-template #FilterFalse><th></th></ng-template>
          </tr>
          <!-- Data -->
          <ng-container *ngIf="!tableAdvanceConfig.loading else loadingTemplate">
            <ng-container *ngIf="(tableAdvanceConfig.dataSource && tableAdvanceConfig.dataSource.length > 0) else NoRecord">
              <ng-container *ngFor="let data of tableAdvanceConfig.dataSource; let ind = index;">
                <tr>
                  <td class="text-center">
                    <div class="pb-2">
                      <label class="kt-checkbox kt-checkbox--bold kt-checkbox--brand">
                        <input [(ngModel)]="checkboxModel[data[tableAdvanceConfig.primaryKey]]" type="checkbox" class="checkbox" (change)="actionSelectCb(data[tableAdvanceConfig.primaryKey])">
                        <span></span>
                      </label>
                    </div>
                  </td>
                  <ng-container *ngFor="let col of tableAdvanceConfig.columnOption">
                    <td>{{ data[col.name] ? data[col.name] : 'not set' }}</td>
                  </ng-container>
                </tr>
              </ng-container>
            </ng-container>
          </ng-container>
        </tbody>
      </table>
    </div>
  </div>
  <div class="full-table-footer bg-light">
    <div class="px-3 py-3 d-flex">
      <div class="d-flex mr-4">
        <button class="btn btn-primary btn-bold mr-2">
          Add
        </button>
        <button class="btn btn-danger btn-bold mr-2" (click)="warning()" [disabled]="!hasSelectedValue">
          Remove
        </button>
        <div ngbDropdown class="dropdown dropdown-inline" #dropMore="ngbDropdown" placement="top-left">
          <button class="btn btn-warning" id="dropDownOrder" ngbDropdownToggle>
            Ordering
          </button>
          <div ngbDropdownMenu aria-labelledby="dropDownOrder">
            <ul class="kt-nav" style="width: 100%;">
              <li>
                <h6 class="dropdown-header">Ordered By</h6>
              </li>
              <li ngbDropdownItem class="kt-nav__item" *ngFor="let order of generateOrderingColumn()">
                <a class="kt-nav__link px-0" href="javascript:" (click)="orderData(order.name)">
                  <span class="kt-nav__link-text">{{order.label}}</span>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="my-auto">
        <app-pagination-common [paginateOption]="tableAdvanceConfig.paginateOption" [pageSizeOption]="tableAdvanceConfig.pageSizeOption"
          (currentPageSizeEvent)="changePageSize($event)"
          (currentPageEvent)="changePage($event)">
        </app-pagination-common>
      </div>
    </div>
  </div>
</div>

<ng-template #loadingTemplate>
  <tr>
    <td [attr.colspan]="(tableAdvanceConfig.columnOption.length + 1)" class="py-5">
      <img src="/assets/media/loader/timeline.svg">
    </td>
  </tr>
</ng-template>
<ng-template #NoRecord>
  <tr>
    <td [attr.colspan]="(tableAdvanceConfig.columnOption.length + 1)" class="py-5">
      <span>No Data Recorded</span>
    </td>
  </tr>
</ng-template>
