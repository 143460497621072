import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { CookieService } from 'ngx-cookie-service';
import { ResponseApiModel } from '../_models/response-api.model';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { GlobalService } from './global.service';
import { map, catchError } from 'rxjs/operators';

const API_LOGOUT = '/auth/logout';
const API_TELEGRAM_LOGIN = '/auth/telegram-login';
const API_LINK_TELEGRAM = '/auth/link-telegram';

const API_DROP_CV = '/users/default/drop-cv';
@Injectable({
    providedIn: 'root'
})
export class UserService {

    constructor(
        private http: HttpClient,
        private cookieService: CookieService,
        private globalService: GlobalService,
    ) {

    }

    public header() {
        return {
            headers: new HttpHeaders()
                .set('token', this.cookieService.get('_q'))
        };
    }

    /**
     * Get access token
     * @returns {Observable<string>}
     */
    public getAccessToken(): Observable<string> {
        const token: string = <string>this.cookieService.get('_q');
        return of(token);
    }

    /**
     * Get refresh token
     * @returns {Observable<string>}
     */
    public getRefreshToken(): Observable<string> {
        const token: string = <string>this.cookieService.get('_w');
        return of(token);
    }

    /**
     * Get user roles in JSON string
     * @returns {Observable<any>}
     */
    public getUserRoles(module: string): any {
        const tokenRoles: any = <string>localStorage.getItem(module);
        if (tokenRoles) {
            let roles = this.payloadToken(tokenRoles);
            return roles;
        }
        else {
            return null;
        }
    }

    public getUserID() {
        const jwt = this.payloadToken();
        return jwt.data.userid;
    }

    public getNama() {
        const jwt = this.payloadToken();
        return jwt.data.nama;
    }

    public getName() {
        const jwt = this.payloadToken();
        return jwt.data.nama;
    }

    public getProfile() {
        const jwt = this.payloadToken();
        return jwt.data.profile;
    }

    public getUsername() {
        const jwt = this.payloadToken();
        return jwt.data.username;
    }

    public getPosition() {
        const jwt = this.payloadToken();
        return jwt.data.position;
    }

    public getDesignation() {
        const jwt = this.payloadToken();
        return jwt.data.designation;
    }

    public getUserPtnwStatus() {
        const jwt = this.payloadToken();
        return (jwt.data.user_ptnw_status === 'Internal') ? true : false;
    }

    public getFcmToken() {
        return this.cookieService.get('_ft');
    }

    public getUserGroup() {
        const tokenRoles: any = <string>this.cookieService.get('_g');
        if (tokenRoles) {
            let roles = this.payloadToken(tokenRoles);
            return roles;
        }
        else {
            return [];
        }
    }

    public isInGroup(g): boolean {
        let group = this.getUserGroup();
        return group.includes(g);
    }

    public fcmTokenExist(token: string) {
        return (token === this.cookieService.get('_ft')) ? true : false;
    }

    /**
     * Set access token
     * @returns {UserService}
     */
    public setAccessToken(token: string): UserService {
        let jwt = this.payloadToken(token);

        if (window.location.hostname == 'ptnw.net') {
            this.cookieService.set('_q', token, jwt.exp, '/', '.ptnw.net', true);
        } else if (window.location.hostname == 'nexwaveindonesia.com') {
            this.cookieService.set('_q', token, jwt.exp, '/', '.nexwaveindonesia.com', true);
        } else {
            this.cookieService.set('_q', token, jwt.exp, '/');
        }

        return this;
    }


    /**
     * Set refresh token
     * @returns {UserService}
     */
    public setRefreshToken(token: string): UserService {
        var date = new Date();
        date.setDate(date.getDate() + 60);
        this.cookieService.set('_w', token, date, '/');
        return this;
    }

    /**
     * Set roles token
     * @returns {UserService}
     */
    public setRolesToken(token: string, module: string): UserService {
        localStorage.setItem(module, token);
        return this;
    }

    /**
     * Set refresh token
     * @returns {UserService}
     */
    public setGroupToken(token: string): UserService {
        // localStorage.setItem('_g', token);
        var date = new Date();
        date.setDate(date.getDate() + 60);

        if (/ptnw\.net/.test(window.location.hostname)) {
            this.cookieService.set('_g', token, date, '/', '.ptnw.net', true);
        } else if (/nexwaveindonesia\.com/.test(window.location.hostname)) {
            this.cookieService.set('_g', token, date, '/', '.nexwaveindonesia.com', true);
        } else {
            this.cookieService.set('_g', token, date, '/');
        }

        return this;
    }

    /**
     * Set user roles
     * @param roles
     * @returns {UserService}
     */
    public setUserRoles(roles: any): any {
        var tomorrow = new Date();
        tomorrow.setDate(tomorrow.getDate() + 1);
        if (roles != null) {
            this.cookieService.set('userRoles', JSON.stringify(roles), tomorrow, '/');
        }
        return this;
    }

    /**
     * Set vacancy token
     * @returns {UserService}
     */
    public setVacancyToken(token: string): UserService {
        localStorage.setItem('vacancy', token);
        return this;
    }

    public setUsername(username: string): any {
        this.cookieService.set('username', username);
        return this;
    }

    public setUserFullname(fullname: string): any {
        this.cookieService.set('fullname', fullname);
        return this;
    }

    public setUserEmail(email: string): any {
        this.cookieService.set('email', email);
        return this;
    }

    public setUserPic(picture: string): any {
        this.cookieService.set('picture', picture);
        return this;
    }

    public setFcmToken(token: string): any {
        this.cookieService.set('_ft', token, 365);
        return null;
    }

    public payloadToken(jwt?: string) {
        const token = jwt ? jwt : <string>this.cookieService.get('_q');
        const tokenParsed = token.split('.');
        const payload = tokenParsed[1];

        try {
            let response = JSON.parse(atob(payload));

            return response;
        }
        catch (ex) {
            return null;
        }
    }

    public startGeolocation(): Promise<any> {
        return new Promise((resolve, reject) => {
            navigator.geolocation.getCurrentPosition(resp => {
                resolve({ lng: resp.coords.longitude, lat: resp.coords.latitude });
            }, err => {
                reject(err);
            });
        });
    }

    public logout(fingerprint: string): Observable<ResponseApiModel> {
        return this.http.post<ResponseApiModel>(
            this.globalService.apiVersionHost + API_LOGOUT, { fingerprint }, this.header()
        ).pipe(map(response => response));
    }

    public telegramLogin(params: any): Observable<ResponseApiModel> {
        return this.http.post<ResponseApiModel>(
            this.globalService.apiVersionHost + API_TELEGRAM_LOGIN, params, this.header()
        ).pipe(map(response => response));
    }

    public linkTelegram(params: any): Observable<ResponseApiModel> {
        return this.http.post<ResponseApiModel>(
            this.globalService.apiVersionHost + API_LINK_TELEGRAM, params, this.header()
        ).pipe(map(response => response));
    }

    public dropCV(params: any): Observable<ResponseApiModel> {
        return this.http.post<ResponseApiModel>(
            this.globalService.apiVersionHost + API_DROP_CV, params, this.header()
        ).pipe(map(response => response));
    }
    public listPosisi(): Observable<ResponseApiModel> {
        return this.http.post<ResponseApiModel>(
            this.globalService.apiVersionHost + '/users/default/list-posisi', null, this.header()
        ).pipe(map(response => response));
    }
}
