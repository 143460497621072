<!-- begin:: Header Mobile -->
<div id="kt_header_mobile" class="kt-header-mobile kt-header-mobile--fixed ">
  <div class="kt-header-mobile__logo">
    <a [routerLink]="['/main/modules']" routerLinkActive="router-link-active" >
      <img alt="LogoNDS" src="./assets/media/logos/logonds3.png" style="height: 25px" />
    </a>
  </div>
  <div class="kt-header-mobile__toolbar">
    <button (click)="toggleSideLeft()" class="kt-header-mobile__toggler kt-header-mobile__toggler--left" id="kt_aside_mobile_toggler"><span></span></button>
    <!-- <button (click)="toggleHeaderMenu()" class="kt-header-mobile__toggler" id="kt_header_mobile_toggler"><span></span></button> -->
    <button (click)="toggleTopbar()" class="kt-header-mobile__topbar-toggler" id="kt_header_mobile_topbar_toggler"><i class="flaticon-more"></i></button>
  </div>
</div>
<!-- end:: Header Mobile -->