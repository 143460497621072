import { HttpClient, HttpHeaders } from '@angular/common/http';
import { CookieService } from 'ngx-cookie-service';
import { map } from 'rxjs/operators';
import { GlobalService } from './global.service';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "./global.service";
import * as i3 from "ngx-cookie-service";
var API_CREATE = '/whistleBlowing/default/create-whistle-blowing';
var API_GET_ALL = '/whistleBlowing/manage/get-whistle-blowings';
var API_GET_ATTACH = '/whistleBlowing/manage/get-whistle-blowing';
var WhistleBlowingService = /** @class */ (function () {
    function WhistleBlowingService(http, globalService, cookieService) {
        this.http = http;
        this.globalService = globalService;
        this.cookieService = cookieService;
    }
    WhistleBlowingService.prototype.header = function () {
        return {
            headers: new HttpHeaders()
                .set('token', this.cookieService.get('_q'))
                .set('module', localStorage.getItem('whistleBlowing'))
        };
    };
    WhistleBlowingService.prototype.create = function (params) {
        return this.http.post(this.globalService.apiVersionHost + API_CREATE, params).pipe(map(function (response) { return response; }));
    };
    WhistleBlowingService.prototype.getAll = function (params) {
        return this.http.post(this.globalService.apiVersionHost + API_GET_ALL, params, this.header()).pipe(map(function (response) { return response; }));
    };
    WhistleBlowingService.prototype.getAttach = function (params) {
        return this.http.post(this.globalService.apiVersionHost + API_GET_ATTACH, params, this.header()).pipe(map(function (response) { return response; }));
    };
    WhistleBlowingService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function WhistleBlowingService_Factory() { return new WhistleBlowingService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.GlobalService), i0.ɵɵinject(i3.CookieService)); }, token: WhistleBlowingService, providedIn: "root" });
    return WhistleBlowingService;
}());
export { WhistleBlowingService };
