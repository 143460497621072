<div id="nds_contents">
  <div class="bg-white navbar-spacer"></div>
  <div class="pagecomponentwrap text-center text-white " id="pageheader">
    <div class="container pb-5 pt-75">
      <div class="no-gutters row justify-content-center align-items-center" data-aos="">
        <div class="col-md-10" data-aos="flip-up">
          <h2 class="stack_font2 h1 pb-2">
            FAQ | Frequently Asked Questions
          </h2>
          <p class="stack_font3 lead ">
            Hai, apa yang bisa kami bantu?
          </p>
          <!-- <div class="container mb-5 p-4 shadow-sm rounded" data-aos="fade-up">
            <form #prForm="ngForm" (ngSubmit)="reloadData()" class="example">
              <input type="text" class="form-control" placeholder="Ketikan kata kunci (misal: Attendance)" name="filter.question"
                [(ngModel)]="filter.question">
              <button type="submit" class="btn btn-primary"><span class="fa fa-search"></span> Cari</button>
            </form>
          </div> -->
        </div>
      </div>
    </div>
  </div>
  <div class="clear"></div>

  <ng-container *ngIf="!isLoading else Loading">
    <ng-container *ngIf="dataSource || dataSource.length !== 0 else NotFound">
      <div class="pagecomponentwrap career-project pb-1">
        <div class="container border mb-5 pb-4 shadow-sm rounded" data-aos="fade-up">
          <div class="row justify-content-center align-items-top pt-4 pb-3">
            <div class="col-md-9" data-aos="">
              <div class="kt-portlet kt-widget19">
                <div class="kt-portlet__body kt-portlet__body--fit kt-portlet__body--unfill">
                  <div class="kt-widget19__shadow"></div>
                  <div class="kt-widget19__labels" style="background-color: white; padding: 5px 5px 0px 5px;">
                    <h3>{{dataSource.question}}</h3>
                  </div>
                </div>
              </div>
              <div class="kt-portlet__body">
                <div class="kt-widget19__wrapper">
                  <div class="kt-widget19__content">
                    <div class="">
                      <span class="far fa-user">
                        {{dataSource.nama === 'Agus Sodikin' ? 'NDS Customer Care' : dataSource.nama}}
                      </span>&nbsp;
                      <span class="far fa-clock-o">
                        {{dataSource.created_at | date: 'd LLL yyyy H:mm'}}
                      </span>
                    </div>
                  </div>
                  <div class="">
                    <div class="blog-single-desc">
                      <p style="text-align: left;">
                        <span [innerHTML]="dataSource.answer">
                          
                        </span>
                      </p>
                      <div style="clear:both"></div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- <div class="kt-portlet" *ngFor="let row of dataSource; let idx = index">
                <div class="kt-portlet__head">
                  <div class="kt-portlet__head-label">
                    <h3 class="kt-portlet__head-title text-uppercase font-weight-bold">
                      {{row['nama_category']}} 
                    </h3>
                  </div>
                </div>
                <div class="kt-portlet__body_disable pb-3">
                  <div class="kt-notification-v2">
                    <div class=""></div>
                    <a class="kt-notification-v2__item" [routerLink]="'/post/'+row.faq_hash" >
                      <div class="kt-notification-v2__item-icon kt-font-bolder kt-font-danger border">
                        {{idx + 1}}
                      </div>
                      <div class="kt-notification-v2__itek-wrapper">
                        <div class="kt-notification-v2__item-title">
                          {{row['question']}}
                        </div>
                      </div>
                    </a>
                  </div>
                </div>
              </div> -->
            </div>
            <div class="col-md-3" data-aos="">
              <div class="kt-portlet">
                <div class="kt-portlet__head">
                  <div class="kt-portlet__head-label">
                    <h3 class="kt-portlet__head-title text-uppercase font-weight-bold">
                      Artikel Terkait
                    </h3>
                  </div>
                </div>
                <div class="pb-2">
                    <ul class="kt-nav">
                      <li class="kt-nav__item">
                        <a class="kt-nav__link" [routerLink]="'/faq'">
                          <i class="fa fa-arrow-left text-uppercase">
                            Kembali
                          </i>
                        </a>
                      </li>
                      <li class="kt-nav__item" *ngFor="let cat of dataTerkait">
                        <a class="kt-nav__link" (click)="changeFaq(cat.faq_hash)">
                          <span class="kt-nav__link-bullet kt-nav__link-bullet--dot">
                            <span></span>
                          </span>
                          <span class="kt-nav__link-text text-uppercase">
                            {{cat.question}}
                          </span>
                        </a>
                      </li><br><br>
                      <li class="kt-nav__item">
                        Masih Belum Menemukan Jawaban ?
                      </li>
                      <li class="kt-nav__item">
                        <a [href]="'mailto:dev.nexwave@gmail.com?subject=Pertanyaan selain FAQ - '">Hubungi Kami</a>
                        <!-- <a class="kt-nav__link" [routerLink]="'/faq'">
                          <i class="fa fa-arrow-left text-uppercase">
                            Hubungi Kami
                          </i>
                        </a> -->
                      </li>
                    </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
  </ng-container>

  <div class="clear"></div>
</div>

<!-- Loading Template -->
<ng-template #Loading>
  <div class="text-center">
    <img src="/assets/media/loader/timeline.svg">
  </div>
</ng-template>

<!-- Not Found Template -->
<ng-template #NotFound>
  <div class="pagecomponentwrap career-project pb-1">
    <div class="container border mb-5 pb-4 shadow-sm rounded" data-aos="fade-up">
      <div class="row justify-content-center align-items-top pt-4 pb-3">
        <div class="col-md-12" data-aos="">
          <div class="pl-3">
            <h2 class="stack_font2 h4 text-uppercase font-weight-bold">
              Questions not found
            </h2>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>