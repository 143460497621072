import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { NgbModal, NgbDateAdapter, NgbDateNativeAdapter, NgbDate, NgbInputDatepicker, NgbCalendar } from '@ng-bootstrap/ng-bootstrap';
import { TableCommonModel } from './table-common.config';
import Swal from 'sweetalert2';
import { GlobalService } from 'src/app/_services/global.service';
import { DropzoneComponent } from 'ngx-dropzone-wrapper';
import { DataDateRangeModel } from './data-daterange.models';

@Component({
  selector: 'app-table-common',
  templateUrl: './table-common.component.html',
  styleUrls: ['./table-common.component.scss'],
})
export class TableCommonComponent implements OnInit {

  @Input() tableCommonConfig: TableCommonModel;
  @Input() loading: boolean;

  currentRow: number = 0;
  currentPageSize: number;
  currentEdit: any = {};
  currentDeleted: any = {};

  expandedElement: boolean = false;
  onEdit: boolean = false;
  checkboxAll: boolean = false;

  api_url: string;
  api_version_url: string;

  toDay: NgbDate;
  dateRange: any = {};

  @ViewChild(DropzoneComponent, { static: false }) componentRef?: DropzoneComponent;
  @ViewChild('datePicker', { static: false }) public datePicker: NgbInputDatepicker;

  @Output() currentPageSizeEvent = new EventEmitter<number>();
  @Output() currentPageEvent = new EventEmitter<number>();
  @Output() filterInput = new EventEmitter<number>();
  @Output() sortEvent = new EventEmitter<number>();
  @Output() checkboxAction = new EventEmitter<any>();
  @Output() rowEdited = new EventEmitter<any>();
  @Output() rowDeleted = new EventEmitter<any>();
  @Output() rowCancelEdit = new EventEmitter<any>();
  @Output() expandRow = new EventEmitter<any>();
  @Output() rowAction = new EventEmitter<any>();
  lastApproval: any;
  constructor(
    private modalService: NgbModal,
    private globalService: GlobalService,
    public calendar: NgbCalendar,
  ) {

  }

  ngOnInit() {
    this.api_url = this.globalService.apiHost;
    this.api_version_url = this.globalService.apiVersionHost;
    this.currentPageSize = this.tableCommonConfig.paginateOption.pageSize;

    this.toDay = this.calendar.getToday();
    for (let colOpt of this.tableCommonConfig.columnOption) {
      if (colOpt['inputType'] && colOpt['inputType'] == 'date') {
        let k = colOpt['column'];
        if (this.tableCommonConfig.filter[k] && this.tableCommonConfig.filter[k]['from'] && this.tableCommonConfig.filter[k]['to']) {
          const toDay = new Date(this.tableCommonConfig.filter[k]['to'] + ' 00:00:00');
          const from = new Date(this.tableCommonConfig.filter[k]['from'] + ' 00:00:00');
          const to = new Date(this.tableCommonConfig.filter[k]['to'] + ' 00:00:00');
          const diffTimeFrom = Math.abs(toDay.getTime() - from.getTime());
          const diffDaysFrom = Math.ceil(diffTimeFrom / (1000 * 60 * 60 * 24));
          const diffTimeTo = Math.abs(toDay.getTime() - to.getTime());
          const diffDaysTo = Math.ceil(diffTimeTo / (1000 * 60 * 60 * 24));
          let d: DataDateRangeModel = {
            hoveredDate: null,
            fromDate: ((diffDaysFrom != 0) ? this.calendar.getNext(this.calendar.getToday(), 'd', -1 * diffDaysFrom) : this.calendar.getToday()),
            strFromDate: this.tableCommonConfig.filter[k]['from'],
            toDate: ((diffDaysTo != 0) ? this.calendar.getNext(this.calendar.getToday(), 'd', -1 * diffDaysTo) : this.calendar.getToday()),
            strToDate: this.tableCommonConfig.filter[k]['to']
          }
          this.dateRange[k] = d;
        }
        else {
          let d: DataDateRangeModel = {
            hoveredDate: null,
            fromDate: null,
            strFromDate: '',
            toDate: null,
            strToDate: ''
          }
          this.dateRange[k] = d;
          this.tableCommonConfig.filter[k] = {};
        }
      }
    }
  }

  resetCheckbox() {
    this.checkboxAll = false;
    if (this.tableCommonConfig.checkboxOption) {
      for (const item of this.tableCommonConfig.checkboxOption.itemCheckbox) {
        item.isSelected = false;
      }
    }
  }

  checkboxAllTriggered() {
    for (const item of this.tableCommonConfig.checkboxOption.itemCheckbox) {
      item.isSelected = this.checkboxAll;
    }
  }

  changePageSize() {
    // this.currentPageEvent.emit(1);
    this.currentPageSizeEvent.emit(this.currentPageSize);
    this.resetCheckbox();
  }

  changePage(page: number, allow: boolean) {
    if (!allow) {
      this.currentPageEvent.emit(page);
      this.resetCheckbox();
    }
  }

  submitFilter() {
    this.currentPageEvent.emit(1);
    this.filterInput.emit(this.tableCommonConfig.filter);
  }

  resetFilter() {
    this.tableCommonConfig.filter = {};
    for (let i in this.dateRange) {
      this.dateRange[i] = {
        hoveredDate: null,
        fromDate: null,
        strFromDate: '',
        toDate: null,
        strToDate: ''
      };
      this.tableCommonConfig.filter[i] = {};
    }
    this.currentPageEvent.emit(1);
    this.filterInput.emit(this.tableCommonConfig.filter);
  }

  expandToogle(id) {
    this.expandedElement = (this.currentRow === id) ? !this.expandedElement : true;
    this.currentRow = id;
    this.expandRow.emit({ expand: this.expandedElement, id: this.currentRow });
  }

  buildRoute(row, route, param) {
    const newRoute: string[] = [];
    newRoute.push(route);
    // tslint:disable-next-line: forin
    for (const key in param) {
      let temp = row[param[key]];
      if (!temp) {
        temp = param[key];
      }
      newRoute.push(temp);
    }
    return newRoute;
  }

  extractParam(row, obj) {
    const param = {};
    // tslint:disable-next-line: forin
    for (const key in obj) {
      param[key] = row[obj[key]];
    }
    return param;
  }

  openModal(param, componentModal, modalOption: any = { size: 'lg' }) {
    const modalRef = this.modalService.open(componentModal, modalOption);

    // tslint:disable-next-line: forin
    for (const key in param) {
      modalRef.componentInstance[key] = param[key];
    }
  }

  sendParam(param, action) {
    const data = { param, action };
    if (action === 'view' || action === 'edit' || action === 'download' || action === 'other') {
      this.rowAction.emit(data);
    }
    if (action === 'delete') {
      Swal.fire({
        title: 'Are you sure ?',
        text: 'You won\'t be able to revert this!',
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!'
      }).then((result) => {
        if (result.value) {
          this.rowAction.emit(data);
        }
      });
    }
  }

  parseColumnOption(obj, find, val, get) {
    const temp = obj.filter(el => el[find] === val);
    return temp[0][get];
  }

  checkboxActionTrigger(event) {
    this.checkboxAction.emit({ message: event, data: this.tableCommonConfig.checkboxOption.itemCheckbox });
  }

  edit(row) {
    const id = row[this.tableCommonConfig.primaryKey];
    this.onEdit = (this.currentRow === id) ? !this.onEdit : true;
    this.currentRow = id;
    this.currentEdit = row;

    const inputDate = this.tableCommonConfig.columnOption.filter(el => el.inputType === 'date');
    for (const input of inputDate) {
      const tempDate = new Date();
      let tempObjDate = { year: tempDate.getFullYear(), month: tempDate.getMonth() + 1, day: tempDate.getDate() };
      this.currentEdit[input.column] = (this.currentEdit[input.column]) ? this.dateParse(this.currentEdit[input.column]) : tempObjDate;
    }
  }

  save() {
    const inputDate = this.tableCommonConfig.columnOption.filter(el => el.inputType === 'date');
    for (const input of inputDate) {
      this.currentEdit[input.column] = this.formatDate(this.currentEdit[input.column]);
    }
    this.rowEdited.emit(this.currentEdit);
    this.onEdit = false;
  }

  cancelEdit() {
    this.onEdit = false;
    this.rowCancelEdit.emit();
  }

  delete(row) {
    const id = row[this.tableCommonConfig.primaryKey];
    this.currentRow = id;
    this.currentDeleted = row;
    Swal.fire({
      title: 'Are you sure ?',
      text: 'You won\'t be able to revert this!',
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.value) {
        this.rowDeleted.emit(this.currentDeleted);
      }
    });
  }

  getSelectedTypehead($event, col) {
    this.currentEdit[col] = $event.key;
  }

  getSelectedSelect(val, col) {
    this.currentEdit[col] = val;
  }

  isHovered(date: NgbDate, col) {
    return this.dateRange[col].fromDate && !this.dateRange[col].toDate && this.dateRange[col].hoveredDate && date.after(this.dateRange[col].fromDate) && date.before(this.dateRange[col].hoveredDate);
  }

  isInside(date: NgbDate, col) {
    return date.after(this.dateRange[col].fromDate) && date.before(this.dateRange[col].toDate);
  }

  isRange(date: NgbDate, col) {
    return date.equals(this.dateRange[col].fromDate) || date.equals(this.dateRange[col].toDate) || this.isInside(date, col) || this.isHovered(date, col);
  }

  dateParse(date) {
    const _date = new Date(date);
    return { year: _date.getFullYear(), month: _date.getMonth() + 1, day: _date.getDate() };
  }

  formatDate({ year, month, day }) {
    let _year, _month, _day;
    _year = year;
    _month = (month < 10) ? '0' + month : month;
    _day = (day < 10) ? '0' + day : day;

    return [_year, _month, _day].join('-');
  }

  onDateSelection(date: NgbDate, col) {
    if (!this.dateRange[col].fromDate && !this.dateRange[col].toDate) {
      this.dateRange[col].fromDate = date;
      this.dateRange[col].strFromDate = this.formatDate(this.dateRange[col].fromDate);
      this.tableCommonConfig.filter[col]['from'] = this.dateRange[col].strFromDate;
    } else if (this.dateRange[col].fromDate && !this.dateRange[col].toDate && date.after(this.dateRange[col].fromDate)) {
      this.dateRange[col].toDate = date;
      this.dateRange[col].strToDate = this.formatDate(this.dateRange[col].toDate);
      this.tableCommonConfig.filter[col]['to'] = this.dateRange[col].strToDate;
    } else {
      this.dateRange[col].toDate = null;
      this.dateRange[col].strToDate = '';
      this.dateRange[col].fromDate = date;
      this.dateRange[col].strFromDate = this.formatDate(this.dateRange[col].fromDate);
      this.tableCommonConfig.filter[col]['from'] = this.dateRange[col].strFromDate;
    }

    if (this.dateRange[col].fromDate && this.dateRange[col].toDate) {
      this.datePicker.close();
      this.submitFilter();
    }
  }

  onSuccess($event, col) {
    this.currentEdit[col] = $event.id.toString();
  }

  sortColumn(col) {
    if (this.tableCommonConfig.sortBy[col] == '-') {
      this.tableCommonConfig.sortBy[col] = 'asc';
    } else if (this.tableCommonConfig.sortBy[col] == 'asc') {
      this.tableCommonConfig.sortBy[col] = 'desc';
    } else if (this.tableCommonConfig.sortBy[col] == 'desc') {
      this.tableCommonConfig.sortBy[col] = '-';
    } else {
      this.tableCommonConfig.sortBy[col] = '-';
    }

    this.sortEvent.emit(this.tableCommonConfig.sortBy);
  }

  stepDate(pass, i, approval_date) {
    let x: any = '';
    if (approval_date !== null) {
      let appdate: any = approval_date.split('.', 2);
      appdate = appdate[0];
      // let x: any = '';
      if (pass == 1) {
        if (i == 0) {
          x = appdate;
        } else {
          x = this.getinterval(this.lastApproval, appdate);
          x = appdate + ' (Approval time: ' + x + ')';
        }
      } else {

        x = ' (Waiting : ' + this.getinterval(this.lastApproval, 'x') + ')';
      }
      this.lastApproval = appdate;
    }
    return x;
  }

  getinterval(from, to) {
    var now = new Date();
    var F: any;
    if (to == 'x') {
      F = now;
    } else {
      F = new Date(to);
    }
    var N = new Date(from);
    var dateFuture = F.getTime();
    var dateNow = N.getTime();

    var seconds = Math.floor((dateFuture - (dateNow)) / 1000);
    var minutes = Math.floor(seconds / 60);
    var hours = Math.floor(minutes / 60);
    var days = Math.floor(hours / 24);

    hours = hours - (days * 24);
    minutes = minutes - (days * 24 * 60) - (hours * 60);
    seconds = seconds - (days * 24 * 60 * 60) - (hours * 60 * 60) - (minutes * 60);
    let x = '';
    if (days > 0) {
      x = days + (days > 1 ? ' days ' : ' day ');
    }
    return x + hours + ':' + minutes + ':' + seconds;
  }

  arrexplode(string:string){
    return string.split('|');
  }

  complateDuration(pass: any, i: any, approval_date: string, data: number, type: string) {
    var labelAging: string = '';
    if (approval_date !== null) {
      let appdate: any = approval_date.split('.', 2);
      appdate = appdate[0];
      if (pass == 1) {
        if (i == 0) {
          labelAging = appdate;
        } else {
          let aging = (data < 0)? 0 : data;
          var seconds = 0
          if (type == 'hour') {
            seconds = aging * 3600;
          } else if (type == 'minute') {
            seconds = aging * 60;
          } else {
            seconds = aging * 3600;
          }
          var minutes = Math.floor(seconds / 60);
          var hours = Math.floor(minutes / 60);
          var days = Math.floor(hours / 24);

          hours = hours - (days * 24);
          minutes = minutes - (days * 24 * 60) - (hours * 60);
          seconds = seconds - (days * 24 * 60 * 60) - (hours * 60 * 60) - (minutes * 60);
          
          if (days > 0) {
            labelAging = days + (days > 1 ? ' days ' : ' day ');
          }
          labelAging = labelAging + hours + ':' + minutes + ':' + seconds;
          labelAging = appdate + ' (Approval time: ' + labelAging + ')';
        }
      } else {
        labelAging = ' (Waiting : ' + this.getinterval(appdate, 'x') + ')';
      }
    }

    return labelAging;
  }
}
