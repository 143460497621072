import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject, Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class RouterService {

    private route = new BehaviorSubject('');
    currentRoute = this.route.asObservable();

    private idMaster = new Subject<any>();

    constructor() { }

    changeRoute(route: string) {
        this.route.next(route);
    }

    changeId(id: any) {
        this.idMaster.next(id);
    }
    getId(): Observable<any> {
        return this.idMaster.asObservable();
    }

}
